import React from 'react'

const TermsWarrantyServices = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>9. Warranty for the Services of the Website</h3>
            <div className={'terms_of_service_item_container'}>
                <span>9.1</span>
                <span>
                    The Website operator guarantees thorough performance of his services and obliges himself to
                    perform them in accordance with the current technological standards.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.2</span>
                <span>
                    The liability of the Website operator is limited to damages that are attributable to willful
                    breaches of contract or gross or average negligence of its employees.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.3</span>
                <span>
                    The customers are aware that even with careful software development and maintenance
                    occasional errors may occur, so that the Website operator does not guarantee an uninterrupted
                    operation of the Website. The Website operator is not liable for consequential damages, loss of
                    profits and loss of data as well as damages as a result of service interruptions.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.4</span>
                <span>
                    The Website operator carries out planned system interruptions, such as maintenance checks
                    whenever possible outside office hours. He can interrupt operations if required due to important
                    reasons, e.g. in case of disorders or danger of abuse. If possible information about
                    interruptions will be published on the Website.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.5</span>
                <span>
                    The Website operator is not liable for defects and faults outside of his responsibility,
                    specifically security deficiencies and standstills caused by third parties, he works with, or
                    whom he depends upon.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.6</span>
                <span>
                    The Website operator is furthermore not liable in the event of force majeure, improper use,
                    disregard of risks on the side of the customer or third parties, excessive use, inadequate
                    equipment and extreme environmental conditions, unauthorized manipulation of the program code of
                    the software on the part of the customer or malfunctions caused by third parties (viruses, worms
                    etc.), that occur despite the current implemented security precautions.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.7</span>
                <span>
                    The Website operator informs the customers on the Website about risks, e.g. spam messages
                    that were supposedly sent in his name, as well as security precautions.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.8</span>
                <span>
                    In the event that the customers detect mistakes on the Website, they shall report them to the
                    customer service as fast as possible.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>9.9</span>
                <span>
                    The customers are responsible for the security of systems, programs, and data under their
                    influence. In their own interest, customers shall keep passwords and user identities secret from
                    third parties.
                </span>
            </div>
        </div>
    )
}

export default TermsWarrantyServices