import React from 'react'

const TermsDataProtection = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>10. Data Protection and Processing of Data</h3>
            <div className={'terms_of_service_item_container'}>
                <span>10.1</span>
                <span>
                    The Website operator expressly guarantees compliance with the rules on data protection. He
                    obliges himself, for systems, programs, etc., that belong to him and on which he has influence,
                    to ensure safety according to the current technological standards.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>10.2</span>
                <span>
                    The customers provide the Website operator with all information required for the services
                    according to the contract and warrant, that all data entered by them are correct and complete.
                    The customers report changes of address and other required information immediately.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>10.3</span>
                <span>
                    The customers acknowledge that the Website operator stores data that is required for
                    fulfillment of the contract, and stores it in accordance with legal archiving obligations and
                    potentially analyzes it for statistical purposes. The Website operator obliges himself not to
                    share the data of the customers with third parties for other purposes than stated in the
                    contract
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>10.4</span>
                <span>
                    This Website stores only personal data that has been provided by the customers themselves by
                    registring for the newsletter and as clients. Customers can delete their data at any time, as
                    follows: send an email with the subject: "delete my data" to
                    <a href="mailto:info@artsted.com"> info@artisfact.co.uk</a>.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>10.5</span>
                <span>
                    The Website operator regularly sends out a newsletter with various contents. The newsletter
                    can be unsubscribed from at any time via user account settings or by clicking the "unsubscribe"
                    link in the newsletter email.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>10.6</span>
                <span>
                    This Website does not use permanent cookies.
                    This Website uses Google Analytics. To disable Google Analytics customers can install a browser
                    add-on from Google, available here:
                    <a rel="noopener noreferrer nofollow"
                       href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>
                </span>
            </div>
        </div>
    )
}

export default TermsDataProtection
