import {Checkbox} from "antd";

import styles from './StyledCheckbox.module.css';
import clsx from "clsx";

export default function StyledCheckbox({ align, children, error, ...props }) {
    return (
        <Checkbox {...props} className={clsx(styles.root, align && styles[align], error && styles.error)}>
            {children}
        </Checkbox>
    )
}