import React from 'react';
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";

const NewFilterView = (props) => {
    const {
        activeFilter, showFilterPopup, handleDetectOutSide,
        filterList, setActiveFilterFunc, setActiveFilter,
        category, mobile, customHandleClick, setListingData,
        sort_by_output, previewTitle
    } = props;


    const filterView = () => (
        <div className={'every_filter__popup every_filter__popup_new overflow_scrollbar'}>
            {filterList.map((item, index) => (
                <div key={index}
                     onClick={() => {
                         if (customHandleClick) {
                             customHandleClick(item)
                         } else {
                             setActiveFilterFunc(item.name, category)
                             setActiveFilter(null)
                         }
                         setListingData(prevState => ({
                             ...prevState,
                             page: 1
                         }))
                     }}>
                    {sort_by_output ?
                        <span>{item.preview} </span> :
                        // [
                            <span key={'name'}>{item.name}</span>
                            // <span className={'numbers_of_listing_grey'} key={'count'}>({item.n})</span>]
                    }
                </div>
            ))}
        </div>
    )

    return (
        <div className={`every_filter ${category} ${activeFilter === category ? 'not_event' : ''}`}>
            <OutsideClickHandler callback={() => activeFilter === category && !mobile && handleDetectOutSide()}>
                <div className={"category_wrapper category_wrapper_new"} onClick={() => showFilterPopup(category)}>
                    <span>{previewTitle ?? capitalizeFirstLetter(category)}</span>
                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 0.111084V1.84732L7.98294 9.88886L0 1.84732V0.111084L7.98294 8.15262L16 0.111084Z"
                              fill="black"/>
                    </svg>
                </div>
                {activeFilter === category ?
                    filterView()
                    : null}
            </OutsideClickHandler>
        </div>
    )
}

export default NewFilterView