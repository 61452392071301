import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import OutsideClickHandler from "../Constants/detectOutsideClick";
import MainButton from "./CustomComponents/MainButton";
import {Loader} from "./Loader";
import NewPaginationView from "./NewPaginationView";
import {getActivePage} from "../Constants/getActivePage";
import {useGetLastChartHash} from "../Constants/useGetLastChartHash";
import API from "../api/API";
import {priceWithDots} from "../Constants/priceWithDots";
import useResize from "../Constants/useResize";

export default function SalesOrdersCard({mySales, orders}) {

    const {activeHashUrl} = useGetLastChartHash();
    const {innerWidth} = useResize();
    const [data, setData] = useState(null);
    const [popup, setPopup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState({
        options: ['all_time', 'last_week', 'last_month', 'year'],
        value: 'all_time'
    });
    const [orderBy, setOrderBy] = useState({
        options: ['price_asc', 'price_desc'],
        value: 'price_asc',
        popup: false,
    });
    const [activePage, setActivePage] = useState(() => ({
        page: activeHashUrl ? activeHashUrl : 1,
        pages: null,
        total: null
    }));

    useEffect(() => {
        if (activePage.page) {
            API().get(`/user/profile/my_sales?page=${activePage.page}&per_page=5&order_by=${orderBy.value}&period=${activeTab.value}`)
                .then(res => {
                    if (res.data) {
                        // console.log(res.data)
                        setData(res.data.orders)
                        setActivePage(prevState => ({
                            ...prevState,
                            total: res.data.total,
                            pages: res.data.pages,
                        }))
                        setLoading(true)
                    }
                })
        }
    }, [activePage.page, activeTab.value, orderBy.value])

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl
            }))
        }
    }, [activeHashUrl])

    const popupWrapper = (el) => (
        <div className={'popup_wrapper'}>
            <svg style={popup === el.order_id ? {pointerEvents: 'none'} : null}
                 onClick={() => setPopup(prevState => prevState !== el.order_id ? el.order_id : null)}
                 className={'dots'} width="24" height="48" viewBox="0 0 24 48" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                    <rect x="10" y="10" width="4" height="4" fill="black"/>
                    <rect x="10" y="22" width="4" height="4" fill="black"/>
                    <rect x="10" y="34" width="4" height="4" fill="black"/>
                </g>
            </svg>
            {popup === el.order_id
                ? <OutsideClickHandler callback={() => setPopup(null)}>
                    <div className={'popup'}>
                        <Link to={'/profile/my_sales'} onClick={() => setPopup(null)}>
                            {mySales ? 'Contact the buyer' : 'Contact seller'}</Link>
                        <div/>
                        <Link to={'/info/buyer_faq'} onClick={() => setPopup(null)}>Help</Link>
                        {/*<div/>*/}
                        {/*<Link to={'/profile/my_sales'} onClick={() => setPopup(null)}>Authenticity*/}
                        {/*    certificate</Link>*/}
                    </div>
                </OutsideClickHandler> : null}
        </div>
    )

    const priceWrap = (el) => (
        <div className={`price ${orders ? 'price_orders' : ''}`}>
            <p>{el.date}</p>
            <div>
                {mySales ? [
                    <div key={0} className={'inline inline_right'}>
                        <p>Full price:</p>
                        <span
                            className={'price_text'}>{el.artwork.category === 'NFT' ? 'ETH ' : '€'}{priceWithDots(el.artwork.price)}</span>
                    </div>,
                    <div key={1} className={'inline inline_right'}>
                        <p>You will receive:</p>
                        <span
                            className={'price_text'}>{el.artwork.category === 'NFT' ? 'ETH ' : '€'}{priceWithDots(el.artwork.artist_price)}</span>
                    </div>
                ] : <span
                    className={'price_text orders_price_text'}>{el.artwork.category === 'NFT' ? 'ETH ' : '€'}{priceWithDots(el.artwork.price)}</span>}
                {el.artwork.category !== 'NFT' && /*Drawing*/
                    <span className={'delivery'}>{el.delivery_type ? el.delivery_type : 'Basic'} delivery</span>
                }
            </div>
        </div>
    )

    const salesOrdersCardItem = (el) => (
        innerWidth > 768 ? <div key={el.order_id} className={'item'}>
                <Link to={`/public-artwork/${el.artwork.id}`}>
                    <div className={'sales-orders-card-item-container'}>
                        <img src={el.artwork.photo} alt="preview"/>
                        {el.artwork.category === 'NFT' && /*Drawing*/
                            <div className={'sales-orders-card-item-container-nft-block'}>NFT</div>
                        }
                    </div>
                </Link>
                <div className={`about ${orders ? 'about_orders' : ''}`}>
                    <h4>{el.artwork.author_name}</h4>
                    <p>{capitalizeFirstLetter(el.artwork.title)}, {el.artwork.year}</p>
                    {el.artwork.category === 'NFT' ? /*Drawing*/
                        <p>{el.artwork.category}</p>
                        :
                        <p>{el.artwork.category}: {el.artwork.size}</p>
                    }

                </div>
                <div className={`order ${orders ? 'order_orders' : ''}`}>
                    <p>Order №{el.order_id}</p>
                    {el.artwork.category !== 'NFT' && /*Drawing*/
                        <p>Tracking №{el.tracking}</p>
                    }
                    <div className={'inline'}>
                        <p>Status:</p>
                        <span>{capitalizeFirstLetter(el.status)}</span>
                    </div>
                </div>
                {priceWrap(el)}
                {popupWrapper(el)}
            </div>
            : innerWidth > 568
            ? <div key={el.order_id} className={'item'}>
                <Link to={`/public-artwork/${el.artwork.id}`}>
                    <img src={el.artwork.photo} alt="preview"/>
                </Link>
                <div className={'item_content'}>
                    <div className={`about ${orders ? 'about_orders' : ''}`}>
                        <h4>{el.artwork.author_name}</h4>
                        <p>{capitalizeFirstLetter(el.artwork.title)}, {el.artwork.year}</p>
                        <p>{el.artwork.category}: {el.artwork.size}</p>
                    </div>
                    {popupWrapper(el)}
                    <div className={`order ${orders ? 'order_orders' : ''}`}>
                        <p>Order №{el.order_id}</p>
                        <p>Tracking №{el.tracking}</p>
                        <div className={'inline'}>
                            <p>Status:</p>
                            <span>{capitalizeFirstLetter(el.status)}</span>
                        </div>
                    </div>
                    {priceWrap(el)}
                </div>
            </div>
            : <div key={el.order_id} className={'item'}>
                <Link to={`/public-artwork/${el.artwork.id}`}>
                    <img src={el.artwork.photo} alt="preview"/>
                </Link>
                <div className={'item_content'}>
                    <div className={`about ${orders ? 'about_orders' : ''}`}>
                        <h4>{el.artwork.author_name}</h4>
                        <p>{capitalizeFirstLetter(el.artwork.title)}, {el.artwork.year}</p>
                        <p>{el.artwork.category}: {el.artwork.size}</p>
                    </div>
                    {popupWrapper(el)}
                    <div className={`order ${orders ? 'order_orders' : ''}`}>
                        <p>Order №{el.order_id}</p>
                        <p>Tracking №{el.tracking}</p>
                        <div className={'inline'}>
                            <p>Status:</p>
                            <span>{capitalizeFirstLetter(el.status)}</span>
                        </div>
                    </div>
                    {priceWrap(el)}
                </div>
            </div>
    )

    return (
        <div className={'my_sales_wrapper'}>
            <div className={'my_sales_navigation'}>
                <div className={'active_tabs_container'}>
                    {activeTab.options.map((el, i) => (
                        <MainButton type={'button'}
                                    key={i}
                                    text={capitalizeFirstLetter(el.split('_').join(' '))}
                                    style={activeTab.value === el ? {
                                        background: '#000',
                                        color: '#fff'
                                    } : null}
                                    className={'button_black'}
                                    onClick={() => setActiveTab(prevState => ({
                                        ...prevState,
                                        value: el
                                    }))}
                        />
                    ))}
                </div>
                <div className={'sort_by_wrapper'}>
                    <div style={orderBy.popup ? {pointerEvents: 'none'} : null}
                         className={'sort_by'} onClick={() => {
                        setOrderBy(prevState => ({
                            ...prevState,
                            popup: !prevState.popup
                        }))
                    }}>
                        <span>{capitalizeFirstLetter(orderBy.value.split('_').join(' '))}</span>
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 0.111328V1.84757L7.98294 9.88911L0 1.84757V0.111328L7.98294 8.15287L16 0.111328Z"
                                fill="black"/>
                        </svg>
                    </div>
                    <OutsideClickHandler callback={() => {
                        setOrderBy(prevState => ({
                            ...prevState,
                            popup: false,
                        }))
                    }}>
                        <div className={`popup ${orderBy.popup ? 'popup_active' : ''}`}>
                            {orderBy.options.filter(el => el !== orderBy.value).map((el, i) => (
                                <span onClick={() => {
                                    setOrderBy(prevState => ({
                                        ...prevState,
                                        popup: false,
                                        value: el,
                                    }))
                                }} key={i}>{capitalizeFirstLetter(el.split('_').join(' '))}</span>
                            ))}
                        </div>
                    </OutsideClickHandler>
                </div>
            </div>

            <div className={'my_sales_content'}>
                {loading ?
                    data.length ? data.map(el => salesOrdersCardItem(el)) :
                        <span className={'no_items_style'}>No items</span>
                    : <Loader color={{
                        '--bgColor': '#000',
                        '--size': '48px',
                        '--wrapper_size': '40px'
                    }}/>}
            </div>

            {activePage?.total > 9 ? loading ?
                <div className={'wrapper_new_pagination'}>
                    <NewPaginationView activePage={activePage}
                                       onChange={pageNumber => {
                                           getActivePage(pageNumber)
                                           setActivePage(prevState => ({
                                               ...prevState,
                                               page: pageNumber
                                           }))
                                       }}/>
                </div>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>
                : null}
        </div>
    )
}