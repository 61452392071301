import React from 'react';
import done from "../../assets/img/subscription_plan_checkk.svg";

const Subscription = ({activeSubscription}) => {
    return (
        <div className={'user_cart_subscription_wrapper'}>
            <div>
                <div className={'user_cart_subscription_image_wrapper'}>
                    <div/>
                    <img src={activeSubscription?.data?.logo}
                         alt="subscription logo"/>
                </div>
            </div>
            <div>
                <h3>{activeSubscription?.data?.plan_title}</h3>
                <p>{activeSubscription?.data?.percentage}</p>
            </div>
            <div>
                <div
                    className={'user_cart_order_item_border_line user_cart_subscription_item_border_line'}/>
            </div>
            <div>
                {activeSubscription?.data?.dataText.map((el, index) => (
                    <div className={'user_cart_subscription_item_text'}
                         key={index}>
                        <img src={done} alt="checked"/>
                        <p>{el}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Subscription;
