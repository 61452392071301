import React from 'react';
import '../Pages/Cart/styles/post_checkout.css'
import Confetti from "react-confetti";
import {useWindowDimensions} from "./Cart/PostCheckout";
import {Link} from "react-router-dom";
import SEO from "../Components/SEO";

export default function PostCheckout() {
    const { height, width } = useWindowDimensions();

    return (
        <div className={'successful_order_wrapper'}>
            <Confetti
                width={width}
                height={height}
                gravity={0.05}
                tweenDuration={100000}
                numberOfPieces={3000}
                recycle={false}
            />
            <SEO title="Card added: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Card added: Buy art online Artsted">
                <meta name="robots" content="noindex, nofollow"/>
            </SEO>
            <div className={'order_details'}>
                <h1 className="checkout-success">Great job! Your card has been added successfully.</h1>
                <p>If you were buying an artwork, you can now complete the purchase.</p>
                <p>You can see it in your <Link to={'/profile/settings'}>personal profile</Link></p>
            </div>
        </div>
    )
}
