import classNames from 'classnames';
import classes from './error.module.scss';

function Error (props) {
   return (
        <div className={classNames(classes.wrapper, props.className)}>
            {props.text}
        </div>
   )
}

Error.defaultProps = {
    text: 'Error text'
}

export default Error;