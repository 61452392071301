import React from 'react';
import SEO from "../../Components/SEO";

export default function Delivery() {
    return (
        <div className={'faq_section_body delivery_padding'}>
            <SEO title="Shipping & Packaging: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Shipping & Packaging: Buy art online Artsted"/>
            <h1>Shipping & Packaging</h1>

             <p>Artsted is a happy partner of the global network of delivery carriers, specialized in fine art
                transportation. The checkout price will automatically include the shipping, from the place of origin to
                the collector’s destination.</p>
            <p>Packaging essentials: soft wrap, layers of paper, bubble wrap.</p>
            <p>   Soft-wrapped pieces must be put in commercial standard wooden or plastic crates, boxes or tubes. </p>
            <p> It is the artist's responsibility to find the best possible way to make sure the artwork gets to the
                collector intact and won’t suffer any damage during the transportation due to the poor packaging. </p>
            <p>It is a buyer’s responsibility to pay due customs duties on the items purchased on Artsted whenever
                needed. Premium delivery comes with transportation insurance and covers loss of shipped items. </p>
            <p>All damage cases that are not covered by the insurance will be dealt with by opening a dispute.</p>
        </div>
    )
}