import React from 'react'

const PersonalDataCollected = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>3. HOW IS YOUR PERSONAL DATA COLLECTED?</h3>
            <div className={'privacy_policy_item_container'}>
                <span>We use different methods to collect data from and about you including through:</span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Direct interactions.</span>
                <span>
                    You may give us your Identity Data, Contact Data,
                            Transaction Data, Profile Data,
                            Financial Data and Marketing and Communications Data by using our website, filling in forms
                            or by corresponding with us by post,
                            phone, email or otherwise. This includes personal data you provide when you:
                    <br/>
                    -purchase a product or service (including gift cards) through our website;
                    <br/>
                    -create an account on our website;
                    <br/>
                    -request marketing to be sent to you;
                    <br/>
                    -use features of our website such as favoriting and following, or messaging our Sellers;
                    <br/>
                    -apply to become a Seller;
                    <br/>
                    -create or update your Artsted store;
                    <br/>
                    -give us some feedback;
                    <br/>
                    Automated technologies or interactions. As you interact with our website, we may
                    automatically collect Usage Data and Technical Data about your equipment, browsing actions
                    and patterns. We collect this personal data by using cookies, server logs and other similar
                    technologies. Please see our Cookie Policy for further details
                </span>
            </div>
        </div>
    )
}

export default PersonalDataCollected