import React from 'react';
import SEO from "../../Components/SEO";

export default function ArtworkUpload() {
    return (
        <div className={'faq_section_body artwork_upload_padding'}>
            <SEO title="How to upload artwork?: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ How to upload artwork?: Buy art online Artsted"/>
            <h1>How to upload artwork?</h1>

            <p> On Artsted the creators are empowered to take over their careers and promotion in a holistic manner. An
                artist willing to create their online portfolio offering, will have to sign up with the platform, fill
                out the personal data, verify the user profile data and upload the items to the inventory. </p>
            <p>Each item must have high resolution photos, a title, exact measurements in cm, packaging type, any
                relevant provenance data (exhibitions, publications) as well as year of creation, mediums, styles, and
                description of the artwork.</p>
        </div>
    )
}