import React, {useEffect, useState} from 'react';
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import API from "../../api/API";
import Keys from "../../Constants/helper";
import search_icon from "../../assets/img/admin/search_button.svg";
import {Link} from "react-router-dom";
import NewPaginationView from "../../Components/NewPaginationView";
import {getActivePage} from "../../Constants/getActivePage";
import ModalWindow from "../../Components/ModalWindow";
import close_modal from "../../assets/img/admin/close_modal.svg";
import FilterView from "../../Components/FilterComponents/FilterView";
import FiltersWrapper from "../../Components/FilterComponents/FiltersWrapper";
import SwitchStatus from "../Components/SwitchStatus";


const typeToValue = {
    "Prizes, Contests Won": "Awards",
    "Acquisition For A Public Collection (Museum)": "Museums",
    "PR, Media Features": "Media",
    "Exhibitions": "Exhibitions",
    "Residencies": "Residencies",
    "Education": "Education",
}

const typeToName = {
    "Awards": "Prizes, Contests Won",
    "Museums": "Acquisition For A Public Collection (Museum)",
    "Media": "PR, Media Features",
    "Exhibitions": "Exhibitions",
    "Residencies": "Residencies",
    "Education": "Education",
}

const initialState = {
    loading: false,
    listing_search: {
        value: ''
    },
    type: {
        activeList: [],
        list: [
            {name: "Education", preview: "Education"},
            {name: "Prizes, Contests Won", preview: "Prizes, Contests Won"},
            {
                name: "Acquisition For A Public Collection (Museum)",
                preview: "Acquisition For A Public Collection (Museum)"
            },
            {name: "PR, Media Features", preview: "PR, Media Features"},
            {name: "Exhibitions", preview: "Exhibitions"},
            {name: "Residencies", preview: "Residencies"},
        ]
    },
    category: {
        activeList: [],
        list: []
    },
    styles: {
        activeList: [],
        list: []
    },
    sort_by: {
        activeList: [],
        list: [{name: 'price_asc', preview: 'Price asc'}, {name: 'price_desc', preview: 'Price desc'}],
    },
}

const InstitutionRequests = () => {
    let {
        filtersObject,
        showFilterPopup,
        setListingData,
        setActiveFilterFunc,
        activeFilter,
        setActiveFilter,
        activeFilterArray,
        categoryViewSection,
        handleDetectOutSide,
    } = FiltersWrapper(
        initialState, undefined,
        'institutions', '/admin/event_requests',
        Keys.JWT_TOKEN_ADMIN, true
    );

    const {activeHashUrl} = useGetLastChartHash()
    const [activeSearch, setActiveSearch] = useState({
        value: ''
    })
    const [activeModal, setActiveModal] = useState({
        value: null,
        type: null,
    })
    const [verifiedUser, setVerifiedUser] = useState('unverified')
    const [activeInstitution, setActiveInstitution] = useState(null)
    const [activeData, setActiveData] = useState([])
    const [activePage, setActivePage] = useState(() => ({
        page: null,
        pages: null,
        total: null,
        loading: null
    }));

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl,
            }))
        }
    }, [activeHashUrl])

    const getFilters = search => {
        console.log("filtersObject.type.activeLis", filtersObject.type.activeList)
        const searchValue = search ?? activeSearch.value.length > 1 ? activeSearch.value.toLowerCase() : '';
        const typeValue = filtersObject.type.activeList.map(i => `&type=${typeToValue[i.name]}`).join("")
        const approvedValue = verifiedUser === "unverified" ? "" : verifiedUser === "approved" ? "1" : "0"
        API(Keys.JWT_TOKEN_ADMIN).get(
            `/admin/event_requests?name=${searchValue}&page=${activePage.page}&per_page=10${typeValue}&approved=${approvedValue}`
        )
            .then((res) => {
                // console.log(res);
                if (res.data) {
                    setActiveData(res.data.events)
                    setActivePage(prevState => ({
                        ...prevState,
                        pages: res.data.pages,
                        total: res.data.total,
                        loading: true
                    }))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (activeSearch.value.length >= 2) {
            getFilters()
        } else if (activeSearch.value.length) {
            getFilters('')
        }

        // eslint-disable-next-line
    }, [activeSearch.value])

    useEffect(() => {
        if (activePage.page) {
            getFilters()
        }
        // eslint-disable-next-line
    }, [activePage.page, filtersObject.type.activeList, verifiedUser])

    const handleUserRequest = () => {
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/event_requests`, {
            id: activeModal.value,
            approved: activeModal.type,
        })
            .then(() => {
                // console.log(res)
                setActiveData(prevState => prevState.filter(el => el.id !== activeModal.value))
                setActiveModal({
                    value: null,
                    type: null,
                })
            })
    }

    console.log("asd", activeFilterArray)
    console.log("type", filtersObject)
    return [
        <div className={'admin_content_wrapper admin_wrapper_institutions'} key={1}>
            <div
                className={'admin_content_wrapper_users admin_content_wrapper_institutions admin_artworks_content_wrapper'}>
                <h1>Institution Requests</h1>
                <div className={'verified_container admin_artworks_head'}>
                    <div className={'search-and-download-button-container'}>
                        <label>
                            <input type="text"
                                   placeholder={'Search '}
                                   value={activeSearch.value}
                                   onChange={({target}) => {
                                       setActiveSearch(prevState => ({
                                           ...prevState,
                                           value: target.value
                                       }))
                                   }}
                            />
                            <img src={search_icon} alt="search icon"/>
                        </label>
                    </div>
                    <div className={'verified_content'}>
                        <SwitchStatus switchStatus={'rejected'}
                                      setActivePage={setActivePage}
                                      setVerifiedUser={setVerifiedUser}
                                      verifiedUser={verifiedUser}/>
                        <SwitchStatus switchStatus={'approved'}
                                      setActivePage={setActivePage}
                                      setVerifiedUser={setVerifiedUser}
                                      verifiedUser={verifiedUser}/>
                        <SwitchStatus switchStatus={'unverified'}
                                      setActivePage={setActivePage}
                                      setVerifiedUser={setVerifiedUser}
                                      verifiedUser={verifiedUser}/>
                    </div>
                    <div className={'verified_content wrapper_list_filters'}>
                        <FilterView category={'type'}
                                    sort_by_output
                                    handleDetectOutSide={handleDetectOutSide}
                                    activeFilter={activeFilter}
                                    filterList={filtersObject.type.list}
                                    setListingData={e => setListingData(e)}
                                    setActiveFilterFunc={setActiveFilterFunc}
                                    setActiveFilter={e => setActiveFilter(e)}
                                    showFilterPopup={e => showFilterPopup(e)}/>
                    </div>
                </div>
                {filtersObject.type.activeList.length ?
                    categoryViewSection('gray', filtersObject.type.activeList)
                    : null}
                <div className={'title'}>
                    <h4>Artist name</h4>
                    <h4>Event Type</h4>
                    <h4>Title</h4>
                </div>
            </div>
            {activePage.loading ? activeData.length
                ? <div style={!activeData.length ? {padding: '1rem 0'} : null}
                       className={'admin_content_wrapper_users_list'}>
                    {activeData?.map(item => (
                        <div className={'item'} key={item.id}>
                            <div className={'item_content'}>
                                <span>{item.creator_full_name}</span>
                                <span>{typeToName[item.event_type] || item.event_type}</span>
                                <span>{item.name}</span>
                            </div>
                            <div
                                style={{gridTemplateColumns: 'repeat(3, max-content)', marginLeft: "24px"}}
                                className={'users_list_buttons'}>
                                <button className={'admin_bordered_button'}
                                        onClick={() => {
                                            setActiveModal({
                                                value: activeModal.value !== item.id ? item.id : null,
                                                type: false,
                                            })
                                            setActiveInstitution(item.name)
                                        }}>Reject
                                </button>
                                <button onClick={() => {
                                    setActiveModal({
                                        value: activeModal.value !== item.id ? item.id : null,
                                        type: true,
                                    })
                                    setActiveInstitution(item.name)
                                }}>Approve
                                </button>
                                <Link to={`/admin/institutions/${item.id}`}>
                                    Details
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
                : <span className={'admin_users_list_no_items no_items_style'}>No items</span> : null}
        </div>,
        activePage.loading && activePage.total > 9 ?
            <div className={'wrapper_new_pagination admin_pagination'} key={2}>
                <NewPaginationView activePage={activePage}
                                   onChange={pageNumber => {
                                       getActivePage(pageNumber)
                                       setActivePage(prevState => ({
                                           ...prevState,
                                           page: pageNumber
                                       }))
                                   }}/>
            </div>
            : null,
        activeModal.value ?
            <div className={'admin_wrapper_modal'} key={3}>
                <ModalWindow activeModal={activeModal}
                             nestedObj
                             setNullData={val => setActiveInstitution(val)}
                             setActiveModal={val => setActiveModal(val)}>
                    <img onClick={() => {
                        setActiveInstitution(null)
                        setActiveModal({
                            value: null,
                            type: null,
                        })
                    }} src={close_modal} alt="close modal"/>
                    <h4>{activeInstitution ?? 'Institution'}</h4>
                    <div className={'admin_wrapper_modal_line'}/>
                    <p style={{margin: '0 0 1rem'}}>
                        {activeModal.type
                            ? 'Are you sure you want to approve this institution?'
                            : 'Are you sure you want to reject this institution?'}
                    </p>
                    <button onClick={() => handleUserRequest()}>{activeModal.type ? "Approve" : "Reject"}</button>
                </ModalWindow>
            </div> : null
    ]
};

export default InstitutionRequests;
