import React from 'react';
import './styles/sidebar_menu.css'
import OutsideClickHandler from "../Constants/detectOutsideClick";
import {Link} from "react-router-dom";

export default function SidebarMenu(props) {
    const {
        activeSidebar, authWrapper,
        handleOutSideClick, cart, user_data,
        linkArtistProfile
    } = props;
    return (
        <OutsideClickHandler callback={handleOutSideClick}>
            <div className={`sidebar_menu ${activeSidebar ? 'active-sidebar_menu' : ''}`}>
                <div className={'sidebar_menu__navigation'}>
                    <Link to={'/'} onClick={() => handleOutSideClick()}>Discover</Link>
                    <Link to={'/artworks'}
                          onClick={() => handleOutSideClick()}>Artworks</Link>
                    <Link to={'/artists'} onClick={e => {
                        handleOutSideClick()
                        linkArtistProfile(e)
                    }}>Artists</Link>
                    <Link to={'/premium'} onClick={() => handleOutSideClick()}>Premium</Link>
                    <a href={'https://blog.artsted.com'}>Blog</a>
                </div>
                {user_data?.role === 'investor' ?
                    <Link to={'/cart'}
                          onClick={() => handleOutSideClick()}
                          className={'sidebar_menu__navigation__cart'}>
                        {cart(true)}
                    </Link>
                    : null}
                <div className={'sidebar_menu__navigation__sign_wrapper'}>
                    {authWrapper()}
                </div>
            </div>
        </OutsideClickHandler>
    )
}