import React from 'react'
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import dropdown_icon from "../../assets/img/admin/dropdown_icon.svg";
import ToolTipCustom from "../../Components/ToolTipCustom";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import cancel_category from "../../assets/img/admin/cancel_category_blog.svg";
import TextInput from "../../Components/CustomComponents/TextInput";
import TextBox from "../../Components/CustomComponents/TextBox";
import AddBlogSection from "./AddBlogSection";
import add_section from "../../assets/img/admin/add_section_blog.svg";

export default function NewPostComponent(props) {

    const {
        setPostPopup, postPopup, initialState, editMode, handlePostSubmit,
        blogMainData, loading, setBlogMainData, uploadFiles, articleData,
        convertToRaw, editorMarkup, editorState, setArticleData, postRef,
        setEditorMarkup, setEditorState, EditorState,
    } = props

    return (
        <div className={'admin_new_post_wrapper'}>
            <div className={'content overflow_scrollbar'} ref={postRef}>
                <svg onClick={() => {
                    bodyOverflow(false)
                    setPostPopup(prevState => ({
                        ...prevState,
                        value: false,
                        category: {
                            ...prevState.category,
                            value: false,
                        }
                    }))
                    setEditorState([]);
                    setEditorMarkup([])
                    setArticleData([])
                    setBlogMainData(() => initialState)
                }} width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect width="2.46964" height="19.7571"
                          transform="matrix(0.694131 0.719849 -0.694131 0.719849 13.7139 0)"
                          fill="#8F9BB3"/>
                    <rect width="2.46964" height="19.7571"
                          transform="matrix(-0.694131 0.719849 -0.694131 -0.719849 16 14.2227)"
                          fill="#8F9BB3"/>
                </svg>
                <h2>{editMode ? "Edit Post" : 'New Post'}</h2>
                <div className={'line'}/>
                <div className={'category'}>
                    <div className={'admin_category_wrapper'}>
                        <div style={postPopup.category.popup
                            ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
                             className={'text'}
                             onClick={() => setPostPopup(prevState => ({
                                 ...prevState,
                                 category: {
                                     ...prevState.category,
                                     popup: !prevState.category.popup,
                                     error: false,
                                 }
                             }))}>
                            <span>Category</span>
                            <img src={dropdown_icon} alt="icon"/>
                        </div>
                        {postPopup.category.error
                            ? <ToolTipCustom toolTipText={'Choose category'}/> : null}
                        {postPopup.category.popup
                            ? postPopup.category.options.length
                                ? <OutsideClickHandler callback={() => setPostPopup(prevState => ({
                                    ...prevState,
                                    category: {
                                        ...prevState.category,
                                        popup: false,
                                    }
                                }))}>
                                    <div
                                        className={`options_wrapper overflow_scrollbar 
                                                ${postPopup.category.popup ? 'options_wrapper_active' : ''}`}>
                                        {postPopup.category.options
                                            .filter(el => el !== postPopup.category.value)
                                            .map((el, i) => (
                                                <span key={i} onClick={() => setPostPopup(state => ({
                                                    ...state,
                                                    category: {
                                                        ...state.category,
                                                        value: el,
                                                        popup: false,
                                                        error: false,
                                                    }
                                                }))}>{capitalizeFirstLetter(el)}</span>
                                            ))}
                                    </div>
                                </OutsideClickHandler> : <span>No items</span> : null}
                    </div>
                    {postPopup.category.value
                        ? <div className={'cancel_category'}>
                            <span>{postPopup.category.value}</span>
                            <img onClick={() => setPostPopup(prevState => ({
                                ...prevState,
                                category: {
                                    ...prevState.category,
                                    value: null,
                                }
                            }))} src={cancel_category} alt="cancel"/>
                        </div>
                        : null}
                </div>
                <div className={'line'}/>

                <span className={'subtitle'}>Author's name</span>
                <TextInput
                    type={'text'}
                    margin={'0 0 1.5rem'}
                    error={blogMainData.author_name.error}
                    errTitle={blogMainData.author_name.errorMessage}
                    value={blogMainData.author_name.value}
                    onChange={({target}) => {
                        setBlogMainData(prevState => ({
                            ...prevState,
                            author_name: {
                                value: target.value,
                                error: false,
                            },
                        }))
                    }}
                />
                <div className={'line'}/>

                <span className={'subtitle'}>Main photo</span>

                <div
                    // style={blogMainData?.post_photo?.previews?.url
                    // ? {border: 'none'}
                    // : {border: 'border: 1px dashed rgba(0, 0, 0, .3)'}}
                    className={'photo_placeholder'}>
                    <label htmlFor={'post_photo'}>
                        Select image
                    </label>
                    <input type="file"
                           id={'post_photo'}
                           onClick={({target}) => target.value = ''}
                           accept="image/jpeg,image/png, .jpeg,.jpg,.png"
                           onChange={e => uploadFiles(e, 'post_photo', false)}
                    />
                    {blogMainData.post_photo.error
                        ? <ToolTipCustom toolTipText={'Upload photo'}
                                         className={'tool_tip_upload_photo_error'}/> : null}
                    {loading ? blogMainData?.post_photo?.previews?.url ?
                        <div className={'preview_image'}>
                            <svg onClick={(e) => {
                                e.preventDefault();
                                setBlogMainData(prevState => ({
                                    ...prevState,
                                    post_photo: {
                                        ...prevState.post_photo,
                                        previews: '',
                                        files: '',
                                    },
                                }))
                            }} width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="2.46964" height="19.7571"
                                      transform="matrix(0.694131 0.719849 -0.694131 0.719849 13.7139 0)"
                                      fill="#8F9BB3"/>
                                <rect width="2.46964" height="19.7571"
                                      transform="matrix(-0.694131 0.719849 -0.694131 -0.719849 16 14.2227)"
                                      fill="#8F9BB3"/>
                            </svg>
                            <img src={blogMainData.post_photo.previews.url}
                                 className={blogMainData.post_photo.previews ? 'active_img' : null}
                                 alt="preview"/>
                        </div>
                        : null : null}
                </div>

                <span className={'subtitle'}>Name</span>
                <TextInput
                    type={'text'}
                    margin={'0 0 1.5rem'}
                    error={blogMainData.name.error}
                    errTitle={blogMainData.name.errorMessage}
                    value={blogMainData.name.value}
                    onChange={({target}) => {
                        setBlogMainData(prevState => ({
                            ...prevState,
                            name: {
                                value: target.value,
                                error: false,
                            },
                        }))
                    }}
                />

                <span className={'subtitle'}>Short description</span>
                <TextBox
                    type={'text'}
                    rows={'3'}
                    margin={'0 0 1.5rem'}
                    error={blogMainData.description.error}
                    errTitle={blogMainData.description.errorMessage}
                    value={blogMainData.description.value}
                    onChange={({target}) => {
                        setBlogMainData(prevState => ({
                            ...prevState,
                            description: {
                                value: target.value,
                                error: false,
                            },
                        }))
                    }}
                />
                {articleData.length ?
                    articleData.map((el, i) => (
                        <div key={i}>
                            <AddBlogSection editorState={editorState}
                                            convertToRaw={convertToRaw}
                                            articleData={articleData}
                                            arrIndex={i}
                                            state={el}
                                            editorMarkup={editorMarkup}
                                            uploadFiles={uploadFiles}
                                            setArticleData={val => setArticleData(val)}
                                            setEditorState={val => setEditorState(val)}
                                            setEditorMarkup={val => setEditorMarkup(val)}/>
                        </div>
                    )) : null}

                <button onClick={() => {
                    setArticleData(prevState => ([
                        ...prevState, {
                            name: {
                                value: '',
                            },
                            file: {
                                previews: '',
                                files: '',
                            },
                            description: {
                                value: '',
                            },
                        }]))
                    setEditorState(prevState => ([...prevState, EditorState.createEmpty()]))
                    setEditorMarkup(prevState => ([...prevState, '']))
                }} className={'add_section_button'}>
                    <img src={add_section} alt="add"/>
                    <span>Add a Section</span>
                </button>
                <div className={'line'}/>
                <div className={'submit_form_wrapper'}>
                    <button className={'submit_form'}
                            onClick={(e) => handlePostSubmit(e)}
                            type={'submit'}>
                        {editMode ? 'Edit' : 'Create'}
                    </button>
                </div>
            </div>
        </div>
    )
}