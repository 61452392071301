import React from 'react';
import SEO from "../../../Components/SEO";

export default function Careers() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Careers: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Careers: Buy art online Artsted"/>
            <h1>Careers</h1>

            <p>The parent company of Artsted, Artisfact ltd is an international business that is committed to expanding
                and promoting our mission globally. Want to get involved? Send your CV to info@artsted.com
            </p>
        </div>
    )
}