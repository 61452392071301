import React, {useEffect, useState} from 'react';
import OutsideClickHandler from "../Constants/detectOutsideClick";
import regExp from "../Constants/regExp";
import TextInput from "../Components/CustomComponents/TextInput";
import MainButton from "../Components/CustomComponents/MainButton";
import CustomCheckbox from "../Components/CustomComponents/CheckBox/index";
import Popup from "../Components/Popup";
import vector_back from "../assets/img/vector_back.svg";
import FacebookLogin from 'react-facebook-login'
import API from "../api/API";
import Keys from "../Constants/helper";
import {bodyOverflow} from "../Constants/bodyOverflow";
import {clearCookies} from "../Constants/clearCookies";
import {useHistory} from "react-router-dom";
import CheckBox from "./CustomComponents/CheckBox";

const initialTerms = {
    terms: {
        value: false,
        error: false
    },
    receive: {
        value: false
    }
};

const initialInput = {value: '', error: null};

const HeaderAuthPopup = (props) => {

    const {
        auth_variant, auth_popup, setAuthPopUp,
        setAuthVariant, setUserData, emailSubscribePopup,
        newHeader,
    } = props;

    const history = useHistory();

    const [email, setEmail] = useState(initialInput);
    const [password, setPassword] = useState(initialInput);
    const [repeatPassword, setRepeatPassword] = useState(initialInput);
    const [serviceTerm, setServiceTerm] = useState(initialTerms);
    const [loginError, setLoginError] = useState(null);
    const [role, setRole] = useState(null);
    const [fbToken, setFbToken] = useState(null);
    const [userBlockedPopup, setUserBlockedPopup] = useState({show: null, reason: null});
    const [forgotPassSend, setForgotPassSend] = useState(false);
    const [resendTokenButton, setResendTokenButton] = useState({
        value: false,
        disabled: false,
        show: false
    })

    useEffect(() => {
        if (sessionStorage.getItem(Keys.LANDING_ROLE)) {
            let getRole = sessionStorage.getItem(Keys.LANDING_ROLE)
            // console.log(getRole)
            setRole(getRole)
        }
        // return () => null
    }, [auth_popup])

    useEffect(() => {
        setServiceTerm(initialTerms);
        setFbToken(null)
        // return () => null
    }, [auth_popup])

    const detectOutSideClick = () => resetAuthData();

    const accessSign = (res, path) => {
        clearCookies();
        let userData = JSON.stringify({
            name: res.data.name,
            role: res.data.role,
            avatar: res.data.avatar ? res.data.avatar : null
        })
        localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
        setUserData(userData)
        window.location.pathname = path
        resetAuthData()
    }

    const loginSuccess = res => {
        if (res.accessToken && auth_popup === 'Registration') {
            setFbToken(res.accessToken)
            setAuthVariant(true)
        } else if (res.accessToken) {
            API().post(`/user/oauth`, {"token": res.accessToken})
                .then(res => {
                    accessSign(res, (role === 'investor' || role === 'organization') ? '/profile/about' : '/profile/portfolio')
                })
                .catch(() => {
                    setAuthPopUp('Registration')
                    setFbToken(res.accessToken)
                    setAuthVariant(true)
                })
        }
    }

    const handleChangeCheck = variant => {
        setServiceTerm(state => {
            // console.log(state[variant])
            return {
                ...state,
                [variant]: {
                    value: !state[variant].value,
                    error: false
                }
            }
        })
    }

    const handleBlockedUser = (res) => {
        if (res.data.error === "user blocked") {
            // console.log(res)
            setUserBlockedPopup({reason: res.data.reason, show: true})
        } else {
            if (res.data.registration_end) {
                //todo: fix
                accessSign(res, (role === 'investor' || role === 'organization') ? '/profile/about' : '/profile/portfolio')
            } else {
                localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                window.location.pathname = '/main-info'
                resetAuthData()
            }
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        if (auth_popup === 'Registration' && auth_variant && role) {
            if (fbToken) {
                API().post(`/user/oauth`, {"token": fbToken, "role": role})
                    .then(res => {
                        clearCookies();
                        let userData = JSON.stringify({
                            name: res.data.name,
                            role: role,
                            avatar: res.data.avatar ? res.data.avatar : null
                        })
                        localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                        setUserData(userData)
                        window.location.pathname = '/main-info'
                        resetAuthData()
                    })
                    .catch((er) => console.log(er))
            } else {
                API().post(`/user/registration/email`, {
                    "email": email.value,
                    "password": password.value,
                    "role": role,
                    "receive_email": serviceTerm.receive.value
                })
                    .then(res => {
                        clearCookies();
                        localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                        resetAuthData()
                        window.location.pathname = '/main-info'
                    })
                    .catch(e => console.log(e))
            }
        } else if (auth_popup === 'Registration') {
            if (regExp.email.test(email.value) && password.value.length > 5 && repeatPassword.value.length > 5 && serviceTerm.terms.value) {
                if (password.value === repeatPassword.value) {
                    API().post(`/user/registration/check_email`, {email: email.value})
                        .then(res => {
                            // alert(JSON.stringify(res.data))
                            if (res.data.result) {
                                setLoginError('The email is already in use')
                            } else {
                                setAuthVariant(true)
                            }
                        })
                } else {
                    setLoginError('Password is not similar')
                    setRepeatPassword(state => ({...state, error: true}));
                    setPassword(state => ({...state, error: true}));
                }
            } else {
                if (!regExp.email.test(email.value) && password.value.length < 6 && repeatPassword.value.length < 6) {
                    setLoginError('Please fill all fields correctly')
                    setEmail(state => ({...state, error: true}));
                    setPassword(state => ({...state, error: true}));
                    setRepeatPassword(state => ({...state, error: true}));
                } else {
                    if (!regExp.email.test(email.value)) {
                        setLoginError('Enter valid email')
                        setEmail(state => ({...state, error: true}));
                    }
                    if (password.value.length < 6) {
                        setPassword(state => ({...state, error: true}));
                        setLoginError('Enter at least 6 characters')
                    }
                    if (repeatPassword.value.length < 6) {
                        setRepeatPassword(state => ({...state, error: true}));
                        setLoginError('Enter at least 6 characters')
                    }
                    if (!serviceTerm.terms.value) {
                        setServiceTerm(state => ({
                            ...state, terms: {...state.terms, error: true}
                        }))
                    }
                }
            }
        } else if (auth_popup === 'Sign in') {
            // if (regExp.email.test(email.value) && password.value.length > 5) {
            if (regExp.email.test(email.value)) {
                API().post(`/user/login`,
                    {
                        email: email.value,
                        password: password.value
                    })
                    .then(res => handleBlockedUser(res))
                    .catch((err) => {
                        if (err.response) {
                            setLoginError(err.response.data.error)
                        }
                    })
            } else {
                if (!regExp.email.test(email.value)) {
                    setEmail(state => ({...state, error: true}));
                }
                if (password.value.length < 6) {
                    setPassword(state => ({...state, error: true}));
                }
            }
        } else if (auth_popup === 'Forgot password') {
            if (regExp.email.test(email.value)) {
                API().post(`/user/forgot_password`, {email: email.value})
                    .then(res => {
                        if (res.status === 200) {
                            setPassword(state => ({...state, value: ''}));
                            setEmail(state => ({...state, value: ''}));
                            setLoginError(null)
                            setForgotPassSend(true)
                            // setAuthPopUp('Sign in')
                        }
                    })
                    .catch(e => {
                        console.log(e)
                        //todo: temp for blocked user forgot pass
                        setLoginError('User not found')
                    })
            } else {
                if (!regExp.email.test(email.value)) {
                    setEmail(state => ({...state, error: true}));
                }
            }
        } else if (auth_popup === 'New password') {
            const resetPasswordCode = sessionStorage.getItem(Keys.RESET_PASSWORD_CODE)
            if (password.value.length > 5 && repeatPassword.value.length > 5) {
                if (password.value === repeatPassword.value) {
                    API().put(`/user/forgot_password`, {
                        password: password.value,
                        code: resetPasswordCode
                    })
                        .then(res => {
                            // console.log(res)
                            sessionStorage.removeItem(Keys.RESET_PASSWORD_CODE);
                            handleBlockedUser(res)
                        })
                        .catch(err => {
                            console.log(err)
                            if (err.response.data.error === 'token has expired') {
                                setLoginError('Your code has been expired, please resend it.')
                                setResendTokenButton(prevState => ({
                                    ...prevState,
                                    value: true
                                }))
                            }
                        })
                } else {
                    setLoginError('Password is not similar')
                    setRepeatPassword(state => ({...state, error: true}));
                    setPassword(state => ({...state, error: true}));
                }
            } else {
                if (password.value.length < 6 && repeatPassword.value.length < 6) {
                    setLoginError('Please fill all fields correctly')
                    setPassword(state => ({...state, error: true}));
                    setRepeatPassword(state => ({...state, error: true}));
                } else {
                    if (password.value.length < 6) {
                        setPassword(state => ({...state, error: true}));
                        setLoginError('Enter at least 6 characters')
                    }
                    if (repeatPassword.value.length < 6) {
                        setRepeatPassword(state => ({...state, error: true}));
                        setLoginError('Enter at least 6 characters')
                    }
                }
            }
        }
    }

    const resetAuthData = () => {
        if (auth_popup === 'New password') {
            history.push('/')
        }
        if (!emailSubscribePopup) {
            setAuthPopUp(null)
            setAuthVariant(null)
            bodyOverflow(false)
            setFbToken(null)
            sessionStorage.removeItem(Keys.VERIFIED_POPUP)
            sessionStorage.removeItem(Keys.LANDING_ROLE)
        }
    }

    console.log(role)
    return [
        <div className={'auth_popup_wrapper'} key={'1'}>
            <OutsideClickHandler callback={() => {
                if (!userBlockedPopup.show) {
                    detectOutSideClick()
                }
            }}>
                <div className={newHeader ? "popup new_popup" : "popup"}>
                    {auth_variant && auth_popup === 'Registration' ?
                        <form onSubmit={handleSubmit}>
                            <div className="title">
                                <span>{'Success! Сhoose your profile'}</span>
                            </div>
                            <div className={'wrapper_radio_btn'}>
                                <CheckBox text={'I’m an Artist'}
                                          value={role === 'artist'}
                                          handleChange={() => {
                                              setRole('artist')
                                          }}/>
                                <CheckBox text={'I’m an Investor'}
                                          value={role === 'investor'}
                                          handleChange={() => {
                                              setRole('investor')
                                          }}/>
                            </div>
                            <MainButton type={'submit'}
                                        text={'Apply'}/>
                        </form>
                        : <form onSubmit={handleSubmit}>
                            <div className="title">
                                <span>{auth_popup}</span>
                            </div>
                            <div className={'wrapper_inputs_auth'}>
                                {!forgotPassSend && auth_popup !== 'New password' ?
                                    <TextInput value={email.value}
                                               error={email.error}
                                               margin={'0 0 1rem'}
                                               title={'Email'}
                                               onChange={e => {
                                                   setEmail({value: e.target.value.trim(), error: null})
                                                   setLoginError(null)
                                               }}/>
                                    : null}
                                {!forgotPassSend && auth_popup !== 'Forgot password' ?
                                    <TextInput value={password.value}
                                               showPass
                                               error={password.error}
                                               type={'password'}
                                               title={auth_popup === 'New password' ? 'New password' : 'Password'}
                                               autoComplete={'on'}
                                               onChange={e => {
                                                   setPassword({value: e.target.value.trim(), error: null})
                                                   setLoginError(null)
                                               }}/>
                                    : null}
                                {!forgotPassSend && (auth_popup === 'Registration' || auth_popup === 'New password') ?
                                    <TextInput value={repeatPassword.value}
                                               showPass
                                               error={repeatPassword.error}
                                               autoComplete={'on'}
                                               type={'password'}
                                               margin={'1rem 0'}
                                               title={auth_popup === 'New password'
                                                   ? 'Confirm new password' : 'Repeat password'}
                                               onChange={e => {
                                                   setRepeatPassword({value: e.target.value, error: null})
                                                   setLoginError(null)
                                               }}/>
                                    : null}
                            </div>
                            {auth_popup === 'Sign in' ?
                                <div className={'forgot_password'}>
                                    <span onClick={() => setAuthPopUp('Forgot password')}>
                                    Forgot Password?
                                    </span>
                                </div>
                                : null}
                            {auth_popup === 'Registration' ?
                                <div className={'agree_terms'}>
                                    <CustomCheckbox text={'Agree to the terms of service'}
                                                    handleChange={() => handleChangeCheck('terms')}
                                                    error={serviceTerm.terms?.error}
                                                    value={serviceTerm.terms.value}/>
                                    <CustomCheckbox text={'I agree to receive emails'}
                                                    handleChange={() => handleChangeCheck('receive')}
                                                    value={serviceTerm.receive.value}/>
                                </div>
                                : null}
                            <div className={'wrapper_btn'}>
                                {loginError ?
                                    <div style={{margin: '0 0 4px'}} className={'text_error'}>{loginError}</div>
                                    : null}
                                {!forgotPassSend ?
                                    <MainButton type={resendTokenButton.value ? 'button' : 'submit'}
                                                onClick={() => {
                                                    if (resendTokenButton.value) {
                                                        const resetPasswordCode = sessionStorage.getItem(Keys.RESET_PASSWORD_CODE)
                                                        API().post(`/user/forgot_password`, {code: resetPasswordCode})
                                                            .then(res => {
                                                                if (res.status === 200) {
                                                                    setLoginError(null)
                                                                    setResendTokenButton(prevState => ({
                                                                        ...prevState,
                                                                        show: true,
                                                                    }))
                                                                    setForgotPassSend(true)
                                                                }
                                                            })
                                                            .catch(e => {
                                                                console.log(e)
                                                                //todo: temp for blocked user forgot pass
                                                                setLoginError('User not found')
                                                            })
                                                    }
                                                }}
                                                text={
                                                    resendTokenButton.value ? 'Resend code' :
                                                        auth_popup === 'Forgot password' ?
                                                            'Send verification code' :
                                                            auth_popup === 'New password'
                                                                ? 'Reset password' :
                                                                auth_popup === 'Registration'
                                                                    ? 'Register account' :
                                                                    auth_popup === 'Sign in' ?
                                                                        'Sign in' : ''}/>
                                    : null}
                                {auth_popup !== 'Forgot password' && auth_popup !== 'New password' ?
                                    <>
                                        <div className={'auth_delimitation'}>
                                            or
                                        </div>
                                        <div className={'fb_sign_wrapper'}>
                                            <FacebookLogin
                                                appId="2618139161816939"
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                textButton={auth_popup === 'Sign in' ? 'Login via Facebook' : 'Register via Facebook'}
                                                callback={(e) => loginSuccess(e)}
                                                cssClass="my-facebook-button-class"
                                                icon="fa-facebook"
                                            />
                                        </div>
                                    </>
                                    : null}
                            </div>
                            {(auth_popup === 'Forgot password' || auth_popup === 'New password') && forgotPassSend ?
                                <div className={'wrapper_forgot_pass_hint'}>
                                    <span>We've sent you an email with a password recovery link. Please open this link to enter new password. <br/>Thank you!</span>
                                </div>
                                : null}
                            {auth_popup === 'Forgot password' || resendTokenButton.show ?
                                <div className={'back_to_sign_in'} onClick={() => {
                                    if (resendTokenButton.show) {
                                        history.push('/')
                                    }
                                    setAuthPopUp('Sign in')
                                    setForgotPassSend(false)
                                }}>
                                    <img src={vector_back} alt="back"/>
                                    <span>Back to Sign In</span>
                                </div>
                                : null}
                            {auth_popup === 'Registration' ?
                                <div className={'switch_auth'}>
                                    <span>Have an account?</span>
                                    <span onClick={() => {
                                        setAuthPopUp('Sign in')
                                    }}>Sign in</span>
                                </div>
                                : null}
                            {auth_popup === 'Sign in' ?
                                <div className={'switch_auth'}>
                                    <span>Don’t have an account?</span>
                                    <span onClick={() => {
                                        setAuthPopUp('Registration')
                                    }}>Register</span>
                                </div>
                                : null}
                        </form>
                    }
                </div>
            </OutsideClickHandler>
        </div>,
        userBlockedPopup.show ?
            <Popup closeCallback={() => setUserBlockedPopup({show: false})}
                   key={'2'}
                   title={'User is blocked'}
                   to_top={true}
                   description={userBlockedPopup.reason}/>
            : null
    ]
}

export default HeaderAuthPopup
