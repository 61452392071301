import React from "react";
import './styles/artist_info_card.css'
import {Link, useHistory} from "react-router-dom";
import defaultAvatar from '../assets/img/new_landing/artist_avatar_default.png'
import Keys from "../Constants/helper";
import API from "../api/API";

export default function ArtistInfoCard(props) {

    const history = useHistory();

    const {
        avatar,
        full_name,
        followed,
        id,
        activeData,
        setActiveData,
        index,
        notRegisteredUser,
        changeContinuePopup,
        role,
        name, setActiveModal, profileFollowArtist, premium,
        profile_url
        // country, verified
    } = props;

    const followArtist = (e) => {
        e.preventDefault();
        if (localStorage.getItem(Keys.JWT_TOKEN)) {
            API().post(`/user/follow?id=${id}`)
                .then(res => {
                    if (res.status === 200) {
                        let followedData = activeData.list[index]
                        followedData.followed = !followedData.followed
                        setActiveData(prevState => ({
                            ...prevState,
                            list: [
                                ...prevState.list.slice(0, index),
                                followedData,
                                ...prevState.list.slice(index + 1),
                            ]
                        }))
                        changeContinuePopup('Artist followed')
                        setTimeout(() => {
                            changeContinuePopup('Artist followed')
                        }, 2000)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            //todo: popup dont show
            changeContinuePopup('Please sign up to view personal pages')
            setTimeout(() => {
                changeContinuePopup('Please sign up to view personal pages')
                history.push('/registration/type')
            }, 2000)
        }
    }

    console.log(premium)
    return (
        <Link onClick={(e) => {
            if (notRegisteredUser) {
                notRegisteredUser(e)
            }
        }} to={`/artist-profile/${profile_url}`} className={'artist_info_card'}>
            <img src={avatar ? avatar : defaultAvatar} alt="avatar"/>
            <div>

                <div className={'popular-section-container'}>
                    <h4>{full_name || name}</h4>
                    {premium &&
                    <svg className={'ver-icon-center'} width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z"
                            fill="#3473FF"/>
                        <path d="M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335" stroke="white" strokeWidth="1.6"
                              strokeLinecap="round"/>
                    </svg>
                    }
                </div>
            </div>

            {role !== 'artist' ?
                <button onClick={(e) => {
                    e.preventDefault();
                    if (profileFollowArtist) {
                        setActiveModal(prevState => ({
                            ...prevState,
                            value: id,
                            role: 'artist',
                            index: index
                        }))
                    } else {
                        followArtist(e)
                    }
                }}
                        className={`follow_button ${followed ? 'follow_button_active' : ''}`}>
                    <span>{followed ? 'followed' : 'follow'}</span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0401 6.6665L16.0156 25.3332" stroke={followed ? '#000' : '#fff'}
                              strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.66797 16H25.3346" stroke={followed ? '#000' : '#fff'}
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button> : null}
        </Link>
    )
}
