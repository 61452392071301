import React from 'react';
import SEO from "../../Components/SEO";

export default function ArtstedFaq () {
    return (
        <div className={'faq_section_body artsted_faq_padding'}>
            <SEO title="What is Artsted?: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ What is Artsted?: Buy art online Artsted"/>
            <h1>What is Artsted?</h1>

            <p> Artsted is a technology company that is working towards the democratization of the art market using
                cutting-edge technology and proprietary algorithms for transparent evaluations. </p>
            <p>Traditionally one of the vaguest issues in the art market is price formation and determining the
                underlying value of a given artwork. To date there has been universal standard or a clear and regulated
                structure related to this matter, since price fixing is a major tool of manipulating profit margins as a
                habitual business practice. </p>
            <p>The Artsted creators recognised an important shift in the attitude towards information transparency as a
                top priority for Millennial and Generation Z collectors over the recent years. The solution the platform
                offers is to use Big Data for valuations, estimations and price predictions. </p>
            <p>Normally it is transactional data from publicly available auction sales. However only an estimated 5% of
                overall transactions in the art market are going through publicly recorded auctions, the rest is done in
                closed ecosystems or through private deals. </p>
            <p>The technology tools employed by the Artsted platform, empower the creators to take control of their
                career progress, as well as provide art collectors with unprecedented freedom to receive instant
                insights on the value of their collection as part of a diversified portfolio.</p>
        </div>
    )
}