import React from "react";
import {Loader} from "../Loader";

const NewStatisticProfileViews = ({data, loading, profile}) => {
    return (
        <div className={'statistic_profile_views'}>
            <div className={'title'}>
                <span>Profile views</span>
            </div>
            <div className={'statistic_views'}>
                <div className={'statistic_title'}>
                    <span>{loading && data.current}</span>
                    <svg width="18" height="37" viewBox="0 0 18 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M0.339844 15L9.0001 -3.78552e-07L17.6604 15L10.5001 15L10.5001 37L7.5001 37L7.5001 15L0.339844 15Z"
                              fill="#fff"/>
                    </svg>
                </div>
                <div className={'statistic_desc'}>
                    <span>
                        {profile ? profile : 'Your'} profile
                        {loading ? data.current === 0 ?
                            ' has no views' :
                            data.current <= 50 ?
                                <>
                                    <span> has {data.current} views over the last week</span>
                                </> :
                                <> is viewed
                                    <b> {data?.percent > 0 ? data?.percent : ~data?.percent + 1}% </b>
                                    {loading && data.percent > 0 ? ' often ' : ' less '}
                                    than others </>
                            : <Loader color={{
                                '--bgColor': '#fff',
                                '--size': '48px',
                                '--wrapper_size': '40px'
                            }}/>
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default NewStatisticProfileViews