import React from 'react'

const TermsWithdrawal = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>5. Withdrawal and Returns</h3>
            <div className={'terms_of_service_item_container'}>
                <span>5.1</span>
                <span>
                    Right of withdrawal: Customers can cancel their order (contract declaration) within 14 days
                    without giving any reason.
                    This can be done in text form (letter, email) or by returning the goods received.
                    The period begins after receipt of this instruction in text form, however not before receipt of
                    the goods by the customer (in the case of the recurring delivery of similar goods not before
                    receipt of the first partial delivery) and also not before fulfilling the information
                    obligations of the Website operator.
                    To meet the withdrawal deadline, it is sufficient to send the withdrawal or ship the goods in
                    good time.
                    <br/>
                    The withdrawal by letter or email must be sent to the Website operator (postal address and email
                    see above).
                    <br/>
                    The return of the goods is to be sent within 7 days of receipt to the sender named on the goods
                    shipment.
                    Important: This can be different depending on the delivery location of the order.
                    <br/>
                    The only exceptions to this are specially marked articles, e.g. promotions
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>5.2</span>
                <span>
                    Consequences of withdrawal: In the event of an effective withdrawal, the services received by
                    both parties must be returned and any benefits (e.g. interest) drawn up must be surrendered.
                    If the customer cannot return the received service or uses (e.g. benefits of use), or return
                    only partially or return only in a deteriorated condition, the customer must compensate the
                    Website operator in this respect.
                    The customer only has to pay compensation for the deterioration of the goods and for the uses
                    made, insofar as the use or the deterioration is due to handling the item that goes beyond
                    checking the properties and functionality.
                    "Checking the properties and functionality" means testing and trying out the respective goods,
                    as is possible and customary in a shop.
                    The goods are to be returned at the customer's own expense and risk. The Website operator under
                    no circumstances accepts returns against cash on delivery.
                    Obligations to reimburse payments must be fulfilled within 30 days.
                    The period begins for the customer when the declaration of cancellation or the goods are sent,
                    for the Website operator when it is received
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>5.3</span>
                <span>
                    In the event of a return, in any case the goods must be sent back new, unused, and in their
                    original condition incl. label(s) and in the original packing.
                </span>
            </div>
        </div>
    )
}

export default TermsWithdrawal