import React, {useEffect, useState} from 'react'
import './styles/list_of_works.css'
import Keys from "../../Constants/helper";
import {Link} from "react-router-dom";
import FiltersWrapper from "../../Components/FilterComponents/FiltersWrapper";
import FilterView from "../../Components/FilterComponents/FilterView";
import TextInput from "../../Components/CustomComponents/TextInput";
import SwitchStatus from "../Components/SwitchStatus";
import artworks_placeholder from "../../assets/img/artworks_placeholder.png";
import {getActivePage} from "../../Constants/getActivePage";
import NewPaginationView from "../../Components/NewPaginationView";
import API from "../../api/API";
//import helper from "../../Constants/helper";
import categoryClose from "../../assets/img/personal_registration/category_close.svg";
import OutsideClickHandler from "../../Constants/detectOutsideClick";

export default function ListOfNfts() {
    const initialState = {
        listing_search: {
            value: ''
        },
        category: {
            activeList: [{name: "nftCategory", category: "Digital"}],
            list: []
        },
        orders: {
            activeList: [],
            list: [{name: 'processed', preview: 'processed'}, {
                name: 'completed',
                preview: 'completed'
            }, {name: 'cancelled', preview: 'cancelled'}],
        },
        styles: {
            activeList: [],
            list: []
        },
        sort_by: {
            activeList: [],
            list: [{name: 'price_asc', preview: 'Price asc'}, {name: 'price_desc', preview: 'Price desc'}],
        },
        materials: {
            activeList: [],
            list: []
        },
        max_price: null,
        price: {
            max: null,
            min: 0
        },
        width: {
            max: 500,
            min: 0
        },
        height: {
            max: 500,
            min: 0
        },
        status: {
            value: window.location.hash?.includes('status') ?
                'orders'
                : 'published'
        }
    }


    let {
        filtersObject,
        showFilterPopup,
        setListingData,
        setActiveFilterFunc,
        loading,
        activeFilter,
        setActiveFilter,
        listingData,
        setFiltersObject,
        activeFilterArray,
        categoryViewSection,
        handleDetectOutSide,
        // sortByCustomHandle,
        filterViewPrice
    } = FiltersWrapper(
        initialState, '/admin/artwork_filters',
        'artworks', '/admin/artworks',
        Keys.JWT_TOKEN_ADMIN, true
    );
    console.log(filtersObject, loading)
    // console.log(listingData)
    const ordersList = ['processed', 'completed', 'cancelled']
    // console.log('filtersObject: ', filtersObject)

    const switchStatusChange = (status) => {
        // console.log(status)
        setFiltersObject(prevState => ({
            ...prevState,
            status: {
                value: status,
            }
        }))
        setListingData(prevState => ({
            ...prevState,
            page: 1
        }))


        getActivePage(1)
    }

    // const downloadTableHandler = () => {
    //     console.log('test')
    //     const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
    //     window.open('https://artsted.movadexdevelopment.com/admin/artworks_to_excel?jwt=' + token)
    // }
    const [testForTest, setTestForTest] = useState({page: 1})
    // const [ordersPagination, setOrdersPagination] = useState({});
    const [orderStatusHandler, setOrderStatusHandler] = useState(false)
    const [orderStatusItem, setOrderStatusItem] = useState('')
    const [listingDataNft, setListingDataNft] = useState({
        artworks: [],
        page: 1,
        pages: 1,
        total: null
    })

    // console.log(orderStatusItem)

    const ordersSelectSection = (page = 1) => {
        // API(Keys.JWT_TOKEN_ADMIN).get(`/admin/nft_orders?page=${listingDataNft.page}&per_page=20&status=${orderStatusItem}`)
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/nft_orders?page=${page}&per_page=10`)
            .then(res => {
                console.log('some resss data: ', res.data)
                if (filtersObject.status?.value === 'orders') {
                    setTimeout(() => {
                        setTestForTest(prevState => {
                            return {
                                ...prevState,
                                data: {
                                    ...res.data
                                },
                                page: 1,
                                pages: res.data.pages,
                            }
                        });
                        setListingDataNft(prevState => ({
                            ...prevState,
                            artworks: res.data.orders,
                            // page: 1,
                            pages: res.data.pages,
                            total: res.data.total
                        }))
                    }, 100);

                } else {
                    setTestForTest(res);
                }
            })
    }


    useEffect(() => {
        ordersSelectSection();
        // eslint-disable-next-line
    }, [filtersObject, loading, orderStatusItem])

    const nftOrderCancel = (item) => {
        // let url = location.pathname.split('/');
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/nft_order/cancel?id=${item}`)
            .then((res) => {
                ordersSelectSection();
            })
    }
    const nftOrderApprove = (item) => {
        // let url = location.pathname.split('/');
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/nft_order/approve?id=${item}`)
            .then((res) => {
                ordersSelectSection();
            })
    }

    const downloadTableHandler = () => {
        // console.log('test')
        const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
        window.open(process.env.REACT_APP_API_URL + `/admin/artworks_to_excel?type=NFT&jwt=` + token)
    }

    const closeChosenOrderFilterItem = () => {
        setOrderStatusItem('')
    }

    const orderStatusShow = () => {
        setOrderStatusHandler(!orderStatusHandler)
    }


    // console.log('orderStatusItem: ', orderStatusItem ? orderStatusItem : 'None inside')
    // console.log('listingData.artworks: ', listingData.artworks)
    // console.log('testForTest.data.orders: ', testForTest.data.orders)
    // console.log('listingData: ', listingData)
    // console.log('listingDataNft: ', listingDataNft)

    console.log('______', testForTest);
    return [
        <div className={'admin-nft-page-content-container admin_content_wrapper admin_artworks_content_wrapper'}
             key={1}>
            <div className={'wrapper_page_head_preview'}>
                <h1 className={'wrapper_title'}>{'NFTs'}</h1>
                <div className={'verified_content'}>
                    <SwitchStatus switchStatus={'orders'}
                                  switchStatusChange={() => switchStatusChange('orders')}
                                  verifiedUser={loading ? filtersObject.status?.value : null}/>
                    <SwitchStatus switchStatus={'published'}
                                  switchStatusChange={() => switchStatusChange('published')}
                                  verifiedUser={loading ? filtersObject.status?.value : null}/>
                    <SwitchStatus switchStatus={'hidden'}
                                  switchStatusChange={() => switchStatusChange('hidden')}
                                  verifiedUser={loading ? filtersObject.status?.value : null}/>
                </div>
            </div>
            <div
                className={loading ? filtersObject.status?.value !== 'orders' ? 'admin_artworks_head admin_artworks_orders_head' : 'admin_artworks_head wrapper_list_filters' : null}>
                <div className={'wrapper_search_input'}>
                    <TextInput type="text"
                               placeholder={'Search'}
                               value={filtersObject.listing_search.value}
                               searchIcon={true}
                               onChange={({target}) => {
                                   setFiltersObject(prevState => ({
                                       ...prevState,
                                       listing_search: {
                                           value: target.value,
                                       }
                                   }))
                                   setListingData(prevState => ({
                                       ...prevState,
                                       page: 1
                                   }))
                               }}/>
                </div>
                {loading ? filtersObject.status?.value !== 'orders' &&
                    <button onClick={downloadTableHandler} className={'download-table-list-of-works'}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15.2438 16.496C15.4338 16.4961 15.6167 16.5683 15.7556 16.698C15.8944 16.8277 15.9788 17.0053 15.9918 17.1949C16.0047 17.3845 15.9453 17.5719 15.8253 17.7193C15.7054 17.8668 15.534 17.9631 15.3458 17.989L15.2438 17.996H4.74977C4.55975 17.996 4.37683 17.9238 4.23798 17.7941C4.09913 17.6643 4.0147 17.4867 4.00175 17.2972C3.98879 17.1076 4.04829 16.9202 4.1682 16.7727C4.28812 16.6253 4.45952 16.5289 4.64777 16.503L4.74977 16.496H15.2438ZM10.0038 1.99905C10.1848 1.9993 10.3597 2.06504 10.4961 2.18414C10.6325 2.30324 10.7211 2.46766 10.7458 2.64705L10.7528 2.74905V12.942L13.7188 9.97205C13.8456 9.84488 14.0139 9.76747 14.193 9.75389C14.3721 9.74031 14.5502 9.79146 14.6948 9.89805L14.7798 9.97005C14.9071 10.0971 14.9844 10.2656 14.9978 10.445C15.0112 10.6243 14.9598 10.8025 14.8528 10.947L14.7808 11.031L10.5378 15.281L10.4678 15.344L10.3758 15.403L10.3398 15.424L10.2488 15.462L10.1288 15.492L10.0588 15.5L9.99877 15.502C9.94802 15.502 9.89741 15.4966 9.84777 15.486L9.76777 15.463C9.67272 15.4325 9.58493 15.3828 9.50977 15.317L5.21977 11.032C5.08523 10.8984 5.00632 10.7187 4.99893 10.5292C4.99153 10.3397 5.05619 10.1545 5.1799 10.0107C5.30361 9.86699 5.47718 9.77545 5.66567 9.75455C5.85416 9.73364 6.04357 9.78491 6.19577 9.89805L6.27977 9.97105L9.25277 12.938V2.74805C9.25277 2.54913 9.33179 2.35837 9.47244 2.21772C9.61309 2.07706 9.80386 1.99805 10.0028 1.99805L10.0038 1.99905Z"
                                fill="black"/>
                        </svg>
                        Download Table
                    </button>
                    : null}
                {loading ? filtersObject.status?.value === 'orders' ?
                    <div className={'wrapper_list_filters wrapper-list-filters-nft'}>
                        {filtersObject.loading && filtersObject.styles.list?.length ?
                            // <FilterView category={'status'} /*order*/
                            //             handleDetectOutSide={handleDetectOutSide}
                            //             activeFilter={activeFilter}
                            //             filterList={filtersObject.orders.list} /*order*/
                            //             setListingData={e => setListingData(e)}
                            //             setActiveFilterFunc={setActiveFilterFunc}
                            //             setActiveFilter={e => setActiveFilter(e)}
                            //             showFilterPopup={e => showFilterPopup(e)}/>
                            // : null}
                            <div className={'nft-orders-filter-container-content'}>
                                <div onClick={orderStatusShow} className={'nft-orders-filter-container'}>
                                    <span>Order status</span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 6.66656L11.06 5.72656L8 8.7799L4.94 5.72656L4 6.66656L8 10.6666L12 6.66656Z"
                                            fill="#B6B6B6"/>
                                    </svg>
                                </div>
                                {orderStatusHandler &&
                                <OutsideClickHandler callback={() => setOrderStatusHandler(!orderStatusHandler)}>
                                    <div className={'nft-orders-filter-items'}>
                                        {ordersList.map((item, index) => (
                                            <div onClick={() => {
                                                setOrderStatusItem(item);
                                                setOrderStatusHandler(!orderStatusHandler)
                                            }} key={index}>{item}</div>
                                        ))}
                                    </div>
                                </OutsideClickHandler>

                                }
                            </div> : null}
                    </div>
                    : <div className={'wrapper_list_filters wrapper-list-filters-nft'}>
                        {filtersObject.loading && filtersObject.styles.list?.length ?
                            <FilterView category={'styles'}
                                        handleDetectOutSide={handleDetectOutSide}
                                        activeFilter={activeFilter}
                                        filterList={filtersObject.styles.list}
                                        setListingData={e => setListingData(e)}
                                        setActiveFilterFunc={setActiveFilterFunc}
                                        setActiveFilter={e => setActiveFilter(e)}
                                        showFilterPopup={e => showFilterPopup(e)}/>
                            : null}
                        {filterViewPrice()}
                    </div>
                    : null}
            </div>
            {loading ? filtersObject.status?.value === 'orders' && orderStatusItem &&
                <div className={'chosen-order-nft'}>
                    <span>{orderStatusItem}</span>
                    <img onClick={closeChosenOrderFilterItem} src={categoryClose} alt="close icon"/>
                </div>
                : null}
            {activeFilterArray.length ?
                categoryViewSection('gray')
                : null}
            <div className={loading ? filtersObject.status?.value !== 'orders' ? 'admin_artworks_container' : '' : ''}>
                {loading ? filtersObject.status?.value !== 'orders' ? loading ? listingData.artworks.length
                    ? listingData.artworks.map((item) => {
                        return item.category === 'NFT' && (
                            <Link to={`/admin/artworks/${item.id}`} className={'admin_artworks_container_card'}
                                  key={item.id}>
                                {/*{console.log('listingData.artworks: ', item.category)}*/}
                                <div className={'image'}>
                                    <img src={item.picture}
                                         onError={e => {
                                             e.target.src = artworks_placeholder
                                         }}
                                         alt="preview"/>
                                </div>
                                <div className={'nfts-img-info-container'}>
                                    <p className={'nfts-author_name'}>{item.author_name}</p>
                                    <h4 className={'nfts-title-and-year'}>{item.title}, {item.year}</h4>
                                    <h5 className={'nfts-price'}>ETH{item.ethereum_price}{` ($${item.price})`}</h5>
                                </div>
                            </Link>
                        )
                    })
                    : <span></span>
                    : null
                    // : <div className={'item item-nft-orders'} /*key={item.id}*/>
                    //     <div className={'item_content'}>
                    //         <span>{/*{item.registration_time}*/} AAA</span>
                    //         <span>{/*{item.name}*/}BBB</span>
                    //     </div>
                    //     <div
                    //         style={{gridTemplateColumns: 'repeat(2, max-content)'}}
                    //         className={'users_list_buttons'}>
                    //         {filtersObject.status?.value === 'orders'
                    //             && <>
                    //                 <button className={'admin_bordered_nft_button_block'}>Block</button>
                    //                 <button className={'admin_bordered_nft_button'}>Verify</button>
                    //                 {/*<Link to={`/admin/${pageTitle.toLowerCase()}/${item.id}`}>*/}
                    //                 {/*    Details*/}
                    //                 {/*</Link>*/}
                    //             </>}
                    //     </div>
                    // </div>
                    :
                    <div className={'wrapper_table'}>
                        <div className={'admin-nfts-container'}>
                            <div className={'admin-nfts-section-name'}>
                                <span className={'admin-nfts-section-text'}>Order №</span>
                                <span className={'admin-nfts-section-text'}>Date</span>
                                <span className={'admin-nfts-section-text'}>Investor name</span>
                                <span className={'admin-nfts-section-text'}>Price</span>
                            </div>
                            <div>

                            </div>
                        </div>
                        {loading ? testForTest.data.orders.map((item) => (
                            <div className={'orders-items-container'} key={item.id}>
                                <div className={'admin-nfts-section-name'}>
                                    <div>
                                        <span>{item.id}</span>
                                    </div>
                                    <div>
                                        <span>{item.date}</span>
                                    </div>
                                    <div>
                                        <span>{item.investor}</span>
                                    </div>
                                    <div>
                                        <span>ETH {item?.ether_price ? item.ether_price : 'None'}</span>
                                    </div>
                                </div>
                                <div className={'orders-buttons-container'}>
                                    <button onClick={() => nftOrderCancel(item.id)}
                                            className={item.order_status === 'cancelled' || item.order_status === 'completed' ? 'admin_bordered_nft_button_block_canceled admin_bordered_nft_button_block' : 'admin_bordered_nft_button_block'}>{item.order_status === 'cancelled' ? 'Canceled' : 'Cancel'}</button>
                                    <button onClick={() => nftOrderApprove(item.id)}
                                            className={item.order_status === 'cancelled' || item.order_status === 'completed' ? 'admin_bordered_nft_button_block_canceled admin_bordered_nft_button' : 'admin_bordered_nft_button'}>{item.order_status === 'completed' ? 'Approved' : 'Approve'}</button>
                                    <Link to={`/admin/nfts/${item.id}`}>
                                        <button className={'admin_bordered_nft_button'}>Details</button>
                                    </Link>
                                </div>
                            </div>
                        )) : null}
                    </div>
                    : null}
            </div>
        </div>,
        loading && listingData.total > 9 ?
            <div className={'wrapper_new_pagination admin_pagination'} key={2}>
                {filtersObject.status?.value !== 'orders' ?
                    <NewPaginationView activePage={listingData}
                                       onChange={pageNumber => {
                                           getActivePage(pageNumber, listingData)
                                           setListingData(prevState => ({
                                               ...prevState,
                                               page: pageNumber
                                           }))
                                       }}/>
                    :
                    <NewPaginationView activePage={listingDataNft}
                                       onChange={pageNumber => {
                                           ordersSelectSection(pageNumber)
                                           getActivePage(pageNumber, listingData)
                                           setListingDataNft(prevState => ({
                                               ...prevState,
                                               page: pageNumber
                                           }))
                                       }}/>
                }

            </div>
            : null
    ]
}
