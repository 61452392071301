import React, {useEffect, useCallback, useState} from 'react'
import API from "../../api/API";
import './styles/artworks_item.css'
import {Link, useLocation} from "react-router-dom";
import Keys from "../../Constants/helper";
import ModalWindow from "../../Components/ModalWindow";
import TextBox from "../../Components/CustomComponents/TextBox";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import close_modal from "../../assets/img/admin/close_modal.svg";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import MainButton from "../../Components/CustomComponents/MainButton";
import Viewer from 'react-viewer';
import {bodyOverflow} from "../../Constants/bodyOverflow";
import userPhoto from "../../assets/img/avatar.png";

let initialModalState = {
    name: null,
    show: null,
    reason: '',
    error: null
};

export default function AdminArtworksItem() {
    let location = useLocation();
    const [imgPopup, showImgPopup] = useState(false)
    const [activeData, setActiveData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [activeModal, setActiveModal] = useState(initialModalState)
    const [showMainFile, setShowMainFile] = useState(false)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const downloadNftAdditionalFile = () => {
        // const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
        window.open(activeData?.artwork?.additional_file)
    }

    const downloadNftMainFile = () => {
        window.open(activeData?.artwork?.main_file)
    }

    useEffect(() => {
        const publicArtworkUrl = location.pathname.split('/')[3];

        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/artwork?id=${publicArtworkUrl}`)
            .then(res => {
                // console.log(res.data)
                setActiveData(res.data)
                setLoading(true)
            })
            .catch(err => {
                console.log(err)
            })
    }, [location.pathname])

    const eventArtworks = actValue => {
        if (actValue !== 'publish') {
            setActiveModal(prevState => ({
                ...prevState,
                name: actValue,
                show: true
            }))
        } else {
            request(actValue)
        }
    }

    const request = (actValue, reason = null) => {
        if (reason) {
            if (activeModal.reason.length) {
                API(Keys.JWT_TOKEN_ADMIN).post(`/admin/artwork_event?id=${activeData.artwork.id}&act=${actValue}&reason=${activeModal.reason}`)
                    .then(() => {
                        setActiveModal(initialModalState)
                        changeContinuePopup(`Artwork is ${actValue}`)
                        setTimeout(() => {
                            changeContinuePopup(`Artwork is ${actValue}`)
                            window.location.pathname = '/admin/artworks'
                        }, 1000)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                setActiveModal(prevState => ({
                    ...prevState,
                    error: true
                }))
            }
        } else {
            API(Keys.JWT_TOKEN_ADMIN).post(`/admin/artwork_event?id=${activeData.artwork.id}&act=${actValue}`)
                .then(() => {
                    changeContinuePopup(`Artwork is ${actValue}`)
                    setTimeout(() => {
                        changeContinuePopup(`Artwork is ${actValue}`)
                        window.location.pathname = '/admin/artworks'
                    }, 1000)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    const onShowImg = () => {
        bodyOverflow(true)
        showImgPopup(true)
    }
    const imgListViewer = useCallback(() => {
        if (loading) {
            return [{src: activeData.artwork.pictures[0], alt: 'sfdf'}]
        }
    }, [activeData?.artwork?.pictures, loading])

    console.log('activeData.artwork: ', activeData?.artwork?.hided)

    return (
        <div className={'admin_content_wrapper admin_artwork_item_content_wrapper'}>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>

            <div className={'navigation'}>
                <Link to={`/admin/artworks`}>Artworks</Link>
                <span>/</span>
                <span>Detailed</span>
            </div>
            <div className={'about'}>
                <div onClick={onShowImg} className={'artwork_image'}>
                    <img src={loading ? activeData.artwork.pictures[0] : null} alt="preview"/>
                </div>
                <Viewer
                    visible={imgPopup}
                    zIndex={100000}
                    onClose={() => showImgPopup(false)}
                    images={imgListViewer()}

                />
                <div className={'user'}>
                    <div className={'about_user'}>
                        <div className={'user_avatar'}>
                            <img src={loading ? activeData.user.avatar ?? userPhoto : null} alt="avatar"/>
                        </div>
                        <div className={'user_name'}>
                            <h3>{loading ? activeData.user.name : null}</h3>
                            <span>{loading ? activeData.user.country : null}</span>
                        </div>
                    </div>
                    <p style={activeData?.artwork?.last_title ? {margin: '0'} : {margin: '0 0 1rem'}}>{loading ? activeData.artwork.title : null}{/*, {loading ? activeData.artwork.year : null}*/}</p>
                    {loading && activeData.artwork.last_title ?
                        <h5 style={{margin: '0 0 1rem'}}>Before
                            modification: {activeData.artwork.last_title}</h5> : null}
                    {activeData?.artwork.category === 'NFT' && <div></div>}
                        <h4 style={activeData?.artwork?.last_price ? {margin: '0'} : {margin: '0 0 2.75rem'}}>{activeData?.artwork.category === 'NFT' ? 'ETH ' : '€'}
                            {loading ? activeData?.artwork.category === 'NFT' ? activeData.artwork.ether_price : activeData.artwork.price : null}
                            {activeData?.artwork.category === 'NFT' && ` ($  ${activeData.artwork.price} )`}</h4>
                    {loading && activeData.artwork.last_price ?
                        <h5 style={{margin: '0 0 1rem'}}>Before
                            modification: {activeData.artwork.last_price}</h5> : null}
                    <div className={'buttons_container'}>
                        {loading ? [
                            /*<MainButton onClick={() => {
                                if (activeData.artwork.hidden) {
                                    eventArtworks('publish')
                                }
                            }}
                                        key={1}
                                        style={{background: '#000', color: '#fff'}}
                                        className={!activeData.artwork.hidden ? 'button_black' : null}
                                        text={!activeData.artwork.hidden ? 'Published' : 'Publish'}/>*/
                            <MainButton onClick={() => {
                                if (!activeData.artwork.hided) {
                                    eventArtworks('hide')
                                } else if (activeData.artwork.hided) {
                                    eventArtworks('publish')
                                }
                            }}
                                        key={2}
                                        style={activeData.artwork.hidden ? {background: '#000', color: '#fff'} : null}
                                        className={'button_black'}
                                        text={activeData.artwork.hided ? 'Publish' : 'Hide'}/>,
                            <MainButton onClick={() => eventArtworks('remove')}
                                        key={3}
                                        className={'button_black'}
                                        text={'Remove'}/>
                        ] : null}
                    </div>
                </div>
            </div>
            <div className={'content'}>
                {loading && activeData.artwork.last_description ?
                    <h5 style={{margin: '0 0 2rem'}}>Before modification:
                        ({activeData.artwork.last_description})</h5> : null}
                <div className={'features content-section-container'}>
                    <div>
                        {loading && <Viewer
                            visible={showMainFile}
                            zIndex={3600}
                            scalable={null}
                            showTotal={null}
                            attribute={null}
                            onMaskClick={() => setShowMainFile(false)}
                            onClose={() => setShowMainFile(false)}
                            images={[{
                                src: activeData?.artwork.main_file,
                                alt: 'preview_img'
                            }]}
                        />}

                        {loading && activeData?.artwork.main_file && ['jpeg', 'png', 'jpg'].includes(activeData?.artwork.main_file_name.split('.').pop()) &&
                        <MainButton
                            style={{marginBottom: '24px', width: 'max-content'}}
                            text={"Preview main file"}
                            onClick={() => setShowMainFile(true)}/>
                        }

                        {loading && activeData.artwork.year &&
                        <div className={'content-item-list-container'}>
                            <h6>Year</h6>
                            <span>{loading ? activeData.artwork.year ? activeData.artwork.year : 'None' : null}</span>
                        </div>}

                        {loading && activeData?.artwork.category !== 'NFT' && activeData.artwork.category &&
                        <div className={'content-item-list-container'}>
                            <h6>Category</h6>
                            <span>{loading ? activeData.artwork.category ? activeData.artwork.category : null : null}</span>
                        </div>}
                        {loading && !!activeData.artwork.styles.length &&
                        <div className={'content-item-list-container'}>
                            <h6>Style of art</h6>
                            {loading ? activeData.artwork.styles.length ? activeData.artwork.styles.map((item, index) => (
                                <span key={index}>{item}{index !== activeData.artwork.styles.length - 1 ? ', ' : ''}</span>
                            )) : null : null}
                        </div>}
                        {activeData?.artwork.category === 'NFT' &&
                        <div>
                            {loading && activeData.artwork.main_file_name &&
                            <div className={'content-item-list-container'}>
                                {console.log('activeData.artwork: ', activeData.artwork)}
                                <h6>Main file</h6>
                                <span className="additional-file-info" onClick={downloadNftMainFile}>{loading ? activeData.artwork.main_file_name ? activeData.artwork.main_file_name : 'None' : null}</span>
                            </div>}
                            {loading && activeData.artwork.additional_file_name &&
                            <div className={'content-item-list-container'}>
                                <h6>Additional file</h6>
                                <span className={'additional-file-info'} onClick={downloadNftAdditionalFile}>{loading ? activeData.artwork.additional_file_name ? activeData.artwork.additional_file_name : 'None' : null}</span>
                            </div>}
                            {loading && activeData.artwork.ethereum_address &&
                            <div className={'content-item-list-container'}>
                                <h6>Ethereum address</h6>
                                <span>{loading ? activeData.artwork.ethereum_address ? activeData.artwork.ethereum_address : 'None' : null}</span>
                            </div>}
                        </div>
                        }
                        {activeData?.artwork.category !== 'NFT' &&
                        <div>
                            {loading && !!activeData.artwork.materials.length &&
                            <div className={'content-item-list-container'}>
                                <h6>Materials</h6>
                                {loading ? activeData.artwork.materials.length ? activeData.artwork.materials.map((item, index) => (
                                    <span key={index}>{item}{index !== activeData.artwork.materials.length - 1 ? ', ' : ''}</span>
                                )) : <span>Temp material</span> : null}
                            </div>}

                            {loading && !!activeData.artwork.exhibitions.links?.length &&
                            <div className={'content-item-list-container'}>
                                <h6>Exhibitions</h6>
                                <span>{loading ? activeData.artwork.exhibitions.description : null}</span>
                                {loading ? activeData.artwork.exhibitions.links?.length ? activeData.artwork.exhibitions.links.map((item, index) => (
                                    <a key={index} href={item} target={'_blank'}
                                       rel="noopener noreferrer nofollow">{item}{index !== activeData.artwork.exhibitions.links.length - 1 ? ', ' : ''}</a>
                                )) : null : null}
                            </div>}
                            {loading && !!activeData.artwork.publication.links?.length &&
                            <div className={'content-item-list-container'}>
                                <h6>Publications</h6>
                                <span>{loading ? activeData.artwork.publication.description : null}</span>
                                {loading ? activeData.artwork.publication.links?.length ? activeData.artwork.publication.links.map((item, index) => (
                                    <a key={index} href={item} target={'_blank'}
                                       rel="noopener noreferrer nofollow">{item}{index !== activeData.artwork.publication.links.length - 1 ? ', ' : ''}</a>
                                )) : null : null}
                            </div>}
                        </div>
                        }
                    </div>
                </div>
                <h2 className={'item-title-section-style'}>Description</h2>
                <p style={activeData?.artwork?.last_description ? {margin: '0 0 .5rem'} : {margin: '0 0 2rem'}}>
                    {loading ?
                        activeData.artwork.description ?
                            activeData.artwork.description :
                            'Dolor Lorem adipisicing mollit pariatur eiusmod incididunt dolore. Minim nulla duis exercitation nostrud voluptate. Dolore duis dolor enim incididunt tempor aliquip dolore cupidatat minim dolore do tempor. Sint cillum elit id velit. Cillum sint mollit quis ullamco Lorem officia reprehenderit et. Anim et velit sint consequat deserunt dolor sit minim aute ex veniam. Ea eiusmod minim aliquip quis officia dolor excepteur.'
                        : null}
                </p>
            </div>
            {activeModal.show ?
                <div className={'admin_wrapper_modal'}>
                    <ModalWindow activeModal={activeModal}
                                 setActiveModal={() => setActiveModal(initialModalState)}>
                        <img onClick={() => {
                            setActiveModal(initialModalState)
                        }} src={close_modal} alt="close modal"/>
                        <h4>{loading ? activeData.artwork.category : null}, {loading ? activeData.artwork.year : null}</h4>
                        <div className={'admin_wrapper_modal_line'}/>
                        <p style={{margin: '0 0 1rem'}}>
                            {`Are you sure you want to ${activeModal.name} this artwork?`}
                        </p>
                        <TextBox value={activeModal.reason}
                                 rows={'3'}
                                 onChange={({target}) => {
                                     setActiveModal(prevState => ({
                                         ...prevState,
                                         reason: target.value,
                                         error: false
                                     }))
                                 }}
                                 error={activeModal.error}
                                 errTitle={"This field can't be empty"}
                                 placeholder={`The reason for ${activeModal.name}`}/>
                        <button onClick={() => request(activeModal.name, true)}>
                            {capitalizeFirstLetter(activeModal.name)}
                        </button>
                    </ModalWindow>
                </div> : null}
        </div>
    )
}
