import React, {useState} from 'react';
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import userPhoto from "../../assets/img/new_landing/profile_avatar_default.svg";
import useResize from "../../Constants/useResize";
import MainButton from "../CustomComponents/MainButton";
import ModalWindow from "../ModalWindow";
import {Link} from "react-router-dom";
import {bodyOverflow} from "../../Constants/bodyOverflow";

const ArtworkShippingDetails = ({previewMessage, role, handleSubmitMessage, order_status}) => {
    const [showInfo, setShowInfo] = useState({shipping: true, order: true});
    const [cancelOrder, setCancelOrder] = useState(null);
    const {innerWidth} = useResize();

    const cancelOrderRequest = () => {
        handleSubmitMessage(null, '', 'cancel')
        setCancelOrder('finish')
    }

    return (
        <div className="wrapper_artwork_details every_inbox_message">
            <div className="avatar_wrapper">
                {/*{previewMessage.artwork.avatar ?*/}
                <img src={userPhoto} alt="avatar"/>
                {/*: null}*/}
            </div>
            {cancelOrder === 'popup' ?
                <div key={3} className={'new_modal_wrapper'}>
                    <ModalWindow setActiveModal={() => setCancelOrder(null)}>
                        <div className={'title'}>
                            <h4>Cancel order</h4>
                        </div>
                        <p>Are you sure you want to cancel this order?</p>
                        <div className={'modal-window_buttons'}>
                            <MainButton type={'button'}
                                        style={{background: '#000', color: '#fff'}}
                                        className={'button_black'}
                                        text={'Cancel'}
                                        onClick={() => {
                                            setCancelOrder(null)
                                            bodyOverflow(false)
                                        }}
                            />
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Delete'}
                                        onClick={cancelOrderRequest}
                            />
                        </div>
                    </ModalWindow>
                </div>
                : null}
            <div className="wrapper_sender_info">
                <div className={'sender_name'}>
                    <span>{'Artsted assist'}</span>
                </div>
                <div className={'sender_message'}>
                    <span>{'Hello, your purchase was successful. You can find all the necessary information about the purchase and delivery below. Please wait shipping confirmation from the seller. It usually takes 1-2 days.'}</span>
                </div>
                <div className="wrapper_order_details">
                    <div className={'section_title'}
                         onClick={() => {
                             if (innerWidth > 768) return null
                             setShowInfo(prevState => ({...prevState, order: !prevState.order}))
                         }}>
                        <span>Order details</span>
                        {innerWidth <= 768 ?
                            <svg width="16" height="10"
                                 className={`${showInfo.order ? 'active' : ''}`}
                                 viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z" fill="black"/>
                            </svg>
                            : null}
                    </div>
                    {innerWidth > 768 || (showInfo.order && innerWidth <= 768) ? [
                        <div className={'head'} key={1}>
                            <span className={'order'}>Order №{previewMessage.id}</span>
                            {cancelOrder !== 'finish' && role === 'artist' && order_status === 'processed' ?
                                <button className={'cancel_order'}
                                        onClick={() => setCancelOrder('popup')}>
                                    Cancel order
                                </button>
                                : null}
                            {role === 'artist' && previewMessage.tracing_id ?
                                <span className={'tracking'}
                                      style={
                                          cancelOrder !== 'finish' && role === 'artist' && order_status === 'processed' ?
                                              null : {justifySelf: 'end'}}
                                >Tracking №{previewMessage.tracing_id}</span>
                                : null}
                            <div className={`status status_${previewMessage.status.toLowerCase()}`}
                            >
                                <span>Status: </span>
                                <span>{capitalizeFirstLetter(previewMessage.status)}</span>
                            </div>
                            <span className={previewMessage.delivery === 'premium' ?
                                'delivery_my_sales_active delivery_my_sales' :
                                'delivery_my_sales'}
                                  style={role !== 'artist' ? null :
                                      cancelOrder !== 'finish' && order_status === 'processed' ?
                                          null : {justifySelf: 'end'}}>
            {previewMessage.delivery === 'premium' ?
                <svg xmlns="http://www.w3.org/2000/svg" id="noun_Star_1515310"
                     width="16" height="16"
                     viewBox="0 0 16.809 16.127">
                    <path id="Path_12782"
                          fill={'#bc4386'}
                          d="M26.123 33.087a1.569 1.569 0 0 0 .4 1.622l2.808 2.745-.663 3.861a1.583 1.583 0 0 0 2.3 1.671l3.489-1.818L37.935 43a1.583 1.583 0 0 0 2.3-1.671l-.663-3.861 2.808-2.745a1.583 1.583 0 0 0-.878-2.7l-3.886-.579-1.755-3.51a1.583 1.583 0 0 0-2.836 0l-1.727 3.51-3.898.566a1.569 1.569 0 0 0-1.277 1.077zm1.334.432a.172.172 0 0 1 .143-.119l4.262-.618a.7.7 0 0 0 .527-.386l1.9-3.861c.084-.165.239-.165.323 0l1.9 3.861a.7.7 0 0 0 .527.386l4.262.618a.172.172 0 0 1 .144.119.168.168 0 0 1-.046.183l-3.089 3a.7.7 0 0 0-.2.621l.737 4.24a.172.172 0 0 1-.074.176.168.168 0 0 1-.186 0l-3.809-1.99a.7.7 0 0 0-.653 0l-3.809 2a.168.168 0 0 1-.186 0 .172.172 0 0 1-.074-.176l.73-4.24a.7.7 0 0 0-.2-.635l-3.089-3a.168.168 0 0 1-.046-.186z"
                          className="cls-1" data-name="Path 12782"
                          transform="translate(-26.044 -27.055)"/>
                </svg>
                : null}
                                {previewMessage?.artwork?.category !== 'NFT' ? previewMessage.delivery === 'premium' ?
                                    "Premium Delivery"
                                    : 'Standart Delivery' : null}
                </span>
                            <span className={'price'}>{previewMessage?.artwork.category === 'NFT' ? 'ETH ' + previewMessage.artwork.ether_price : '€' + previewMessage.artwork.price}</span>
                            {role !== 'artist' && previewMessage?.artwork?.category !== 'NFT' ?
                                <span className={'tracking'}>Tracking №{previewMessage.tracing_id}</span>
                                : null}
                        </div>,
                        <div className={'body'} key={2}>
                            <Link to={`/public-artwork/${previewMessage.artwork.id}`} className="wrapper_picture">
                                <img src={previewMessage.artwork.picture} alt="artworkPicture"/>
                            </Link>
                            <div className={'wrapper_info'}>
                                <div className={'info_title'}>
                                    <span className={'title'}>Artist Name</span>
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'title'}>Date</span>
                                    }
                                    <span className={'title'}>Title</span>
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'title'}>Image size</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'title'}>Year of origination</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'title'}>Category</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'title'}>Style of art</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'title'}>Ethereum address</span>
                                    }
                                </div>
                                <div className={'info_desc'}>
                                    <span className={'desc'}>{previewMessage.artwork.author_name}</span>
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'desc'}>{previewMessage.artwork.date}</span>
                                    }
                                    <span className={'desc'}>{previewMessage.artwork.title}</span>
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'desc'}>{previewMessage.artwork.size}</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' ? null :
                                        <span className={'desc'}>{previewMessage.artwork.year_of_origination}</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'desc'}>{previewMessage.artwork.category ? previewMessage.artwork.category : 'None'}</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'desc'}>{previewMessage.artwork.styles[0] ? previewMessage.artwork.styles[0] : 'None'}</span>
                                    }
                                    {previewMessage.artwork.category === 'NFT' &&
                                        <span className={'desc'}>{previewMessage.artwork.ethereum_address ? previewMessage.artwork.ethereum_address : 'None'}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    ] : null}
                    {previewMessage.artwork.category !== 'NFT' &&
                    <div className={'section_title section_title_shipping'}
                         onClick={() => {
                             if (innerWidth > 768) return null
                             setShowInfo(prevState => ({...prevState, shipping: !prevState.shipping}))
                         }}>
                        <span>Shipping details</span>
                        {innerWidth <= 768 ?
                            <svg width="16" height="10"
                                 className={`${showInfo.shipping ? 'active' : ''}`}
                                 viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z" fill="black"/>
                            </svg>
                            : null}
                    </div>
                    }

                    {previewMessage?.artwork?.category !== 'NFT'
                    // eslint-disable-next-line
                    && innerWidth > 768 || (showInfo.shipping && innerWidth <= 768) ?
                        <div className={'shipping_details'}>
                            {innerWidth >= 992 ? [
                                    <div className={'wrapper_info'} key={1}>
                                        <div className={'info_title'}>
                                            <span className={'title'}>Customer Name</span>
                                            <span className={'title'}>Customer Surname</span>
                                            <span className={'title'}>Phone</span>
                                        </div>
                                        <div className="info_desc">
                                            <span className={'desc'}>{previewMessage.shipping_details.customer_name}</span>
                                            <span
                                                className={'desc'}>{previewMessage.shipping_details.customer_surname}</span>
                                            <span className={'desc'}>{previewMessage.shipping_details.phone}</span>
                                        </div>
                                    </div>,
                                    <div className={'wrapper_info'} key={2}>
                                        <div className={'info_title'}>
                                            <span className={'title'}>Country</span>
                                            <span className={'title'}>Address and Postcode</span>
                                            <span className={'title'}>City</span>
                                        </div>
                                        <div className="info_desc">
                                    <span className={'desc'}>
            {previewMessage.shipping_details.country?.length > 15 ?
                `${previewMessage.shipping_details.country.substring(0, 15)}...` :
                previewMessage.shipping_details.country}</span>
                                            <span className={'desc'}>
                                        {previewMessage.shipping_details.address_and_postcode}
                                    </span>
                                            <span className={'desc'}>{previewMessage.shipping_details.city}</span>
                                        </div>
                                    </div>
                                ] :
                                <div className={'wrapper_info'}>
                                    <div className={'info_title'}>
                                        <span className={'title'}>Customer Name</span>
                                        <span className={'title'}>Customer Surname</span>
                                        <span className={'title'}>Phone</span>
                                        <span className={'title'}>Country</span>
                                        <span className={'title'}>Address and Postcode</span>
                                        <span className={'title'}>City</span>
                                    </div>
                                    <div className="info_desc">
                                        <span className={'desc'}>{previewMessage.shipping_details.customer_name}</span>
                                        <span
                                            className={'desc'}>{previewMessage.shipping_details.customer_surname}</span>
                                        <span className={'desc'}>{previewMessage.shipping_details.phone}</span>
                                        <span className={'desc'}>
                            {previewMessage.shipping_details.country?.length > 15 ?
                                `${previewMessage.shipping_details.country.substring(0, 15)}...` :
                                previewMessage.shipping_details.country}</span>
                                        <span className={'desc'}>
                            {previewMessage.shipping_details.address_and_postcode}
                                </span>
                                        <span className={'desc'}>{previewMessage.shipping_details.city}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        : null}
                    {/*{previewMessage.certificate ?*/}
                    {/*    <a href={previewMessage.certificate} className={'certificate'}>*/}
                    {/*        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path*/}
                    {/*                d="M7.46995 12.0303C7.53959 12.1 7.62227 12.1552 7.71327 12.1929C7.80427 12.2306 7.9018 12.25 8.00029 12.25C8.09879 12.25 8.19632 12.2306 8.28731 12.1929C8.37831 12.1552 8.46099 12.1 8.53064 12.0303L11.359 9.20191C11.4997 9.06125 11.5787 8.87049 11.5787 8.67158C11.5787 8.47267 11.4997 8.2819 11.359 8.14125C11.2184 8.0006 11.0276 7.92158 10.8287 7.92158C10.6298 7.92158 10.439 8.0006 10.2984 8.14125L8.75029 9.68934V1.5C8.75029 1.30109 8.67127 1.11032 8.53062 0.96967C8.38997 0.829018 8.19921 0.75 8.00029 0.75C7.80138 0.75 7.61061 0.829018 7.46996 0.96967C7.32931 1.11032 7.25029 1.30109 7.25029 1.5V9.68934L5.7022 8.14125C5.56155 8.0006 5.37078 7.92158 5.17187 7.92158C4.97296 7.92158 4.7822 8.0006 4.64154 8.14125C4.50089 8.2819 4.42188 8.47267 4.42188 8.67158C4.42188 8.87049 4.50089 9.06125 4.64154 9.20191L7.46995 12.0303Z"*/}
                    {/*                fill="#bc4386"/>*/}
                    {/*            <path*/}
                    {/*                d="M14.5 7.25C14.3011 7.25 14.1103 7.32902 13.9697 7.46967C13.829 7.61032 13.75 7.80109 13.75 8V13.75H2.25V8C2.25 7.80109 2.17098 7.61032 2.03033 7.46967C1.88968 7.32902 1.69891 7.25 1.5 7.25C1.30109 7.25 1.11032 7.32902 0.96967 7.46967C0.829018 7.61032 0.75 7.80109 0.75 8V14C0.75 14.3315 0.881696 14.6495 1.11612 14.8839C1.35054 15.1183 1.66848 15.25 2 15.25H14C14.3315 15.25 14.6495 15.1183 14.8839 14.8839C15.1183 14.6495 15.25 14.3315 15.25 14V8C15.25 7.80109 15.171 7.61032 15.0303 7.46967C14.8897 7.32902 14.6989 7.25 14.5 7.25Z"*/}
                    {/*                fill="#bc4386"/>*/}
                    {/*        </svg>*/}
                    {/*        <span>Download Authenticity certificate</span>*/}
                    {/*    </a>*/}
                    {/*    : null}*/}
                    {/*<div className="start_shipping" onClick={() => {*/}
                    {/*    handleSubmitMessage(null, '', 'start')*/}
                    {/*    // setCancelOrder(false)*/}
                    {/*}}>*/}
                    {/*    <span>Start Shipping</span>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="wrapper_date">
                <span>{previewMessage.date}</span>
                <span>{previewMessage.time}</span>
            </div>
        </div>
    )
}
export default ArtworkShippingDetails
