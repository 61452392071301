import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import '../styles/new_landing.css'
import sliderImage from '../../assets/img/new_landing/header_slider.jpg'
import artistPhoto from '../../assets/img/new_landing/artist_photo.png'
import artist_avatar_default from "../../assets/img/new_landing/profile_avatar_default.svg";
import API from "../../api/API";
import useResize from "../../Constants/useResize";
import {Loader} from "../../Components/Loader";
import '../styles/landing.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const headSectionSettings = {
    infinite: true,
    speed: 500,
    arrows: false,
    dots: true,
    swipeToSlide: false,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
}

const initialHeadSlide = {
    id: 1,
    photo: sliderImage,
    user: {
        avatar: artistPhoto,
        name: 'Ekaterina Belukhina',
        price: '1100',
        date: '01.05.2021',
        id: 12,
    }
};

function MainSlider() {
    const [loading, setLoading] = useState(false);
    const [headData, setHeadData] = useState(null);
    // const [adminData, setAdminData] = useState(null);
    const {innerWidth} = useResize();
    // const [latestArtworks, setLatestArtworks] = useState(null);


    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    // console.log(res)
                    // setAdminData(res.data)
                    setHeadData([initialHeadSlide, ...res.data.artworks_top])
                    // console.log(res.data)
                    // setLatestArtworks({
                    //     loading: true,
                    //     new_landing_artworks: res.data.new_landing_artworks,
                    //     top_landing_artworks: res.data.artworks_top
                    // })
                    setLoading(true)
                }
            })
    }, [])

    return (
        <div className='home_page_wrapper'>
            <section style={!loading ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : null}
            className={'head_section'}>
            {loading ?
                <Slider {...headSectionSettings}>
                    {headData.sort(() => 0.5 - Math.random()).map((el, i) => (
                        <div key={i}>
                            <div style={{ backgroundImage: `url(${el.photo})` }}
                                className={'head_bg_image'}>
                                <div className={'head_bg_opacity'}>
                                    <div className={'content'}>
                                        <h2>We use AI to help you identify tomorrow’s blue-chip artists.</h2>
                                        <p>The only online platform offering affordable {innerWidth > 992 ?
                                            <br /> : null} investment-grade artwork with
                                            transparent price history and artist career analytics.</p>
                                        <div className={'artist_info'}>
                                            <button>Discover now</button>
                                            {innerWidth > 660 ?
                                                <div className={'artist_info_position'}>
                                                    <h3>Artwork by</h3>
                                                    <div
                                                        // to={`/artist-profile/${el.user.id}`}
                                                        //   onClick={(e) => notRegisteredUser(e)}
                                                        className={'about_artist'}>
                                                        <div className={'avatar'}>
                                                            <img src={el.user.avatar ?? artist_avatar_default}
                                                                alt="avatar" />
                                                        </div>
                                                        <div>
                                                            <h5>{el.user.name}</h5>
                                                            <span>{el.user.price}€</span>
                                                            <span>date: {el.user.date}</span>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }} />}
        </section>
        </div>
    );
}

export default MainSlider;
