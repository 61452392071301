import React, {useEffect} from 'react';
import API from "../../api/API";
import SEO from "../../Components/SEO";

const SuccessPayment = () => {

    useEffect(() => {
        API().post(`/user/payment/buy`)
            .then(res => {
                window.location.href = res.data.url
            })
    }, [])

    const style = {
        empty_page: {
            minHeight: '500px'
        }
    }

    return (
        <>
            <SEO key={1} title="Success: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Buy art online Artsted">
                <meta name="robots" content="noindex, nofollow"/>
            </SEO>
            <div style={style.empty_page}/>
        </>
    )
};

export default SuccessPayment