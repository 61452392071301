import React from 'react';
import Pagination from 'react-js-pagination'

const PaginationView = ({activePage, onChange}) => {
    return (
        <Pagination
            activePage={activePage.page}
            itemsCountPerPage={1}
            itemClass={'pagination_list_buttons'}
            activeClass={'pagination_list_buttons_active'}
            firstPageText={activePage.page >= 3 && activePage.pages > 3 ? '1' : null}
            prevPageText={activePage.page >= 3 ? <button>{'Prev <<'}</button> : null}
            nextPageText={(activePage.pages - activePage.page) >= 2 ?
                <button>{'Next >>'}</button> : null}
            totalItemsCount={activePage.pages}
            lastPageText={activePage.page <= activePage.pages - 2 && activePage.pages > 3 ? activePage.pages.toString() : null}
            pageRangeDisplayed={3}
            onChange={onChange}/>
    )
}
export default PaginationView
