import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import './styles/artworks_info_card.css'
import Keys from "../Constants/helper";
import {handleFavoriteData} from "../Constants/handleFavoriteData";
import {connect, useSelector} from "react-redux";
import {priceWithDots} from "../Constants/priceWithDots";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import OutsideClickHandler from "../Constants/detectOutsideClick";
import {unitByCurrency} from "../Constants/unitByCurrency";
import {localizeSize} from "../Constants/localizeSize";

function ArtworkInfoCard(props) {
    const {
        author_name, id, picture, sold, activeData, setActiveData,
        categoriesHandleFavorite, categoriesFunction, categoryDataIndex,
        price, size, title, year, liked, index, nestedArray, setData,
        setActiveModal, profileFavorites, statistic, priceObject, category, ethereum_price, premium,
        currency, currencies, units
    } = props;

    // console.log('author_name:', author_name, 'id: ', id, 'picture: ', picture, 'sold: ', sold, 'activeData: ', activeData, 'setActiveData: ', setActiveData,
    //     'categoriesHandleFavorite: ', categoriesHandleFavorite, 'categoriesFunction: ', categoriesFunction, 'categoryDataIndex', categoryDataIndex,
    //     'price: ', price, 'size: ', size, 'title: ', title, 'year: ', year, 'liked: ', liked, 'index: ', index, 'nestedArray: ', nestedArray, 'setData: ', setData,
    //     'setActiveModal: ', setActiveModal, 'profileFavorites: ', profileFavorites, 'statistic: ', statistic, 'priceObject: ', priceObject)

    // console.log('activeData: ', activeData.artworks[0].category)

    let activeStatsObj = activeData[index]

    const userRole = useSelector(state => state?.auth?.user_data?.role);

    const setPopup = (close) => {
        if (close) {
            activeStatsObj.price.popup = false
        } else {
            activeStatsObj.price.popup = !activeStatsObj.price.popup
        }
        setData(prevState => ([
            ...prevState.slice(0, index),
            activeStatsObj,
            ...prevState.slice(index + 1),
        ]))
    }

    const popupLayout = () => (
        <OutsideClickHandler callback={() => setPopup(true)}>
            <div
                className={`custom_dropdown_option overflow_scrollbar ${priceObject.popup ? 'active_custom_dropdown' : ''}`}>
                {priceObject.options.filter(el => el !== priceObject.value).map((a, num) => (
                    <span key={num} onClick={(e) => {
                        e.preventDefault();
                        activeStatsObj.price.value = a;
                        activeStatsObj.price.popup = false;
                        setData(prevState => ([
                            ...prevState.slice(0, index),
                            activeStatsObj,
                            ...prevState.slice(index + 1),
                        ]))
                    }}>{capitalizeFirstLetter(a.split('_').join(' '))}</span>
                ))}
            </div>
        </OutsideClickHandler>
    )

    // console.log('statistic: ', statistic)

    let localSize = useMemo(() => {
        if (size) {
            return units === "inches" ? localizeSize(size, units) : size
        }
    }, [units, size])
    const localizedPrice = currency ? (currencies[currency] * price).toFixed(0) : price;
    const currencySymbol = currency ? unitByCurrency[currency] : "€"
    return (
        <Link onClick={(e) => {
            if (statistic && priceObject) {
                if (priceObject.popup) e.preventDefault()
            }
        }} to={`/public-artwork/${id}`} className={'artwork_info_card'}
              style={priceObject && priceObject.popup ? {background: 'var(--beige)'} : null}>
            <div className={'card_image_wrapper'}>
                <img src={picture} alt={`${title} - Artwork`} className={"card_image"}/>
                {category === 'NFT' && <div className={'nft-artwork-block'}>NFT</div>} {/*Drawing*/}
                {userRole === 'investor' && !statistic ? [
                    <div key={0} className={'shadow_hover'}/>,
                    <div key={1} onClick={(e) => {
                        e.preventDefault();
                        if (localStorage.getItem(Keys.JWT_TOKEN)) {
                            if (profileFavorites) {
                                setActiveModal({value: id, index: index, role: 'artwork'})
                            } else {
                                handleFavoriteData({
                                    e, index, id: id, activeData: activeData,
                                    setActiveData: setActiveData, nestedArray: nestedArray,
                                    categoriesHandleFavorite, categoriesFunction,
                                    categoryDataIndex
                                })
                            }
                        }
                    }} className={`favorite ${liked
                        ? 'favorite_active' : ''}`}>
                        <svg width="32" height="32"
                             viewBox="0 0 32 32" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.75" y="0.75" width="30.5"
                                  height="30.5" rx="15.25"
                                  stroke="#F6F6F1" strokeWidth="1.5"/>
                            <path d="M11 22V9H22V22L16.5 18.0658L11 22Z"
                                  fill={'transparent'}
                                  stroke="#F6F6F1"
                                  strokeWidth="1.5"/>
                        </svg>
                    </div>] : null}
            </div>
            <div className={'card_line'}/>
            <div className={'card_content'}>
                <div className={'artwork-name-list-container'}>
                    <h4>{author_name}</h4>
                    {premium &&
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z" fill="#3473FF"/>
                        <path d="M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335" stroke="white" strokeWidth="1.6" strokeLinecap="round"/>
                    </svg>
                    }
                </div>
                <span>{title?.length > 25 ? title.substring(0, 25) + '...' : title}, {year}</span>
                {category !== 'NFT' &&
                <p>{localSize}</p>
                }
                {!statistic ?
                    <div className={'card_sold'}>
                        <h6 style={sold ? {color: 'rgba(0, 0, 0, .45)'} : {color: '#000'}}>{category === 'NFT' ? 'ETH ' : currencySymbol}{category === 'NFT' ? ethereum_price : localizedPrice}{category === 'NFT' && ` ($ ${price})`}</h6>
                        {/*{category === 'NTF' ? <h6></h6> : <h6></h6>}*/}
                        {sold ? <span>sold</span> : null}
                    </div>
                    : <div className={'card_statistic'}>
                        <div className={'inline'}>
                            <span>Purchased by price</span>
                            <h5>{category === 'NFT' ? 'ETH ' : '€'}{priceWithDots(priceObject.purchased_by_price)}</h5>
                        </div>
                        <div className={'inline'}>
                            <span>Price for today</span>
                            <h3>{category === 'NFT' ? 'ETH ' : '€'}{priceWithDots(priceObject.price_for_today)}</h3>
                        </div>

                        <div className={'card_line'}/>

                        <div className={'price_change_wrapper'}>
                            <div className={'price_changed_in'}>
                                <p>price changed in</p>
                                <div className={'popup_wrapper'}>
                                    <div className={'content'}
                                         style={priceObject.popup ? {pointerEvents: 'none'} : null}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             setPopup(false)
                                         }}>
                                        <span>{capitalizeFirstLetter(priceObject.value.split('_').join(' '))}</span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.15 7V6.68285L17.9048 6.88405L9.98314 13.3852L2.0954 6.88425L1.85 6.68199V7V8.42056V8.49131L1.9046 8.53631L9.88754 15.1158L9.98274 15.1942L10.0781 15.116L18.0952 8.53651L18.15 8.49151V8.42056V7Z"
                                                fill="black" stroke="black" strokeWidth="0.3"/>
                                        </svg>
                                    </div>
                                    {popupLayout()}
                                </div>
                            </div>
                            <div className={'changed_price'}>
                                <p>{category === 'NFT' ? 'ETH ' : '€'}{Object.entries(priceObject.price_changed_in).map((el, i) => el[0] === priceObject.value && (
                                    <span key={i}>{el[1]}</span>
                                ))}</p>
                                <svg width="10" height="20" viewBox="0 0 10 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5 0.5L0.669872 8L9.33013 8L5 0.5ZM5.75 19.5L5.75 7.25L4.25 7.25L4.25 19.5L5.75 19.5Z"
                                        fill="black"/>
                                </svg>
                            </div>
                        </div>
                    </div>}
            </div>
        </Link>
    )
}

const mapStateToProps = state => ({
    currency: state.auth.currency,
    currencies: state.auth.currencies,
    units: state.auth.units,
})

export default connect(mapStateToProps)(ArtworkInfoCard);