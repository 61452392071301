import React from 'react';
import Progress from 'react-progressbar';

const ProgressLine = ({value}) => {
    return (
        <div className={'wrapper_progress_bar'}>
            <Progress completed={value} />
            <span>{value}%</span>
        </div>
    )
}

export default ProgressLine