import React, {useEffect, useState} from 'react'
import API from "../../api/API";
import Keys from "../../Constants/helper";
import {Link, useLocation} from "react-router-dom";
import './styles/user_profile.css'
import userPhoto from "../../assets/img/avatar.png";
import ModalWindow from "../../Components/ModalWindow";
import close_modal from "../../assets/img/admin/close_modal.svg";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import instagram_link from "../../assets/img/admin/instagram.svg";
import facebook_link from "../../assets/img/admin/facebook.svg";
import linkedin_link from "../../assets/img/admin/linkedin.svg";
import file_proof from "../../assets/img/admin/file_proof.svg";
// import {useHistory} from "react-router";
// import visa_payment from "../../assets/img/admin/visa.svg";

export default function AdminUserProfile() {
    let location = useLocation();
    // let history = useHistory();

    const [pageNavigation, setPageNavigation] = useState(null)
    const [urlNft, setUrlNft] = useState()
    const [nftOrder, setNftOrder] = useState()
    const [activeData, setActiveData] = useState(null)
    const [activeSocial, setActiveSocial] = useState([])
    const [loading, setLoading] = useState(null)
    const [activeModal, setActiveModal] = useState({
        type: null,
        value: false
    })
    const [banReason, setBanReason] = useState('')



    useEffect(() => {
        let url = location.pathname.split('/');
        setPageNavigation(url[2])
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/user?id=${url[3]}`)
            .then(res => {
                if (res.data) {
                    setActiveData({
                        ...res.data,
                        categories: res.data.categories
                    })
                    setActiveSocial([{
                        icon: linkedin_link,
                        link: res.data.linkedin_link
                    }, {
                        icon: facebook_link,
                        link: res.data.facebook_link
                    }, {
                        icon: instagram_link,
                        link: res.data.instagram_link
                    }, {
                        icon: file_proof,
                        link: res.data.website

                    }]);
                    setLoading(true)
                }
            })
    }, [location.pathname])

    const nftOrderDetails = () => {
        let url = location.pathname.split('/');
        setUrlNft(url[2])
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/nft_order?id=${url[3]}`)
            .then(res => {
                setNftOrder(res.data)
            })
    }

    useEffect(() => {
        nftOrderDetails()
        // eslint-disable-next-line
    },[])

    const nftOrderCancel = () => {
        let url = location.pathname.split('/');
        console.log('Im working: ', url[3])
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/nft_order/cancel?id=${url[3]}`)
            .then((res) => {
                console.log('nft_order/cancel: ', res)
                if (res.status === 200) {
                    // history.push(`/admin/${pageNavigation}`)
                    nftOrderDetails()
                }
            })
    }
    const nftOrderApprove = () => {
        let url = location.pathname.split('/');
        console.log('Im working: ', url[3])
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/nft_order/approve?id=${url[3]}`)
            .then((res) => {
                console.log('nft_order/cancel: ', res)
                // nftOrderDetails();
                // history.push('/')
                if (res.status === 200) {
                    // history.push(`/admin/${pageNavigation}`)
                    nftOrderDetails()
                }
            })
    }

    const handleUserRequest = () => {
        let url = location.pathname.split('/');
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/user?id=${url[3]}&act=${activeModal.type}&reason=${activeModal.type === 'block' ? banReason : null}`)
            .then(() => {
                if (activeModal.type === 'block'){
                    setActiveData(prevState => ({
                        ...prevState,
                        blocked: true
                    }))
                }else {
                    setActiveData(prevState => ({
                        ...prevState,
                        verified: true
                    }))
                }
                setBanReason('')
                setActiveModal({
                    value: null,
                    type: null,
                    reason: null,
                })
            })
    }

    // console.log(urlNft)
    // console.log(nftOrder)

    return [
        nftOrder !== null && urlNft === 'nfts' ?
            <div className={'admin_content_wrapper admin_content_wrapper_user'}
                 key={1}>
                <div className={'navigation'}>
                    <Link to={`/admin/${pageNavigation}`}>{pageNavigation}</Link>
                    <span>/</span>
                    <span>NFTs</span>
                </div>
                <div className={'nft-detailed-header'}>
                    <Link className={'nft_order_go_back'} to={`/admin/${pageNavigation}#status=orders`}>
                        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.75167 7.88797L0.263672 4.30397L3.75167 0.719971H6.56767L3.07967 4.30397L6.56767 7.88797H3.75167Z"
                                fill="black"/>
                        </svg>
                        Go back
                    </Link>
                    <span className={'nft-detailed-header-orders'}>NFT order №{nftOrder?.id ? nftOrder?.id : 'None'}</span>
                    <span>{nftOrder?.date ? nftOrder?.date : 'None'}</span>
                </div>
                <p className={'nfts-detailed-title'}>NFT info</p>
                <div className={'nft-detailed-first-section margin-bottom-40'}>
                    {/*<img/>*/}
                    {/*<h1>Img</h1>*/}
                    {nftOrder?.picture[0] ?
                        <img alt={'order'} className={'admin_nft_order'} src={nftOrder?.picture[0]}/>
                        :
                        null
                    }
                    <div className={'nft-detailed-first-section-second-row nft-detailed-first-section-second-row-bold'}>
                        <span>Title</span>
                        <span>Price type</span>
                        <span>Price</span>
                    </div>
                    <div className={'nft-detailed-first-section-second-row'}>
                        <span>{nftOrder?.nft_title ? nftOrder?.nft_title : 'None'}</span>
                        <span>{nftOrder?.price_type ? nftOrder?.price_type : 'None'}</span>
                        <span>{nftOrder?.ether_price ? 'ETH ' + nftOrder?.ether_price : 'None'}</span>
                    </div>
                </div>
                <p className={'nfts-detailed-title'}>Investor info</p>
                <div className={'nfts-detailed-second-section-container margin-bottom-40'}>
                    <div className={'nft-detailed-first-section-second-row nft-detailed-first-section-second-row-bold'}>
                        <span>Name</span>
                        <span>Email</span>
                        <span>Ethereum address</span>
                    </div>
                    <div className={'nft-detailed-first-section-second-row'}>
                        <span>{nftOrder?.investor?.name ? nftOrder?.investor?.name : 'None'}</span>
                        <span>{nftOrder?.investor?.email ? nftOrder.investor?.email : 'None'}</span>
                        <span>{nftOrder?.investor?.ethereum_address ? nftOrder?.investor?.ethereum_address : 'None'}</span>
                    </div>
                </div>
                <p className={'nfts-detailed-title'}>Artist info</p>
                <div className={'nfts-detailed-second-section-container line-block'}>
                    <div className={'nft-detailed-first-section-second-row nft-detailed-first-section-second-row-bold'}>
                        <span>Name</span>
                        <span>Email</span>
                        <span>Ethereum address</span>
                    </div>
                    <div className={'nft-detailed-first-section-second-row'}>
                        <span>{nftOrder?.artist?.name ? nftOrder?.artist?.name : 'None'}</span>
                        <span>{nftOrder?.artist?.email ? nftOrder?.artist?.email : 'None'}</span>
                        <span>{nftOrder?.artist?.ethereum_address ? nftOrder?.artist?.ethereum_address : 'None'}</span>
                    </div>
                </div>
                <div className={'nfts-detailed-buttons-container'}>
                    <div onClick={nftOrderCancel} className={nftOrder?.order_status === 'cancelled' || nftOrder?.order_status === 'completed' ? 'nfts-detailed-cancel-button-disabled nfts-detailed-cancel-button' : 'nfts-detailed-cancel-button'}>{nftOrder?.order_status === 'cancelled' ? 'Canceled' : 'Cancel'}</div>
                    <div onClick={nftOrderApprove} className={nftOrder?.order_status === 'cancelled' || nftOrder?.order_status === 'completed' ? 'nfts-detailed-cancel-button-disabled nfts-detailed-cancel-approve' : 'nfts-detailed-cancel-approve'}>{nftOrder?.order_status === 'completed' ? 'Approved' : 'Approve'}</div>
                </div>
            </div>
            :
        <div className={'admin_content_wrapper admin_content_wrapper_user'}
             key={1}>
            <div className={'navigation'}>
                <Link to={`/admin/${pageNavigation}`}>{pageNavigation}</Link>
                <span>/</span>
                <span>Detailed</span>
            </div>
            <div className={'content'}>
                <div className={'content_about'}>
                    {loading
                        ? <div className={'grid-align'}>
                            <img src={loading
                                ? activeData.avatar
                                    ? activeData.avatar
                                    : userPhoto : null}
                                 alt="avatar"/>
                        </div> : null}
                    <div>
                        <h3>{loading ? activeData.name : null}</h3>
                        <span>{loading
                            ? capitalizeFirstLetter(activeData.role) : null} (registered {loading ? activeData.registration_time
                            : null})</span>
                    </div>
                </div>
                <div className={'content_payment'}>
                    <div className={'content_payment_content'}>
                        <div className={'content_payment_block'}>
                            {loading && activeData.role !== 'investor' ? activeData.blocked
                                ? <span>Blocked</span>
                                : <button onClick={() => {
                                    setActiveModal({
                                        type: 'block',
                                        value: true
                                    })
                                }}>Block</button> : null}
                        </div>
                        <div className={'content_payment_verified'}>
                            {loading && activeData.role !== 'investor' ? activeData.verified
                                ? <span>Verified</span>
                                : <button onClick={() => {
                                    setActiveModal({
                                        type: 'verify',
                                        value: true
                                    })
                                }}>Verify</button> : null}
                        </div>
                    </div>
                </div>
            </div>
            {loading && activeData.categories.length ?
                <div className={'categories'}>
                    {activeData.categories.map((item, index) => (
                        <div key={index}><p>{item}</p></div>
                    ))}
                </div> : null}

            <div className={'user_info'}>
                <div className={'email_country'}>
                    <div>
                        <span>E-mail:</span>
                        <a href={`mailto:${activeData?.email}`}>{loading && activeData.email}</a>
                    </div>
                    <div>
                        <span>Country:</span><p>{loading && activeData.country}</p>
                    </div>
                    <div>
                        <span>Ethereum:</span><p>{loading && activeData.ethereum_address ? activeData.ethereum_address : 'None'}</p>
                    </div>
                </div>
                <div className={'portfolio_about'}>
                    <div>
                        <h3>Portfolio</h3>
                        {activeSocial.map((el, i) => (
                            <div className={'social_item'} key={i}>
                                <img src={el.icon} alt="social"/>
                                {el.link ?
                                    <a rel="noopener noreferrer nofollow"
                                       href={el.link}>{el.link}</a>
                                    : <span>—</span>}
                            </div>
                        ))}
                    </div>
                    <div>
                        <h3>About</h3>
                        <p>{loading && activeData.about_yourself}</p>
                    </div>
                </div>

                <div className={'works_container'}>
                    <h3>Works</h3>
                    <div>{loading ? activeData?.proof_artworks?.length
                        ? activeData.proof_artworks.map((el, i) => (
                            <div className={'work_item'} key={i}>
                                <img src={el.path} alt="img"/>
                                <p>{el.description}</p>
                            </div>
                        )) : <span className={'no_items_style'}>No items</span> : null}</div>
                </div>
            </div>
        </div>,
        activeModal.value ?
        <div className={'admin_wrapper_modal'} key={3}>
            <ModalWindow activeModal={activeModal}
                         nestedObj
                         setActiveModal={val => setActiveModal(val)}>
                <img onClick={() => {
                    setActiveModal({
                        value: null,
                        type: null,
                        reason: null,
                    })
                }} src={close_modal} alt="close modal"/>
                <h4>{activeData?.name ?? 'Username'}</h4>
                <div className={'admin_wrapper_modal_line'}/>
                <p style={activeModal.type === 'verify'
                    ? {margin: '0'}
                    : {margin: '0 0 1rem'}}>
                    {activeModal.type === 'block'
                        ? 'Are you sure you want to block this user?'
                        : activeModal.type === 'verify'
                            ? 'Are you sure you want to verify this user?'
                            : 'Are you sure you want to unblock this user?'}</p>
                {activeModal.type === 'block'
                    ? <textarea value={banReason}
                                rows={'3'}
                                onChange={({target}) => setBanReason(target.value)}
                                placeholder={'The reason for blocking'}/>
                    : activeModal.type === 'unblocked'
                        ? activeModal.reason
                            ? <div className={'admin_wrapper_modal_reason'}>
                                <h6>Reason:</h6>
                                <span>{activeModal.reason}</span>
                            </div> : null : null}
                <button onClick={() => handleUserRequest()}>{activeModal.type === 'block'
                    ? 'Block'
                    : activeModal.type === 'verify'
                        ? 'Verify'
                        : 'Unblock'}</button>
            </ModalWindow>
        </div> : null
    ]
}
