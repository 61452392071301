import React from 'react';
import userPhoto from "../../assets/img/avatar.png";

const InboxMessage = ({type, message, type_img, event_preview_message, user, event_message_link, date, time}) => {

    const previewSection = () => (
        <div className="event_preview">
            {type_img}
            {event_message_link ?
                <a target="_blank" rel="noreferrer"
                   href={event_message_link}>{event_preview_message}</a>
                : <span className={`type`}>{event_preview_message}</span>}
        </div>
    )

    return (
        <div
            className={`${event_preview_message ? 'every_inbox_message every_inbox_message_event' : 'every_inbox_message'}`}>
            {!event_preview_message ?
                <div className="avatar_wrapper">
                    <img src={user?.avatar ?? userPhoto} alt="avatar"/>
                </div>
                : null}
            <div className="wrapper_sender_info">
                {!event_preview_message ?
                    <div className={'sender_name'}>
                        <span>{user?.name}</span>
                    </div>
                    : null}
                {type === 'shipping' ?
                    <div className={'wrapper_event'}>
                        {previewSection()}
                        {message.text ?
                            <div className={'wrapper_message'}>
                                <span className={'name_sender'}>
                                    {message.sender} comment:
                                </span>
                                <span className={'message'}>
                                    {message.text}
                                </span>
                            </div>
                            : null}
                    </div>
                    : type === 'completed' ?
                        <div className={'wrapper_event'}>
                            {previewSection()}
                        </div>
                        : type === 'cancel' ?
                            <div className={'wrapper_event'}>
                                {previewSection()}
                            </div>
                            : type === 'tracking_number' ?
                                <div className={'wrapper_event'}>
                                    {previewSection(event_message_link)}
                                </div>
                                : type === 'start' ?
                                    <div className={'wrapper_event'}>
                                        {previewSection()}
                                    </div>
                                    : <div className={'sender_message'}>
                                        <span style={{whiteSpace: 'break-spaces'}}>{message.text}</span>
                                    </div>}
            </div>
            <div className="wrapper_date">
                <span>{date}</span>
                <span>{time}</span>
            </div>
        </div>
    )
};

export default InboxMessage