import axios from 'axios'
import helper from "../Constants/helper";
import {clearCookies} from "../Constants/clearCookies";
import Keys from "../Constants/helper";

function API(token = null, url) {
    let baseURL = url || process.env.REACT_APP_API_URL;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (localStorage.getItem(token ?? helper.JWT_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(token ?? helper.JWT_TOKEN); /* treba JWT_TOKEN */
    }

    let api = axios.create({
        baseURL: baseURL,
        timeout: 6000000,
        headers: headers
    })

    api.interceptors.request.use(
        request => requestHandler(request));

    api.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    );

    const successHandler = response => response
    const requestHandler = request => request

    const errorHandler = error => {
        console.log("error")
        console.log(error)
        console.log("error")
        if (error?.response?.status === 401) {
            if ((error?.response?.data?.error && (error?.response?.data?.error.toLowerCase() === 'token has been revoked' || error?.response?.data?.error.toLowerCase() === "token has expired")) ||
                (error?.response?.data?.msg && (error?.response?.data?.msg.toLowerCase() === 'token has been revoked' || error?.response?.data?.msg.toLowerCase() === "token has expired"))) {
                userLogout();
            }
            // console.log(error)
            return Promise.reject({...error})
        } else if (error?.response?.status === 404) {
            console.log(error)
            // debugger
            // window.location = "/404" treba
            return Promise.reject({...error})
        } else {
            console.log(error)
            // debugger
            return Promise.reject({...error})
        }
    };
    return api
}

export function userLogout(isUser = null) {
    if (isUser) {
        API().post("/user/logout")
            .then(() => forceLogout())
            .catch(() => forceLogout())
    } else {
        forceLogout()
    }
}

export function forceLogout() {
    clearCookies()
    const fromApp = localStorage.getItem(Keys.FROM_APP)
    const platform = localStorage.getItem(Keys.APP_PLATFORM)
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem(Keys.FROM_APP, fromApp)
    localStorage.setItem(Keys.APP_PLATFORM, platform)
    if (window.location.href.includes('admin')) {
        window.location = '/admin'
    } else {
        window.location = '/'
    }
}

export default API
