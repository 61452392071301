import {Col, Row, Space, Typography} from "antd";
import clsx from "clsx";
import {Link} from "react-router-dom";
import React, {Fragment} from "react";

import {defaultBase64Image} from "../../../Constants/images";

import GoogleMap from "../../GoogleMap";
import StyledButton from "../../ui/StyledButton";
import CheckInOutDates from "../../ui/CheckInOutDates";

import {CheckIcon} from "../../../assets/icons";

import styles from "./BookingItemInfo.module.css";

export default function BookingItemInfo({data = {}}) {
    const image = data.photos?.sort((a, b) => Number(b.main) - Number(a.main))?.[0]?.path;

    return (
        <div>
            <div className={styles.infoItemGrid}>
                <div className={styles.imageWrapper}>
                    <img
                        src={image ? process.env.REACT_APP_API_URL + '/' + image : defaultBase64Image}
                        alt=""
                    />
                </div>
                <div className={styles.basicInfo}>
                    <div>
                        <h3 className={styles.title}>{data.name}</h3>
                        <div className={styles.text}>{data.address}</div>
                    </div>
                    <Link className={styles.link} to={`/public-experiences/${data.type}/${data.listing_id}`}>
                        <StyledButton color="transparent">
                            Open Studio Details
                        </StyledButton>
                    </Link>
                </div>
            </div>
            <Row gutter={[10, 5]} className={styles.infoItem}>
                <Col span={24}>
                    <Typography.Title level={4}>Dates</Typography.Title>
                </Col>
                <Col span={24}>
                    <CheckInOutDates
                        checkIn={data.date_from}
                        checkOut={data.date_to}
                        checkInFromTime={data.check_in_from_time}
                        checkInToTime={data.check_in_to_time}
                        checkOutFromTime={data.check_out_from_time}
                        checkOutToTime={data.check_out_to_time}
                    />
                </Col>
            </Row>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Guests</Typography.Title>
                3
            </div>
            <Row gutter={[10, 5]} className={clsx(styles.infoItem, styles.smallText)}>
                <Col xs={24} md={12}>
                    <Row gutter={[24, 5]}>
                        <Col span={8}>
                            <Typography.Text strong>{data.type === 'Stay' ? 'Place' : 'Studio'} Area</Typography.Text>
                        </Col>
                        <Col span={16}>{data.area_sq_ft} sq. ft.</Col>
                        <Col span={8}>
                            <Typography.Text strong>Max. people</Typography.Text>
                        </Col>
                        <Col span={16}>{data.guests_num_max}</Col>
                        <Col span={8}>
                            <Typography.Text strong>Baths</Typography.Text>
                        </Col>
                        <Col span={16}>{data.baths_num}</Col>
                        <Col span={8}>
                            <Typography.Text strong>Rooms</Typography.Text>
                        </Col>
                        <Col span={16}>{data.rooms_num}</Col>
                    </Row>
                </Col>
                <Col xs={24} md={12}>
                    <Row gutter={24}>
                        {data.other_infos?.map((info) => (
                            <Fragment key={info.id}>
                                <Col span={8}>
                                    <Typography.Text strong>{info.name}</Typography.Text>
                                </Col>
                                <Col span={16}>{info.value}</Col>
                            </Fragment>
                        ))}
                    </Row>
                </Col>
            </Row>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Description</Typography.Title>
                <Typography.Paragraph>{data.description}</Typography.Paragraph>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Amenities</Typography.Title>
                <ul className={styles.list}>
                    {data.amenities?.map((item) => (
                        <li key={item}>
                            <Space><CheckIcon/>{item}</Space>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Location</Typography.Title>
                <Typography.Text underline>{data.address}</Typography.Text>
                <div className={styles.mapWrapper}>
                    <GoogleMap address={data.address}/>
                </div>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Important details</Typography.Title>
                <div className={styles.gridRowInner}>
                    <Typography.Text strong>Cancellation policy</Typography.Text>
                    <div className={styles.preWrap}>
                        {data.cancellation_policy?.text}
                    </div>
                </div>
            </div>
            <div className={styles.infoItem}>
                <Row gutter={[10, 20]}>
                    <Col span={24}>
                        <Row gutter={10} justify="space-between">
                            <Col>
                                <div className={styles.priceText}>Price</div>
                            </Col>
                            <Col>
                                <div className={styles.priceText}>€{data.price}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={10} justify="space-between">
                            <Col>
                                <div className={styles.priceText}>Discount</div>
                            </Col>
                            <Col>
                                <div className={styles.priceText}>-€0</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={10} justify="space-between">
                            <Col>
                                <div className={styles.priceText}>Platform fee:</div>
                            </Col>
                            <Col>
                                <div className={styles.priceText}>€{data.fee_amount}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row gutter={10} justify="space-between">
                            <Col>
                                <Typography.Title level={4}>Total price</Typography.Title>
                            </Col>
                            <Col>
                                <Typography.Title level={4}>€{data.price + data?.fee_amount}</Typography.Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}