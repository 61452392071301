import React, {useMemo, useState} from "react";

import StyledTabs from "../../../Components/ui/StyledTabs";
import ProfileListings from "../ProfileListings";
import ProfileBookings from "../ProfileBookings";

import styles from './ProfileArtistBnB.module.css';
import {useSelector} from "react-redux";
import MainButton from "../../../Components/CustomComponents/MainButton";
import {Link} from "react-router-dom";

export default function ProfileArtistBnB() {
    const [tab, setTab] = useState('listings');
  let store = useSelector(store => store.auth.user_data)
  const isOrganization = useMemo(() => store?.role === 'organization', [store?.role])

    return (
        <div className={styles.wrapper}>
          <div className={styles.bannerWrapper}>
            <div className={styles.topBanner}>
              <span className={styles.bannerTitle}>Rent Out or Creative Space with Artsted Stays</span>
              <span className={styles.bannerSubtitle}>Reach a global community of artists and creatives. List your space today and start earning.</span>
            </div>
          </div>
          <div className={styles.bottomBanner} style={{marginBottom: 24}}>
            <div className={styles.bottomBannerText}>
              <span className={styles.bannerTitle} style={{color: 'white'}}>Got space?</span>
              <span className={styles.bannerSubtitle} style={{color: 'white'}}>Monetize your unused residencies or art spaces. Rent them out for short stays or creative retreats or residencies and earn from a global audience of artists. <br/> Upload Space</span>
            </div>
            <div>
              <Link to="/profile/listings/new">
                <MainButton
                  className={'button_white'}
                  text={'Upload Space'}
                  onClick={() => ''}
                />
              </Link>
            </div>
          </div>
          <>
            <StyledTabs
              centered
              className={styles.tabs}
              defaultActiveKey={tab}
              items={[
                {key: 'listings', label: 'My Listing'},
                {key: 'bookings', label: 'My Bookings'}
                ]}
                onChange={setTab}
              />
              {tab === 'listings' && <ProfileListings />}
              {tab === 'bookings' && <ProfileBookings />}
            </>

        </div>
    )
}