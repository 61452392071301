import React from 'react';
import SEO from "../../Components/SEO";

export default function ForInvestor() {
    return (
        <div className={'faq_section_body investor_padding'}>
            <SEO title="For Investors: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ For Investors: Buy art online Artsted"/>
            <h1>For Investors</h1>

            <p>Analytics and asset performance insights provided by the Artsted platform are created through a unique,
                proprietary algorithm, the outcome data is then shaped into a number of indicators which help
                distinguish early-stage investment opportunities in the art market within an affordable price range.</p>
            <p>Artsted does not provide certified investment advice.</p>
        </div>
    );
}
