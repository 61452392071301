import {Navigation} from "../../routers/navigation";
import Keys from "../../Constants/helper";

const HistoryDetectBack = (store, activeHistory, detectHistoryBack) => {
    let historyArray,
        nextHistory = null,
        urlSlug = activeHistory.split('/').pop(),
        tempArray = JSON.parse(JSON.stringify(store));

    // crash when storage i't
    if (store) {
        if (store.type === 'artist') {
            historyArray = ['/registration/type', ...Navigation.mainNavigationRegistrationArtist.map(i => i.path)]
        } else if (store.type === 'organization') {
            historyArray = ['/registration/type', ...Navigation.mainNavigationRegistrationOrganization.map(i => i.path)]
        } else {
            historyArray = ['/registration/type', ...Navigation.mainNavigationRegistrationCollector.map(i => i.path)]
        }

        historyArray.forEach((i, index) => {
            if (i === window.location.pathname) {
                if (historyArray[index + 1]) {
                    nextHistory = historyArray[index + 1]
                } else {
                    nextHistory = store.type === 'organization' ? '/profile/about' : '/profile/portfolio'
                }
            }
        })
    }

    if ((urlSlug === 'type' || urlSlug === 'auth') && detectHistoryBack) {
        tempArray = null
    } else if ((urlSlug === 'work' || urlSlug === 'greeting') && detectHistoryBack) {
        localStorage.removeItem(Keys.JWT_TOKEN_REGISTRATION)
        localStorage.removeItem(Keys.USER_DATA)
        tempArray = Object.entries(tempArray).filter(i => {
            if (i[0] === 'type') {
                return i
            } else {
                return null
            }
        })
        tempArray = Object.fromEntries(tempArray)
    }

    return {nextHistory, tempArray, urlSlug}
}

export default HistoryDetectBack;