import React, {useEffect, useState} from 'react';
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import {Link} from "react-router-dom";
import useBottomPopup from "../../Constants/useBottomPopup";
import API from "../../api/API";
import {useHistory, useParams} from "react-router";
import Keys from "../../Constants/helper";
import moment from "moment";
import ModalWindow from "../../Components/ModalWindow";
import close_modal from "../../assets/img/admin/close_modal.svg";


const typeToName = {
    "Awards": "Prizes, Contests Won",
    "Museums": "Acquisition For A Public Collection (Museum)",
    "Media": "PR, Media Features",
    "Exhibitions": "Exhibitions",
    "Residencies": "Residencies",
    "Education": "Education",
}

const Institution = () => {
    let {successText, changingConfirmed} = useBottomPopup();
    const [data, setData] = useState({});
    const [activeModal, setActiveModal] = useState({
        value: null,
        type: null,
    })
    const {id} = useParams()
    const history = useHistory()

    useEffect(() => {
        API(Keys.JWT_TOKEN_ADMIN).get("/admin/event_requests", {params: {id}})
            .then(res => {
                setData(res.data)
            })
    }, [id])

    const handleUserRequest = () => {
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/event_requests`, {
            id: activeModal.value,
            approved: activeModal.type,
        })
            .then(() => {
                // console.log(res)
                setActiveModal({
                    value: null,
                    type: null,
                })
                history.push("/admin/institutions")
            })
    }

    return (
        <div className={'admin_content_wrapper admin_artwork_item_content_wrapper admin_institution_item'}>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>

            <div className={'navigation'}>
                <Link to={`/admin/institutions`}>Institutions</Link>
                <span>/</span>
                <span>Details</span>
            </div>
            <div className={"institution-header"}>
                <Link to={`/admin/institutions`} className={"go-back"}>
                    {"< Go Back"}
                </Link>
                <h1>{typeToName[data.event_type] || data.event_type}</h1>
                <span>{moment(data.created_at, "YYYY-MM-DD").format("DD.MM.YYYY")}</span>
            </div>
            <div className={'content'}>
                <div className={'features content-section-container'}>
                    <div>
                        <h2 className={'item-title-section-style'}>Artist info</h2>

                        <div className={'content-item-list-container'}>
                            <h6>Name</h6>
                            <span>{data.creator_full_name}</span>
                        </div>

                        <div className={'content-item-list-container'}>
                            <h6>E-mail</h6>
                            <span>{data.creator_email}</span>
                        </div>

                        <div className={'content-item-list-container'}>
                            <h6>Country</h6>
                            <span>{data.country}</span>
                        </div>

                        <div className={'content-item-list-container'}>
                            <h6>Performance</h6>
                            <span>{data.creator_cv_total_score}</span>
                        </div>
                    </div>
                    <div>
                        <h2 className={'item-title-section-style'}>Event info</h2>

                        <div className={'content-item-list-container'}>
                            <h6>Type</h6>
                            <span>{typeToName[data.event_type] || data.event_type}</span>
                        </div>

                        <div className={'content-item-list-container'}>
                            <h6>Title</h6>
                            <span>{data.name}</span>
                        </div>
                        {/*<div className={'content-item-list-container'}>*/}
                        {/*    <h6>Short description</h6>*/}
                        {/*    <span>asd</span>*/}
                        {/*</div>*/}
                        <div className={'content-item-list-container'}>
                            <h6>Link</h6>
                            <span>{data.website_link}</span>
                        </div>
                        <div className={'content-item-list-container'}>
                            <h6>Facebook</h6>
                            <span>{data.facebook_link}</span>
                        </div>
                        <div className={'content-item-list-container'}>
                            <h6>Instagram</h6>
                            <span>{data.instagram_link}</span>
                        </div>
                        <div className={'content-item-list-container'}>
                            <h6>Twitter</h6>
                            <span>{data.twitter_link}</span>
                        </div>
                        <div className={'content-item-list-container'}>
                            <h6>Status</h6>
                            <span>{data.status ? "Active" : "Closed"}</span>
                        </div>
                        <div className={'content-item-list-container'}>
                            <h6>Comments</h6>
                            <span>{data.comments}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-buttons">
                <button className={'admin_bordered_button'}
                        onClick={() => {
                            setActiveModal({
                                value: id,
                                type: false,
                            })
                        }}>Reject
                </button>
                <button onClick={() => {
                    setActiveModal({
                        value: id,
                        type: true,
                    })
                }}>Approve
                </button>
            </div>

            {!!activeModal.value &&
                <div className={'admin_wrapper_modal'} key={3}>
                    <ModalWindow activeModal={activeModal}
                                 nestedObj
                                 setActiveModal={val => setActiveModal(val)}>
                        <img onClick={() => {
                            setActiveModal({
                                value: null,
                                type: null,
                            })
                        }} src={close_modal} alt="close modal"/>
                        <h4>{data.event_type ?? 'Institution'}</h4>
                        <div className={'admin_wrapper_modal_line'}/>
                        <p style={{margin: '0 0 1rem'}}>
                            {activeModal.type
                                ? 'Are you sure you want to approve this institution?'
                                : 'Are you sure you want to reject this institution?'}
                        </p>
                        <button onClick={() => handleUserRequest()}>{activeModal.type ? "Approve" : "Reject"}</button>
                    </ModalWindow>
                </div>}
        </div>
    );
};

export default Institution;
