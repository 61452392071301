import clsx from "clsx";
import {Fragment} from "react";
import {Avatar, Col, Row, Space, Typography} from "antd";

import GoogleMap from "../../GoogleMap";

import {CheckIcon} from "../../../assets/icons";

import styles from "./ExperiencesItemInfo.module.css";

export default function ExperiencesItemInfo({data}) {
    return (
        <div>
            <div className={styles.infoItem}>
                <div className={styles.gridRow}>
                    <Space size={20}>
                        <Avatar src={process.env.REACT_APP_API_URL + '/' + data?.owner_avatar} size={64}/>
                        <div>
                            <Typography.Text className={clsx(styles.block, styles.text)}>Hosted by</Typography.Text>
                            <h4 className={styles.hostName}>
                                {data?.owner_full_name}
                            </h4>
                        </div>
                    </Space>
                </div>
            </div>
            <Row gutter={[10, 5]} className={clsx(styles.infoItem, styles.smallText)}>
                <Col xs={24} md={12}>
                    <Row gutter={[24, 5]}>
                        <Col span={8}>
                            <Typography.Text strong>{data.type === 'Stay' ? 'Place' : 'Studio'} Area</Typography.Text>
                        </Col>
                        <Col span={16}>{data.area_sq_ft} sq. ft.</Col>
                        <Col span={8}>
                            <Typography.Text strong>Max. people</Typography.Text>
                        </Col>
                        <Col span={16}>{data.guests_num_max}</Col>
                        <Col span={8}>
                            <Typography.Text strong>Baths</Typography.Text>
                        </Col>
                        <Col span={16}>{data.baths_num}</Col>
                        <Col span={8}>
                            <Typography.Text strong>Rooms</Typography.Text>
                        </Col>
                        <Col span={16}>{data.rooms_num}</Col>
                    </Row>
                </Col>
                <Col xs={24} md={12}>
                    <Row gutter={24}>
                        {data.other_infos?.map((info) => (
                            <Fragment key={info.id}>
                                <Col span={8}>
                                    <Typography.Text strong>{info.name}</Typography.Text>
                                </Col>
                                <Col span={16}>{info.value}</Col>
                            </Fragment>
                        ))}
                    </Row>
                </Col>
            </Row>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Description</Typography.Title>
                <Typography.Paragraph>{data.description}</Typography.Paragraph>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Amenities</Typography.Title>
                <ul className={styles.list}>
                    {data.amenities.map((item) => (
                        <li key={item}>
                            <Space><CheckIcon/>{item}</Space>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Location</Typography.Title>
                <Typography.Text underline>{data.address}</Typography.Text>
                <div className={styles.mapWrapper}>
                    <GoogleMap address={data.address}/>
                </div>
            </div>
            <div className={styles.infoItem}>
                <Typography.Title level={4}>Important details</Typography.Title>
                    <div className={styles.gridRowInner}>
                        <Typography.Text strong>Cancellation policy</Typography.Text>
                        <div className={styles.preWrap}>
                            {data.cancellation_policy?.text}
                        </div>
                    </div>
            </div>
        </div>
    )
}