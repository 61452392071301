import API from "../api/API";

export const handleFavoriteData = (props) => {

    const {
        e, index, id, activeData,
        setActiveData, categoriesFunction, categoryDataIndex,
        nestedArray, categoriesHandleFavorite, setRecentViewLiked,
        reduxState, setFiltered
    } = props

    e?.preventDefault();
    API().post(`/user/like_artwork`, {id: id})
        .then((res) => {
            if (res.status === 200) {
                if (categoriesFunction) {
                    let likeData = categoriesHandleFavorite[categoryDataIndex].categoryData[index]
                    likeData.liked = !likeData.liked
                    let categoryItemData = {
                        ...categoriesHandleFavorite[categoryDataIndex],
                        categoryData: [
                            ...categoriesHandleFavorite[categoryDataIndex].categoryData.slice(0, index),
                            likeData,
                            ...categoriesHandleFavorite[categoryDataIndex].categoryData.slice(index + 1),
                        ]
                    }

                    setActiveData(prevState => ([
                        ...prevState.slice(0, categoryDataIndex),
                        categoryItemData,
                        ...prevState.slice(categoryDataIndex + 1),
                    ]))
                } else if (reduxState) {
                    setRecentViewLiked(id)
                } else if (setFiltered && nestedArray) {
                    setActiveData(prevState => ({
                        ...prevState,
                        [nestedArray]: prevState[nestedArray].filter(el => el.id !== id)
                    }))
                } else if (nestedArray) {
                    let likeData = activeData[nestedArray][index]
                    likeData.liked = !likeData.liked
                    setActiveData(prevState => ({
                        ...prevState,
                        [nestedArray]: [
                            ...prevState[nestedArray].slice(0, index),
                            likeData,
                            ...prevState[nestedArray].slice(index + 1),
                        ]
                    }))
                } else {
                    let likeData = activeData[index]
                    likeData.liked = !likeData.liked
                    setActiveData(prevState => ([
                        ...prevState.slice(0, index),
                        likeData,
                        ...prevState.slice(index + 1),
                    ]))
                }
            }
        })
}