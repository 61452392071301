import React from 'react'

const DataRetention = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>8. DATA RETENTION</h3>
            <div className={'privacy_policy_item_container'}>
                <span>HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</span>
                <span>
                    We will only retain your personal data for as long as necessary to fulfil the purposes we
                                collected it for, including for the purposes of satisfying any legal, accounting, or
                                reporting requirements
                    <br/>
                    To determine the appropriate retention period for personal data, we consider the amount,
                                nature, and sensitivity of the personal data, the potential risk of harm from
                                unauthorised use or disclosure of your personal data, the purposes for which we process
                                your personal data and whether we can achieve those purposes through other means, and
                                the applicable legal requirements. For example, details of your orders will be kept for
                                as long as we need to retain that data to comply with our legal and regulatory
                                requirements. This is generally 7 years unless the law prescribes a longer period.
                    <br/>
                    In some circumstances you can ask us to delete your data: see Request erasure in Your
                                Legal Rights section below for further information
                    <br/>
                    In some circumstances we may anonymise your personal data (so that it can no longer be
                                associated with you) for research or statistical purposes in which case we may use this
                                information indefinitely without further notice to you.
                </span>
            </div>
        </div>
    )
}

export default DataRetention