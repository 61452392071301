import React, {useMemo} from "react";
import SimpleChart from "../StatisticComponent/SimpleChart";
import {Link} from "react-router-dom";
import MainButton from "../CustomComponents/MainButton";
import ToolTipComponent from "../ToolTipComponent";

const NewStatisticPerformance = ({data, loading, innerWidth, title, full_name, publicSection}) => {

    const performanceChart = useMemo(() => {
        if (loading && Object.keys(data)?.length) {
            if (Object.entries(data).length === 1) {
                let protoArray = Object.entries(data).map(i => {
                    return {...i[1], date: i[0]}
                })
                protoArray[1] = JSON.parse(JSON.stringify(protoArray[0]));
                protoArray[0].avg = 0;
                protoArray[0].total_score = 0;
                protoArray[0].date = (protoArray[1].date - 1).toString();
                return protoArray
            } else {
                return Object.entries(data).map(i => {
                    return {...i[1], date: i[0]}
                })
            }
        }
    }, [data, loading])

    return (
        <div className={'statistic_profile_views'}>
            <div className="title">
                <span>{title}</span>
                {publicSection ?
                    <ToolTipComponent link={'/premium#artists_career_tracking'}
                                      text={'Artists’ Career Tracking'}/>
                    : null}
            </div>
            <div className={'statistic_wrapper__area_chart'}>
                {loading && performanceChart?.length ?
                    <SimpleChart data={performanceChart}
                                 innerWidth={innerWidth}/>
                    : <span>No data yet</span>}
                {/*<div className={'wrapper_area_chart_info wrapper_views_info'}>*/}
                {/*    <div className="wrapper_views_info">*/}
                {/*        {data?.last_5_events?.length ?*/}
                {/*            Object.values(data.last_5_events).map((item, index) => {*/}
                {/*                console.log(item)*/}
                {/*                return (*/}
                {/*                    <div className={'every'} key={index}>*/}
                {/*                        /!*<span><b>{item.name}</b>, {item.country}</span>*!/*/}
                {/*                        /!*<span className={'val'}><b>{item.year}</b></span>*!/*/}
                {/*                    </div>*/}
                {/*                )*/}
                {/*            }) : <span>No data yet</span>}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className={'statistic_wrapper__area_legend_wrapper'}>
                {performanceChart?.length ?
                    <div className={'statistic_wrapper__area_legend'}>
                        <div className={'every'}>
                            <div className={'color'} style={{background: '#000'}}/>
                            {/*<span className={'title'}>{full_name ? full_name + '`s' : 'Your'} performance score</span>*/}
                            <span className={'title'}>{full_name ?? 'Your'} performance score</span>
                        </div>
                        <div className={'every'}>
                            <div className={'color'} style={{background: '#ccc'}}/>
                            <span className={'title'}>Average performance score</span>
                        </div>
                    </div>
                    : null}
                {!publicSection ?
                    <Link to={'/profile/cv'} className={'wrapper_btn'}>
                        <MainButton text={'Update CV'}
                                    className={'button_black'}/>

                    </Link>
                    : null}
            </div>
        </div>
    )
}

export default NewStatisticPerformance