import {Avatar, Col, Row, Space, Tag, Typography} from "antd";
import moment from "moment";
import {useCallback, useEffect, useState} from "react";

import API from "../../../api/API";

import StyledCheckbox from "../../ui/StyledCheckbox";
import StyledDatePicker from "../../ui/StyledDatePicker";
import StyledTable from "../../ui/StyledTable/StyledTable";

import styles from './ListingBooking.module.css';
import useResize from "../../../Constants/useResize";

const columns = [
    {title: 'Booker', dataIndex: 'booker', key: 'booker'},
    {title: 'Dates', dataIndex: 'dates', key: 'dates'},
    {title: 'Status', dataIndex: 'status', key: 'status'},
    {
        title: 'Guests',
        dataIndex: 'guests_num',
        key: 'guests_num',
        sorter: true
    },
    {
        sorter: true,
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
    },
    {
        sorter: true,
        title: 'Booking date',
        dataIndex: 'created_at',
        key: 'created_at',
    },
];

export default function ListingBooking() {
    const {innerWidth} = useResize();
    const [data, setData] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        API().get('/listing/bookings', {
            params: {
                type: 'Stay',
                owner_view: 1,
                per_page: 100,
                ...filters,
                date_from: filters.date_to ? moment(filters.date_from).format('YYYY-MM-DD') : null,
                date_to: filters?.date_to ? moment(filters.date_to).format('YYYY-MM-DD') : null,
            }
        })
            .then((res) => {
                setData(res.data?.bookings?.reduce?.((acc, cur) => {
                    const key = moment(cur.created_at).format('MMMM YYYY');
                    acc[key] = [...(acc[key] || []), cur];
                    return acc;
                }, {}));
                setDataSource(res.data.bookings.map((i) => ({
                    key: i.id,
                    booker: (
                        <Space>
                            <Avatar src={process.env.REACT_APP_API_URL + '/' + i.booker_avatar}/>
                            {i.booker_name}
                        </Space>
                    ),
                    dates: `${moment(i.date_from).format('DD MMM YYYY')} -> ${moment(i.date_to).format('DD MMM YYYY')} (${moment(i.date_to).diff(moment(i.date_from), 'days')} nights)`,
                    status: (
                        moment(i.date_from).diff(moment(), 'days') > 0 ? (
                            <Tag className={styles.tag} color="#69C967">Upcoming</Tag>
                        ) : (
                            <Tag className={styles.tag} color="#C7C7C7">Completed</Tag>
                        )
                    ),
                    guests_num: i.guests_num,
                    price: (<Typography.Text strong>€{i.price}</Typography.Text>),
                    created_at: moment(i.created_at).format('DD MMM YYYY HH:mm')
                })))
            })
    }, [filters]);

    const onChange = (pagination, filters, sorter) => {
        setFilters((p) => ({
            ...p,
            sort_by: sorter?.field,
            sort_by_type: sorter?.order?.slice(0, -3)
        }))
    };

    const handleChangeUpcoming = (e) => {
        setFilters((p) => ({
            ...p,
            upcoming_only: Number(e.target.checked)
        }))
    }

    const handleChangeDate = useCallback(([date_from, date_to]) => {
        setFilters((p) => ({...p, date_from, date_to}))
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.headerFitters}>
                <StyledCheckbox checked={filters?.upcoming_only} onChange={handleChangeUpcoming}>
                    Show upcoming only
                </StyledCheckbox>
                <StyledDatePicker
                    isClearable
                    selectsRange
                    monthsShown={2}
                    className={styles.datePicker}
                    placeholderText="Dates"
                    selectsDisabledDaysInRange
                    selected={filters?.date_from}
                    startDate={filters?.date_from}
                    endDate={filters?.date_to}
                    onChange={handleChangeDate}
                />
            </div>
            <div className={styles.tableWrapper}>
                {innerWidth < 768 ? (
                    <div>
                        {Object.entries(data)?.map(([k, v]) => (
                            <div key={k}>
                                <div className={styles.tableDate}>
                                    {k}
                                </div>
                                {v.map((i) => (
                                    <div key={i.id} className={styles.tableListItem}>
                                        <Row gutter={20} justify="space-between">
                                            <Col span={14}>
                                                {moment(i.date_from).diff(moment(), 'days') > 0 ? (
                                                    <Tag className={styles.tag} color="#69C967">Upcoming</Tag>
                                                ) : (
                                                    <Tag className={styles.tag} color="#C7C7C7">Completed</Tag>
                                                )}
                                            </Col>
                                            <Col span={10}>
                                                <div className={styles.tableListItemText}>
                                                    {moment(i.created_at).format('DD MMM YYYY HH:mm A')}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Space>
                                            <Avatar size={24} src={i.booker_avatar}/>
                                            <Typography.Text>{i.booker_name}</Typography.Text>
                                        </Space>
                                        <Row gutter={20} justify="space-between">
                                            <Col span={14}>
                                                <div className={styles.tableListItemText}>Dates</div>
                                                <div className={styles.date}>
                                                    {`${moment(i.date_from).format('DD MMM YYYY')} -> ${moment(i.date_to).format('DD MMM YYYY')} (${moment(i.date_to).diff(moment(i.date_from), 'days')} nights)`}
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <div className={styles.tableListItemText}>Guests</div>
                                                {i.guests_num}
                                            </Col>
                                        </Row>
                                        <div>
                                            <div className={styles.tableListItemText}>Price</div>
                                            <div className={styles.price}>€{i.price}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ) : (
                    <StyledTable
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        onChange={onChange}
                    />
                )}
            </div>
        </div>
    )
}