import React, {useEffect, useState} from 'react';
import './styles/main_button.css';

function MainButton(props) {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
    }, [props.disabled])

    return (
        <button
            id={props.id ? props.id : null}
            autoFocus={props.autofocus}
            onClick={props.onClick ? () => {
                props.onClick()
            } : null}
            type={props.type ?? 'button'}
            style={props.style ?? null}
            className={`${props.hoverDisable ? 'button_hover_disable' : ''} button_default ${props.className}`}
            disabled={disabled}>
            {props.svg ?
                props.svg
                : props.img ?
                    <img style={{marginRight: '4px'}} src={props.img} alt="img"/> :
                    null}
            <span>{props.text ?? ''}</span>
        </button>
    );
}

export default MainButton;
