import React from 'react';
import SEO from "../../Components/SEO";

export default function CareerTracking() {
    return (
        <div className={'faq_section_body career_padding'}>
            <SEO title="Artists’ Career Tracking: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Artists’ Career Tracking: Buy art online Artsted"/>
            <h1>Artists’ Career Tracking</h1>
            <p>Artsted provides a visual representation of a user's career over time, as reported by the user
                themselves. The visual representation of the form of a "Performance Chart" that will be reflected in the
                Artist's public profile. The updates of "CV", will be connected to the raise in Personal Coefficient
                analyzed by the proprietary algorithm. This means that the prices on already sold items will be updated
                according to that raised by x% , this change can be viewed in Investors profile in "My portfolio".</p>
        </div>
    )
}