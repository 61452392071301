import React, {useEffect, useState} from 'react'
import './styles/user_cart.css'
// import checkmark from '../../assets/img/cart/checkmark.svg'
import delete_button from '../../assets/img/cart/delete_button.svg'
import API from "../../api/API";
import useResize from "../../Constants/useResize";
import done from "../../assets/img/subscription_plan_checkk.svg";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import {setCartCount, setAuthPopUp, setNewHeaderBucket} from "../../main-store/auth/actions";
import {connect, useSelector, useDispatch} from "react-redux";
import {useStripe} from "@stripe/react-stripe-js";
import {Link, useHistory} from "react-router-dom";
import Keys from "../../Constants/helper";
import CartSteps from "../../Components/Cart/CartSteps";
import CartContentByStep from "../../Components/Cart/CartContentByStep";
import NFTItem from "../../Components/Cart/NFTItem";
import CartFooter from "../../Components/Cart/CartFooter";
import CartSidebar from "../../Components/Cart/CartSidebar";
import SEO from "../../Components/SEO";
import {unitByCurrency} from "../../Constants/unitByCurrency";

// const deliveryService = {
//     basic: ['Shipped directly from the Artist with a Tracking number'],
//     premium: ['Additional Authenticity Verification', 'Authenticity Sticker', 'Secure packaging', 'Custom Shipping handled by the Artisfact Delivery'],
// }

const initialState = {
    name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    surname: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    country: {
        value: '',
        error: false,
        options: [],
        popup: false,
        errorMessage: 'Please select your Country',
    },
    postcode: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    address: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    city: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    phone: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    state: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    promocode: {
        value: '',
        discount: 0,
        status: null,
    },
    error: false
}

function UserCart({setCartCount, user_data, cart_status, currency, currencies}) {
    const stripe = useStripe();
    const dispatch = useDispatch();
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const {innerWidth} = useResize()
    const userStore = useSelector(store => store.auth.user_data)
    const [activeData, setActiveData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [loadingPlan, setLoadingPlan] = useState(false)
    const [shippingDetails, setShippingDetails] = useState(() => initialState)
    const [activeModal, setActiveModal] = useState(null)
    const [selectedShippingAddress, setSelectedShippingAddress] = useState({});
    const [activeStep, setActiveStep] = useState({
        access: 1,
        active: 1
    });
    const [activeSubscription, setActiveSubscription] = useState({
        value: '',
        data: null,
        billPlan: '',
    })
    const [checkout, setCheckout] = useState({
        error: "",
        disabled: false
    });
    let history = useHistory();
    const [cardPaymentInProgress, setCardPaymentInProgress] = useState(false);

    useEffect(() => {
        if (!!userStore) {
            let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []

            bucket.map(art => (
                API().post(`/user/basket`, {id: art.artwork.id})
                    .catch(err => {
                        console.log(err);
                    })
            ))

            localStorage.setItem('artstedBucketList', JSON.stringify([]))
        }

    }, [userStore])

    useEffect(() => {
        const subscription = new URLSearchParams(window.location.search).get("subscription")
        const subscriptionHandle = (subscription === 'basic' || subscription === 'creator' || subscription === 'analytics' || (!subscription && user_data?.role === 'investor'))
        if (subscriptionHandle) {
            setActiveSubscription({
                value: subscription,
                data: JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)),
                billPlan: sessionStorage.getItem(Keys.SUBSCRIPTION_PLAN_BILL)
            })
            if (sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)) {
                if (JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).subTime === 'year') {
                    setTotalPrice(activeSubscription.billPlan === 'annually' ? +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).priceMonth : +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).priceMonth)
                } else {
                    setTotalPrice(activeSubscription.billPlan === 'annually' ? +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).price : +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).price)
                }
            }
            setLoadingPlan(true)
        } else {
            // window.location.href = '/404' treba
        }
    }, [activeSubscription.billPlan, user_data?.role, activeData])


    useEffect(() => {
        const bucket = JSON.parse(localStorage.getItem('artstedBucketList'))
        setActiveData(bucket)

        API().get(`/user/basket`)
            .then(res => {
                if (res.data) {
                    // dispatch(setNewHeaderBucket(res.data.artworks.length))
                    setActiveData(res.data.artworks)
                    console.log(9999, activeSubscription, totalPrice)
                    if (activeSubscription.value && JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).subTime === 'year') {
                        setTotalPrice(activeSubscription.billPlan === 'annually' ? +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).priceMonth : +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).priceMonth)
                        console.log('hah: ', totalPrice)
                    } else if (activeSubscription.value) {
                        setTotalPrice(activeSubscription.billPlan === 'annually' ? +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).price : +JSON.parse(sessionStorage.getItem(Keys.SUBSCRIPTION_DATA)).price)
                    } else {
                        if (res.data.artworks.length) {
                            setTotalPrice(res.data.artworks.map(i => i.price).reduce((a, b) => a + b))
                        }
                    }
                    setShippingDetails(prevState => ({
                        ...prevState,
                        name: {
                            ...prevState.name,
                            value: res.data.shipping_details.name ? res.data.shipping_details.name : ''
                        },
                        surname: {
                            ...prevState.surname,
                            value: res.data.shipping_details.surname ? res.data.shipping_details.surname : ''
                        },
                        country: {
                            ...prevState.country,
                            value: res.data.shipping_details.country ? res.data.shipping_details.country : '',
                        },
                        postcode: {
                            ...prevState.postcode,
                            value: res.data.shipping_details.postcode ? res.data.shipping_details.postcode : ''
                        },
                        state: {
                            ...prevState.state,
                            value: res.data.shipping_details.state ? res.data.shipping_details.state : ''
                        },
                        address: {
                            ...prevState.address,
                            value: res.data.shipping_details.address ? res.data.shipping_details.address : ''
                        },
                        city: {
                            ...prevState.city,
                            value: res.data.shipping_details.city ? res.data.shipping_details.city : ''
                        },
                        phone: {
                            ...prevState.phone,
                            value: res.data.shipping_details.phone ? res.data.shipping_details.phone : ''
                        },
                    }))
                    setLoading(true)
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(true)
            })
// eslint-disable-next-line
    }, [activeSubscription.value, activeSubscription.billPlan])

    const handleDeleteOrder = () => {
        if (!!userStore) {
            const body = {
                id: typeof activeModal === 'number' ? activeModal : undefined
            }
            API().delete(`/user/basket`, {params: body})
                .then(res => {
                    if (res.status === 200) {
                        if (activeData?.length === 1 || activeData?.length === 0) {
                            dispatch(setNewHeaderBucket(0))
                        }
                        if (activeData.filter(el => el.id !== activeModal).length) {
                            setTotalPrice(activeData.filter(el => el.id !== activeModal)
                                .map(i => i.price)
                                .reduce((a, b) => (a + b)))
                            // dispatch(setNewHeaderBucket(activeData.length))
                        } else {
                            activeData.filter(el => el.id !== activeModal).map(i => i.price)
                            setTotalPrice(0)
                            // dispatch(setNewHeaderBucket(activeData.length))
                        }
                        setActiveData(prevState => prevState.filter(el => el.id !== activeModal))
                        setActiveModal(null)
                        bodyOverflow(false)
                        setCartCount(activeData.length /*- 1*/)
                        changeContinuePopup('Deleted from cart')
                        setTimeout(() => changeContinuePopup('Deleted from cart'), 2000)
                        // dispatch(setNewHeaderBucket(activeData.length))
                    }
                })
        } else {
            const bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
            const newBucket = bucket.filter(e => e.artwork.id !== activeModal)
            newBucket.length ? localStorage.setItem('artstedBucketList', JSON.stringify(newBucket)) : localStorage.removeItem('artstedBucketList')


            // dispatch(setNewHeaderBucket(newBucket.length))
            setActiveData(newBucket.map(e => e))
            setActiveModal(null)
            bodyOverflow(false)
            setCartCount(newBucket.length /*- 1*/)
            changeContinuePopup('Deleted from cart')
            setTimeout(() => changeContinuePopup('Deleted from cart'), 2000)
            if (!localStorage.getItem('artstedBucketList')) {
                dispatch(setNewHeaderBucket(0))
            } else {
                dispatch(setNewHeaderBucket(1))
            }
        }
    }


    useEffect(() => {
        if (activeSubscription?.value) {
            setActiveStep({active: 3, access: 3})
        } else if (userStore?.name) {
            setActiveStep({active: 2, access: 2})
        }
    }, [userStore, activeSubscription])

    // console.log('activeData: ', activeData[0])

    const linkArtistProfile = event => {
        if (!localStorage.getItem(Keys.JWT_TOKEN)) {
            event.preventDefault();
            changeContinuePopup('Please sign up to view personal pages')
            setTimeout(() => {
                setAuthPopUp('Registration')
                changeContinuePopup('Please sign up to view personal pages')
            }, 2000)
        }
    }

    const buyNftItems = (type) => {
        if (!cardPaymentInProgress) {
            if (user_data?.role !== undefined) {
                setCardPaymentInProgress(true)
                API().post('/user/payment/buy_nft', {payment_method: type})
                    .then((res) => {
                        console.log("res")
                        console.log(res)
                        console.log("res")

                        if (res.data && res?.data?.session_id) {
                            stripe.redirectToCheckout({sessionId: res.data.session_id})
                        } else {
                            sessionStorage.setItem('arstedCartLastBuy', 'NFT');
                            API().delete('/user/basket').then(r => console.log(r))
                            setCardPaymentInProgress(false)
                            window.location.href = '/post-checkout'
                        }
                    })
                    .catch((e) => {
                        console.log("e")
                        console.log(e)
                        setCardPaymentInProgress(false)
                    })
            } else {
                history.push('/login')
            }
        }
    }

    const submitCheckout = () => {
        setCheckout(state => ({...state, disabled: true}))
        let requestObj = {
            shipping_address_id: selectedShippingAddress.id
        }

        if (shippingDetails.promocode.status === "success") {
            requestObj.promo_code = shippingDetails.promocode.value
        }

        API().post(`/user/payment/buy`, requestObj)
            .then(res => {
                if (res.data.session_id) {
                    stripe.redirectToCheckout({sessionId: res.data.session_id})
                } else {
                    window.location.href = res.data.url.replaceAll("_", "-")
                }
            })
            .catch(err => {
                if (err.response.status === 400 && err.response.data.message) {
                    setCheckout(prevState => ({
                        ...prevState,
                        error: err.response.data.message
                    }))
                } else {
                    setCheckout(prevState => ({
                        ...prevState,
                        error: "Data validation failed"
                    }))
                }
            })
            .finally(() => {
                setCheckout(state => ({
                    ...state,
                    disabled: false
                }))
            })
    }

    const setCorrectPeriod = () => activeSubscription.data.subTime === 'month' ? 'monthly' : 'yearly';

    const subscriptionCheckout = () => {
        let subscription = activeSubscription.value;
        setCheckout(state => ({...state, disabled: true}))

        API().post(`/user/payment/subscription?coupon=${shippingDetails.promocode.value}&subscription=${subscription}&period=${setCorrectPeriod()}`)
            .then(res => {
                if (res.data?.session_id) {
                    stripe.redirectToCheckout({sessionId: res.data.session_id})
                } else if (res.data?.url) {
                    window.location.href = res.data.url.replaceAll("_", "-")
                } else {
                    sessionStorage.setItem('arstedCartLastBuy', 'classic')
                    window.location.href = '/post-checkout'
                }
            })
            .catch(err => {
                setCheckout(prevState => ({
                    ...prevState,
                    error: "Data validation failed"
                }))
            })
            .finally(() => {
                setCheckout(state => ({...state, disabled: false}))
            })
    }
    // console.log('activeSubscription: ', activeSubscription)
    // console.log('activeData: ', activeData?.artwork?.title ? activeData?.artwork?.title : "None")


    // <Link to={`/artist-profile/${item.id}`}
    //       onClick={event => linkArtistProfile(event)}>
    //     <h5>{item.name}</h5>
    // </Link>

    // <Link to={`/public-artwork/${item.id}`}>
    //     <img src={item.picture}
    //          onError={e => {
    //              e.target.src = artworks_placeholder
    //          }}
    //          alt="order preview"/>
    // </Link>

    useEffect(() => {
        console.log(activeData)
    }, [activeData])

    console.log('activeSubscription: ', activeSubscription)
    const price = !!userStore
        ? totalPrice
        : (localStorage.getItem('artstedBucketList')
            ? JSON.parse(localStorage.getItem('artstedBucketList')).length && JSON.parse(localStorage.getItem('artstedBucketList')).map(e => e.artwork.price).reduce((a, b) => (a + b))
            : 0)
    const localizedPrice = currency ? (currencies[currency] * (price || 0)).toFixed(0) : (price || 0);
    const localizedTotalPrice = currency ? (currencies[currency] * (totalPrice || 0)).toFixed(0) : (totalPrice || 0);
    const localizedSubscriptionPrice = currency ? (currencies[currency] * (activeSubscription?.data?.price || 0)).toFixed(0) : (activeSubscription?.data?.price || 0);
    const currencySymbol = currency ? unitByCurrency[currency] : "€"
    const nonNFT = !activeData || activeData.length === 0 || (activeData[0]?.artwork?.category && activeData[0]?.artwork?.category !== 'NFT') || (activeData[0]?.category && activeData[0]?.category !== 'NFT')
    console.log(activeStep)
    return (
        <>
            <SEO title="Cart: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Cart: Buy art online Artsted"/>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            {nonNFT && <CartFooter/>}

            {nonNFT &&
            <CartSteps
                userStore={userStore}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                activeSubscription={activeSubscription}
            />}
            <div className={'user_cart_wrapper'}>
                <div className={'user_cart_wrapper_content'}>

                    {!!activeData &&
                    // eslint-disable-next-line
                    (activeData[0]?.category === 'NFT' || !!activeData && activeData[0]?.artwork?.category === 'NFT') &&
                    <div className={'nft-item-details-container'}>
                        <h1>NFT Cart</h1>
                        <h3>Orders details</h3>
                        <span>Here is your great choice</span>
                    </div>
                    }
                    {/*{console.log(activeData.length)}*/}
                    {/*<span className={'user_cart_navigation'}>Home  >> My cart</span>*/}
                    {nonNFT ?
                        <div className={'user_cart_container'}>
                            <div className={'user_cart_order'}>
                                {loading &&
                                <CartContentByStep
                                    activeData={activeData}
                                    setShippingDetails={setShippingDetails}
                                    selectedShippingAddress={selectedShippingAddress}
                                    setSelectedShippingAddress={setSelectedShippingAddress}
                                    shippingDetails={shippingDetails}
                                    activeSubscription={activeSubscription}
                                    activeStep={activeStep}
                                    userStore={userStore}
                                    setActiveStep={setActiveStep}
                                    checkout={checkout}
                                    submitCheckout={submitCheckout}
                                    subscriptionCheckout={subscriptionCheckout}
                                />}
                            </div>
                            {loading &&
                            <CartSidebar
                                setActiveModal={setActiveModal}
                                activeData={activeData}
                                shippingDetails={shippingDetails}
                                currency={currency}
                                currencies={currencies}
                                price={localizedPrice}
                                setShippingDetails={setShippingDetails}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                activeSubscription={activeSubscription}
                                selectedShippingAddress={selectedShippingAddress}
                                submitCheckout={submitCheckout}
                                subscriptionCheckout={subscriptionCheckout}
                                checkout={checkout}
                            />}
                        </div>
                        :
                        <div className={'user_cart_order_item_container'}>
                            {/*treba*/}
                            <div className={'nft-order-item-line'}>
                                {console.log('activeData', activeData)}
                                {/*loading || loadingPlan*/}
                                {!activeSubscription.value ? activeData && activeData.length
                                    ? activeData.map((item, index) => (
                                        innerWidth > 600 ?
                                            <NFTItem
                                                activeData={activeData}
                                                activeModal={activeModal}
                                                setActiveModal={setActiveModal}
                                                linkArtistProfile={linkArtistProfile}
                                                item={item}
                                                index={index}
                                            />
                                            : <div style={activeData?.user_order?.length === index + 1
                                            ? {borderBottom: 'none', padding: '0'}
                                            : {
                                                borderBottom: 'solid 1px var(--lite-grey)',
                                                padding: '0 0 20px'
                                            }} className={'user_cart_order_item'} key={item.id}>
                                                <div className={'user_cart_order_item_delete_mobile'}>
                                                    <img onClick={() => {
                                                        bodyOverflow(true)
                                                        setActiveModal(activeModal !== item.id ? item.id : null)
                                                        dispatch(setNewHeaderBucket(activeData?.length))
                                                    }}
                                                         src={delete_button} alt="delete button"/>
                                                </div>
                                                <div className={'user_cart_order_item_details_mobile'}>
                                                    <div className={'user_cart_order_item_preview'}>
                                                        <Link to={`/public_artwork/${item.id}`}>
                                                            <img src={item.picture || item.pictures?.[0]} alt="order preview"/>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link to={`/artist-profile/${item.artist.profile_url}`}
                                                              onClick={event => linkArtistProfile(event)}>
                                                            <h5>{item.artist.name}</h5>
                                                        </Link>
                                                        <p>{item.title}, {item.year}</p>
                                                        <div>
                                                            <span>{item.category}</span>
                                                            <span>|</span>
                                                            <span>{item.size}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'user_cart_order_item_price_mobile'}>
                                                    <div className={'user_cart_order_item_number'}>
                                                        <span>${item.price}</span>
                                                    </div>
                                                    <div className={'user_cart_order_item_content_price'}>
                                                        <span>${item.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    )) : <span className={'no_items_style'}>No items</span>
                                    : loadingPlan ? innerWidth > 600
                                        ? <div className={'user_cart_subscription_wrapper'}>
                                            <div>
                                                <div className={'user_cart_subscription_image_wrapper'}>
                                                    <div/>
                                                    <img src={activeSubscription?.data?.logo}
                                                         alt="subscription logo"/>
                                                </div>
                                            </div>
                                            <div>
                                                <h3>{activeSubscription?.data?.plan_title}</h3>
                                                <p>{activeSubscription?.data?.percentage}</p>
                                            </div>
                                            <div>
                                                <div
                                                    className={'user_cart_order_item_border_line user_cart_subscription_item_border_line'}/>
                                            </div>
                                            <div>
                                                {activeSubscription?.data?.dataText.map((el, index) => (
                                                    <div className={'user_cart_subscription_item_text'}
                                                         key={index}>
                                                        <img src={done} alt="checked"/>
                                                        <p>{el}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                <div
                                                    className={'user_cart_order_item_border_line user_cart_subscription_item_border_line'}/>
                                            </div>
                                            <div><h4>{!activeSubscription?.data?.basicPlan
                                                ? `${currencySymbol || "€"}${activeSubscription?.billPlan === 'monthly'
                                                    ? localizedSubscriptionPrice
                                                    : localizedSubscriptionPrice}`
                                                : localizedSubscriptionPrice}</h4></div>
                                        </div>
                                        : <div className={'user_cart_subscription_wrapper'}>
                                            <div className={'user_cart_subscription_wrapper_title'}>
                                                <div className={'user_cart_subscription_image_wrapper'}>
                                                    <img src={activeSubscription?.data?.logo}
                                                         alt="subscription logo"/>
                                                </div>
                                                <div>
                                                    <h3>{activeSubscription?.data?.plan_title}</h3>
                                                    <p>{activeSubscription?.data?.percentage}</p>
                                                </div>
                                            </div>
                                            <div>
                                                {activeSubscription?.data?.dataText.map((el, index) => (
                                                    <div className={'user_cart_subscription_item_text'}
                                                         key={index}>
                                                        <img src={done} alt="checked"/>
                                                        <p>{el}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div><h4>{!activeSubscription?.data?.basicPlan
                                                ? `${currencySymbol || "€"}${activeSubscription?.billPlan === 'monthly'
                                                    ? localizedSubscriptionPrice
                                                    : localizedSubscriptionPrice}`
                                                : localizedSubscriptionPrice}</h4></div>
                                        </div>
                                        : null}
                            </div>
                            <div className={'nft-full-price-container'}>
                                {!!userStore &&
                                <h3>Total price:</h3>
                                }
                                {/*<h2>$ {!!userStore ? totalPrice*/}
                                {/*    : (localStorage.getItem('artstedBucketList') ?*/}
                                {/*        JSON.parse(localStorage.getItem('artstedBucketList')).length && JSON.parse(localStorage.getItem('artstedBucketList')).map(e => e.artwork.ether_price).reduce((a, b) => (a + b))*/}
                                {/*        : 0)} </h2>*/}
                                {!!userStore &&
                                <h2>{currencySymbol || "$"} {!!userStore ? localizedTotalPrice
                                    : (localStorage.getItem('artstedBucketList'))} </h2>
                                }
                            </div>
                            <div className={'nft-full-price-container-text'}>
                                <span>Click ‘Buy’ and administrator will contact you for futher purchase instructions</span>
                            </div>
                            <div className={'nft-full-price-container-text'}>
                                <div className={'nft-full-price-container-button'}
                                     onClick={() => buyNftItems("crypto")}> Pay with crypto
                                </div>
                                <div className={'nft-full-price-container-button'} onClick={() => buyNftItems("card")}
                                     style={{marginLeft: 26}}>Pay by card
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {nonNFT && <CartFooter mobile/>}

            {activeModal ?
                <ModalWindow activeModal={activeModal}
                             setActiveModal={val => setActiveModal(val)}>
                    <h4>Delete from cart?</h4>
                    <p>Are you sure you want to delete? This action can't be undone</p>
                    <div className={'modal-window_buttons'}>
                        <MainButton type={'button'}
                                    style={{padding: '10px 1.5rem'}}
                                    text={'Cancel'}
                                    onClick={() => {
                                        setActiveModal(null)
                                        bodyOverflow(false)
                                    }}
                        />
                        <MainButton type={'button'}
                                    style={{padding: '8px 1.5rem'}}
                                    className={'button_bordered'}
                                    text={'Delete'}
                                    onClick={handleDeleteOrder}
                        />
                    </div>
                </ModalWindow> : null}
        </>
    )
}

const mapStateToProps = state => {
        return {
            cart_length: state.auth.cart_length,
            user_data: state.auth.user_data,
            cart_status: state.auth.cart_status,
            currency: state.auth.currency,
            currencies: state.auth.currencies
        }
    }
;

const putDispatchToProps =
    {
        setCartCount,
        setAuthPopUp
    }

export default connect(mapStateToProps, putDispatchToProps)(UserCart);
