import React, {useEffect, useState} from 'react';
import TextInput from "../CustomComponents/TextInput";
import AutocompleteTextInput from "../CustomComponents/AutocompleteTextInput";
import regExp from "../../Constants/regExp";
import MainButton from "../CustomComponents/MainButton";
import API from "../../api/API";
import CheckBox from "../CustomComponents/CheckBox";
import {ThreeDotsIcon} from "../../assets/icons";
import {Dropdown, Menu} from "antd";
import CustomDropdown from "../CustomComponents/Dropdown";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";

const requestURL = "/user/payment/shipping_address"

const initShipping = {
    name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    surname: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    country: {
        value: '',
        error: false,
        options: [],
        popup: false,
        errorMessage: 'Please select your Country',
    },
    zip: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    street: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    city: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    phone: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    error: false
}

const ShippingDetails = ({
                             setShippingDetails,
                             shippingDetails,
                             selectedShippingAddress,
                             setSelectedShippingAddress,
                             setActiveStep,
                             userStore
                         }) => {
        console.log(initShipping)
        const [shippingAddresses, setShippingAddresses] = useState([]);
        const [newShipping, setNewShipping] = useState({...initShipping});
        const [addNew, setAddNew] = useState(false);

        useEffect(() => {
            getPageData()

            //eslint-disable-next-line
        }, [])

        const getPageData = async () => {
            let [
                shippings,
                countries
            ] = await Promise.all([
                API().get(requestURL),
                API().get(`/user/countries`)
            ])

            console.log(countries)
            setNewShipping(prevState => ({
                ...prevState,
                country: {
                    ...prevState.country,
                    options: countries.data.countries,
                },
            }))
            const {address, name, phone, surname} = shippings.data

            const selectedAddress = address.find(i => i.default) ?? (address.length ? address[0] : {})
            setShippingAddresses(address)
            setSelectedShippingAddress({...selectedAddress})
            if (!address.length) {
                setNewShipping(state => ({
                    ...state,
                    name: {
                        ...state.name,
                        value: name
                    },
                    phone: {
                        ...state.phone,
                        value: phone
                    },
                    surname: {
                        ...state.surname,
                        value: surname
                    }
                }))
            }
        }

        // const getShippingAddresses = () => {
        //     API().get(requestURL)
        //         .then(res => {
        //             const address = res.data.address,
        //                 selectedAddress = defaultOrFirstAddress(address)
        //             setShippingAddresses(address)
        //             setSelectedShippingAddress({...selectedAddress})
        //         })
        // }

        const setError = (name) => {
            setNewShipping(state => ({
                ...state,
                [name]: {
                    ...state[name],
                    error: true
                }
            }))
        }

        const addAddress = () => {
            let body = {},
                errors = false;

            console.log(newShipping, "shipping")
            Object.entries(newShipping).forEach(i => {
                if (typeof i[1] === "object") {
                    if (!i[1].value) {
                        setError(i[0])
                        errors = true
                    } else {
                        const isCountry = i[0] === "country",
                            key = isCountry ? "country_code" : i[0];

                        body[key] = isCountry ? i[1].country_code : i[1].value
                    }
                } else if (i[0] === "id") {
                    body[i[0]] = i[1]
                }
            })

            if (errors) {
                return
            }

            console.log(body, "body")
            API().post(requestURL, body)
                .then(res => {
                    console.log(res)
                    setNewShipping({...initShipping})
                    setAddNew(false)
                    setSelectedShippingAddress(res.data)
                    if (body.id) {
                        const shippingIdx = shippingAddresses.findIndex(i => i.id === body.id)
                        setShippingAddresses(state => [
                            ...state.slice(0, shippingIdx),
                            res.data,
                            ...state.slice(shippingIdx + 1)
                        ])
                    } else {
                        setShippingAddresses(state => [...state, res.data])
                    }
                })
        }

        const onAutocomplete = data => {
            const {city, country, address, postcode, country_code} = data;

            setNewShipping(prevState => ({
                ...prevState,
                city: {
                    ...prevState.city,
                    value: city
                },
                country: {
                    ...prevState.country,
                    value: country,
                    country_code
                },
                street: {
                    ...prevState.street,
                    value: address
                },
                zip: {
                    ...prevState.zip,
                    value: postcode
                }
            }))
        }

        const onChange = (name, value) => {
            setNewShipping(state => ({
                ...state,
                [name]: {
                    ...state[name],
                    value,
                    error: false
                },
                error: false
            }))
        }

        const editAddress = (id) => {
            const shipping = shippingAddresses.find(i => i.id === id);
            const tempNewShipping = JSON.parse(JSON.stringify(newShipping))

            Object.entries(shipping).forEach(i => {
                if (tempNewShipping.hasOwnProperty(i[0]) && tempNewShipping[i[0]] instanceof Object) {
                    tempNewShipping[i[0]].value = i[1]
                } else if (i[0] === "country_code") {
                    tempNewShipping.country.country_code = i[1]
                } else {
                    tempNewShipping[i[0]] = i[1]
                }
            })

            setNewShipping(tempNewShipping)
            setAddNew(true)
        }

        const defaultOrFirstAddress = (arr) => {
            return arr.find(i => i.default) ?? arr?.[0]
        }

        const deleteAddress = (id) => {
            API().delete(requestURL, {params: {id}})
                .then(() => {
                    const newAddresses = shippingAddresses.filter(i => i.id !== id);
                    setShippingAddresses(newAddresses)
                    setSelectedShippingAddress(state => state.id === id ? defaultOrFirstAddress(newAddresses) : state)
                })
        }

        const addressActionClick = ({key}, id) => {
            switch (key) {
                case "edit":
                    editAddress(id)
                    break;
                case "delete":
                    deleteAddress(id)
                    break;
                default:
                    break;
            }
        };

        const addressActionsMenu = (id) => (
            <Menu onClick={(e) => addressActionClick(e, id)}>
                <Menu.Item key="edit">
                    Edit
                </Menu.Item>
                <Menu.Item key="delete">
                    Delete
                </Menu.Item>
            </Menu>
        );

        const onCancel = () => {
            if (!!addNew) {
                setNewShipping(initShipping)
                setAddNew(false)
            } else {
                setActiveStep(state => ({...state, active: 1}))
            }
        }

        const onContinue = () => {
            if (!!addNew) {
                addAddress()
            } else {
                setActiveStep({access: 3, active: 3})
            }
        }

        const hideAddNewAddress = () => addNew ? onCancel() : setAddNew(true);


        console.log(newShipping)
        console.log(userStore)
        return (
            <div className={`shipping-details-wrapper ${addNew ? "add-new" : ""}`}>
                <span className="title">Select shipping address</span>
                <div className="shipping-addresses">
                    {shippingAddresses.map(i => {
                        const isSelected = selectedShippingAddress.id === i.id;

                        return <div
                            key={i.id}
                            className={`shipping-address ${isSelected ? 'active' : ''}`}
                        >
                            <CheckBox value={isSelected}
                                      handleChange={() => {
                                          setSelectedShippingAddress(isSelected ? {} : i)
                                      }}/>
                            <div className="shipping-address-content">
                                <span className="confirmed">Confirmed address</span>
                                <strong>{i.name} {i.surname}</strong>
                                <span>{i.phone}</span>
                                <span>{i.street}</span>
                                <span>{i.zip} {i.city}, {i.country}</span>
                            </div>
                            <Dropdown placement={"bottomRight"} trigger={"click"}
                                      overlay={() => addressActionsMenu(i.id)}>
                                <ThreeDotsIcon/>
                            </Dropdown>
                        </div>
                    })}
                </div>
                <span onClick={hideAddNewAddress} className="add-new-address">
                    {addNew ? "Hide a new address" : "Add a new address"}
                </span>
                <div className={`new-address ${addNew ? "active" : ''}`}>
                    <div className="new-address-header">
                        <span className="title">Please enter your shipping details and address</span>
                    </div>
                    <div id={'user_cart_delivery_data'} className="user_cart_shipping_details">
                        <div className={'user_cart_shipping_details_info'}>
                            <TextInput value={newShipping.name.value}
                                       error={newShipping.name.error}
                                       errTitle={newShipping.name.errorMessage}
                                       title={'Name'}
                                       onChange={({target}) => onChange("name", target.value)}/>

                            <TextInput value={newShipping.surname.value}
                                       error={newShipping.surname.error}
                                       title={'Surname'}
                                       errTitle={newShipping.surname.errorMessage}
                                       onChange={({target}) => onChange("surname", target.value)}/>

                            <TextInput value={newShipping.phone.value}
                                       error={newShipping.phone.error}
                                       title={'Phone'}
                                       errTitle={newShipping.phone.errorMessage}
                                       onChange={({target}) => {
                                           if (regExp.phone_number.test(target.value) && target.value.length <= 20) {
                                               onChange("phone", target.value)
                                           }
                                       }}/>

                            <AutocompleteTextInput value={newShipping.street.value}
                                                   onAutocomplete={onAutocomplete}
                                                   error={newShipping.street.error}
                                                   title={'Street address, house number'}
                                                   errTitle={newShipping.street.errorMessage}
                                                   onChange={({target}) => onChange("street", target.value)}/>

                            <div className={`hidden-inputs ${newShipping?.country?.country_code ? "active" : ""}`}>
                                <CustomDropdown value={newShipping?.country?.value}
                                                setInputValue={val => setNewShipping(val)}
                                                errTitle={newShipping?.country?.errorMessage}
                                                optionLabel={'country'}
                                                disabled
                                                error={newShipping?.country?.error}
                                                search
                                                defaultDropdown
                                                title={'Country'}
                                                dropdownPadding={'5px 16px'}
                                                dropdownIcon={greyChevron}
                                                popup={newShipping?.country?.popup}
                                                options={newShipping?.country?.options}
                                />

                                <TextInput value={newShipping.zip.value}
                                           error={newShipping.zip.error}
                                           title={'Postcode'}
                                           disabled
                                           errTitle={newShipping.zip.errorMessage}/>
                                <TextInput value={newShipping.city.value}
                                           error={newShipping.city.error}
                                           title={'City'}
                                           disabled
                                           errTitle={newShipping.city.errorMessage}/>
                            </div>
                        </div>
                        {newShipping.error ?
                            <span className={'text_error'}>{newShipping.error}</span>
                            : null}
                    </div>
                </div>
                <div className="shipping-details-footer">
                    {(!userStore?.name || !!addNew) && (
                        <MainButton text={!!addNew ? "Cancel" : "Back"}
                                    onClick={onCancel}
                                    className={'button_bordered'}/>
                    )}
                    <MainButton disabled={!selectedShippingAddress.id && !addNew}
                                style={{marginLeft: 'auto'}}
                                onClick={onContinue}
                                text={!!addNew ? "Save" : "Continue"}/>
                </div>
            </div>
        );
    }
;

export default ShippingDetails;
