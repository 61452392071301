import React, {useEffect, useState} from 'react';
import Wrapper from '../OnboardingWrapper';
import Button from '../../../Components/CustomComponents/Onboarding/Button';
import classes from './styles/work.module.scss';
import artwork_img from '../../../assets/img/onboarding/artwork6.png';
import {useDispatch, useSelector} from "react-redux";
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import API from "../../../api/API";
import Keys from "../../../Constants/helper";
import historyDetectBack from "../HistoryBackDetect";
import {useHistory} from "react-router";
import Uploader from '../UploadFile'
import {ResizeTextBox} from "../../../Components/ResizeTextBox";

function Work() {
    const [works, setWorks] = useState([]);
    const [pieces, setPiece] = useState(["", "", ""]);
    const [continueBtn, setContinueBtn] = useState(true);
    const [loading, setLoading] = useState(false);
    const store = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const history = useHistory();

    const _setWork = (photo, index) => {
        const w = [...works];
        w[index] = photo;
        setWorks(w);
    }

    const _deleteWork = index => {
        const w = [...works];
        w[index] = null;
        setWorks(w);
    }

    const _setText = (value, index) => {
        const p = [...pieces];
        p[index] = value;
        setPiece(p);
    }

    const _getCountUploadedWork = () => {
        let count = 0;
        works.forEach(work => {
            if (work) {
                count++;
            }
        });
        return count;
    }

    const _onSubmit = e => {
        e.preventDefault()
        setContinueBtn(false)
        let formData = new FormData();
        for (let i = 0; i < works.length; i++) {
            if (!works[i]) {
                continue;
            }
            if (works[i].file) {
                formData.append(`artwork_photo${i}`, works[i].file)
            }
            formData.append(`artwork_description${i}`, pieces[i] || "")
        }

        if (formData.entries().next().done) {
            API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/upload_artwork`, formData)
                .catch(e => console.log(e))
        }
        let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
        history.push(nextHistory)
        setContinueBtn(true)
    }

    const getUploadedInfo = () => {
        API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/profile/upload_artwork`)
            .then((res) => {
                const {artist_artworks} = res.data
                if (artist_artworks.length) {
                    let artworksImg = artist_artworks.map(i => ({
                        blob: i.path
                    }))
                    let artworksDesc = artist_artworks.map(i => i.description)
                    setWorks(artworksImg)
                    if (artworksDesc.length !== 2) {
                        do {
                            artworksDesc.push('')
                        } while (artworksDesc.length === 2);
                        setPiece(artworksDesc)
                    } else {
                        setPiece(artworksDesc)
                    }
                }
            })
    }

    useEffect(() => {
        if (store.user_registration_data && localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION)) {
            setLoading(true)
            getUploadedInfo()
        } else {
            setLoading(false)
            let {tempArray} = historyDetectBack(store.user_registration_data, store.active_history)
            dispatch(setUserRegistrationData(tempArray))
            history.push('/registration/auth')
        }
        // eslint-disable-next-line
    }, [store.user_registration_data, store.active_history])


    return loading ? (
        <Wrapper>
            <Wrapper.Picture src={artwork_img} key={1}/>
            <Wrapper.Form key={2}>
                <form className={classes.wrapper} onSubmit={_onSubmit}>
                    <h2 className={classes.title}>Upload your work</h2>
                    <p className={classes.label}>Upload 3 Artworks</p>
                    <div className={classes.works_wrapper}>
                        <Uploader value={works[0] ? works[0].blob : null}
                                  delete={true}
                                  _deleteFile={() => _deleteWork(0)}
                                  onLoad={photo => _setWork(photo, 0)}/>
                        <Uploader value={works[1] ? works[1].blob : null}
                                  delete={true}
                                  _deleteFile={() => _deleteWork(1)}
                                  onLoad={photo => _setWork(photo, 1)}/>
                        <Uploader value={works[2] ? works[2].blob : null}
                                  _deleteFile={() => _deleteWork(2)}
                                  delete={true}
                                  onLoad={photo => _setWork(photo, 2)}/>
                    </div>
                    <p className={classes.label}>Explain the story behind these pieces in a couple of words.</p>
                    <p className={classes.label}>(Max 300 characters per line)</p>
                    <div className={classes.inputs_wrapper}>
                        <ResizeTextBox
                            className={classes.input}
                            value={pieces[0]}
                            borderBottomInput
                            maxLength={300}
                            placeholder="First Piece..."
                            onChange={({target}) => _setText(target.value, 0)}
                            focusBorder
                        />
                        <ResizeTextBox
                            className={classes.input}
                            value={pieces[1]}
                            maxLength={300}
                            borderBottomInput
                            placeholder="Second Piece..."
                            onChange={({target}) => _setText(target.value, 1)}
                            focusBorder
                        />
                        <ResizeTextBox
                            className={classes.input}
                            value={pieces[2]}
                            borderBottomInput
                            maxLength={300}
                            placeholder="Third Piece..."
                            onChange={({target}) => _setText(target.value, 2)}
                            focusBorder
                        />
                    </div>
                    <div className={classes.footer}>
                        <Button onClick={continueBtn ? _onSubmit : null}
                                disable={!continueBtn}>Continue &#62;</Button>
                        <p className={classes.footer__counter}>{_getCountUploadedWork()}/3 Uploaded</p>
                    </div>
                </form>
            </Wrapper.Form>
        </Wrapper>
    ) : null
}

export default Work;