import React, {useEffect, useState} from 'react'
import './styles/mailing_list.css'
import API from "../../api/API";
import Keys from "../../Constants/helper";
import downloadIcon from "../../assets/img/admin/download_xls_icon.svg";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import {getActivePage} from "../../Constants/getActivePage";
import NewPaginationView from "../../Components/NewPaginationView";
import {useHistory} from "react-router";

export default function MailingList() {
    const history = useHistory();
    let {activeHashUrl} = useGetLastChartHash()
    const [activeData, setActiveData] = useState(null)
    const [activePage, setActivePage] = useState(() => ({
        page: null,
        pages: null,
        total: null,
        loading: null
    }));

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl
            }))
        }
    }, [activeHashUrl])

    useEffect(() => {
        history.push('/admin/investors')
    }, [history])

    useEffect(() => {
        if (activePage.page) {
            API(Keys.JWT_TOKEN_ADMIN).get(`/admin/mails?page=${activePage.page}&per_page=10&export=0`)
                .then(res => {
                    setActiveData(res.data)
                    setActivePage(prevState => ({
                        ...prevState,
                        pages: res.data.pages,
                        total: res.data.total,
                        loading: true
                    }))
                })
        }
    }, [activePage.page])

    const downloadItems = () => {
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/mails?page=1&per_page=10&export=1`)
            .then(res => {
                // console.log(res)
                window.open(res.data.link)
            })
    }

    return [
        <div className={'admin_content_wrapper admin_content_wrapper_mailing'}
             key={1}>
            <div className={'title'}>
                <h1>Mailing List</h1>
                <button onClick={() => downloadItems()}>
                    <span>Download XLS</span>
                    <img src={downloadIcon} alt="download"/>
                </button>
            </div>
            <div className={'subtitle'}>
                <h4>Date</h4>
                <h4>E-mail</h4>
            </div>
            {activePage.loading ?
                <div className={'mailing_content'}>
                    {activeData.emails.map((item, index) => (
                        <div key={index}>
                            <span>{item.date}</span>
                            <span>{item.email}</span>
                        </div>
                    ))}
                </div> : null}
        </div>,
        activePage.loading && activePage.total > 9 ?
            <div className={'wrapper_new_pagination admin_pagination'}>
                key={2}>
                <NewPaginationView activePage={activePage}
                                   onChange={pageNumber => {
                                       getActivePage(pageNumber)
                                       setActivePage(prevState => ({
                                           ...prevState,
                                           page: pageNumber
                                       }))
                                   }}/>
            </div>
            : null
    ]
}