import React from 'react'

const TermsWarranty = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>6. Warranty and Liability</h3>
            <div className={'terms_of_service_item_container'}>
                <span>6.1</span>
                <span>
                    The customer must report obvious faults at latest 7 days after delivery of the goods by email
                   to <a href="mailto:info@artsted.com">info@artisfact.co.uk</a>.
                   Hidden faults must be reported immediately, at latest 7 days after discovery by email.
                   If the customer fails to report in due time, the warranty claims expire.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>6.2</span>
                <span>
                    Faulty goods must be sent back including a copy of the invoice and a detailed description of
                    the fault.
                    Upon receipt of the written complaint, the return address will be communicated to the customer
                    by email.
                    The transport costs incurred are borne by the customer.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>6.3</span>
                <span>
                    In case of warranty the Website operator decides if an appropriate reduction of the purchase
                    price, a replacement of the goods, or a reimbursement of the purchase price will be effectuated.
                    Damages arising from normal wear and tear, excessive use, any negligence, faulty storage or
                    treatment, faulty maintenance, failure to follow care instructions, or other causes in the sole
                    responsibility of the customer, and external influences not in the responsibility of the Website
                    operator are excluded from the warranty and liability.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>6.4</span>
                <span>
                    Other claims of the customer – irrespective of the legal basis – are, to the extent legally
                    permitted, excluded.
                    Neither the Website operator, nor any of its officers, directors, employees, agents, contractors
                    or other suppliers is liable to the customer for any loss, cost, damage or other injury not
                    inflicted to the goods themselves, and any consequential damages caused by a defect, lost
                    profits or other financial losses.
                </span>
            </div>
        </div>
    )
}

export default TermsWarranty