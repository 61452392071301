import React, {useState} from 'react';
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";
import MainButton from "../CustomComponents/MainButton";
import CustomCheckbox from "../CustomComponents/CheckBox";
import {useHistory} from "react-router";
import API from "../../api/API";
import {clearCookies} from "../../Constants/clearCookies";
import Keys from "../../Constants/helper";
import {setUserData} from "../../main-store/auth/actions";
import {useDispatch} from "react-redux";

const initGuestData = {
    name: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    surname: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    email: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    phone: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    password: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    repeat_password: {
        value: "",
        error: false,
        errorMessage: "Fill this field"
    },
    receive_email: false,
    collector: false,
    error: false
}

const OrderDetails = ({shippingDetails, setShippingDetails, activeData, setActiveStep}) => {
    const [guestData, setGuestData] = useState(() => initGuestData)
    const [guestRequest, setGuestRequest] = useState({
        error: "",
        disabled: false
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        phoneNumber: "",
        name: "",
        surname: "",
        confirmPassword: ""
    })
    const dispatch = useDispatch();
    const history = useHistory();

    const changeGuestData = (name, value) => {
        setGuestData(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: false,
            },
            error: null
        }))
    }

    const changeCheckbox = (name) => {
        setGuestData(state => ({
            ...state,
            [name]: !state[name]
        }))
    }

    const guestCheckout = () => {
        let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
        const {email, password, phone, name, surname, receive_email} = guestData

        if (_isFormValid()) {
            const request = {
                email: email.value,
                password: password.value,
                name: name.value,
                surname: surname.value,
                phone: phone.value,
                receive_email: receive_email,
                artworks_in_basket: bucket.map(i => i.artwork.id)
            }
            setGuestRequest(state => ({...state, disabled: true}))
            API().post(`/user/registration/fast`, request)
                .then(res => {
                    clearCookies();
                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    dispatch(setUserData({
                        name: res.data.name,
                        surname: res.data.surname,
                        role: res.data.role,
                    }));

                    localStorage.setItem('artstedBucketList', JSON.stringify([]))
                    setActiveStep(state => ({access: state.access < 2 ? 2 : state.access, active: 2}));
                })
                .catch(e => {
                    const error = e.response?.data?.error;
                    setGuestRequest(state => ({...state, error: error || "Something went wrong"}));
                })
                .finally(() => {
                    setGuestRequest(state => ({...state, disabled: false}))
                })
        }
    }

    const _isFormValid = () => {
        const errors = {};

        if (!guestData.name.value) {
            errors.name = "Should not be empty";
        }

        if (!guestData.surname.value) {
            errors.surname = "Should not be empty";
        }

        if (!guestData.email.value) {
            errors.email = "Should not be empty";
        } else if (!regExp.email.test(guestData.email.value)) {
            errors.email = "Enter valid email";
        }

        if(!guestData.phone.value) {
            errors.phoneNumber = "Should not be empty"
        } else if(!regExp.phone_number.test(guestData.phone.value)) {
            errors.phoneNumber = "Enter valid phone number"
        }

        if (guestData.password.value < 6) {
            errors.password = "Enter at least 6 characters";
        }

        if (!guestData.repeat_password.value) {
            errors.confirmPassword = "Should not be empty";
        } else if (guestData.password.value !== guestData.repeat_password.value) {
            errors.confirmPassword = "Password is not similar";
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    return (
        <div className="order-details-wrapper">
            <div className="continue">
                <span className="title">Do you have an account?</span>
                <MainButton text={'Login'}
                            onClick={() => history.push({
                                pathname: '/login',
                                state: {redirect: "/cart"}
                            })}
                            disabled={!activeData || !activeData.length}
                            className={'button_bordered'}/>
            </div>
            <div className="or">
                <div className="line"/>
                <span>OR</span>
                <div className="line"/>
            </div>
            <span className="title">Checkout as guest</span>
            <div id={'user_cart_delivery_data'} className={'user_cart_shipping_details'}>
                <div className={'user_cart_shipping_details_info'}>
                    <TextInput value={guestData.name.value}
                               error={errors.name}
                               errTitle={errors.name}
                               title={'Name'}
                               onChange={({target}) => {
                                   changeGuestData("name", target.value)
                               }}/>

                    <TextInput value={guestData.surname.value}
                               error={errors.surname}
                               title={'Surname'}
                               errTitle={errors.surname}
                               onChange={({target}) => {
                                   changeGuestData("surname", target.value)
                               }}/>


                    <TextInput value={guestData.email.value}
                               error={errors.email}
                               title={'E-mail'}
                               errTitle={errors.email}
                               onChange={({target}) => {
                                   changeGuestData("email", target.value)
                               }}/>

                    <TextInput value={guestData.phone.value}
                               error={errors.phoneNumber}
                               title={'Phone'}
                               errTitle={errors.phoneNumber}
                               onChange={({target}) => {
                                   if (regExp.phone_number.test(target.value) && target.value.length <= 20) {
                                       changeGuestData("phone", target.value)
                                   }
                               }}/>

                    <TextInput value={guestData.password.value}
                               error={errors.password}
                               title={'Password'}
                               type={"password"}
                               showPass
                               newShowPassIcon
                               errTitle={errors.password}
                               onChange={({target}) => {
                                   changeGuestData("password", target.value)
                               }}/>

                    <TextInput value={guestData.repeat_password.value}
                               error={errors.confirmPassword}
                               title={'Confirm password'}
                               type={"password"}
                               showPass
                               newShowPassIcon
                               errTitle={errors.confirmPassword}
                               onChange={({target}) => {
                                   changeGuestData("repeat_password", target.value)
                               }}/>
                </div>
                {guestData.error ?
                    <span className={'text_error'}>{guestData.error}</span>
                    : null}
            </div>
            <CustomCheckbox text={"Receive Newsletter"}
                            noMargin
                            handleChange={() => {
                                changeCheckbox("receive_email")
                            }}
                            value={guestData.receive_email}/>
            <CustomCheckbox text={"Register as collector"}
                            noMargin
                            handleChange={() => {
                                changeCheckbox("collector")
                            }}
                            value={guestData.collector}/>
            {guestRequest.error && <span className="error">{guestRequest.error}</span>}

            <MainButton disabled={!activeData || !activeData.length}
                        onClick={guestCheckout} text={"Continue"}/>
        </div>
    );
};

export default OrderDetails;
