import React, {useEffect, useState} from 'react';
import API from '../../api/API';
import classes from './styles/login.module.scss';
import Wrapper from './OnboardingWrapper';
//import artwork_img from '../../assets/img/onboarding/artwork2.png'
import Input from '../../Components/CustomComponents/Onboarding/Input';
import Button from '../../Components/CustomComponents/Onboarding/Button';
// import gmailIcon from '../../assets/img/onboarding/social/gmail.svg';
// import hederalIcon from '../../assets/img/onboarding/social/hedera.svg';
import {useHistory} from 'react-router-dom';
import Keys from '../../Constants/helper';
import {setUserData, setUserRegistrationData} from '../../main-store/auth/actions';
import {clearCookies} from '../../Constants/clearCookies';
import {useDispatch} from 'react-redux';
import regExp from '../../Constants/regExp';
import Error from '../../Components/CustomComponents/Onboarding/Error';
import Popup from "../../Components/Popup";
import {Loader} from "../../Components/Loader";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {Apple} from "../../Components/Apple";
import Facebook from "../../Components/Facebook";


function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPass] = useState("");
    const [errors, setErrors] = useState({});
    const [showErrors, setVisibilityErrors] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [artImg, setArtImg] = useState(null);
    const [facebookLogin, setFacebookLogin] = useState(false)
    const [showAppleButton, setShowAppleButton] = useState(true)
    // const [fbToken, setFbToken] = useState(null);
    const [userBlockedPopup, setUserBlockedPopup] = useState({show: null, reason: null});
    console.log(history)

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setArtImg(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                    setLoading(true)
                }
            })
        getStorageValues()
    }, [])

    useEffect(() => {
        dispatch(setUserRegistrationData(null))
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showErrors) {
            _isFormValid();
        }
        // eslint-disable-next-line
    }, [email, password]);

    const _isFormValid = () => {
        const errors = {};

        if (!showErrors) {
            setErrors({});
        }

        if (!email.trim()) {
            errors.email = "Should not be empty";
        } else if (!regExp.email.test(email)) {
            errors.email = "Enter valid email"
        }

        // todo: give back to prod
        // if (password.length < 5) {
        //     errors.password = "Enter at least 6 characters";
        // }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    const getStorageValues = () => {
        const platform = localStorage.getItem(Keys.APP_PLATFORM)
        setShowAppleButton(platform !== "android")
    }


    const _onSubmit = e => {
        e.preventDefault();

        if (!_isFormValid()) {
            setVisibilityErrors(true);
            return;
        }

        API().post(`/user/login?registration=false`, {email, password})
            .then(res => {
                // console.log(res);
                if (res.data.error === "user blocked") {
                    setUserBlockedPopup({reason: res.data.reason, show: true})
                } else if (res.data.registration_end) {
                    clearCookies();
                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    dispatch(setUserData({
                        name: res.data.name,
                        surname: res.data.surname,
                        role: res.data.role,
                        avatar: res.data.avatar ? res.data.avatar : null
                    }));

                    const redirectURL = history.location?.state?.redirect

                    //------ send selected pictures --------

                    if (redirectURL) {
                        let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
                        bucket.map((art, idx) => (
                            API().post(`/user/basket`, {id: art.artwork.id})
                                .then(() => {
                                    if (idx === bucket.length - 1) {
                                        window.location.pathname = redirectURL
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        ))

                        localStorage.setItem('artstedBucketList', JSON.stringify([]))

                        if (!bucket.length) {
                            window.location.pathname = redirectURL
                        }
                    } else {
                        window.location.pathname = (res.data.role === 'investor' || res.data.role === 'organization')
                            ? '/profile/about'
                            : '/profile/portfolio';
                    }
                } else {
                    localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
                    // console.log(res.data);
                    dispatch(setUserRegistrationData({
                        name: res.data.name,
                        surname: res.data.surname,
                        type: res.data.role
                    }))
                    history.push(res.data.role === 'artist' ? '/registration/work' : '/registration/greeting');

                    //------ send selected pictures --------

                    let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
                    bucket.map(art => (
                        API().post(`/user/basket`, {id: art.artwork.id})
                            .catch(err => {
                                console.log(err);
                            })
                    ))

                    localStorage.setItem('artstedBucketList', JSON.stringify([]))
                }
            })
            .catch(e => {
                const error = e.response?.data?.error;
                if (error === 'Wrong password') {
                    setErrors({password: error});
                } else {
                    setErrors({email: error});
                }
            })
    }

    // const resetAuthData = () => {
    //     dispatch(setAuthVariant(null))
    //     // setFbToken(null)
    //     sessionStorage.removeItem(Keys.VERIFIED_POPUP)
    //     sessionStorage.removeItem(Keys.LANDING_ROLE)
    // }

    // const accessSign = (res, path) => {
    //     clearCookies();
    //     localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
    //     dispatch(setUserData({
    //         name: res.data.name,
    //         surname: res.data.surname,
    //         role: res.data.role,
    //         avatar: res.data.avatar ? res.data.avatar : null
    //     }))
    //     window.location.pathname = path
    //     resetAuthData()
    // }

    // const loginSuccess = res => {
    //     if (res.accessToken) {
    //         API().post(`/user/oauth`, {"token": res.accessToken})
    //             .then(res => {
    //                 accessSign(res, userRole === 'investor' ? '/profile/about' : '/profile/portfolio')
    //             })
    //             .catch(() => {
    //                 // setFbToken(res.accessToken)
    //                 dispatch(setAuthVariant(true))
    //             })
    //     }
    // }

    //console.log('artImg: ', artImg[0]?.photo)

    console.log(facebookLogin, "facebooklogin")

    const loginWithApple = async (token) => {
        try {
            const res = await API().post("/user/login/apple", {
                apple_token: token

            })
            clearCookies();
            localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
            dispatch(setUserData({
                name: res.data.name,
                surname: res.data.surname,
                role: res.data.role,
                avatar: res.data.avatar ? res.data.avatar : null
            }));

            const redirectURL = history.location?.state?.redirect

            //------ send selected pictures --------

            if (redirectURL) {
                let bucket = localStorage.getItem('artstedBucketList') ? JSON.parse(localStorage.getItem('artstedBucketList')) : []
                bucket.map((art, idx) => (
                    API().post(`/user/basket`, {id: art.artwork.id})
                        .then(() => {
                            if (idx === bucket.length - 1) {
                                window.location.pathname = redirectURL
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                ))

                localStorage.setItem('artstedBucketList', JSON.stringify([]))

                if (!bucket.length) {
                    window.location.pathname = redirectURL
                }
            } else {
                window.location.pathname = (res.data.role === 'investor' || res.data.role === 'organization')
                    ? '/profile/about'
                    : '/profile/portfolio';
            }
        } catch (e) {
            const error = e.response?.data?.error;
            setErrors({email: error});
            console.log(e)
        }
    }

    return (
        <Wrapper>
            <Wrapper.Form>
                <div className={classes.login}>
                    {facebookLogin &&
                    <div key={35} className={'new_modal_wrapper'}>
                        <ModalWindow>
                            <h4>You can’t log in with facebook</h4>
                            <p>Your facebook account doesn’t have an e-mail address.</p>
                            <div className={'modal-window_buttons'}>
                                <div></div>
                                <MainButton type={'button'}
                                            style={{background: '#000', color: '#fff'}}
                                            className={'button_black'}
                                            text={'Cancel'}
                                            onClick={() => {
                                                setFacebookLogin(false)
                                                bodyOverflow(false)
                                            }}
                                />
                            </div>
                        </ModalWindow>
                    </div>
                    }
                    <h1 className={classes.title}>Login to Artsted!</h1>
                    <div style={{marginTop: "24px"}}>
                        <Facebook
                            setFacebookLogin={setFacebookLogin}
                            registration={false}
                        />
                        {showAppleButton && <Apple onSuccess={loginWithApple} />}
                    </div>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <Input
                            className={classes.input}
                            value={email}
                            onChange={({target}) => setEmail(target.value.trim())}
                            title="E-Mail"
                            error={errors.email}
                            type="email"
                            // autoComplete="off"
                        />
                        <Input
                            className={classes.input}
                            value={password}
                            onChange={({target}) => setPass(target.value.trim())}
                            title="Password"
                            error={errors.password}
                            type="password"
                        />
                        <Button
                            variant="link"
                            type="button"
                            style={{marginTop: '24px'}}
                            onClick={() => history.push({pathname: '/reset-password', email})}>
                            Forgot Password?
                        </Button>
                        <div className={classes.errors}>
                            {Object.keys(errors).map(key => {
                                return <Error text={errors[key]} key={key}/>
                            })}
                        </div>
                        <div className={classes.form_footer}>
                            <div className={classes.footer_btns}>
                                <Button type="submit" className={classes.submit}>Continue &#62;</Button>
                                <Button variant="link"
                                        type="button"
                                        onClick={() => history.push('/registration/type')}>Not
                                    a
                                    Member? Register</Button>
                            </div>
                            {/*<div className={classes.facebookLoginContainer}>*/}
                            {/*<div>*/}
                            {/*    <Facebook setFacebookLogin={setFacebookLogin} registration={false}/>*/}
                            {/*</div>*/}
                            {/*<Social className={classes.footer_social}>*/}
                            {/*    /!*<Social.Item icon={gmailIcon}*!/*/}
                            {/*    /!*             text="Register with Gmail"*!/*/}
                            {/*    /!*             onClick={null}/>*!/*/}
                            {/*    <FacebookLogin*/}
                            {/*        appId="2618139161816939"*/}
                            {/*        autoLoad={false}*/}
                            {/*        fields="name,email,picture"*/}
                            {/*        callback={loginSuccess}*/}
                            {/*        render={renderProps => <Social.Item type="fb" icon={fbIcon}*/}
                            {/*                                            text="Register with Facebook"*/}
                            {/*                                            onClick={renderProps.onClick}/>}*/}
                            {/*    />*/}
                            {/*    /!*<Social.Item icon={hederalIcon} text="Register with Hedera" onClick={null}/>*!/*/}
                            {/*</Social>*/}
                            <Button
                                variant="link"
                                className={classes.footer_reg_btn_sm}
                                type="button"
                                onClick={() => history.push('/registration/type')}
                            >
                                Not a Member? Register
                            </Button>
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
            {artImg && loading ?
                <Wrapper.Picture src={artImg.photo} imgRight artAuthor={artImg.user.name}
                                 authorAvatar={artImg.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
            {userBlockedPopup.show ?
                <Popup closeCallback={() => setUserBlockedPopup({show: false})}
                       key={'2'}
                       title={'User is blocked'}
                       to_top={true}
                       description={userBlockedPopup.reason}/>
                : null}
        </Wrapper>
    );
}

export default Login;
