import React, {useState} from "react";
import './styles/stay_in_loop.css'
import {Link} from "react-router-dom";
import regExp from "../Constants/regExp";
import API from "../api/API";

const initialState = {
    value: '',
    error: false,
    errorMessage: `This field can't be empty`
}

export default function StayInLoop() {

    const [activeSubscribe, setActiveSubscribe] = useState(initialState)
    const [successful, setSuccessful] = useState(null)

    const handleSubscribe = (e) => {
        e.preventDefault();
        if (activeSubscribe.value) {
            if (regExp.email.test(activeSubscribe.value)) {
                API().post(`/landing/subscribe`, {email: activeSubscribe.value})
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessful(true)
                            setActiveSubscribe(initialState)
                            setTimeout(() => {
                                setSuccessful(false)
                            }, 3000)
                        }
                    })
            } else {
                setActiveSubscribe(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: `Wrong email format`,
                }))
            }
        } else {
            setActiveSubscribe(prevState => ({
                ...prevState,
                error: true,
                errorMessage: `This field can't be empty`,
            }))
        }
    }

    return (
        <div className={'stay_in_loop_wrapper'}>
            <div className={'stay_in_loop_bg'}>
                <div>
                    <h3>Stay in the loop </h3>
                    <div className={'successful_email'}>
                        {successful
                            ? <span>You succesfully subscribed to newsletter!</span>
                            : <p>Discover new art and collections added weekly</p>}
                    </div>
                    <form onSubmit={(e) => handleSubscribe(e)}>
                        <label>
                            {activeSubscribe.error ?
                                <span>{activeSubscribe.errorMessage}</span> : null}
                            <input style={activeSubscribe.error
                                ? {border: '1px solid #fe3e3e'} : null}
                                   onChange={({target}) => {
                                       setActiveSubscribe(prevState => ({
                                           ...prevState,
                                           error: false,
                                           value: target.value
                                       }))
                                   }}
                                   value={activeSubscribe.value}
                                   type="text"
                                   placeholder={'Your E-mail'}/>
                            <button type={'submit'}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M40 0H0V40H40V0ZM19.2976 13H13L20.792 20.1795L13 27.359H19.2976L27.0541 20.1795L19.2976 13Z"
                                          fill="white"/>
                                </svg>
                            </button>
                        </label>
                    </form>
                </div>
                <Link to={'/premium'}>Learn about Artsted Premium ></Link>
            </div>
        </div>
    )
}