import React from "react";
import CustomDropdown from "../CustomComponents/Dropdown";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";
import MainButton from "../CustomComponents/MainButton";
import TextInput from "../CustomComponents/TextInput";
import regExp from "../../Constants/regExp";
import API from "../../api/API";
import {usePlacesWidget} from "react-google-autocomplete";
//import {setCanCreate} from "../../main-store/auth/actions";

const StripeFields = ({setStripeFields, stripeFields, stripeSubmit, setCanCreateRef}) => {
    const {ref: autoFillProp} = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            console.log(place)
            const address_components = place?.address_components;
            let components = {};
            if (address_components) {
                address_components.forEach(v1 => {
                        v1.types.forEach(v2 => {
                            components[v2] = (v2 === "administrative_area_level_1" || v2 === "country") ? {
                                value: v1.short_name,
                                preview: v1.long_name
                            } : v1.long_name
                        });
                    }
                );
                console.log(components)

                if (components.street_number) {
                    const address = (components.street_number ? (components.street_number + " ") : "") +
                        (components.route ?? "")
                    const city = (components.locality ?? "")
                    const state = (components.administrative_area_level_1 ?? "")
                    const zip = (components.postal_code ?? "")
                    const country = (components.country.preview ?? "")
                    const country_code = (components.country.value ?? "")

                    setSelectedAddress(country, country_code, address, city, state, zip)
                } else {
                    setStripeFields(prevState => ({
                        ...prevState,
                        street: {
                            ...prevState.street,
                            error: true,
                            errorMessage: "The house number is missing"
                        }
                    }))
                }
            }
        },
        inputAutocompleteValue: "country",
        language: "en",
        options: {
            types: ['geocode'],
        },
    });

    const setSelectedAddress = (country, country_code, address, city, state, zip) => {
        console.log(address, city, state, zip)
        setStripeFields(prevState => ({
            ...prevState,
            country_code,
            country: {
                ...prevState.country,
                value: country,
                error: false
            },
            state: {
                ...prevState.state,
                value: state.value,
                error: false,
            },
            street: {
                ...prevState.street,
                value: address,
                error: false,
            },
            city: {
                ...prevState.city,
                value: city,
                error: false,
            },
            zip: {
                ...prevState.zip,
                value: zip,
                error: false,
            },
            error: null
        }))
    }

  console.log('stripeFields.country.value: ', stripeFields)

    return (
        <form className={'profile_section_body_stripe_info'}
              onSubmit={(e) => {
                  stripeSubmit(e)
                  API().get(`/user/profile/info`)
                      .then(res => {
                          if (res.data.can_create === true) {
                              console.log('res: ', res.data.can_create)
                              setCanCreateRef(res.data.can_create)
                              // window.location.href = "/profile/settings"
                          }
                      })
              }}>
            <TextInput value={stripeFields.street.value}
                       error={stripeFields.street.error}
                       newError={stripeFields.street.error}
                       title={'Street'}
                       borderBottomInput
                       ref={autoFillProp}
                       errTitle={stripeFields.street.errorMessage}
                       onChange={({target}) => {
                           setStripeFields(prevState => ({
                               ...prevState,
                               street: {
                                   ...prevState.street,
                                   value: target.value,
                                   error: false,
                               },
                               error: null
                           }))
                       }}/>

                <CustomDropdown value={stripeFields.country.value}
                                setInputValue={val => setStripeFields(val)}
                                errTitle={'Please select your Country'}
                                optionLabel={'country'}
                                error={stripeFields.country.error}
                                newError={stripeFields.country.error}
                                placeholder={'England'}
                                disabled
                                tooltip="The country is filling in automatically according to the address"
                                search
                                borderDropdown
                                dropdownSelect
                                newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                        fill="black"/>
                                </svg>
                                }
                                title={'Country'}
                                defaultDropdown
                                onChange={({target}) => {
                                    setStripeFields(prevState => ({
                                        ...prevState,
                                        country: {
                                            ...prevState.country,
                                            value: target.value,
                                            error: null,
                                            popup: true
                                        },
                                        error: null
                                    }))
                                }}
                                dropdownPadding={'5px 16px'}
                                dropdownIcon={greyChevron}
                                popup={stripeFields.country.popup}
                                options={stripeFields.country.options}/>

            <TextInput value={stripeFields.state.value}
                       error={stripeFields.state.error}
                       newError={stripeFields.state.error}
                       title={'State'}
                       borderBottomInput
                       errTitle={stripeFields.state.errorMessage}
                       onChange={({target}) => {
                           setStripeFields(prevState => ({
                               ...prevState,
                               state: {
                                   ...prevState.state,
                                   value: target.value,
                                   error: false,
                               },
                               error: null
                           }))
                       }}/>

            <TextInput value={stripeFields.city.value}
                       error={stripeFields.city.error}
                       newError={stripeFields.city.error}
                       title={'City'}
                       borderBottomInput
                       errTitle={stripeFields.city.errorMessage}
                       onChange={({target}) => {
                           setStripeFields(prevState => ({
                               ...prevState,
                               city: {
                                   ...prevState.city,
                                   value: target.value,
                                   error: false,
                               },
                               error: null
                           }))
                       }}/>

            <TextInput value={stripeFields.zip.value}
                       error={stripeFields.zip.error}
                       newError={stripeFields.zip.error}
                       title={'Zip'}
                       errTitle={stripeFields.zip.errorMessage}
                       maxLength={255}
                       borderBottomInput
                       onChange={({target}) => {
                           setStripeFields(prevState => ({
                               ...prevState,
                               zip: {
                                   ...prevState.zip,
                                   value: target.value,
                                   error: false,
                               },
                               error: null
                           }))
                       }}/>

            <TextInput value={stripeFields.phone.value}
                       error={stripeFields.phone.error}
                       newError={stripeFields.phone.error}
                       title={'Phone'}
                       borderBottomInput
                       errTitle={stripeFields.phone.errorMessage}
                       onChange={({target}) => {
                           if (regExp.phone_number.test(target.value) && target.value.length <= 20) {
                               setStripeFields(prevState => ({
                                   ...prevState,
                                   phone: {
                                       ...prevState.phone,
                                       value: target.value,
                                       error: false,
                                   },
                                   error: null
                               }))
                           }
                       }}/>

            {stripeFields.error ?
                <span className={'text_error'}>
                   {stripeFields.error}
               </span>
                : null}

            <MainButton
                text={'Save'}
                className={'button_black'}
                type={'submit'}/>
        </form>
    )
}

export default StripeFields