import {useEffect, useRef, useState} from 'react';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {fromAddress, setDefaults} from "react-geocode";

setDefaults({
    key: process.env.REACT_APP_MAPS_API_KEY,
    language: "en",
    region: "es",
});

const mapStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '0',
};

function GoogleMap({coordinates, address, ...props}) {
    const markerRef = useRef();
    const [coords, setCoords] = useState(coordinates);

    useEffect(() => {
        if (address) {
            fromAddress(address)
                .then(({results}) => {
                    const {lat, lng} = results[0].geometry.location;
                    setCoords({lat, lng})
                })
                .catch(console.error);
        }
    }, [address])

    return (
        <div style={{
            position: 'relative',
            minHeight: 300
        }}>
            {
                coords ?
                    <Map
                        google={props.google}
                        zoom={14}
                        tilt={0}
                        mapTypeControl={false}
                        streetViewControl={false}
                        zoomControl
                        mapType="roadmap"
                        rotateControl={false}
                        center={coords}
                        style={mapStyles}
                        initialCenter={coords || {}}
                    >
                        <Marker ref={markerRef} position={coords}/>
                    </Map> : <div style={{backgroundColor: 'rgba(0,0,0,0.08)', height: '300px'}}/>
            }
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(GoogleMap);
