import React from "react";
import './styles/listBlockSection.css'

const ListBlockSection = (props) => {
    return (
        <div>
            <h2 className={'block_title'}>{props.title}</h2>
            <div className={'block_container'}>
                {props.blockContent.map(e => (
                    <div className={'block_item'}>
                        {e.svg}
                        <span className={'block_item_text'}>{e.text}</span>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default ListBlockSection
