import React, {useEffect, useState} from 'react';
import Confetti from 'react-confetti'
import './styles/post_checkout.css'
import {Link} from "react-router-dom";
import SEO from "../../Components/SEO";
import payment_failed from "../../assets/img/payment_failed.svg";

export default function PostCheckout({status}) {
    const success = status === "success"
    const lastBuy = sessionStorage.getItem('arstedCartLastBuy')
    const {height, width} = useWindowDimensions();
    return (
        <div className={'successful_order_wrapper'}>
            <SEO key={1} title="Shopping cart: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Shopping cart: Buy art online Artsted">
                <meta name="robots" content="noindex, nofollow"/>
            </SEO>
            {success && <Confetti
                width={width}
                height={height}
                gravity={0.05}
                tweenDuration={100000}
                numberOfPieces={3000}
                recycle={false}
            />}
            <div className={'successful_order_background'}/>
            <div className={'order_wrapper_content'}>
                <h1>Shopping cart</h1>
            </div>
            <div className={'logo_order'}>
                {success
                    ? <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M28.8846 1.57443C29.8375 1.04526 30.9095 0.767578 31.9994 0.767578C33.0894 0.767578 34.1614 1.04526 35.1143 1.57443L59.2212 14.964C59.7209 15.2419 60.1374 15.6484 60.4273 16.1413C60.7172 16.6343 60.87 17.1957 60.87 17.7676V44.1841C60.8697 45.3282 60.5635 46.4514 59.9831 47.4373C59.4028 48.4232 58.5693 49.236 57.5691 49.7914L35.1143 62.2699C34.1614 62.7991 33.0894 63.0768 31.9994 63.0768C30.9095 63.0768 29.8375 62.7991 28.8846 62.2699L6.42977 49.7914C5.43007 49.2363 4.59691 48.424 4.01656 47.4387C3.43621 46.4534 3.12975 45.3309 3.12891 44.1874V17.7676C3.12888 17.1957 3.28174 16.6343 3.57164 16.1413C3.86154 15.6484 4.27794 15.2419 4.77773 14.964L28.8878 1.57443H28.8846Z"
                            stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3.5 16L31.9994 31.921M31.9994 31.921L60.5 16M31.9994 31.921V63" stroke="black"
                              strokeLinejoin="round"/>
                        <path d="M17.5625 23.9025L46.433 7.86328" stroke="black" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M12.7539 32.9717L22.3774 38.3352" stroke="black" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                    : <img className={'delivery_logo'} src={payment_failed} alt="delivery_logo"/>
                }
            </div>
            <div className={'order_details'}>
                <h2>{
                    success
                        ? "Great job! Your order has been placed successfully."
                        : "Failed Payment! We are sorry, but something went wrong!"
                }</h2>
                {success
                    ? lastBuy === 'NFT' ?
                        <p>You can see it on your <Link to={'/profile/orders'}>personal profile</Link> in Orders
                            Section.
                            You will be informed by e-mail for further purchase instructions.</p>
                        :
                        <p>You can see it on your <Link to={'/profile/orders'}>personal profile</Link> in Orders
                            Section.
                            As soon as the Tracking Information will be available you will be informed.</p>
                    : <p>You can try to repeat your payment later. <br/>Or contact our customer service <Link
                        to={'/profile/support'}>here</Link>.</p>
                }

            </div>
        </div>
    )
}


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
