import React from "react";
import './styles/header_search_popup.css'
import {Link} from "react-router-dom";
import avatar_placeholder from "../assets/img/new_landing/profile_avatar_default.svg";
import Keys from "../Constants/helper";
import {bodyOverflow} from "../Constants/bodyOverflow";
import {Loader} from "./Loader";

export default function HeaderSearchWrapper(props) {
    const {activeSearch, setActiveSearch} = props;
    return (
        activeSearch.options ?
            <div className={'header_search_popup'}>
                {activeSearch.loading ? Object.entries(activeSearch.options).map((el, i) => (
                    <div key={i} className={'content'}>
                        <h4>{el[0]}</h4>
                        <div className={'items'}>
                            {el[1].length ? el[1].map((item) => (
                                <div key={item.id} className={'header_search_popup_item'}>
                                    <Link onClick={(e) => {
                                        if (!localStorage.getItem(Keys.JWT_TOKEN) && el[0] === 'artists') {
                                            e.preventDefault();
                                            // setAuthPopUp('Registration')
                                            window.location = "/registration/type"
                                            bodyOverflow(true)
                                        }
                                        setActiveSearch(prevState => ({
                                            ...prevState,
                                            popup: false
                                        }))
                                    }}
                                          to={el[0] === 'artists'
                                              ? `/artist-profile/${item.profile_url}`
                                              : `/public-artwork/${item.id}`}>
                                        <img className={'avatar_search_header'}
                                             src={item.avatar ?? item.photo ?? avatar_placeholder}
                                             alt="avatar"/>
                                        <span>{item.name ?? item.title}</span>
                                    </Link>
                                </div>
                            )) : <span>No results</span>}
                        </div>
                    </div>
                )) : <div className={'loader_wrapper'}><Loader  color={{
                    '--bgColor': '#000',
                    '--size': '24px',
                    '--wrapper_size': '20px'
                }}/></div>}
            </div>
            : <Loader  color={{
                '--bgColor': '#000',
                '--size': '24px',
                '--wrapper_size': '20px'
            }}/>
    )
}
