import React from "react";
import './styles/cardItemsSection.css'

const CardItemsSection = (props) => {
    return (
        <div>
            <h1 className={'card_main_title'}>{props.title}</h1>
            <div className={'card_item_section'}>
                {props.items.map((item, index) => (
                    <div key={index} className={'card_item_container'}>
                        <span className={'card_title'}>{item.title}</span>
                        <span>{item.subTitle}</span>
                        {item?.list?.map((e) => (
                            <span className={'list_item'}>{e}</span>
                        ))}
                    </div>
                ))}

            </div>
        </div>
    )
}

export default CardItemsSection
