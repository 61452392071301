import React, {useEffect, useState} from "react";
import './styles/new_artworks.css'
import StayInLoop from "../Components/StayInLoop";
import useResize from "../Constants/useResize";
import TextInput from "../Components/CustomComponents/TextInput";
import regExp from "../Constants/regExp";
import ArtworkInfoCard from "../Components/ArtworkInfoCard";
import {getActivePage} from "../Constants/getActivePage";
import NewPaginationView from "../Components/NewPaginationView";
import NewFiltersWrapper from "../Components/FilterComponents/NewFiltersWrapper";
import artistPhoto from '../assets/img/new_landing/artist_photo.png'
import artistPhotoNFT from '../assets/img/new_landing/artist_photo_nft.jpg'
import NewRangeSliderMultiple from "../Components/CustomComponents/NewRangeSliderMultiple";
import {Loader} from "../Components/Loader";
import SEO from "../Components/SEO";
import {useParams} from "react-router";
import ExtendSEO from "../Components/ExtendSEO";
import RadioButton from "../Components/CustomComponents/RadioButton";
import MainButton from "../Components/CustomComponents/MainButton";
import chevron_bottom from "../assets/img/chevron_bottom.svg"
import {connect} from "react-redux";
import Keys from "../Constants/helper";
import "./styles/listings.css"

const filtersIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 4H3.5V6H4.5V4ZM7.05556 6H8.05556V4H7.05556V6ZM20.5 6H21.5V4H20.5V6ZM11.6111 4H10.6111V6H11.6111V4ZM4.5 11H3.5V13H4.5V11ZM14.1667 13H15.1667V11H14.1667V13ZM20.5 13H21.5V11H20.5V13ZM18.7222 11H17.7222V13H18.7222V11ZM4.5 18H3.5V20H4.5V18ZM5.27778 20H6.27778V18H5.27778V20ZM20.5 20H21.5V18H20.5V20ZM9.83333 18H8.83333V20H9.83333V18ZM4.5 6H7.05556V4H4.5V6ZM20.5 4H11.6111V6H20.5V4ZM4.5 13H14.1667V11H4.5V13ZM20.5 11H18.7222V13H20.5V11ZM4.5 20H5.27778V18H4.5V20ZM20.5 18H9.83333V20H20.5V18Z"
            fill="white"/>
        <path
            d="M6.5 7L6.5 8L8.5 8L8.5 7L6.5 7ZM8.5 3L8.5 2L6.5 2L6.5 3L8.5 3ZM8.5 7L8.5 3L6.5 3L6.5 7L8.5 7Z"
            fill="white"/>
        <path
            d="M13.5 14L13.5 15L15.5 15L15.5 14L13.5 14ZM15.5 10L15.5 9L13.5 9L13.5 10L15.5 10ZM15.5 14L15.5 10L13.5 10L13.5 14L15.5 14Z"
            fill="white"/>
        <path
            d="M5.5 21L5.5 22L7.5 22L7.5 21L5.5 21ZM7.5 17L7.5 16L5.5 16L5.5 17L7.5 17ZM7.5 21L7.5 17L5.5 17L5.5 21L7.5 21Z"
            fill="white"/>
    </svg>
)

const initialState = {
    listing_search: {
        value: ''
    },
    category: {
        activeList: [],
        list: []
    },
    styles: {
        activeList: [],
        list: []
    },
    mediums: {
        activeList: [],
        list: []
    },
    sort_by: {
        activeList: [],
        list: [{name: 'price_asc', preview: 'Price asc'}, {name: 'price_desc', preview: 'Price desc'}],
    },
    materials: {
        activeList: [],
        list: []
    },
    max_price: null,
    price: {
        max: null,
        min: 0
    },
    width: {
        max: 500,
        min: 0
    },
    height: {
        max: 500,
        min: 0
    },
}

const initialSeoText = {
    title: "Contemporary Artists",
    description: <span>The post-World War II era up till the present is usually regarded as the period of Contemporary Art. It includes various forms, genres, and aesthetic philosophies, making it challenging to understand completely. That's where Artsted comes to the rescue: in this article, we'll highlight contemporary art movements so you can completely comprehend this creative era.
<br/><br/>
<b>1. Environmental Art (1960 – present)</b><br/>
Art addressing ecological challenges in a formal, political, historical, social, or aesthetic setting is called Environmental Art. The phrase Environmental Art, originally used to refer to Earth Art, has grown to include urban landscapes.
<br/><br/>
<b>2. Identity Art & Identity Politics (1960 – present)</b><br/>
LGBTQ+ artists, women, and artists of colour have staged and shown experiences of self-identification and community via art since the 1960s.
<br/><br/>
<b>3. Digital art (1965 – present)</b><br/>
Artists would use cutting-edge technology like computers, the internet, and a/v software for their artistic output in the digital era. Artists and inventors began to push art into new, occasionally virtual, worlds in the 1960s.
<br/><br/>
<b>4. Street & Graffiti Art (1967 – present)</b><br/>
Any visual art produced in public spaces, particularly graffiti and often illegal murals, is considered street art.
<br/><br/>
<b>5. Institutional Critique (1968 – present)</b><br/>
The process of conducting a methodical investigation of the operations of art institutions and their relationships to the advancement of art is known as Institutional Critique. The links between language, method, art consumption, and the viewer are the main topics of Institutional Critique.
<br/><br/>
<b>6. Installation Art (1970 – present)</b><br/>
Contemporary art forms like installation art combine two- and three-dimensional materials to turn a specific location into an immersive environment for the viewer. Installations can be temporary or permanent and contain found objects, sound-based, performance, and sculptural components.
<br/><br/>
<b>7. Relational Aesthetics (1996 – present)</b> <br/>
Artwork that creates a transient atmosphere or event in which spectators may engage to absorb and understand the artist's message or concept is the emphasis of relational aesthetics.
<br/><br/>
<b>8. Superflat (2000 – present)</b><br/>
Takashi Murakami developed the postmodern art style known as Superflat, which takes its name from the ubiquitous and traditional flattened shapes seen in Japanese animation, pop culture, fine arts, and graphic art. To examine topics like consumerism and sexual fetishism, Superflat juxtaposes conventional and contemporary approaches.
<br/><br/>
Artsted is the only online marketplace offering high-quality, contemporary art for investment with transparent prices. We work directly with the artists and provide a distinctive selection of contemporary works of art from emerging and mid-career creators, allowing you to select from some of the most fantastic pieces of art on the whole globe.
All of the unique, one-of-a-kind art pieces are for sale and may be bought from our website.
The artwork and a certificate of authenticity will be shipped to you within seven business days after you choose it, add it to your basket, and complete your transaction.
We work with all contemporary mediums: digital, photography, prints, sculptures, installation, painting, video, drawing, and NFT artworks. If you want to buy the perfect piece of art, we are convinced you will find it at Artsted - every taste can be satisfied by what we provide!
To view the featured artists, go to the main page, click on artworks, and choose a category from the list on the left. You may narrow your search by selecting the media, dimensions, pricing, and styles that interest you.
Invest in contemporary artists with Artsted!</span>
}

const titleByCategory = {
    digital: {
        h1: "Digital Art for Sale",
        title: "Original digital art for sale | Art investment: Artsted",
        description: "Digital artworks ♦ Buy limited edition art-works by Recognized Artists Online ♦ Famous digital art pieces ♦ Buying art as an investment with Art marketplace: Artsted",
        seo_title: "Digital Art",
        seo_description: "“Skin has become inadequate in interfacing with reality. Technology has become the body’s new membrane of existence.” – Nam June Paik\n" +
            "\n" +
            "Digital art is, in the broadest sense, any creative endeavour that utilises any form of digital technology during its conception or presentation. Digital art is the first medium to significantly alter the fabric of art production since the invention of the camera. \n" +
            "Between 1950 and 1970, the information era — also known as the digital age — marched around the globe, and it was only a matter of time until creators of all kinds embraced its revolutionary technology. With minds always eager for the ample opportunities to use contemporary means to evolve their voices in a new way, as they did with all new mediums, artists started to incorporate these bold new societal innovations into their works, such as television, the invention of the personal computer, the availability of audio and visual software, and eventually the internet. \n" +
            "Digital art will continue to develop into a diverse, ever-changing landscape as technology continues its rapid jackrabbit bloom into modern society, establishing itself as a credible alternative to traditional methods of art-making for a post-millennial community, even though it is not yet recognised as a distinct movement. \n" +
            "\n" +
            "All of the digital artworks for sale on our website are one-of-a-kind, original works of art. Artsted is the only online marketplace offering investment-grade digital artworks with transparent prices.\n" +
            "We have a variety of digital artworks available for you, including vibrant figurative, abstract, political, narrative and symbolist. Choose the digital artwork you love, put it in the cart, and finish the purchase - within 7 business days, the digital artwork and a certificate of authenticity will be mailed to you. \n" +
            "As a result of our close collaboration with the artists, we can provide a unique selection of distinctive contemporary digital artworks from up-and-coming and mid-career artists. With us, you will discover high-calibre digital artworks made by some of the finest artists in the world. \n" +
            "Because we offer something to fit every taste, we are convinced that Artsted has what you're looking for if you want to purchase the appropriate digital artwork. To view the featured artists, go to the main page, click on artworks, and select digital art from the menu on the left. \n" +
            "You may narrow your search by choosing the sizes, shapes, and prices you're interested in.\n" +
            "\n" +
            "Invest in contemporary digital artists with Artsted!"
    },
    pencil: {
        h1: "Original pencil drawings for sale",
        title: "Original pencil drawings for sale | Art investment: Artsted",
        description: "Pencil art drawings for sale in an online gallery: Artsted ♦ Buying pencil drawings as an investment with Art marketplace: Artsted"
    },
    "nft-digital photography": {
        h1: "NFT photo gallery",
        title: "NFT photo gallery | Art investment: Artsted",
        description: "Buy nft photos in an online gallery: Artsted ♦ Best place to sell or buy nft photos ♦ Buying art as an investment with Art marketplace"
    },
    "abstract": {
        h1: "Original abstract artwork for sale",
        title: "Modern abstract art for sale | Art investment: Artsted",
        description: "Abstract paintings for sale in an online gallery: Artsted ♦ Buying art as an investment with Art marketplace"
    },
    "wood": {
        h1: "Wood art for sale",
        title: "Wood art for sale | Art investment: Artsted",
        description: "Wood wall art  for sale in an online gallery: Artsted ♦ Buying wood art as an investment with Art-marketplace"
    },
    "ceramics": {
        h1: "Ceramic art for sale",
        title: "Ceramic art for sale | Art investment: Artsted",
        description: "Сeramic art works in an online gallery: Artsted ♦ Buying ceramic art as an investment with Art-marketplace"
    },
    "sculpture-abstract": {
        h1: "Abstract sculpture for sale",
        title: "Abstract sculpture for sale | Art investment: Artsted",
        description: "Abstract sculpture artwowrks in an online gallery: Artsted ♦ Buying famous abstract sculpture artists as an investment with Art-marketplace"
    },
    photograph: {
        h1: "Photos for Sale",
        title: "Photographs for sale online | Art investment: Artsted",
        description: "Buying photographs as art ♦ Unique photos ♦ A selection of high-quality work from world’s best artists ♦ Buying art as an investment with Art marketplace: Artsted",
        seo_title: "Photography",
        seo_description: "“The most astonishing possibilities remain to be discovered in the raw material of the photograph” – László Moholy-Nagy.\n" +
            "\n" +
            "Greek etymology of the term photography refers to the art of sketching with light. Although the camera obscura was first discovered in ancient China, it took hundreds of years for all the components of this intricate process to come together. \n" +
            "Combining several technical advancements related to viewing and capturing a picture resulted in photography as we know it. The first monochrome image, created by French scientist Joseph Nicéphore Niépce in1826, was the first of its kind; colour photography followed 35 years later.\n" +
            "A considerable aesthetic change in photographic production and a change in how photography was created, utilised, and valued were brought about by the development of modern photography in the 20th century. Contemporary photography is diverse, with genres such as abstract, surrealist, documentary, fashion, group, photojournalism, photorealism, pictorialism, straight, and street photography. \n" +
            "Many contemporary artists have chosen this medium as source material and a genre of art.\n" +
            "\n" +
            "\n" +
            "The only platform that provides investment-grade photography with transparent costs listed beside it is Artsted. All photographs are one-of-a-kind, authentic, for sale, and can be purchased directly from our website. \n" +
            "All you have to do is pick the photograph that speaks to you, add it to the cart, finish the transaction, and the artwork will be shipped to you along with a certificate of authenticity within 7 business days. You may choose from a variety of high-calibre pieces created by some of the top photographers in the world since we collaborate closely with the artists to deliver an exclusive collection of unique contemporary photography from emerging and mid-career artists. \n" +
            "We display images in various genres, including political, abstract, narrative, symbolist, and emotional. Our realistic and representational photos are also included.\n" +
            "We are confident that you will discover what you're searching for at Artsted if you're looking to buy the perfect photograph. We have something to suit every taste. Go to the home page, click on artworks, and then pick photography from the category list on the left to view the photographers that we feature.\n" +
            "You may focus your search by choosing the styles, dimensions, and prices you're interested in. \n" +
            "\n" +
            "Invest in contemporary photographers with Artsted now!"
    },
    print: {
        h1: "Art Prints for Sale",
        title: "Original prints for sale | Art investment: Artsted",
        description: "Art marketplace: buy high quality art prints ♦ Sell limited edition art-works by Recognized Artists Online ♦ Collectors Prints ♦ Buying art as an investment with Art marketplace: Artsted"
    },
    painting: {
        h1: "Paintings for Sale",
        title: "Paintings on sale online | Art investment: Artsted",
        description: "Original paintings on sale ♦ Investing in paintings ♦ Original paintings ♦ Fine art for sale by owner ♦ Buying art as an investment with Art marketplace: Artsted",
        seo_title: "Painting",
        seo_description: "Over time, especially from the 1950s, when we entered the Modern Art era, artists began to value the concept over simply figurative art, as they began to consider this old-fashioned form of painting a craft rather than art in its higher sense; focusing on the concept has resulted in many contemporary painters to have continued to work in the medium, either as a whole or as part of their work. Even with the advent of different digital art forms in the 1960s, painting — the oldest form of Fine Art with an estimated 40,000-year history — remains essential. \n" +
            "Even though painting has frequently been considered a kind of visual art in decline during the past 70 years, we now see that it is a vibrant and adaptable medium. The audience and the market determine the value of the significant paintings that artists continue to produce in various styles and aesthetics. \n" +
            "There isn't and can't be a clear consensus on a representative style of the time because we live in a pluralistic age, so scroll through our selection of paintings, and find what speaks to you!\n" +
            "\n" +
            "All of the paintings on our website are unique, one-of-a-kind pieces of art. The only online marketplace featuring investment-grade paintings with transparent pricing is Artsted.\n" +
            "You may choose from various paintings, including lively realistic, abstract, political, narrative, and symbolist pieces. Select your favourite painting, add it to your shopping cart, and complete the transaction – the painting will be shipped along with a certificate of authenticity within 7 business days. \n" +
            "We can provide a distinctive collection of contemporary paintings from emerging and mid-career artists due to our close relationship with the artists. You can get your hands on the world-class paintings created by some of the best artists with us.\n" +
            "We are confident that Artsted has what you're seeking if you want to buy a perfect painting because we have something to suit every taste. Go to the home page, click on artworks, and then choose paintings from the menu on the left to browse the featured artists.\n" +
            "You may focus your search by selecting the sizes, mediums, and prices you're interested in. \n" +
            "\n" +
            "With Artsted, you can invest in contemporary painters; why wait?",
    },
    drawing: {
        h1: "Original drawings for Sale",
        title: "Original drawings for sale | Art investment: Artsted",
        description: "Sell drawing online ♦ With Art marketplace: Artsted ♦ Unique drawings by artists ♦ Buy sketches and Drawing works by Recognized Artists Online",
        seo_title: "Drawings",
        seo_description: "\"Drawing is the probity of art. To draw does not mean to reproduce contours; drawing does not consist merely of line: drawing is also expression, the inner form, the plane, and modelling. See what remains after that!\" – Jean-Auguste-Dominique Ingres.\n" +
            "\n" +
            "Since the dawn of human history, drawing has been a primary form of artistic expression and is one of the first forms of human expression in the visual arts. Drawing has always been seen as a motivation for creative endeavours. \n" +
            "Drawings were initially made on wood, but as paper became widely available in the 14th century, the usage of drawing in the arts increased. Drawing evolved into a research and thought tool that served as a study technique for artists as they prepared their works of art. Drawing skills underwent a remarkable complexity throughout the Renaissance, allowing artists to depict objects more realistically than ever before and demonstrating an interest in philosophy and pure mathematics.Contemporary artists who use drawing must consider not just the many technical factors that come into play throughout the drawing-making process, such as materials, technique, tone, shape, dimensions, and perspective, but also the idea behind the drawing. \n" +
            "\n" +
            "Artsted is the only marketplace that offers investment-grade drawings with transparent prices indicated next to them. All drawings are original, one-of-a-kind works of art that may be bought from our website.\n" +
            "Choose the drawing that speaks to you, put it in the cart, and complete your purchase to have the artwork and a certificate of authenticity mailed to you within 7 business days. Since we work directly with the artists to present an exclusive collection of distinctive contemporary drawings from emerging and mid-career artists, you may choose high-calibre pieces made by some of the best artists in the world.\n" +
            "We present to you a collection of drawings in various genres, including political, abstract, narrative, symbolist, and emotive, as well as representational and figurative. If you want to purchase the perfect drawing, we are optimistic that Artsted has what you're looking for: every taste can be satisfied by what we provide. \n" +
            "To view the highlighted artists, go to the main page, click on artworks, and select drawings from the categories on the left. You may narrow your search by selecting the sizes, shapes, and prices you're interested in.\n" +
            "\n" +
            "Invest in contemporary artists right away with Artsted!"
    },
    nft: {
        h1: "NFT artwork for sale",
        title: "Art NFTs for sale | NFT investment: Artsted",
        description: "NFTs Art ♦ digital art from the best creators and artists ♦ Buying NFT as an investment with Art marketplace: Artsted",
        seo_title: "NFT",
        seo_description: "The idea of converting artwork to a purely digital format is gaining traction. In 2021, an art object was converted into a non-fungible token (NFT) for the first time, and the material copy was destroyed. In the world of digital art, NFT began to be valued as permanent online proof of ownership, resulting in a surge in the number of artists selling their work online. From then on, any creator can use the blockchain infrastructure to save and safeguard their work. Anyone can convert their thoughts into digital information and sell it to collectors of fine art. \n" +
            "\n" +
            "Online registration for a cryptocurrency wallet and artworks for sale\n" +
            "\n" +
            "To sell an artwork in the form of NFT, the artist must take the following steps:\n" +
            "- Create an account on the platform's website.\n" +
            "- Pass the verification process\n" +
            "\n" +
            "All non-fungible tokens you've uploaded are under the jurisdiction of solid marketplaces. The online platform can request links to social media sites and verify authorship on the NFT. If a user tries to post a stolen artwork on the internet, the person is barred, and such content cannot be shared or sold.\n" +
            "The next step is obtaining a bitcoin wallet, which each network requires. The platform website's Terms and Condition section contains information regarding the required wallet for online sales of artworks. Then, to create each NFT token, you must deposit a specified quantity of money into your wallet for transactions:\n" +
            "- For Ethereum, at least $200 is required; however, $10 is sufficient for secondary chains such as Matic.\n" +
            "\n" +
            "You must complete the following procedures on your user dashboard on the marketplace:\n" +
            "- Upload the NFT file \n" +
            "- Name and describe the token \n" +
            "- Indicate the price of the artwork\n" +
            "- Decide on the number of units in the edition.\n" +
            "\n" +
            "NFTs can be sold as single copies or in multiple editions. The cryptocurrency is automatically credited to your crypto wallet when your original artwork is purchased. \n" +
            "\n" +
            "Artworks for sale online, and cryptocurrency\n" +
            "\n" +
            "Your original artworks can be sold for a variety of cryptocurrencies, depending on the platform:\n" +
            "- Ethereum is a popular cryptocurrency on Rarible and Foundation\n" +
            "- Binance Coin (BNB) — available on Refinable and other Binance Smart Chain platforms\n" +
            "\n" +
            "More marketplaces accept stablecoins, which are coins that are fixed to the US dollar."
    },
    installation: {
        h1: "Installation Sculptures for Sale",
        title: "Famous installation art for sale | Art investment: Artsted",
        description: "Digital art installation from the best creators and artists ♦ Buying installation as an investment with Art marketplace: Artsted "
    },
    sculpture: {
        h1: "Modern sculptures for sale",
        title: "Fine art sculpture for sale | Art investment: Artsted",
        description: "Unique artistic sculptures on our investment platform ♦ Buying sculpture as an investment with Art marketplace: Artsted "
    }
};

function NewArtworks(props) {
    let {innerWidth} = useResize();
    const [filterExpand, setFilterExpand] = useState({
        category: false,
        materials: false,
        styles: false,
    });
    const [showFilters, setShowFilters] = useState(false)
    let {
        filtersObject,
        setPriceRange,
        setListingData,
        setActiveFilterFunc,
        deleteActiveFilterArray,
        loading,
        // showMobileFilters,
        // setShowMobileFilters,
        setActiveFilter,
        setFiltersObject,
        listingData,
        activeFilterArray,
        setActiveFilterArray,
        setInputPriceRange,
        urlFromFilters,
        setOffset
        // filterViewPrice,
        // viewMoreFilters
    } = NewFiltersWrapper(initialState, '/public/artwork_filters', 'artworks', '/public/artworks', null, true, props.units);

    let {category, filters, page} = useParams()

    const materialArr = activeFilterArray.filter(i => i.category === "materials");
    const materialValue = materialArr.length === 1 ? materialArr[0].name.toLowerCase() : materialArr.length ? "none" : "";
    const styleArr = activeFilterArray.filter(i => i.category === "styles");
    const styleValue = styleArr.length === 1 ? styleArr[0].name.toLowerCase() : styleArr.length ? "none" : "";
    const categoryArr = activeFilterArray.filter(j => j.category === "category");
    const categoryValue = categoryArr.length === 1 ? categoryArr[0].name.toLowerCase() : categoryArr.length ? "none" : "";

    const metadataKey = [categoryValue, materialValue, styleValue].filter(i => i).join("-");
    const metadata = titleByCategory[metadataKey]

    if (category?.includes("page")) {
        page = category
        category = undefined
    }

    useEffect(() => {
        window.history.replaceState(null, '', urlFromFilters(activeFilterArray))

        //eslint-disable-next-line
    }, [activeFilterArray, listingData.page])

    console.log(urlFromFilters(activeFilterArray))

    useEffect(() => {
        if (category) {
            let tempCateg = category === "nft" ? category.toUpperCase() : [category[0].toUpperCase(), category.slice(1)].join('')
            setActiveFilterFunc(tempCateg, "category")
        }

        if (filters) {
            const filtersArr = filters.split('/')
            let finalArr = []

            filtersArr.forEach(i => {
                let filterKeyValues = i.split("=");
                filterKeyValues[1]?.split(",").forEach(j => {
                    finalArr.push({name: j, category: filterKeyValues[0]})
                })
            })

            let filtersSorted = [...finalArr].sort((a, b) => a.category.localeCompare(b.category) || a.name.localeCompare(b.name));

            if (filtersSorted.map(i => i.name + "-" + i.category).join('_') === [...finalArr].map(i => i.name + "-" + i.category).join('_')) {
                finalArr.forEach(i => {
                    setActiveFilterFunc(i.name, i.category)
                })
            } else {
                window.open(urlFromFilters(finalArr), "_self")
            }
        }

        if (page && page.includes('page-')) {
            const pageNum = page.split("-")?.[1];

            setListingData(prevState => ({
                ...prevState,
                page: +pageNum ?? 1
            }));
        }

        //eslint-disable-next-line
    }, [category, filters, page])

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [setActiveFilterFunc, category])

    const clearFilters = () => {
        setActiveFilterArray([])
        setFiltersObject(state => ({
            ...initialState,
            category: {activeList: [], list: state.category.list},
            materials: {activeList: [], list: state.materials.list},
            styles: {activeList: [], list: state.styles.list},
            price: {
                min: 0,
                max: state.max_price
            },
            max_price: state.max_price,
            loading: true
        }))
    }

    const filterViewSearch = () => (
        <>
            <label className={'listing_search'}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8047 14.862L11.2547 10.312C12.136 9.22334 12.6667 7.84 12.6667 6.33334C12.6667 2.84134 9.82531 0 6.33331 0C2.84131 0 0 2.84131 0 6.33331C0 9.82531 2.84134 12.6667 6.33335 12.6667C7.84 12.6667 9.22335 12.136 10.312 11.2547L14.862 15.8047C14.992 15.9347 15.1627 16 15.3333 16C15.504 16 15.6747 15.9347 15.8047 15.8047C16.0653 15.544 16.0653 15.1227 15.8047 14.862ZM6.33335 11.3333C3.576 11.3333 1.33334 9.09066 1.33334 6.33331C1.33334 3.57597 3.576 1.33331 6.33335 1.33331C9.09069 1.33331 11.3333 3.57597 11.3333 6.33331C11.3333 9.09066 9.09066 11.3333 6.33335 11.3333Z"
                        fill="black"/>
                </svg>

                <input type="text"
                       value={filtersObject.listing_search.value}
                       placeholder={'Search for Artworks....'}
                       onChange={({target}) => {
                           setFiltersObject(prevState => ({
                               ...prevState,
                               listing_search: {
                                   value: target.value,
                               }
                           }))
                           setListingData(prevState => ({
                               ...prevState,
                               page: 1
                           }))
                       }}/>
            </label>
            {innerWidth <= 600 && <MainButton type={'button'}
                                              style={{padding: '8px 0', margin: '0', minWidth: '80px'}}
                                              className={'button_bordered_fill_in'}
                                              text={<>
                                                  {filtersIcon}
                                                  Filter
                                              </>}
                                              onClick={() => {
                                                  setShowFilters(true)
                                              }}
            />}
        </>
    )

    const renderExpand = (name) => {
        return (
            <span
                onClick={() => setFilterExpand(state => ({...state, [name]: !state[name]}))}
                className={`expand-filter ${filterExpand[name] ? "expand-active" : ""}`}>
                <img src={chevron_bottom} alt=""/>
            </span>
        )
    }

    const renderSortBy = () => (
        <div className="checkbox-filter">
            <span>Sort by:</span>
            {(filterExpand.sort ? filtersObject.sort_by.list : []).map(i => {
                const sort_by = activeFilterArray.find(j => j.category === "sort_by"),
                    isChecked = sort_by?.name === i.name
                return (
                    <RadioButton title={i.preview}
                                 value={isChecked ? i.preview : null}
                                 no_margin
                                 onClick={() => {
                                     if (isChecked) {
                                         deleteActiveFilterArray({name: i.name, category: "sort_by"})
                                     } else {
                                         setActiveFilterFunc(i.name, "sort_by")
                                         setActiveFilter(null)
                                     }
                                 }}/>
                )
            })}
            {renderExpand("sort")}
        </div>
        // <NewFilterView category={'sort by'}
        //                handleDetectOutSide={handleDetectOutSide}
        //                activeFilter={activeFilter}
        //                sort_by_output={true}
        //                filterList={filtersObject.sort_by.list}
        //                setActiveFilterFunc={setActiveFilterFunc}
        //                setListingData={setListingData}
        //                setActiveFilter={setActiveFilter}
        //                customHandleClick={sortByCustomHandle}
        //                showFilterPopup={showFilterPopup}/>
    )

    const moreNewFilterView = () => (
        <div
            className={`more_filters_wrapper more_filters_wrapper_new wrapper_every_section ${innerWidth <= 600 && showFilters ? 'active' : ''}`}>
            {innerWidth <= 600 && (
                <div className="filters-header">
                    <span onClick={() => {
                        setShowFilters(false)
                    }}>Close</span>
                    <span className="title">Filters</span>
                    <MainButton type={'button'}
                                className={'button_bordered_close_popup'}
                                text={'Clear all'}
                                onClick={clearFilters}
                    />
                </div>
            )}
            <div className={'styles_materials'}>
                {innerWidth <= 650
                    ? filtersObject.loading && filtersObject.category.list?.length ?
                        <div className="checkbox-filter">
                            <span>Category:</span>
                            {(filterExpand.category
                                    ? filtersObject.category.list
                                    : []
                            ).map(i => {
                                const category = activeFilterArray.find(j => j.category === "category"),
                                    isChecked = category?.name === i.name
                                const shouldHide = JSON.parse(localStorage.getItem(Keys.FROM_APP)) && i.name === "NFT"
                                return !shouldHide && (
                                    <RadioButton title={i.name}
                                                 no_margin
                                                 value={isChecked ? i.name : null}
                                                 onClick={() => {
                                                     if (isChecked) {
                                                         deleteActiveFilterArray({name: i.name, category: "category"})
                                                     } else {
                                                         setActiveFilterFunc(i.name, "category")
                                                         setActiveFilter(null)
                                                     }
                                                 }}/>
                                )
                            })}
                            {renderExpand("category")}
                        </div>
                        : null : null}Х
                {filtersObject.loading && filtersObject.materials.list?.length ?
                    <div className="checkbox-filter">
                        <span>Materials:</span>
                        {(filterExpand.materials
                                ? filtersObject.materials.list
                                : []
                        ).map(i => {
                            const materials = activeFilterArray.filter(j => j.category === "materials").map(i => i.name),
                                isChecked = materials.includes(i.name)
                            return (
                                <RadioButton title={i.name}
                                             no_margin
                                             value={isChecked ? i.name : null}
                                             onClick={() => {
                                                 if (isChecked) {
                                                     deleteActiveFilterArray({name: i.name, category: "materials"})
                                                 } else {
                                                     setActiveFilterFunc(i.name, "materials")
                                                     setActiveFilter(null)
                                                 }
                                             }}/>
                            )
                        })}
                        {renderExpand("materials")}
                    </div>
                    : null}
                {filtersObject.loading && filtersObject.styles.list?.length ?
                    <div className="checkbox-filter">
                        <span>Styles:</span>
                        {(filterExpand.styles
                                ? filtersObject.styles.list
                                : []
                        ).map(i => {
                            const styles = activeFilterArray.filter(j => j.category === "styles").map(i => i.name),
                                isChecked = styles.includes(i.name)
                            return (
                                <RadioButton title={i.name}
                                             no_margin
                                             value={isChecked ? i.name : null}
                                             onClick={() => {
                                                 if (isChecked) {
                                                     deleteActiveFilterArray({name: i.name, category: "styles"})
                                                 } else {
                                                     setActiveFilterFunc(i.name, "styles")
                                                     setActiveFilter(null)
                                                 }
                                             }}/>
                            )
                        })}
                        {renderExpand("styles")}
                    </div>
                    : null}
                {filtersObject.loading && filtersObject.mediums.list?.length ?
                    <div className="checkbox-filter">
                        <span>Mediums:</span>
                        {(filterExpand.mediums
                                ? filtersObject.mediums.list
                                : []
                        ).map(i => {
                            const mediums = activeFilterArray.filter(j => j.category === "mediums").map(i => i.name),
                                isChecked = mediums.includes(i.name)
                            return (
                                <RadioButton title={i.name}
                                             no_margin
                                             value={isChecked ? i.name : null}
                                             onClick={() => {
                                                 if (isChecked) {
                                                     deleteActiveFilterArray({name: i.name, category: "mediums"})
                                                 } else {
                                                     setActiveFilterFunc(i.name, "mediums")
                                                     setActiveFilter(null)
                                                 }
                                             }}/>
                            )
                        })}
                        {renderExpand("mediums")}
                    </div>
                    : null}
                {innerWidth <= 992 && renderSortBy()}

            </div>
            <div className={'more_filters_params'}>
                <div className={'more_filters_params__info more_filters_params__info_new'}>
                    <span>Height, {props.units === "inches" ? "inch" : "cm"}</span>
                </div>

                {filterExpand.height && <>
                    <div className={'input_range'}>
                        <TextInput value={filtersObject.height.min}
                                   placeholder={0}
                                   title={'Min'}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && value <= 500 && value < filtersObject.height.max) {
                                           setInputPriceRange(value, 'min', 'height')
                                       }
                                   }}/>
                        <TextInput value={filtersObject.height.max}
                                   title={'Max'}
                                   placeholder={500}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && value <= 500) {
                                           if (value > filtersObject.height.min) {
                                               setInputPriceRange(value, 'max', 'height')
                                           } else {
                                               setInputPriceRange('0', 'min', 'height')
                                               setInputPriceRange(value, 'max', 'height')
                                           }
                                       }
                                   }}/>
                    </div>
                    <NewRangeSliderMultiple setValue={e => setPriceRange(e, 'height')}
                                            value={filtersObject.height}
                                            step={25}
                                            max_price={500}/>
                </>}

                {renderExpand("height")}
            </div>
            <div className={'more_filters_params'}>
                <div className={'more_filters_params__info more_filters_params__info_new'}>
                    <span>Width, {props.units === "inches" ? "inch" : "cm"}</span>
                </div>
                {filterExpand.width && <>
                    <div className={'input_range'}>
                        <TextInput value={filtersObject.width.min}
                                   placeholder={0}
                                   title={'Min'}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && value <= 500 && value < filtersObject.width.max) {
                                           setInputPriceRange(value, 'min', 'width')
                                       }
                                   }}/>
                        <TextInput value={filtersObject.width.max}
                                   title={'Max'}
                                   placeholder={500}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && value <= 500) {
                                           if (regExp.only_number.test(value) && value <= 500) {
                                               if (value > filtersObject.width.min) {
                                                   setInputPriceRange(value, 'max', 'width')
                                               } else {
                                                   setInputPriceRange('0', 'min', 'width')
                                                   setInputPriceRange(value, 'max', 'width')
                                               }
                                           }
                                       }
                                   }}/>
                    </div>

                    <NewRangeSliderMultiple setValue={e => setPriceRange(e, 'width')}
                                            value={filtersObject.width}
                                            step={25}
                                            max_price={500}/>
                </>}

                {renderExpand("width")}
            </div>

            <div className={'more_filters_params'}>
                <div className={'more_filters_params__info more_filters_params__info_new'}>
                    <span>Price, € </span>
                </div>

                {filterExpand.price && <>
                    <div className={'input_range input_range_price'}>
                        <TextInput value={filtersObject.price.min}
                                   placeholder={0}
                                   title={'Min'}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && Number(value) < Number(filtersObject.price.max)) {
                                           setInputPriceRange(Number(value), 'min', 'price')
                                       }
                                   }}/>
                        <TextInput value={filtersObject.price.max}
                                   title={'Max'}
                                   placeholder={filtersObject.max_price}
                                   onChange={({target}) => {
                                       let {value} = target
                                       if (regExp.only_number.test(value) && Number(value) <= filtersObject.max_price) {
                                           if (value > filtersObject.price.min) {
                                               setInputPriceRange(Number(value), 'max', 'price')
                                           } else {
                                               setInputPriceRange(Number(value), 'max', 'price')
                                               setInputPriceRange(0, 'min', 'price')
                                           }
                                       }
                                   }}/>
                    </div>
                    <NewRangeSliderMultiple value={filtersObject.price}
                                            step={200} setValue={e => {
                        setPriceRange(e, 'price')
                        setListingData(prevState => ({
                            ...prevState, page: 1
                        }))
                        getActivePage(1)
                    }}
                                            max_price={filtersObject.max_price}/>
                </>}

                {renderExpand("price")}
            </div>
            {innerWidth <= 600 && (
                <MainButton type={'button'}
                            style={{width: "100%"}}
                            className={'button_bordered_fill_in'}
                            text={"Show results"}
                            onClick={() => {
                                setShowFilters(false)
                            }}
                />
            )}
            {innerWidth > 600 && (
                <MainButton type={'button'}
                            style={{width: "100%"}}
                            className={'button_bordered_close_popup'}
                            text={"Clear filters"}
                            onClick={clearFilters}
                />
            )}
        </div>
    )

    return [
        <div key={2} className={'artworks_page_wrapper'}>
            <SEO
                title={metadata?.title ?? "Original modern artwork for sale online| Art investment: Artsted" + listingData.page > 1 ? " Page " + listingData.page : ""}
                description={(metadata?.description ?? "The best place to Buy famous artwork ♦ Buying arttwork as an investment with Art marketplace: Artsted") + listingData.page > 1 ? " Page " + listingData.page : ""}>
                {listingData.page !== 1 &&
                    <link rel="canonical" href={window.location.href.replace(/\/page-[0-9]+/, "")}/>}
            </SEO>
            <section className={'head_content'}>
                <div className={'content_bg'}/>
                <h1>{metadata?.h1 ?? "Original artwork for sale online"}</h1>
                <div className={'art_container'}>
                    {category === "nft" ?
                        <div className={'text_content'}>
                            <h2>Curated NFT collection</h2>
                            <p>Artsted is using unique proprietary technology to help determine next art market stars.
                                Discover the selection.</p>
                        </div> :
                        <div className={'text_content'}>
                            <h2>Curated Artworks Selection</h2>
                            <p>An exclusive collection of works from emerging and mid-career visual artists</p>
                        </div>}
                    <div
                        className={`artwork_container ${category === "nft" ? "artwork_container_nft" : "artwork_container_usual"}`}>
                        {category === "nft" ?
                            <div className={'artist'}>
                                <img src={artistPhotoNFT} alt="avatar"/>
                                <div>
                                    <h4>Xiaoling Jin</h4>
                                    <span>Under the Shell</span>
                                </div>
                            </div> :
                            <div className={'artist'}>
                                <img src={artistPhoto} alt="avatar"/>
                                <div>
                                    <h4>Jenny Doe</h4>
                                    <span>Untitled</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>

            <section className={'filters artworks_listing listings'}>

                <div
                    className={'wrapper_list_filters'}>
                    <div className={'wrapper_list_filters__filters'}>
                        {filterViewSearch()}
                        {innerWidth > 992 && renderSortBy()}
                        {innerWidth > 650
                            ? filtersObject.loading && filtersObject.category.list?.length ?
                                <div className="checkbox-filter">
                                    <span>Category:</span>
                                    {(filterExpand.category
                                            ? filtersObject.category.list
                                            : []
                                    ).map(i => {
                                        const category = activeFilterArray.filter(j => j.category === "category").map(i => i.name),
                                            isChecked = category.includes(i.name)
                                        return (
                                            <RadioButton title={i.name}
                                                         no_margin
                                                         value={isChecked ? i.name : null}
                                                         onClick={() => {
                                                             if (isChecked) {
                                                                 deleteActiveFilterArray({
                                                                     name: i.name,
                                                                     category: "category"
                                                                 })
                                                             } else {
                                                                 setActiveFilterFunc(i.name, "category")
                                                                 setActiveFilter(null)
                                                             }
                                                         }}/>
                                        )
                                    })}
                                    {renderExpand("category")}
                                </div>
                                // <NewFilterView category={'category'}
                                //                handleDetectOutSide={handleDetectOutSide}
                                //                activeFilter={activeFilter}
                                //                filterList={filtersObject.category.list}
                                //                setActiveFilterFunc={setActiveFilterFunc}
                                //                setListingData={setListingData}
                                //                setActiveFilter={setActiveFilter}
                                //                showFilterPopup={showFilterPopup}/>
                                : null : null}
                    </div>
                    {moreNewFilterView()}
                    {/*{activeFilterArray.length ?*/}
                    {/*{categoryViewSection()}*/}
                    {/*: null}*/}
                </div>
                <div className={'artworks_list wrapper_every_section'}>
                    {loading ? listingData.artworks?.length ?
                            <div className="masonry_layout">
                                {listingData.artworks?.map((el, index) => {
                                    return (
                                        <div key={index}>
                                            <ArtworkInfoCard {...el}
                                                             activeData={listingData}
                                                             setActiveData={val => setListingData(val)}
                                                             nestedArray={'artworks'}
                                                             index={index}/>
                                        </div>
                                    )
                                })}
                            </div>
                            : <span className={'no_items_style'}>No items</span>
                        : <Loader color={{
                            '--bgColor': '#000',
                            '--size': '48px',
                            '--wrapper_size': '40px'
                        }}/>}
                </div>
                {loading && listingData.total > 9 ?
                    <>
                        {listingData.page < listingData.pages &&
                            <MainButton
                                className={'button_black show-more'}
                                onClick={() => {
                                    setOffset(state => state + 15)
                                    setListingData(prevState => {
                                        const pageNumber = prevState.page + 1;
                                        return {
                                            ...prevState,
                                            page: pageNumber
                                        }
                                    })
                                }}
                                text={"Show more"}
                            />}
                        <div className={'wrapper_new_pagination flex-end'}>
                            <NewPaginationView activePage={listingData}
                                               onChange={pageNumber => {
                                                   window.scrollTo(0, 0)
                                                   setOffset(15)
                                                   setListingData(prevState => ({
                                                       ...prevState,
                                                       page: pageNumber
                                                   }))
                                               }}/>
                        </div>
                    </>
                    : null}
            </section>
            {listingData.page === 1 && (metadata || activeFilterArray.length === 0) &&
                <ExtendSEO title={activeFilterArray.length === 0 ? initialSeoText.title : metadata?.seo_title}
                           description={activeFilterArray.length === 0 ? initialSeoText.description : metadata?.seo_description}/>}
        </div>,
        <StayInLoop key={3}/>
    ]
}

const mapStateToProps = state => ({
    units: state.auth.units
})

export default connect(mapStateToProps)(NewArtworks);
