const AUTH_VARIANT = "AUTH_VARIANT";
const AUTH_POPUP = "AUTH_POPUP";
const USER_NAME = "USER_NAME";
const USER_CATEGORIES = 'USER_CATEGORIES';
const CART_COUNT = 'CART_COUNT';
const ACTIVE_UPLOAD = 'ACTIVE_UPLOAD';
const USER_REGISTRATION_DATA = 'USER_REGISTRATION_DATA';
const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION';
const ACTIVE_HISTORY = 'ACTIVE_HISTORY';
const CAN_CREATE = 'CAN_CREATE'
const CURRENCY = 'ARTSTED_CURRENCY'
const CURRENCIES = 'ARTSTED_CURRENCIES'
const UNITS = 'ARTSTED_UNITS'
const CART_STATUS = 'CART_STATUS'
const FROM_APP = "FROM_APP"

export {
    AUTH_POPUP, AUTH_VARIANT, USER_SUBSCRIPTION,
    USER_NAME, USER_CATEGORIES, USER_REGISTRATION_DATA,
    CART_COUNT, ACTIVE_UPLOAD,ACTIVE_HISTORY,
    CAN_CREATE, CART_STATUS, CURRENCY, UNITS, CURRENCIES, FROM_APP
};
