import {Table, Tag, Typography} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import API from "../../../api/API";
import Keys from "../../../Constants/helper";

import {ArrowLeft2Icon, ArrowRight2Icon} from "../../../assets/icons";

import styles from './AdminExperienceBookings.module.css';

const columns = [
    {title: 'Booker', dataIndex: 'booker', key: 'booker'},
    {title: 'Dates', dataIndex: 'dates', key: 'dates'},
    {title: 'Status', align: 'center', dataIndex: 'status', key: 'status'},
    {title: 'Guests', align: 'center', dataIndex: 'guests_num', key: 'guests_num'},
    {title: 'Price', align: 'center', dataIndex: 'price', key: 'price'},
    {title: 'Booking date', dataIndex: 'created_at', key: 'created_at'},
];

export default function AdminExperienceBookings() {
    const {type} = useParams();
    const [data, setData] = useState({});
    const [params, setParams] = useState({
        page: 1
    });
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        API(Keys.JWT_TOKEN_ADMIN).get('/admin/listing/bookings', {
            params: {
                type: type.replace(/^\w/, (l) => l.toUpperCase()),
                ...params
            }
        })
            .then((res) => {
                setData(res.data)
                setDataSource(res.data.bookings.map((i) => ({
                    key: i.id,
                    booker: i.booker_name,
                    dates: `${moment(i.date_from).format('DD MMM YYYY')} -> ${moment(i.date_to).format('DD MMM YYYY')} (${moment(i.date_to).diff(moment(i.date_from), 'days')} nights)`,
                    status: (
                        moment(i.date_from).diff(moment(), 'days') > 0 ? (
                            <Tag className={styles.tag} color="green">Upcoming</Tag>
                        ) : (
                            <Tag className={styles.tag}>Completed</Tag>
                        )
                    ),
                    guests_num: i.guests_num,
                    price: (<Typography.Text strong>€{i.price}</Typography.Text>),
                    created_at: moment(i.created_at).format('DD MMM YYYY HH:mm')
                })))
            })
    }, [params]);

    const handleChangePage = (page) => {
        setParams((p) => ({...p, page}))
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div>
            <Table
                pagination={{
                    hideOnSinglePage: true,
                    defaultPageSize: 15,
                    total: data.total,
                    showSizeChanger: false,
                    rootClassName: styles.pagination,
                    onChange: handleChangePage,
                    itemRender: (page, v) => (
                        <div>
                            {v ==='prev' && (
                                <div className={styles.paginationButtons}>
                                    <ArrowLeft2Icon width="1.5rem" />
                                    Previous
                                </div>
                            )}
                            {v ==='page' && page}
                            {v ==='jump-next' && <div className={styles.paginationDots}>...</div>}
                            {v ==='next' && (
                                <div className={styles.paginationButtons}>
                                    Next
                                    <ArrowRight2Icon width="1.5rem" />
                                </div>
                            )}
                        </div>
                    )
                }}
                className={styles.table}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}