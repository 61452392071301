import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import CustomCheckbox from "./CustomComponents/CheckBox";
import MainButton from "./CustomComponents/MainButton";
import './styles/authenticityCertificate.css'
import {bodyOverflow} from "../Constants/bodyOverflow";
import {useSelector} from "react-redux";

const profileSettings = {
    infinite: true,
    speed: 500,
    arrows: false,
    dots: true,
    fade: true,
    swipeToSlide: false,
    slidesToShow: 1,
    slidesToScroll: 1,
}

const initialState = {
    artwork_consigned: {value: false, error: null},
    terms: {value: false, error: null}
}

const AuthenticityCertificate = (props) => {
    const {
        setShowPreview, uploadWorkData, handleSubmitArtwork,
        setIsFinished, files, handleCustomError
    } = props;

    const userName = useSelector(state => state?.auth?.user_data?.name);
    const [checkBoxes, setCheckBoxed] = useState(initialState)
    const [sliderArray, setSliderArray] = useState(null)

    useEffect(() => {
        if (files) {
            setSliderArray(files.map(item => {
                if (Object.keys(item).length) {
                    return item[0].preview
                } else {
                    return null
                }
            }).filter(Boolean))
        }
    }, [files])

    const handleConfirm = () => {
        if (checkBoxes.terms.value && checkBoxes.artwork_consigned.value) {
            setIsFinished(true)
            setShowPreview(false)
            bodyOverflow(false)
            setCheckBoxed(initialState)
            handleSubmitArtwork()
        } else {
            Object.entries(checkBoxes).forEach(item => {
                if (!item[1].value) {
                    setCheckBoxed(state => ({
                        ...state,
                        [item[0]]: {...state[item[0]], error: true}
                    }))
                }
            })
        }
    }

    return (
        <div className={'authenticity_certificate_wrapper'}>
            <div className={'authenticity_certificate_content overflow_scrollbar'}>
                <div className={'title'}>
                    <h3>Authenticity Certificate</h3>
                    <svg onClick={() => {
                        bodyOverflow(false)
                        setShowPreview(false)
                    }}
                         width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" fill="#F6F6F1"/>
                        <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                    </svg>
                </div>
                <div className={'basic_info'}>
                    <div className={'images'}>
                        <Slider {...profileSettings}>
                            {sliderArray?.map((item, index) => {
                                return (
                                    <div className={'every_image'}
                                         key={index}>
                                        <img src={item} alt="img"/>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div className={'about'}>
                        <h5>Artist Name</h5><span>{userName}</span>
                        <h5>Date</h5><span>{new Date().toLocaleString().split(',')[0].split('/').join('.')}</span>
                        <h5>Title</h5><span>{uploadWorkData.art_title.value}</span>
                        <h5>Artwork size</h5>
                        <span>{`${uploadWorkData.art_width.value} x ${uploadWorkData.art_depth.value} x ${uploadWorkData.art_height.value} cm`}</span>
                        <h5>Year of origination</h5><span>{uploadWorkData.year_of_origination.value}</span>
                    </div>
                </div>

                <div className={'texts'}>
                    <p>The digital image provided captures the given artwork in its final version and serves for future
                        authentication purposes. Therefore, neither the physical work itself nor the digital image
                        consigned to Artisfact Limited will be altered after having signed the present certificate.</p>
                    <p>I herby confirm that I created the work specified anddescribed above, alone,<br/>
                        I confirm the authenticity of the work <br/>
                        I confirm to be the only legal owner of the present artwork <br/>
                        I assume full legal responsibility were it ever to infringe copyright, violate property right,
                        moral rights, intellectual or other third party right.</p>
                </div>

                <div className={'agree_terms'}>
                    <CustomCheckbox
                        text={'By clicking I Agree you assume full legal responsibility for the Authenticity of the Artwork consigned.'}
                        // handleChange={() => handleChangeCheck('terms')}
                        error={checkBoxes.artwork_consigned.error}
                        authenticity
                        value={checkBoxes.artwork_consigned.value}
                        handleChange={() => {
                            setCheckBoxed(state => ({
                                ...state,
                                artwork_consigned: {
                                    value: !state.artwork_consigned.value,
                                    error: null
                                }
                            }))
                        }}
                    />
                    {checkBoxes.artwork_consigned.error
                        ? handleCustomError('0', 'You must agree') : null}
                    <CustomCheckbox text={'I have read the '}
                                    authenticity
                                    handleChange={() => {
                                        setCheckBoxed(state => ({
                                            ...state,
                                            terms: {
                                                value: !state.terms.value,
                                                error: null
                                            }
                                        }))
                                    }}
                                    link={{value: 'Terms and Conditions.', link: '/info/terms_of_service'}}
                                    value={checkBoxes.terms.value}
                                    error={checkBoxes.terms.error}
                    />
                    {checkBoxes.terms.error
                        ? handleCustomError('0', 'You must read the Terms of service to upload artwork') : null}
                </div>

                <MainButton text={'Confirm'}
                            type={'submit'}
                            style={{margin: '0 auto'}}
                            className={'button_black'}
                            onClick={handleConfirm}/>
            </div>
        </div>
    )
}

export default AuthenticityCertificate;
