import React, {useEffect, useMemo, useState} from 'react'
import './styles/header.css';
import artIsFactLogo from '../assets/img/new_logo.svg'
import signInIcon from '../assets/img/signInIcon.svg'
import chevronDown from '../assets/img/chevron-down.svg'
import cart from '../assets/img/cart.svg'
import searchArrow from '../assets/img/search_arrow.svg'
import {connect, useSelector} from "react-redux";
import {setAuthPopUp, setAuthVariant, setCartCount, setUserData} from "../main-store/auth/actions";
import {bodyOverflow} from "../Constants/bodyOverflow";
import HeaderAuthPopup from "./HeaderAuthPopup";
import searchIcon from '../assets/img/search_header_icon.svg'
import SidebarMenu from "./SidebarMenu";
import API, {userLogout} from '../api/API'
import useResize from "../Constants/useResize";
import Keys from "../Constants/helper";
import OutsideClickHandler from "../Constants/detectOutsideClick";
import EmailSubscriptionPopup from "./EmailSubscriptionPopup";
import artworks_placeholder from "../assets/img/artworks_placeholder.png";
import {Link, useHistory} from "react-router-dom";
import useBottomPopup from "../Constants/useBottomPopup";
import ChangedConfirmedPopup from "./CustomComponents/ChangedConfirmedPopup";

const Header = (props) => {
    const {
        cart_length, auth_variant, auth_popup,
        setAuthPopUp, setAuthVariant, setCartCount,
        user_data, setUserData
    } = props;
    const history = useHistory();
    const [popupNavigation, setPopupNavigation] = useState(false)
    const [activeSidebar, setActiveSidebar] = useState(false);
    const [navigationPopup, setNavigationPopup] = useState(false);
    const [shouldClose, setShouldClose] = useState(false)
    const [inputCallBack, setInputCallBack] = useState(false)
    const [hiddenInput, setHiddenInput] = useState(false)
    const [emailSubscribePopup, setEmailSubscribePopup] = useState(false)
    // const [navigationPopup, setNavigationPopup] = useState({
    //     popup: false,
    //     // popupData: null
    // });
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    const {innerWidth} = useResize()
    const [activeSearch, setActiveSearch] = useState({
        value: '',
        popup: false,
        options: {},
    });

    useEffect(() => {
        const condition = !localStorage.getItem(Keys.JWT_TOKEN) && !localStorage.getItem('emailSubscriptionPopup') && !localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION)
        if (condition) {
            setTimeout(() => {
                setEmailSubscribePopup(true)
                bodyOverflow(true)
            }, 15000)
        } else {
            setEmailSubscribePopup(false)
        }
    }, [])

    useEffect(() => {
        if (activeSearch.value.length >= 2) {
            API().get(`/landing/search?name=${activeSearch.value}`)
                .then(res => {
                        // console.log(res)
                        if (res.data) {
                            setActiveSearch(state => ({
                                ...state,
                                options: res.data
                            }))
                        }
                    }
                )
        }
    }, [activeSearch.value])

    useEffect(() => {

        if (localStorage.getItem(Keys.JWT_TOKEN) && user_data?.role === 'investor') {
            API().get(`/user/basket`)
                .then(res => {
                    if (res.data) {
                        setCartCount(res.data.artworks.length)
                    }
                })
        }

        // setNavigationPopup(prevState => ({
        //     ...prevState,
        //     popupData: tempJson,
        // }))
    }, [setCartCount, user_data?.role])

    const handleOutSideClick = () => {
        setPopupNavigation(false)
        setActiveSidebar(false)
        bodyOverflow(false)
    }

    const profilePopup = () => {
        return (
            <OutsideClickHandler callback={() => {
                setPopupNavigation(false)
            }}>
                <div className={'wrapper_profile_navigation_popup'}
                     style={popupNavigation ? {pointerEvents: 'all'} : null}>
                    <Link to={(user_data?.role === 'investor' || user_data?.role === 'organization') ? '/profile/about' : '/profile/portfolio'}
                          onClick={() => setPopupNavigation(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="noun_Man_1515231" width="17.944" height="18.692"
                             viewBox="0 0 26.571 26.571">
                            <path id="Path_4"
                                  fill={'#bc4386'}
                                  d="M27.107 52.571h24.357a1.107 1.107 0 0 0 1.107-1.107v-2.292a4.428 4.428 0 0 0-2.966-4.179l-5.891-2.065v-.947a8.1 8.1 0 0 0 2.214-5.536v-4.954C45.8 28.513 42.762 26 39.286 26s-6.515 2.513-6.643 5.536v4.91a8.1 8.1 0 0 0 2.214 5.536v.947l-5.89 2.065A4.429 4.429 0 0 0 26 49.172v2.292a1.107 1.107 0 0 0 1.107 1.107zm1.107-3.4A2.214 2.214 0 0 1 29.7 47.08l6.643-2.319a1.107 1.107 0 0 0 .731-1.046V41.5a1.107 1.107 0 0 0-.349-.808 5.868 5.868 0 0 1-1.866-4.246V31.58c.072-1.794 2.142-3.366 4.429-3.366s4.357 1.572 4.429 3.321v4.91a5.868 5.868 0 0 1-1.866 4.268 1.107 1.107 0 0 0-.349.786v2.214a1.107 1.107 0 0 0 .742 1.046l6.643 2.319a2.214 2.214 0 0 1 1.472 2.093v1.185H28.214z"
                                  className="cls-1" data-name="Path 4" transform="translate(-26 -26)"/>
                        </svg>
                        <span>{user_data?.role === 'investor' ? 'Profile' : 'Portfolio'}</span>
                    </Link>
                    <Link to={'/profile/settings'} onClick={() => setPopupNavigation(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="noun_Settings_1515651" width="17.944"
                             height="18.692"
                             viewBox="0 0 24.314 26.571">
                            <path id="Path_10026"
                                  d="M46.429 50.857A4.429 4.429 0 1 0 42 46.429a4.429 4.429 0 0 0 4.429 4.428zm0-6.643a2.214 2.214 0 1 1-2.214 2.214 2.214 2.214 0 0 1 2.214-2.214z"
                                  fill={'#bc4386'}
                                  className="cls-1" data-name="Path 10026" transform="translate(-34.271 -33.143)"/>
                            <path id="Path_10027"
                                  fill={'#bc4386'}
                                  d="M28.377 45.336a12.578 12.578 0 0 0 .67 1.146 3.216 3.216 0 0 0 3.764 1.24l1.389-.5a9.865 9.865 0 0 0 2.12 1.229l.266 1.456a3.227 3.227 0 0 0 3.056 2.663h1.107a3.227 3.227 0 0 0 3.045-2.652l.277-1.467a9.865 9.865 0 0 0 2.109-1.218l1.389.5a3.216 3.216 0 0 0 3.775-1.251 11.377 11.377 0 0 0 .664-1.157 3.166 3.166 0 0 0-.808-3.875l-1.107-.95a9.61 9.61 0 0 0 0-2.436l1.107-.954a3.222 3.222 0 0 0 .814-3.875 12.577 12.577 0 0 0-.67-1.146 3.216 3.216 0 0 0-3.764-1.24l-1.389.5a9.638 9.638 0 0 0-2.12-1.229l-.271-1.457A3.227 3.227 0 0 0 40.749 26H39.6a3.227 3.227 0 0 0-3.045 2.652l-.233 1.467a9.638 9.638 0 0 0-2.109 1.218l-1.389-.5a3.216 3.216 0 0 0-3.775 1.251 11.929 11.929 0 0 0-.664 1.151 3.222 3.222 0 0 0 .808 3.875l1.107.952a9.61 9.61 0 0 0 0 2.436l-1.107.952a3.183 3.183 0 0 0-.816 3.882zm2.248-2.214l1.594-1.351a1.107 1.107 0 0 0 .371-1.052 7.435 7.435 0 0 1 0-2.912 1.107 1.107 0 0 0-.371-1.052l-1.594-1.345a.985.985 0 0 1-.271-1.185 10.334 10.334 0 0 1 .554-.963.991.991 0 0 1 1.163-.354l1.971.7a1.107 1.107 0 0 0 1.107-.2 7.75 7.75 0 0 1 2.513-1.45 1.107 1.107 0 0 0 .725-.847l.376-2.065a1.03 1.03 0 0 1 .963-.83 8.9 8.9 0 0 1 .941 0 1.03 1.03 0 0 1 .974.83l.376 2.07a1.107 1.107 0 0 0 .725.847 7.75 7.75 0 0 1 2.513 1.45 1.107 1.107 0 0 0 1.107.2l1.971-.7a.991.991 0 0 1 1.163.354 10.336 10.336 0 0 1 .554.963.985.985 0 0 1-.282 1.179l-1.589 1.351a1.107 1.107 0 0 0-.376 1.052 7.435 7.435 0 0 1 0 2.912 1.107 1.107 0 0 0 .376 1.052l1.589 1.384a.985.985 0 0 1 .271 1.185 10.335 10.335 0 0 1-.554.963.985.985 0 0 1-1.163.354l-1.971-.7a1.107 1.107 0 0 0-1.107.2 7.75 7.75 0 0 1-2.513 1.45 1.107 1.107 0 0 0-.725.847l-.376 2.065a1.03 1.03 0 0 1-.963.83 8.9 8.9 0 0 1-.941 0 1.03 1.03 0 0 1-.974-.836l-.376-2.065a1.107 1.107 0 0 0-.725-.847 7.75 7.75 0 0 1-2.513-1.45 1.093 1.093 0 0 0-1.1-.2l-1.971.7a.985.985 0 0 1-1.163-.354 10.334 10.334 0 0 1-.554-.963.985.985 0 0 1 .271-1.179z"
                                  className="cls-1" data-name="Path 10027" transform="translate(-28.037 -26)"/>
                        </svg>
                        <span>Settings</span>
                    </Link>

                    <Link to={'/profile/support'} onClick={() => setPopupNavigation(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="noun_Buoy_1515358" width="17.944"
                             height="18.692"
                             viewBox="0 0 25.949 25.932">
                            <path id="Path_12683"
                                  fill={'#bc4386'}
                                  d="M29.739 48.15a12.923 12.923 0 1 0-3.832-9.184 12.965 12.965 0 0 0 3.832 9.184zm.8-16.008l3.014 3.02s.054.032.081.054a6.44 6.44 0 0 0 0 7.531.5.5 0 0 0-.081.054l-3.014 3.02a10.805 10.805 0 0 1 .016-13.7zm11.437 9.881a4.322 4.322 0 1 1 1.268-3.058 4.322 4.322 0 0 1-1.263 3.059zm-9.9 5.311l3.02-3.02s.032-.054.054-.081a6.439 6.439 0 0 0 7.531 0 .5.5 0 0 0 .054.081l3.02 3.02a10.837 10.837 0 0 1-13.671 0zm15.2-1.529l-3.014-3.02s-.054-.032-.081-.054a6.44 6.44 0 0 0 0-7.531.5.5 0 0 0 .081-.054l3.014-3.02a10.805 10.805 0 0 1 0 13.679zM45.762 30.6l-3.02 3.015s-.032.054-.054.081a6.439 6.439 0 0 0-7.531 0 .5.5 0 0 0-.054-.081L32.084 30.6a10.8 10.8 0 0 1 13.679 0z"
                                  className="cls-1" transform="translate(-25.908 -26)"/>
                        </svg>
                        <span>Support</span>
                    </Link>

                    <div onClick={() => userLogout(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="noun_Log_Out_1515744" width="17.944" height="18.692"
                             data-name="noun_Log Out_1515744" viewBox="0 0 17.944 18.692">
                            <path id="Path_12684"
                                  fill={'#bc4386'}
                                  d="M60.262 37.307a.759.759 0 0 0-1.047.075.786.786 0 0 0 0 .972l.972.972h-3.439a.748.748 0 0 0 0 1.5h3.439l-.972.972a.742.742 0 0 0 .972 1.122c.037-.037.075-.037.075-.075L62.5 40.6a.723.723 0 0 0 0-1.047z"
                                  className="cls-1" data-name="Path 12684" transform="translate(-44.785 -32.596)"/>
                            <path id="Path_12685"
                                  fill={'#bc4386'}
                                  d="M26 37.524a3.347 3.347 0 0 0 1.944 3.028l6.692 3.065a.88.88 0 0 0 .3.075.75.75 0 0 0 .748-.748v-2.99h.748a2.25 2.25 0 0 0 2.243-2.243v-1.5a.748.748 0 1 0-1.5 0v1.5a.75.75 0 0 1-.748.748h-.748v-8.973a.739.739 0 0 0-.449-.673L30.187 26.5h6.28a.75.75 0 0 1 .748.748v1.5a.748.748 0 1 0 1.5 0v-1.5A2.25 2.25 0 0 0 36.467 25h-8.224A2.25 2.25 0 0 0 26 27.243zm1.5-10.281a.7.7 0 0 1 .075-.3l6.654 3.028v11.814l-5.645-2.579a1.863 1.863 0 0 1-1.084-1.682V27.243z"
                                  className="cls-1" data-name="Path 12685" transform="translate(-26 -25)"/>
                        </svg>
                        <span>Log&nbsp;Out</span>
                    </div>
                </div>
            </OutsideClickHandler>
        )
    }
    const mobSearch = () => {
        return (
            <div onClick={() => {
                if (!inputCallBack) {
                    setHiddenInput(true)
                    setNavigationPopup(true)
                }
            }}
                 className={'header_search_section_adaptive'}>
                <img className={'mob_search_logo'} src={searchIcon} alt="search icon"/>
                {hiddenInput ?
                    inputWrapper()
                    : null
                }
            </div>

        )
    }
    const inputWrapper = () => {
        return (
            // todo
            <OutsideClickHandler callback={() => {
                setHiddenInput(false)
                setInputCallBack(true)
                setTimeout(() => {
                    setInputCallBack(false)
                }, 200)
            }}>
                <div className={'header_search_section adaptive_search'}>
                    <label>
                        <div className={"input_block_wrapper"} onClick={() => {
                            if (!inputCallBack)
                                setNavigationPopup(true)
                        }}>
                            <span>Search</span>
                            <input autoFocus value={activeSearch.value}
                                   onChange={({target}) => {
                                       setActiveSearch(prevState => ({
                                           ...prevState,
                                           value: target.value,
                                       }))
                                   }}
                                   onClick={() => {
                                       setActiveSearch(prevState => ({
                                           ...prevState,
                                           popup: true,
                                       }))
                                   }}
                                   placeholder={'best artwork…'}
                                   type="text"/>
                        </div>
                        {navigationPopup && activeSearch.value.length >= 2 ?
                            searchNavPopUp()
                            : null}
                    </label>
                </div>
            </OutsideClickHandler>

        )
    }

    const searchWrapper = () => {
        return (
            <div className={'header_search_section'}>
                <label>
                    <img src={searchIcon} alt="search icon"/>
                    <div onClick={() => {
                        if (!(shouldClose && inputCallBack))
                            setNavigationPopup(true)
                    }}>
                        <span>Search</span>
                        <input value={activeSearch.value}
                               onChange={({target}) => {
                                   setActiveSearch(prevState => ({
                                       ...prevState,
                                       value: target.value,
                                   }))
                               }}
                               onClick={() => {
                                   setActiveSearch(prevState => ({
                                       ...prevState,
                                       popup: true,
                                   }))
                               }}
                               placeholder={'best artwork…'}
                               type="text"/>
                    </div>
                    {navigationPopup && activeSearch.value.length >= 2 ?
                        searchNavPopUp()
                        : null}
                </label>
            </div>
        )
    }
    const searchNavPopUp = () => {
        return (
            <OutsideClickHandler callback={() => {
                setNavigationPopup(false)
                setShouldClose(true)
                setInputCallBack(true)
                setTimeout(() => {
                    setInputCallBack(false)
                    setShouldClose(false)
                }, 200)
            }}>
                <div className={'search_wrapper_popup'}>
                    {Object.entries(activeSearch.options).map((item, index) => {
                        return (
                            <div key={index}>
                                <div className={'title_of_search_category'}>
                                    {activeSearch.value.length >= 2 ?
                                        <span>{item[0]}</span> : <span>No results</span>}
                                </div>
                                <div className={"art_wrapper_search"}>
                                    {item[1].length ?
                                        item[1].map((el) => {
                                            return (
                                                <Link key={el.id}
                                                      to={item[0] === 'artists'
                                                          ? `/artist-profile/${el.profile_url}`
                                                          : `/public-artwork/${el.id}`}
                                                      onClick={event => {
                                                          if (!localStorage.getItem(Keys.JWT_TOKEN) && item[0] === 'artists') {
                                                              event.preventDefault();
                                                              setNavigationPopup(false)
                                                              changeContinuePopup('Please sign up to view personal pages')
                                                              setTimeout(() => {
                                                                  history.push('/registration/type')
                                                                  changeContinuePopup('Please sign up to view personal pages')
                                                              }, 2000)
                                                          }
                                                      }}>
                                                    <div className={"search_results_of_art"}>
                                                        <div className={"art_band_of_artistfaction"}>
                                                            <img className={'avatar_search_header'}
                                                                 src={el.avatar ?? el.photo ?? artworks_placeholder}
                                                                 alt="avatar"/>
                                                            <span>{el.name ?? el.title}</span>
                                                        </div>
                                                        <div>
                                                            <img className={"arrow_search_logo"} src={searchArrow}
                                                                 alt="arrow"/>
                                                        </div>

                                                    </div>
                                                </Link>
                                            )
                                        })
                                        : <span>No Results</span>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </OutsideClickHandler>
        )
    }

    const cartWrapper = mob => {
        return user_data?.role === 'investor' ? (
            <div onClick={() => {
                sessionStorage.removeItem(Keys.SUBSCRIPTION_PLAN_BILL)
                sessionStorage.removeItem(Keys.SUBSCRIPTION_DATA)
                history.push('/cart')
            }} className={'cart_wrapper'}>
                {cart_length ?
                    <div className={'cart_wrapper_items_counts'}>
                        <span>{cart_length}</span>
                    </div>
                    : null}
                <img src={cart} alt="cart"/>
                {mob ?
                    <span className={'cart_wrapper_mobile_text'}>My Cart</span>
                    : null}
            </div>
        ) : null
    }

    const authWrapper = () => {
        let user = localStorage.getItem(Keys.JWT_TOKEN)
        return user ?
            <div className={'header_registered_user'}
                 style={popupNavigation ? {pointerEvents: 'none'} : null}>
                <div onClick={() => {
                    setPopupNavigation(!popupNavigation)
                }}>
                    <span>{user_data?.name}</span>
                    <img src={chevronDown}
                         className={popupNavigation ? 'active' : ''}
                         alt="chevron down"/>
                </div>
                {popupNavigation ?
                    profilePopup()
                    : null}
            </div>
            : <div className={'header_sign_in_container'}>
                            <span className={'auth_variant'}
                                  style={auth_popup ? {pointerEvents: 'none'} : null}
                                  onClick={() => {
                                      if (!localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION)) {
                                          setPopupNavigation(null)
                                          setActiveSidebar(false)
                                          bodyOverflow(true)
                                          history.push('/login')
                                      }
                                  }}>Sign in
                            </span>
                <span>/</span>
                <span className={'auth_variant'}
                      style={auth_popup ? {pointerEvents: 'none'} : null}
                      onClick={() => {
                          if (!localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION)) {
                              setPopupNavigation(null)
                              setActiveSidebar(false)
                              bodyOverflow(true)
                              history.push('/registration/type')
                          }
                      }}>Register
                            </span>
            </div>
    }

    const linkArtistProfile = event => {
        if (!localStorage.getItem(Keys.JWT_TOKEN)) {
            event.preventDefault();
            changeContinuePopup('Please sign up to view personal pages')
            setTimeout(() => {
                setAuthPopUp('Registration')
                changeContinuePopup('Please sign up to view personal pages')
            }, 2000)
        }
    }

    return (
        <>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            {innerWidth > 992 ?
                <header id={'header'} className={'header'}>
                    <div className={'header_navigation'}>
                        <Link to={'/'}
                              className={'grid-align'}>
                            <img src={artIsFactLogo} alt="artisfact logo"/>
                        </Link>
                        <ul>
                            {/*<li*/}
                            {/*    //     onClick={() => {*/}
                            {/*    //     setNavigationPopup(prevState => ({*/}
                            {/*    //         ...prevState,*/}
                            {/*    //         popup: !prevState.popup*/}
                            {/*    //     }))*/}
                            {/*    // }}*/}
                            {/*    className={'header_navigation_hover_button'}>*/}
                            {/*    <button>Discover</button>*/}
                            {/*</li>*/}
                            <li><Link to={'/artworks#page=1'}>Artworks</Link></li>
                            <li><Link to={'/artists'}
                                      onClick={e => linkArtistProfile(e)}>Artists</Link></li>
                            <li><Link to={'/premium'}>Premium</Link></li>
                            <li><a href={'https://blog.artsted.com'}>Blog</a></li>
                        </ul>
                    </div>
                    <div className={localStorage.getItem(Keys.JWT_TOKEN) ?
                        'header_login_section_user_active header_login_section' :
                        'header_login_section'}>
                        {searchWrapper()}
                        {localStorage.getItem(Keys.JWT_TOKEN) ?
                            cartWrapper()
                            : null}
                        <div className={'header_sign_in'}>
                            <div onClick={() => {
                                if (localStorage.getItem(Keys.JWT_TOKEN)) {
                                    history.push((user_data?.role === 'investor' || user_data?.role === 'organization') ? '/profile/about' : '/profile/portfolio');
                                } else if (!localStorage.getItem(Keys.JWT_TOKEN) && !localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION)) {
                                    setPopupNavigation(null)
                                    setActiveSidebar(false)
                                    bodyOverflow(true)
                                    history.push('/login')
                                }
                            }}
                                 className={'grid-align pointer_sign'}>
                                <img src={signInIcon} alt="sign in icon"/>
                            </div>
                            {authWrapper()}
                        </div>
                    </div>
                </header>
                : <>
                    <header
                        style={activeSidebar ? {boxShadow: 'none'} : {boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.06)'}}
                        id={'header_mobile'} className={'header header_mobile'}>
                        <div className={!hiddenInput ? "logo_wrapper_art" : "logo_hidden_wrapper_art"}>
                            <Link to={'/'} className={'grid-align'}>
                                <img src={artIsFactLogo} alt="artisfact logo"/>
                            </Link>
                        </div>
                        <div className={'left_side_header_menu'}>
                            {mobSearch()}
                            <div className={`hamburger ${activeSidebar ? 'hamburger_crossed' : ''}`}
                                 onClick={() => {
                                     setActiveSidebar(!activeSidebar)
                                     bodyOverflow(!activeSidebar)
                                 }}>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </div>

                    </header>
                    <SidebarMenu activeSidebar={activeSidebar}
                                 searchWrapper={searchWrapper}
                                 popupNavigation={popupNavigation}
                                 authWrapper={authWrapper}
                                 linkArtistProfile={linkArtistProfile}
                                 cart={cartWrapper}
                                 user_data={user_data}
                                 handleOutSideClick={handleOutSideClick}/>
                </>}
            {auth_popup ?
                <HeaderAuthPopup auth_variant={auth_variant}
                                 {...props}
                                 auth_popup={auth_popup}
                                 setUserData={setUserData}
                                 emailSubscribePopup={emailSubscribePopup}
                                 setAuthPopUp={e => setAuthPopUp(e)}
                                 setAuthVariant={e => setAuthVariant(e)}/>
                : null}
            {emailSubscribePopup &&
            <EmailSubscriptionPopup innerWidth={innerWidth}
                                    setEmailSubscribePopup={e => setEmailSubscribePopup(e)}/>}
            {/*{navigationPopup.popup ? <HeaderNavigationPopup popup={navigationPopup.popup} setNavigationPopup={val => setNavigationPopup(val)} data={navigationPopup.popupData}/> : null}*/}
        </>
    )
}

const mapStateToProps = state => ({
    auth_variant: state.auth.auth_variant,
    auth_popup: state.auth.auth_popup,
    user_data: state.auth.user_data,
    cart_length: state.auth.cart_length,
})

const putDispatchToProps = {
    setAuthPopUp,
    setAuthVariant,
    setUserData,
    setCartCount
}

export default connect(mapStateToProps, putDispatchToProps)(Header);
