import classes from './styles/collections_footer.module.scss';
import freeze from '../../assets/img/collections/freeze_col.png';
import sunday from '../../assets/img/collections/sunday_col.png';
import top from '../../assets/img/collections/top20_col.png';

function Card (props) {
    return (
        <div className={classes.card_wrapper}>
            <div className={classes.card}>
                <div className={classes.hovered}>Read more</div>
                <img src={props.src} alt='card_img' className={classes.card_img} />
                <h3 className={classes.card_title}>{props.title}</h3>
                <p className={classes.card_description}>{props.children}</p>
            </div>
        </div>
    );
}

function Collections () {
    return (
        <div className={classes.wrapper}>
            <h2>more collections you will love</h2>
            <div className={classes.cards}>
                <Card src={sunday} title='Sunday Art Brunch: from the crypto art market to the importance of scents'>
                    Frieze is back and in this article we are going to talk about this big return in the art world.
                </Card>
                <Card src={top} title='Top 20 affordable artists to invest in: 2021 edition'>
                    While the market leaders such as KAWS, Damien Hirst, Jeff Koons and Andy Warhol are leading the charts since years, the barrier to entry is quite steep, along with the uncertainty of the ceiling for the demand.
                </Card>
                <Card src={freeze} title='Sunday Art Brunch: Freeze NYC 2021 and the NFT news'>
                    Frieze is back and in this article we are going to talk about this big return in the art world.
                </Card>
            </div>
        </div>
    )
}

export default Collections;