import React from 'react';
import SEO from "../../Components/SEO";

export default function DueDiligence() {
    return (
        <div className={'faq_section_body due_diligence_padding '}>
            <SEO title="Due diligence and AML: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Due diligence and AML: Buy art online Artsted"/>
            <h1>Due diligence and AML</h1>
            <p>In terms of Due Diligence, Artsted is leaving its users the freedom to upload the relevant data in the
                form of self-audit. The compliance check will only be triggered once a user uploads or purchases an item
                that is priced over 10'000 EUR as for the latest European AML directive.</p>
        </div>
    )
};