import React from "react";
import './styles/numListSection.css'

const NumListSection = (props) => {
    return (
        <div className={'num_list_section_container'}>
            <h2 className={'num_list_section_container__title'}>{props.title}</h2>
            <p className={'num_list_section_container__about'}>{props.about}</p>
            {props.items.map((item, index) => (
                <div className={'item_container'}>
                    <div className={'index_container'}>
                        <span className={'index_style'}>{index + 1}{'.'}</span>
                    </div>
                    <div className={'item_text_wrapper'}>
                        {item.text}
                    </div>

                    {/*<span className={'item_text'}>{}</span>*/}
                    <div/>
                    <span className={'item_subtext'}>{item?.subText}</span>
                </div>
            ))}
        </div>
    )
}

export default NumListSection
