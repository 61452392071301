import {Col, Modal, Row, Space, Typography} from "antd";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import Slider from "react-slick";

import API from "../../api/API";
import {defaultBase64Image} from "../../Constants/images";
import useResize from "../../Constants/useResize";
import {getListingStayFailed, getListingStayReceived, getListingStayRequest} from "../../main-store/listing/actions";

import AsideCard from "../../Components/ui/AsideCard";
import IncrementDecrementInput from "../../Components/ui/IncrementDecrementInput";
import ExperiencesItemInfo from "../../Components/experiences/ExperiencesItemInfo";
import StyledButton from "../../Components/ui/StyledButton";
import StyledDatePicker from "../../Components/ui/StyledDatePicker";
import {Loader} from "../../Components/Loader";

import {ChevronLeftMd, ChevronRightMd} from "../../assets/icons";

import styles from './ExperiencesView.module.css';

function ExperiencesView() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {innerWidth} = useResize();
    const [error, setError] = useState('');
    const [date, setDate] = useState([]);
    const [info, setInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {loading, stayData} = useSelector((s) => s.listings);
    const [guests, setGuests] = useState(stayData?.guests_num_min);
    const data = useSelector(state => state.auth);

    const duration = date.length ? moment(date[1]).diff(moment(date[0]), 'days') : 1;
    const mainImage = stayData?.photos?.sort((a, b) => Number(b.main) - Number(a.main))?.[0]?.path;

    useEffect(() => {
        dispatch(getListingStayRequest())
        API().get('user/listing/stay', {params: {id}})
            .then((res) => dispatch(getListingStayReceived(res.data)))
            .catch(() => dispatch(getListingStayFailed()))
    }, [id])

    useEffect(() => {
        if (date[0] && date[1]) {
            API().get('/user/listing/pre-book-info', {
                params: {
                    stay_id: id,
                    date_from: date[0] ? moment(date[0]).format('YYYY-MM-DD') : null,
                    date_to: date[1] ? moment(date[1]).format('YYYY-MM-DD') : null,
                    guests_num: guests
                }
            })
                .then((res) => {
                    setInfo(res.data);
                })
                .catch((e) => setError(e?.response?.data?.message))
        }
    }, [date])

  useEffect(() => {
    if (stayData) {
      setGuests(stayData?.guests_num_min || 1)
    }
  }, [stayData])

    const handleBook = () => {
        if (!date.length) {
            setError('Select dates, please')
            return;
        }
        if (data?.user_data) {
            API().post('/user/listing/book', {
                stay_id: id,
                date_from: moment(date[0]).format('YYYY-MM-DD'),
                date_to: moment(date[1]).format('YYYY-MM-DD'),
                guests_num: guests
            })
                .then(() => history.push('/confirm-pay'))
                .catch((e) => setError(e.response.data.message))
        } else {
            history.push('/registration/type')
        }
    }

    const handleChangeDate = ([date_from, date_to]) => {
        setDate([date_from, date_to])
        setError(null);
    }

    return (
        <main className={styles.wrapper}>
            {loading ? (
                <div className={styles.loaderWrapper}>
                    <Loader/>
                </div>
            ) : (
                stayData && (
                    <>
                        <div className={styles.heroBg}/>
                        <section className={styles.heroSection}>
                            <h1 className={styles.title}>{stayData.name}</h1>
                            <p className={styles.address}>
                                {stayData.address?.split(',')?.splice(-2)?.join(',')}
                            </p>
                            {innerWidth < 768 ? (
                                <div className={styles.photos}>
                                    <Slider
                                        dots
                                        swipeToSlide
                                        adaptiveHeight={false}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        nextArrow={<ChevronRightMd/>}
                                        prevArrow={<ChevronLeftMd/>}
                                    >
                                        {stayData?.photos?.length > 0 ? (
                                            stayData.photos.sort((a, b) => Number(b.main) - Number(a.main))?.map((photo, index) => (
                                                <div key={index} className={styles.photosSlide}>
                                                    <img src={`${process.env.REACT_APP_API_URL}/${photo.path}`} alt=""/>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                <img
                                                    className={styles.defaultImage}
                                                    alt={stayData?.name}
                                                    src={defaultBase64Image}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            ) : (
                                <div className={styles.gallery}>
                                    {stayData.photos.slice(0, 3).sort((a, b) => Number(b.main) - Number(a.main)).map((photo, i) => (
                                        <div className={styles.galleryImage} key={photo.path}>
                                            <img src={`${process.env.REACT_APP_API_URL}/${photo.path}`} alt="123"/>
                                            {stayData.photos.length > 3 && i === 2 && (
                                                <div className={styles.overlayShowAll}
                                                     onClick={() => setIsModalOpen(true)}>
                                                    All {stayData.photos.length} photos >
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </section>
                        <div className={styles.content}>
                            <ExperiencesItemInfo data={stayData}/>
                            <AsideCard>
                                <Space align="center">
                                    <Typography.Title level={4} className={styles.price}>
                                        €{stayData.daily_price}
                                    </Typography.Title>
                                    night
                                </Space>
                                <Space direction="vertical" size={24}>
                                    <Space direction="vertical" className={styles.block}>
                                        <div className={styles.label}>Dates</div>
                                        <Space direction="vertical" size={5} className={styles.block}>
                                            <StyledDatePicker
                                                error={error}
                                                selectsRange
                                                monthsShown={2}
                                                placeholderText="Dates"
                                                selectsDisabledDaysInRange
                                                selected={date[0]}
                                                startDate={date[0]}
                                                endDate={date[1]}
                                                onChange={handleChangeDate}
                                            />
                                            {error && (
                                                <Typography.Text type="danger">{error}</Typography.Text>
                                            )}
                                        </Space>
                                    </Space>
                                    <Space direction="vertical" className={styles.block}>
                                        <div className={styles.label}>Guests</div>
                                        <IncrementDecrementInput
                                            value={guests}
                                            content={guests}
                                            min={stayData?.guests_num_min}
                                            max={stayData?.guests_num_max}
                                            onChange={setGuests}
                                        />
                                    </Space>
                                </Space>
                                <Row gutter={[10, 10]} justify="space-between" align="center">
                                    <Col>
                                        <span className={styles.text}>€{stayData.daily_price} X {duration} nigths</span>
                                    </Col>
                                    <Col>
                                        <Typography.Title level={4} className={styles.price}>
                                            {info?.price && `€${info?.price}`}
                                        </Typography.Title>
                                    </Col>
                                </Row>
                                <StyledButton onClick={handleBook}>
                                    Book now
                                </StyledButton>
                            </AsideCard>
                        </div>
                    </>
                )
            )}
            <Modal
                centered
                header={null}
                footer={null}
                width="70vw"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
            >
                <div className={styles.allImagesModal}>
                    <div className={styles.mainPhoto}>
                        <img src={`${process.env.REACT_APP_API_URL}/${mainImage}`} alt=""/>
                    </div>
                    <div className={styles.imagesList}>
                        {stayData?.photos?.filter((p) => !p.main).map((p) => (
                            <div className={styles.imagesListItem} key={p.path}>
                                <img src={`${process.env.REACT_APP_API_URL}/${p.path}`} alt=""/>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
        </main>
    )
}

export default ExperiencesView;
