import React, {useEffect} from 'react'
import StayInLoop from "../Components/StayInLoop"
import './styles/new_artists.css';
import artists_section_preview from '../assets/img/artists_section_preview.png'
import useResize from "../Constants/useResize";
import NewFiltersWrapper from "../Components/FilterComponents/NewFiltersWrapper";
import NewFilterView from "../Components/FilterComponents/NewFilterView";
import NewPaginationView from "../Components/NewPaginationView";
import artist_avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import {Link} from "react-router-dom";
import SEO from "../Components/SEO";
import {useParams} from "react-router";
import ExtendSEO from "../Components/ExtendSEO";

const initialState = {
    listing_search: {
        value: ''
    },
    category: {
        activeList: [],
        list: []
    },
    country: {
        activeList: [],
        list: [],
    },
}

const seoData = {
    title: "Artists",
    description: "Art created in the present or a recent past is a broad, straightforward description of the term Contemporary Art; however, it denotes more than that. Understanding the differences between contemporary art and its predecessor, modern art, is crucial before diving in.\n" +
        "It doesn't help that contemporary and modern are interchangeable terms in everyday English. The phrases Modern Art/artists and Contemporary Art/artists are frequently confused and used interchangeably. \n" +
        "These phrases relate to two distinct periods of creation and different scales and methods of producing art. Art created between the 1860s, and the 1970s is referred to as Modern Art. During this time, artists began to reject conventions and restrictions and embrace material experimentation. As they transitioned from figurative to abstract art, modern painters established a new method of artistic intention. There are many versions as to when precisely Modern Art began. \n" +
        "Many people associate the 1970s with the advent of Postmodernism, which marked the end of Modern Art and the beginning of Contemporary Art. Contemporary Art is varied and diverse; it rejects a unified organising concept or title. Contemporary artists typically push the boundaries of what art and artwork may be by using various materials, techniques, images, and subject areas. Contemporary artists continue to explore, question, and reexamine the idea of art itself, lacking a shared belief and vision and cooperative means of expression compared to Modern Art and other historical art periods. \n" +
        "Despite the wide range of Contemporary Art, specific similar themes can be seen in many of the pieces. Contemporary artists frequently examine hot-button subjects that shape our society, including time and memory, globalisation and migration, the body, identity politics, technology, and the digital world. The importance given to the viewer's experience of the artwork is another crucial aspect of Contemporary Art that sets it apart from Modern Art. Contemporary artists frequently focus on their pieces' impact on the audience when creating them. \n" +
        "Art no longer revolves around the genius creator who made it or the beauty and shape of the piece. Today's art takes on many diverse forms, some transient (like Performance Art or Street Art) and exists outside of typical settings. Artsted provides a thorough overview of the many aesthetic expressions found in Contemporary Art. The artists we pick are evaluated according to the influence of their contributions to the art world, our culture, and the market. The artists presented on our platform were chosen for their essential and ground-breaking works and their representation of the numerous artistic styles and tendencies that define Contemporary Art. \n" +
        "\n" +
        "Learn about contemporary artists with Artsted!"
}

const NewArtists = () => {
    let {innerWidth} = useResize();
    let {
        filtersObject,
        showFilterPopup,
        setListingData,
        setActiveFilterFunc,
        loading,
        activeFilter,
        setActiveFilter,
        setFiltersObject,
        listingData,
        activeFilterArray,
        categoryViewSection,
        handleDetectOutSide,
        urlFromFilters
    } = NewFiltersWrapper(initialState, '/public/artist_filters', 'artists', '/public/artists', null, true);
    let {category, filters, page} = useParams()

    if (category?.includes("page")) {
        page = category
        category = undefined
    }

    useEffect(() => {
        window.history.replaceState(null, '', urlFromFilters(activeFilterArray))

        //eslint-disable-next-line
    }, [activeFilterArray, listingData.page])

    useEffect(() => {
        if (category) {
            let tempCateg = category === "nft" ? category.toUpperCase() : [category[0].toUpperCase(), category.slice(1)].join('')
            setActiveFilterFunc(tempCateg, "category")
        }

        if (filters) {
            const filtersArr = filters.split('/')
            let finalArr = []

            filtersArr.forEach(i => {
                let filterKeyValues = i.split("=");
                filterKeyValues[1]?.split(",").forEach(j => {
                    finalArr.push({name: j, category: filterKeyValues[0]})
                })
            })

            let filtersSorted = [...finalArr].sort((a, b) => a.category.localeCompare(b.category) || a.name.localeCompare(b.name));

            if (filtersSorted.map(i => i.name + "-" + i.category).join('_') === [...finalArr].map(i => i.name + "-" + i.category).join('_')) {
                finalArr.forEach(i => {
                    setActiveFilterFunc(i.name, i.category)
                })
            } else {
                window.open(urlFromFilters(finalArr), "_self")
            }
        }

        if (page && page.includes('page-')) {
            const pageNum = page.split("-")?.[1];

            setListingData(prevState => ({
                ...prevState,
                page: +pageNum ?? 1
            }));
        }
        //eslint-disable-next-line
    }, [category, filters, page])


    const filterViewSearch = () => (
        <label className={'listing_search'}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8047 14.862L11.2547 10.312C12.136 9.22334 12.6667 7.84 12.6667 6.33334C12.6667 2.84134 9.82531 0 6.33331 0C2.84131 0 0 2.84131 0 6.33331C0 9.82531 2.84134 12.6667 6.33335 12.6667C7.84 12.6667 9.22335 12.136 10.312 11.2547L14.862 15.8047C14.992 15.9347 15.1627 16 15.3333 16C15.504 16 15.6747 15.9347 15.8047 15.8047C16.0653 15.544 16.0653 15.1227 15.8047 14.862ZM6.33335 11.3333C3.576 11.3333 1.33334 9.09066 1.33334 6.33331C1.33334 3.57597 3.576 1.33331 6.33335 1.33331C9.09069 1.33331 11.3333 3.57597 11.3333 6.33331C11.3333 9.09066 9.09066 11.3333 6.33335 11.3333Z"
                    fill="black"/>
            </svg>

            <input type="text"
                   value={filtersObject.listing_search.value}
                   placeholder={'Search for Artists....'}
                   onChange={({target}) => {
                       setFiltersObject(prevState => ({
                           ...prevState,
                           listing_search: {
                               value: target.value,
                           }
                       }))
                       setListingData(prevState => ({
                           ...prevState,
                           page: 1
                       }))
                   }}/>
        </label>
    )

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    const EveryGalleryImg = ({id, image}) => (
        <Link to={`/public-artwork/${id}`} className={'every_artwork'}>
            <img src={image} alt=""/>
        </Link>
    )

    // console.log(activeFilterArray, filtersObject, listingData);
    return (
        <div className={'wrapper_new_artists'}>
            <SEO title={"Famous artists paintings for sale | Art investment: Artsted" + listingData.page > 1 ? " Page " + listingData.page : ""}
                 description={"The best emerging creators and famous artists HERE ♦ Buying artist paints as an investment with Art marketplace: Artsted" + listingData.page > 1 ? " Page " + listingData.page : ""}>
                {listingData.page !== 1 &&
                <link rel="canonical" href={window.location.href.replace(/\/page-[0-9]+/, "")}/>}
            </SEO>

            <div className={'new_artists'}>
                <h1>Our artists</h1>
                <div className={'section_preview'}>
                    <div className={'section_preview__text'}>
                        <span>
                            The ultimate art buying insight: try Premium analytics!
                        </span>
                    </div>
                    <div className={'section_preview__img'}>
                        <img src={artists_section_preview} alt="artists_section_preview"/>
                    </div>
                </div>
                <div className={'section_filter listings'}>
                    {filterViewSearch()}
                    {filtersObject.loading && filtersObject.category.list?.length ?
                        <NewFilterView category={'category'}
                                       handleDetectOutSide={handleDetectOutSide}
                                       previewTitle={'Sort by Category'}
                                       activeFilter={activeFilter}
                                       filterList={filtersObject.category.list}
                                       setActiveFilterFunc={setActiveFilterFunc}
                                       setListingData={e => setListingData(e)}
                                       setActiveFilter={e => setActiveFilter(e)}
                                       showFilterPopup={e => showFilterPopup(e)}/>
                        : null}
                    {filtersObject.loading && filtersObject.country.list?.length ?
                        <NewFilterView category={'country'}
                                       previewTitle={'Sort by Country'}
                                       handleDetectOutSide={handleDetectOutSide}
                                       activeFilter={activeFilter}
                                       filterList={filtersObject.country.list}
                                       setActiveFilterFunc={setActiveFilterFunc}
                                       setListingData={e => setListingData(e)}
                                       setActiveFilter={e => setActiveFilter(e)}
                                       showFilterPopup={e => showFilterPopup(e)}/>
                        : null}
                    {activeFilterArray.length ?
                        categoryViewSection()
                        : null}
                </div>
                <div className={'artists_list'}>
                    {listingData.artist?.length ?
                        listingData.artist.map(item => (
                            <div className={'every_artist'} key={item.id}>
                                <Link to={`/artist-profile/${item.profile_url}`} className={'avatar_wrapper'}>
                                    <img src={item.avatar ?? artist_avatar_default} alt="avatar"/>
                                </Link>
                                <div className={'info_wrapper'}>
                                    <div className={'artist-name-container'}>
                                        <Link to={`/artist-profile/${item.profile_url}`} className={'name'}>{item.name}</Link>
                                        {item.premium &&
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z"
                                                fill="#3473FF"/>
                                            <path d="M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335" stroke="white"
                                                  strokeWidth="1.6" strokeLinecap="round"/>
                                        </svg>
                                        }
                                    </div>
                                    <span className={'followers'}>{item.followers ? item.followers + " Followers" : "new member"}</span>
                                    <span className={'cv_entries'}>{item.cv_entries} CV entries</span>
                                    <Link to={`/artist-profile/${item.profile_url}`} className={'see_profile'}>
                                        See Profile &#62;
                                    </Link>
                                </div>
                                <div className={'gallery_wrapper'}>
                                    {item.artworks?.map((item, index) => (
                                        innerWidth < 1200 ?
                                            innerWidth > 1000 && index < 3 ?
                                                <EveryGalleryImg {...item} key={index}/> :
                                                innerWidth < 1000 && index < 2 ?
                                                    <EveryGalleryImg {...item} key={index}/>
                                                    : innerWidth < 768 ?
                                                    <EveryGalleryImg {...item} key={index}/>
                                                    : null
                                            : <EveryGalleryImg {...item} key={index}/>
                                    ))}
                                </div>
                            </div>
                        ))
                        : null}
                </div>
                {loading && listingData.total > 9 ?
                    <div className={'wrapper_new_pagination'}>
                        <NewPaginationView activePage={listingData}
                                           onChange={pageNumber => {
                                               window.scrollTo(0, 0)
                                               setListingData(prevState => ({
                                                   ...prevState,
                                                   page: pageNumber
                                               }))
                                           }}/>
                    </div>
                    : null}
            </div>
            {listingData.page === 1 && <ExtendSEO title={seoData.title} description={seoData.description}/>}
            <StayInLoop/>
        </div>
    )
}
export default NewArtists
