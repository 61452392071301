export const InitialState = {
    video: {
        preview: null,
        value: null,
        complete: {
            value: false,
            percent: 10
        }
    },
    avatar: {
        complete: {
            value: false,
            percent: 10
        }
    },
    about: {
        value: '',
        error: false,
        complete: {
            value: false,
            percent: 20
        }
    },
    education: {
        complete: {
            value: false,
            percent: 10
        },
        sectionName: 'education',
        activeList: [],
        entriesShow: false,
        edit: null,

        bodyFields: {
            date: {
                required: true,
                type: 'range',
                value: '',
                name: 'education_date',
                error: false
            },
            context_field: {
                required: true,
                type: 'text',
                value: '',
                title: 'Title of Institution',
                name: 'title',
                error: false
            },
            country: {
                value: '',
                required: true,
                title: 'Country',
                name: 'country',
                popup: false,
                options: [],
                error: false
            },
            context_field_description: {
                value: '',
                required: true,
                error: false,
                title: 'Specialization',
                name: 'description'
            },
            proof_document: {
                value: null,
                preview: null,
                title: 'Upload proof of your activity'
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
    awards: {
        activeList: [],
        sectionName: 'awards',
        edit: null,
        entriesShow: false,
        complete: {
            value: false,
            percent: 10
        },
        title: 'PRIZES, CONTESTS WON',
        bodyFields: {
            date: {
                type: 'picker',
                error: false,
                required: true,
                value: '',
                name: 'date'
            },
            context_field_description: {
                value: '',
                title: 'Short description',
                error: false,
                required: true,
                name: 'description'
            },
            context_field: {
                type: 'drop_down',
                value: '',
                title: 'Title of Contest',
                error: false,
                required: true,
                name: 'title_of_contest',
                options: []
            },
            proof_document: {
                value: null,
                preview: null,
                title: 'Upload proof of your activity'
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
    museums: {
        activeList: [],
        sectionName: 'museums',
        edit: null,
        entriesShow: false,
        complete: {
            value: false,
            percent: 10
        },
        title: 'ACQUISITION FOR A PUBLIC COLLECTION (MUSEUM)',
        bodyFields: {
            date: {
                type: 'picker',
                required: true,
                error: false,
                name: 'date',
                value: ''
            },
            context_field: {
                type: 'drop_down',
                required: true,
                error: false,
                value: '',
                title: 'Museum',
                name: 'museum',
                options: []
            },
            context_field_description: {
                required: true,
                value: '',
                error: false,
                title: 'Short description',
                name: 'description',
            },
            proof_document: {
                value: null,
                preview: null,
                title: 'Upload proof of your activity'
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
    media: {
        activeList: [],
        sectionName: 'media',
        edit: null,
        entriesShow: false,
        complete: {
            value: false,
            percent: 10
        },
        title: 'PR, MEDIA FEATURES',
        bodyFields: {
            date: {
                name: 'date',
                required: true,
                error: false,
                type: 'picker',
                value: ''
            },
            context_field: {
                type: 'drop_down',
                value: '',
                required: true,
                error: false,
                title: 'Title',
                name: 'title',
                options: []
            },
            context_field_description: {
                value: '',
                required: true,
                error: false,
                title: 'Short description',
                name: 'description'
            },
            proof_document: {
                value: null,
                preview: null,
                title: 'Upload proof of your activity'
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
    exhibitions: {
        activeList: [],
        edit: null,
        sectionName: 'exhibitions',
        entriesShow: false,
        complete: {
            value: false,
            percent: 10
        },
        title: 'EXHIBITIONS',
        bodyFields: {
            date: {
                name: 'date',
                required: true,
                type: 'picker',
                value: ''
            },
            context_field_description: {
                value: '',
                title: 'Short description',
                required: true,
                name: 'description'
            },
            context_field: {
                type: 'drop_down',
                value: '',
                title: 'Exhibitions',
                required: true,
                name: 'exhibitions',
                options: []
            },
            proof_document: {
                value: null,
                title: 'Upload proof of your activity',
                preview: null
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
    residencies: {
        activeList: [],
        edit: null,
        sectionName: 'residencies',
        entriesShow: false,
        complete: {
            value: false,
            percent: 10
        },
        title: 'RESIDENCIES',
        bodyFields: {
            date: {
                name: 'date',
                required: true,
                type: 'picker',
                value: ''
            },
            context_field_description: {
                value: '',
                title: 'Short description',
                required: true,
                name: 'description'
            },
            context_field: {
                type: 'drop_down',
                value: '',
                title: 'Residencies',
                required: true,
                name: 'residencies',
                options: []
            },
            proof_document: {
                value: null,
                title: 'Upload proof of your activity',
                preview: null
            },
            proof_link: {
                title: 'Link proof of your activity',
                value: ''
            }
        }
    },
}