import {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";

export const useGetLastChartHash = () => {
    const [activeHashUrl, setActiveHashUrl] = useState(null)
    let location = useLocation();

    useEffect(() => {
        let activePage = location.hash.split('=')[1];
        if (!isNaN(Number(activePage)))
        setActiveHashUrl(Number(activePage))
        else if (activePage === undefined || activePage === null) {
            setActiveHashUrl(1)
        } else window.location.href = '/404-redirect'
    }, [location])

    return {activeHashUrl}
}
