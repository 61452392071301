export default class otherConst {
    static chartColors = ['#014498', '#4D9CFE', '#80B8FE', '#1958E3', '#002E66'];
    static months = [
        {val: "January", prev: 'Jan'},
        {val: "February", prev: 'Feb'},
        {val: "March", prev: 'Mar'},
        {val: "April", prev: 'Apr'},
        {val: "May", prev: 'May'},
        {val: "June", prev: 'Jun'},
        {val: "July", prev: 'Jul'},
        {val: "August", prev: 'Aug'},
        {val: "September", prev: 'Sep'},
        {val: "October", prev: 'Oct'},
        {val: "November", prev: 'Nov'},
        {val: "December", prev: 'Dec'}
    ]
}
