import React, {useEffect, useState} from 'react';
import '../../styles/terms_of_service.css'
//import artsted_logo from "../../../assets/img/new_landing/artsted_logo.svg";
import {Navigation} from "../../../routers/navigation";
//import log_out from "../../../assets/img/admin/log_out.svg";
//import API from "../../../api/API";
//import Keys from "../../../Constants/helper";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import useResize from "../../../Constants/useResize";
import SEO from "../../../Components/SEO";

export default function TermsOfService() {
    const {innerWidth} = useResize();

    let location = useLocation();

    function SwitcherNavBar({routes}) {
        return (
            <Switch>
                {routes.map((route, index) => (
                    <Route path={route.path}
                           exact={true}
                           component={route.main}
                           key={index}/>
                ))}
            </Switch>
        )
    }

    //const handleLogOut = () => {
    //    API(Keys.JWT_TOKEN_ADMIN).post('/admin/logout')
    //        .then(() => {
    //            localStorage.removeItem(Keys.JWT_TOKEN_ADMIN)
    //            window.location.href = '/admin';
    //        });
    //};
    const renderSwitchRoute = React.useCallback(() => (
        <div /*className={'wrapper_admin_panel_content'}*/>
            <SwitcherNavBar routes={Navigation.mainNavigationTermsOfService}/>
        </div>
    ), [])

    const [activeNavigationPage, setActiveNavigationPage] = useState(location.pathname.split('/'))
    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        let url = location.pathname.split('/');
        setActiveNavigationPage(url)
    }, [location.pathname])

    const OldSchoolMenuLink = ({label, pathname, path, icon}) => {
        return label ? (
            <div
                className={`terms_of_service_navigation_wrapper ${activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'terms_of_service_navigation_wrapper_active' : ''}`}>
                <Link key={pathname}
                      className={activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'admin_bar_active_link' : null}
                      onClick={() => {
                          if (pathname === 'blog') {
                              window.open(`https://webflow.com/dashboard/sites/blog-artsted-com/general`)
                          } else {
                              setActiveNavigationPage(pathname)
                              window.scrollTo(0, 0)
                          }
                      }}
                      to={path}>
                    {icon}
                    <span>{label}</span>
                    {activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ?
                        <div className={'terms_of_service_active_close'}>
                            <div className={'vertical_line_terms_item_close'}/>
                            <div className={'horizontal_line_terms_item_close'}/>
                        </div>
                        :
                        <div className={'terms_of_service_active'}>
                            <div className={'vertical_line_terms_item'}/>
                            <div className={'horizontal_line_terms_item'}/>
                        </div>
                    }
                </Link>
                {(activeNavigationPage === pathname || activeNavigationPage.includes(pathname)) && innerWidth <= 570 && renderSwitchRoute()}
            </div>
        ) : null
    };

    return (
        <div>
            <SEO title="Terms of Service: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Terms of Service: Buy art online Artsted"/>
            <div className={'terms_of_service_container'}>
                <h1>Terms of Service</h1>
                <div /*className={'terms_of_service-two-block'}*/>
                    <div /*className={'terms_of_service_content_container'}*/>

                    </div>
                    <div className={'terms_of_service_section_container'}>
                        <div /*className={'wrapper_admin_panel_navigation'}*/>
                            <div>
                                <div className={'panel_navigation_content_terms_of_service'}>
                                    {/*console.log(Navigation.mainNavigationTermsOfService)*/}
                                    {Navigation.mainNavigationTermsOfService.map((item, index) => {
                                        return (
                                            <OldSchoolMenuLink {...item} key={index}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {innerWidth >= 570 && renderSwitchRoute()}
                    </div>
                </div>
            </div>
        </div>
        // <div className={'answer_from_footer'}>
        //     <h1>Terms of Service</h1>
        //     <span>General Terms and Conditions (GTC)<p>for the sale of products and services via the Website www.artsted.com operated by Artisfact Ltd.</p></span>
        //
        //     <div className={'entity_rules first_block_of_terms'}>
        //         <span>1. Applicability of these GTC and Conclusion of Contract</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.1</div>
        //             <span><p>The products and services of this website are distributed resp. produced by (Website operator):</p>
        //             <p>Artisfact Ltd.</p>
        //             <p>71-75 Shelton Street, Covent Garden</p>
        //             <p>London, England, WC2H 9JQ</p>
        //             <p>Email:<a href="mailto:info@artsted.com">info@artisfact.co.uk</a></p></span>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.2</div>
        //             <p>These present GTC apply to all on the website available products and services for individual
        //                 and business customers.
        //                 By posting the online order, the individual or business customer accepts these present GTC.
        //                 Any conditions conflicting or deviating from these GTC only apply after a respective
        //                 confirmation in written form by the Website operator.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.3</div>
        //             <p>The offerings of the Website operator on the website are presented without any obligation.
        //                 The Website operator reserves the right to change prices, range of products and services, as
        //                 well as to execute technical changes at any time.
        //                 The product and service offerings are only valid, as long as they are presented on the website
        //                 and as long as stocks last.
        //                 Product and service information such as descriptions, pictures etc., serve illustration purposes
        //                 and are presented without any obligation.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.4</div>
        //             <p>The order of a customer via the online shop counts as a compulsory offer to the Website
        //                 operator.
        //                 By clicking the button "place order" in the last step of the order process, the customer posts a
        //                 compulsory order for the products and services added to the shopping cart.
        //                 The customer is obliged to state truthfully all requested information in the order process
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.3</div>
        //             <p>The Website operator reserves the right not to accept an order.
        //                 The decision about acceptance or refusal of an order is at the Website operators sole
        //                 discretion.
        //                 The contract is effective after the declaration of acceptance by the Website operator.
        //                 The order confirmation sent to the customer by email counts as declaration of acceptance.
        //                 Changes and updates of the order, of the delivery conditions, of the product and service range,
        //                 as well as restrictions on the quantities delivered are reserved until the order is confirmed by
        //                 the Website operator.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>1.3</div>
        //             <p>These GTC apply accordingly to purchase contracts that have been concluded in a different
        //                 way, e,g, via phone, email or letter.
        //                 Customers will be informed in the order confirmation where they can download the GTC on the
        //                 Internet.
        //             </p>
        //         </div>
        //     </div>
        //
        //     <div className={'entity_rules second_block_of_terms'}>
        //         <span>2. Prices and Special Offers</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>2.1</div>
        //             <p>Prices are indicated in Euros / EUR. The prices valid at the time the order was placed are
        //                 authoritative.
        //                 The displayed prices include the applicable statutory VAT, if not separately declared.
        //                 Handling fees, as well as shipment and packaging costs are indicated separately.
        //                 Depending on the destination of the shipment, additional costs for duty and taxes may accrue to
        //                 the recipient.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>2.2</div>
        //             <p>Conditions for special offers and rebates are indicated where applicable.</p>
        //         </div>
        //     </div>
        //
        //     <div className={'entity_rules third_block_of_terms'}>
        //         <span>3. Delivery Conditions</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>3.1</div>
        //             <p>The website operator delivers worldwide.
        //                 The shipping costs incurred depending on the delivery address are shown accordingly in the
        //                 purchase process.
        //                 Any additional costs for customs or other taxes that incur upon receipt must be borne
        //                 additionally by the customer
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>3.2</div>
        //             <p>Delivery is executed to the contact person and delivery address indicated by the customer.
        //                 The delivery is executed via postal mail.
        //                 All information regarding availability and delivery dates is non-binding, unless confirmed in
        //                 written form by the Website operator.
        //                 If the products are in stock, the delivery is executed within 14 days.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>3.3</div>
        //             <p>All claims for damages due to late deliveries are excluded.
        //                 The Website operator is not liable for delays in delivery caused by suppliers or third parties,
        //                 and owes no compensation in this regard whatsoever.
        //             </p>
        //         </div>
        //     </div>
        //     <div className={'entity_rules fourth_block_of_terms'}>
        //         <span>4. Withdrawal and Returns</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>4.1</div>
        //             <span><p>Right of withdrawal: Customers can cancel their order (contract declaration) within 14 days
        //                 without giving any reason.
        //                 This can be done in text form (letter, email) or by returning the goods received.
        //                 The period begins after receipt of this instruction in text form, however not before receipt of
        //                 the goods by the customer (in the case of the recurring delivery of similar goods not before
        //                 receipt of the first partial delivery) and also not before fulfilling the information
        //                 obligations of the Website operator.
        //                 To meet the withdrawal deadline, it is sufficient to send the withdrawal or ship the goods in
        //                 good time.</p>
        //             <p>The withdrawal by letter or email must be sent to the Website operator (postal address and email
        //                 see above).</p>
        //             <p>The return of the goods is to be sent within 7 days of receipt to the sender named on the goods
        //                 shipment.
        //                 Important: This can be different depending on the delivery location of the order.</p>
        //             <p>The only exceptions to this are specially marked articles, e.g. promotions</p></span>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>4.2</div>
        //             <p>Consequences of withdrawal: In the event of an effective withdrawal, the services received by
        //                 both parties must be returned and any benefits (e.g. interest) drawn up must be surrendered.
        //                 If the customer cannot return the received service or uses (e.g. benefits of use), or return
        //                 only partially or return only in a deteriorated condition, the customer must compensate the
        //                 Website operator in this respect.
        //                 The customer only has to pay compensation for the deterioration of the goods and for the uses
        //                 made, insofar as the use or the deterioration is due to handling the item that goes beyond
        //                 checking the properties and functionality.
        //                 "Checking the properties and functionality" means testing and trying out the respective goods,
        //                 as is possible and customary in a shop.
        //                 The goods are to be returned at the customer's own expense and risk. The Website operator under
        //                 no circumstances accepts returns against cash on delivery.
        //                 Obligations to reimburse payments must be fulfilled within 30 days.
        //                 The period begins for the customer when the declaration of cancellation or the goods are sent,
        //                 for the Website operator when it is received</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>4.3</div>
        //             <p>In the event of a return, in any case the goods must be sent back new, unused, and in their
        //                 original condition incl. label(s) and in the original packing.</p>
        //         </div>
        //     </div>
        //
        //     <div className={'entity_rules fifth_block_of_terms'}>
        //         <span>5. Warranty and Liability</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>5.1</div>
        //             <p>The customer must report obvious faults at latest 7 days after delivery of the goods by email
        //                 to <a href="mailto:info@artsted.com">info@artisfact.co.uk</a>.
        //                 Hidden faults must be reported immediately, at latest 7 days after discovery by email.
        //                 If the customer fails to report in due time, the warranty claims expire.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>5.2</div>
        //             <p> Faulty goods must be sent back including a copy of the invoice and a detailed description of
        //                 the fault.
        //                 Upon receipt of the written complaint, the return address will be communicated to the customer
        //                 by email.
        //                 The transport costs incurred are borne by the customer.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>5.3</div>
        //             <p>In case of warranty the Website operator decides if an appropriate reduction of the purchase
        //                 price, a replacement of the goods, or a reimbursement of the purchase price will be effectuated.
        //                 Damages arising from normal wear and tear, excessive use, any negligence, faulty storage or
        //                 treatment, faulty maintenance, failure to follow care instructions, or other causes in the sole
        //                 responsibility of the customer, and external influences not in the responsibility of the Website
        //                 operator are excluded from the warranty and liability.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>5.4</div>
        //             <p> Other claims of the customer – irrespective of the legal basis – are, to the extent legally
        //                 permitted, excluded.
        //                 Neither the Website operator, nor any of its officers, directors, employees, agents, contractors
        //                 or other suppliers is liable to the customer for any loss, cost, damage or other injury not
        //                 inflicted to the goods themselves, and any consequential damages caused by a defect, lost
        //                 profits or other financial losses.</p>
        //         </div>
        //     </div>
        //     <div className={'entity_rules sixth_block_of_terms'}>
        //         <span>6. Payment Conditions</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>6.1</div>
        //             <p> The prices are displayed under the respective offerings and listed in Euros / EUR. Applicable
        //                 is the price at the time the order is made.
        //                 The applicable price includes the applicable statutory VAT, if not separately declared.
        //             </p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>6.2</div>
        //             <p> The customer may choose among the following payment options: <br/>
        //                 – Pay by credit card<br/>
        //                 – Pay by bank transfer (prepay): the invoice will be sent to the customer after receipt of the
        //                 order and must be paid in advance by bank transfer. If no payment is received within 14 days of
        //                 receipt of the order, the order will be canceled by the Website operator. Any partial payments
        //                 will be refunded.</p>
        //         </div>
        //     </div>
        //
        //     <div className={'entity_rules seventh_block_of_terms'}>
        //         <span>7. Retention of Title and Trademark Rights</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>7.1</div>
        //             <p>The goods remain the property of the Website operator, until the due payment is received in
        //                 full and on time.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>7.2</div>
        //             <p>Tools or auxiliary means manufactured for the creation of custom products on behalf of the
        //                 customer remain the property of the Website operator and are not provided to the customer. This
        //                 also applies if these materials are separately charged for. The customer warrants and ensures
        //                 that the implementation of her order and the distribution of goods in her name (trademark) will
        //                 not infringe upon any copyright and any protective or proprietary or other rights of any third
        //                 party or parties. The customer indemnifies the Website operator from all possible third party
        //                 claims resulting from the infringement of their rights.</p>
        //         </div>
        //     </div>
        //
        //     <div className={'entity_rules eighth_block_of_terms'}>
        //         <span>8. Warranty for the Services of the Website</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.1</div>
        //             <p>The Website operator guarantees thorough performance of his services and obliges himself to
        //                 perform them in accordance with the current technological standards.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.2</div>
        //             <p>The liability of the Website operator is limited to damages that are attributable to willful
        //                 breaches of contract or gross or average negligence of its employees.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.3</div>
        //             <p>The customers are aware that even with careful software development and maintenance
        //                 occasional errors may occur, so that the Website operator does not guarantee an uninterrupted
        //                 operation of the Website. The Website operator is not liable for consequential damages, loss of
        //                 profits and loss of data as well as damages as a result of service interruptions.</p>
        //         </div>
        //         <div className={'products_services_terms list_block '}>
        //             <div>8.4</div>
        //             <p>The Website operator carries out planned system interruptions, such as maintenance checks
        //                 whenever possible outside office hours. He can interrupt operations if required due to important
        //                 reasons, e.g. in case of disorders or danger of abuse. If possible information about
        //                 interruptions will be published on the Website.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.5</div>
        //             <p>The Website operator is not liable for defects and faults outside of his responsibility,
        //                 specifically security deficiencies and standstills caused by third parties, he works with, or
        //                 whom he depends upon.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.6</div>
        //             <p>The Website operator is furthermore not liable in the event of force majeure, improper use,
        //                 disregard of risks on the side of the customer or third parties, excessive use, inadequate
        //                 equipment and extreme environmental conditions, unauthorized manipulation of the program code of
        //                 the software on the part of the customer or malfunctions caused by third parties (viruses, worms
        //                 etc.), that occur despite the current implemented security precautions.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.7</div>
        //             <p>The Website operator informs the customers on the Website about risks, e.g. spam messages
        //                 that were supposedly sent in his name, as well as security precautions.</p>
        //         </div>
        //         <div className={'products_services_terms list_block '}>
        //             <div>8.8</div>
        //             <p>In the event that the customers detect mistakes on the Website, they shall report them to the
        //                 customer service as fast as possible.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>8.9</div>
        //             <p>The customers are responsible for the security of systems, programs, and data under their
        //                 influence. In their own interest, customers shall keep passwords and user identities secret from
        //                 third parties.</p>
        //         </div>
        //     </div>
        //     <div className={'entity_rules ninth_block_of_terms'}>
        //         <span>9. Data Protection and Processing of Data</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.1</div>
        //             <p>The Website operator expressly guarantees compliance with the rules on data protection. He
        //                 obliges himself, for systems, programs, etc., that belong to him and on which he has influence,
        //                 to ensure safety according to the current technological standards.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.2</div>
        //             <p>The customers provide the Website operator with all information required for the services
        //                 according to the contract and warrant, that all data entered by them are correct and complete.
        //                 The customers report changes of address and other required information immediately.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.3</div>
        //             <p>The customers acknowledge that the Website operator stores data that is required for
        //                 fulfillment of the contract, and stores it in accordance with legal archiving obligations and
        //                 potentially analyzes it for statistical purposes. The Website operator obliges himself not to
        //                 share the data of the customers with third parties for other purposes than stated in the
        //                 contract.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.4</div>
        //             <p>This Website stores only personal data that has been provided by the customers themselves by
        //                 registring for the newsletter and as clients. Customers can delete their data at any time, as
        //                 follows: send an email with the subject: "delete my data" to <a
        //                     href="mailto:info@artsted.com">info@artisfact.co.uk</a>.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.5</div>
        //             <p>The Website operator regularly sends out a newsletter with various contents. The newsletter
        //                 can be unsubscribed from at any time via user account settings or by clicking the "unsubscribe"
        //                 link in the newsletter email.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>9.6</div>
        //             <p>This Website does not use permanent cookies.
        //                 This Website uses Google Analytics. To disable Google Analytics customers can install a browser
        //                 add-on from Google, available here:
        //                 <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
        //             </p>
        //         </div>
        //     </div>
        //     <div className={'entity_rules tenth_block_of_terms'}>
        //         <span>10. Final Provisions and Changes of GTC</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>10.1</div>
        //             <p>The here presented Terms and Conditions may be revised at any time by the Website
        //                 operator.</p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>10.2</div>
        //             <p>Changes and amendments to these Terms and Conditions are effective and valid upon publishing
        //                 on the Website.</p>
        //         </div>
        //         <div className={'products_services_terms list_block'}>
        //             <div>10.3</div>
        //             <p>It is the customer's responsibility to inform himself regularly about the content of and the
        //                 potential changes to the presented GTC, by looking up the following address:
        //                 <a href="/">www.artsted.com</a>
        //             </p>
        //         </div>
        //     </div>
        //     <div className={'entity_rules eleventh_block_of_terms'}>
        //         <span>11. Applicable Law and Jurisdiction</span>
        //         <div className={'products_services_terms list_block'}>
        //             <div>11.1</div>
        //             <p>In the event of a dispute, only substantive British law is applicable, explicitly excluding
        //                 the application of any conflict of law provisions. The place of jurisdiction is the ordinary
        //                 courts at the registered office of the website operator.</p>
        //         </div>
        //
        //         <div className={'products_services_terms list_block'}>
        //             <div>11.2</div>
        //             <p>Should individual provisions of this contract be or become without legal force, the validity
        //                 of the rest of the contract is not touched. The ineffective provisions shall be replaced by an
        //                 agreement in accordance with the law and in as close correspondence as possible to the will of
        //                 the parties.</p>
        //         </div>
        //         <div className={'data_of_rules_public'}>
        //             <p>London, 01.07.2020</p>
        //         </div>
        //     </div>
        // </div>
    )
}