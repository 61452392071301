import {useHistory} from 'react-router-dom';
import classes from './styles/onboarding_wrapper.module.scss';
//import artist_photo from '../../assets/img/onboarding/artist_photo.png';
import classNames from 'classnames';
import Button from '../../Components/CustomComponents/Onboarding/Button/index'
import {setUserRegistrationData} from "../../main-store/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import historyDetectBack from './HistoryBackDetect';
import useResize from "../../Constants/useResize";
import {bodyOverflow} from "../../Constants/bodyOverflow";

const Form = props => {
    const history = useHistory()
    const dispatch = useDispatch();
    const store = useSelector(store => store.auth);
    let activeUrl = window.location.pathname.split('/').pop();
    let {innerWidth} = useResize();

    const backClick = () => {
        if (activeUrl === 'auth') {
            let {tempArray} = historyDetectBack(store.user_registration_data, store.active_history, true)
            dispatch(setUserRegistrationData(tempArray))
            history.push('/registration/type');
        } else if (activeUrl === 'type' || activeUrl === 'login') {
            dispatch(setUserRegistrationData(null))
            history.push('/');
        } else if (activeUrl === 'reset_password') {
            history.goBack()
        } else if (activeUrl === 'facebook_email_login') {
            history.push('/login');
        } else {
            let {tempArray} = historyDetectBack(store.user_registration_data, store.active_history, true)
            dispatch(setUserRegistrationData(tempArray))
            history.goBack();
        }
        bodyOverflow(false)
    }

    return (
        <div className={classes.form_wrapper}>
            <div className={classes.wrapper_head}>
                <div className={classes.form__logo}
                     onClick={() => history.push('/')}/>
                <Button onClick={() => backClick()}
                        className={classes.form__back}>
                    {innerWidth < 979 ?
                        <>
                            <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.75167 7.88797L0.263672 4.30397L3.75167 0.719971H6.56767L3.07967 4.30397L6.56767 7.88797H3.75167Z"
                                    fill="black"/>
                            </svg>
                            Go back {activeUrl === 'type' ? 'to main page' : null}</> :
                        <svg width="19" height="28" viewBox="0 0 19 28" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 0H15.694L0.382 13.63L15.694 27.202H19L3.688 13.63L19 0Z" fill="black"/>
                        </svg>
                    }
                </Button>
            </div>
            <div className={classes.form__content}>
                {props.children}
            </div>
        </div>
    );
}
// 14/10
const Picture = ({src, imgRight, artAuthor, authorAvatar}) => {
    return (
        <div
            className={classes.picture_wrapper}
            style={{backgroundImage: `url(${src})`}}>
            <div className={classNames(classes.picture_artist, {[classes.right]: imgRight})}>
                Artwork done by:
                <img
                    className={classes.picture_artist__photo}
                    src={authorAvatar}
                    alt="artwork_artist"
                />
                <span className={classes.picture_artist__name}>{artAuthor}</span>
            </div>
        </div>
    )
}


function OnboardingWrapper(props) {

    return (
        <div className={classes.container}>
            {props.children}
        </div>
    );
}

OnboardingWrapper.Form = Form;
OnboardingWrapper.Picture = Picture;

export default OnboardingWrapper;