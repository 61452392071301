export const getActivePage = (pageNumber, noScroll) => {
    if (+pageNumber === 1) {
        window.location.hash = "";
        window.history.pushState({}, null, window.location.href.replace("#", ""));
    } else {
        const urlParams = new URLSearchParams();

        urlParams.set('page', pageNumber);

        if (noScroll) {
            window.history.pushState(null, null, window.location.pathname + "#" + urlParams.toString());
        } else {
            window.location.hash = `${urlParams.toString()}`;
        }
    }
};
