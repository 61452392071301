import React, {useRef} from 'react';
import TextInput from "../../Components/CustomComponents/TextInput";
import CustomDropdown from "../../Components/CustomComponents/Dropdown";
import greyChevron from '../../assets/img/personal_registration/chevron-input-down.svg'
import linkedinIcon from '../../assets/img/personal_registration/linkedin.svg'
import facebookIcon from '../../assets/img/personal_registration/registration_facebook.svg'
import instagramIcon from '../../assets/img/personal_registration/registration_instagram.svg'
import behanceIcon from '../../assets/img/personal_registration/behance.svg'
import TextBox from "../../Components/CustomComponents/TextBox";
import MainButton from "../../Components/CustomComponents/MainButton";
import clearPhoto from "../../assets/img/clear_gray.svg";


export default function MainInfo({registrationData, setRegistrationData, uploadCV, uploadIcon, cv, role, setCv}) {
    let ref = useRef();

    return (
        <div>
            <h2>Main Info</h2>
            <div className={'registration_main_info_container'}>
                <div>
                    <span>Name *</span>
                    <TextInput
                        type={'text'}
                        error={registrationData.name.error}
                        errTitle={'Please fill your Name'}
                        value={registrationData.name.value}
                        onChange={({target}) => {
                            setRegistrationData(prevState => ({
                                ...prevState,
                                name: {
                                    ...prevState.name,
                                    value: target.value,
                                    error: false
                                },
                            }))
                        }}
                    />
                </div>
                <div>
                    <span>Surname *</span>
                    <TextInput
                        type={'text'}
                        error={registrationData.surname.error}
                        errTitle={'Please fill your Surname'}
                        value={registrationData.surname.value}
                        onChange={({target}) => {
                            setRegistrationData(prevState => ({
                                ...prevState,
                                surname: {
                                    ...prevState.surname,
                                    value: target.value,
                                    error: false
                                },
                            }))
                        }}
                    />
                </div>
                <div>
                    <span>Country *</span>
                    <CustomDropdown value={registrationData.country.value}
                                    setInputValue={val => setRegistrationData(val)}
                                    errTitle={'Please select your Country'}
                                    optionLabel={'country'}
                                    error={registrationData.country.error}
                                    placeholder={'England'}
                                    search
                                    defaultDropdown
                                    onChange={({target}) => {
                                        setRegistrationData(prevState => ({
                                            ...prevState,
                                            country: {
                                                ...prevState.country,
                                                value: target.value,
                                                error: null,
                                                popup: true
                                            }
                                        }))
                                    }}
                                    dropdownPadding={'5px 16px'}
                                    dropdownIcon={greyChevron}
                                    popup={registrationData.country.popup}
                                    options={registrationData.country.options}
                    />
                </div>
            </div>
            {role === 'artist' ?
                <div className={'registration_main_info_container_social'}>
                    <span>Social Media Links</span>
                    <div className={'registration_main_info_container_social_block'}>
                        <TextInput
                            type={'text'}
                            style={{borderRadius: '100px 0 0 100px', padding: '8px 18px 8px 48px'}}
                            value={registrationData.linkedin_link.value}
                            placeholder={'https://'}
                            socialLinkIcon={linkedinIcon}
                            onChange={({target}) => {
                                setRegistrationData(prevState => ({
                                    ...prevState,
                                    linkedin_link: {
                                        value: target.value,
                                    },
                                }))
                            }}
                        />
                        <TextInput
                            type={'text'}
                            style={{borderRadius: '100px 0 0 100px', padding: '8px 18px 8px 48px'}}
                            value={registrationData.behance_link.value}
                            placeholder={'https://'}
                            socialLinkIcon={behanceIcon}
                            onChange={({target}) => {
                                setRegistrationData(prevState => ({
                                    ...prevState,
                                    behance_link: {
                                        value: target.value,
                                    },
                                }))
                            }}
                        />
                        <TextInput
                            type={'text'}
                            style={{borderRadius: '100px 0 0 100px', padding: '8px 18px 8px 48px'}}
                            value={registrationData.facebook_link.value}
                            placeholder={'https://'}
                            socialLinkIcon={facebookIcon}
                            onChange={({target}) => {
                                setRegistrationData(prevState => ({
                                    ...prevState,
                                    facebook_link: {
                                        value: target.value,
                                    },
                                }))
                            }}
                        />
                        <TextInput
                            type={'text'}
                            style={{borderRadius: '100px 0 0 100px', padding: '8px 18px 8px 48px'}}
                            value={registrationData.instagram_link.value}
                            placeholder={'https://'}
                            socialLinkIcon={instagramIcon}
                            onChange={({target}) => {
                                setRegistrationData(prevState => ({
                                    ...prevState,
                                    instagram_link: {
                                        value: target.value,
                                    },
                                }))
                            }}
                        />
                    </div>
                </div>
                : null}
            {role === 'artist' ?
                <div className={'registration_main_info_about'}>
                    <h2>About Yourself *</h2>
                    <TextBox rows={8}
                             value={registrationData.about_yourself.value}
                             error={registrationData.about_yourself.error}
                             errTitle={registrationData.about_yourself.errorMessage}
                             onChange={({target}) => {
                                 setRegistrationData(prevState => ({
                                     ...prevState,
                                     about_yourself: {
                                         value: target.value,
                                         error: null
                                     },
                                 }))
                             }}
                    />
                    <div className={'upload_cv_wrapper'}>
                        <input type="file"
                               accept="image/jpeg,application/pdf,image/png,.jpeg,.jpg,.pdf"
                               ref={input => ref = input}
                               onChange={e => uploadCV(e)}/>
                        <span>Upload CV</span>
                        <MainButton text={cv ? cv.name : 'Find on Computer'}
                                    svg={uploadIcon()}
                                    onClick={() => ref.click()}
                                    hoverDisable={true}
                                    className={cv ? 'button_bordered' : 'button_purple'}
                                    style={{width: 'max-content', padding: '10px 16px 10px 16px'}}/>
                        {cv ? <div className={'clear_cv'} onClick={() => setCv(null)}>
                            <img src={clearPhoto}
                                 className={'delete'}
                                 alt="clear cv"/>
                        </div> : null}
                    </div>
                </div>
                : null}
        </div>
    )
}