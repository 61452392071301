import clsx from "clsx";
import {Button} from "antd";

import styles from './StyledButton.module.css';

export default function StyledButton({color = 'black', variant, className, children, ...props}) {
    return (
        <Button
            {...props}
            className={clsx(styles.root, styles.defaultButton, styles[color], className, variant && styles[variant])}
        >
            {children}
        </Button>
    )
}