import React, {useEffect, useMemo, useState} from 'react';
import styles from './PasswordLock.module.css';
import Input from "../CustomComponents/Onboarding/Input";
import MainButton from "../CustomComponents/MainButton";
import {Banner} from "../Banner";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const PasswordLock = ({children}) => {
  const [password, setPassword] = useState("");
  const [hasAccess, setHasAccess] = useState(false);
  const [error, setError] = useState(false);
  let store = useSelector((store) => store.auth.user_data);
  const isOrganization = useMemo(() => store?.role === 'organization', [store?.role])

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === process.env.REACT_APP_BOOKING_PASSWORD) {
      setHasAccess(true);
      sessionStorage.setItem('artistBnBHasAccess', 'true')
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('artistBnBHasAccess')) {
      setHasAccess(true);
    }
  }, []);

  const onBannerButtonClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeZ2NHt-bTjHXlWVF3QRUHQO5_HlWqMBNAdCZhzbM7xXEsYXg/viewform?pli=1', "_blank", "noreferrer");
  }

  return hasAccess || isOrganization ? (
    <>{children}</>
  ) : (
    <>
      <Banner
        title={'ArtistBnB Waitlist - get on the list for an exclusive early access.'}
        buttonText={'Get Early Access'}
        onClick={onBannerButtonClick}
      />
      <div className={styles.root}>
        <form onSubmit={onSubmit} className={styles.form}>
          <span>Access page</span>

          <Input
            title="Please enter page access code"
            type="password"
            value={password}
            error={error}
            onChange={({target}) => setPassword(target.value.trim())}
          />

          <MainButton type={'submit'}
                      disabled={password.length === 0}
                      className={`button_black ${password.length === 0 ? 'button_black_disabled' : ''}`}
                      text={'Submit'}
          />
        </form>
      </div>
    </>
  )
};

export default PasswordLock;
