import {Helmet} from "react-helmet";
import meta_image from '../assets/img/metaimage.png'
import React, {useEffect, useState} from 'react'

export const SEO = (props) => {
    const [url, setUrl] = useState(window.location.href)

    const {
        title, description, image, fb_title, fb_description, children
        // brand, availability, price, item_id, group_id
    } = props

    useEffect(() => {
        setUrl(window.location.href)

        // eslint-disable-next-line
    },[window.location.href])

    console.log("CURRENT_ENVIRONMENT", process.env.REACT_APP_CURRENT_ENVIRONMENT)
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="og:title" property="og:title" content={fb_title ?? title}/>
            <meta name="twitter:title" property="twitter:title" content={title}/>
            <meta name="description" property="description" content={description}/>
            <meta name="og:description" property="og:description" content={fb_description ?? description}/>
            <meta name="twitter:description" property="twitter:description" content={description}/>
            <meta name="og:image" property="og:image" content={image ?? meta_image}/>
            <meta name="twitter:image" property="twitter:image" content={image ?? meta_image}/>
            <meta name="twitter:card" property="twitter:card" content="summary_large_image"/>
            {process.env.REACT_APP_CURRENT_ENVIRONMENT === "prod" && <meta name="robots" content="index,follow"/>}
            {url && <meta name="og:url" property="og:url" content={url}/>}
            {url && <link rel="canonical" href={url} />}
            {children}
            {process.env.REACT_APP_CURRENT_ENVIRONMENT === "dev" && <meta name="robots" content="noindex,nofollow"/>}
            {/*{item_id && [*/}
            {/*    <meta property="product:brand" key='1' content={brand}/>,*/}
            {/*    <meta property="product:availability" key='2' content={availability ? "in stock" : "sold"}/>,*/}
            {/*    <meta property="product:condition" key='3' content="new"/>,*/}
            {/*    <meta property="product:price:amount" key='4' content={price}/>,*/}
            {/*    <meta property="product:price:currency" key='5' content="EUR"/>,*/}
            {/*    <meta property="product:retailer_item_id" key='6' content={item_id}/>,*/}
            {/*    <meta property="product:item_group_id" key='7' content={group_id}/>*/}
            {/*]}*/}
        </Helmet>
    )
}

export default SEO
