import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigation} from "../../routers/navigation";
import {useHistory, useLocation} from "react-router";
import {setUserRegistrationData, setActiveHistory} from "../../main-store/auth/actions";
import historyDetectBack from "./HistoryBackDetect";
import {Helmet} from "react-helmet";

const ARTIST = "artist";
const COLLECTOR = "collector";
const ORGANIZATION = "organization";

function Registration() {
    const [loading, setLoading] = useState(null);
    const store = useSelector(state => state.auth)
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveHistory(location.pathname));
    }, [dispatch, location])
    //
    // useEffect(() => {
    //     console.log(store.active_history)
    // }, [store.active_history])


    useEffect(() => {
        window.onpopstate = () => {
            let {tempArray} = historyDetectBack(store.user_registration_data, store.active_history, true)
            dispatch(setUserRegistrationData(tempArray));
        }
    }, [dispatch, store.user_registration_data, store.active_history])

    useEffect(() => {
        setLoading(true)

    }, [])
    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])
    // useEffect(() => {
    //     console.log(store.user_registration_data)
    // }, [store.user_registration_data])

    useEffect(() => {
        return () => {
            dispatch(setUserRegistrationData(null));
        }
    }, [dispatch])

    if (!loading) {
        return null
    }

    let url = history.location.pathname.split('/').pop();

    if (store.user_registration_data?.type === ARTIST) {
        return Navigation.mainNavigationRegistrationArtist.filter(i => url === i.pathname)
            .map(route => {
                return <Fragment key={route.pathname}>
                    <Helmet>
                        <meta name="robots" content="noindex, follow" />
                    </Helmet>
                    {route.main}
                </Fragment>
            })
    } else if (store.user_registration_data?.type === ORGANIZATION) {
        return Navigation.mainNavigationRegistrationOrganization.filter(i => url === i.pathname)
          .map(route => {
              return <Fragment key={route.pathname}>
                  <Helmet>
                      <meta name="robots" content="noindex, follow" />
                  </Helmet>
                  {route.main}
              </Fragment>
          })
    } else {
        return Navigation.mainNavigationRegistrationCollector.filter(i => url === i.pathname)
            .map(route => {
                return <Fragment key={route.pathname}>
                    <Helmet>
                        <meta name="robots" content="noindex, follow" />
                    </Helmet>
                    {route.main}
                </Fragment>
            })
    }
}

export default Registration;
