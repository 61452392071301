import React from 'react';
import SEO from "../../../Components/SEO";

export default function HowDoesItWork() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="How does it Work?: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ How does it Work?: Buy art online Artsted"/>
            <h1>How does it Work?</h1>
            <div className={'products_services_terms'}>
                <p>Artsted is a technology company that is working towards the democratization of the art market using
                    cutting-edge technology and proprietary algorithms for transparent evaluations. Traditionally one of
                    the vaguest issues in the art market is price formation and determining the underlying value of a
                    given artwork. To date there has been universal standard or a clear and regulated structure related
                    to this matter, since price fixing is a major tool of manipulating profit margins as a habitual
                    business practice. The Artsted creators recognised an important shift in the attitude towards
                    information transparency as a top priority for Millennial and Generation Z collectors over the
                    recent years. The solution the platform offers is to use Big Data for valuations, estimations and
                    price predictions. Normally it is transactional data from publicly available auction sales. However
                    only an estimated 5% of overall transactions in the art market are going through publicly recorded
                    auctions, the rest is done in closed ecosystems or through private deals. The technology tools
                    employed by the Artsted platform, empower the creators to take control of their career progress, as
                    well as provide art collectors with unprecedented freedom to receive instant insights on the value
                    of their collection as part of a diversified portfolio.</p>
                <p>At Artsted we are looking at a solution to use non-transactional data for price formation and
                    updates, such as giving calculated estimates to non-monetary metrics that are directly correlated to
                    an artist's career performance.</p>
            </div>
        </div>
    )
}