import clsx from "clsx";
import React, { useState } from 'react';

import styles from './ShowMoreLess.module.css';

const ShowMoreLess = ({ children, maxHeight = 110, showMoreText = 'Show more', showLessText = 'Show less' }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleContent = () => setIsOpen(!isOpen);

    return (
        <div className={styles.wrapper}>
            <div className={clsx(styles.content, isOpen && styles.open)} style={{ maxHeight: isOpen ? '1000px' : maxHeight }}>
                {children}
            </div>
            <button className={styles.toggleButton} onClick={toggleContent}>
                {isOpen ? showLessText : showMoreText}
            </button>
        </div>
    );
};

export default ShowMoreLess;