import React from 'react';
import NewContactUs from "../../../Components/NewContactUs";
import SEO from "../../../Components/SEO";

export default function ContactUsFooter() {
    return (
        <div className={'answer_from_footer contact_form_footer'}>
            <SEO title="Contact us: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Contact us: Buy art online Artsted"/>
            <h1>Contact us</h1>
            <div className={'contact_us_info_footer'}>
                <NewContactUs/>
            </div>
        </div>
    )
}