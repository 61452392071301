import React from 'react';
import {Link} from "react-router-dom";
import {unitByCurrency} from "../../Constants/unitByCurrency";

const deleteIcon = (action) => <svg width="24" height="24" onClick={action} viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white"/>
    <path d="M7.19922 7.19922L16.7992 16.7992M7.19922 16.7992L16.7992 7.19922" stroke="black" stroke-width="2"/>
</svg>


const CartSidebarArtwork = ({idx, removeArtwork, currency, currencies, i}) => {
    const {title, year, category, size, order_id, price, id, picture} = i;

    console.log(currencies)
    console.log(currency)
    console.log(price)
    const currencySymbol = currency ? unitByCurrency[currency] : "€"
    const localizedPrice = currency ? (currencies[currency] * (price || 0)).toFixed(0) : (price || 0);
    return (
        <div key={idx} className="cart-artwork">
            <Link to={`/public-artwork/${id}`}><img src={i.pictures?.[0] ?? picture} alt={`Cart: ${title} artwork`}/></Link>
            <div className="artwork-content">
                {deleteIcon(() => removeArtwork(id))}
                <Link to={`/public-artwork/${id}`} className="title">{title}, {year}</Link>
                <span className="additional-info">{category} | {size}</span>
                <span className="additional-info">Order №{order_id}</span>
                <span className="price">Price: {currencySymbol || "€"}{localizedPrice}</span>
            </div>
        </div>
    );
};

export default CartSidebarArtwork;
