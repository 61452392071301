import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import './styles/article.css';
import FooterForm from "../Components/FooterForm";
import timeToRead from '../assets/img/article/time_to_read.svg'
import shareIcon from '../assets/img/article/share_symbol.svg'
import twitterIcon from '../assets/img/article/share_twitter.svg'
import telegramIcon from '../assets/img/article/share_telegram.svg'
import facebookIcon from '../assets/img/article/share_facebook.svg'
import useResize from "../Constants/useResize";
import API from '../api/API';
import Slider from "react-slick";
import Keys from '../Constants/helper';
import {
    // EmailShareButton,
    FacebookShareButton,
    // InstapaperShareButton,
    // LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    // ViberShareButton,
    // WhatsappShareButton,
} from "react-share";
import useBottomPopup from "../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../Components/CustomComponents/ChangedConfirmedPopup";
// import bigger_picture from '../assets/img/article/bigger_picture.png'
// import smaller_picture from '../assets/img/article/smaller_picture.png'
// import square_picture from '../assets/img/article/square_picture.png'
// import quote_picture from '../assets/img/article/quote_picture.png'
// import quoteIcon from '../assets/img/article/quote.svg'
// import DiscoverMoreCollector from "../Components/BlogComponents/DiscoverMoreCollector";
// import slide1 from "../assets/img/blog/slide1.png";
// import slide2 from "../assets/img/blog/slide2.png";

// const latestArticle = [
//     {
//         id: 1,
//         img: smaller_picture,
//         category: 'category',
//         title: 'Title of article',
//         time: '10',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         id: 2,
//         img: smaller_picture,
//         category: 'category',
//         title: 'Title of article',
//         time: '10',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         id: 3,
//         img: smaller_picture,
//         category: 'category',
//         title: 'Title of article',
//         time: '10',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         id: 4,
//         img: smaller_picture,
//         category: 'category',
//         title: 'Title of article',
//         time: '10',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
// ]

// const authorsArray = [
//     {
//         title: 'Title Header H4',
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         title: 'Title Header H4',
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         title: 'Title Header H4',
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
//     {
//         title: 'Title Header H4',
//         text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis',
//     },
// ]

// const sliderData = [
//     {
//         id: 1,
//         img: slide1,
//         category: 'paintings',
//     },
//     {
//         id: 2,
//         img: slide2,
//         category: 'sculptures',
//     },
//     {
//         id: 3,
//         img: slide1,
//         category: 'Photography',
//     },
//     {
//         id: 4,
//         img: slide2,
//         category: 'paintings',
//     },
//     {
//         id: 5,
//         img: slide1,
//         category: 'sculptures',
//     },
//     {
//         id: 6,
//         img: slide2,
//         category: 'Photography',
//     },
// ]

const latestArticlesSlider = {
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
}

export default function Article() {
    const {innerWidth} = useResize()
    const [activeArticleData, setActiveArticleData] = useState(null)
    const [activeLatestArticles, setActiveLatestArticles] = useState(null)
    const [loading, setLoading] = useState(false)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    let location = useLocation();
    let layoutIndex = 1;

    useEffect(() => {
        let articleUrl = location.pathname.split('/')[2];
        if (!isNaN(Number(articleUrl))) {
            API(Keys.JWT_TOKEN_ADMIN).get(`/admin/post?id=${articleUrl}`)
                .then((res) => {
                    if (res.data) {
                        // console.log(res.data)
                        setActiveArticleData(res.data)
                        setLoading(true)
                    }
                })
                .catch(err => {
                    if (err?.response?.data?.error === 'cant find post') {
                        window.location.href = '/blog'
                    }
                })
        } else {
            window.location.href = '/404-redirect'
        }
    }, [location.pathname])

    useEffect(() => {
        API().get(`/admin/post`)
            .then(res => {
                if (res.data) {
                    // console.log(res)
                    setActiveLatestArticles({
                        ...res.data,
                        loading: true
                    })
                }
            })
    }, [])

    const latestArticleLayout = () => (
        activeLatestArticles.posts
            .filter(el => el.id !== activeArticleData?.id)
            .map((item, index) => {
            return index < 4 && (
                <Link className={'card'} to={`/article/${item.id}`} key={item.id}>
                    <img className={'card_preview'} src={item.photo} alt="preview"/>
                    <div className={'category'}>
                        <span>{item.category}</span>
                        <div className={'time'}>
                            <img src={timeToRead} alt="time"/>
                            <span>{item.time ?? '4'} min</span>
                        </div>
                    </div>
                    <h4>{item.name.length > 40 ?
                        item.name.substring(0, 40) + '...' :
                        item.name}</h4>
                    <p>{item.description.length > 100 ?
                        item.description.substring(0, 100) + '...' :
                        item.description}</p>
                </Link>
            )
        })
    )

    const shareWrapper = () => (
        <div className={'share_container'}>
            <button className={'share'} onClick={() => {
                navigator?.clipboard?.writeText(window.location.href)
                    .then(() => {
                        changeContinuePopup('Copied to Clipboard')
                        setTimeout(() => changeContinuePopup('Copied to Clipboard'), 2000)
                    })
                    .catch(() => {
                        window.prompt("Copy to clipboard: Ctrl+C, Enter", window.location.href);
                    })
            }}>
                <img src={shareIcon} alt="share"/>
                <span>Share</span>
            </button>
            <div className={'social'}>
                <FacebookShareButton className={'facebook'}
                                     resetButtonStyle={false}
                                     url={window.location.href}>
                    <img src={facebookIcon} alt="facebook"/>
                </FacebookShareButton>
                <TelegramShareButton className={'telegram'}
                                     resetButtonStyle={false}
                                     url={window.location.href}>
                    <img src={telegramIcon} alt="telegram"/>
                </TelegramShareButton>
                <TwitterShareButton className={'twitter'}
                                    resetButtonStyle={false}
                                    url={window.location.href}>
                    <img src={twitterIcon} alt="twitter"/>
                </TwitterShareButton>
            </div>
        </div>
    )

    return [
        <ChangedConfirmedPopup key={0} successText={successText}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={'wrapper_article_page'}>
            <div className={'wrapper_preview_section'}
                 style={{
                     backgroundImage: `url(${activeArticleData?.photo})`,
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat no-repeat'
                 }}>
                <div className={'preview_text'}>
                    <span>{loading && activeArticleData.category}</span>
                    <h1>{loading && activeArticleData.name}</h1>
                    <div className={'inline'}>
                        <p>{loading && activeArticleData.author_name}</p>
                        <p>●</p>
                        <img src={timeToRead} alt="time"/>
                        {/*todo: hardcode*/}
                        <p>{(loading && activeArticleData.time) ?? '4 min'}</p>
                    </div>
                </div>
                {innerWidth > 568 ? shareWrapper() : null}
            </div>

            <div className={'content'}>
                {innerWidth <= 568 ? shareWrapper() : null}
                <div className={'table_of_content'}>
                    <p className={'bigger_text'}>{loading && activeArticleData.description}</p>
                    {/*<h6>Table of content</h6>*/}
                    {/*<div className={'navigation_links'}>*/}
                    {/*    <a href={'/article'}>Lorem ipsum dolor sit amet, consectetur adipiscing</a>*/}
                    {/*    <a href={'/article'}>Lorem ipsum dolor sit amet, consectetur adipiscing</a>*/}
                    {/*    <a href={'/article'}>Lorem ipsum dolor sit amet, consectetur</a>*/}
                    {/*</div>*/}
                </div>
                {loading ? activeArticleData.sections.length
                    ? <div className={'table_of_content_wrapper'}>{activeArticleData.sections.map((el) => {
                        if (!el.photo) {
                            return (
                                <div key={el.id} className={'table_of_content text_block'}>
                                    <div className={'text'}>
                                        <h2>{el.name}</h2>
                                        <p dangerouslySetInnerHTML={{__html: el.description}}
                                           className={'regular_text'}/>
                                    </div>
                                </div>
                            )
                        } else {
                            layoutIndex += 1
                            if (layoutIndex % 2 === 0) {
                                return (
                                    <div key={el.id} className={'image_left_block'}>
                                        <img src={el.photo} alt="preview"/>
                                        <div className={'text'}>
                                            <h2>{el.name}</h2>
                                            <p dangerouslySetInnerHTML={{__html: el.description}}
                                               className={'regular_text'}/>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={el.id} className={'image_right_block'}>
                                        <div className={'text'}>
                                            <h2>{el.name}</h2>
                                            <p dangerouslySetInnerHTML={{__html: el.description}}
                                               className={'regular_text'}/>
                                        </div>
                                        <img src={el.photo} alt="preview"/>
                                    </div>
                                )
                            }
                        }
                    })}</div>
                    : null : null}
                {/*<div className={'image_left_block'}>*/}
                {/*    <img src={bigger_picture} alt="preview"/>*/}
                {/*    <div className={'text'}>*/}
                {/*        <h2>Title H2 Lorem ipsum dolor sit onsectetur adipiscing elit</h2>*/}
                {/*        <p className={'regular_text'}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*            adipiscing elit, sed do*/}
                {/*            eiusmod tempor incididunt ut*/}
                {/*            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud*/}
                {/*            exercitation ullamco*/}
                {/*            laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor*/}
                {/*            in reprehenderit in*/}
                {/*            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur*/}
                {/*            sint occaecat</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className={'video_block'}>*/}
                {/*    <h2>Title H2 Lorem ipsum dolor sit amet onsectetur</h2>*/}
                {/*    <p className={'regular_text mb_40'}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*        adipiscing elit, sed do*/}
                {/*        eiusmod*/}
                {/*        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,*/}
                {/*        quis nostrud*/}
                {/*        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis*/}
                {/*        aute irure dolor in*/}
                {/*        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
                {/*        pariatur. Excepteur sint*/}
                {/*        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit*/}
                {/*        anim id est*/}
                {/*        laborum.</p>*/}
                {/*    <img className={'mb_40 video_image'} src={square_picture} alt="square_picture"/>*/}
                {/*    <p className={'regular_text'}>Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                {/*        elit, sed do*/}
                {/*        eiusmod tempor incididunt ut*/}
                {/*        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud*/}
                {/*        exercitation ullamco*/}
                {/*        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in*/}
                {/*        reprehenderit in*/}
                {/*        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint*/}
                {/*        occaecat cupidatat*/}
                {/*        non proident, sunt in culpa qui officia deserunt mollit anim id est*/}
                {/*        laborum.</p>*/}
                {/*</div>*/}

                {/*<div className={'image_right_section'}>*/}
                {/*    <div className={'image_right_block'}>*/}
                {/*        <div className={'text'}>*/}
                {/*            <h2>Lorem ipsum dolor sit amet onsectetur adipiscing elit</h2>*/}
                {/*            <p className={'regular_text'}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*                adipiscing elit, sed*/}
                {/*                do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut*/}
                {/*                enim ad minim veniam,*/}
                {/*                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea*/}
                {/*                commodo consequat. Duis*/}
                {/*                aute irure dolor in reprehenderit in voluptate velit esse cillum*/}
                {/*                dolore eu fugiat nulla*/}
                {/*                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in*/}
                {/*                culpa qui officia*/}
                {/*                deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet,*/}
                {/*                consectetur adipiscing*/}
                {/*                elit, sed do eiusmod tempor incididunt ut labore et dolore magna*/}
                {/*                aliqua. Ut enim ad*/}
                {/*                minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
                {/*                aliquip ex ea commodo*/}
                {/*                consequat. Duis aute irure dolor in reprehenderit in voluptate velit*/}
                {/*                esse cillum dolore*/}
                {/*                eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non*/}
                {/*                proident, sunt in culpa*/}
                {/*                qui officia deserunt mollit anim id est laborum.</p>*/}
                {/*        </div>*/}
                {/*        <img src={bigger_picture} alt="preview"/>*/}
                {/*    </div>*/}

                {/*    <p className={'regular_text'}>Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                {/*        elit, sed do*/}
                {/*        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
                {/*        veniam, quis*/}
                {/*        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo*/}
                {/*        consequat. Duis aute irure*/}
                {/*        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla*/}
                {/*        pariatur. Excepteur*/}
                {/*        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt*/}
                {/*        mollit anim id est*/}
                {/*        laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
                {/*        eiusmod tempor*/}
                {/*        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                {/*        nostrud exercitation*/}
                {/*        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                {/*        dolor in reprehenderit*/}
                {/*        in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur*/}
                {/*        sint occaecat*/}
                {/*        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id*/}
                {/*        est laborum.</p>*/}
                {/*</div>*/}

                {/*<div className={'list_section'}>*/}
                {/*    <h3>Title H3 Lorem ipsum dolor sit amet onsectetur</h3>*/}
                {/*    <ul className={'article_list'}>*/}
                {/*        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                {/*            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco*/}
                {/*            laboris nisi ut*/}
                {/*        </li>*/}
                {/*        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                {/*            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco*/}
                {/*            laboris nisi ut*/}
                {/*        </li>*/}
                {/*        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                {/*            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco*/}
                {/*            laboris nisi ut*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}

                {/*<div className={'quote_section'}>*/}
                {/*    <img className={'quote_icon'} src={quoteIcon} alt="quote"/>*/}
                {/*    <p className={'quote_text'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
                {/*        tempor incididunt ut*/}
                {/*        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco*/}
                {/*        laboris nisi ut aliquip ex ea commodo consequat.</p>*/}
                {/*    <div className={'quote_author'}>*/}
                {/*        <div>*/}
                {/*            <h5>— Rebecca Wilson</h5>*/}
                {/*            <span>Chief Curator</span>*/}
                {/*        </div>*/}
                {/*        <img className={'avatar'} src={quote_picture} alt="quote_picture"/>*/}
                {/*    </div>*/}
                {/*    <p className={'regular_text quote_text_padding'}>Lorem ipsum dolor sit amet, consectetur adipiscing*/}
                {/*        elit, sed do*/}
                {/*        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis*/}
                {/*        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure*/}
                {/*        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur*/}
                {/*        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est*/}
                {/*        laborum.</p>*/}
                {/*</div>*/}

                {/*<div className={'authors_section'}>*/}
                {/*    {authorsArray.map((el, i) => (*/}
                {/*        <div className={'card'} key={i}>*/}
                {/*            <div/>*/}
                {/*            <h4>{el.title}</h4>*/}
                {/*            <p className={'regular_text'}>{el.text}</p>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}

            </div>

            {/*<div className={'discover_section'}>*/
            }
            {/*    <DiscoverMoreCollector discoverButton sliderData={sliderData} subtitle={'category'}*/
            }
            {/*                           innerWidth={innerWidth}/>*/
            }
            {/*</div>*/
            }

            <div className={'latest_articles_section'}>
                <h2>Latest Articles</h2>
                <div className={'wrapper'}>
                    {activeLatestArticles?.loading
                        ? activeLatestArticles.posts.length
                            ? innerWidth > 568 ?
                                latestArticleLayout()
                                : <Slider {...latestArticlesSlider}>
                                    {latestArticleLayout()}
                                </Slider>
                            : <span className={'no_items_style'}>No items</span> : null}
                </div>
            </div>

            <FooterForm/>
        </div>
    ]
}
