import React, {useCallback, useEffect, useRef, useState} from "react";
import '../Profile/styles/new_main_profile.css';
import API from "../../api/API";
import {Loader} from "../../Components/Loader";
import Slider from "react-slick";
import ArtistInfoCard from "../../Components/ArtistInfoCard";
import sliderArrowLeft from "../../assets/img/new_landing/slider_left_arrow.svg";
import sliderArrowRight from "../../assets/img/new_landing/slider_right_arrow.svg";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import ArtworkInfoCard from "../../Components/ArtworkInfoCard";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {handleFavoriteData} from "../../Constants/handleFavoriteData";

const ArtworksArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_left'}>
        <img src={sliderArrowLeft} alt="arrow left"/>
    </div>
);
const ArtworksArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_right'}>
        <img src={sliderArrowRight} alt="arrow right"/>
    </div>
);

export default function NewMainProfile() {
    let artistsRef = useRef();
    const [investorProfile, setInvestorProfile] = useState({
        liked_artworks: null,
        artists: null
    });
    const [loading, setLoading] = useState(false);
    const [swiped, setSwiped] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    const artistsSettings = {
        infinite: investorProfile?.artists?.length > 4,
        speed: 500,
        arrows: true,
        dots: false,
        swipeToSlide: true,
        useTransform: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    infinite: investorProfile?.artists?.length > 3,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    infinite: investorProfile?.artists?.length > 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    infinite: investorProfile?.artists?.length > 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    infinite: investorProfile?.artists?.length > 1,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    centerMode: investorProfile?.artists?.length > 2,
                    centerPadding: investorProfile?.artists?.length > 2 ? '45px' : '0',
                    infinite: investorProfile?.artists?.length > 2,
                }
            },
        ],
        prevArrow: <ArtworksArrowLeft/>,
        nextArrow: <ArtworksArrowRight/>,
    }
    const sliderResize = () => {
        artistsRef.current?.slickGoTo(0)
    }

    useEffect(() => {
        window.addEventListener('load', sliderResize)
        window.addEventListener('resize', sliderResize)

        return () => {
            window.removeEventListener('load', sliderResize)
            window.removeEventListener('resize', sliderResize)
        }
    }, [])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        }
    }, [swiped])

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    const handleDeleteFavorite = (e, index, id, activeData, setActiveData, nestedArray) => {
        handleFavoriteData({
            e: e,
            index: index,
            id: id,
            activeData: activeData,
            setActiveData: setActiveData,
            nestedArray: nestedArray,
            setFiltered: true
        })
        bodyOverflow(false);
        setActiveModal(null);
    }

    const handleDeleteFollowed = () => {
        API().post(`/user/follow?id=${activeModal.value}`)
            .then(res => {
                if (res.status === 200) {
                    setInvestorProfile(prevState => ({
                        ...prevState,
                        artists: prevState.artists.filter(el => el.id !== activeModal.value)
                    }))
                    changeContinuePopup('Artist unfollowed')
                    setTimeout(() => {
                        changeContinuePopup('Artist unfollowed')
                    }, 2000)
                }
            })
            .catch(err => {
                console.log(err)
            })
        bodyOverflow(false);
        setActiveModal(null);
    }

    useEffect(() => {
        Promise.all([
            API().get(`/user/profile/follows`),
            API().get(`/user/like_artwork`),
        ])
            .then(res => {
                if (res[0].data && res[1].data) {
                    // console.log(res);
                    setInvestorProfile({
                        liked_artworks: res[1].data.liked_artworks,
                        artists: res[0].data.artists
                    })
                    setLoading(true)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return [
        <ChangedConfirmedPopup key={0}
                               successText={successText}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} style={!loading ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : null}
             className={'investor_main_profile'}>
            {loading
                ? [
                    <section key={0} className={'home_artists_wrapper followed_artists_wrapper'}>
                        <h3>Followed artists</h3>
                        <div className={'artists_slider_container slider_container'}>
                            <Slider {...artistsSettings} onSwipe={handleSwiped} ref={artistsRef}>
                                {investorProfile.artists.length
                                    ? investorProfile.artists.map((el, i) => (
                                        <div key={i} onClickCapture={(e) => handleOnItemClick(e)}>
                                            <ArtistInfoCard {...el}
                                                            changeContinuePopup={changeContinuePopup}
                                                            activeData={investorProfile}
                                                            index={i}
                                                            profileFollowArtist
                                                            setActiveModal={e => setActiveModal(e)}
                                                            setActiveData={val => setInvestorProfile(val)}/>
                                        </div>
                                    )) : <span>No followed artists yet</span>}
                            </Slider>
                        </div>
                    </section>,
                    <section key={1} className={'liked_artworks_wrapper'}>
                        <h3>Artworks you saved</h3>
                        <div className={'masonry_layout'}>
                            {investorProfile.liked_artworks.length ? investorProfile.liked_artworks.map((el, index) => (
                                <div key={index}>
                                    <ArtworkInfoCard {...el}
                                                     profileFavorites
                                                     activeData={investorProfile}
                                                     setActiveModal={e => setActiveModal(e)}
                                                     setActiveData={val => setInvestorProfile(val)}
                                                     nestedArray={'liked_artworks'}
                                                     index={index}/>
                                </div>
                            )) : <span>No liked artworks</span>}
                        </div>
                    </section>,
                    activeModal?.value ?
                        <div key={2} className={'new_modal_wrapper'}>
                            <ModalWindow nestedObj={'value'}
                                         setActiveModal={val => setActiveModal(val)}>
                                <h4>{activeModal.role === 'artwork' ? 'Delete from favorites?' : 'Delete from followed?'}</h4>
                                <p>Are you sure you want to delete? This action can't be undone.</p>
                                <div className={'modal-window_buttons'}>
                                    <MainButton type={'button'}
                                                style={{background: '#000', color: '#fff'}}
                                                className={'button_black'}
                                                text={'Cancel'}
                                                onClick={() => {
                                                    setActiveModal(null)
                                                    bodyOverflow(false)
                                                }}
                                    />
                                    <MainButton type={'button'}
                                                className={'button_black'}
                                                text={'Delete'}
                                                onClick={(event) => {
                                                    if (activeModal.role === 'artwork') {
                                                        handleDeleteFavorite(event, activeModal.index, activeModal.value, investorProfile, setInvestorProfile, 'liked_artworks')
                                                    } else {
                                                        handleDeleteFollowed()
                                                    }
                                                }}
                                    />
                                </div>
                            </ModalWindow>
                        </div> : null,
                ]
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
        </div>
    ]
}
