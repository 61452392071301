import React from 'react';
import Slider from "react-slick";
import "./styles/testimonials.css"
import useResize from "../../../Constants/useResize";
import learnMore from "../../../assets/info/learn_more.svg"
import avatarOne from "../../../assets/info/artist_pics1.png"
import avatarTwo from "../../../assets/info/artist_pics2.png"
import galleryPics from "../../../assets/info/artist_gallery_pics.png"
import galleryPicsOne from "../../../assets/info/artist_gallery_pics1.png"
import galleryPicsTwo from "../../../assets/info/artist_gallery_pics2.png"
import galleryPicsThree from "../../../assets/info/artist_gallery_pics3.png"
import galleryPicsFour from "../../../assets/info/artist_gallery_pics4.png"
import galleryPicsFive from "../../../assets/info/artist_gallery_pics5.png"
import nftGalleryPics from "../../../assets/info/nft_gallery_pics.png"
import nftGalleryPicsOne from "../../../assets/info/nft_gallery_pics1.png"
import nftGalleryPicsTwo from "../../../assets/info/nft_gallery_pics2.png"
import nftGalleryPicsThree from "../../../assets/info/nft_gallery_pics3.png"
import userPhotoOne from "../../../assets/info/user_photo1.png"
import userPhotoTwo from "../../../assets/info/user_photo2.png"
import userPhotoThree from "../../../assets/info/user_photo3.png"
import userPhotoFour from "../../../assets/info/user_photo4.png"
import userPhotoFive from "../../../assets/info/user_photo5.png"
import userPhotoSix from "../../../assets/info/user_photo6.png"
import userPhotoSeven from "../../../assets/info/user_photo7.png"
import userPhotoEight from "../../../assets/info/user_photo8.png"
import userArtPicOne from "../../../assets/info/user_art_pics1.png"
import userArtPicTwo from "../../../assets/info/user_art_pics2.png"
import userArtPicThree from "../../../assets/info/user_art_pics3.png"
import userArtPicFour from "../../../assets/info/user_art_pics4.png"
import userArtPicFive from "../../../assets/info/user_art_pics5.png"
import userArtPicSix from "../../../assets/info/user_art_pics6.png"
import userArtPicSeven from "../../../assets/info/user_art_pics7.png"
import userArtPicEight from "../../../assets/info/user_art_pics8.png"
import userArtPicNine from "../../../assets/info/user_art_pics9.png"
import userArtPicTen from "../../../assets/info/user_art_pics10.png"
import userArtPicEleven from "../../../assets/info/user_art_pics11.png"
import userArtPicTwelve from "../../../assets/info/user_art_pics12.png"
import userArtPicThirteen from "../../../assets/info/user_art_pics13.png"
import userArtPicFourteen from "../../../assets/info/user_art_pics14.png"
import userArtPicFifteen from "../../../assets/info/user_art_pics15.png"
import userArtPicSixteen from "../../../assets/info/user_art_pics16.png"
import userArtPicSeventeen from "../../../assets/info/user_art_pics17.png"
import userArtPicEighteen from "../../../assets/info/user_art_pics18.png"
import userArtPicNineteen from "../../../assets/info/user_art_pics19.png"
import userArtPicTwenty from "../../../assets/info/user_art_pics20.png"
import userArtPicTwentyOne from "../../../assets/info/user_art_pics21.png"
import userArtPicTwentyTwo from "../../../assets/info/user_art_pics22.png"
import userArtPicTwentyThree from "../../../assets/info/user_art_pics23.png"
import userArtPicTwentyFour from "../../../assets/info/user_art_pics24.png"
import sliderArrowLeft from "../../../assets/info/arrow_left.svg"
import sliderArrowRight from "../../../assets/info/arrow_right.svg";
import SEO from "../../../Components/SEO";


const OurArtistsTestimonials = [{
    from: "from our artists",
    artist: "e. cecconi-meloni",
    country: "italy",
    avatar: avatarOne,
    article: "'I like the elegance of Artsted’s website and this is an aspect that is worth considering when choosing a place to sell art. Platforms like Artsted are going to shape the future of the art world. Collectors and people passionate about art should support the living artist because, in order to make art, support and resources are essential'",
    thumbImg: [galleryPics, galleryPicsOne, galleryPicsTwo],
    url: "https://artsted.com/artist_profile/242"
}]

const OurArtistsTestimonialsTwo = [{
    from: "from our artists",
    artist: "vitalina fedorova",
    country: "ukraine",
    avatar: avatarTwo,
    article: "“I like this platform, specifically for young artists that are not represented by galleries or big collectors. The Artsted interface is elegant and easy to approach. Everything works even if you need to contact the Artist Liaison department which will help you quickly with every issue concerning your profile management. It is crucial for young artists to be supported, otherwise, the art market will lose a very good opportunity“.",
    thumbImg: [galleryPicsThree, galleryPicsFour, galleryPicsFive],
    url: `https://artsted.com/artist_profile/301`
}]

const nftArtistsTestimonialsArts = [
    {
        thumb: nftGalleryPics,
        article: "According to the latest surveys, 91% of the collectors who started collecting in the last four years have bought art online in 2021. Artsted is on the cutting edge of this movement, providing unprecedented transparency and market evaluation tools in the primary market to anyone who decides to start a personal or corporate art collection."
    },
    {
        thumb: nftGalleryPicsOne,
        article: "Very excited for another IRL event coming up in Switzerland! Come meet us at @start_global in St. Gallen on 25-25 March - where we will be showcasing a curated NFTs collection and more! You can still grab a last moment ticket on their website.",
        url: "https://www.instagram.com/p/Ca92DXFo4AU/"
    },

    {
        thumb: nftGalleryPicsTwo,
        article: "Amazing time at @start_global in St. Gallen with @marinarybackova and @angelica_guaresi - 2 days of networking, connecting and showcasing all of our amazing creators on the platform.",
        url: "https://www.instagram.com/p/Cbkd319Iz2H/"
    },

    {
        thumb: nftGalleryPicsThree,
        article: "Last friday April 1st, 2022, was an exclusive phygital event, “WTF is an NFT”, the first collaboration between Artsted.com and @condominioxyz , gathering artists, curators, and experts to discuss the new Web 3.0 and crypto art technologies.",
        url: "https://www.instagram.com/p/CcDAW5Xsm36/"
    }
]

const userArtDescription = [
    {
        user: "stuart mel wilson",
        country: "great britain",
        avatar: userPhotoOne,
        article: "I let my art do the talking for itself a lot I guess, it might also be why some might see my works as literal because it is clear, but please don’t take that as it being unlearned, as I talk about a lot of different thing threw my work. I just want it to be understood on a lot of levels and for it to be clear.",
        thumbImg: [userArtPicOne, userArtPicTwo, userArtPicThree],
        portfolio: "stuart's portfolio",
        url: "https://artsted.com/artist_profile/312",
    },
    {
        user: "anna bochkova",
        country: "germany",
        avatar: userPhotoTwo,
        article: "“My visual vocabulary does not necessarily come from watching art; it takes a lot from architecture, design, theatre and nature. I believe in a concept of a certain eternity: everything is connected and flows into one another.”",
        thumbImg: [userArtPicFour, userArtPicFive, userArtPicSix],
        portfolio: "Anna's portfolio",
        style: "4.5rem",
        url: "https://artsted.com/artist_profile/318"
    },

    {
        user: "federico  polloni",
        country: "italy",
        avatar: userPhotoThree,
        article: "“In the last year I became more and more involved in the crypto-art and NFT realm. Therefore, I am working on a series of works with digital painting and animations. It fascinates me to expand this new background experimenting with new languages and researches.”",
        thumbImg: [userArtPicSeven, userArtPicEight, userArtPicNine],
        portfolio: "federico's portfolio",
        url: "https://artsted.com/artist_profile/324"

    },

    {
        user: "giada pignotti",
        country: "italy",
        avatar: userPhotoFour,
        article: "“I’ve always found it very stimulating to study artists which don’t specifically work with the same media as I do, but with the ones I feel instinctively akin to: the evanescence in the photography of Francesca Woodman, the cloudy border between experience life, and performance in Sophie Calle...”",
        thumbImg: [userArtPicTen, userArtPicEleven, userArtPicTwelve],
        portfolio: "giada's portfolio",
        url: "https://artsted.com/artist_profile/308"
    },

    {
        user: "c. simoncini",
        country: "italy",
        avatar: userPhotoFive,
        article: "“In general, I find that looking around with curiosity and perseverance is extremely important. Drawing from every period and form of creative expression as much as one can, and then re-elaborate in our way, even (and probably above all) from the biggest artists of the past...”",
        thumbImg: [userArtPicThirteen, userArtPicFourteen, userArtPicFifteen],
        portfolio: "camilla's portfolio",
        url: "https://artsted.com/artist_profile/395"

    },

    {
        user: "sam king",
        country: "great britain",
        avatar: userPhotoSix,
        article: "“My work elicits contradictory sensory and emotional responses to reveal the contingent nature of incarnation and the subversive possibilities of representation. By synthesizing alternative interpretations of the body, my practice aims to transgress the experiential boundaries imposed by contemporary culture.”",
        thumbImg: [userArtPicSixteen, userArtPicSeventeen, userArtPicEighteen],
        portfolio: "sam's portfolio",
        url: "https://artsted.com/artist_profile/387"

    },

    {
        user: "damiano fasso",
        country: "italy",
        avatar: userPhotoSeven,
        article: "“Social media are becoming evermore important within this industry, in fact the majority of works and collaborations come from them. I mainly use IG and Facebook to post new works, Press Releases and new announcements.“",
        thumbImg: [userArtPicNineteen, userArtPicTwenty, userArtPicTwentyOne],
        portfolio: "damiano's portfolio",
        url: "https://artsted.com/artist_profile/20"

    },

    {
        user: "alan abrahams",
        country: "france",
        avatar: userPhotoEight,
        article: "“As a 3D artist I am scanning environments in our physical world and then transforming them in the virtual world, distorting their original purpose. And then using them as a kind of set in my 3D scene, contributing towards the narrative.”",
        thumbImg: [userArtPicTwentyTwo, userArtPicTwentyThree, userArtPicTwentyFour],
        portfolio: "alans's portfolio",
        url: "https://artsted.com/artist_profile/1583"

    },

]

const ArtworksArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_left'}>
        <img src={sliderArrowLeft} alt="arrow left"/>
    </div>
);
const ArtworksArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_right'}>
        <img src={sliderArrowRight} alt="arrow right"/>
    </div>
);

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '45px',
            }

        },


    ],
    prevArrow: <ArtworksArrowLeft/>,
    nextArrow: <ArtworksArrowRight/>,

};

const artSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    // centerPadding: '50px',

};


export default function Testimonials() {
    const {innerWidth} = useResize();

    return (
        <div>
            <SEO title="Testimonials: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Testimonials: Buy art online Artsted"/>
            <section className={"testimonials"}>
                <div className={"testimonials_message_wrapper"}>
                    <div>
                        <h1 className={"testimonials_title"}>Testimonials</h1>
                        <p className={"testimonials_message"}>Don't take our word for its see what the Artsted users
                            have to share about their experience of navigating the
                            new paradigm in the online art market.
                        </p>
                    </div>
                    <div className={"testimonials_feed_back"}>
                        < p className={"testimonials_text"}>Are you an active member of our community?
                            Please tell us about tour experience with Artsted!
                            Send us photos of the artworks finding a new life
                            in your home or work space at <span className={"info_web"}> <a
                                href={"mailto:info@artsted.com"}> info@artsted.com</a> </span>
                        </p>
                    </div>
                </div>
            </section>
            <section className={"artist_testimonials_section"}>
                <div className={"testimonials_grid"}>
                    {OurArtistsTestimonials.map((artist, index) => (
                        <div key={index} className={"testimonials_grid_wrapper"}>
                            <div className={"avatar_with_text"}>
                                <div className={"artist_info_display"}>
                                    <img className={"avatar_user_img"} src={artist.avatar} alt={""}/>
                                    <div className={"artist_info_wrapper"}>
                                        <span className={"from_our_artists"}>{artist.from}</span>
                                        <span className={"artist_name"}>{artist.artist}</span>
                                        <span className={"artist_country"}>{artist.country}</span>
                                    </div>
                                </div>
                            </div>

                            <article>
                                <p className={"testimonials_review_one"}>{artist.article}</p>
                            </article>
                            <div className={"testimonials_photo"}>
                                {artist.thumbImg.map((img, index) => (
                                    <img key={index} className={"user_photos"} src={img} alt={""}/>))}
                            </div>
                            <button className={"btn_testimonials"}>
                                <a rel="noreferrer" href={artist.url}>view artworks</a>
                            </button>
                        </div>
                    ))}
                    {innerWidth <= 414 ?
                        <div className={"border_art_display"}></div>
                        : null}
                    {OurArtistsTestimonialsTwo.map((artist, index) => (
                        <div key={index} className={"testimonials_grid_wrapper"}>
                            <div className={"avatar_with_text"}>
                                <div className={"artist_info_display"}>
                                    <img className={"avatar_user_img"} src={artist.avatar} alt={""}/>
                                    <div className={"artist_info_wrapper"}>
                                        <span className={"from_our_artists"}>{artist.from}</span>
                                        <span className={"artist_name"}>{artist.artist}</span>
                                        <span className={"artist_country"}>{artist.country}</span>
                                    </div>
                                </div>
                            </div>

                            <article>
                                <p className={"testimonials_review"}>{artist.article}</p>
                            </article>

                            <div className={"testimonials_photo"}>
                                {artist.thumbImg.map((img, index) => (
                                    <img className={"user_photos"} key={index} src={img} alt={""}/>))}
                            </div>

                            <button className={"btn_testimonials"}>
                                <a rel="noreferrer" href={artist.url}>view artworks</a>
                            </button>

                        </div>
                    ))}

                </div>
            </section>

            <section>
                <div className={"testimonials_details"}>
                    <p className={"text_details"}>
                        {/*“The mean can have every limit of the case, the important thing is to create*/}
                        {/*and transfer something meaningful“*/}
                        "Having good lighting is essential for me. Working outside, for example, is a huge part in my
                        creative process." - Aimee Ruoff
                    </p>
                    <h4 className={"title_details"}> cartier-bresson</h4>
                </div>
                {innerWidth >= 415 ?
                    <div className={"art_showcase"}>
                        {
                            nftArtistsTestimonialsArts.map((arts, index) => (
                                <div key={index}>
                                    <img className={"art_image_show"} src={arts.thumb} alt={""}/>
                                    <div className={"border_bottom_art_two"}></div>
                                    <article>
                                        <p className={"art_article"}> {arts.article}</p>
                                    </article>
                                    <a rel="noreferrer" href={arts.url}><img className={"art_learn_more"}
                                                                             src={learnMore} alt={""}/></a>
                                </div>
                            ))
                        }

                    </div>
                    :
                    <div className={"art_showcase"}>
                        <Slider {...artSettings}>
                            {
                                nftArtistsTestimonialsArts.map((arts, index) => (
                                    <div key={index}>
                                        <img className={"art_image_show"} src={arts.thumb} alt={""}/>
                                        <div className={"border_bottom_art_two"}></div>
                                        <article>
                                            <p className={"art_article"}> {arts.article}</p>
                                        </article>
                                        <a rel="noreferrer" href={arts.url}>
                                            <img className={"art_learn_more"} src={learnMore} alt={""}/></a>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                }
            </section>

            <section className={"user_art_title"}>
                <span className={"user_art_text"}>
                    “It is essential for me to have moments where we exchange ideas with other artists;
                      this is why I needed to share my Studio with others.”
                </span>
                <h4 className={"user_name_title"}> Federico Polloni</h4>
            </section>
            <section className={"testimonial_display"}>
                <div className={"testimonial_info_wrapper"}>
                    <Slider {...settings}>
                        {
                            userArtDescription.map((user, index) => (
                                <div key={index} className={"user_testimonial_ref"}>
                                    <div className={"avatar_user_profile"}>
                                        <img src={user.avatar} alt={""}/>
                                        <div className={"user_name_country"}>
                                            <span className={"nft_user_name"}>{user.user}</span>
                                            <span className={"nft_user_country"}>{user.country}</span>
                                        </div>
                                    </div>

                                    {innerWidth >= 415 ?
                                        <div>
                                            <div className={"thumb_img_display"}>
                                                {
                                                    user.thumbImg.map((m, index) => (
                                                        <img key={index} src={m} alt={""}/>
                                                    ))
                                                }
                                            </div>
                                            <div className={"border_bottom"}>
                                            </div>
                                            <article>
                                                <p className={"user_profile_description"}> {user.article}</p>
                                            </article>
                                        </div>

                                        :
                                        <div>
                                            <div className={"border_bottom_mob"}>
                                            </div>
                                            <article>
                                                <p className={"user_profile_description"}> {user.article}</p>
                                            </article>
                                            <div className={"thumb_img_display"}>
                                                {
                                                    user.thumbImg.map((m, index) => (
                                                        <img key={index} src={m} alt={""}/>
                                                    ))
                                                }
                                            </div>

                                        </div>

                                    }

                                    <button className={"btn_user"}>
                                        <a rel="noreferrer" href={user.url}>view {user.portfolio}</a>
                                    </button>


                                </div>

                            ))
                        }
                    </Slider>
                </div>
            </section>
        </div>
    )
}