import React, {useEffect, useState} from "react";
import {useParams} from "react-router";

import API from "../../api/API";

import ListingForm from "../../Components/profile/ListingForm";
import StyledTabs from "../../Components/ui/StyledTabs";
import ListingBooking from "../../Components/profile/ListingBooking";
import ListingCalendar from "../../Components/ListingCalendar";

import styles from './ListingView.module.css'

const items = [
    {key: 'settings', label: 'Settings'},
    {key: 'bookings', label: 'Bookings'},
    {key: 'calendar', label: 'Calendar'},
];

export default function ListingView() {
    const {id} = useParams();
    const [data, setData] = useState();
    const [tab, setTab] = useState('settings');

    useEffect(() => {
        API().get('/user/listing/stay', {params: {id}})
            .then((res) => setData(res.data))
    }, [])

    return (
        <main>
            <section className={styles.hero}>
                <h1 className={styles.title}>Your listing {data?.name}</h1>
                <StyledTabs centered defaultActiveKey={tab} items={items} onChange={setTab}/>
            </section>
            {data && (
                <div className={styles.inner}>
                    {tab === 'settings' && <ListingForm id={id} data={data}/>}
                    {tab === 'bookings' && <ListingBooking/>}
                    {tab === 'calendar' && <ListingCalendar/>}
                </div>
            )}
        </main>
    )
}