import React from 'react';
import SEO from "../../Components/SEO";


export default function HowWork() {
    return (
        <div className={'faq_section_body work_padding'}>
            <SEO title="How does it work?: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ How does it work?: Buy art online Artsted"/>
            <h1>How does it work?</h1>
            <p>At Artsted we are looking at a solution to use non-transactional data for price formation and updates,
                such as giving calculated estimates to non-monetary metrics that are directly correlated to an artist's
                career performance.</p>
        </div>
    )
}