import React from 'react';
import './styles.css';

function CustomCheckbox({text, handleChange, value, name, error, link, authenticity, noMargin, children}) {
    return (
        <div className={`wrapper_check_box ${authenticity ? 'wrapper_check_box_authenticity' : ''} ${value ? "active" : ''}`}>
            <label htmlFor={name}
                   onClick={handleChange}
                   className={value ? 'checkbox_active' : 'checkbox_un_active'}>
                <span className={error ? 'check_error' : ''}/>
                <p className={error ? 'text_error' : null}
                style={noMargin ? undefined : {marginTop: 12}}>
                    {text}
                    {link ?
                        <a href={link.link} target="_blank" rel="noopener noreferrer nofollow">{link.value}</a>
                        : null}
                </p>
            </label>
            {children}
        </div>
    );
}

export default CustomCheckbox;
