import React, {useState, useEffect} from 'react';
import Wrapper from '../OnboardingWrapper';
import artwork_img from '../../../assets/img/onboarding/artwork1.png';
import classes from './styles/profile.module.scss';
import Input from '../../../Components/CustomComponents/Onboarding/Input';
import Dropdown from '../../../Components/CustomComponents/Onboarding/Dropdown';
import Buttom from '../../../Components/CustomComponents/Onboarding/Button';
import in_ico from '../../../assets/img/onboarding/social/in.svg';
import fb_ico from '../../../assets/img/onboarding/social/fb_black.svg';
import inst_ico from '../../../assets/img/onboarding/social/inst.svg';
import upload_ico from '../../../assets/img/onboarding/upload_black.svg';
import API from '../../../api/API';
import Keys from '../../../Constants/helper';
import Error from '../../../Components/CustomComponents/Onboarding/Error';
import useBottomPopup from "../../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import {useDispatch, useSelector} from "react-redux";
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import historyDetectBack from "../HistoryBackDetect";
import {useHistory} from "react-router";

function Profile() {
    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [fbLink, setFbLink] = useState('');
    const [inLink, setInLink] = useState('');
    const [instLink, setInstLink] = useState('');
    const [pdf, setPDF] = useState(null);
    const [errors, setErrors] = useState({});
    const [showErrors, setVisibilityErrors] = useState(false);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const store = useSelector(state => state.auth)
    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        if (showErrors) {
            _isFormValid();
        }
        // eslint-disable-next-line
    }, [country]);


    useEffect(() => {
        Promise.all([
            API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/profile/upload_cv`),
            API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/countries`)
        ])
            .then(response => {
                setCountry(store.user_registration_data.country || null)
                setFbLink(store.user_registration_data.facebook_link || null)
                setInLink(store.user_registration_data.linkedin_link || null)
                setInstLink(store.user_registration_data.instagram_link || null)
                setCountries(response[1].data.countries)
                setPDF(response[0].data.cv_path);
            })
            .catch(e => console.error(e));
        // eslint-disable-next-line
    }, []);

    const _isFormValid = () => {
        const errors = {};

        if (!country) {
            errors.country = "Choose country from list";
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        return true;
    }

    const _onSubmit = e => {
        e.preventDefault();
        if (!_isFormValid()) {
            setVisibilityErrors(true);
            return;
        }
        dispatch(setUserRegistrationData({
            ...store.user_registration_data,
            country,
            facebook_link: fbLink,
            linkedin_link: inLink,
            instagram_link: instLink,
        }))
        let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
        history.push(nextHistory)
    }

    const _uploadPdf = e => {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData()
            formData.append("cv", e.target.files[0])
            API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/upload_cv`, formData)
                .then((res) => {
                    setPDF(e.target.files[0]);
                    changeContinuePopup('You successfully uploaded cv')
                    setTimeout(() => {
                        changeContinuePopup('You successfully uploaded cv')
                    }, 2000)
                })
        }
    }

    return (
        <Wrapper>
            <Wrapper.Picture src={artwork_img}/>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>Submit your Artist profile</h1>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <p className={classes.form__label}>Choose a Country</p>
                        <Dropdown
                            options={countries}
                            value={country || ''}
                            onChange={country => setCountry(country)}
                            placeholder='Country'
                            error={errors.country}
                        />
                        {errors.country && <Error text={errors.country} className={classes.error_component}/>}
                        <p className={classes.form__label}>Upload Portfolio (Links / PDF)</p>
                        <div className={classes.wrapper_inputs}>
                            <Input
                                className={classes.form__input_link}
                                variant="icon"
                                placeholder={"Paste link here"}
                                icon={in_ico}
                                value={inLink || ''}
                                onChange={({target}) => setInLink(target.value)}
                            />
                            <Input
                                className={classes.form__input_link}
                                variant="icon"
                                icon={fb_ico}
                                placeholder={"Paste link here"}
                                value={fbLink || ''}
                                onChange={({target}) => setFbLink(target.value)}
                            />
                            <Input
                                className={classes.form__input_link}
                                variant="icon"
                                placeholder={"Paste link here"}
                                icon={inst_ico}
                                value={instLink || ''}
                                onChange={({target}) => setInstLink(target.value)}
                            />
                        </div>
                        <div className={classes.form_upload}>
                            <label className={classes.form_upload__label_flex}>
                                <input type='file' accept="application/pdf" onChange={_uploadPdf} hidden/>
                                <img src={upload_ico} alt="upload_svg" className={classes.form_upload__icon}/>
                                <p className={classes.form_upload__text}>
                                    {pdf ? `${pdf?.name ?? pdf} Uploaded!` : "Upload PDF"}
                                </p>
                            </label>
                        </div>
                        <div className={classes.form_buttons}
                             style={{gridTemplateColumns: 'max-content', justifyContent: "end"}}>
                            <Buttom type="submit">Continue &#62;</Buttom>
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
        </Wrapper>
    );
}

export default Profile;