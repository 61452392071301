import React from 'react';
import SEO from "../../../Components/SEO";

export default function WhySell() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Why Sell: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Why Sell: Buy art online Artsted"/>
            <h1>Why Sell</h1>
            <p>Selling with Artsted is a way to reach out to a pool of loyal collectors that are not only looking for
                decorative value of an artwork but are also interested in supporting and nurturing artists' career and
                getting involved with the arts community on a meaningful level. The platform assumes the role of the
                intermediary, taking care of the promotion and advertisement of the artist’s profile through various
                features, targeted advertisement, marketing campaigns and more. Once an artwork is sold, it is included
                in the Investor's online Collection, where an artist's progress is traced and career updates are
                provided. To put your works for sale, a user should sign up as an artist and upload an inventory of
                available works.</p>
        </div>
    )
}