import {Col, Row, Space} from "antd";
import clsx from "clsx";
import {Link} from "react-router-dom";

import {defaultBase64Image} from "../../../Constants/images";

import styles from './AdminExperienceItem.module.css'

export default function AdminExperienceItem({ ...props }) {
    return (
        <Link to={`/admin/experience/${props.type.toLowerCase()}/${props.id}`} className={styles.root}>
            <div className={styles.image}>
                <img src={props?.photos?.[0]?.path ? `${process.env.REACT_APP_API_URL}/${props?.photos?.[0]?.path}` : defaultBase64Image} alt=""/>
            </div>
            <div className={styles.content}>
                <Row align="middle" gutter={10}>
                    <Col span={17}>
                        <Space direction="vertical" size={16}>
                            <div>
                                <Space size={4} className={styles.text}>
                                    {props.daily_rentable && 'Daily'}
                                    {props.daily_rentable && props.monthly_rentable && '|'}
                                    {props.monthly_rentable && 'Monthly'}
                                </Space>
                                <h4 className={styles.title}>{props.name}</h4>
                                <span className={styles.name}>{props.owner_full_name}</span>
                            </div>
                            <div className={styles.address}>{props.address}</div>
                            <div className={clsx(styles.status, props.is_active && styles.activeStatus)}>
                                {props.is_active ? 'Active' : 'Inactive'}
                            </div>
                        </Space>
                    </Col>
                    <Col span={7}>
                        {props.daily_price && (
                            <div className={styles.price}>
                                <b>€{props.daily_price}</b>
                                night
                            </div>
                        )}
                        {props.monthly_price && (
                            <div className={styles.price}>
                                <b>€{props.monthly_price}</b>
                                month
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </Link>
    )
}