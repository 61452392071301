export const RECENT_VIEWED = "RECENT_VIEWED";
export const RECENT_VIEWED_LIKED = "RECENT_VIEWED_LIKED";
export const RECENT_VIEWED_ARRAY = "RECENT_VIEWED_ARRAY";

export const setRecentView = value => ({
    type: RECENT_VIEWED,
    payload: value
})
export const setRecentViewArray = value => ({
    type: RECENT_VIEWED_ARRAY,
    payload: value
})

export const setRecentViewLiked = value => ({
    type: RECENT_VIEWED_LIKED,
    payload: value
})
