import classes from './button.module.scss';
import classNames from 'classnames';

const Button = props => {
    const className = classNames(
        classes.btn,
        {[classes.variant_link]: props.variant === 'link'},
        props.className
    );

    return (
        <button
            className={className}
            type={props.type}
            style={props.style}
            disabled={props.disable}
            onClick={props.onClick}>
            {props.children}
        </button>
    );
}

Button.defaultProps = {
    onClick: null,
    disable: null
}

export default Button;