import React, {useMemo} from "react";
import {WorldMap} from "react-svg-worldmap";

const NewStatisticRegionViews = ({data, loading, innerWidth}) => {

    const viewsByRegion = useMemo(() => {
        if (loading && data) {
            return data.filter(i => {
                let country = i.country;
                i.country = i.country_code
                i.country_code = country
                i.value = i.views
                delete i.views
                return i
            })
        }
    }, [data, loading])


    return (
        <div className={'statistic_profile_views'}>
            <div className="title">
                <span>Views by region</span>
            </div>
            <div className={'wrapper_geo_info'}>
                <WorldMap color={'#9D9D9D'}
                          enter={{background: '#000'}}
                          frameColor={'#9D9D9D'}
                          style={{fillOpacity: 1}}
                          value-suffix="country"
                          borderColor={'rgba(0,0,0,.9)'}
                          size={innerWidth <= 800 ? 'responsive' : 'lg'}
                          data={viewsByRegion ?? []}
                />
                {loading && viewsByRegion.length ?
                    <div className="wrapper_views_info">
                        {viewsByRegion.map((item, index) => (
                            <div className={'every'} key={index}>
                                <span>{item.country_code}</span>
                                <span className={'val'}>{item.value}</span>
                            </div>
                        ))}
                    </div>
                    : <span>No data yet</span>}
            </div>
        </div>
    )
}

export default NewStatisticRegionViews