import React, {useState} from 'react'
import './styles/footer_form.css';
import TextInput from "./CustomComponents/TextInput";
import w_arrow from "../assets/img/footer_arrow_white.png";
import fioletarr from "../assets/img/arrow_right_violet.svg";
import useResize from "../Constants/useResize";
import regExp from "../Constants/regExp";
import API from "../api/API";
import Popup from "./Popup";
import {bodyOverflow} from "../Constants/bodyOverflow";
import {Link} from "react-router-dom";

let initialState = {
    value: '',
    error: false,
    errorMessage: `This field can't be empty`
}

const FooterForm = ({wrapperMargin}) => {
    const [activeSubscribe, setActiveSubscribe] = useState(initialState)
    const {innerWidth} = useResize()
    const [emailPopup, setEmailPopup] = useState(null)


    const handleSubscribe = (e) => {
        e.preventDefault();
        if (activeSubscribe.value) {
            if (regExp.email.test(activeSubscribe.value)) {
                API().post(`/landing/subscribe`, {email: activeSubscribe.value})
                    .then((res) => {
                        if (res.status === 200) {
                            setEmailPopup(true)
                            bodyOverflow(true)
                            setActiveSubscribe(initialState)
                        }
                    })
            } else {
                setActiveSubscribe(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: `Wrong email format`,
                }))
            }
        } else {
            setActiveSubscribe(prevState => ({
                ...prevState,
                error: true,
                errorMessage: `This field can't be empty`,
            }))
        }
    }

    return (
        <div style={wrapperMargin ? {margin: wrapperMargin} : null} className="news_footer">
            <div className="feedback_form">
                <h4>Stay in the loop</h4>
                <p>Discover new art and collections added weekly</p>
                <form className="input_form"
                      onSubmit={(e) => handleSubscribe(e)}>
                    <TextInput value={activeSubscribe.value}
                               error={activeSubscribe.error}
                               errTitle={activeSubscribe.errorMessage}
                               onChange={({target}) => {
                                   setActiveSubscribe(prevState => ({
                                       ...prevState,
                                       value: target.value,
                                       error: false,
                                   }))
                               }}
                               placeholder={"Your E-mail"}/>
                    <button type="submit"><img src={w_arrow} alt="Arrow"/></button>
                </form>
            </div>
            <div className="left_footer">
                <div className="circle_star">
                    <div className={'image_container'}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="noun_Star_1515310"
                             width="22" height="22"
                             viewBox="0 0 16.809 16.127">
                            <path id="Path_12782"
                                  fill={'#bc4386'}
                                  d="M26.123 33.087a1.569 1.569 0 0 0 .4 1.622l2.808 2.745-.663 3.861a1.583 1.583 0 0 0 2.3 1.671l3.489-1.818L37.935 43a1.583 1.583 0 0 0 2.3-1.671l-.663-3.861 2.808-2.745a1.583 1.583 0 0 0-.878-2.7l-3.886-.579-1.755-3.51a1.583 1.583 0 0 0-2.836 0l-1.727 3.51-3.898.566a1.569 1.569 0 0 0-1.277 1.077zm1.334.432a.172.172 0 0 1 .143-.119l4.262-.618a.7.7 0 0 0 .527-.386l1.9-3.861c.084-.165.239-.165.323 0l1.9 3.861a.7.7 0 0 0 .527.386l4.262.618a.172.172 0 0 1 .144.119.168.168 0 0 1-.046.183l-3.089 3a.7.7 0 0 0-.2.621l.737 4.24a.172.172 0 0 1-.074.176.168.168 0 0 1-.186 0l-3.809-1.99a.7.7 0 0 0-.653 0l-3.809 2a.168.168 0 0 1-.186 0 .172.172 0 0 1-.074-.176l.73-4.24a.7.7 0 0 0-.2-.635l-3.089-3a.168.168 0 0 1-.046-.186z"
                                  className="cls-1" data-name="Path 12782" transform="translate(-26.044 -27.055)"/>
                        </svg>
                    </div>
                </div>
                <div className="learn_arrow">
                    <Link to={'/premium'}>
                        <span>Learn about {innerWidth <= 568 ? <br/> : null} Artsted Premium</span>
                        <img src={fioletarr} alt="Arrow"/>
                    </Link>
                </div>
            </div>
            {innerWidth > 1020 ?
                <>
                    <div className={'dark_blue'}/>
                    <div className={'blue'}/>
                    <div className={'purple'}/>
                </> : null}
            {emailPopup ?
                <Popup closeCallback={() => setEmailPopup(false)}
                       to_top={true}
                       title={'Thank you for subscription!'}/>
                : null}
        </div>
    )
}

export default FooterForm;