import React from 'react';
import SEO from "../../../Components/SEO";

export default function AffiliateProgram() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Affiliate Program: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Affiliate Program: Buy art online Artsted"/>
            <h1>Affiliate Program</h1>
        </div>
    )
}