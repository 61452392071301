import React from 'react'

const SharingData = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>4.THIRD PARTY SOURCES OF DATA AND SHARING DATA</h3>
            <div className={'privacy_policy_item_container'}>
                <span>We also collect and share data as described below:</span>
                <span>
                    Sellers
                    <br/>
                    (Artists) - when you place an order for an artwork, we share your
                            information with the relevant seller(s) on our marketplace ("Seller") so that they can
                            process and deliver your order.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Cloud storage providers</span>
                <span>
                    - we use cloud computing platforms that securely store
                            all of our data, including customer details.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Email service providers</span>
                <span>
                    - in order to send you marketing content and
                            transactional emails, we share your details with our email service providers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Social Media Platforms</span>
                <span>
                    - We may make your name and email address available to
                            companies in order to advertise and market our services to you through other platforms on
                            the internet (e.g. Facebook). See the 'Marketing' section of this Privacy Policy above.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Analytics tools</span>
                <span>
                    - we use analytics tools to track the way that users interact
                            with our website.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Profiling tools</span>
                <span>
                    -we use profiling tools to understand how you engage with our
                            website and show you content we think will be most relevant to you, based on our
                            understanding of your interests and preferences.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Payment providers</span>
                <span>
                    - in order to facilitate any payments made on our site, we
                            facilitate the sharing of your Financial Data with payment providers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Delivery providers</span>
                <span>
                     - in order to package and mail your orders to you, it is
                            necessary to share your information with delivery providers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Marketing and insights providers</span>
                <span>
                     - marketing and insights tools allow us to
                            understand our customers better so that we may provide you with the best possible website,
                            products and customer service experience. We may share certain information about our
                            customers to facilitate this process.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Customer service platforms</span>
                <span>
                      - when you interact with our customer service team,
                            your details are shared with our customer service platform providers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Feedback forms</span>
                <span>
                       - when you make a purchase, we engage a third party to send out
                            feedback forms on our behalf.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Essential Service Providers</span>
                <span>
                        - Sometimes, other businesses give us data about you
                            which we may need for our legitimate interests of conducting business with you and on
                            occasion they are necessary to perform our contract with you. It usually comprises Financial
                            Data or Transaction Data. This happens when we link through to third party payment
                            providers. They tell us that you have paid for your products and, where relevant and/or
                            necessary they will provide us with your Contact Data and Transaction Data. We also might
                            engage third party contractors to provide us with technical or delivery services that are
                            related to your account with us.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Professional Advisers and Investors</span>
                <span>
                        - We may also share your data with
                            professional advisers such as our lawyers and insurers to manage risks and legal claims,
                            and/or as part of our relationship and obligations to our investor organisations. This is in
                            our legitimate interests.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Group</span>
                <span>
                        - It is possible we could sell our business to a third party or
                            re-organise our business or become insolvent. In that scenario, our database of customers is
                            one of the biggest parts of that business and so we would need to share it with the
                            third-party buyer and their advisers. This is in the legitimate interests of selling our
                            business. We may also expand our group of companies and in this scenario we may share your
                            data within our group in order to improve our products and services and because of some of
                            our internal support services may be shared across the group. This is in our legitimate
                            interests of cost efficiency and growing our business. Where this occurs, we will post a
                            link to all group companies and their locations in this Privacy Policy and they will use it
                            in the ways set out in this Policy.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Law Enforcement/Legal Compliance</span>
                <span>
                       - We will cooperate with all third parties to
                            enforce their intellectual property or other rights. We will also cooperate with law
                            enforcement requests from within or outside your country of residence. This may include
                            disclosing your personal information to government or law enforcement agencies, or private
                            parties, when we have a good faith belief that disclosure is required by law or when we, in
                            our discretion, believe that disclosure is necessary to protect our legal rights, or those
                            of third parties and/or to comply with a judicial proceeding, court order, fraud reduction
                            or legal process served on us. In such cases, we may raise or waive any legal objection or
                            right available to us. These uses of your data are in our legitimate interests of protecting
                            our business security. We may also use your data and share it with the recipients listed in
                            this Privacy Policy for the purpose of complying with our legal obligations.
                </span>
            </div>
        </div>
    )
}

export default SharingData