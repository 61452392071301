import React from 'react';

const footerContent = [
    {
        icon: <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.9375 39.375H9.25C8.0901 39.3736 6.97811 38.9122 6.15794 38.0921C5.33777 37.2719 4.87639 36.1599 4.875 35V14C4.87639 12.8401 5.33777 11.7281 6.15794 10.9079C6.97811 10.0878 8.0901 9.62639 9.25 9.625H42.5C43.6599 9.62639 44.7719 10.0878 45.5921 10.9079C46.4122 11.7281 46.8736 12.8401 46.875 14V28.9275C46.875 29.1596 46.7828 29.3821 46.6187 29.5462C46.4546 29.7103 46.2321 29.8025 46 29.8025C45.7679 29.8025 45.5454 29.7103 45.3813 29.5462C45.2172 29.3821 45.125 29.1596 45.125 28.9275V14C45.125 13.3038 44.8484 12.6361 44.3562 12.1438C43.8639 11.6516 43.1962 11.375 42.5 11.375H9.25C8.55381 11.375 7.88613 11.6516 7.39384 12.1438C6.90156 12.6361 6.625 13.3038 6.625 14V35C6.625 35.6962 6.90156 36.3639 7.39384 36.8562C7.88613 37.3484 8.55381 37.625 9.25 37.625H35.9375C36.1696 37.625 36.3921 37.7172 36.5562 37.8813C36.7203 38.0454 36.8125 38.2679 36.8125 38.5C36.8125 38.7321 36.7203 38.9546 36.5562 39.1187C36.3921 39.2828 36.1696 39.375 35.9375 39.375Z"
                fill="black"/>
            <path
                d="M46 24.5H5.75C5.51794 24.5 5.29538 24.4078 5.13128 24.2437C4.96719 24.0796 4.875 23.8571 4.875 23.625V18.375C4.875 18.1429 4.96719 17.9204 5.13128 17.7563C5.29538 17.5922 5.51794 17.5 5.75 17.5H46C46.2321 17.5 46.4546 17.5922 46.6187 17.7563C46.7828 17.9204 46.875 18.1429 46.875 18.375V23.625C46.875 23.8571 46.7828 24.0796 46.6187 24.2437C46.4546 24.4078 46.2321 24.5 46 24.5ZM6.625 22.75H45.125V19.25H6.625V22.75Z"
                fill="black"/>
            <path
                d="M35.5 29.75H32C31.7679 29.75 31.5454 29.6578 31.3813 29.4937C31.2172 29.3296 31.125 29.1071 31.125 28.875C31.125 28.6429 31.2172 28.4204 31.3813 28.2563C31.5454 28.0922 31.7679 28 32 28H35.5C35.7321 28 35.9546 28.0922 36.1187 28.2563C36.2828 28.4204 36.375 28.6429 36.375 28.875C36.375 29.1071 36.2828 29.3296 36.1187 29.4937C35.9546 29.6578 35.7321 29.75 35.5 29.75Z"
                fill="black"/>
            <path
                d="M28.5 29.75H25C24.7679 29.75 24.5454 29.6578 24.3813 29.4937C24.2172 29.3296 24.125 29.1071 24.125 28.875C24.125 28.6429 24.2172 28.4204 24.3813 28.2563C24.5454 28.0922 24.7679 28 25 28H28.5C28.7321 28 28.9546 28.0922 29.1187 28.2563C29.2828 28.4204 29.375 28.6429 29.375 28.875C29.375 29.1071 29.2828 29.3296 29.1187 29.4937C28.9546 29.6578 28.7321 29.75 28.5 29.75Z"
                fill="black"/>
            <path
                d="M21.5 35H11C10.7679 35 10.5454 34.9078 10.3813 34.7437C10.2172 34.5796 10.125 34.3571 10.125 34.125V28.875C10.125 28.6429 10.2172 28.4204 10.3813 28.2563C10.5454 28.0922 10.7679 28 11 28H21.5C21.7321 28 21.9546 28.0922 22.1187 28.2563C22.2828 28.4204 22.375 28.6429 22.375 28.875V34.125C22.375 34.3571 22.2828 34.5796 22.1187 34.7437C21.9546 34.9078 21.7321 35 21.5 35ZM11.875 33.25H20.625V29.75H11.875V33.25Z"
                fill="black"/>
            <path
                d="M43.375 46.374C43.3061 46.374 43.2374 46.3661 43.1702 46.3504C40.754 45.7588 38.603 44.3805 37.056 42.4324C35.509 40.4843 34.6538 38.0771 34.625 35.5896V29.686C34.6249 29.4577 34.7141 29.2384 34.8735 29.0749C35.0329 28.9114 35.2499 28.8167 35.4781 28.811C38.066 28.6969 40.6167 28.1515 43.025 27.1975C43.1355 27.1497 43.2546 27.125 43.375 27.125C43.4954 27.125 43.6145 27.1497 43.725 27.1975C46.1007 28.2316 48.6592 28.7802 51.25 28.811C51.4821 28.811 51.7046 28.9032 51.8687 29.0673C52.0328 29.2314 52.125 29.4539 52.125 29.686V35.5896C52.0966 38.0774 51.2415 40.485 49.6945 42.4335C48.1475 44.3819 45.9963 45.7605 43.5798 46.3521C43.5126 46.3672 43.4439 46.3746 43.375 46.374ZM36.375 30.512V35.587C36.4011 37.646 37.099 39.6401 38.3626 41.266C39.6262 42.8919 41.3862 44.0606 43.375 44.5942C45.3638 44.0606 47.1238 42.8919 48.3874 41.266C49.651 39.6401 50.3489 37.646 50.375 35.587V30.5365C47.9692 30.4044 45.6027 29.8681 43.375 28.9501C41.131 29.8116 38.7724 30.3379 36.375 30.512Z"
                fill="black"/>
            <path
                d="M42.5001 39.376C42.3273 39.376 42.1583 39.3248 42.0145 39.229L39.3895 37.479C39.1964 37.3502 39.0624 37.1499 39.0169 36.9223C38.9715 36.6948 39.0183 36.4584 39.1471 36.2653C39.2759 36.0723 39.4761 35.9383 39.7037 35.8928C39.9313 35.8473 40.1677 35.8942 40.3607 36.023L42.389 37.3748L46.694 33.0698C46.859 32.9105 47.08 32.8223 47.3095 32.8243C47.5389 32.8262 47.7584 32.9183 47.9206 33.0805C48.0828 33.2427 48.1748 33.4622 48.1768 33.6916C48.1788 33.921 48.0906 34.1421 47.9312 34.3071L43.1187 39.1196C42.9547 39.2837 42.7322 39.3759 42.5001 39.376Z"
                fill="black"/>
        </svg>,
        title: "Secure payments",
        description: "All payments are 100% secured through our trusted partners."
    },
    {
        icon: <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4417_15141)">
                <path
                    d="M40.4852 38.9074H24.4086C23.9554 38.9074 23.5881 39.2746 23.5881 39.7279C23.5881 40.1811 23.9554 40.5484 24.4086 40.5484H40.4852C43.6522 40.5484 46.2287 37.9719 46.2287 34.8049V22.4844C46.2287 19.3174 43.6522 16.7409 40.4852 16.7409H35.8263L25.2388 11.3363C24.1825 10.7973 22.8848 11.2182 22.3458 12.274L20.0657 16.7409H17.0278C15.9721 16.7409 15.1133 17.5997 15.1133 18.6554V38.6338C15.1133 39.6895 15.9721 40.5483 17.0278 40.5483H20.7996C21.2528 40.5483 21.6201 40.181 21.6201 39.7278C21.6201 39.2745 21.2528 38.9073 20.7996 38.9073H17.0278C16.877 38.9073 16.7542 38.7846 16.7542 38.6337V18.6553C16.7542 18.5044 16.8768 18.3817 17.0278 18.3817H40.4851C42.7473 18.3817 44.5877 20.2221 44.5877 22.4843V25.1521H38.0521C35.9591 25.1521 34.2563 26.8549 34.2563 28.9479V30.0415C34.2563 32.1345 35.9591 33.8373 38.0521 33.8373H44.5877V34.8045C44.5878 37.0669 42.7474 38.9074 40.4852 38.9074ZM23.8075 13.0202C23.9353 12.7699 24.2426 12.6704 24.4928 12.7979L32.217 16.7409H21.9082L23.8075 13.0202ZM38.0521 32.1965C36.8639 32.1965 35.8973 31.2299 35.8973 30.0417V28.9481C35.8973 27.76 36.8639 26.7933 38.0521 26.7933H44.5877V32.1965H38.0521Z"
                    fill="black"/>
                <path
                    d="M54.5257 18.0122C53.2151 14.8321 51.3189 11.9795 48.8895 9.53373C48.293 8.93316 47.6621 8.35555 47.0142 7.81687C46.6659 7.52758 46.1485 7.57483 45.8586 7.9233C45.569 8.27177 45.6167 8.78911 45.9651 9.07884C46.5731 9.58427 47.1652 10.1263 47.7252 10.6902C52.326 15.3223 54.8597 21.4697 54.8597 27.9998C54.8597 34.5615 52.3044 40.7303 47.6646 45.37C43.025 50.0098 36.8561 52.565 30.2943 52.565C25.111 52.565 20.1739 50.9788 15.9905 47.9737L16.9598 46.7132C20.8627 49.5034 25.4648 50.976 30.2943 50.976C36.4329 50.976 42.2074 48.5761 46.5542 44.2186C50.9006 39.8612 53.2859 34.0807 53.2706 27.9415C53.2391 15.336 42.9582 5.05505 30.3526 5.02355C30.3326 5.02355 30.3129 5.02344 30.2928 5.02344C26.6277 5.02344 23.1222 5.8653 19.873 7.52583C16.759 9.11723 14.0014 11.4423 11.8982 14.2497C11.6265 14.6123 11.7004 15.1266 12.0629 15.3983C12.4255 15.6698 12.9399 15.5961 13.2116 15.2336C17.2334 9.86492 23.6166 6.66428 30.2962 6.66428C30.3134 6.66428 30.3312 6.66439 30.3485 6.66439C42.0536 6.6937 51.6003 16.2403 51.6295 27.9454C51.6437 33.6455 49.4286 39.0131 45.3923 43.0596C41.3557 47.1062 35.9937 49.3349 30.2942 49.3349C25.6891 49.3349 21.3077 47.8935 17.6234 45.1667C17.0805 44.7648 16.3031 44.8749 15.8902 45.4118L14.4461 47.2897C14.2401 47.5575 14.1522 47.8894 14.1988 48.224C14.2456 48.56 14.4217 48.8567 14.6944 49.059C19.2294 52.4261 24.6237 54.2059 30.2942 54.2059C37.2942 54.2059 43.8752 51.4799 48.825 46.5303C53.7748 41.5807 56.5007 34.9997 56.5007 27.9998C56.5007 24.5523 55.8362 21.192 54.5257 18.0122Z"
                    fill="black"/>
                <path
                    d="M11.237 24.0337C11.0813 23.7708 10.8325 23.5843 10.5365 23.5086C10.2406 23.4327 9.9329 23.477 9.67007 23.6327L9.3729 23.809C9.74904 21.9245 10.3792 20.1051 11.2538 18.3806C11.4588 17.9765 11.2975 17.4827 10.8932 17.2777C10.4896 17.0729 9.99547 17.234 9.79039 17.6384C8.56867 20.0466 7.79003 22.6272 7.47568 25.3087C7.43937 25.6186 7.582 25.9223 7.84362 26.0923C8.10525 26.2623 8.4407 26.2691 8.709 26.1101L10.0787 25.2981L10.8888 26.6652L6.20453 29.4416L2.34633 25.9412L3.41383 24.7645L4.5149 25.7632C4.74339 25.9703 5.06834 26.0319 5.35665 25.9229C5.64486 25.8137 5.84775 25.5522 5.88165 25.2458C6.49918 19.6727 8.93409 14.6182 12.9232 10.6291C17.5408 6.01146 23.6794 3.45624 30.208 3.43414C30.2361 3.43403 30.2641 3.43403 30.2922 3.43403C34.7837 3.43403 39.1629 4.6458 42.9617 6.9406C43.3496 7.17477 43.854 7.05052 44.0883 6.66246C44.3226 6.27461 44.1981 5.77028 43.8102 5.53589C39.7551 3.08622 35.083 1.79297 30.2921 1.79297C30.2623 1.79297 30.232 1.79297 30.2023 1.79308C26.7552 1.80467 23.3972 2.48105 20.2217 3.80285C17.0465 5.12464 14.2005 7.03083 11.7626 9.46858C7.8819 13.3494 5.37984 18.176 4.46951 23.5065L4.14795 23.2148C3.92176 23.0095 3.63017 22.9061 3.32392 22.9193C3.01876 22.9343 2.73767 23.067 2.53259 23.2933L0.796811 25.207C0.591514 25.4332 0.486514 25.7259 0.501389 26.031C0.516264 26.3362 0.649045 26.6173 0.875342 26.8224L5.31531 30.8504C5.55265 31.0657 5.85212 31.1763 6.15465 31.1763C6.37362 31.1763 6.59434 31.1183 6.79373 30.9999L12.1537 27.8231C12.6959 27.5018 12.8757 26.7991 12.5544 26.2565L11.237 24.0337Z"
                    fill="black"/>
                <path
                    d="M38.6146 30.8698C39.379 30.8698 39.9987 30.2501 39.9987 29.4857C39.9987 28.7213 39.379 28.1016 38.6146 28.1016C37.8502 28.1016 37.2305 28.7213 37.2305 29.4857C37.2305 30.2501 37.8502 30.8698 38.6146 30.8698Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_4417_15141">
                    <rect width="56" height="56" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>,
        title: "Money back guarantee",
        description: "We guarantee satisfaction with our free, no questions asked returns policy."
    },
    {
        icon: <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.375 29.75C43.1663 29.7498 42.9646 29.675 42.8062 29.5391L36.9263 24.5H22.375C21.2151 24.4986 20.1031 24.0372 19.2829 23.2171C18.4628 22.3969 18.0014 21.2849 18 20.125V6.125C18.0014 4.9651 18.4628 3.85311 19.2829 3.03294C20.1031 2.21277 21.2151 1.75139 22.375 1.75H46.875C48.0349 1.75139 49.1469 2.21277 49.9671 3.03294C50.7872 3.85311 51.2486 4.9651 51.25 6.125V20.125C51.2486 21.2849 50.7872 22.3969 49.9671 23.2171C49.1469 24.0372 48.0349 24.4986 46.875 24.5H44.25V28.875C44.25 29.1071 44.1578 29.3296 43.9937 29.4937C43.8296 29.6578 43.6071 29.75 43.375 29.75ZM22.375 3.5C21.6788 3.5 21.0111 3.77656 20.5188 4.26884C20.0266 4.76113 19.75 5.42881 19.75 6.125V20.125C19.75 20.8212 20.0266 21.4889 20.5188 21.9812C21.0111 22.4734 21.6788 22.75 22.375 22.75H37.25C37.4587 22.7498 37.6606 22.8246 37.8188 22.9609L42.5 26.9727V23.625C42.5 23.3929 42.5922 23.1704 42.7563 23.0063C42.9204 22.8422 43.1429 22.75 43.375 22.75H46.875C47.5712 22.75 48.2389 22.4734 48.7312 21.9812C49.2234 21.4889 49.5 20.8212 49.5 20.125V6.125C49.5 5.42881 49.2234 4.76113 48.7312 4.26884C48.2389 3.77656 47.5712 3.5 46.875 3.5H22.375Z"
                fill="black"/>
            <path
                d="M34.6232 19.2481C34.4232 19.2461 34.23 19.1749 34.0765 19.0467C33.923 18.9184 33.8186 18.741 33.781 18.5445C33.7435 18.3481 33.7751 18.1446 33.8705 17.9688C33.9658 17.793 34.1191 17.6556 34.3043 17.5799C34.4895 17.5043 34.6952 17.4951 34.8864 17.5539C35.0776 17.6126 35.2426 17.7358 35.3533 17.9023C35.464 18.0689 35.5137 18.2687 35.4939 18.4678C35.474 18.6668 35.3859 18.8529 35.2445 18.9943C35.0768 19.1542 34.8549 19.2449 34.6232 19.2481Z"
                fill="black"/>
            <path
                d="M34.625 16.6242C34.3929 16.6242 34.1704 16.5321 34.0063 16.368C33.8422 16.2039 33.75 15.9813 33.75 15.7492V13.8444C33.7524 13.461 33.8838 13.0897 34.123 12.7901C34.3622 12.4906 34.6953 12.2803 35.0686 12.1932C35.4977 12.0812 35.8683 11.8105 36.1054 11.4358C36.3425 11.0611 36.4286 10.6103 36.3461 10.1746C36.2787 9.82836 36.1095 9.51013 35.86 9.2607C35.6106 9.01127 35.2924 8.84201 34.9461 8.77461C34.6931 8.72738 34.4328 8.73654 34.1837 8.80144C33.9347 8.86635 33.703 8.98539 33.5052 9.15009C33.3074 9.31479 33.1484 9.52109 33.0394 9.75429C32.9305 9.98749 32.8744 10.2419 32.875 10.4992C32.875 10.7313 32.7828 10.9539 32.6187 11.118C32.4546 11.282 32.2321 11.3742 32 11.3742C31.7679 11.3742 31.5454 11.282 31.3813 11.118C31.2172 10.9539 31.125 10.7313 31.125 10.4992C31.1251 9.84507 31.3086 9.20404 31.6546 8.64886C32.0006 8.09369 32.4952 7.64658 33.0824 7.35827C33.6696 7.06996 34.3259 6.95198 34.9767 7.01772C35.6276 7.08346 36.247 7.33029 36.7647 7.7302C37.2824 8.13011 37.6776 8.6671 37.9056 9.28026C38.1336 9.89341 38.1851 10.5582 38.0545 11.1992C37.9238 11.8401 37.6161 12.4317 37.1663 12.9066C36.7165 13.3816 36.1426 13.721 35.5096 13.8864L35.5 15.7492C35.5 15.9813 35.4078 16.2039 35.2437 16.368C35.0796 16.5321 34.8571 16.6242 34.625 16.6242Z"
                fill="black"/>
            <path
                d="M13.625 54.25C13.3929 54.25 13.1704 54.1578 13.0063 53.9937C12.8422 53.8296 12.75 53.6071 12.75 53.375V49H10.125C8.9651 48.9986 7.85311 48.5372 7.03294 47.7171C6.21277 46.8969 5.75139 45.7849 5.75 44.625V30.625C5.75139 29.4651 6.21277 28.3531 7.03294 27.5329C7.85311 26.7128 8.9651 26.2514 10.125 26.25H34.625C35.7849 26.2514 36.8969 26.7128 37.7171 27.5329C38.5372 28.3531 38.9986 29.4651 39 30.625V44.625C38.9986 45.7849 38.5372 46.8969 37.7171 47.7171C36.8969 48.5372 35.7849 48.9986 34.625 49H20.0738L14.1946 54.0391C14.036 54.1752 13.834 54.25 13.625 54.25ZM10.125 28C9.42881 28 8.76113 28.2766 8.26884 28.7688C7.77656 29.2611 7.5 29.9288 7.5 30.625V44.625C7.5 45.3212 7.77656 45.9889 8.26884 46.4812C8.76113 46.9734 9.42881 47.25 10.125 47.25H13.625C13.8571 47.25 14.0796 47.3422 14.2437 47.5063C14.4078 47.6704 14.5 47.8929 14.5 48.125V51.4727L19.1804 47.4609C19.339 47.3248 19.541 47.25 19.75 47.25H34.625C35.3212 47.25 35.9889 46.9734 36.4812 46.4812C36.9734 45.9889 37.25 45.3212 37.25 44.625V30.625C37.25 29.9288 36.9734 29.2611 36.4812 28.7688C35.9889 28.2766 35.3212 28 34.625 28H10.125Z"
                fill="black"/>
            <path
                d="M13.625 33.25H11.875C11.6429 33.25 11.4204 33.1578 11.2563 32.9937C11.0922 32.8296 11 32.6071 11 32.375C11 32.1429 11.0922 31.9204 11.2563 31.7563C11.4204 31.5922 11.6429 31.5 11.875 31.5H13.625C13.8571 31.5 14.0796 31.5922 14.2437 31.7563C14.4078 31.9204 14.5 32.1429 14.5 32.375C14.5 32.6071 14.4078 32.8296 14.2437 32.9937C14.0796 33.1578 13.8571 33.25 13.625 33.25Z"
                fill="black"/>
            <path
                d="M32.875 33.25H17.125C16.8929 33.25 16.6704 33.1578 16.5063 32.9937C16.3422 32.8296 16.25 32.6071 16.25 32.375C16.25 32.1429 16.3422 31.9204 16.5063 31.7563C16.6704 31.5922 16.8929 31.5 17.125 31.5H32.875C33.1071 31.5 33.3296 31.5922 33.4937 31.7563C33.6578 31.9204 33.75 32.1429 33.75 32.375C33.75 32.6071 33.6578 32.8296 33.4937 32.9937C33.3296 33.1578 33.1071 33.25 32.875 33.25Z"
                fill="black"/>
            <path
                d="M13.625 36.75H11.875C11.6429 36.75 11.4204 36.6578 11.2563 36.4937C11.0922 36.3296 11 36.1071 11 35.875C11 35.6429 11.0922 35.4204 11.2563 35.2563C11.4204 35.0922 11.6429 35 11.875 35H13.625C13.8571 35 14.0796 35.0922 14.2437 35.2563C14.4078 35.4204 14.5 35.6429 14.5 35.875C14.5 36.1071 14.4078 36.3296 14.2437 36.4937C14.0796 36.6578 13.8571 36.75 13.625 36.75Z"
                fill="black"/>
            <path
                d="M32.875 36.75H17.125C16.8929 36.75 16.6704 36.6578 16.5063 36.4937C16.3422 36.3296 16.25 36.1071 16.25 35.875C16.25 35.6429 16.3422 35.4204 16.5063 35.2563C16.6704 35.0922 16.8929 35 17.125 35H32.875C33.1071 35 33.3296 35.0922 33.4937 35.2563C33.6578 35.4204 33.75 35.6429 33.75 35.875C33.75 36.1071 33.6578 36.3296 33.4937 36.4937C33.3296 36.6578 33.1071 36.75 32.875 36.75Z"
                fill="black"/>
            <path
                d="M13.625 40.25H11.875C11.6429 40.25 11.4204 40.1578 11.2563 39.9937C11.0922 39.8296 11 39.6071 11 39.375C11 39.1429 11.0922 38.9204 11.2563 38.7563C11.4204 38.5922 11.6429 38.5 11.875 38.5H13.625C13.8571 38.5 14.0796 38.5922 14.2437 38.7563C14.4078 38.9204 14.5 39.1429 14.5 39.375C14.5 39.6071 14.4078 39.8296 14.2437 39.9937C14.0796 40.1578 13.8571 40.25 13.625 40.25Z"
                fill="black"/>
            <path
                d="M32.875 40.25H17.125C16.8929 40.25 16.6704 40.1578 16.5063 39.9937C16.3422 39.8296 16.25 39.6071 16.25 39.375C16.25 39.1429 16.3422 38.9204 16.5063 38.7563C16.6704 38.5922 16.8929 38.5 17.125 38.5H32.875C33.1071 38.5 33.3296 38.5922 33.4937 38.7563C33.6578 38.9204 33.75 39.1429 33.75 39.375C33.75 39.6071 33.6578 39.8296 33.4937 39.9937C33.3296 40.1578 33.1071 40.25 32.875 40.25Z"
                fill="black"/>
            <path
                d="M13.625 43.75H11.875C11.6429 43.75 11.4204 43.6578 11.2563 43.4937C11.0922 43.3296 11 43.1071 11 42.875C11 42.6429 11.0922 42.4204 11.2563 42.2563C11.4204 42.0922 11.6429 42 11.875 42H13.625C13.8571 42 14.0796 42.0922 14.2437 42.2563C14.4078 42.4204 14.5 42.6429 14.5 42.875C14.5 43.1071 14.4078 43.3296 14.2437 43.4937C14.0796 43.6578 13.8571 43.75 13.625 43.75Z"
                fill="black"/>
            <path
                d="M32.875 43.75H17.125C16.8929 43.75 16.6704 43.6578 16.5063 43.4937C16.3422 43.3296 16.25 43.1071 16.25 42.875C16.25 42.6429 16.3422 42.4204 16.5063 42.2563C16.6704 42.0922 16.8929 42 17.125 42H32.875C33.1071 42 33.3296 42.0922 33.4937 42.2563C33.6578 42.4204 33.75 42.6429 33.75 42.875C33.75 43.1071 33.6578 43.3296 33.4937 43.4937C33.3296 43.6578 33.1071 43.75 32.875 43.75Z"
                fill="black"/>
        </svg>,
        title: "Dedicated Support",
        description: "Have total peace of mind, knowing our Support Team is always here to help."
    },

]

const CartFooter = ({mobile}) => {
    return (
        <div className={`cart-footer ${mobile ? "mobile" : ""}`}>
            {footerContent.map((i, idx) => (
                <div key={idx} className="footer-item">
                    {i.icon}
                    <div>
                        <span className="title">{i.title}</span>
                        <span>{i.description}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CartFooter;
