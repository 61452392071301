import React, {useEffect, useState} from 'react';
import Wrapper from '../OnboardingWrapper';
import artwork_img from '../../../assets/img/onboarding/artwork5.png';
import classes from './styles/greetings.module.scss';
import Button from '../../../Components/CustomComponents/Onboarding/Button';
import Dropdown from '../../../Components/CustomComponents/Onboarding/Dropdown';
import API from "../../../api/API";
import Keys from '../../../Constants/helper';
import {useHistory} from 'react-router';
import Error from '../../../Components/CustomComponents/Onboarding/Error';
import {useDispatch, useSelector} from "react-redux";
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import useBottomPopup from "../../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import historyDetectBack from "../HistoryBackDetect";
import Input from '../../../Components/CustomComponents/Onboarding/Input';

function Greetings() {
    const [photo, setPhoto] = useState(null);
    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [countryError, setCountryError] = useState("");
    const [loading, setLoading] = useState(null);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [errors, setErrors] = useState({});

    const history = useHistory()
    const store = useSelector(state => state.auth)
    const dispatch = useDispatch();
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    useEffect(() => {
        if (store?.user_registration_data && localStorage?.getItem(Keys.JWT_TOKEN_REGISTRATION)) {
            Promise.all([
                API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/countries`),
                API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/profile/edit_photo`)
            ])
              .then(response => {
                  const countriesData = response[0]?.data?.countries || [];
                  setCountries(countriesData);

                  const selectedCountry = store.user_registration_data?.country || null;
                  setCountry(selectedCountry);

                  const avatar = response[1]?.data?.avatar ?? null;
                  if (avatar) {
                      setPhoto({ blob: avatar });
                  }

                  setLoading(true);
              })
              .catch(e => console.log(e));
        } else {
            let { tempArray } = historyDetectBack(store?.user_registration_data, store?.active_history);
            dispatch(setUserRegistrationData(tempArray));
            setLoading(false);
            history?.push('/registration/auth');
        }
        // eslint-disable-next-line
    }, [dispatch, store.user_registration_data, store.active_history]);

    const _uploadPhoto = e => {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('photo', e.target.files[0])
            API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/edit_photo`, formData)
                .then(() => {
                    changeContinuePopup(`You successfully uploaded avatar`)
                    setTimeout(() => {
                        changeContinuePopup('You successfully uploaded avatar')
                    }, 2000)
                    setPhoto({
                        file: e.target.files[0],
                        blob: URL.createObjectURL(e.target.files[0])
                    });
                })
        }
    };

    const _getPhotoStyle = () => {
        if (photo) {
            return {
                backgroundImage: `url(${photo.blob})`,
                backgroundSize: 'cover',
            };
        }

        return {};
    }

    const _onSubmit = e => {
        e.preventDefault();
        const errors = {}

        if (!country) {
            errors.country = "Choose country from list"
        }
        if(!store.user_registration_data.name && !name) {
            errors.name = "Enter name"
        }
        if(!store.user_registration_data.surname && !surname) {
            errors.surname = "Enter surname"
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
        }

        let {nextHistory} = historyDetectBack(store.user_registration_data,store.active_history)
        const userData = {country}
        if(name && surname) {
            userData.name = name
            userData.surname = surname
        }
        dispatch(setUserRegistrationData(
            {
                ...store.user_registration_data,
                ...userData
            }
        ))
        history.push(nextHistory)
    }

    // console.log(store.user_registration_data)
    return loading ? (
        <Wrapper>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>Greetings{(store.user_registration_data.name || store.user_registration_data.surname) && ","} {store.user_registration_data.name} {store.user_registration_data.surname}!</h1>
                    <div className={classes.img_profile}>
                        <label>
                            <div className={classes.img_upload} style={_getPhotoStyle()}/>
                            <p className={classes.img_text}>Upload Avatar</p>
                            <input type="file" hidden accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                                   onChange={_uploadPhoto}/>
                        </label>
                    </div>
                    <form className={classes.form}>
                        {!store.user_registration_data.name && (
                            <Input
                                title={"Name"}
                                value={name}
                                onChange={({target}) => setName(target.value.trim())}
                                error={errors.name}
                            />
                        )}
                        {!store.user_registration_data.surname && (
                            <Input
                                title={"Surname"}
                                value={surname}
                                onChange={({target}) => setSurname(target.value.trim())}
                                error={errors.name}
                            />
                        )}
                    </form>
                    <p className={classes.form_label}>
                        Choose a Country
                    </p>
                    <Dropdown
                        value={country}
                        options={countries}
                        onChange={country => {
                            setCountryError("");
                            setCountry(country);
                        }}
                        placeholder="Country"
                        error={errors.country}
                    />
                    <div className={classes.error}>
                        {countryError && <Error text={countryError}/>}
                    </div>
                    <div className={classes.buttons}>
                        <Button onClick={_onSubmit}>Continue &#62;</Button>
                        <Button variant="link" onClick={() => history.push('/login')}>
                            Already a member? Log In
                        </Button>
                    </div>
                </div>
            </Wrapper.Form>
            <Wrapper.Picture src={artwork_img} imgRight/>
        </Wrapper>
    ) : null;
}

export default Greetings;
