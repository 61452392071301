import React from 'react'

const ImportantInformation = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>1. Important Information</h3>
            <div className={'privacy_policy_item_container'}>
                <span>Data Controller</span>
                <span>
                    Artsted is the data controller responsible for this website and the handling of your personal data.
                    We have appointed a data privacy lead who is responsible for overseeing questions in relation to
                    this Privacy Policy. If you have any questions about this Privacy Policy, including any requests
                    to exercise your legal rights, please contact us at legal@artsted.com.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>CONTACT DETAILS</span>
                <span>
                    Artisfact Limited (company number 12015093, registered in England).
                    <br/>
                    Registered office address: 71-75 Shelton Street, Covent Garden, London, England, WC2H 9JQ
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>CHANGES TO THE PRIVACY POLICY</span>
                <span>
                    We may need to update this Policy at any time and without notice and where we do this we will notify
                    you by including pop up boxes on the website and/or emailing our customers. This Policy was last
                    updated on 30 July 2020. It is important that the personal data we hold about you is accurate
                    and current. Please keep us informed if your personal data changes during your relationship with us.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>THIRD-PARTY LINKS</span>
                <span>
                    This website may include links to third-party websites, plug-ins and applications. Clicking on those
                    links or enabling those connections may allow third parties to collect or share data about you.
                    We do not control these third-party websites and are not responsible for their privacy
                    statements. When you leave our website, we encourage you to read the privacy policy
                    of every website you visit.
                </span>
            </div>
        </div>
    )
}

export default ImportantInformation