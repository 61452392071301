import Wrapper from "../OnboardingWrapper";
import artwork_img from "../../../assets/img/onboarding/artwork1.png";
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import classes from "../ArtistRegistration/styles/profile.module.scss";
import Dropdown from "../../../Components/CustomComponents/Onboarding/Dropdown";
import Error from "../../../Components/CustomComponents/Onboarding/Error";
import Input from "../../../Components/CustomComponents/Onboarding/Input";
import website_ico from "../../../assets/img/onboarding/social/website.svg";
import in_ico from "../../../assets/img/onboarding/social/in.svg";
import fb_ico from "../../../assets/img/onboarding/social/fb_black.svg";
import inst_ico from "../../../assets/img/onboarding/social/inst.svg";
import upload_ico from "../../../assets/img/onboarding/upload_black.svg";
import Buttom from "../../../Components/CustomComponents/Onboarding/Button";
import React, {useEffect, useState} from "react";
import useBottomPopup from "../../../Constants/useBottomPopup";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import API from "../../../api/API";
import Keys from "../../../Constants/helper";
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import historyDetectBack from "../HistoryBackDetect";

const initialState = {
  country: {
    value: null,
    errorMessage: null,
  },
  address: {
    value: null,
    errorMessage: null,
  },
  website: {
    value: '',
    errorMessage: null,
  },
  fbLink: {
    value: '',
    errorMessage: null,
  },
  inLink: {
    value: '',
    errorMessage: null,
  },
  instLink: {
    value: '',
    errorMessage: null,
  },
  pdf: {
    value: null,
    errorMessage: null,
  }
}

export const OrganizationProfile = () => {
  const [profileData, setProfileData] = useState(initialState)
  const [countries, setCountries] = useState([]);
  let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
  const store = useSelector(state => state.auth)
  const history = useHistory()
  const dispatch = useDispatch();

  useEffect(() => {
    API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/countries`)
      .then(res => {
        setCountries(res.data.countries)
      })
      .catch(e => console.error(e))
  }, []);

  const uploadPdf = (e) => {
    if (e.target.files && e.target.files[0]) {
      let formData = new FormData()
      formData.append("cv", e.target.files[0])
      API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/upload_cv`, formData)
        .then((res) => {
          setProfileData(prevState => ({
            ...prevState,
            pdf: {
              ...prevState.pdf,
              value: e.target.files[0],
              errorMessage: null,
            }
          }));
          changeContinuePopup('You successfully uploaded cv')
          setTimeout(() => {
            changeContinuePopup('You successfully uploaded cv')
          }, 2000)
        })
    }
  }

  const onSubmit = event => {
    event.preventDefault();
    const { country, address, website, fbLink, inLink, instLink } = profileData
    const countryCheck = country.value?.length > 0
    if (!countryCheck) {
      setProfileData(prevState => ({
        ...prevState,
        country: {
          ...prevState.country,
          errorMessage: 'Choose country from list',
        }
      }))
      return
    }
    dispatch(setUserRegistrationData({
      ...store.user_registration_data,
      country: country.value || '',
      address: address.value || '',
      website: website.value || '',
      facebook_link: fbLink.value || '',
      linkedin_link: inLink.value || '',
      instagram_link: instLink.value || '',
    }))
    let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
    history.push(nextHistory)
  }

  return (
    <Wrapper>
      <Wrapper.Picture src={artwork_img}/>
      <ChangedConfirmedPopup successText={successText}
                             changingConfirmed={changingConfirmed}/>
      <Wrapper.Form>
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Submit your profile</h1>
          <form className={classes.form} onSubmit={onSubmit}>
            <p className={classes.form__label}>Choose a Country</p>
            <Dropdown
              options={countries}
              value={profileData.country.value}
              onChange={country => {
                setProfileData(prevState => ({
                  ...prevState,
                  country: {
                    ...prevState.country,
                    value: country,
                    errorMessage: null,
                  }
                }));
              }}
              placeholder='Country'
              error={profileData.country.errorMessage}
            />
            {profileData.country.errorMessage && <Error text={profileData.country.errorMessage} className={classes.error_component}/>}
            <Input
              className={classes.form__input}
              title=" "
              placeholder={'Address'}
              value={profileData.address.value}
              onChange={({ target }) => {
                setProfileData(prevState => ({
                  ...prevState,
                  address: {
                    ...prevState.address,
                    value: target.value,
                    errorMessage: null,
                  }
                }));
              }}
              error={profileData?.address.errorMessage}
            />
            <p className={classes.form__label}>Upload Portfolio (Links / PDF)</p>
            <div className={classes.wrapper_inputs}>
              <Input
                className={classes.form__input_link}
                variant="icon"
                placeholder={"Website"}
                icon={website_ico}
                value={profileData.website.value}
                onChange={({ target }) => {
                  setProfileData(prevState => ({
                    ...prevState,
                    website: {
                      ...prevState.website,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
              />
              <Input
                className={classes.form__input_link}
                variant="icon"
                placeholder={"Paste link here"}
                icon={in_ico}
                value={profileData.inLink.value}
                onChange={({ target }) => {
                  setProfileData(prevState => ({
                    ...prevState,
                    inLink: {
                      ...prevState.inLink,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
              />
              <Input
                className={classes.form__input_link}
                variant="icon"
                icon={fb_ico}
                placeholder={"Paste link here"}
                value={profileData.fbLink.value}
                onChange={({ target }) => {
                  setProfileData(prevState => ({
                    ...prevState,
                    fbLink: {
                      ...prevState.fbLink,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
              />
              <Input
                className={classes.form__input_link}
                variant="icon"
                placeholder={"Paste link here"}
                icon={inst_ico}
                value={profileData.instLink.value}
                onChange={({ target }) => {
                  setProfileData(prevState => ({
                    ...prevState,
                    instLink: {
                      ...prevState.instLink,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
              />
            </div>
            <div className={classes.form_upload}>
              <label className={classes.form_upload__label_flex}>
                <input type='file' accept="application/pdf" onChange={uploadPdf} hidden/>
                <img src={upload_ico} alt="upload_svg" className={classes.form_upload__icon}/>
                <p className={classes.form_upload__text}>
                  {profileData.pdf.value ? `${profileData.pdf.value?.name ?? profileData.pdf.value} Uploaded!` : "Upload PDF"}
                </p>
              </label>
            </div>
            <div className={classes.form_buttons}
                 style={{gridTemplateColumns: 'max-content', justifyContent: "end"}}>
              <Buttom type="submit">Continue &#62;</Buttom>
            </div>
          </form>
        </div>
      </Wrapper.Form>
    </Wrapper>
  )
}