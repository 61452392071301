import React from 'react'
import '../../../styles/terms_of_service.css'

const TermsDelivery = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>3. Delivery Conditions</h3>
            <div className={'terms_of_service_item_container'}>
                <span>4.1</span>
                <span>
                    The website operator delivers worldwide.
                    The shipping costs incurred depending on the delivery address are shown accordingly in the
                    purchase process.
                    Any additional costs for customs or other taxes that incur upon receipt must be borne
                    additionally by the customer
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>4.2</span>
                <span>
                    Delivery is executed to the contact person and delivery address indicated by the customer.
                    The delivery is executed via postal mail.
                    All information regarding availability and delivery dates is non-binding, unless confirmed in
                    written form by the Website operator.
                    If the products are in stock, the delivery is executed within 14 days.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>4.3</span>
                <span>
                    All claims for damages due to late deliveries are excluded.
                    The Website operator is not liable for delays in delivery caused by suppliers or third parties,
                    and owes no compensation in this regard whatsoever.
                </span>
            </div>
        </div>
    )
}

export default TermsDelivery