import React from 'react';
import SEO from "../../../Components/SEO";

export default function CuratorsCircle() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Curator’s circle: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Curator’s circle: Buy art online Artsted"/>
            <h1>
                Curator’s circle
            </h1>
        </div>
    )
}