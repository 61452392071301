import moment from "moment";
import {Calendar, momentLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import IconButton from "../IconButton";
import StyledButton from "../StyledButton";

import {ChevronLeft, ChevronRight} from "../../../assets/icons";

import styles from './StyledCalendar.module.css';

const localizer = momentLocalizer(moment)

const CustomHeader = ({ onNavigate, label }) => {
    return (
        <div className={styles.header}>
            <div className={styles.inner}>
                <div className={styles.headerLeft}>
                    <div className={styles.label}>{label}</div>
                    <IconButton size="small" icon={ChevronLeft} onClick={() => onNavigate('PREV')} />
                    <IconButton size="small" icon={ChevronRight} onClick={() => onNavigate('NEXT')} />
                </div>
                <div>
                    <StyledButton className={styles.todayButton} color="transparent" onClick={() => onNavigate('TODAY')}>Today</StyledButton>
                </div>
            </div>
            <div className={styles.headerDescription}>
                Select specific days to customize them
            </div>
        </div>
    );
};

export default function StyledCalendar({ price, ...props }) {
    return (
        <div className={styles.root}>
            <Calendar
                selectable
                localizer={localizer}
                views={['month']}
                {...props}
                components={{
                    toolbar: CustomHeader,
                    month: {
                        dateHeader: (props) => (
                            <div className={styles.dayContent}>
                                <div className={styles.dateBadge}>
                                    {props.label}
                                </div>
                                <div className={styles.price}>{price && `€${price}`}</div>
                            </div>
                        ),
                    }
                }}
            />
        </div>
    )
}