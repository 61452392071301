import React, { useEffect, useState } from "react";
import TextInput from "../../Components/CustomComponents/TextInput";
import TextBox from "../../Components/CustomComponents/TextBox";
import './styles/event_banner.css';
import API from "../../api/API";
import Keys from "../../Constants/helper";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";

const initialState = {
  banner_label: {
    value: '',
    error: false,
    errorMessage: 'Please fill this label',
  },
  banner_heading: {
    value: '',
    error: false,
    errorMessage: 'Please fill this label',
  },
  banner_body: {
    value: '',
    error: false,
    errorMessage: 'Please fill this label',
  },
  banner_link: {
    value: '',
    error: false,
    errorMessage: 'Please fill this label',
  },
  banner_picture: {
    file: null,
    error: false,
    errorMessage: 'Please upload a picture',
  }
}

export const EventBanner = () => {
  const [bannerData, setBannerData] = useState(initialState);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  let { successText, changingConfirmed, changeContinuePopup } = useBottomPopup();

  useEffect(() => {
    API(Keys.JWT_TOKEN_ADMIN).get('/admin/landing/banner')
      .then(res => {
        if (res.data) {
          setImagePreviewUrl(res?.data?.banner_picture)
          setBannerData(prevState => ({
            ...prevState,
            banner_body: {
              ...prevState.banner_body,
              value: res.data.banner_body || '',
            },
            banner_heading: {
              ...prevState.banner_heading,
              value: res.data.banner_heading || '',
            },
            banner_label: {
              ...prevState.banner_label,
              value: res.data.banner_label || '',
            },
            banner_link: {
              ...prevState.banner_link,
              value: res.data.banner_link || '',
            },
            banner_picture: {
              ...prevState.banner_picture,
              value: res.data.banner_picture || '',
            },
          }));
        }
      });
  }, []);

  const onImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setBannerData(prevState => ({
        ...prevState,
        banner_picture: {
          ...prevState.banner_picture,
          file: file,
          error: false,
        }
      }));
      setImagePreviewUrl(URL.createObjectURL(file));
    }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const { banner_label, banner_body, banner_link, banner_heading } = bannerData
    const submitData = banner_label.value && banner_body.value && banner_link.value && banner_heading.value

    if (submitData) {
      formData.append('banner_label', bannerData.banner_label.value);
      formData.append('banner_heading', bannerData.banner_heading.value);
      formData.append('banner_body', bannerData.banner_body.value);
      formData.append('banner_link', bannerData.banner_link.value);
      if (bannerData.banner_picture.file) {
        formData.append('banner_picture', bannerData.banner_picture.file);
      }

      API(Keys.JWT_TOKEN_ADMIN).post('/admin/landing/banner', formData)
        .then(res => {
          changeContinuePopup()
          setTimeout(() => {
            changeContinuePopup()
          }, 2000)
        })
        .catch(err => {
          console.log(err)
        });
    } else {
      Object.entries(bannerData).forEach(item => {
        if (!item[1].value) {
          setBannerData(state => ({
            ...state,
            [item[0]]: { ...state[item[0]], error: true }
          }))
        }
      })
    }
  }

  const onImageRemove = () => {
    setImagePreviewUrl(null)
    setBannerData(prevState => ({
      ...prevState,
      banner_picture: {
        ...prevState.banner_picture,
        value: null,
      },
    }));
  }

  return (
    <>
      <ChangedConfirmedPopup
        successText={successText}
        changingConfirmed={changingConfirmed}
      />
      <form className="admin_content_wrapper" onSubmit={onSubmitHandler}>
        <div className="banner_wrapper">
          <h1>Event Banner</h1>
          <div className="mb-3">
            <p>Banner Label</p>
            <TextInput
              value={bannerData?.banner_label.value}
              error={bannerData?.banner_label.error}
              maxLength={255}
              errTitle={bannerData?.banner_label.errorMessage}
              onChange={({ target }) => {
                setBannerData(prevState => ({
                  ...prevState,
                  banner_label: {
                    ...prevState.banner_label,
                    value: target.value,
                    error: false,
                  }
                }));
              }}
            />
          </div>
          <div className="mb-3">
            <p>Banner Heading</p>
            <TextInput
              value={bannerData?.banner_heading.value}
              error={bannerData?.banner_heading.error}
              maxLength={255}
              errTitle={bannerData?.banner_heading.errorMessage}
              onChange={({ target }) => {
                setBannerData(prevState => ({
                  ...prevState,
                  banner_heading: {
                    ...prevState.banner_heading,
                    value: target.value,
                    error: false,
                  }
                }));
              }}
            />
          </div>
          <div className="mb-3">
            <p>Banner Body</p>
            <TextBox
              borderRadius="7px"
              type="text"
              rows="3"
              margin="0 0 1.5rem"
              value={bannerData?.banner_body.value}
              error={bannerData?.banner_body.error}
              errTitle={bannerData?.banner_body.errorMessage}
              onChange={({ target }) => {
                setBannerData(prevState => ({
                  ...prevState,
                  banner_body: {
                    ...prevState.banner_body,
                    value: target.value,
                    error: false,
                  }
                }));
              }}
            />
          </div>
          <div className="mb-3">
            <p>Button Link</p>
            <TextInput
              value={bannerData?.banner_link.value}
              error={bannerData?.banner_link.error}
              maxLength={255}
              errTitle={bannerData?.banner_link.errorMessage}
              onChange={({ target }) => {
                setBannerData(prevState => ({
                  ...prevState,
                  banner_link: {
                    ...prevState.banner_link,
                    value: target.value,
                    error: false,
                  }
                }));
              }}
            />
          </div>
          <p>Upload Picture</p>
          {imagePreviewUrl ? (
              <div className="image_preview" style={{position: 'relative'}}>
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    top: 0,
                    left: 0,
                    zIndex: 1
                  }}
                />
                <label
                  onClick={onImageRemove}
                  className="purple_button"
                  htmlFor="post_photo"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                  }}
                >
                  Remove
                </label>
              </div>
            ) :
            <div className="photo_placeholder">
              <label className="purple_button" htmlFor="post_photo">
                Upload Picture
              </label>
              <input
                type="file"
                id="post_photo"
                onClick={({target}) => target.value = ''}
                accept="image/jpeg,image/png, .jpeg,.jpg,.png"
                onChange={onImageUpload}
              />
            </div>
          }
        </div>
        <div className="bottom_wrapper">
          <button type="submit" className="purple_button">
            Submit
          </button>
        </div>
      </form>
    </>
  )
}
