import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AdminRoutes from "../AdminPages/AdminRoutes";
import Keys from "../Constants/helper";

const PrivateRouteAdmin = ({component: Component, ...rest}) => {
    let responseComponent;
    const userToken = localStorage.getItem(Keys.JWT_TOKEN_ADMIN);

    if (userToken) {
        responseComponent = props => (<AdminRoutes {...props} />);
    } else responseComponent = () => <Redirect to='/admin'/>;

    return <Route {...rest} render={responseComponent}/>;
};

export default PrivateRouteAdmin;