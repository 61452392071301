import {Col, Row, Typography} from 'antd';
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import * as Yup from "yup";

import API from "../../api/API";

import StyledButton from "../../Components/ui/StyledButton";
import StyledInput from "../../Components/ui/StyledInput";
import StyledCalendar from "../../Components/ui/StyledCalendar";

import styles from './CalendarCustomization.module.css';

export default function CalendarCustomization() {
    const {id} = useParams();
    const history = useHistory();
    const [data, setData] = useState();

    const {errors, values, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: data || {id},
        validationSchema: Yup.object({
            min_nights: Yup.number().min(1).required('Input minimum nights, please.'),
            max_nights: Yup.number().min(1).required('Input maximum nights, please.'),
            days_between_bookings: Yup.number().min(1).required('Input the days between booking, please.'),
            availability_window: Yup.number().min(1).required('Input availability window, please.'),
            daily_price: Yup.number().min(1).required('Input the daily price, please.'),
            monthly_price: Yup.number().min(1).required('Input the monthly price, please.'),
        }),
        enableReinitialize: true,
        onSubmit: (values) => {
            API().put('/user/listing/availability', values)
                .then(() => {
                    history.goBack();
                })
        }
    })

    useEffect(() => {
        API().get(`/user/listing/availability?id=${id}`)
            .then((res) => setData(res.data))
    }, [])

    return (
        <div className={styles.root}>
            <StyledCalendar
                price={data?.daily_price}
                selectable
            />
            <form action="" onSubmit={handleSubmit}>
                <div className={styles.aside}>
                    <Row gutter={20} justify="space-between">
                        <Col>
                            <Typography.Title level={4}>Availability</Typography.Title>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row gutter={[8, 16]} justify="space-between">
                        <Col span={12}>
                            <StyledInput
                                type="number"
                                status={errors.min_nights ? 'error' : null}
                                name="min_nights"
                                label="Minimum nights"
                                value={values.min_nights}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12}>
                            <StyledInput
                                type="number"
                                status={errors.max_nights ? 'error' : null}
                                name="max_nights"
                                label="Maximum nights"
                                value={values.max_nights}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={24}>
                            <StyledInput
                                type="number"
                                status={errors.days_between_bookings ? 'error' : null}
                                name="days_between_bookings"
                                label="Time between bookings (nights)"
                                value={values.days_between_bookings}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={24}>
                            <StyledInput
                                type="number"
                                status={errors.availability_window ? 'error' : null}
                                name="availability_window"
                                label="Availability window"
                                value={values.availability_window}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <div>
                        <Typography.Title level={4}>Price</Typography.Title>
                        <Row gutter={[16, 16]} justify="space-between">
                            <Col span={24}>
                                <StyledInput
                                    type="number"
                                    status={errors.daily_price ? 'error' : null}
                                    name="daily_price"
                                    label="Price per night (€)"
                                    value={values.daily_price}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col span={24}>
                                <StyledInput
                                    type="number"
                                    status={errors.monthly_price ? 'error' : null}
                                    name="monthly_price"
                                    label="Price per month (€) | minimum 28 days"
                                    value={values.monthly_price}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.asideActions}>
                        <StyledButton block onClick={handleSubmit} loading={isSubmitting}>
                            Save
                        </StyledButton>
                        <StyledButton block color="transparent" onClick={history.goBack}>
                            Cancel
                        </StyledButton>
                    </div>
                </div>
            </form>
        </div>
    )
}