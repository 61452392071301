import {Col, Row} from "antd";
import {useSelector} from "react-redux";

import useResize from "../../../Constants/useResize";

import TextInput from "../../CustomComponents/TextInput";
import StyledButton from "../../ui/StyledButton";
import StyledCheckbox from "../../ui/StyledCheckbox";
import StyledDatePicker from "../../ui/StyledDatePicker";
import ShowMoreLess from "../../ui/ShowMoreLess";
import IncrementDecrementInput from "../../ui/IncrementDecrementInput";
import NewRangeSliderMultiple from "../../CustomComponents/NewRangeSliderMultiple";

import styles from './ExperiencesFilters.module.css';
import {places} from "../../profile/ListingForm/ListingForm.constants";

export default function ExperiencesFilters({filters, onChangeFilters, onClear}) {
    const {amenities} = useSelector(state => state.listings);
    const {innerWidth} = useResize();

    const handleChangePrice = (name, e) => {
        onChangeFilters((p) => ({...p, [name]: e.target.value || null}))
    }

    const handleChange = (name, value) => () => {
        onChangeFilters((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangePriceRange = ({value}) => {
        onChangeFilters((prev) => ({
            ...prev,
            daily_price_from: value.min,
            daily_price_to: value.max,
        }))
    }

    const handleChangeMultiple = (name, value) => () => {
        onChangeFilters((prev) => ({
            ...prev,
            [name]: prev[name].indexOf(value) >= 0 ? prev[name].filter((i) => i !== value) : [...prev[name], value]
        }))
    }

    return (
        <div className={styles.root}>
            {innerWidth < 992 && (
                <>
                    <div className={styles.inner}>
                        <div className={styles.label}>Dates:</div>
                        <StyledDatePicker
                            selectsRange
                            monthsShown={2}
                            placeholderText="Dates"
                            selectsDisabledDaysInRange
                            selected={filters.date_from}
                            startDate={filters.date_from}
                            endDate={filters.date_to}
                            onChange={onChangeFilters}
                        />
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.label}>Guests:</div>
                        <IncrementDecrementInput
                            content={`${filters.guests_num || 1} guest`}
                            onChange={(v) => onChangeFilters((p) => ({...p, guests_num: v}))}
                            value={filters.guests_num || 1}
                        />
                    </div>
                </>
            )}
            <div className={styles.inner}>
                <div className={styles.label}>Sort by:</div>
                <StyledCheckbox
                    checked={filters.sort_by_type === 'asc'}
                    onClick={handleChange('sort_by_type', 'asc')}
                >
                    Price asc
                </StyledCheckbox>
                <StyledCheckbox
                    checked={filters.sort_by_type === 'desc'}
                    onClick={handleChange('sort_by_type', 'desc')}
                >
                    Price desc
                </StyledCheckbox>
            </div>

            <div className={styles.inner}>
                <div className={styles.label}>Rental type:</div>
                <StyledCheckbox
                    checked={!!filters.daily_rentable}
                    onClick={(e) => handleChange('daily_rentable', e.target.checked ? 1 : undefined)()}
                >
                    Daily
                </StyledCheckbox>
                <StyledCheckbox
                    checked={!!filters.monthly_rentable}
                    onClick={(e) => handleChange('monthly_rentable', e.target.checked ? 1 : undefined)()}
                >
                    Monthly
                </StyledCheckbox>
            </div>

            <div className={styles.inner}>
                <div className={styles.label}>Place type:</div>
                <ShowMoreLess>
                    <div className={styles.list}>
                        {places.map((type) => (
                            <StyledCheckbox
                                key={type}
                                checked={filters.place_types.includes(type)}
                                onClick={handleChangeMultiple('place_types', type)}
                            >
                                {type}
                            </StyledCheckbox>
                        ))}
                    </div>
                </ShowMoreLess>
            </div>

            <div className={styles.inner}>
                <div className={styles.label}>Amenities</div>
                <ShowMoreLess>
                    <div className={styles.list}>
                        {amenities.map((amenity) => (
                            <StyledCheckbox
                                key={amenity}
                                checked={filters.amenities.includes(amenity)}
                                onClick={handleChangeMultiple('amenities', amenity)}
                            >
                                {amenity}
                            </StyledCheckbox>
                        ))}
                    </div>
                </ShowMoreLess>
            </div>

            <div className={styles.inner}>
                <div className={styles.label}>Price, € / night</div>

                <Row justify="space-between" className={styles.inputWrapper}>
                    <Col span={6}>
                        <TextInput
                            title="Min"
                            placeholder={0}
                            value={filters.daily_price_from}
                            onChange={(e) => handleChangePrice('daily_price_from', e)}
                        />
                    </Col>
                    <Col span={6}>
                        <TextInput
                            title="Max"
                            placeholder={filters.daily_price_to}
                            value={filters.daily_price_to}
                            onChange={(e) => handleChangePrice('daily_price_to', e)}
                        />
                    </Col>
                </Row>

                <NewRangeSliderMultiple
                    step={200}
                    value={{
                        min: filters.daily_price_from,
                        max: filters.daily_price_to
                    }}
                    setValue={handleChangePriceRange}
                    max_price={1000}
                />
            </div>

            <StyledButton
                color="white"
                className={styles.clearButton}
                onClick={onClear}
            >
                Clear filters
            </StyledButton>
        </div>
    )
}