import React from 'react';
import ReactDOM from 'react-dom';
import MainRouter from './routers/MainRouter';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './main-store/store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConfigProvider } from 'antd';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
        <ConfigProvider theme={{ hashed: false }}>
            <MainRouter />
        </ConfigProvider>
    </Elements>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
