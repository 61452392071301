import React, {useMemo} from 'react'
import '../styles/highcharts.css'
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';

const MyStockChart = ({data, COLOR}) => {
    const renderPieData = useMemo(() => {
        if (data.length) {
            return data.filter(i => {
                i.value = i.y
                i.name = i.title
                return i
            })
        }
    }, [data])

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div className={'pie_wrapper'}>
            <div className={'pie_wrapper2'}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={200} height={200}>
                        <Pie
                            data={renderPieData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {renderPieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLOR[index % COLOR.length]}/>
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default MyStockChart
