import React from 'react';
import {XAxis, LineChart, Line, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';

const CustomTooltip = (props) => {
    let toolTipEvents = props.data?.find(i => i.date === props.label);
    return toolTipEvents ?
        <div className="customizeToolTip customizeToolTipAvgDate">
            <span className="date">{toolTipEvents?.total_score}</span>
            {/*<span className="name">{toolTipEvents[toolTipEvents.length - 1].name}</span>*/}
            {/*<span className="city">{toolTipEvents[toolTipEvents.length - 1].country}</span>*/}
        </div>
        : null;
};

const CustomizedDot = (props) => {
    const {cx, cy} = props;
    return (
        <svg width="17" x={cx - 10} y={cy - 10}
             cursor={'pointer'}
             height="18" viewBox="0 0 17 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect y="9" width="12" height="12" transform="rotate(-45 0 9)" fill="black"/>
        </svg>
    )
}
const CustomizedDotDisable = () => {
    return (
        <div style={{display: 'none'}}>
        </div>
    )
}

const SimpleChart = ({data, innerWidth}) => {
    return (
        <ResponsiveContainer width={innerWidth < 600 ? 600 : '99%'} height={400}>
            <LineChart width={500}
                       height={300}
                       data={data}
                       margin={{
                           top: 12,
                           right: 20,
                           left: -12,
                           bottom: 5
                       }}>
                <CartesianGrid vertical={false}/>
                <XAxis dataKey="date"/>
                <YAxis width={80}/>
                <Tooltip content={<CustomTooltip data={data}/>}/>
                <Line activeDot={<CustomizedDot/>}
                      strokeWidth={2}
                      dot={null}
                      dataKey="total_score"
                      stroke="#000"/>
                <Line strokeWidth={2}
                      activeDot={<CustomizedDotDisable/>}
                      dot={false}
                      dataKey="avg"
                      stroke="#ccc"/>
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SimpleChart