import React from 'react';
import {currencies} from "../Constants/currencies";
import {Modal, Select} from "antd";

const unitList = [
    {value: "centimetres", label: "Centimetres"},
    {value: "inches", label: "Inches"}
]

const RegionalSettingsPopup = ({visible, closePopup, currency, setCurrency, units, setUnits}) => {

    return (
        <Modal width={250} visible={visible} title={"Settings"} onCancel={closePopup} footer={false}>
            <div className="regional-settings-wrapper">
                <span>Currency</span>
                <Select
                    style={{marginBottom: "24px"}}
                    onChange={setCurrency}
                    options={currencies}
                    value={currency}
                />
                <span>Display Units</span>
                <Select
                    onChange={setUnits}
                    options={unitList}
                    value={units}
                />
            </div>
        </Modal>
    );
};

export default RegionalSettingsPopup;
