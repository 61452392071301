import React from 'react';
import NewContactUs from "../../../Components/NewContactUs";
import SEO from "../../../Components/SEO";
export default function ArtAdvisoryServices() {
    return (
        <div className={'answer_from_footer contact_advisory'}>
            <SEO title="Art Advisory Services: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Art Advisory Services: Buy art online Artsted"/>
            <h1>Art Advisory Services</h1>

            <p>Free art advisory services at Artsted are managed by a team of Art History and Art Business graduates
                whose mission is to promote and kickstart the careers of international emerging artists. Our advisors
                will provide tailor-made solutions to suit your specific requests, existing collection, taste
                preferences, available space and budget.
                Fill out the contact form and we will be in touch shortly!
            </p>
            <div className={'contact_us_info_footer'}>
                <NewContactUs/>
            </div>
        </div>
    )
}