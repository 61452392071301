import React from 'react'
import '../styles/not_verified_user.css'
// import NotVerifiedIcon from '../../assets/img/not_verified_icon.svg'
// import arrowRightWhite from '../../assets/img/arrow_right_white.svg'
// import closeButton from '../../assets/img/close_verified_icon.svg'
// import Keys from "../Constants/helper";

export default function NotVerifiedUser({setActiveVerifiedPopup, setVerificationPopup}) {
    return (
        <></>
        // <div className={'not_verified_wrapper'}>
        //     <div onClick={() => {
        //         setActiveVerifiedPopup(false)
        //         // sessionStorage.setItem(Keys.VERIFIED_POPUP, JSON.stringify(false));
        //     }} className={'not_verified_close_modal'}>
        //         <img src={closeButton} alt="close button"/>
        //     </div>
        //     <div className={'not_verified_content'}>
        //         <img src={NotVerifiedIcon} alt="not verified icon"/>
        //         <p>Your Profile is not verified. <br/>Please confirm your identity!</p>
        //     </div>
        //     <span onClick={() => {
        //         setActiveVerifiedPopup(false)
        //         setVerificationPopup(true)
        //     }}>
        //         <span>Verify me right now</span>
        //         <img src={arrowRightWhite} alt="arrrow right"/>
        //     </span>
        // </div>
    )
}