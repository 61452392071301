import React from 'react'

const InternationalTransfers = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>6. INTERNATIONAL TRANSFERS</h3>
            <div className={'privacy_policy_item_container'}>
                <span>
                    Some of our external third parties are based outside the European Economic Area ( EEA) so
                            their processing of your personal data will involve a transfer of data outside the
                            EEA.
                    <br/>
                    Whenever we transfer your personal data out of the EEA, we ensure a similar degree of
                            protection is afforded to it in accordance with data protection laws. Some examples of
                            how we might do this are:
                    <br/>
                    The transfer of your personal data is to countries that have been deemed to provide
                                an adequate level of protection for personal data by the European Commission. For
                                further details, see European Commission: Adequacy of the protection of personal
                                data in non-EU countries.
                    <br/>
                    The transfer uses specific contracts approved by the European Commission which give
                                personal data the same protection it has in Europe. For further details, see
                                European Commission: Model contracts for the transfer of personal data to third
                                countries .
                    <br/>
                    Where we use providers based in the US, the transfer of your personal data is to a
                                provider with Privacy Shield certification. For further details, see European
                                Commission: EU-US Privacy Shield.
                    <br/>
                    Please Contact us if you want further information on the specific mechanism used by us
                            when transferring your personal data out of the EEA.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Identity Data</span>
                <span>
                    includes first name, last name, username and ID, title, date of birth and gender
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Contact Data</span>
                <span>
                    includes billing address, delivery address, email address and telephone number.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Financial Data</span>
                <span>
                    includes payment card details and bank account (if you are a Seller).
                        We do
                        not
                        store or process your card details ourselves, they are processed and stored via one of our
                        contracted third party service providers. We encrypt your payment card details in your
                        browser
                        and securely transfer this data to our relevant third party payment provider to process a
                        payment.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Transaction Data</span>
                <span>
                    includes details about payments to and from you and other details
                        of
                        products
                        and services you have purchased from us or our Sellers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Technical Data</span>
                <span>
                    includes internet protocol (IP) address, your login data, browser
                        type and
                        version, time zone setting and location, browser plug-in types and versions, operating
                        system
                        and platform and other technology on the devices you use to access this website.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Profile Data</span>
                <span>
                    includes your username and password, purchases or orders made by you,
                        your
                        interests, artists / artworks / users you like / follow / dislike, preferences, a link to
                        your
                        Facebook account if you register using Facebook Connect, feedback and survey responses.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Messaging Data</span>
                <span>
                    includes messages sent to or received from Sellers and Artsted staff,
                        including
                        use of our Make an Offer and Commission an Artist features.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Usage Data</span>
                <span>
                    includes information about how you use our website, products and
                        services.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Marketing and Communications Data</span>
                <span>
                    includes your preferences in receiving marketing
                        from us
                        and
                        our third parties and your communication preferences.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Seller Application Data</span>
                <span>
                    includes any details provided when applying to become a
                        Seller.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                    We also collect, use and share Aggregated Data such as statistical or demographic data for any
                        purpose. Aggregated Data may be derived from your personal data but is not considered personal
                        data in law as this data does not directly or indirectly reveal your identity. For example, we
                        may aggregate your Usage Data to calculate the percentage of users accessing a specific website
                        feature. However, if we combine or connect Aggregated Data with your personal data so that it
                        can directly or indirectly identify you, we treat the combined data as personal data which will
                        be used in accordance with this Privacy Policy
                </span>
                <span>
                    We do not ordinarily collect any Special Categories of Personal Data about you (this includes
                        details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                        orientation, political opinions, trade union membership, information about your health and
                        genetic and biometric data). Nor do we collect any information about criminal convictions and
                        offences. If you use any of these types of personal data when using any of the website's
                        features (including messaging system), in your public artist or user bio or when selling
                        artworks through Artsted, we will not be able to tell. We will treat all the personal data
                        included in your user or artist profile or artwork listings as ordinary personal data
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>IF YOU FAIL TO PROVIDE PERSONAL DATA</span>
                <span>
                    Where we need to collect personal data by law, or under the terms of a contract we have with you
                        and you fail to provide that data when requested, we may not be able to perform the contract we
                        have or are trying to enter into with you (for example, to provide you with goods or services).
                        In this case, we may have to cancel a product or service you have with us but we will notify you
                        if this is the case at the time.
                </span>
            </div>
        </div>
    )
}

export default InternationalTransfers