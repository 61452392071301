import * as types from "./types";

export const setAuthVariant = value => ({
    type: types.AUTH_VARIANT,
    payload: value
});

export const setAuthPopUp = value => ({
    type: types.AUTH_POPUP,
    payload: value
});

export const setUserData = value => ({
    type: types.USER_NAME,
    payload: value,
});

export const setUserRegistrationData = value => ({
    type: types.USER_REGISTRATION_DATA,
    payload: value
});

export const setActiveHistory = value => ({
    type: types.ACTIVE_HISTORY,
    payload: value
});

export const setUserCategories = (action) => ({
    type: types.USER_CATEGORIES,
    payload: action,
})

export const setUserSubscription = (action) => ({
    type: types.USER_SUBSCRIPTION,
    payload: action,
})

export const setCartCount = (action) => ({
    type: types.CART_COUNT,
    payload: action,
})

export const setActiveUpload = (action) => ({
    type: types.ACTIVE_UPLOAD,
    payload: action,
})

export const setCanCreate = (action) => ({
    type: types.CAN_CREATE,
    payload: action,
})

export const setNewHeaderBucket = (action) => ({
    type: types.CART_STATUS,
    payload: action,
})

export const setCurrency = (action) => ({
    type: types.CURRENCY,
    payload: action,
})

export const setUnits = (action) => ({
    type: types.UNITS,
    payload: action,
})

export const setCurrencies = (action) => ({
    type: types.CURRENCIES,
    payload: action,
})

export const setFromApp = (action) => ({
    type: types.FROM_APP,
    payload: action
})
