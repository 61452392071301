import {RECENT_VIEWED, RECENT_VIEWED_LIKED, RECENT_VIEWED_ARRAY} from './actions'
import Keys from "../../Constants/helper";

const initialState = {
    recentViewList: localStorage.getItem(Keys.RECENT_VIEW) ?
        JSON.parse(localStorage.getItem(Keys.RECENT_VIEW)) : []
}

const recentView = (item, list) => {
    if (list.length) {
        if (list.some(e => e.id === item.id)) {
            return list
        } else if (list.length >= 4) {
            list[list.length - 1] = item;
            return list
        } else {
            return [...list, item]
        }
    } else {
        return [item]
    }
}

const recentViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECENT_VIEWED :
            let recentList = recentView(action.payload, state.recentViewList);
            localStorage.setItem(Keys.RECENT_VIEW, JSON.stringify(recentList));
            return {...state, recentViewList: recentList};
        case RECENT_VIEWED_ARRAY :
            localStorage.setItem(Keys.RECENT_VIEW, JSON.stringify(action.payload));
            return {...state, recentViewList: action.payload};
        case RECENT_VIEWED_LIKED :
            let id = action.payload;
            let likedArray = state.recentViewList
            likedArray.forEach(e => {
                if (id === e.id) {
                    e.liked = !e.liked
                }
            })
            localStorage.setItem(Keys.RECENT_VIEW, JSON.stringify(likedArray));
            return {...state, recentViewList: [...likedArray]}
        default :
            return state
    }
}

export default recentViewReducer;
