import React from 'react';
import SEO from "../../../Components/SEO";

export default function Press() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Press: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Press: Buy art online Artsted"/>
            <h1>Press</h1>
            <div className={'products_services_terms recent_article_press'}>
                <span>Recent Articles:</span>
                <p><a
                    rel="noopener noreferrer nofollow"
                    href={'https://hudsonweekly.com/interview-with-maryna-rybakova-a-female-entrepreneur-behind-the-disruptive-artsted-project/'}>Interview
                    With Maryna Rybakova, a Female Entrepreneur Behind the Disruptive Artsted Project | The Hudson
                    Weekly</a></p>
                <p><a
                    rel="noopener noreferrer nofollow"
                    href={'https://www.artsmanagement.net/Articles/Art-marketing-for-artists-A-Visual-Artist-s-Guide-to-Online-Promotion,4061'}>Art
                    marketing for artists - A Visual Artist’s Guide to Online Promotion (artsmanagement.net)</a></p>
                <p><a
                    rel="noopener noreferrer nofollow"
                    href={'https://www.artsmanagement.net/Articles/Artists-as-activists-Art-in-Pandemic-Times,4272/'}>Artists
                    as activists - Art in Pandemic Times (artsmanagement.net)</a></p>
                <p><a rel="noopener noreferrer nofollow"
                      href={'https://www.artrevealmagazine.com/artsted/'}>Artsted: the online art market company
                    focusing on the benefits for artists - Art Reveal online contemporary art magazine
                    (artrevealmagazine.com)</a></p>
            </div>

        </div>
    )
}
