import {Avatar, Breadcrumb, Col, Row, Space, Typography} from "antd";
import {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import Slider from "react-slick";

import API from "../../../api/API";
import Keys from "../../../Constants/helper";
import {defaultBase64Image} from "../../../Constants/images";

import GoogleMap from "../../../Components/GoogleMap";
import StyledTabs from "../../../Components/ui/StyledTabs";
import StyledButton from "../../../Components/ui/StyledButton";
import AdminExperienceBookings from "../../Components/AdminExperienceBookings";

import {ChevronLeft, ChevronRight} from "../../../assets/icons";

import styles from './AdminExperienceView.module.css';

export default function AdminExperienceView() {
    const history = useHistory();
    const {id, type} = useParams();
    const [data, setData] = useState({});
    const [tab, setTab] = useState('details');
    const [loading, setLoading] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <ChevronRight/>,
        prevArrow: <ChevronLeft/>,
        afterChange: (currentSlideIndex) => setCurrentSlide(currentSlideIndex)
    };

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    useEffect(() => {
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/listing/${type}`, {
            params: {id}
        }).then((res) => setData(res.data))
    }, [type, id]);

    const handleActivate = () => {
        setLoading(1);
        API(Keys.JWT_TOKEN_ADMIN).put('/admin/listing/stay', {
            id,
            is_active: true
        })
            .then(() => history.push('/admin/experience'))
            .finally(() => setLoading(true))
    }

    const handleDeactivate = () => {
        setLoading(2);
        API(Keys.JWT_TOKEN_ADMIN).put('/admin/listing/stay', {
            id,
            is_active: false
        })
            .then(() => history.push('/admin/experience'))
            .finally(() => setLoading(true))
    }

    const handleRemove = () => {
        setLoading(3);
        API(Keys.JWT_TOKEN_ADMIN).delete('/admin/listing/stay', {params: {id}})
            .then(() => history.push('/admin/experience'))
            .finally(() => setLoading(true))
    }

    return (
        <div className="admin_content_wrapper">
            <div className="admin_content_wrapper_users">
                <Row gutter={[12, 12]} justify="space-between">
                    <Col>
                        <Breadcrumb
                            items={[
                                {title: <Link to="/admin/experience">Experience</Link>},
                                {title: data.name},
                            ]}
                        />
                    </Col>
                    <Col>
                        <StyledTabs
                            className={styles.tabs}
                            items={[
                                {key: 'details', label: 'Details'},
                                {key: 'bookings', label: 'Bookings'},
                            ]}
                            onChange={setTab}
                        />
                    </Col>
                </Row>
                <div className={styles.inner}>
                    {tab === 'details' && (
                        <>
                            <div className={styles.topContent}>
                                <div className={styles.images}>
                                    <Slider {...settings}>
                                        {data.photos?.length > 0 ? data.photos?.map((photo) => (
                                            <div className={styles.image} key={photo.id}>
                                                <img src={`${process.env.REACT_APP_API_URL}/${photo.path}`} alt=""/>
                                            </div>
                                        )) : <img src={defaultBase64Image} alt=""/>}
                                    </Slider>
                                    {data.photos?.length > 0 && (
                                        <div className={styles.slides}>
                                            <span>{currentSlide + 1}</span>/{data.photos?.length}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.topContentInfo}>
                                    <Space direction="vertical" size={16}>
                                        <Space size={16}>
                                            <Avatar src={`${process.env.REACT_APP_API_URL}/${data.owner_avatar}`}/>
                                            {data.owner_full_name}
                                        </Space>
                                        <h2 className={styles.title}>
                                            {data.name}
                                        </h2>
                                        <Space align="center" size={24}>
                                            {data.daily_price &&
                                                <div className={styles.price}><b>{data.daily_price} Eur.</b> night
                                                </div>}
                                            {data.daily_price && data.monthly_price &&
                                                <div className={styles.priceDivider}/>}
                                            {data.monthly_price &&
                                                <div className={styles.price}><b>{data.monthly_price} Eur.</b> month
                                                </div>}
                                        </Space>
                                        <div className={styles.address}>{data.address}</div>
                                    </Space>
                                    <Row gutter={16}>
                                        <Col xs={8} md={7} xxl={5}>
                                            <StyledButton
                                                block
                                                color="pink"
                                                variant={data.is_active ? "outlined" : "contained"}
                                                loading={loading === 1}
                                                {...(!data.is_active && {
                                                    onClick: handleActivate
                                                })}
                                            >
                                                Active
                                            </StyledButton>
                                        </Col>
                                        <Col xs={8} md={7} xxl={5}>
                                            <StyledButton
                                                block
                                                color="pink"
                                                loading={loading === 2}
                                                variant={!data.is_active ? "outlined" : "contained"}
                                                {...(data.is_active && {
                                                    onClick: handleDeactivate
                                                })}
                                            >
                                                Deactivate
                                            </StyledButton>
                                        </Col>
                                        <Col xs={8} md={7} xxl={5}>
                                            <StyledButton
                                                block
                                                color="pink"
                                                variant="contained"
                                                onClick={handleRemove}
                                                loading={loading === 3}
                                            >
                                                Remove
                                            </StyledButton>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Features:</Typography.Title>
                                <Row gutter={[24, 24]}>
                                    <Col span={6}>
                                        <div className={styles.featureTitle}>
                                            place area:
                                        </div>
                                        <div className={styles.featureValue}>
                                            {data.area_sq_ft} sq. ft.
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={styles.featureTitle}>
                                            max.people:
                                        </div>
                                        <div className={styles.featureValue}>
                                            {data.guests_num_max}
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={styles.featureTitle}>
                                            baths:
                                        </div>
                                        <div className={styles.featureValue}>
                                            {data.baths_num}
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={styles.featureTitle}>
                                            rooms:
                                        </div>
                                        <div className={styles.featureValue}>
                                            {data.rooms_num}
                                        </div>
                                    </Col>
                                    {data.other_infos?.map((i) => (
                                        <Col span={6} key={i.id}>
                                            <div className={styles.featureTitle}>
                                                {i?.name}
                                            </div>
                                            <div className={styles.featureValue}>
                                                {i.value}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Description</Typography.Title>
                                <div>{data.description}</div>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Amenities</Typography.Title>
                                <Row gutter={[16, 16]}>
                                    {data.amenities?.map((a) => (
                                        <Col span={6} key={a}>
                                            <div className={styles.amenities}>
                                                {a}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Location</Typography.Title>
                                <Typography.Text underline className={styles.location}>
                                    {data.address}
                                </Typography.Text>
                                <div className={styles.mapWrapper}>
                                    <GoogleMap address={data.address}/>
                                </div>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Contacts</Typography.Title>
                                <Space direction="vertical" size={16}>
                                    <Space size={24}>
                                        <div className={styles.contacts}>E-mail:</div>
                                        <div className={styles.contactsValue}>{data.owner_email}</div>
                                    </Space>
                                    <Space size={24}>
                                        <div className={styles.contacts}>Phone number:</div>
                                        <div className={styles.contactsValue}>{data.owner_phone}</div>
                                    </Space>
                                </Space>
                            </div>
                            <div>
                                <Typography.Title level={3} className={styles.subtitle}>Cancellation policy</Typography.Title>
                                <div className={styles.preWrap}>{data.cancellation_policy?.text}</div>
                            </div>
                        </>
                    )}
                    {tab === 'bookings' && (
                        <AdminExperienceBookings/>
                    )}
                </div>
            </div>
        </div>
    )
}