import React from 'react';
import TextInput from "../CustomComponents/TextInput";
import success from "../../assets/img/tick-circle-green.svg";
import error from "../../assets/img/danger-red.svg";
import API from "../../api/API";
import MainButton from "../CustomComponents/MainButton";

const PromoCode = ({shippingDetails, setShippingDetails, noApply}) => {

    const checkPromo = () => {
        API().post("/user/payment/check_promo_code", {promo_code: shippingDetails.promocode.value})
            .then(res => {
                console.log(res)
                setShippingDetails(prevState => ({
                    ...prevState,
                    promocode: {
                        ...prevState.promocode,
                        discount: res.data.active || 0,
                        status: res.data.active ? "success" : "error"
                    }
                }))
            })
    }

    const changePromo = ({target}) => {
        setShippingDetails(prevState => ({
            ...prevState,
            promocode: {
                ...prevState.promocode,
                value: target.value,
                status: null,
            },
        }))
    }

    return (
        <div className={'user_cart_promocode'}>
            <div className="promo-row">
                <TextInput value={shippingDetails.promocode.value}
                           error={shippingDetails.promocode.error}
                           errTitle={shippingDetails.promocode.errorMessage}
                           title={'Promo code'}
                           onChange={changePromo}/>
                {!noApply && (
                    <MainButton onClick={checkPromo}
                                className={'button_bordered'}
                                text={"Apply"}
                                type={'button'}/>
                )}
            </div>
            {shippingDetails.promocode.status && <span
                className={"promocode-status " + shippingDetails.promocode.status}>
               <img
                   src={shippingDetails.promocode.status === 'success' ? success : error}
                   alt={shippingDetails.promocode.status}/>
                {shippingDetails.promocode.status === 'success'
                    ? "The promo code you entered is applied"
                    : "The promo code you entered is invalid. Please try a different one."}
           </span>}
        </div>
    );
};

export default PromoCode;
