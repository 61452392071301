import {Dropdown, Menu} from "antd";
import React, {useEffect, useState} from "react";
import './styles/new_header.css';
import logo from '../assets/img/new_landing/artsted_logo.svg'
import logoMobile from '../assets/img/new_landing/artsted_logo_mobile.svg'
import {Link} from "react-router-dom";
import {
    setActiveUpload,
    setAuthPopUp,
    setAuthVariant,
    setNewHeaderBucket,
    setUserData
} from "../main-store/auth/actions";
//setCartCount
import {useDispatch, useSelector} from "react-redux";
import HeaderAuthPopup from "./HeaderAuthPopup";
import Keys from "../Constants/helper";
import {bodyOverflow} from "../Constants/bodyOverflow";
import EmailSubscriptionPopup from "./EmailSubscriptionPopup";
import useResize from "../Constants/useResize";
import OutsideClickHandler from "../Constants/detectOutsideClick";
import API, {userLogout} from "../api/API";
import HeaderSearchWrapper from "./HeaderSearchWrapper";
import MobileMenu from "./MobileMenu";
import artist_avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import investor_avatar_default from "../assets/img/new_landing/investor_avatar_default.svg";
//import cart_icon from "../assets/img/new_landing/cart_icon.svg";
import {useHistory} from 'react-router-dom';
import ModalWindow from "./ModalWindow";
import MainButton from "./CustomComponents/MainButton";

export default function NewHeader(props) {
    const {innerWidth} = useResize();
    const history = useHistory();
    const reduxState = useSelector(state => ({
        auth_variant: state.auth.auth_variant,
        auth_popup: state.auth.auth_popup,
        user_data: state.auth.user_data,
        cart_length: state.auth.cart_length,
        cart_status: state.auth.cart_status,
        can_create: state.auth.can_create
    }));

    const dispatch = useDispatch();

    const [activeSearchBox, setActiveSearchBox] = useState(false);
    const [activeBurger, setActiveBurger] = useState(false);
    const [showUploadWork, setShowUploadWork] = useState(false);
    const userStore = useSelector(store => store.auth.user_data)
    const [activeSearch, setActiveSearch] = useState({
        value: '',
        popup: false,
        options: {},
        loading: false,
    });
    const [emailSubscribePopup, setEmailSubscribePopup] = useState(false);
    const [activeProfilePopup, setActiveProfilePopup] = useState(false);
    const [freshUserPhoto, setFreshUserPhoto] = useState(null);
    const [mobilePremiumExpanded, setMobilePremiumExpanded] = useState(false);

    const pathname = window.location.pathname
    const settingsHistoryPush = () => history.push("/profile/settings#delivery_info")

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN)) {
            API().get(`/user/basket`)
                .then(res => {
                    dispatch(setNewHeaderBucket(res.data.artworks.length))
                })
        } else if (!localStorage.getItem('artstedBucketList')) {
            dispatch(setNewHeaderBucket(0))
        } else {
            dispatch(setNewHeaderBucket(localStorage.getItem('artstedBucketList')?.length))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
            const condition = !localStorage.getItem(Keys.JWT_TOKEN) && !localStorage.getItem('emailSubscriptionPopup') && !localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION) && !localStorage.getItem(Keys.FROM_APP)
            if (condition) {
                setTimeout(() => {
                    setEmailSubscribePopup(true)
                    bodyOverflow(true)
                }, 15000)
            } else {
                setEmailSubscribePopup(false)
            }
            if (reduxState.user_data?.avatar) {
                API().get(`/user/profile/info`)
                    .then(res => {
                            if (res?.data?.photo) {
                                setFreshUserPhoto(res.data.photo)
                            }
                        }
                    )
                    .catch(err => console.log(err))
            }
        // eslint-disable-next-line
        }, [])

    useEffect(() => {
        if (activeSearch.value.length >= 2) {
            let request = `/landing/search?name=${activeSearch.value}`
            if(JSON.parse(localStorage.getItem(Keys.FROM_APP))) {
                request += "&show_allowed_artworks=1"
            }
            API().get(request)
                .then(res => {
                        if (res.data) {
                            setActiveSearch(state => ({
                                ...state,
                                options: res.data,
                                loading: true,
                            }))
                        }
                    }
                )
                .catch(e => console.log(e))
        }
    }, [activeSearch.value])

    const searchLabelLayout = () => (
        <div className={'search_label_layout'}>
            <OutsideClickHandler callback={() => {
                if (!activeSearch.popup) {
                    setActiveSearchBox(false)
                }
                setActiveSearch(prevState => ({
                    ...prevState,
                    popup: false,
                }))
            }}>
                <div>
                    <label onClick={() => setActiveSearchBox(true)}
                           className={`search_container ${activeSearchBox ? 'search_container_active' : ''}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0)">
                                <path
                                    d="M11.1768 10.25L11.1202 10.3199L11.1838 10.3836L15.7338 14.9335L15.8045 14.8628L15.7338 14.9335C15.9554 15.1551 15.9554 15.5132 15.7338 15.7348C15.6233 15.8453 15.4784 15.9008 15.3332 15.9008C15.188 15.9008 15.0431 15.8453 14.9326 15.7348L10.3826 11.1848L10.3189 11.1212L10.249 11.1778C9.17732 12.0454 7.81599 12.5675 6.33328 12.5675C2.89654 12.5675 0.1 9.77096 0.1 6.33423C0.1 2.89749 2.89651 0.100977 6.33325 0.100977C9.76999 0.100977 12.5665 2.89752 12.5665 6.33426C12.5665 7.81697 12.0444 9.1783 11.1768 10.25ZM1.23333 6.33423C1.23333 9.14677 3.52074 11.4342 6.33328 11.4342C9.1458 11.4342 11.4332 9.14677 11.4332 6.33423C11.4332 3.52168 9.14583 1.23428 6.33328 1.23428C3.52074 1.23428 1.23333 3.52168 1.23333 6.33423Z"
                                    fill={'rgba(0, 0, 0, .4)'} stroke="#F6F6F1" strokeWidth="0.2"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <input type="text"
                               value={activeSearch.value}
                               onChange={({target}) => {
                                   setActiveSearch(prevState => ({
                                       ...prevState,
                                       value: target.value,
                                       popup: target.value.length >= 2,
                                   }))
                               }}
                               onClick={() => {
                                   setActiveSearch(prevState => ({
                                       ...prevState,
                                       popup: activeSearch.value.length >= 2,
                                   }))
                               }}
                               placeholder={'Search...'}/>
                    </label>
                </div>
                {activeSearch.popup && activeSearch.value.length >= 2
                    ? <HeaderSearchWrapper setAuthPopUp={e => dispatch(setAuthPopUp(e))}
                                           setActiveSearch={e => setActiveSearch(e)}
                                           activeSearch={activeSearch}/> : null}
            </OutsideClickHandler>
        </div>
    )

    const addressActionClick = ({key}) => {
        switch (key) {
            case "premium":
                history.push("/premium")
                break;
            case "collectors-hub":
                history.push("/collectors-hub")
                break;
            default:
                break;
        }
    };

    const addressActionsMenu = () => (
        <Menu onClick={(e) => addressActionClick(e)}>
            <Menu.Item key="premium">
                Premium Features
            </Menu.Item>
            <Menu.Item key="collectors-hub">
                Collectors Hub
            </Menu.Item>
        </Menu>
    );

    const navigationLinksLayout = (mobile) => {
        const closeSidebar = () => {
            if (mobile) {
                bodyOverflow(false)
                setActiveBurger(false)
            }
        }
        return (
            <div className={'navigation_links'}>
                {/*<Link className={'discover-now'} to={'/artworks#page=1'} onClick={closeSidebar}>Discover</Link>*/}
                <Link className={pathname.includes("/artworks") && !pathname.includes("/artworks/nft") ? "active-link" : ""} onClick={closeSidebar} to={'/artworks'}>Artworks</Link>
                <Link className={pathname.includes("/artists") ? "active-link" : ""} to={'/artists'} onClick={closeSidebar}>Artists</Link>
                <div className="nft_nav_container">
                    <a className={pathname.includes("/experiences") ? "active-link" : ""} href="https://artistbnb.artsted.com/" onClick={closeSidebar}>ArtistBnB</a>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_4278_14306)">
                            <path d="M7.67203 22.74L12.0854 20.1267L16.3187 22.7133C16.4196 22.7747 16.535 22.8083 16.653 22.8104C16.7711 22.8126 16.8877 22.7834 16.9908 22.7258C17.0938 22.6681 17.1798 22.5841 17.2397 22.4824C17.2997 22.3806 17.3315 22.2648 17.332 22.1467L17.332 1.33333C17.332 0.979711 17.1916 0.640573 16.9415 0.390524C16.6915 0.140476 16.3523 -4.28245e-08 15.9987 -5.82819e-08L7.9987 -4.07973e-07C7.64507 -4.2343e-07 7.30594 0.140475 7.05589 0.390524C6.80584 0.640572 6.66536 0.979711 6.66536 1.33333L6.66536 22.1667C6.66538 22.2843 6.69655 22.3999 6.75569 22.5016C6.81483 22.6034 6.89985 22.6876 7.0021 22.7458C7.10435 22.8041 7.22019 22.8342 7.33785 22.8332C7.45552 22.8322 7.57081 22.8 7.67203 22.74ZM9.8787 7.00667L9.8787 6.26L12.772 4.08667L9.87203 4.08667L9.87203 3.33333L13.9987 3.33333L13.9987 4.08667L11.0987 6.26667L13.9987 6.26667L13.9987 7.01333L9.8787 7.00667ZM13.332 11.2267L13.332 8.87333L12.3387 8.87333L12.3387 11.0067L11.672 11.0067L11.672 8.87333L10.5987 8.87333L10.5987 11.2267L9.93203 11.2267L9.93203 8.12L13.9987 8.12L13.9987 11.22L13.332 11.2267ZM9.89203 16.7533L9.89203 16L12.952 14.9667L9.8787 13.9333L9.8787 13.1867L13.9987 11.8533L13.9987 12.6667L11.0454 13.5467L13.9987 14.56L13.9987 15.3733L11.0454 16.3467L13.9987 17.2333L13.9987 18.0533L9.89203 16.7533Z" fill="#EB4250"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4278_14306">
                                <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                {!JSON.parse(localStorage.getItem(Keys.FROM_APP)) && (
                    mobile
                    ? <div className={`premium-mobile-wrapper ${mobilePremiumExpanded ? "active" : ""}`}>
                        <div onClick={() => setMobilePremiumExpanded(!mobilePremiumExpanded)} className={"premium-head"}>
                            <span>Premium</span>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 8.375L10 14.625L16.25 8.375" stroke="black" strokeWidth="1.5"
                                      strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className={"premium-content"}>
                            <Link onClick={closeSidebar} to="/premium">
                                Premium Features
                            </Link>
                            <Link onClick={closeSidebar} to="/collectors-hub">
                                Collectors Hub
                            </Link>
                        </div>
                    </div>
                    : <Dropdown overlayClassName={"header-premium"} placement={"bottomLeft"} overlay={addressActionsMenu}>
                    <span
                        className={`header-premium-content ${pathname.includes("/premium") || pathname.includes("/collectors-hub") ? "active-link" : ""}`}>
                        Premium
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 8.375L10 14.625L16.25 8.375" stroke="black" strokeWidth="1.5"
                                  strokeLinecap="round"/>
                        </svg>
                    </span>
                </Dropdown>
                )}
                {/*<Link onClick={closeSidebar} to={'/premium'}></Link>*/}
                <a onClick={closeSidebar} href={'https://blog.artsted.com'}>Blog</a>
                {/*<a onClick={closeSidebar} href={'https://blog.artsted.com/curated-collections'}>Collections</a>*/}
                {!JSON.parse(localStorage.getItem(Keys.FROM_APP)) && <div className={'nft_nav_container'}>
                    <Link className={pathname.includes("/artworks/nft") ? "active-link" : ""} onClick={closeSidebar} to={'/artworks/nft'}>NFT</Link>

                </div>}


            </div>
        )
    }

    const avatarWrapperLayout = (avatar, margin, rightPosition) => (
        <OutsideClickHandler callback={() => setActiveProfilePopup(false)}>
            <div className={'user_avatar_wrapper'}>
                <div style={margin ? {margin: '0'} : null}
                     onClick={() => setActiveProfilePopup(true)}
                     className={'user_avatar'}>
                    <img src={avatar}
                         alt="avatar"/>
                </div>
                <div style={rightPosition ? {right: '-2rem'} : null}
                     className={`profile_popup ${activeProfilePopup ? 'profile_popup_active' : ''}`}>
                    <div className={'name_avatar'}>
                        <span>{reduxState?.user_data?.name}</span>
                        <img src={avatar} alt="avatar"/>
                    </div>
                    <Link onClick={() => setActiveProfilePopup(false)}
                          to={(reduxState?.user_data?.role === 'investor' || reduxState?.user_data?.role === 'organization') ? '/profile/about' : '/profile/portfolio'}>{(reduxState?.user_data?.role === 'investor' || reduxState?.user_data?.role === 'organization') ? 'Profile' : 'Portfolio'}</Link>
                    <Link onClick={() => setActiveProfilePopup(false)} to={'/profile/settings'}>Settings</Link>
                    <Link onClick={() => setActiveProfilePopup(false)} to={'/profile/support'}>Support</Link>
                    <div className={'user_logout'} onClick={() => userLogout(true)}>Sign Out</div>
                </div>
            </div>
        </OutsideClickHandler>
    )

    const loginRegisterButtons = (mob) => (
        reduxState.user_data?.role === 'artist' || reduxState.user_data?.role === 'organization' ?
            <>
                {!mob ?
                    <>
                        {avatarWrapperLayout(freshUserPhoto ? freshUserPhoto : (reduxState.user_data?.avatar ?? artist_avatar_default), false, false)}
                        {reduxState?.can_create ?
                            <Link onClick={() => {
                                dispatch(setActiveUpload(true))
                            }}
                                  to={'/profile/portfolio'} className={'header_upload'}>Upload</Link>

                            :
                            <div onClick={() => setShowUploadWork(!showUploadWork)} className={'header-upload-button'}>
                                <span>Upload</span>
                            </div>

                        }
                        {showUploadWork &&
                            <div className={'popup_window_upload_work'} key={316}>
                                <ModalWindow activeModal={showUploadWork}
                                             key={2}
                                             setActiveModal={val => setShowUploadWork(val)}>
                                    <div className={'portfolio_popup_header_container'}>
                                        <h4>You can’t upload artwork</h4>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                            setShowUploadWork(false)
                                            bodyOverflow(false)
                                        }}>
                                            <path d="M1 1L17 17M1 17L17 1" stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                    <p>You have to fill in shipping address.</p>
                                    <div className={'modal-window_buttons'}>
                                        <MainButton type={'button'}
                                                    className={'button_bordered_close_popup'}
                                                    style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                    text={'Cancel'}
                                                    onClick={() => {
                                                        setShowUploadWork(false)
                                                        bodyOverflow(false)
                                                    }}
                                        />
                                        <MainButton type={'button'}
                                                    style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                    className={'button_bordered_fill_in'}
                                                    text={'Fill in'}
                                                    onClick={() => {
                                                        setShowUploadWork(false)
                                                        bodyOverflow(false)
                                                        settingsHistoryPush()
                                                    }}
                                        />
                                    </div>
                                </ModalWindow>
                            </div>
                        }

                    </>
                    : null}
            </>
            : reduxState.user_data?.role === 'investor'
                ? !mob ?
                    <>
                        <Link to={'/cart'} className={'header_cart'}>
                            {reduxState.cart_status === 0 &&
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.86914 10.3333H27.2558C28.0009 10.3333 29.5649 10.3333 30.2094 10.3333C30.2094 10.3333 29.9833 12.5238 29.9091 13.2653L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333Z"
                                        stroke="black" strokeWidth="1.5"/>
                                    <path
                                        d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                        stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                    <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                </svg>
                            }
                            {reduxState.cart_status !== 0 &&
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M29.5857 16.5L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333H23.5"
                                        stroke="black" strokeWidth="1.5"/>
                                    <path
                                        d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                        stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                    <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <circle cx="30" cy="10" r="4" fill="#FF1515"/>
                                </svg>
                            }
                        </Link>
                        {avatarWrapperLayout(freshUserPhoto ? freshUserPhoto : (reduxState.user_data?.avatar ?? investor_avatar_default), true, true)}
                    </>
                    : null
                : <>
                    {innerWidth > 1160 &&
                        <Link to={'/cart'} className={'header_cart'}>
                            {reduxState.cart_status === 0 &&
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.86914 10.3333H27.2558C28.0009 10.3333 29.5649 10.3333 30.2094 10.3333C30.2094 10.3333 29.9833 12.5238 29.9091 13.2653L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333Z"
                                        stroke="black" strokeWidth="1.5"/>
                                    <path
                                        d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                        stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                    <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                </svg>
                            }
                            {reduxState.cart_status !== 0 &&
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M29.5857 16.5L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333H23.5"
                                        stroke="black" strokeWidth="1.5"/>
                                    <path
                                        d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                        stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                    <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <circle cx="30" cy="10" r="4" fill="#FF1515"/>
                                </svg>
                            }
                        </Link>
                    }
                    <button onClick={() => {
                        if (mob) setActiveBurger(false)
                        history.push('/login') /*traba /facebook_email_login*/
                    }} className={'login'}>Login
                    </button>
                    <button onClick={() => {
                        if (mob) setActiveBurger(false)
                        history.push('/registration/type')
                    }} className={'register'}>Register
                    </button>
                </>
    )

    return [
        innerWidth > 1160
            ? <header className={`header_container ${props.showBanner ? "header_container_under_banner" : ""}`} key={0}>
                {/*<div className={'artsted_digital_link'}>*/}
                {/*    <a href={'/'}>Artsted Digital></a>*/}
                {/*</div>*/}
                <Link to={'/'} className={'logo'}>
                    <img src={logo} alt="artsted logo"/>
                </Link>
                {navigationLinksLayout()}
                <div className={`search_login ${activeSearchBox ? 'search_login_active' : ''}`}>
                    {searchLabelLayout()}
                    {!activeSearchBox ? loginRegisterButtons() : null}
                </div>
            </header>
            : <header
                className={`header_container header_container_mobile ${props.showBanner ? "header_container_under_banner" : ""} ${reduxState.user_data?.role !== 'investor' ? 'logout_user' : ''}`}
                key={1}>
                <div
                    className={`mobile_logo_search ${activeSearchBox && innerWidth <= 568 ? 'mobile_logo_search_active' : ''}`}>
                    <Link to={'/'}>
                        <img src={innerWidth > 650 ? logo : logoMobile} alt="artsted logo"/>
                    </Link>
                    {searchLabelLayout()}
                </div>
                {/*{!userStore || localStorage.getItem(Keys.JWT_TOKEN)*/}
                {/*    ? reduxState.user_data?.role === 'investor'*/}
                {/*        ? <Link className={'cart'} to={'/cart'}>*/}
                {/*            <div className={'shop_icon_container'}>*/}
                {/*                <svg width="36" height="36" viewBox="0 0 36 36" fill="none"*/}
                {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                {/*                    <path*/}
                {/*                        d="M8.86914 10.3333H27.2558C28.0009 10.3333 29.5649 10.3333 30.2094 10.3333C30.2094 10.3333 29.9833 12.5238 29.9091 13.2653L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333Z"*/}
                {/*                        stroke="black" strokeWidth="1.5"/>*/}
                {/*                    <path*/}
                {/*                        d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"*/}
                {/*                        stroke="black" strokeWidth="1.5" strokeLinecap="square"/>*/}
                {/*                    <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"*/}
                {/*                          strokeLinejoin="round"/>*/}
                {/*                    <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"*/}
                {/*                          strokeLinejoin="round"/>*/}
                {/*                </svg>*/}
                {/*            </div>*/}
                {/*        </Link> : null : null}*/}
                {// eslint-disable-next-line
                    !userStore || localStorage.getItem(Keys.JWT_TOKEN)
                    // eslint-disable-next-line
                    && reduxState.user_data?.role === 'investor'
                        ? <Link className={'cart'} to={'/cart'}>
                            <div className={'shop_icon_container'}>
                                {reduxState.cart_status === 0 &&
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.86914 10.3333H27.2558C28.0009 10.3333 29.5649 10.3333 30.2094 10.3333C30.2094 10.3333 29.9833 12.5238 29.9091 13.2653L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333Z"
                                            stroke="black" strokeWidth="1.5"/>
                                        <path
                                            d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                            stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                        <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                              strokeLinejoin="round"/>
                                        <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                              strokeLinejoin="round"/>
                                    </svg>
                                }
                                {reduxState.cart_status !== 0 &&
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M29.5857 16.5L29.1091 21.2653C29.0433 21.9233 28.7817 23.6666 28.7817 23.6666C28.2949 23.6666 27.1171 23.6666 26.4558 23.6666H13.7225C13.1058 23.6668 11.5 23.6666 11.5 23.6666C10.71 19.2067 9.75714 14.7743 8.86914 10.3333H23.5"
                                            stroke="black" strokeWidth="1.5"/>
                                        <path
                                            d="M8.86914 10.3333L7.78914 6.00933C7.71691 5.721 7.5 5.00002 7.5 5.00002C7.5 5.00002 6.79305 5.00001 6.49581 5H4.86914"
                                            stroke="black" strokeWidth="1.5" strokeLinecap="square"/>
                                        <path d="M12.8691 29H15.5358" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                              strokeLinejoin="round"/>
                                        <path d="M23.5352 29H26.2018" stroke="black" strokeWidth="1.5" strokeLinecap="square"
                                              strokeLinejoin="round"/>
                                        <circle cx="30" cy="10" r="4" fill="#FF1515"/>
                                    </svg>
                                }
                            </div>
                        </Link> : null}


                <div onClick={() => {
                    bodyOverflow(prevState => !prevState)
                    setActiveBurger(prevState => !prevState)
                }}
                     className={`burger ${activeBurger ? 'burger_crossed' : ''}`}>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </header>,
        reduxState.auth_popup ?
            <HeaderAuthPopup auth_variant={reduxState.auth_variant}
                             {...reduxState}
                             key={2}
                             newHeader={true}
                             auth_popup={reduxState.auth_popup}
                             setUserData={e => dispatch(setUserData(e))}
                             emailSubscribePopup={emailSubscribePopup}
                             setAuthPopUp={e => dispatch(setAuthPopUp(e))}
                             setAuthVariant={e => dispatch(setAuthVariant(e))}/>
            : null,
        emailSubscribePopup &&
        <EmailSubscriptionPopup innerWidth={innerWidth}
                                key={3}
                                setEmailSubscribePopup={e => setEmailSubscribePopup(e)}/>,
        innerWidth <= 1160 ? <MobileMenu key={4}
                                         {...reduxState}
                                         setActiveBurger={val => setActiveBurger(val)}
                                         mobile
                                         showBanner={props.showBanner}
                                         loginRegisterButtons={loginRegisterButtons}
                                         navigationLinksLayout={navigationLinksLayout}
                                         activeBurger={activeBurger}/> : null
    ]
}
