import React, {Component} from 'react';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import API from "../api/API";
import {withRouter} from 'react-router-dom';
import {setAuthPopUp, setAuthVariant, setUserData} from "../main-store/auth/actions";
import {connect} from "react-redux";
import Keys from "../Constants/helper";
import facebook from "../assets/img/facebook_rect.svg"

class Facebook extends Component {
    state = {
        isLoggedIn: true,
        userID: '',
        name: '',
        email: '',
        picture: ''
    }

    responseFacebook = response => {
        console.log(response)
        console.log('accessToken: ', response.accessToken)
        // console.log('accessToken: ', response.registration_end)

        API().post(`/user/oauth?registration=${!!this.props.registration}`, {"token": response.accessToken})
            .then(res => {
                console.log('this.state.email: ', this.state.email)
                console.log('registration_end: ', res.data.registration_end)
                console.log(res.data);
                localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                if (res.data.registration_end) {
                    this.props.history.push({pathname: `/`});
                    this.props.setUserData({
                        name: res.data.name,
                        surname: "",
                        role: res.data.role,
                        avatar: res.data.avatar ? res.data.avatar : null
                    })
                    window.location.pathname = (res.data.role === 'investor' || res.data.role === 'organization') ? '/profile/about' : '/profile/portfolio';
                } else {
                    this.props.setFacebookLogin(true)
                    // this.props.history.push({pathname: `/facebook-email-login`})
                }
            }).catch((err) => {
            if (err.response.status === 400 && err.response.data.error) {
            }
            alert(err.response.data.error)
        })
    }

    componentClicked = (response) => console.log(response)

    render() {
        return (
            <div>
                {this.state.isLoggedIn && !JSON.parse(localStorage.getItem(Keys.FROM_APP)) ?
                    <FacebookLogin
                        appId="288271123755145"
                        fields="name,email,picture"
                        onClick={this.componentClicked}
                        callback={this.responseFacebook}
                        style={this.props.style}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className={"facebook_button"}>
                                <img src={facebook} alt={"Facebook icon"} />
                                <span>Continue with facebook</span>
                            </button>
                        )}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth_variant: state.auth.auth_variant,
    auth_popup: state.auth.auth_popup,
    user_data: state.auth.user_data,
    cart_length: state.auth.cart_length,
})

const putDispatchToProps = {
    setAuthPopUp,
    setAuthVariant,
    setUserData,
}

export default connect(mapStateToProps, putDispatchToProps)(withRouter(Facebook))
