import React, {useState} from 'react';
import "./styles/collectors-hub.css"
import collectors_activities_1 from "../assets/img/collectors_activities_1.png";
import collectors_activities_2 from "../assets/img/collectors_activities_2.png";
import collectors_activities_3 from "../assets/img/collectors_activities_3.png";
import collectors_activities_4 from "../assets/img/collectors_activities_4.png";
import download_collectors_report from "../assets/img/download_collectors_report.png";
import current_members_placeholder from "../assets/img/current-members-placeholder.png";
import about_us_1 from "../assets/img/about_us_1.png";
import about_us_2 from "../assets/img/about_us_2.png";
import about_us_3 from "../assets/img/about_us_3.png";
import TextInput from "../Components/CustomComponents/TextInput";
import regExp from "../Constants/regExp";
import {bodyOverflow} from "../Constants/bodyOverflow";
import API from "../api/API";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import Keys from "../Constants/helper";

const activities = [
    {
        year: 2021,
        title: "Scholarship Grant to Vitaliia Fedorova - 1500$",
        title_url: "https://blog.artsted.com/post/vitaliia-fedorova-a-chat-with-the-artsted-prize-winner",
        description: "Based on historical or psychological data, it acts as the bearer of a certain mentality and culture in a multicultural and generalised environment. It always tries to share in such a way that personal experience is common and common experience is personal.",
        img: collectors_activities_1
    },
    {
        year: 2022,
        title: "Scholarship Grant to XAARCHIVE Studio - 1500$",
        description: "XAARCHIVE STUDIO is an interdisciplinary studio founded in 2020 and concerned with technological development in the fields of Design and Architecture. \n" +
            "The collective was founded in Buenos Aires, Argentina by Nayla Cefaerlii and Sofia Lorenzo - two young artists and designers with an academic background in architecture. \n" +
            "The studio is inclined to study and use software-based design and digital technologies; through data gathering and its management, they aim to reconstruct our relationship with nature and the non-human.",
        img: collectors_activities_2
    },
    {
        year: 2022,
        title: "VOLTA NFT Spotlight Prize won by Salustiano - 2500$",
        title_url: "https://blog.artsted.com/post/salustiano-winner-of-the-artsted-spotlight-prize",
        description: "We collaborated with VOLTA Art Fair to assign an NFT prize during their fair in NYC",
        img: collectors_activities_3
    },
    {
        year: 2023,
        title: "Collectors’ Report",
        description: "Artsted unveils a report that delves into the online art-buying habits of 135 collectors worldwide, leaving no brushstroke untouched.",
        img: collectors_activities_4
    },
]

const currentMemberSettings = {
    infinite: true,
    speed: 500,
    arrows: false,
    dots: true,
    autoplay: true,
    swipeToSlide: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "progressive",
}

const aboutUsSettings = {
    infinite: true,
    speed: 500,
    arrows: true,
    dots: false,
    autoplay: true,
    swipeToSlide: true,
    useTransform: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
            }
        },
    ],
    lazyLoad: "progressive",
}

let initialState = {
    value: '',
    error: false,
    errorMessage: `This field can't be empty`
}

const currentMembers = [
    {
        img: current_members_placeholder,
        text: "We try to collect works that seem visually original in some way, at a time where everything has been seen and done.",
        name: "Gerry Bonetti"
    }
]


const aboutUsData = [
    {
        img: about_us_1,
        title: "Protecting cultural heritage:",
        description: "The Club supports efforts to protect and preserve cultural heritage sites or artifacts, such as through donations or fundraising campaigns."
    },
    {
        img: about_us_2,
        title: "Supporting emerging artists:",
        description: "The Club sponsors scholarships or grants for young artists, providing them with financial support and resources to help further their careers."
    },
    {
        img: about_us_3,
        title: "Promoting art education:",
        description: "The Club could support art education programs or initiatives, such as after-school art classes or artist-in-residence programs."
    }
]

const PLANS = [
    {
        title: "Basic",
        price: "250",
        link: "https://buy.stripe.com/bIYaIk2bTaa46mAdQS",
        features: [
            "Access to monthly Zoom webinars on contemporary art trends and collectible artists",
            "Invitations to quarterly gallery tours and networking events, studio visits, and guided tours",
            "Discounts on art-related products and services",
            "Complimentary Artnet subscription monthly recap"
        ]
    },
    {
        title: "Standard Membership",
        price: "500",
        link: "https://buy.stripe.com/14keYA17Pfuo9yMdQT",
        features: [
            "All benefits of Basic Membership, plus:",
            "Personalized art consultation services",
            "Opportunity to sponsor a scholarship for a promising young artist",
            "Access to exclusive resources and opportunities within the art world"
        ]
    },
    {
        title: "Premium Membership",
        price: "1,000",
        link: "https://buy.stripe.com/14kcQs2bTeqkbGU6os",
        features: [
            "All benefits of Basic Membership, plus:",
            "Invitation to an annual art prize event and the opportunity to vote on the winner",
            "Priority access to limited-capacity events and activities",
            "Discounts on art storage and conservation services"
        ]
    },
]

const Checkmark = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6.83333L5.66667 11.5L15.6667 1.5" stroke="black"
              strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
)


const CollectorsHub = () => {
    const [activeSubscribe, setActiveSubscribe] = useState(initialState)
    const [activeDownload, setActiveDownload] = useState(initialState)
    const [successful, setSuccessful] = useState(null)


    const handleSubscribe = (e) => {
        e.preventDefault();
        if (activeSubscribe.value) {
            if (regExp.email.test(activeSubscribe.value)) {
                API().post(`/landing/subscribe`, {email: activeSubscribe.value})
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessful(true)
                            bodyOverflow(true)
                            setActiveSubscribe(initialState)
                        }
                    })
            } else {
                setActiveSubscribe(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: `Wrong email format`,
                }))
            }
        } else {
            setActiveSubscribe(prevState => ({
                ...prevState,
                error: true,
                errorMessage: `This field can't be empty`,
            }))
        }
    }

    const handleDownload = (e) => {
        e.preventDefault();
        if (activeDownload.value) {
            if (regExp.email.test(activeDownload.value)) {
                API().post(`/public/request_collectors_report`, {email: activeDownload.value})
                    .then((res) => {
                        if (res.status === 200) {
                            setActiveDownload(initialState)
                        }
                    })
            } else {
                setActiveDownload(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: `Wrong email format`,
                }))
            }
        } else {
            setActiveDownload(prevState => ({
                ...prevState,
                error: true,
                errorMessage: `This field can't be empty`,
            }))
        }
    };

    const scrollToAboutUs = () => {
        const yOffset = -50;
        const element = document.getElementById("about-us");
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <div className={"collectors-hub-wrapper"}>
            <div className={"collectors-hero"}>
                <h1>“The Artsted Collectors Hub: more than just a club,
                    a community”</h1>
                <span>
                    “<b>The Artsted Collector's Hub is a global community of art enthusiasts, industry professionals, and passionate collectors</b>. As a member, you'll have access to exclusive events, personalized art consultations, and a network of like-minded individuals. <br/>
                    The hub also supports charitable causes in the art world and helps to foster the growth and success of emerging artists and organizations by sponsoring scholarships, studio grants, and cash or in-kind art prizes. <br/>
                    <b>Discover. Connect. Support.</b>”
                </span>
                <button onClick={scrollToAboutUs}>About Us</button>
                <div className="collectors-shadow"/>
            </div>
            <div className={"collectors-activities"}>
                <h2>PAST AND UPCOMING</h2>
                <span>Supporting the contemporary art scene in a meaningful way.</span>
                {activities.map((activity, index) => {
                    return (
                        <div className={"collectors-activity"} key={index}>
                            <span className={"collectors-activity-year"}>{activity.year}</span>
                            <div className={"collectors-activity-content"}>
                                {activity.title_url
                                    ? <a href={activity.title_url} className={"collectors-activity-title"}
                                         target={"_blank"} rel="noreferrer">
                                        {activity.title}
                                    </a>
                                    : <span className={"collectors-activity-title"}>
                                        {activity.title}
                                    </span>
                                }
                                <span className={"collectors-activity-description"}>
                                    {activity.description}
                                </span>
                            </div>
                            <img src={activity.img} alt={activity.title}/>
                        </div>
                    )
                })}
            </div>
            <div className="collectors-report-wrapper">
                <div className="collectors-report">
                    <div className="collectors-report-content">
                        <h2>Download the 2023 Art Collectors Report Report</h2>
                        <form className="input_form"
                              onSubmit={(e) => handleDownload(e)}>
                            <TextInput value={activeDownload.value}
                                       error={activeDownload.error}
                                       errTitle={activeDownload.errorMessage}
                                       onChange={({target}) => {
                                           setActiveDownload(prevState => ({
                                               ...prevState,
                                               value: target.value,
                                               error: false,
                                           }))
                                       }}
                                       placeholder={"Your E-mail"}/>
                            <button type={'submit'}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M40 0H0V40H40V0ZM19.2976 13H13L20.792 20.1795L13 27.359H19.2976L27.0541 20.1795L19.2976 13Z"
                                          fill="white"/>
                                </svg>
                            </button>
                        </form>
                    </div>
                    <img src={download_collectors_report} alt="download collectors report"/>
                </div>
            </div>
            <div className="current-members">
                <h2>OUR CURRENT MEMBERS</h2>
                {/*<img src={current_members_icon} alt=""/>*/}
                <Slider {...currentMemberSettings}>
                    {currentMembers.map(member => (
                        <div className="slider-member">
                            <div className="slider-member-inner">
                                <img src={member.img} alt=""/>
                                <div className="slider-member-content">
                                    <span className="slider-member-text">{member.text}</span>
                                    <span className="slider-member-name">{member.name}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div id="about-us" className="about-us">
                <h2>ABOUT US</h2>
                <span className="description">Artsted is a contemporary art marketplace that bases its entire operation on the support, dissemination, and communication of artistic research. Collectors Hub was born as a collateral and ethical project of our platform that allows us to establish a community of experts to open an intergenerational dialogue, sharing experiences, advice, suggestions, and our own know-how. Our intention is to have an active community ready to get involved and support financially, ethically, and morally non-profit art projects aimed at the support and growth of individual artists.</span>
                <Slider {...aboutUsSettings}>
                    {aboutUsData.map((i, idx) => (
                        <div className="about-us-slider-elem">
                            <div className={`about-us-slider-inner ${idx % 2 === 0 ? 'reverse-slide' : ''}`}>
                                <img src={i.img} alt=""/>
                                <div className="about-us-content">
                                    <span className="title">{i.title}</span>
                                    <span className="description">{i.description}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            { !JSON.parse(localStorage.getItem(Keys.FROM_APP)) && <div className="choose-plan">
                <h2>CHOOSE YOUR PLAN</h2>
                <div className="plans-list">
                    {PLANS.map(i => (
                        <div className="plan">
                            <h3>{i.title}</h3>
                            <span className="price"><strong>${i.price}/</strong>year</span>
                            <div className="features">
                                {i.features.map(f => (
                                    <div className={"feature"}>
                                        <Checkmark/>
                                        <span>{f}</span>
                                    </div>
                                ))}
                            </div>
                            <a href={i.link} target={"_blank"} rel="noreferrer">Choose plan</a>
                        </div>
                    ))}
                </div>
            </div>}
            <div className={'stay_in_loop_wrapper'}>
                <div className={'stay_in_loop_bg'}>
                    <div className="stay_in_loop_left">
                        <h3>SIGN UP</h3>
                        <div className={'successful_email'}>
                            {successful
                                ? <span>You succesfully subscribed to newsletter!</span>
                                : <p>Join the forefront of conscious art collecting</p>}
                        </div>
                        <form onSubmit={(e) => handleSubscribe(e)}>
                            <label>
                                {activeSubscribe.error ?
                                    <span>{activeSubscribe.errorMessage}</span> : null}
                                <input style={activeSubscribe.error
                                    ? {border: '1px solid #fe3e3e'} : null}
                                       onChange={({target}) => {
                                           setActiveSubscribe(prevState => ({
                                               ...prevState,
                                               error: false,
                                               value: target.value
                                           }))
                                       }}
                                       value={activeSubscribe.value}
                                       type="text"
                                       placeholder={'Your E-mail'}/>
                                <button type={'submit'}>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M40 0H0V40H40V0ZM19.2976 13H13L20.792 20.1795L13 27.359H19.2976L27.0541 20.1795L19.2976 13Z"
                                              fill="white"/>
                                    </svg>
                                </button>
                            </label>
                        </form>
                    </div>
                    <div className="stay_in_loop_right">
                        <h3>UNSURE?</h3>
                        <div className={'successful_email'}>
                            <p>We are here to help</p>
                        </div>
                        <Link to={"/premium#contact_us"}>Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectorsHub;
