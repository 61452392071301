import React, {useEffect, useState} from "react";
import '../Profile/styles/new_portfolio_investor.css'
import API from "../../api/API";
import MainButton from "../../Components/CustomComponents/MainButton";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import NewPaginationView from "../../Components/NewPaginationView";
import {getActivePage} from "../../Constants/getActivePage";
import {Loader} from "../../Components/Loader";
import ArtworkInfoCard from "../../Components/ArtworkInfoCard";

export default function NewInvestorPortfolio() {
    const {activeHashUrl} = useGetLastChartHash();

    const [activeArtistTab, setActiveArtistTab] = useState('last_purchased');
    const [profileData, setProfileData] = useState(null);
    const [orderBy, setOrderBy] = useState({
        options: ['price_asc', 'price_desc'],
        value: 'price_asc',
        popup: false,
    });
    const [activePage, setActivePage] = useState(() => ({
        page: activeHashUrl ? activeHashUrl : 1,
        pages: null,
        total: null
    }));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API().get(`/user/profile/portfolio?category=${activeArtistTab}&order_by=${orderBy.value}&page=1&per_page=20`)
            .then(res => {
                // console.log(res)
                res.data.artworks.forEach(el => {
                    el.price = {
                        ...el.price,
                        value: 'last_month',
                        options: ['last_month', 'all_time', 'last_week', 'year'],
                        popup: false,
                    }
                })
                setProfileData(res.data.artworks)
                setActivePage(prevState => ({
                    ...prevState,
                    total: res.data.total,
                    pages: res.data.pages,
                }))
                setLoading(true)
            })
    }, [activeArtistTab, orderBy.value])

    // useEffect(() => {
    //     API().get(`/user/profile/portfolio_statistic`)
    //         .then(res => {
    //             console.log(res)
    //         })
    // }, [])

    return (
        <div className={'main_profile_wrapper_investor'}>
            <div className={'my_portfolio_wrapper'}>
                <h2>My portfolio</h2>
                <div className={'my_portfolio_navigation'}>
                    <div className={'navigation_buttons'}>
                        <MainButton type={'button'}
                                    text={'Last purchased'}
                                    style={activeArtistTab === 'last_purchased' ? {
                                        background: '#000',
                                        color: '#fff'
                                    } : null}
                                    className={'button_black'}
                                    onClick={() => setActiveArtistTab('last_purchased')}
                        />
                        <MainButton type={'button'}
                                    text={'Most viewed'}
                                    className={'button_black'}
                                    style={activeArtistTab === 'most_watched' ? {
                                        background: '#000',
                                        color: '#fff'
                                    } : null}
                                    onClick={() => setActiveArtistTab('most_watched')}
                        />
                    </div>
                    <div className={'sort_by_wrapper'}>
                        <div style={orderBy.popup ? {pointerEvents: 'none'} : null}
                             className={'sort_by'} onClick={() => {
                            setOrderBy(prevState => ({
                                ...prevState,
                                popup: !prevState.popup
                            }))
                        }}>
                            <span>{capitalizeFirstLetter(orderBy.value.split('_').join(' '))}</span>
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 0.111328V1.84757L7.98294 9.88911L0 1.84757V0.111328L7.98294 8.15287L16 0.111328Z"
                                    fill="black"/>
                            </svg>
                        </div>
                        <OutsideClickHandler callback={() => {
                            setOrderBy(prevState => ({
                                ...prevState,
                                popup: false,
                            }))
                        }}>
                            <div className={`popup ${orderBy.popup ? 'popup_active' : ''}`}>
                                {orderBy.options.filter(el => el !== orderBy.value).map((el, i) => (
                                    <span onClick={() => {
                                        setOrderBy(prevState => ({
                                            ...prevState,
                                            popup: false,
                                            value: el,
                                        }))
                                    }} key={i}>{capitalizeFirstLetter(el.split('_').join(' '))}</span>
                                ))}
                            </div>
                        </OutsideClickHandler>
                    </div>
                </div>

                <div className={'my_portfolio_content'}
                     style={!loading ? {
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center'
                     } : !profileData.length ? {gridTemplateColumns: '1fr'} : null}>
                    {loading ?
                        <div className={'masonry_layout'}>
                            {profileData.length ? profileData.map((el, index) => (
                                <ArtworkInfoCard {...el.artwork} priceObject={el.price}
                                                 setData={e => setProfileData(e)}
                                                 activeData={profileData}
                                                 index={index}
                                                 key={el.artwork.id} statistic/>
                            )) : <span className={'no_items_style'}>No items yet</span>}
                        </div>
                        : <Loader color={{
                            '--bgColor': '#000',
                            '--size': '48px',
                            '--wrapper_size': '40px'
                        }}/>}
                </div>
            </div>

            {activePage?.total > 9 ? loading ?
                <div className={'wrapper_new_pagination'}>
                    <NewPaginationView activePage={activePage}
                                       onChange={pageNumber => {
                                           getActivePage(pageNumber)
                                           setActivePage(prevState => ({
                                               ...prevState,
                                               page: pageNumber
                                           }))
                                       }}/>
                </div>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>
                : null}
        </div>
    )
}