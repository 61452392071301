import React from "react";
import './styles/textListSection.css'

const TextListSection = (props) => {

    return (
        <div className={'main_wrapper'}>
            <h1 className={'main_title'}>Our services for professionals</h1>
            {props.items.map((e) => (
                <p className={'item_from_list'}>{e.text}</p>
            ))}

        </div>
    )
}

export default TextListSection
