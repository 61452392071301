import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './styles/new_artist_public.css';
import API from "../api/API";
import ViewAnalytic from "../Constants/viewAnalytic";
import {useLocation, useParams} from "react-router";
import useResize from "../Constants/useResize";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import {Link, useHistory} from "react-router-dom";
import Slider from "react-slick";
import sliderArrowLeft from "../assets/img/new_landing/slider_left_arrow.svg";
import sliderArrowRight from "../assets/img/new_landing/slider_right_arrow.svg";
import ArtworkInfoCard from "../Components/ArtworkInfoCard";
import NewUserInformationWrapper from "../Components/NewUserInformationWrapper";
//import preview_video from "../assets/img/landing_preview_video.png";
import PublicCv from "../Components/CvComponents/PublicCv";
import PublicStatistic from "../Components/PublicStatistic";
import Keys from "../Constants/helper";
import ModalWindow from "../Components/ModalWindow";
import MainButton from "../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../Constants/bodyOverflow";
import SEO from "../Components/SEO";
import userPhoto from "../assets/img/avatar.png";
import ExtendSEO from "../Components/ExtendSEO";
import {useSelector} from "react-redux";
// import {useSelector} from "react-redux";


const ArtworksArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_left'}>
        <img src={sliderArrowLeft} alt="arrow left"/>
    </div>
);

const ArtworksArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_right'}>
        <img src={sliderArrowRight} alt="arrow right"/>
    </div>
);

const initialState = {
    artistsTab: {
        value: 'last_uploads',
        popup: false,
        options: ['last_uploads', 'most_viewed', 'sold'],
    }
}

const NewArtistProfilePublic = () => {
    const location = useLocation();
    const history = useHistory();
    const {id: urlName} = useParams();
    const {innerWidth} = useResize();
    let store = useSelector(store => store.auth.user_data)
    const isOrganization = useMemo(() => store?.role === 'organization', [store?.role])
    const [swiped, setSwiped] = useState(false);
    const [activeSliderTab, setActiveSliderTab] = useState(() => initialState);
    const [activeArtistData, setActiveArtistData] = useState(null);
    const [artworksLoading, setArtworksLoading] = useState(false);
    const [profileSub, setProfileSub] = useState(null)
    const [activeModal, setActiveModal] = useState({
        value: false,
        type: '',
    });
    const [profileInfo, setProfileInfo] = useState({
        loading: false
    });
    // const userStore = useSelector(store => store.auth.user_data)
    const [playVideo, setPlayVideo] = useState({loading: true, play: false})
    const [activeTab, setActiveTab] = useState({
        barChart: {
            options: ['all_time', 'year'],
            value: 'all_time'
        },
        priceChart: {
            options: ['all_time', 'year'],
            value: 'all_time'
        },
    })
    const [cvPreviewData, setCvPreviewData] = useState([
        {
            title: 'Artist CV',
            open: false,
            id: 1,
            preview: 'cv'
        },
        {
            title: 'Artist statistics',
            open: false,
            id: 2,
            preview: 'statistic'
        }
    ])
    let artworksRef = useRef();
    let videoRef = useRef();

    const artistsSettings = {
        infinite: true,
        speed: 500,
        arrows: true,
        dots: false,
        swipeToSlide: true,
        useTransform: false,
        slidesToShow: artworksLoading && (activeArtistData?.artworks?.length >= 5 ? 5 : activeArtistData.artworks.length),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: artworksLoading && (activeArtistData?.artworks?.length >= 4 ? 4 : activeArtistData.artworks.length),
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: artworksLoading && (activeArtistData?.artworks?.length >= 3 ? 3 : activeArtistData.artworks.length),
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: artworksLoading && (activeArtistData?.artworks?.length >= 2 ? 2 : activeArtistData.artworks.length),
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: artworksLoading && (activeArtistData?.artworks?.length ? 1 : activeArtistData.artworks.length),
                    centerMode: true,
                    centerPadding: '45px',
                }
            },
        ],
        prevArrow: <ArtworksArrowLeft/>,
        nextArrow: <ArtworksArrowRight/>,
    }

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    const getUserId = useCallback(() => {
        const nameArr = location.pathname.split('/')[2]?.split('-')
        return nameArr[nameArr.length - 1]
    }, [location.pathname])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        }
    }, [swiped])

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        API().get(`/user/profile/info`)
            .then(res => {
                setProfileSub(res.data.premium)
            })
    }, [])

    useEffect(() => {
        if (!isNaN(Number(getUserId()))) {
            Promise.all([
                API().get(`/public/profile?id=${getUserId()}`).catch(console.log),
                API().get(`/public/profile/statistic?id=${getUserId()}`).catch(console.log),
                API().get(`/public/profile/cv_statistic?id=${getUserId()}`).catch(console.log),
                API().get(`/public/profile/price_statistic?id=${getUserId()}`).catch(console.log)
            ])
                .then(res => {
                    console.log('resresresres: ', res)
                    if (store?.role !== 'artist' && store?.role !== 'investor') {
                        setProfileInfo({
                            loading: true,
                            ...res[0].data,
                        })
                        return
                    }

                    let performanceScore = Object.keys(res?.[2]?.data)?.length ?
                        Object.entries(res[2]?.data)?.map((i, index) => {
                            if (index === Object?.keys(res?.[2]?.data)?.length - 1) {
                                return i?.[1]?.total_score
                            } else {
                                return null
                            }
                        }).filter(Boolean)[0]
                        : 0
                    setProfileInfo({
                        loading: true,
                        ...res[0].data,
                        ...res[1].data,
                        performanceScore,
                        barChart: res[2].data,
                        priceChart: res[3].data
                    })
                })
                .catch(err => {
                    console.log(err)
                })
            ViewAnalytic(`/public/view_user?id=${getUserId()}`)
        } else {
            window.location.pathname = '404'
        }
    }, [getUserId])

    useEffect(() => {
        API().get(`/public/profile/artworks?id=${getUserId()}&category=${activeSliderTab.artistsTab.value}&page=1&pages=10`)
            .then(res => {
                setActiveArtistData(res.data)
                setArtworksLoading(true)
            })
            .catch(err => {
                if (err.response.data.error === "cant find user") {
                    window.location.href = '/404-redirect'
                }
            })
    }, [activeSliderTab.artistsTab.value, getUserId])

    const followArtist = () => {
        API().post(`/user/follow?id=${getUserId()}`)
            .then(() => {
                setProfileInfo(prevState => ({
                    ...prevState,
                    loading: true,
                    user: {
                        ...prevState.user,
                        follow: !prevState.user.follow
                    }
                }))
            })
    }

    const handlePlayVideo = () => {
        videoRef.current.play();
        setPlayVideo(prevState => ({...prevState, play: true}))
    }

    useEffect(() => {
        API().get(`/public/profile/artworks?category=all&id=${getUserId()}&page=1&pages=10`)
            .then(res => {
                console.log("res data for artwork: ", res)
            })
    })
    console.log('profileInfo.loading: ', profileInfo.loading)
    console.log('profileInfo: ', profileInfo)
    console.log('profileInfo.user: ', profileInfo)
    console.log('isOrganization: ', isOrganization)
    return [
        <div key={0} className={'new_artist_public profile_section_body'}>
            {profileInfo.loading ?
                <section className={'home_artists_header_wrapper'}>
                    <SEO title={`Arts by ${profileInfo.user.full_name} | Art investment: Artsted`}
                         image={profileInfo.user.photos?.length ? profileInfo.user.photos[0] : userPhoto}
                         description={`${profileInfo.user.full_name} the artist from Toronto ♦ Buying art as an investment with Art marketplace: Artsted`}>
                        {!profileInfo?.user?.full_name && profileInfo.user.full_name === "null" && (
                            <meta name="robots" content="noindex, nofollow"/>
                        )}
                    </SEO>

                    <NewUserInformationWrapper {...profileInfo}
                                               name={profileInfo.user.full_name}
                                               categories={profileInfo.user.categories}
                                               photo={profileInfo.user.photo}
                                               follow={profileInfo.user.follow}
                                               publicArtist={true}
                                               userRole={profileInfo?.user?.role}
                                               followArtist={followArtist}
                                               profileInfo={profileInfo}
                                               setProfileInfo={e => setProfileInfo(e)}/>
                    <div className={profileInfo.user.video ?
                        'about_artist_wrapper about_video_artist_wrapper' :
                        'about_artist_wrapper'}>
                        <div className={'about'}>
                            <h3>{profileInfo?.user?.role === 'organization' ? 'About Organization' : 'About Artist'}</h3>
                            <p>{profileInfo.user.about_yourself ?? 'No info'}</p>
                        </div>
                        {profileInfo.user.seo_text && (
                            <ExtendSEO
                                title={"Artist SEO"}
                                description={profileInfo.user.seo_text}
                            />
                        )}
                        {profileInfo.user.video ?
                            <div className="video_preview_landing home_video_preview">
                                {playVideo.loading ?
                                    <video controls={playVideo.play ? "controls" : null}
                                           ref={videoRef}
                                           preload="metadata"
                                           onEnded={() => {
                                               setPlayVideo({play: false, loading: false})
                                               setTimeout(() => {
                                                   setPlayVideo(prevState => ({...prevState, loading: true}))
                                               })
                                           }}
                                        /*poster={preview_video}*/>
                                        <source src={profileInfo.user.video + '#t=0.001'}
                                                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                    </video>
                                    : null}
                                {!playVideo.play ?
                                    <div onClick={() => handlePlayVideo()}
                                         className={'play_button'}>
                                        <svg width="20" height="23" viewBox="0 0 20 23" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.79317 21.1043L1.78618 21.1084L1.77926 21.1126C1.7315 21.1414 1.67774 21.1565 1.62342 21.1571C1.5691 21.1576 1.5151 21.1436 1.46686 21.1158C1.41856 21.0879 1.37736 21.0469 1.34838 20.996C1.31937 20.9451 1.30396 20.8867 1.30439 20.8268H1.30442V20.8197L1.30444 1.38247L1.3044 1.37564C1.30395 1.31176 1.32018 1.24936 1.35082 1.19479C1.38143 1.1403 1.42507 1.09597 1.4765 1.06539C1.52785 1.03486 1.58554 1.01886 1.64386 1.01829C1.70113 1.01773 1.75802 1.03207 1.80915 1.0605L18.5126 10.7789C18.5128 10.779 18.513 10.7791 18.5132 10.7792C18.5633 10.8087 18.6061 10.8516 18.6363 10.9044C18.6666 10.9575 18.6829 11.0184 18.6829 11.0809C18.6829 11.1434 18.6666 11.2043 18.6363 11.2574C18.6061 11.3101 18.5634 11.353 18.5133 11.3824C18.5131 11.3826 18.5129 11.3827 18.5126 11.3828L1.79317 21.1043Z"
                                                fill={'transparent'}
                                                stroke="#F6F6F1" strokeWidth="1.94671"/>
                                        </svg>
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                </section>
                : null}
            <section className={'faq_section cv_public_previews'}>
                <div className={'faq_questions_wrapper'}>
                    {profileInfo?.user?.role !== 'organization' &&
                      profileInfo.loading && cvPreviewData.map((el, i) => (
                            <div key={el.id} className={`question_wrap ${el.open ? 'question_wrap_active' : ''}`}
                                 onClick={e => {
                                     if (localStorage.getItem(Keys.JWT_TOKEN)) {
                                         if (e.target.getAttribute('data-key') === 'show_window') {
                                             let openData = cvPreviewData.find(item => item.id === el.id)
                                             openData.open = !openData.open
                                             setCvPreviewData(prevState => ([
                                                 ...prevState.slice(0, i),
                                                 openData,
                                                 ...prevState.slice(i + 1),
                                             ]))
                                         } else {
                                             e.preventDefault()
                                         }
                                     } else {
                                         setActiveModal({
                                             value: true,
                                             type: el.preview,
                                         })
                                     }
                                 }}>
                                <div className={'question_title'} data-key={'show_window'}>
                                    <h3 data-key={'show_window'}>{el.title}</h3>
                                    <div className={`square ${el.open ? 'square_active' : ''}`} data-key={'show_window'}>
                                        <div data-key={'show_window'}/>
                                        <div data-key={'show_window'}/>
                                    </div>
                                </div>
                                {el.open ?
                                  el.preview === 'cv' ?
                                    <PublicCv about_yourself={profileInfo.user.about_yourself}
                                              profileSub={profileSub}
                                              innerWidth={innerWidth}
                                              name={profileInfo.user.full_name}
                                              categories={profileInfo.user.categories}
                                              country={profileInfo.user.country}
                                              cv={profileInfo.cv}
                                              user={profileInfo.user}
                                              photo={profileInfo.user.photo}/> :
                                    el.preview === 'statistic' ?
                                      <PublicStatistic {...profileInfo}
                                                       activeTab={activeTab}
                                                       profileInfo={profileInfo}
                                                       setActiveTab={e => setActiveTab(e)}
                                                       innerWidth={innerWidth}/> :
                                      null
                                  : null}
                            </div>
                          ))
                    }
                </div>
                {/*<MainButton type={'button'}*/}
                {/*            text={showMoreFaq.text ? 'Show less' : 'Show more'}*/}
                {/*            className={'button_black'}*/}
                {/*            onClick={() => {*/}
                {/*                setShowMoreFaq(prevState => ({*/}
                {/*                    count: !prevState.count,*/}
                {/*                    text: !prevState.text*/}
                {/*                }))*/}
                {/*            }}*/}
                {/*/>*/}
            </section>
            {artworksLoading ?
                <section className={'home_works_wrapper'}>
                    <h3>Artworks</h3>
                    <div className={'works_container'}>
                        <div className={'tabs_content'}>
                            <div className={'sort_by_buttons'}>
                                {activeSliderTab.artistsTab.options.map((el, i) => (
                                    <h5 style={el === activeSliderTab.artistsTab.value
                                        ? {color: '#2B2B2E', textDecoration: 'none'} : {color: 'rgba(0, 0, 0, .5)'}}
                                        onClick={() => {
                                            setActiveSliderTab(prevState => ({
                                                ...prevState,
                                                artistsTab: {
                                                    ...prevState.artistsTab,
                                                    value: el
                                                }
                                            }))
                                        }} key={i}>{capitalizeFirstLetter(el.split('_').join(' '))}</h5>
                                ))}
                            </div>
                            {innerWidth > 992 ?
                                <Link className={'view_more_link'}
                                      to={`/artist-profile/${urlName}/gallery`}>View more ></Link> : null}
                        </div>
                        <div className={'works_slider_container slider_container'}>
                            {artworksLoading ? activeArtistData.artworks.length ?
                                <Slider {...artistsSettings}
                                        onSwipe={handleSwiped}
                                        ref={artworksRef}>
                                    {activeArtistData.artworks.map((el, i) => (
                                        <div key={i}
                                             className={'new_artist_public_wrapper_every__item'}
                                             onClickCapture={(e) => handleOnItemClick(e)}>
                                            <ArtworkInfoCard {...el}
                                                             activeData={activeArtistData}
                                                             setActiveData={val => setActiveArtistData(val)}
                                                             nestedArray={'artworks'}
                                                             index={i}/>
                                        </div>
                                    ))}
                                </Slider>
                                : <span>No items</span> : null}
                        </div>
                    </div>
                    {innerWidth <= 992
                        ? <Link className={'view_more_link'}
                                to={`/artist-profile/${urlName}/gallery`}>View more ></Link>
                        : null}
                </section> : null}
        </div>,
        activeModal.value ?
            <div key={3} className={'new_modal_wrapper_profile_popup_width new_modal_wrapper'}>
                <ModalWindow activeModal={activeModal}
                             nestedObj
                             setActiveModal={val => setActiveModal(val)}>
                    <div className={'head'}>
                        <h4>You cannot view the {activeModal.type === 'statistic' ? 'analytics' : 'cv'}</h4>
                        <svg onClick={() => {
                            setActiveModal(prevState => ({
                                ...prevState,
                                value: false,
                            }))
                        }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" fill="#F6F6F1"/>
                            <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                        </svg>
                    </div>
                    <p>Sign up to unlock
                        the {activeModal.type === 'statistic' ? 'analytics' : 'cv'} on {profileInfo.user.full_name}</p>
                    <div className={'modal-window_buttons'}>
                        <MainButton type={'button'}
                                    className={'button_black'}
                                    text={'Cancel'}
                                    onClick={() => {
                                        setActiveModal(prevState => ({
                                            ...prevState,
                                            value: false,
                                        }))
                                        bodyOverflow(false)
                                    }}
                        />
                        <MainButton type={'button'}
                                    className={'button_black'}
                                    style={{background: '#000', color: '#fff'}}
                                    text={'Sign Up'}
                                    onClick={() => history.push('/registration/type')}
                        />
                    </div>
                </ModalWindow>
            </div> : null
    ]
}

export default NewArtistProfilePublic
