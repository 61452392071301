import React from "react";
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    BarChart,
    ResponsiveContainer
} from "recharts";
import useResize from "../Constants/useResize";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import otherConst from "../Constants/otherConst";


export default function NewStatisticArtistActivity({data}) {
    const {innerWidth} = useResize();

    const monthReplace = month => {
        let activeMonth = null;
        otherConst.months.forEach(m => {
            if (m.prev === month) {
                activeMonth = m.val
            }
        })
        return activeMonth
    }

    const activeDate = toolTipEvents => {
        let actData = toolTipEvents.month.split(' ')
        actData[1] = monthReplace(actData[1])
        return actData.join(' ')
    }

    const activeScore = (toolTipEvents, score) => {
        let actScore = Object.keys(toolTipEvents).filter(i => i === score)[0]
        return capitalizeFirstLetter(actScore).replace('_', ' ')
    }

    const CustomTooltip = (props) => {
        let toolTipEvents = props.data?.find(i => i.month === props.label);
        return toolTipEvents ?
            <div className="customizeToolTip">
                <p className="title">{activeDate(toolTipEvents)}</p>
                <div className={'wrapper_events'}>
                    <div className={'every_events'}>
                        <div className={'awards color_block'}/>
                        <span className="date">{activeScore(toolTipEvents, 'awards_score')}</span>
                        <span className="city">{toolTipEvents.awards_score}</span>
                    </div>
                    <div className={'every_events'}>
                        <div className={'museums color_block'}/>
                        <span className="date">{activeScore(toolTipEvents, 'museums_score')}</span>
                        <span className="city">{toolTipEvents.museums_score}</span>
                    </div>
                    <div className={'every_events'}>
                        <div className={'media color_block'}/>
                        <span className="date">{activeScore(toolTipEvents, 'media_score')}</span>
                        <span className="city">{toolTipEvents.media_score}</span>
                    </div>
                    <div className={'every_events'}>
                        <div className={'exhibitions color_block'}/>
                        <span className="date">{activeScore(toolTipEvents, 'exhibitions_score')}</span>
                        <span className="city">{toolTipEvents.exhibitions_score}</span>
                    </div>
                </div>
            </div>
            : null;
    };

    let fakeData = [
        {total_score: 100, month: 'Jan'},
        {total_score: 200, month: 'Feb'},
        {total_score: 300, month: 'Mar'},
        {total_score: 400, month: 'Apr'},
        {total_score: 500, month: 'May'},
        {total_score: 600, month: 'Jun'},
        {total_score: 700, month: 'Jul'},
        {total_score: 800, month: 'Aug'},
        {total_score: 900, month: 'Sep'},
        {total_score: 1000, month: 'Oct'},
        {total_score: 1100, month: 'Nov'},
        {total_score: 1200, month: 'Dec'},
    ]

    // console.log(data)
    return (
        <div id={'statistic_profile_views'} style={{"overflowY": "hidden"}}>
            {!data?.length ?
                <div className="no_data_wrapper">
                    <div className="no_data_container">
                        <span>The artist did not fill in the CV</span>
                    </div>
                </div>
                : null}
            {data?.length ?
                <ResponsiveContainer width={innerWidth < 600 ? 600 : '99%'} height={400}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 40,
                            right: 0,
                            bottom: 0,
                            left: 10
                        }}>
                        <CartesianGrid vertical={false}/>
                        <YAxis dataKey="total_score"/>
                        <XAxis dataKey='month'/>
                        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip data={data}/>}/>
                        {/*<Tooltip cursor={{fill: 'transparent'}}/>*/}
                        <Bar dataKey="exhibitions_score"
                             stackId="a" fill="rgb(54, 52, 52)"/>
                        <Bar dataKey="media_score"
                             stackId="a" fill="rgb(91, 87, 87)"/>
                        <Bar dataKey="museums_score"
                             stackId="a" fill="rgb(109, 145, 153)"/>
                        <Bar dataKey="awards_score"
                             stackId="a" fill="rgb(225, 220, 220)"/>
                    </BarChart>
                </ResponsiveContainer>
                : <ResponsiveContainer width={innerWidth < 600 ? 600 : '99%'} height={400}>
                    <BarChart
                        data={fakeData}
                        margin={{
                            top: 40,
                            right: 0,
                            bottom: 0,
                            left: -20
                        }}>
                        <CartesianGrid vertical={false}/>
                        <YAxis dataKey="total_score"/>
                        <XAxis dataKey='month'/>
                        <Tooltip cursor={{fill: 'transparent'}}/>
                    </BarChart>
                </ResponsiveContainer>}
        </div>
    );
}
