import React, {useCallback, useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import './styles/new_landing.css'
import straightLine from '../assets/img/new_landing/straight_lines.svg'
import curvedLine from '../assets/img/new_landing/curved_lines.svg'
import artist_avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import {Link, useHistory} from "react-router-dom";
import Keys from "../Constants/helper";
import API from "../api/API";
import sliderArrowLeft from "../assets/img/new_landing/slider_left_arrow.svg";
import sliderArrowRight from "../assets/img/new_landing/slider_right_arrow.svg";
import artInsider from "../assets/img/art_insider.png";
import AD from "../assets/img/AD.png";
import artribune from "../assets/img/artribune.png";
import exibart from "../assets/img/exibart.png";
import artnet from "../assets/img/artnet.png";
import ArtworkInfoCard from "../Components/ArtworkInfoCard";
import ArtistInfoCard from "../Components/ArtistInfoCard";
import StayInLoop from "../Components/StayInLoop";
import {connect, useDispatch, useSelector} from "react-redux";
import {setAuthPopUp, setUserRegistrationData} from "../main-store/auth/actions";
import {bodyOverflow} from "../Constants/bodyOverflow";
import useResize from "../Constants/useResize";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import ChangedConfirmedPopup from "../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../Constants/useBottomPopup";
import SEO from "../Components/SEO";
import {Loader} from "../Components/Loader";
import './styles/landing.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {LazyLoadImage} from "react-lazy-load-image-component";
import ExtendSEO from "../Components/ExtendSEO";
import {unitByCurrency} from "../Constants/unitByCurrency";

const initialState = {
    artworksTab: {
        value: 'recent_updates',
        popup: false,
        options: ['recent_updates', 'affordable_pieces', 'most_expensive'],
    },
    experiencesTab: {
        value: 'stays',
        popup: false,
        options: ['studios', 'events', 'stays'],
    },
    artistsTab: {
        value: 'most_popular',
        popup: false,
        options: ['most_popular', 'most_viewed', 'best_performing', 'established'],
    }
}

const ArtworksArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_left'}>
        <img src={sliderArrowLeft} alt="arrow left"/>
    </div>
);
const ArtworksArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_right'}>
        <img src={sliderArrowRight} alt="arrow right"/>
    </div>
);

const headSectionSettings = {
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    arrows: false,
    dots: true,
    swipeToSlide: false,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    loop: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
}


const artistsSettings = {
    infinite: true,
    speed: 500,
    arrows: true,
    dots: false,
    swipeToSlide: true,
    useTransform: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyLoad: "progressive",
    responsive: [
        {
            breakpoint: 1800,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '45px',
            }
        },
    ],
    prevArrow: <ArtworksArrowLeft/>,
    nextArrow: <ArtworksArrowRight/>,
}

const seenOnSettings = {
    infinite: true,
    speed: 250,
    arrows: true,
    dots: false,
    autoplay: true,
    swipeToSlide: false,
    useTransform: false,
    slidesToShow: 4.5,

    slidesToScroll: 1,
    lazyLoad: "progressive",
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3.5,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 2.5,
            }
        }
    ],

    prevArrow: <ArtworksArrowLeft/>,
    nextArrow: <ArtworksArrowRight/>,
}

const seenOnData = [
    {
        img: artInsider,
        url: "https://www.art-insider.com/artsted-com-will-be-the-first-platform-to-offer-its-users-visual-artists-without-specific-blockchain-knowledge-the-possibility-of-minting-authenticated-nfts-of-their-works-creatin/3445"
    },
    {img: AD, url: "https://www.ad-italia.it/article/artsted-larte-di-domani-esalta-le-nuove-tecnologie/"},
    {
        img: artribune,
        url: "https://www.artribune.com/arti-visive/arte-contemporanea/2022/02/artsted-piattaforma-creare-nft/"
    },
    {img: exibart, url: "https://www.exibart.com/mercato/artsted-com-una-piattaforma-per-coniare-nft/"},
    {img: artnet, url: "https://news.artnet.com/market/crypto-presence-art-basel-2022-2130496"}
]

const newItemSettings = {
    infinite: true,
    speed: 500,
    arrows: true,
    dots: true,
    swipeToSlide: true,
    useTransform: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    lazyLoad: "progressive",
    responsive: [
        {
            breakpoint: 1800,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '45px',
            }
        },
    ],
}

const artworksSettings = {
    infinite: true,
    speed: 500,
    arrows: true,
    dots: false,
    slidesToShow: 4,
    useTransform: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    lazyLoad: "progressive",
    responsive: [
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '45px',
            }
        },
    ],
    prevArrow: <ArtworksArrowLeft/>,
    nextArrow: <ArtworksArrowRight/>,
}

// const initialHeadSlide = {
// id: 1,
// photo: sliderImage,
// user: {
//     avatar: artistPhoto,
//     name: 'Ekaterina Belukhina',
//     price: '1100',
//     date: '01.05.2021',
//     id: 12,
// }
// };

function NewLanding({currencies, currency}) {

    const history = useHistory();
    const {innerWidth} = useResize();
    const videoRef = useRef();
    let artworksRef = useRef();
    let artistsRef = useRef();
    const [mouseMoved, setMouseMoved] = useState(false);

    const chooseRoleCards = [
        {
            image: straightLine,
            imageMargin: innerWidth > 568 ? '32px 0 49px' : '0 0 28px',
            title: 'Collector',
            text: 'At Artsted, we provide a selection of high-quality work from world’s best art school students, with daily new arrivals and a bespoke art advisory service.',
            options: ['Build lasting and reliable assets', 'Blockchain technology protection', 'Track your collection’s progress',],
            role: 'I’m a Collector',
        },
        {
            image: curvedLine,
            imageMargin: '16px 0 6px',
            title: 'Artist',
            text: 'On Artsted your creativity is promoted to a target of global collectors. Get visibility, sales, career advice & analytics,with help of cutting edge technology.',
            options: ['Transparent pricing', `Technology to protect your works' provenance`, 'Efficient promotion tools',],
            role: 'I’m an Artist',
        },
        {
            image: curvedLine,
            imageMargin: '16px 0 6px',
            title: 'Organization',
            text: ' Artsted is open to galleries, institutions, non-profits, museums, art residencies, fairs and hybrid spaces.',
            options: ['Access to global network', 'Connection with peer organizations', 'Additional revenue streams',],
            role: 'I’m an Organization',
        },
    ]


    const signDispatch = useDispatch();
    const userRole = useSelector(state => state?.auth?.user_data?.role);
    const [headData, setHeadData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [latestArtworks, setLatestArtworks] = useState(null);
    const [playVideo, setPlayVideo] = useState(false);
    const [activeSliderTab, setActiveSliderTab] = useState(() => initialState);
    const [activeExperiencesData, setActiveExperiencesData] = useState(null);
    const [activeArtworksData, setActiveArtworksData] = useState(null);
    const [adminData, setAdminData] = useState(null);
    const [activeArtistData, setActiveArtistData] = useState(null);
    const [activeBannerData, setActiveBannerData] = useState(null);
    const [swiped, setSwiped] = useState(false);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    const sliderResize = () => {
        artworksRef.current?.slickGoTo(0)
        artistsRef.current?.slickGoTo(0)
    }

    useEffect(() => {
        // todo: badul perepysaty sogodni
        window.addEventListener('load', sliderResize)
        if (artworksRef.current) {
            sliderResize()
        }
        window.addEventListener('resize', sliderResize)

        return () => {
            window.removeEventListener('load', sliderResize)
            window.removeEventListener('resize', sliderResize)
        }
    }, [])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        }
    }, [swiped])

    useEffect(() => {
        let codeUrl = history.location.pathname.split('/').pop();
        if (codeUrl.includes('code')) {
            let token = codeUrl.split('=').pop();
            sessionStorage.setItem(Keys.RESET_PASSWORD_CODE, token.toString())
            signDispatch(setAuthPopUp('New password'))
            bodyOverflow(true)
        }
    }, [history.location.pathname, signDispatch])

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setAdminData(res.data)
                    setHeadData([...res.data.artworks_top].sort(() => 0.5 - Math.random()))
                    setLatestArtworks({
                        loading: true,
                        popular: res.data.popular,
                        top_landing_artworks: res.data.artworks_top
                    })
                    setLoading(true)
                }
            })
    }, [])

    useEffect(() => {
        API().get(`/landing/banner`)
          .then(res => {
              if (res.data) {
                  setActiveBannerData(res.data)
              }
          })
          .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        API().get(`/listing/list?per_page=10&type=${activeSliderTab.experiencesTab.value.replace(/^\w/, l => l?.toUpperCase()).slice(0, -1)}`)
            .then(res => {
                if (res.data) {
                    setActiveExperiencesData({loading: true, list: res.data.listings})
                }
            })
            .catch(err => console.log(err))
    }, [activeSliderTab.experiencesTab.value])

    useEffect(() => {
        API().get(`/landing/artworks?category=${activeSliderTab.artworksTab.value}`)
            .then(res => {
                if (res.data) {
                    setActiveArtworksData({loading: true, list: res.data.artworks})
                }
            })
            .catch(err => console.log(err))
    }, [activeSliderTab.artworksTab.value])

    useEffect(() => {
        API().get(`/landing/artists?category=${activeSliderTab.artistsTab.value}`)
            .then(res => {
                if (res.data) {
                    setActiveArtistData({loading: true, list: res.data.artists})
                }
            })
            .catch(err => console.log(err))
    }, [activeSliderTab.artistsTab.value])


    const handlePlayVideo = () => {
        videoRef.current.play();
        setPlayVideo(true)
    }

    const notRegisteredUser = (e, stop, id) => {
        if (!localStorage.getItem(Keys.JWT_TOKEN)) {
            e.preventDefault();
            if (stop) e.stopPropagation();
            changeContinuePopup('Please sign up to view personal pages')
            setTimeout(() => {
                changeContinuePopup('Please sign up to view personal pages')
                history.push('/registration/type')
            }, 1000)
            bodyOverflow(true)
        } else if (id) {
            history.push(`/artist-profile/${id}`)
        }
    }

    return [
        <SEO key={1} title="Art investment | Buy art online | Artsted"
             description="Artsted is the art marketplace providing transparent and reliable information on prices and evaluations for art investment opportunities."/>,

        <ChangedConfirmedPopup key={2}
                               successText={successText}
                               changingConfirmed={changingConfirmed}/>,

        <div key={3} className={'home_page_wrapper'}>
            <section style={!loading ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : null}
                     className={'head_section'}>
                {loading ?
                    <Slider {...headSectionSettings}>
                        {headData.map((el, i) => {
                            const localizedPrice = currency ? (currencies[currency] * el.user.price).toFixed(0) : el.user.price;
                            const currencySymbol = currency ? unitByCurrency[currency] : "€"
                            return (
                                <div key={i}>
                                    <div style={{backgroundImage: `url(${el.photo})`}}
                                         className={'head_bg_image'}>
                                        <div className={'head_bg_opacity'}>
                                            <div className={'content'}>
                                                <h2>{adminData.main_header}</h2>
                                                <p>{adminData.main_subheader}</p>
                                                <div className={'artist_info'}>
                                                    <button onClick={() => history.push('/artworks')}>Discover now
                                                    </button>
                                                    {innerWidth > 660 ?
                                                        <div className={'artist_info_position'}>
                                                            <h3>Artwork by</h3>
                                                            <div
                                                                // to={`/artist-profile/${el.user.id}`}
                                                                //   onClick={(e) => notRegisteredUser(e)}
                                                                className={'about_artist'}>
                                                                <div className={'avatar'}>
                                                                    <img src={el.user.avatar ?? artist_avatar_default}
                                                                         alt="avatar"/>
                                                                </div>
                                                                <div>
                                                                    <h5>{el.user.name}</h5>
                                                                    <span>{currencySymbol}{localizedPrice}</span>
                                                                    <span>date: {el.user.date}</span>
                                                                </div>
                                                            </div>
                                                        </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    : <Loader color={{
                        '--bgColor': '#000',
                        '--size': '48px',
                        '--wrapper_size': '40px'
                    }}/>}
            </section>
            {!userRole ?
                <section className={'role_choose_wrapper'}>
                    <div className={'cards_container'}>
                        {chooseRoleCards.map((el, i) => (
                            <div className={'card_item'} key={i}
                                 onClick={(e) => {
                                     if (!localStorage.getItem(Keys.JWT_TOKEN)) {
                                         e.preventDefault();
                                         let registrationRole = el.role === 'I’m an Artist' ? 'artist' : 'I’m an Organization' ? 'organization' : 'investor'
                                         signDispatch(setUserRegistrationData({type: registrationRole}))
                                         history.push('/registration/auth')
                                     }
                                 }}>
                                <LazyLoadImage effect={"blur"} style={{margin: el.imageMargin}} src={el.image}
                                               alt={el.role}/>
                                <div className={'content'}>
                                    <h3>{el.title}</h3>
                                    <p>{el.text}</p>
                                    <div className={'options_wrapper'}>
                                        {el.options.map((item, index) => (
                                            <div className={'options'} key={index}>
                                                <div/>
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={'checkmark'}>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect x="1.26667" y="1.26667" width="37.4667" height="37.4667" rx="18.7333"
                                                  stroke="black" strokeWidth="2.53333" fill={'#fff'}/>
                                            <path d="M13.3359 19.9993L18.0026 24.666L28.0026 14.666"
                                                  stroke={'#000'}
                                                  strokeWidth="2.53333" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                        <span>{el.role}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                : null}

            {/*<section className={'home_works_wrapper experiences_wrapper'}>*/}
            {/*    <div className={'home_section_header'}>*/}
            {/*        <h2>ArtistBnB</h2>*/}
            {/*        <p className={'home_section_description'}>*/}
            {/*            Looking for a space? At Artsted you can book a short term stay when travelling for your next*/}
            {/*            exhibition, as well as rent or sublet a studio*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className={'works_container'}>*/}
            {/*        <div className={'tabs_content'}>*/}
            {/*            <div className={'sort_by_buttons'}>*/}
            {/*                {activeSliderTab.experiencesTab.options.map((el, i) => (*/}
            {/*                    <h5*/}
            {/*                        key={i}*/}
            {/*                        style={el === activeSliderTab.experiencesTab.value ? {*/}
            {/*                            color: '#2B2B2E',*/}
            {/*                            textDecoration: 'none'*/}
            {/*                        } : {color: 'rgba(0, 0, 0, .5)'}}*/}
            {/*                        onClick={() => {*/}
            {/*                            setActiveSliderTab(prevState => ({*/}
            {/*                                ...prevState,*/}
            {/*                                experiencesTab: {*/}
            {/*                                    ...prevState.experiencesTab,*/}
            {/*                                    value: el*/}
            {/*                                }*/}
            {/*                            }))*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        {capitalizeFirstLetter(el.split('_').join(' '))}*/}
            {/*                    </h5>*/}
            {/*                ))}*/}
            {/*            </div>*/}
            {/*            {innerWidth > 992 ? (*/}
            {/*                <Link className="view_more_link" to="/experiences/stay">*/}
            {/*                    View more >*/}
            {/*                </Link>*/}
            {/*            ): null}*/}
            {/*        </div>*/}
            {/*        <div className={'works_slider_container slider_container'}>*/}
            {/*            <Slider*/}
            {/*                {...artworksSettings}*/}
            {/*                onSwipe={handleSwiped}*/}
            {/*                ref={artworksRef}*/}
            {/*            >*/}
            {/*                {activeExperiencesData?.loading ? activeExperiencesData.list.length*/}
            {/*                    ? activeExperiencesData.list.map((el, i) => (*/}
            {/*                        <div*/}
            {/*                            key={i}*/}
            {/*                            onClickCapture={(e) => handleOnItemClick(e)}*/}
            {/*                        >*/}
            {/*                            <ExperiencesStayItem*/}
            {/*                                {...el}*/}
            {/*                                activeData={activeArtworksData}*/}
            {/*                                setActiveData={val => setActiveArtworksData(val)}*/}
            {/*                                nestedArray={'list'}*/}
            {/*                                index={i}*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                    )) : <span>No items</span> : null}*/}
            {/*            </Slider>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {innerWidth <= 992 ? (*/}
            {/*        <Link className="view_more_link" to="/experiences/stay">*/}
            {/*            View more >*/}
            {/*        </Link>*/}
            {/*    ) : null}*/}
            {/*</section>*/}

            {activeBannerData?.banner_picture &&
              <section className={'banner_section'}>
                  <div className={'banner_image_wrapper'} style={{backgroundImage: `url(${activeBannerData?.banner_picture})`}}>
                      <div className={'banner_left_wrapper'}>
                          {activeBannerData?.banner_label &&
                            <p className={'banner_label_text'}>{activeBannerData.banner_label}</p>
                          }
                          {activeBannerData.banner_heading &&
                            <p className={'banner_heading_text'}>{activeBannerData.banner_heading}</p>
                          }
                          {activeBannerData.banner_body &&
                            <p className={'banner_body_text'}>{activeBannerData.banner_body}</p>
                          }
                      </div>
                      <div className={'banner_right_wrapper'}>
                          <button onClick={() => window.open(activeBannerData.banner_link, '_blank', 'noopener,noreferrer')}>
                              See All Listings in Roma
                          </button>
                      </div>
                  </div>
              </section>
            }

            {loading ?
              <section className={'home_video_section'}>
                  <h2>{adminData?.post_header}</h2>
                  <div className={'video_wrapper'}>
                      <div className={'video_text_content'}>
                          <h3>{adminData?.post_subheader}</h3>
                          <p>{adminData?.post_text}</p>
                      </div>
                      <div className="video_preview_landing home_video_preview">
                          <video controls={playVideo ? "controls" : null}
                                 ref={videoRef}
                                 preload="metadata"
                                 onEnded={() => {
                                     setLoading(false)
                                     setTimeout(() => {
                                           setLoading(true)
                                       })
                                       setPlayVideo(false)
                                   }}
                                // poster={preview_video}
                            >
                                <source src={adminData?.video_file + '#t=0.001'}
                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            </video>
                            {!playVideo ?
                                <div onClick={() => handlePlayVideo()}
                                     className={'play_button'}>
                                    <svg width="20" height="23" viewBox="0 0 20 23" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.79317 21.1043L1.78618 21.1084L1.77926 21.1126C1.7315 21.1414 1.67774 21.1565 1.62342 21.1571C1.5691 21.1576 1.5151 21.1436 1.46686 21.1158C1.41856 21.0879 1.37736 21.0469 1.34838 20.996C1.31937 20.9451 1.30396 20.8867 1.30439 20.8268H1.30442V20.8197L1.30444 1.38247L1.3044 1.37564C1.30395 1.31176 1.32018 1.24936 1.35082 1.19479C1.38143 1.1403 1.42507 1.09597 1.4765 1.06539C1.52785 1.03486 1.58554 1.01886 1.64386 1.01829C1.70113 1.01773 1.75802 1.03207 1.80915 1.0605L18.5126 10.7789C18.5128 10.779 18.513 10.7791 18.5132 10.7792C18.5633 10.8087 18.6061 10.8516 18.6363 10.9044C18.6666 10.9575 18.6829 11.0184 18.6829 11.0809C18.6829 11.1434 18.6666 11.2043 18.6363 11.2574C18.6061 11.3101 18.5634 11.353 18.5133 11.3824C18.5131 11.3826 18.5129 11.3827 18.5126 11.3828L1.79317 21.1043Z"
                                            fill={'transparent'}
                                            stroke="#F6F6F1" strokeWidth="1.94671"/>
                                    </svg>
                                </div>
                                : null}
                        </div>
                    </div>
                </section>
                : null}

            <section className={'latest_artworks latest_artworks_overflow'}>
                <div className={'title'}>
                    <h2>Popular</h2>
                    {innerWidth > 650 ?
                        <Link className={'view_more_link'}
                              to={'/artworks'}>View more ></Link> : null}
                </div>
                {latestArtworks?.loading && latestArtworks?.popular?.length ?
                    <div>
                        {loading ?
                            <Slider {...newItemSettings}>
                                {latestArtworks.popular.map((el) => {
                                    const localizedPrice = currency ? (currencies[currency] * el.price).toFixed(0) : el.price;
                                    const currencySymbol = currency ? unitByCurrency[currency] : "€"
                                    return (
                                        <Link
                                            onMouseMove={() => setMouseMoved(true)}
                                            onMouseDown={() => setMouseMoved(false)}
                                            onMouseUp={() => {
                                                if (!mouseMoved) {
                                                    history.push(`/public-artwork/${el.id}`);
                                                }
                                            }}
                                            className={'public-artwork-slider'} key={el.id}>
                                            <img
                                                src={el.photo}
                                                alt={`${el.title} - Artwork`}/>
                                            <div className={'popular_image_title_container'}>
                                                <div className={'popular-section-container'}>
                                                    <span>{el.artist.name}</span>
                                                    {el.artist?.premium &&
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z"
                                                                fill="#3473FF"/>
                                                            <path d="M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335"
                                                                  stroke="white" strokeWidth="1.6"
                                                                  strokeLinecap="round"/>
                                                        </svg>
                                                    }
                                                </div>

                                                <span>{el.title}</span>
                                                <span>{currencySymbol}{localizedPrice}</span>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </Slider>
                            : <Loader color={{
                                '--bgColor': '#000',
                                '--size': '48px',
                                '--wrapper_size': '40px'
                            }}/>}
                    </div>
                    : <span>No items</span>}
                {innerWidth <= 650 ?
                    <Link className={'view_more_link'}
                          to={'/artworks'}>View more ></Link> : null}
            </section>

            <section className={'home_video_section seen-on-wrapper'}>
                <h2>As seen on:</h2>
                <div>
                    <Slider {...seenOnSettings}>
                        {seenOnData.map(({img, url}, idx) => (
                            <a href={url} target={"_blank"} rel={"noreferrer"}>
                                <img key={idx} src={img} alt=""/>
                            </a>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className={'home_works_wrapper'}>
                <h2>Artworks</h2>
                <div className={'works_container'}>
                    <div className={'tabs_content'}>
                        <span>Sort by</span>
                        <div className={'sort_by_buttons'}>
                            {activeSliderTab.artworksTab.options.map((el, i) => (
                                <h5 style={el === activeSliderTab.artworksTab.value
                                    ? {color: '#2B2B2E', textDecoration: 'none'} : {color: 'rgba(0, 0, 0, .5)'}}
                                    onClick={() => {
                                        setActiveSliderTab(prevState => ({
                                            ...prevState,
                                            artworksTab: {
                                                ...prevState.artworksTab,
                                                value: el
                                            }
                                        }))
                                    }} key={i}>{capitalizeFirstLetter(el.split('_').join(' '))}</h5>
                            ))}
                        </div>
                        {innerWidth > 992 ?
                            <Link className={'view_more_link'}
                                  to={'/artworks'}>View more ></Link> : null}
                    </div>
                    <div className={'works_slider_container slider_container'}>
                        <Slider {...artworksSettings}
                                onSwipe={handleSwiped}
                                ref={artworksRef}>
                            {activeArtworksData?.loading ? activeArtworksData.list.length
                                ? activeArtworksData.list.map((el, i) => (
                                    <div key={i} onClickCapture={(e) => handleOnItemClick(e)}>
                                        <ArtworkInfoCard {...el}
                                                         activeData={activeArtworksData}
                                                         setActiveData={val => setActiveArtworksData(val)}
                                                         nestedArray={'list'}
                                                         index={i}/>
                                    </div>
                                )) : <span>No items</span> : null}
                        </Slider>
                    </div>
                </div>
                {innerWidth <= 992
                    ? <Link className={'view_more_link'}
                            to={'/artworks'}>View more ></Link>
                    : null}
            </section>

            <section className={'home_artists_wrapper'}>
                <h2>Artists</h2>
                <div className={'artists_navigation'}>
                    <div className={'tabs'}>
                        {activeSliderTab.artistsTab.options.map((el, i) => (
                            <h5 style={el === activeSliderTab.artistsTab.value
                                ? {color: '#000', textDecoration: 'none'} : {color: 'rgba(0, 0, 0, .5)'}}
                                onClick={() => {
                                    setActiveSliderTab(prevState => ({
                                        ...prevState,
                                        artistsTab: {
                                            ...prevState.artistsTab,
                                            value: el
                                        }
                                    }))
                                }} key={i}>{capitalizeFirstLetter(el.split('_').join(' '))}</h5>
                        ))}
                    </div>
                    {innerWidth > 992
                        ? <Link className={'view_more_link'}
                                onClick={(e) => notRegisteredUser(e)}
                                to={'/artists'}>View
                            more ></Link>
                        : null}
                </div>

                <div className={'artists_slider_container slider_container'}>
                    <Slider {...artistsSettings} onSwipe={handleSwiped} ref={artistsRef}>
                        {activeArtistData?.loading ? activeArtistData.list.length
                            ? activeArtistData.list.map((el, i) => (
                                <div key={i} onClickCapture={(e) => handleOnItemClick(e)}>
                                    <ArtistInfoCard {...el}
                                                    changeContinuePopup={changeContinuePopup}
                                                    activeData={activeArtistData}
                                                    index={i}
                                                    role={userRole}
                                                    notRegisteredUser={notRegisteredUser}
                                                    setActiveData={val => setActiveArtistData(val)}/>
                                </div>
                            )) : <span>No items</span> : null}
                    </Slider>
                </div>
                {innerWidth <= 992
                    ? <Link className={'view_more_link'}
                            onClick={(e) => notRegisteredUser(e)}
                            to={'/artists'}>View more ></Link>
                    : null}
                <ExtendSEO/>
            </section>
        </div>,
        <StayInLoop key={4}/>
    ]
}

const mapStateToProps = state => ({
    currency: state.auth.currency,
    currencies: state.auth.currencies,
})

export default connect(mapStateToProps)(NewLanding);
