export const localizeSize = (size, units) => {
    const regex = /\d+/g;

    let temp = size,
        hasW = temp.includes("W"),
        hasH = temp.includes("H"),
        hasD = temp.includes("D"),
        final = [];


    (temp.match(regex) || []).forEach(i => {
        const postfix = hasW ? "W" : hasH ? "H" : hasD ? "D" : "";

        if (hasW) {
            hasW = false
        } else if (hasH) {
            hasH = false
        } else if (hasD) {
            hasD = false
        }

        final.push(`${(i / 2.54).toFixed(0)} ${postfix}`)
    })

    return final.join(" x ") + (units === "inches" ? " inches" : " cm")
}