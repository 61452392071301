import React from 'react';
import vector from "../../assets/img/chevron-down.svg";
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";

const FilterView = (props) => {
    const {
        activeFilter, showFilterPopup, handleDetectOutSide,
        filterList, setActiveFilterFunc, setActiveFilter,
        category, mobile, customHandleClick, setListingData,
        sort_by_output
    } = props;


    const filterView = () => (
        <div className={'every_filter__popup overflow_scrollbar'}>
            {filterList.map((item, index) => (
                <div key={index}
                     onClick={() => {
                         if (customHandleClick) {
                             customHandleClick(item)
                         } else {
                             setActiveFilterFunc(item.name, category)
                             setActiveFilter(null)
                         }
                         setListingData(prevState => ({
                             ...prevState,
                             page: 1
                         }))
                     }}>
                    {sort_by_output ?
                        <span>{item.preview} </span> :
                        [<span key={'name'}>{item.name} </span>,
                            <span className={'numbers_of_listing_grey'} key={'count'}>({item.n})</span>]}
                </div>
            ))}
        </div>
    )

    return (
        <div className={`every_filter ${category} ${activeFilter === category ? 'not_event' : ''}`}>
            <OutsideClickHandler callback={() => activeFilter === category && !mobile && handleDetectOutSide()}>
            <div className={"category_wrapper"} onClick={() => showFilterPopup(category)}>
                <span>{capitalizeFirstLetter(category)}</span>
                <img src={vector}
                     className={`${activeFilter === category ? 'active' : ''}`}
                     alt="vector"/>
            </div>
            {activeFilter === category ?
                    filterView()
                : null}
            </OutsideClickHandler>
        </div>
    )
}

export default FilterView