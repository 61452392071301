import classes from './styles/collections.module.scss';
import Section from './Section';
import Button from './Button';
import Illustration from './Illustration';
import ruoff1 from '../../assets/img/collections/ruoff_1.png';
import ruoff2 from '../../assets/img/collections/ruoff_2.png';
import ruoff3 from '../../assets/img/collections/ruoff_3.png';
import sa1 from '../../assets/img/collections/sunday_art_1.png';
import sa2 from '../../assets/img/collections/sunday_art_2.png';
import sa3 from '../../assets/img/collections/sunday_art_3.png';
import why1 from '../../assets/img/collections/why1.png';
import why2 from '../../assets/img/collections/why2.png';
import why3 from '../../assets/img/collections/why3.png';
import eleonora from '../../assets/img/collections/eleonora_rinaldi.png';
import curated from '../../assets/img/collections/curated.png';
import marta from '../../assets/img/collections/Marta_Longa_pattern.png';
import valeria from '../../assets/img/collections/valeria_abendroth_materia.png';
import paolla from '../../assets/img/collections/paolla_passantino.png';
import logo from '../../assets/img/collections/logo.svg';
import help from '../../assets/img/collections/help.png';
import CollectionsFooter from './CollectionsFooter';
import MainSlider from './Slider';
import Notify from './Notify';
import SEO from "../../Components/SEO";
import React from "react";

function Recomendation(props) {
    return (
        <div className={classes.recomendation_item}>
            <p className={classes.recomendation_item__name}>{props.name}</p>
            <p className={classes.recomendation_item__category}>{props.category}</p>
        </div>
    );
}

function Collections() {
    return (
        <div className={classes.wrapper}>
            <SEO title="Collections: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Collections: Buy art online Artsted"/>
            <MainSlider />
            <Section vReverse>
                <Section.Left>
                    <img className={classes.curated_img} alt='curated' src={curated} />
                </Section.Left>
                <Section.Right>
                    <div className={classes.curated}>
                        <h2>Curated <br /> Collections</h2>
                        <div className={classes.curated_text}>
                            <p>Cover image: Sofia Fioramonti, 2020, € 385</p>
                            <p>All images courtesy of the Artsted's artists</p>
                        </div>
                    </div>
                </Section.Right>
            </Section>
            <Section>
                <Section.Left>
                    <div className={classes.white_block}>
                        “Art enables us to find ourselves and lose ourselves at the same time”
                    </div>
                </Section.Left>
                <Section.Right>
                    <div className={classes.ruoff}>
                        <Button className={classes.btn}> artworks available on Artsted you can find here.</Button>
                        <h3>5 questions for the artist: Aimee Ruoff</h3>
                        <p>Aimee Rouff is one of the newest addition to the Artsted platform and we are gladly presenting her to our public asking her 5 questions so you can get to know her work better.
                            She graduated in 2020 with her BA in Studio Art and she focuses her work on oil painting.
                            Her works describe the quotidian as she paints directly from real life in a very delicate yet impacting way. Aimee seems to use paint in a new, revolutionary way including the defining elements of her generation, therefore the result is incredibly fascinating.
                        </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={ruoff1}
                            text='Aimee Ruoff, Known Fully, Drawing, 2021'
                            price='275'
                        />
                        <h4>How do you find your education shaped you as an artist today?</h4>
                        <p>
                            My mother is an artist as well and I would say my art education started way before school. Since childhood she brought me to a lot of galleries and museums so I was always immersed in this world. And then I did go to an arts high school before attending university. I just graduated in 2020 from my Bachelor’s in Arts. It is still quite fresh but I learned so much throughout my formative years.
                        </p>
                        <h4>How did COVID-19 impact your way of making art?</h4>
                        <p>I think it was a very challenging and disappointing time. Before COVID-19 happened, my plans were different so when the pandemic hit I did not expect to be stuck at home working as an artist full time. I was just stuck at home and this forced me to paint. It ended up being such a blessing because it improved my technical skills in a way that would not happened otherwise.</p>
                        <Illustration
                            className={classes.section_illustration}
                            src={ruoff2}
                            text='Aimee Ruoff, Every Single Chain, Drawing, 2021'
                            price='275'
                        />
                        <h4>‍Who are your all-time favorite artists?</h4>
                        <p>I am so very passionate about art history. Two artists that I would always look back to are Manet and J. M. W. Turner, I love their use of light. Speaking of contemporary art I would say that Mamma Andersson and Peter Doig are among my favorites. I like that they have a kind of spiritual element in their art.</p>
                        <h4>‍What is indispensable when you are in your studio?</h4>
                        <p>‍Having good lighting is essential for me. Working outside, for example, is a huge part in my creative process. Also, tools wise, having a range of brush sizes and a very sharp pencil is also crucial for the way that I paint. </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={ruoff3}
                            text='Aimee Ruoff, Guelph (August 13), Drawing, 2021'
                            price='275'
                        />
                        <h4>‍What is your relationship with social media and how do you use them?</h4>
                        <p>‍I think I have a love and hate relationship with it but it is also great for networking and finding other artists that I like. I sometimes use Reels and Tiktok but I am not as good at keeping up with that. I am thankful for it in the end even though I know it is never going to replace seeing a piece of art in real life.</p>
                    </div>
                </Section.Right>
            </Section>
            <Illustration
                className={classes.main_illustration}
                src={marta}
                text='Marta Longa, pattern, 2021'
                price='880'
            />
            <Section>
                <Section.Left>
                    <img className={classes.recomendation_img} src={paolla} alt='rec_img' />
                </Section.Left>
                <Section.Right>
                    <div className={classes.recomendations}>
                        <Recomendation name='5 questions for the artist: Aimee Ruoff' category='EDITORIAL' />
                        <Recomendation name='5 questions for the artist: Edvige Cecconi Meloni' category='EDITORIAL' />
                        <Recomendation name="Industry Voices: Marta Czyż" category='EDITORIAL' />
                        <Recomendation name="Sunday Art Brunch: from the Pompeii's fast food to the influnce of Britney Spears" category='ART NEWS' />
                    </div>
                    <div className={classes.buy_item}>
                        <p>image left: Paolla Passantino, Ritratto in rosso e blu, 2020</p>
                        <Button>BUY NOW € 1100</Button>
                    </div>
                </Section.Right>
            </Section>
            <Illustration
                className={classes.main_illustration}
                src={valeria}
                text='Valeria Abendroth, Materia, 2020'
                price='3000'
            />
            <Section>
                <Section.Left>
                    <div className={classes.white_block}>
                        'Sunday Art Brunch' - a weekly news digest
                    </div>
                </Section.Left>
                <Section.Right>
                    <div className={classes.sunday_art}>
                        <h3>Christie's new headquarters and Amoako Boafo's artwork in space</h3>
                        <p>
                            Sunday Art Brunch is your weekly appointment with international art news curated by Anna Frattini. In this section you will find art news from across the world from big events to fun pieces about art and the art world.
                        </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={sa1}
                            text='Hans Op de Beeck, Dancer (2021). Courtesy of Galleria Continua'
                        />
                        <p>
                            If you love Hans Op De Beek’s art here is an interesting take from artnet on his latest exhibition in San Giminiano, Italy.
                            His career is very different from other artists and his passion for the theatrical aspects of art is spectacular. This exhibition is on view until the 6th of January.
                        </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={sa2}
                            text='Shanti C. Shah, Hiralal A Gandhi memorial portrait (1941). Purchased 2009. Courtesy National Gallery of Australia, Canberra'
                        />
                        <p>We often talk about museums acknowledging the origin of artworks they conserve but, again from artnet, here is the story of 14 pieces of art returned by the government of Australia. Nonetheless the National Gallery of Australia its new guidelines for repatriating works of art.</p>
                        <Illustration
                            className={classes.section_illustration}
                            src={sa3}
                            text='Amoako Boafo The Pink Background, 2020 Mariane Ibrahim Gallery'
                        />
                        <p>
                            At last, here is a piece of news about the space. Amoako Boafo had a very unusual task: to paint three panels of a Blue Origin New Shepard rocket ship that will be launched on a roundtrip mission to space in the fall. This partnership is part of Jeff Bezos’s project to conquer the space, even with art on board.
                        </p>
                    </div>
                </Section.Right>
            </Section>
            <div className={classes.help_wrapper}>
                <div className={classes.help_text}>
                    <p>CONTEMPORARY</p>
                    <h3>We are working to help you keep up with the last market developments and trends.
                        The next hot artist, a ground-breaking project, a visionary curator - we have it all covered.</h3>
                    <img src={logo} alt='logo' />
                </div>
                <img className={classes.help_img} alt='help' src={help} />
            </div>
            <Section>
                <Section.Left>
                    <img src={eleonora} className={classes.artsted_sticky_img} alt='eleonora' />
                </Section.Left>
                <Section.Right>
                    <div className={classes.why}>
                        <h3>What is Artsted and why you should be excited about it</h3>
                        <p>
                            Artsted is an online marketplace the objective of which is to create clear and direct exchange relationships between artists and collectors empowering transparency values. The project is aimed at emerging creators and targets a new generation of millennial art investors who are looking for an innovative approach in the art market sector.
                        </p>
                        <p>
                            Artsted is an online marketplace the objective of which is to create clear and direct exchange relationships between artists and collectors empowering transparency values. The project is aimed at emerging creators and targets a new generation of millennial art investors who are looking for an innovative approach in the art market sector.
                        </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={why1}
                            text='Elisabetta Colò, Giovane che beve, Painting'
                            price='323'
                        />
                        <p>Artsted arises from a specific need and a long-existing customer pain. For years, the art market has been a place full of confusing issues, and the vaguest one has always been price formation. The platform wants to substitute the traditional valuations system, where estimates normally come from publicly available auction sales, for a more precise and primary market oriented one. In fact, Artsted is surprisingly the first art ecommerce that focuses on data analytics to create a transparent framework.</p>
                        <Illustration
                            className={classes.section_illustration}
                            src={why2}
                            text='Sofia Fioramonti, Got Lost, Photograph, 2019'
                            price='440'
                        />
                        <p>
                            What kind of artworks are going to be found on the platform by the new wave of art investors? The Artsted team is responsible for selecting the art pieces they offer to collectors. For them, it is important to control the quality of the visual content and institutional presence of artists on the platform. Therefore, the artist will join the marketplace once the admin has manually checked all the entries and accepted them.
                        </p>
                        <p>
                            The best part is that all the services are free for the artists, meaning that unlike physical galleries who take up to 50% in commission on each sale, Artsted reduces it to 35% on each sold item. This is why its philosophy is about proximity and transparency.
                        </p>
                        <Illustration
                            className={classes.section_illustration}
                            src={why3}
                            text='Sofia Fioramonti, Got Lost, Photograph, 2019'
                            price='440'
                        />
                        <p>
                            To sum up, Artsted aims to support emerging artists launching them to the global art market on their own merits. The platform allows them to operate as self-auditors, only exhibiting the relevant data they choose. Artsted is interested in loyal art investors, as they are not exclusively looking for the decorative value of the artwork, but also have the willingness to nurture artists’ careers.
                        </p>
                        <div className={classes.buy_item_2}>
                            <p>image left: Eleonora Rinaldi, Soku IV, 2020</p>
                            <Button>BUY NOW € 578</Button>
                        </div>
                    </div>
                </Section.Right>
            </Section>
            <CollectionsFooter />
            <Notify />
        </div>
    );
}

export default Collections;