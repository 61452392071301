import React from 'react';
import SEO from "../../Components/SEO";

export default function ExclusivityArt() {
    return (
        <div className={'faq_section_body exclusivity_art_padding '}>
            <SEO title="Is Artsted requiring exclusivity?: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Is Artsted requiring exclusivity?: Buy art online Artsted"/>
            <h1>Is Artsted requiring exclusivity?</h1>

            <p>No, Artsted does not require exclusivity in terms of representation or inventory. Artists are,
                nevertheless, required to keep their inventory up to date, in case of selling through a third party
                platform. </p>
        </div>
    )
}