import { Select } from 'antd';
import clsx from "clsx";

import {ChevronDown} from "../../../assets/icons";

import styles from './StyledSelect.module.css'

export default function StyledSelect({ className, ...props }) {
    return (
        <Select
            suffixIcon={<ChevronDown />}
            {...props}
            className={clsx(styles.root, className)}
        />
    )
}