import React from 'react';
import "../Pages/styles/info.css"
import {Navigation} from "../routers/navigation";
import {Route} from "react-router-dom";

const Info = React.memo(() => {

    const renderSwitchRoute = React.useCallback(() => {
        return (
            <div className={'wrapper_info_comp_content'}>
                {Navigation.mainNavigationInfo.map((items, index) => {
                    return (
                        <Route path={items.path}
                               component={items.main}
                               key={index}
                               exact={true}/>
                    )
                })}
            </div>
        )
    }, [])


    return (
        <div className={"wrapper_every_page"}>
            <div className={'wrapper_component_info_footer'}>
                {renderSwitchRoute()}
            </div>
        </div>
    )
})
export default Info;