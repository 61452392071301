import React from 'react'

const TermsFinalProvisions = () => {
    return (
        <div>
            <h3 className={'terms_item_title'}>11. Final Provisions and Changes of GTC</h3>
            <div className={'terms_of_service_item_container'}>
                <span>11.1</span>
                <span>
                    The here presented Terms and Conditions may be revised at any time by the Website
                    operator.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>11.2</span>
                <span>
                    Changes and amendments to these Terms and Conditions are effective and valid upon publishing
                    on the Website.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>11.3</span>
                <span>
                    It is the customer's responsibility to inform himself regularly about the content of and the
                    potential changes to the presented GTC, by looking up the following address:
                    <a href="https://artsted.com/"> www.artsted.com</a>
                </span>
            </div>
        </div>
    )
}

export default TermsFinalProvisions