import clsx from "clsx";

import styles from './AsideCard.module.css'

export default function AsideCard({ children, className }) {
    return (
        <div className={clsx(styles.root, className)}>
            {children}
        </div>
    )
}