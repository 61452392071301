import React, {useEffect, useState} from "react";
import useBottomPopup from "../../Constants/useBottomPopup";
import API from "../../api/API";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import CustomDropdown from "../../Components/CustomComponents/Dropdown";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";
import MainButton from "../../Components/CustomComponents/MainButton";
import '../Profile/styles/new_support.css';
import {ResizeTextBox} from "../../Components/ResizeTextBox";
import {Loader} from "../../Components/Loader";

const initialState = {
    use_platform_for: {
        value: '',
        dataArray: [],
        error: false,
        required: false,
        options: [],
        popup: false,
        errorMessage: 'Please select one of the option ',
    },
    inquiry: {
        value: '',
        dataArray: [],
        required: false,
        error: false,
        options: [],
        popup: false,
        errorMessage: 'Please select one of the option ',
    },
    problem: {
        value: '',
        error: false,
        errorMessage: 'Enter at least 10 characters',
    },
    email: {
        value: '',
        error: false,
        errorMessage: 'Wrong email format',
    }

}

export default function NewSupport() {
    const [data, setData] = useState(() => initialState)
    const [loading, setLoading] = useState(false)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    useEffect(() => {
        API().get(`/public/contact_us`)
            .then(res => {
                setData(state => ({
                    ...state,
                    inquiry: {
                        ...state.inquiry,
                        options: res.data.inquiry
                    }
                }))
            })
            .then(() => setLoading(true))
    }, [])

    const handleSupportQuestion = (e) => {
        e.preventDefault();
        if (data.problem.value.length >= 10 && data.inquiry.value) {
            API().post(`/public/contact_us`,
                {
                    "inquiry": data.inquiry.value,
                    "problem": data.problem.value
                })
                .then(res => {
                    if (res.status === 200) {
                        // console.log(res)
                        setData(prevState => ({
                            ...prevState,
                            inquiry: {
                                ...prevState.inquiry,
                                value: ''
                            },
                            problem: {
                                ...prevState.problem,
                                value: ''
                            }
                        }))
                        changeContinuePopup('Thank you for the submission!')
                        setTimeout(() => changeContinuePopup('Thank you for the submission!'), 2000)
                    }
                })
        } else {
            if (!data.inquiry.value) {
                setData(state => ({
                    ...state,
                    inquiry: {
                        ...state.inquiry,
                        error: true,
                        value: ''
                    },
                    problem: {
                        ...state.problem,

                    }
                }))
            }
            if (data.problem.value.length < 10) {
                setData(state => ({
                    ...state,
                    problem: {
                        ...state.problem,
                        error: true
                    }
                }))

            }
        }
    }

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={0}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={'profile_support_wrapper'}>
            <h2>Contact our Agent</h2>
            <p className={'description'}>
                We are always happy to help you find the right answers. Reach out and our support agents will take care
                of your inquiry within 24 hours.
            </p>
            <div style={!loading ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : null}>
                {loading ?
                    <form onSubmit={(e) => handleSupportQuestion(e)}>
                        <div className={'need_help'}>
                            <CustomDropdown value={data.inquiry.value}
                                            setInputValue={val => {
                                                setData(val)
                                            }}
                                            borderDropdown
                                            optionLabel={'inquiry'}
                                            errTitle={'Choose an option'}
                                            error={data.inquiry.error}
                                            newError={data.inquiry.error}
                                            dropdownSelect
                                            defaultDropdown
                                            newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                                    fill="black"/>
                                            </svg>
                                            }
                                            placeholder={'Sales'}
                                            onChange={({target}) => {
                                                setData(prevState => ({
                                                    ...prevState,
                                                    inquiry: {
                                                        ...prevState.inquiry,
                                                        value: target.value,
                                                    }
                                                }))
                                            }}
                                            title={'I need help with'}
                                            dropdownIcon={greyChevron}
                                            popup={data.inquiry.popup}
                                            options={data.inquiry.options}
                            />
                        </div>

                        <div className={'wrapper_text_box'}>
                            <ResizeTextBox value={data.problem.value}
                                           error={data.problem.error}
                                           onChange={({target}) => {
                                               setData(prevState => ({
                                                   ...prevState,
                                                   problem: {
                                                       ...prevState.problem,
                                                       value: target.value,
                                                       error: false
                                                   }
                                               }))
                                           }}
                                           inputTitle={'Description'}
                                           errTitle={'Enter at least 10 characters'}
                                           borderBottomInput/>
                        </div>

                        <div className={'wrapper_support_form'}>
                            <MainButton type={'submit'}
                                        text={'Apply'}
                                        style={{margin: '1.75rem 0 0'}}
                                        className={'button_black'}/>
                        </div>
                    </form>
                    : <Loader color={{
                        '--bgColor': '#000',
                        '--size': '48px',
                        '--wrapper_size': '40px'
                    }}/>}
            </div>
        </div>
    ]
}
