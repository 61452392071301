import React from "react";

import './styles/modal_window.css'
import OutsideClickHandler from "../Constants/detectOutsideClick";
import {bodyOverflow} from "../Constants/bodyOverflow";

export default function ModalWindow({setActiveModal, setNullData, children, nestedObj}) {

    return (
        <div className={'modal-window-bg'}>
            <OutsideClickHandler callback={() => {
                if (nestedObj) {
                    setActiveModal(prevState => ({
                        ...prevState,
                        value: null,
                    }))
                } else {
                    setActiveModal(null)
                }
                if (setNullData) {
                    setNullData(null)
                }
                bodyOverflow(false)
            }}>
                <div className={'modal-window-content'}>
                    {children}
                </div>
            </OutsideClickHandler>
        </div>
    )
}