import {Space} from "antd";
import moment from "moment";

import {ArrowRightIcon} from "../../../assets/icons";

import styles from "./CheckInOutDates.module.css";

function convertTime(timeString) {
    // Validate input format (optional)
    if (!/^\d{2}:\d{2}$/.test(timeString)) {
        return '';
    }

    const [hours, minutes] = timeString.split(':').map(Number);

    let convertedHours = hours % 12 || 12; // Convert to 12-hour format (or 12 if 0)
    const amPm = hours >= 12 ? 'PM' : 'AM';

    return `${convertedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;
}

export default function CheckInOutDates(
    {
        checkIn,
        checkInFromTime,
        checkInToTime,
        checkOut,
        checkOutFromTime,
        checkOutToTime
    }) {
    return (
        <dic className={styles.root}>
            <div>
                <div>
                    Check-in:
                </div>
                <div className={styles.date}>
                    {checkIn ? moment(checkIn).format('ddd DD MMM YYYY') : '-'}
                </div>
                <Space>{convertTime(checkInFromTime?.slice?.(0, -3))}-{convertTime(checkInToTime?.slice?.(0, -3))}</Space>
            </div>
            <div>
                <ArrowRightIcon className={styles.arrowIcon}/>
            </div>
            <div>
                <div>
                    Check-out:
                </div>
                <div className={styles.date}>
                    {checkOut ? moment(checkOut).format('ddd DD MMM YYYY') : '-'}
                </div>
                <Space>{convertTime(checkOutFromTime?.slice?.(0, -3))}-{convertTime(checkOutToTime?.slice?.(0, -3))}</Space>
            </div>
        </dic>
    )
}