import React from 'react';

const arrowRight = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.73438 4L16.7759 12.0171L8.73438 20" stroke="black" stroke-width="2"/>
</svg>

const cartSteps = [
    {
        step: 1,
        title: "Order Details",
        auth: true,
        subscription: true
    },
    {
        step: 2,
        title: "Shipping details",
        subscription: true
    },
    {
        step: 3,
        title: "Payment details",
    }
]

const CartSteps = ({activeStep, setActiveStep, userStore, activeSubscription}) => {
    return (
        <div className="cart-steps">
            {
                cartSteps
                    .filter(i =>
                        ((i.auth && !userStore?.name) || !i.auth) &&
                        ((i.subscription && !activeSubscription?.value) || !i.subscription)
                    )
                    .map((i, idx) => (
                    <div onClick={
                        activeStep.access >= i.step
                            ? () => setActiveStep(state => ({...state, active: i.step}))
                            : undefined
                    }
                         key={i.step}
                         className={`cart-step ${activeStep.active >= i.step ? 'active' : ''}`}
                    >
                        <span>{idx + 1}</span>
                        {i.title}
                        {arrowRight}
                    </div>
                ))
            }
        </div>
    );
};

export default CartSteps;
