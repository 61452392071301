import React from "react";
import './styles/topSectionContact.css'
import {Link} from "react-router-dom";
// import backgroundImage from '../../assets/img/artsted_for/Image.png'


const TopSectionContact = (props) => {


    const onContactHandler = () => {
        document.getElementById('form_template_wrapper').scrollIntoView({
            behavior: "smooth",
            block: 'center'
        })
    }

    return (
        <div className={'background_image'} style={{backgroundImage: `url(${props.img})`}}>
            {/*<img className={'background_image'} src={backgroundImage}/>*/}
            <div className={'main_container'}>
                <div className={'first_main_section'}>
                    <h1 className={'top_main_title'}>{props.title}</h1>
                    <span className={'top_about'}>{props.about}</span>
                    <div onClick={onContactHandler} className={'contact_button'}>
                        <span className={'contact_button_content'}>Contact us</span>
                    </div>
                </div>
                <div className={'bottom_top_section_text_container'}>
                    <span className={'bottom_top_section_text'}>{props.additional}</span>
                    <Link
                        className={'bottom_top_section_text bottom_top_section_text_to'}
                        to={props.link}>
                        {props.to}
                    </Link>
                </div>

            </div>
        </div>

    )
}

export default TopSectionContact
