import React from "react";
import {useHistory} from "react-router-dom";
import SEO from "../../Components/SEO";
import MainButton from "../../Components/CustomComponents/MainButton";
import logo from "../../assets/img/new_landing/artsted_logo_vertical.svg"

export default function NewPaymentSubscription() {
    const history = useHistory()

    return (
        <div className={'successful_order_wrapper'}>
            <SEO>
                <meta name="robots" content="noindex, nofollow"/>
            </SEO>
            <div className={'logo_order'} style={{marginBottom: "56px"}}>
                <img src={logo} alt="event_completed" style={{width: 143}}/>
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <h2 style={{fontSize: 20, fontWeight: "bold"}}>Purchase Successful!</h2>
                <p style={{textAlign: "center", marginBottom: 32}}>
                    Great job! Welcome to Artsted premium - explore your <br />
                    profile to experience all the advanced tools and functions
                </p>
                <MainButton type={'button'}
                            text={'Go to profile'}
                            className={'button_black'}
                            style={{background: '#000', color: '#fff'}}
                            onClick={() => history.push('/profile/about')}
                />
            </div>
        </div>
    )
}
