import React from 'react';
import OrderDetails from "./OrderDetails";
import ShippingDetails from "./ShippingDetails";
import PaymentDetails from "./PaymentDetails";

const CartContentByStep = ({
                               activeSubscription,
                               shippingDetails,
                               setShippingDetails,
                               activeStep,
                               userStore,
                               selectedShippingAddress,
                               setSelectedShippingAddress,
                               setActiveStep,
                               activeData,
                               checkout,
                               submitCheckout,
                               subscriptionCheckout
                           }) => {
    console.log(activeStep)
    switch (activeStep?.active) {
        default:
        case 1:
            return (
                <OrderDetails
                    shippingDetails={shippingDetails}
                    setShippingDetails={setShippingDetails}
                    userStore={userStore}
                    activeData={activeData}
                    setActiveStep={setActiveStep}
                />
            )
        case 2:
            return (
                <ShippingDetails
                    setActiveStep={setActiveStep}
                    shippingDetails={shippingDetails}
                    setShippingDetails={setShippingDetails}
                    selectedShippingAddress={selectedShippingAddress}
                    setSelectedShippingAddress={setSelectedShippingAddress}
                    userStore={userStore}
                />
            );
        case 3:
            return (
                <PaymentDetails
                    activeSubscription={activeSubscription}
                    setActiveStep={setActiveStep}
                    activeData={activeData}
                    selectedShippingAddress={selectedShippingAddress}
                    shippingDetails={shippingDetails}
                    checkout={checkout}
                    submitCheckout={submitCheckout}
                    subscriptionCheckout={subscriptionCheckout}
                />
            )
    }
};

export default CartContentByStep;
