import {Slider} from "antd";

import styles from './StyledRangeSlider.module.css';

export default function StyledRangeSlider({ ...props }) {
    return (
        <Slider
            {...props}
            className={styles.root}
        />
    )
}