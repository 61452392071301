import React, {useState} from "react";
import TopSectionContact from "../../../Components/SectionsComponents/TopSectionContact";
import NumListSection from "../../../Components/SectionsComponents/NumListSection";
import TextListSection from "../../../Components/SectionsComponents/TextListSection";
import FormTemplate from "../../../Components/SectionsComponents/FormTemplate";
import backgroundImg from '../../../assets/img/artsted_for/ImageSecond.jpeg'
import './styles/forBusinesses.css'

const ForProfessionals = () => {
    const [numListItems] = useState([
        {text: <span>Roster of more than <b>500 carefully selected emerging artists</b> and thousands of artworks with a diverse range of mediums</span>},
        {text: <span><b>Cutting-edge technology:</b> AI, Blockchain, NFT creation</span>},
        {text: <span>Over 10 years of combined <b>art business experience</b></span>},
    ])
    const [textList] = useState([
        {text: 'Earn a percentage on every artwork sold with us'},
        {text: 'Customer service with deep experience in handling orders and projects'},
        {text: 'Top-tier shipping experience'},
        {text: 'Have access to the skills of our art advisors to guide you through our collection of artworks'},
    ])


    return (
        <div>
            <TopSectionContact
                img={backgroundImg}
                title={'Artsted for professionals'}
                about={'On the constant look for original fine art for your clients’ projects? We are here for you!' +
                'Whether you are an architect, interior designer, art advisor or real estate agent we have solutions tailored to your budgets and requirements'}
                additional={'If you are a business have a look at our '}
                to={' customized services'}
                link={'/artsted-for-businesses'}
            />
            <NumListSection
                title={'Why Artsted?'}
                about={'We are an online marketplace at the forefront of technological innovation in the art market'}
                items={numListItems}
            />
            <div className={'content_wrapper'}>
                <TextListSection
                    items={textList}
                />
                <FormTemplate
                    type={'For professionals'}
                />

            </div>

        </div>
    )
}

export default ForProfessionals
