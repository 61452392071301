import React, {useEffect, useState} from 'react';
import './styles/faq.css';
import {BrowserRouter as Router, Route, useLocation, Link, useHistory} from "react-router-dom";
import {Navigation} from "../routers/navigation";
import useResize from "../Constants/useResize";
import NewContactUs from "../Components/NewContactUs";
import SEO from "../Components/SEO";
import StayInLoop from "../Components/StayInLoop";


const Faq = React.memo(() => {
    const [activePage, setActivePage] = useState();
    const {innerWidth} = useResize()

    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        let url = location.pathname.split('/').pop();
        setActivePage(url)
        if (document.getElementById(url)) {
            document.getElementById(url).scrollIntoView({
                behavior: "smooth"
            })
        } else {
            window.location.href = '/404-redirect'
        }

    }, [location, history])

    const OldSchoolMenuLink = ({pathname, path, id, label}) => (
        <div id={id}>
            <Link key={pathname}
                  className={activePage === pathname ? 'question_active' : null}
                  onClick={() => {
                      setActivePage(pathname)
                      // console.log(id)

                      if (innerWidth > 768) {
                          window.scrollTo(0, 0)
                      } else {
                          setTimeout(() => {
                              let offset = document.getElementById(id).offsetTop
                              // console.log(offset)
                              window.scrollTo({top: offset, behavior: "smooth"})
                          }, 200)
                      }
                  }}
                  to={path}>
                <span>{label}</span>
            </Link>
            {activePage === pathname && innerWidth <= 768 ?
                <div>
                    {renderSwitchRoute()}
                </div>
                : null}
        </div>
    );


    const SwitcherNavBar = ({routes}) => (
        routes.map((items, index) => {
            return (
                <Route path={items.path}
                       component={items.main}
                       key={index}
                       exact={true}/>
            )
        })
    )

    const renderSwitchRoute = React.useCallback(() => {
        return (
            <div className={'wrapper_faq_comp_content'}>
                <SwitcherNavBar routes={Navigation.mainNavigationFaq}/>
            </div>
        )
    }, [])


    return (
        <Router>
            <div className="wrapper_every_page_faq">
                <SEO title="Frequently Asked Questions | Artist handbook | Artsted technology"
                     description="Artsted uses a proprietary algorithm to provide insights and instant evaluations based on artists’ career performance. Find answers in the FAQ section."/>
                <div className={'wrapper_question_faq'}>
                    <div className={'question_list padding_faq'}>
                        {Navigation.mainNavigationFaq.map((item, index) => (
                            <OldSchoolMenuLink {...item} key={index}/>
                        ))}
                    </div>

                    {innerWidth > 768 ?
                        renderSwitchRoute()
                        : null}
                </div>
            </div>


            <div className={'border_line'}/>

            <div className={'faq_contact_us'}>
                <h1>Could not find the answer? Contact us</h1>
                <div className={'account_question'}>
                    <div className={'find_answer_text'}>
                            <span>We are always happy to help you find the right answers.
                                Reach out to <a href="mailto:info@artsted.com">info@artsted.com</a> and our support agents will take care of your inquiry.</span>
                    </div>
                    <NewContactUs/>
                </div>
            </div>
            <StayInLoop/>
        </Router>
    );
});

export default Faq;


