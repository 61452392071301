import React from 'react'

const TermsOffers = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>2. Prices and Special Offers</h3>
            <div className={'terms_of_service_item_container'}>
                <span>2.1</span>
                <span>
                    Prices are indicated in Euros / EUR. The prices valid at the time the order was placed are
                    authoritative.
                    The displayed prices include the applicable statutory VAT, if not separately declared.
                    Handling fees, as well as shipment and packaging costs are indicated separately.
                    Depending on the destination of the shipment, additional costs for duty and taxes may accrue to
                    the recipient.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>2.2</span>
                <span>
                    Conditions for special offers and rebates are indicated where applicable.
                </span>
            </div>
        </div>
    )
}

export default TermsOffers