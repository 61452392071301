import React from 'react';
import {unitByCurrency} from "../../Constants/unitByCurrency";

const MyComponent = ({shippingDetails, price, currency}) => {
    const discountedPrice = +price * (1 - (+shippingDetails.promocode.discount) / 100)
    const currencySymbol = currency ? unitByCurrency[currency] : "€"
    return (
        <div className={'user_cart_order_total_price_container'}>
            <div className={'line'}/>
            {!!(+shippingDetails.promocode.discount) && (
                <>
                    <div className={'total_price'}>
                        <h6>Price:</h6>
                        <span>{currencySymbol || "€"}{price}</span>
                    </div>
                    <div className={'total_price'}>
                        <h6>Discount:</h6>
                        <span>{shippingDetails.promocode.discount}%</span>
                    </div>
                </>
            )}
            <div className={'total_price bold'}>
                <h6>Total:</h6>
                <span>{currencySymbol || "€"}{
                    discountedPrice && discountedPrice.toString().split('.').length > 1
                        ? discountedPrice.toFixed(1)
                        : discountedPrice
                }</span>
            </div>
        </div>
    );
};

export default MyComponent;
