import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import PrivateRoute from "./PrivateRouter";
import PrivateRouteAdmin from './PrivateRouteAdmin'
import Routers from "./Routers";
import "../main_global.css";
import Login from '../AdminPages/Pages/Login';
import ProviderAuth from './ProviderAuth';
import MainNavigationProfile from "../Pages/Profile/MainNavigationProfile";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ScrollToTop from "../Constants/ScrollToTop";
import LoginUser from '../Pages/Onboarding/Login';
import FacebookEmailLogin from "../Pages/Onboarding/FacebookEmailLogin";
import NewHeader from "../Components/NewHeader";
import NewFooter from "../Components/NewFooter";
import NewRouter from "./NewRouter";
import Registration from "../Pages/Onboarding/Registration";
import ResetPassword from "../Pages/Onboarding/ResetPassword";
import HeaderBanner from "../Components/HeaderBanner";
import Keys from "../Constants/helper";
import {PageNotFound} from "../Pages/404";
import GetCurrencies from "../Components/GetCurrencies";
import {useDispatch} from "react-redux";
import {setFromApp} from "../main-store/auth/actions";

const MainRouter = () => {

    const dispatch = useDispatch()
    const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)

    useEffect(() => {
        appHeight()
        window.addEventListener('resize', appHeight)
        return () => window.removeEventListener('resize', appHeight)
    }, [])

    useEffect(() => {
        const getRouteQueries = () => {
            const fromApp = JSON.parse(localStorage.getItem(Keys.FROM_APP))
            if(fromApp === null) {
                const searchParams = new URLSearchParams(document.location.search)
                localStorage.setItem(Keys.FROM_APP, searchParams.get("fromApp"))
                localStorage.setItem(Keys.APP_PLATFORM, searchParams.get("platform"))
                dispatch(setFromApp(JSON.parse(searchParams.get("fromApp"))))
            }
        }
        getRouteQueries()
        // eslint-disable-next-line
    }, [])

    return (
        <ProviderAuth>
            <Router>
                <ScrollToTop/>

                <GetCurrencies>
                <Switch>
                    <Route exact path='/admin' component={Login}/>
                    <PrivateRouteAdmin path='/admin/:slug'/>
                    <PrivateRouteAdmin path='/admin/:slug/:id?'/>

                    <Route exact path='/' component={NewRouter}/>
                    <Route path='/code=:code' component={NewRouter}/>
                    <Route exact path='/premium' component={NewRouter}/>
                    <Route exact path='/collections' component={NewRouter}/>
                    {/*<Route exact path='/artworks' component={NewRouter}/>*/}
                    <Route exact path='/experiences/:category?/:page(page-[0-9]+)?' component={NewRouter}/>
                    <Route exact path='/experiences/:category?/:page(page-[0-9]+)?/f/:filters+' component={NewRouter}/>
                    <Route exact path='/public-experiences/:category/:id' component={NewRouter}/>
                    <Route exact path='/artworks/:category?/:page(page-[0-9]+)?' component={NewRouter}/>
                    <Route exact path='/artworks/:category?/:page(page-[0-9]+)?/f/:filters+' component={NewRouter}/>
                    <Route exact path='/artists/:category?/:page(page-[0-9]+)?' component={NewRouter}/>
                    <Route exact path='/artists/:category?/:page(page-[0-9]+)?/f/:filters+' component={NewRouter}/>
                    <Route exact path='/artist-profile/:id' component={NewRouter}/>
                    <Route exact path='/calendar-customization/:id' component={NewRouter}/>
                    <Route exact path='/listing/:id' component={NewRouter}/>

                    <Route exact path='/main-info' component={Routers}/>
                    <Route exact path='/public-artwork/:id' component={NewRouter}/>
                    <Route exact path='/cart' component={NewRouter}/>
                    <Route exact path='/confirm-pay' component={NewRouter}/>
                    <Route exact path='/subscription-plan' component={NewRouter}/>
                    <Route exact path='/post-checkout' component={NewRouter}/>
                    <Route exact path='/card-added' component={NewRouter}/>
                    <Route exact path='/payment-success' component={Routers}/>
                    <Route exact path='/payment-subscription' component={NewRouter}/>
                    <Route exact path='/article/:id' component={Routers}/>
                    <Route exact path='/payment-failed' component={NewRouter}/>
                    <Route exact path='/faq/:route' component={NewRouter}/>
                    <Route exact path='/info/:route' component={NewRouter}/>
                    <Route exact path='/info/:route/:route2' component={NewRouter}/>
                    <Route exact path='/password-reset' component={Routers}/>
                    <Route exact path='/login' component={LoginUser}/>
                    <Route exact path='/reset-password' component={ResetPassword}/>
                    <Route exact path='/reset-password/:code' component={ResetPassword}/>
                    <Route exact path='/registration/:slug' component={Registration}/>
                    <Route exact path='/facebook-email-login' component={FacebookEmailLogin}/>
                    <Route exact path='/artist-profile/:id/gallery' component={NewRouter}/>
                    <Route exact path='/artsted-for-businesses' component={NewRouter}/>
                    <Route exact path='/artsted-for-professionals' component={NewRouter}/>
                    <Route exact path='/collectors-hub' component={NewRouter}/>
                    <Route exact path='/booking/:id/:type' component={NewRouter}/>
                    <Route exact path='/bookings/:id' component={NewRouter}/>

                    <Route
                        exact
                        path="/404-redirect"
                        component={PageNotFound}
                    />
                    {/*<Route exact path='/registration/type' component={Registration}/>*/}
                    {/*<Route exact path='/registration' component={Registration}/>*/}


                    {/*<PrivateRoute path={'/artists'}>*/}
                    {/*<Route exact path={'/artists'} component={NewRouter}/>*/}
                    {/*</PrivateRoute>*/}
                    {/*<PrivateRoute path={'/artist-profile/:id'}>*/}
                    {/*<NewRouter exact path={'/artist-profile/:id'} component={Routers}/>*/}
                    {/*</PrivateRoute>*/}

                    <PrivateRoute path={'/profile/:slug'}>
                        <NewWrapperApp>
                            <MainNavigationProfile/>
                        </NewWrapperApp>
                    </PrivateRoute>



                    <Route
                        path="*"
                        component={PageNotFound}
                    />

                </Switch>
                </GetCurrencies>
            </Router>
        </ProviderAuth>
    )
}


export const WrapperApp = ({children}) => (
    <div className={'wrapper_app'}>
        <Header/>
        {children}
        <Footer/>
    </div>
);

export const NewWrapperApp = ({children}) => {

    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        const saved = !localStorage.getItem(Keys.TOP_BANNER_HIDE)
        const fromApp = JSON.parse(localStorage.getItem(Keys.FROM_APP))
        setShowBanner(saved && !fromApp)
    }, [])

    return (
        <div className={'wrapper_app wrapper_app_design'}>
            {showBanner && <HeaderBanner setShowBanner={setShowBanner}/>}
            {showBanner && <div className={"banner_block"}/>}
            <NewHeader showBanner={showBanner}/>
            {children}
            <NewFooter/>
        </div>
    )
};

export default MainRouter;
