import React, {useEffect, useState} from 'react';
import '../Pages/styles/faq.css';
import '.././Components/styles/contact_us.css';
import '.././Components/styles/contact_us_new.css';
import API from "../api/API";
import regExp from "../Constants/regExp";
import Keys from "../Constants/helper";
import CustomDropdown from "./CustomComponents/Dropdown";
import TextInput from "./CustomComponents/TextInput";
import MainButton from "./CustomComponents/MainButton";
import {ResizeTextBox} from "./ResizeTextBox";

const initialState = {
    use_platform_for: {
        value: '',
        dataArray: [],
        error: false,
        required: false,
        options: [],
        popup: false,
        errorMessage: 'Please select one of the option ',
    },
    inquiry: {
        value: '',
        dataArray: [],
        required: false,
        error: false,
        options: [],
        popup: false,
        errorMessage: 'Please select one of the option ',
    },
    problem: {
        value: '',
        error: false,
        errorMessage: 'Enter at least 10 characters',
    },
    email: {
        value: '',
        error: false,
        errorMessage: 'Wrong email format',
    }

}
const NewContactUs = () => {
    const [data, setData] = useState(initialState);

    useEffect(() => {
        setData(initialState)
        API().get(`/public/contact_us`)
            .then(res => {
                setData(state => ({
                    ...state,
                    use_platform_for: {
                        ...state.use_platform_for,
                        options: res.data.use_platform_for,
                    },
                    inquiry: {
                        ...state.inquiry,
                        options: res.data.inquiry
                    }
                }))
            })
    }, [])

    const handleContactUs = (e) => {
        e.preventDefault();
        if (data.problem.value.length >= 10 && data.inquiry.value && (data.use_platform_for.value || regExp.email.test(data.email.value))) {

            const requestData = {
                'inquiry': data.inquiry.value,
                'problem': data.problem.value,
            }
            if (localStorage.getItem(Keys.JWT_TOKEN)) {
                requestData.use_platform_for = data.use_platform_for.value
            } else {
                requestData.use_platform_for = "Selling"
                requestData.email = data.email.value
            }

            API().post('/public/contact_us', requestData)
                .then(res => {
                    if (res.status === 200) {
                        setData(prevState => ({
                            ...prevState,
                            use_platform_for: {
                                ...prevState.use_platform_for,
                                value: ''
                            },
                            inquiry: {
                                ...prevState.inquiry,
                                value: ''
                            },
                            problem: {
                                ...prevState.problem,
                                value: ''
                            },
                            email: {
                                ...prevState.email,
                                value: ''
                            }
                        }))
                    }
                })
        } else {
            if (!data.use_platform_for.value) {
                setData(state => ({
                    ...state,
                    use_platform_for: {
                        ...state.use_platform_for,
                        error: true,
                        value: ''
                    },
                }))
            }
            if (!data.inquiry.value) {
                setData(state => ({
                    ...state,
                    inquiry: {
                        ...state.inquiry,
                        error: true,
                        value: ''
                    },
                }))
            }
            if (data.problem.value.length < 10) {
                setData(state => ({
                    ...state,
                    problem: {
                        ...state.problem,
                        error: true
                    }
                }))
            }
            if (!regExp.email.test(data.email.value)) {
                setData(state => ({
                    ...state,
                    email: {
                        ...state.email,
                        error: true
                    }
                }))
            }

        }

    }
    return (
        <form className={'form_wrapper question_feedback_form'}
              onSubmit={e => handleContactUs(e)}>
            {localStorage.getItem(Keys.JWT_TOKEN) ?
                <CustomDropdown value={data.use_platform_for.value}
                                setInputValue={val => {
                                    setData(val)
                                }}
                                newDropdownIcon={<svg
                                    style={data.use_platform_for.popup ? {transform: 'rotateX(180deg)'} : {transform: 'rotateX(0)'}}
                                    width="16" height="10" viewBox="0 0 16 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0.111084V1.84732L7.98294 9.88886L0 1.84732V0.111084L7.98294 8.15262L16 0.111084Z"
                                        fill="black"/>
                                </svg>
                                }
                                errorTitle={data.use_platform_for.errorMessage}
                                optionLabel={'use_platform_for'}
                                error={data.use_platform_for.error}
                                newError={data.use_platform_for.error}
                                defaultDropdown
                                dropdownSelect
                                borderDropdown
                                title={'Use Platform For'}
                                onChange={({target}) => {
                                    setData(prevState => ({
                                        ...prevState,
                                        use_platform_for: {
                                            ...prevState.use_platform_for,
                                            value: target.value,
                                        }
                                    }))
                                }}
                                dropdownPadding={'5px 16px'}
                                popup={data.use_platform_for.popup}
                                options={data.use_platform_for.options}
                />
                : <TextInput value={data.email.value}
                             error={data.email.error}
                             title={'Email'}
                             errTitle={data.email.errorMessage}
                             onChange={({target}) => {
                                 setData(prevState => ({
                                     ...prevState,
                                     email: {
                                         ...prevState.email,
                                         value: target.value,
                                         error: false,
                                     }
                                 }))
                             }}/>
            }
            <CustomDropdown value={data.inquiry.value}
                            setInputValue={val => setData(val)}
                            errorTitle={data.inquiry.errorMessage}
                            optionLabel={'inquiry'}
                            error={data.inquiry.error}
                            newError={data.inquiry.error}
                            defaultDropdown
                            borderDropdown
                            dropdownSelect
                            newDropdownIcon={<svg
                                style={data.inquiry.popup ? {transform: 'rotateX(180deg)'} : {transform: 'rotateX(0)'}}
                                width="16" height="10" viewBox="0 0 16 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 0.111084V1.84732L7.98294 9.88886L0 1.84732V0.111084L7.98294 8.15262L16 0.111084Z"
                                    fill="black"/>
                            </svg>
                            }
                            title={'Inquiry'}
                            onChange={({target}) => {
                                setData(prevState => ({
                                    ...prevState,
                                    inquiry: {
                                        ...prevState.inquiry,
                                        value: target.value,
                                        error: false,
                                        popup: true
                                    }
                                }))
                            }}
                            dropdownPadding={'10px 16px'}
                            popup={data.inquiry.popup}
                            options={data.inquiry.options}
            />
            <ResizeTextBox addMargin={'8px 0 0'}
                           borderBottomInput
                           value={data.problem.value}
                           error={data.problem.error}
                           errTitle={data.problem.errorMessage}
                           inputTitle={'How can we help?'}
                           onChange={({target}) => setData(prevState => ({
                               ...prevState,
                               problem: {
                                   ...prevState.problem,
                                   value: target.value,
                                   error: false
                               }
                           }))}
                           blockStyle={{
                               margin: '0',
                               display: 'grid',
                               gridTemplateColumns: '1fr',
                               alignItems: 'center'
                           }}/>
            <div className={'wrapper_contact_form_footer'}>
                <MainButton text={'Send'}
                            className={'button_black'}
                            type={'submit'}/>
            </div>
        </form>
    )
}
export default NewContactUs;
