import React, {forwardRef, useEffect, useState} from 'react';
import './styles/text_input.css';

const TextBox = forwardRef((props, ref) => {
    const [disabled, setDisabled] = useState(false)
    const [required, setRequired] = useState(false)

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
        if (props.required !== undefined && props.required !== null) {
            setRequired(props.required)
        }
    }, [props.disabled, props.required])

    return (
        <div style={props.blockStyle ? props.blockStyle : props.margin ? {margin: props.margin} : null}
             className={'input_component'}>
            {props.title
                ? <span className={'input_component_title_new'}
                        style={disabled ? {color: '#C2C2C2'} : null}>{props.title}</span> : null}
            <textarea
                style={props.width ? {width: `${props.width}`} : props.borderRadius ? {borderRadius: `${props.borderRadius}`} : null}
                disabled={disabled}
                ref={ref}
                name={props.name ? props.name : ""}
                required={required}
                maxLength={props.maxLength ?? 999}
                // maxLength={1000}
                onKeyDown={props.onKeyDown}
                autoFocus={props.autoFocus ?? false}
                placeholder={props.placeholder ? props.placeholder : null}
                className={props.error ? `error textarea overflow_scrollbar ${props.borderBottomInput ? 'border_bottom_input_error border_bottom_input' : ''}`
                    : props.borderBottomInput ? 'border_bottom_input'
                        : 'textarea overflow_scrollbar'}
                rows={props.rows ? props.rows : 5}
                value={props.value ? props.value : ''}
                onChange={props.onChange ? e => {
                    props.onChange(e)
                } : null}

            />
            {props.newError && props.error
                ? <div className={'new_error_wrapper'}>
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z"
                            fill="#FF4949"/>
                        <path
                            d="M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z"
                            fill="#FF4949"/>
                        <path
                            d="M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z"
                            fill="#FF4949"/>
                    </svg>
                    <span>{props.errTitle ? props.errTitle : 'Fill this label'}</span>
                </div>
                : props.error ?
                    <span className={'input_component_error_text'}
                          style={{color: 'var(--error)', margin: props.addMargin ? props.addMargin : '0'}}>
                    {props.errTitle}
                </span>
                    : null
            }
        </div>
    );
})

export default TextBox;
