import './styles/info_banner.css'
import MainButton from "./CustomComponents/MainButton";
import React from "react";
import {TwoLinesBushPrint} from "../assets/icons";

export const InfoBanner = ({title, subtitle, buttonText, onClick}) => {
  return(
    <div className={'top_banner_wrapper_info_banner'} onClick={onClick}>
      <div className={'brush_wrapper_info_banner'}>
        <TwoLinesBushPrint/>
      </div>
      <div className={'info_text_wrapper_info_banner'}>
        <span className={'title_info_banner'}>{title}</span>
        <span className={'subtitle_info_banner'}>{subtitle}</span>
      </div>
      <div className={'top_banner_button_wrapper_info_banner'}>
          <MainButton
            style={{width: '100%'}}
            className={'button_black'}
            text={buttonText}
            onClick={onClick}
          />
      </div>

    </div>
  )
}