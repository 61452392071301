import React from 'react';
import MainButton from "./CustomComponents/MainButton";

const StripeCard = ({condition, onClick, text, no_fill}) => (
    <div className={`stripe_button_wrapper ${no_fill ? "no-fill" : ""}`}>
        <MainButton className={condition ? "button_bordered" : "button_purple"}
                    onClick={onClick}
                    text={text}
                    svg={condition ?
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H10C10 18 10 18 10 17C10 16 10 16 10 16H5C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15V9H20V11C21 11 20.7348 11 21 11C20.7348 11 22 11 21 11C21.2109 11 21 11 22 11V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7956 3 19 3ZM20 7H4V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V7ZM11 11H6V12C6 12.2652 6 13 6 13C6 13 6.73478 13 7 13H11C11 13 11 13 11 12C11 11 11 11 11 11Z"
                                fill="black"/>
                            <rect x="17" y="13" width="2" height="8" fill="black"/>
                            <rect x="22" y="16" width="2" height="8" transform="rotate(90 22 16)" fill="black"/>
                        </svg> :
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M19 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H10C10 18 10 18 10 17C10 16 10 16 10 16H5C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15V9H20V11C21 11 20.7348 11 21 11C20.7348 11 22 11 21 11C21.2109 11 21 11 22 11V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7956 3 19 3ZM20 7H4V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V7ZM11 11H6V12C6 12.2652 6 13 6 13C6 13 6.73478 13 7 13H11C11 13 11 13 11 12C11 11 11 11 11 11Z"
                                fill="#F2F2F2"/>
                            <path
                                d="M5 18L19 18C19.7956 18 20.5587 17.6839 21.1213 17.1213C21.6839 16.5587 22 15.7956 22 15L22 6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7957 3 19 3L14 3C14 3 14 3 14 4C14 5 14 5 14 5L19 5C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6L20 12L20 14L4 14L4 12L4 10C3 10 3.26522 10 3 10C3.26522 10 2 10 3 10C2.78909 10 3 10 2 10L2 15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18ZM4 14L20 14L20 15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16L5 16C4.73478 16 4.48043 15.8946 4.29289 15.7071C4.10536 15.5196 4 15.2652 4 15L4 14Z"
                                fill="#F2F2F2"/>
                        </svg>
                    }/>
    </div>
)

export default StripeCard;
