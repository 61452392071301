import {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import Wrapper from './OnboardingWrapper';
import classes from './styles/categories.module.scss';
import Button from '../../Components/CustomComponents/Onboarding/Button';
import artwork_img from '../../assets/img/onboarding/artwork9.png'
import API from '../../api/API';
import Keys from '../../Constants/helper';
import {clearCookies} from '../../Constants/clearCookies';
import {useDispatch, useSelector} from "react-redux";
import historyDetectBack from "./HistoryBackDetect";
import {setUserData} from "../../main-store/auth/actions";

const headerToken = !localStorage.getItem(Keys.JWT_TOKEN) ? Keys.JWT_TOKEN_REGISTRATION : null;

const Category = props => (
    <div className={classes.category_wrapper}>
        <div
            className={classNames(classes.category, {[classes.selected]: props.selected})}
            onClick={props.onClick}
        >
            <div className={classes.category__text}>{props.name}</div>
        </div>
    </div>
);

function Categories() {
    const [categories, setCategories] = useState([]);
    const [filter, setFilter] = useState('');
    const store = useSelector(state => state.auth)
    let ref = useRef();
    let dispatch = useDispatch()

    const getCategories = () => {
        API(headerToken).get(`/user/categories`)
            .then(response => {
                // console.log(response)
                let categories = [];
                if (!response.data.categories) {
                    return;
                }

                categories = response.data.categories.map(ct => ({
                    name: ct,
                    selected: false,
                }));

                setCategories(categories);
            })
            .catch(e => console.error(e));
    }

    useEffect(() => {
        getCategories()
    }, []);

    const _onCategoryClick = name => {
        const newCategories = [...categories];
        const category = newCategories.find(ct => ct.name === name);
        const index = newCategories.indexOf(category);

        if (categories.filter(({selected}) => selected).length > 4 && !newCategories[index].selected) {
            return;
        }
        newCategories[index].selected = !category.selected;
        setCategories(newCategories);
    }

    const _onSubmit = () => {
        const selected = categories.filter(ct => ct.selected).map(ct => ct.name);
        if (!selected.length) {
            return;
        }

        const formData = new FormData();
        Object.entries(store.user_registration_data).forEach(i => {
            if (i[0] !== 'step') {
                if (store.user_registration_data.type === "collector") {
                    formData.append(i[0], i[1]);
                } else {
                    if (i[0] !== "photos") {
                        formData.append(i[0], i[1]);
                    }
                }
            }
        })
        let filterCategoriesName = categories.map(i => {
            if (i.selected) {
                return i.name

            } else {
                return null
            }
        }).filter(Boolean)
        formData.append('categories', filterCategoriesName);

        // for (let key of formData.entries()) {
        //     console.log(key);
        // }

        API(headerToken).post(`/user/profile/edit`, formData)
            .then(res => {
                let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
                let userData = {
                    name: res.data.name,
                    role: res.data.role
                }
                if (!headerToken) {
                    dispatch(setUserData(userData))
                    window.location.pathname = nextHistory;
                } else {
                    clearCookies();
                    sessionStorage.removeItem(Keys.REGISTRATION_DATA);

                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    dispatch(setUserData(userData))
                    window.location.pathname = nextHistory;
                }
            })
            .catch(e => {
                console.error(e);
            })
    }

    const filteredCategories = useMemo(() => {
        if(JSON.parse(localStorage.getItem(Keys.FROM_APP)))
            return categories.filter(el => el.name !== "NFT")
        return categories
    }, [categories])

    return (
        <Wrapper>
            <Wrapper.Picture src={artwork_img}/>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <p className={classes.uptitle}>Choose 5 categories to continue</p>
                    <h1 className={classes.title}>Choose your Categories</h1>
                    <div className={classes.search_input}>
                        <input
                            onChange={({target}) => setFilter(target.value)}
                            value={filter}
                            className={classes.search_control}
                            placeholder="Search for Category..."
                        />
                    </div>
                    <div className={classes.categories_wrapper} ref={ref}>
                        {filteredCategories
                            .filter(ct => {
                                return ct.name.toLowerCase().includes(filter.toLocaleLowerCase());
                            })
                            .map(({name, selected}) => (
                                <Category
                                    key={name}
                                    name={name}
                                    selected={selected}
                                    onClick={() => _onCategoryClick(name)}
                                />
                            ))}
                    </div>
                    <div className={classes.footer}>
                        <div className={classes.counter}>{categories.filter(ct => ct.selected).length ?? 0}/5 Categories
                            Chosen
                        </div>
                        <Button onClick={_onSubmit}>Continue &#62;</Button>
                    </div>
                </div>
            </Wrapper.Form>
        </Wrapper>
    );
}

export default Categories;
