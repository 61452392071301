import React from 'react'
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import TextInput from "../../Components/CustomComponents/TextInput";
import delete_blog_icon from "../../assets/img/admin/delete_blog_icon.png";

export default function AddBlogSection(props) {
    const {
        editorState, setEditorState, setEditorMarkup, convertToRaw,
        state, setArticleData, arrIndex, uploadFiles, articleData,
        editorMarkup,
    } = props;

    return (
        <div className={'add_blog_section'}>
            <div className={'line'}/>

            <div className={'delete_blog_section'}>
                <span className={'subtitle'}>Title {arrIndex + 1}</span>
                <img className={'delete'} onClick={() => {
                    setArticleData(prevState => ([
                        ...prevState.slice(0, arrIndex),
                        ...prevState.slice(arrIndex + 1)
                    ]))
                    setEditorState(prevState => ([
                        ...prevState.slice(0, arrIndex),
                        ...prevState.slice(arrIndex + 1)
                    ]))
                    setEditorMarkup(prevState => ([
                        ...prevState.slice(0, arrIndex),
                        ...prevState.slice(arrIndex + 1)
                    ]))
                }}
                     src={delete_blog_icon} alt="delete"/>
            </div>
            <TextInput
                type={'text'}
                margin={'0 0 1.5rem'}
                value={state.name.value}
                onChange={({target}) => {
                    if (target.value.length <= 200) {
                        let changeState = articleData.find((el, i) => i === arrIndex)
                        changeState = {
                            ...changeState,
                            name: {
                                value: target.value
                            }
                        }
                        setArticleData(prevState => ([
                            ...prevState.slice(0, arrIndex),
                            changeState,
                            ...prevState.slice(arrIndex + 1)
                        ]))
                    }
                }}
            />

            <div
                // style={state?.file?.previews?.url
                // ? {border: 'none'}
                // : {border: 'border: 1px dashed rgba(0, 0, 0, .3)'}}
                className={'photo_placeholder'}>
                <label htmlFor={`article_photo_${arrIndex}`}>
                    Select image
                </label>
                <input type="file"
                       id={`article_photo_${arrIndex}`}
                       onClick={({target}) => target.value = ''}
                       accept="image/jpeg,image/png, .jpeg,.jpg,.png"
                       onChange={e => uploadFiles(e, 'file', true, arrIndex)}
                />
                {state?.file?.previews?.url ?
                    <div className={'preview_image'}>
                        <svg onClick={(e) => {
                            e.preventDefault();
                            let changeState = articleData.find((el, i) => i === arrIndex)
                            changeState = {
                                ...changeState,
                                file: {
                                    ...changeState.file,
                                    previews: '',
                                    files: '',
                                },
                            }
                            setArticleData(prevState => ([
                                ...prevState.slice(0, arrIndex),
                                changeState,
                                ...prevState.slice(arrIndex + 1)
                            ]))
                        }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="2.46964" height="19.7571"
                                  transform="matrix(0.694131 0.719849 -0.694131 0.719849 13.7139 0)" fill="#8F9BB3"/>
                            <rect width="2.46964" height="19.7571"
                                  transform="matrix(-0.694131 0.719849 -0.694131 -0.719849 16 14.2227)" fill="#8F9BB3"/>
                        </svg>
                        <img src={state?.file?.previews?.url}
                             className={state?.file?.previews ? 'active_img' : null}
                             alt="preview"/>
                    </div>
                    : null}
            </div>

            <div className={'editor'}>
                <span className={'subtitle'}>Description {arrIndex + 1}</span>
                <Editor toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
                }}
                        editorState={editorState[arrIndex]}
                        onEditorStateChange={state => {
                            let changeState = editorState.find((el, i) => i === arrIndex)
                            changeState = state
                            setEditorState(prevState => ([
                                ...prevState.slice(0, arrIndex),
                                changeState,
                                ...prevState.slice(arrIndex + 1)
                            ]))
                            const rawContentState = convertToRaw(state.getCurrentContent());

                            const markup = draftToHtml(rawContentState);
                            // console.log(markup)
                            let changeEditorMarkup = editorMarkup[arrIndex]
                            changeEditorMarkup = markup
                            setEditorMarkup(prevState => ([
                                ...prevState.slice(0, arrIndex),
                                changeEditorMarkup,
                                ...prevState.slice(arrIndex + 1)
                            ]))
                        }}
                />
            </div>
        </div>
    )
}