import React, {useEffect, useState} from 'react'
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import ModalWindow from "../../Components/ModalWindow";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import close_modal from "../../assets/img/admin/close_modal.svg";
import API from "../../api/API";
import Keys from "../../Constants/helper";
import useBottomPopup from "../../Constants/useBottomPopup";
import {getActivePage} from "../../Constants/getActivePage";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import NewPaginationView from "../../Components/NewPaginationView";

export default function SupportComponent(props) {
    const {activeHashUrl} = useGetLastChartHash();

    const {requestHandle, pageTitle, getTicketsData, activeModal, setActiveModal, requestDataTab} = props

    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const [loading, setLoading] = useState(null)
    const [activeData, setActiveData] = useState(null)
    const [activePage, setActivePage] = useState({
        page: activeHashUrl ? activeHashUrl : 1,
        pages: null,
        total: null
    });
    const [activeTab, setActiveTab] = useState({
        value: 1,
        active: 'open'
    })

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl
            }))
        }
    }, [activeHashUrl])

    useEffect(() => {
        let requestTab = requestDataTab === 'new' ? `&new=${activeTab.value}` : `&open=1`
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/${requestHandle}?page=${activePage.page}&per_page=10${requestTab}`)
            .then(res => {
                if (res.data) {
                    // console.log(res)
                    setActiveData(res.data.tickets)
                    setActivePage(prevState => ({
                        ...prevState,
                        pages: res.data.pages,
                        total: res.data.total,
                    }))
                    setLoading(true)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [activeTab.value, activePage.page, requestHandle, requestDataTab])

    const markAsResolved = () => {
        bodyOverflow(false)
        API(Keys.JWT_TOKEN_ADMIN).post(`admin/contact_us_ticket?id=${activeModal.value}`)
            .then(() => {
                // console.log(res)
                changeContinuePopup('Marked as resolved')
                setTimeout(() => {
                    changeContinuePopup('Marked as resolved')
                }, 2000)
                setActiveData(prevState => prevState.filter(el => el.id !== activeModal.value))
                setActiveModal({
                    value: null,
                    data: null
                })
            })
    }

    return (
        <>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <div className={'admin_content_wrapper admin_content_wrapper_tickets'}>
                <div className={'head'}>
                    <h1>{pageTitle}</h1>
                    <div>
                        <h3 className={activeTab?.value === 1 ? 'active_tab' : null}
                            onClick={() => {
                                setActivePage(prevState => ({
                                    ...prevState,
                                    page: 1
                                }))
                                getActivePage(1)
                                setActiveTab({
                                    value: 1,
                                    active: 'open'
                                })
                            }}>Open</h3>
                        <h3 className={activeTab?.value === 0 ? 'active_tab' : null}
                            onClick={() => {
                                setActivePage(prevState => ({
                                    ...prevState,
                                    page: 1
                                }))
                                getActivePage(1)
                                setActiveTab({
                                    value: 0,
                                    active: 'resolved'
                                })
                            }}>Resolved</h3>
                    </div>
                </div>

                <div className={'subtitle'}>
                    <h4>DateTime</h4>
                    <h4>Name</h4>
                    <h4>{requestDataTab === 'new' ? 'Topic' : 'Order number'}</h4>
                </div>

                <div style={!activeData?.length ? {padding: '1rem 0 0'} : null} className={'content'}>
                    {loading ? activeData.length ?
                        activeData.map(item => (
                            <div key={item.id}>
                                <span>{item.time}</span>
                                <span>{item.name.length > 25 ?
                                    item.name.substring(0, 25) + '...' :
                                    item.name ?? 'name'}</span>
                                {item.topic ?
                                    <span>{item.topic.length > 20 ?
                                        item.topic.substring(0, 20) + '...' :
                                        item.topic}</span>
                                    : <span>{item.id}</span>}
                                <button onClick={() => {
                                    if (requestDataTab === 'new') {
                                        getTicketsData(item)
                                    }
                                }}>Details
                                </button>
                            </div>
                        )) : <span className={'admin_users_list_no_items no_items_style'}>No items</span> : null}
                </div>
            </div>

            {loading && activePage.total > 9 ?
                <div className={'wrapper_new_pagination admin_pagination'}>
                    <NewPaginationView activePage={activePage}
                                    onChange={pageNumber => {
                                        getActivePage(pageNumber)
                                        setActivePage(prevState => ({
                                            ...prevState,
                                            page: pageNumber
                                        }))
                                    }}/>
                </div>
                : null}

            {activeModal?.value ?
                <div className={'admin_wrapper_modal admin_wrapper_modal_tickets'}>
                    <ModalWindow nestedObj setActiveModal={val => setActiveModal(val)}>
                        <img onClick={() => {
                            bodyOverflow(false)
                            setActiveModal({
                                value: null,
                                data: null
                            })
                        }} src={close_modal} alt="close modal"/>
                        <h2>Support Ticket</h2>
                        <div className={'line'}/>
                        <div className={'inline'}>
                            <div className={'content'}>
                                <h6>Name:</h6>
                                <span>{activeModal.data.name ?? 'Name'}</span>
                            </div>
                            <div className={'content'}>
                                <h6>Usage type:</h6>
                                <span>{activeModal.data.role}</span>
                            </div>
                        </div>
                        <div className={'content'}>
                            <h6>Category:</h6>
                            <span>{activeModal.data.category}</span>
                        </div>
                        <div className={'content'}>
                            <h6>Email:</h6>
                            <span>{activeModal.data.email}</span>
                        </div>
                        <h5>Message:</h5>
                        <p style={activeTab.active !== 'open' ? {margin: '0'} : {margin: '0 0 1.5rem'}}>
                            {activeModal.data.message}</p>
                        {activeTab.active === 'open' ?
                            <div className={'button'}>
                                <div className={'line'}/>
                                <button onClick={() => markAsResolved()}>Mark as resolved</button>
                            </div>
                            : null}
                    </ModalWindow>
                </div> : null}
        </>
    )
}