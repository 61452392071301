import React from "react";
import {useDropzone} from "react-dropzone";
import './styles/dropzone_styles.css';

export default function DropzonePhotoContainer(props) {

    const {data, setData, index, biggerContainer, setArtworkFilesError, artworkFilesError} = props;

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: (acceptedFiles, fileRejections) => {
            // console.log(acceptedFiles)
            fileRejections.forEach(el => el.errors.some(item => {
                if (item.message.includes("File type must be image/*")) {
                    alert('File type must be image')
                    return null
                } else {
                    return null
                }
            }))
            if (fileRejections.length > 1) {
                alert('Maximum allowed 1 photo')
                return null
            } else {
                setArtworkFilesError(false)
                if (acceptedFiles.some(el => el.type.includes('svg+xml'))) {
                    alert('svg is not allowed')
                } else {
                    setData(prevState => (
                        [...prevState.slice(0, index),
                            acceptedFiles.map(file => {
                                return (Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                }))
                            }),
                            ...prevState.slice(index + 1)
                        ]))
                }
            }
        }
    })

    return (
        <div onClickCapture={e => {
            if (Object.keys(data[index]).length) {
                e.preventDefault();
            }
        }} key={index} {...getRootProps({className: 'dropzone'})}>
            <input accept="image/png, image/jpg, image/jpeg, image/gif, image/webp" {...getInputProps()} />
            <div style={artworkFilesError ? {border: '2px solid var(--new-error)'} : null}
                 className={'photo_wrapper'}>
                <div className={`photo_content ${index === 0 ? 'photo_content_big' : ''}`}>
                    {data[index][0]
                        ? <div className={'delete_preview'}>
                            <div className={'delete_icon'}>
                                <svg onClick={(e) => {
                                    e.preventDefault();
                                    setData(prevState => (
                                        [...prevState.slice(0, index),
                                            {},
                                            ...prevState.slice(index + 1)
                                        ]))
                                }} width="30" height="30" viewBox="0 0 30 30" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.66016 4.66211L25.7548 25.7568" stroke="white" strokeWidth="0.812531"/>
                                    <path d="M4.66016 25.1719L25.7548 4.07718" stroke="white" strokeWidth="0.812531"/>
                                </svg>
                            </div>
                            <img key={2} src={data[index][0].preview}
                                 alt={'preview'}/>
                        </div>
                        : <div className={'no_preview_photo'}>
                            {biggerContainer
                                ? [
                                    <svg key={0} width="57" height="57" viewBox="0 0 57 57" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.3329 15.5811L19.3322 15.5819C19.1011 15.8142 18.8266 15.9986 18.5242 16.1246C18.2217 16.2505 17.8975 16.3156 17.5699 16.3161C17.2423 16.3165 16.9178 16.2523 16.615 16.1272C16.3123 16.002 16.0372 15.8183 15.8056 15.5867C15.5739 15.355 15.3903 15.08 15.2651 14.7772C15.1399 14.4745 15.0757 14.15 15.0762 13.8224C15.0766 13.4948 15.1417 13.1705 15.2677 12.8681L14.895 12.7128L15.2677 12.8681C15.3937 12.5657 15.5781 12.2911 15.8104 12.0601L15.8112 12.0593L26.7347 1.1357L26.7347 1.1357C26.9659 0.90444 27.2404 0.720996 27.5426 0.595839C27.8447 0.470683 28.1686 0.406265 28.4956 0.406265C28.8226 0.406265 29.1465 0.470683 29.4486 0.595839L29.6041 0.220501L29.4486 0.595839C29.7508 0.720996 30.0253 0.90444 30.2565 1.1357L30.2566 1.1357L41.1801 12.0593L41.1808 12.0601C41.4131 12.2911 41.5975 12.5657 41.7235 12.8681C41.8495 13.1705 41.9146 13.4948 41.915 13.8224C41.9155 14.15 41.8513 14.4745 41.7261 14.7772C41.6009 15.08 41.4173 15.355 41.1856 15.5867C40.954 15.8183 40.6789 16.002 40.3762 16.1272C40.0734 16.2523 39.7489 16.3165 39.4213 16.3161C39.0938 16.3156 38.7695 16.2505 38.4671 16.1246C38.1646 15.9986 37.8901 15.8142 37.6591 15.5819L37.6583 15.5811L31.6794 9.60227L30.9859 8.90873V9.88954V41.5174C30.9859 42.1778 30.7235 42.8112 30.2565 43.2783C29.7895 43.7453 29.1561 44.0076 28.4956 44.0076C27.8351 44.0076 27.2017 43.7453 26.7347 43.2783C26.2677 42.8112 26.0053 42.1778 26.0053 41.5174V9.88954V8.90873L25.3118 9.60227L19.3329 15.5811Z"
                                            fill="#F7F6F1" stroke="black" strokeWidth="0.812531"/>
                                        <path
                                            d="M50.7069 50.6152H51.1132V50.2089V28.002C51.1132 27.3416 51.3755 26.7081 51.8426 26.2411C52.3096 25.7741 52.943 25.5117 53.6034 25.5117C54.2639 25.5117 54.8973 25.7741 55.3643 26.2411C55.8314 26.7081 56.0937 27.3416 56.0937 28.002V51.1744C56.0937 52.347 55.6279 53.4716 54.7988 54.3008C53.9696 55.1299 52.845 55.5958 51.6724 55.5958H5.32759C4.15498 55.5958 3.0304 55.1299 2.20124 54.3008C1.37208 53.4716 0.906265 52.347 0.906265 51.1744V28.002C0.906265 27.3416 1.16863 26.7081 1.63565 26.2411C2.10267 25.7741 2.73609 25.5117 3.39655 25.5117C4.05702 25.5117 4.69043 25.7741 5.15745 26.2411C5.62447 26.7081 5.88684 27.3416 5.88684 28.002V50.2089V50.6152H6.2931H50.7069Z"
                                            fill="#F7F6F1" stroke="black" strokeWidth="0.812531"/>
                                    </svg>,
                                    <p key={1}><span>Choose a file </span>or drag & drop it here</p>
                                ]
                                : <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.97483 5.98312L7.97405 5.9839C7.90633 6.05199 7.82585 6.10604 7.73721 6.14297C7.64857 6.17989 7.55352 6.19897 7.4575 6.1991C7.36148 6.19923 7.26637 6.18041 7.17763 6.14373C7.0889 6.10704 7.00827 6.05321 6.94037 5.98531C6.87247 5.91741 6.81864 5.83678 6.78195 5.74804L6.41066 5.90154L6.78195 5.74804C6.74526 5.65931 6.72645 5.5642 6.72658 5.46818C6.72671 5.37216 6.74578 5.2771 6.78271 5.18846C6.81964 5.09983 6.87369 5.01934 6.94178 4.95163L6.94256 4.95085L11.2274 0.665974L11.2274 0.665969C11.2952 0.598184 11.3756 0.544414 11.4642 0.50773C11.5528 0.471045 11.6477 0.452164 11.7435 0.452164C11.8394 0.452164 11.9343 0.471045 12.0229 0.50773L12.1766 0.13655L12.0229 0.50773C12.1114 0.544415 12.1919 0.598184 12.2597 0.665968L12.2597 0.665974L16.5445 4.95085L16.5453 4.95163C16.6134 5.01934 16.6674 5.09983 16.7044 5.18846C16.7413 5.2771 16.7604 5.37215 16.7605 5.46818C16.7606 5.5642 16.7418 5.65931 16.7051 5.74804C16.6684 5.83678 16.6146 5.91741 16.5467 5.98531C16.4788 6.05321 16.3982 6.10704 16.3095 6.14373C16.2207 6.18041 16.1256 6.19923 16.0296 6.1991C15.9336 6.19897 15.8385 6.17989 15.7499 6.14297C15.6612 6.10604 15.5808 6.05199 15.513 5.9839L15.5123 5.98312L13.167 3.63787L12.4735 2.94433V3.92515V16.3314C12.4735 16.525 12.3966 16.7106 12.2597 16.8475C12.1228 16.9844 11.9371 17.0613 11.7435 17.0613C11.55 17.0613 11.3643 16.9844 11.2274 16.8475C11.0905 16.7107 11.0136 16.525 11.0136 16.3314V3.92515V2.94434L10.3201 3.63787L7.97483 5.98312Z"
                                        fill="#F7F6F1" stroke="black" strokeWidth="0.812531"/>
                                    <path
                                        d="M20.4558 20.1468H20.862V19.7406V11.0297C20.862 10.8362 20.9389 10.6505 21.0758 10.5136L20.7896 10.2274L21.0758 10.5136C21.2127 10.3767 21.3984 10.2998 21.592 10.2998C21.7855 10.2998 21.9712 10.3767 22.1081 10.5136C22.245 10.6505 22.3219 10.8362 22.3219 11.0297V20.1193C22.3219 20.5138 22.1652 20.8921 21.8862 21.1711C21.6073 21.45 21.229 21.6067 20.8345 21.6067H2.65538C2.2609 21.6067 1.88257 21.45 1.60363 21.1711L1.31636 21.4583L1.60363 21.1711C1.32469 20.8921 1.16798 20.5138 1.16798 20.1193V11.0297C1.16798 10.8362 1.24489 10.6505 1.38178 10.5136C1.51866 10.3767 1.70432 10.2998 1.89791 10.2998C2.0915 10.2998 2.27716 10.3767 2.41405 10.5136C2.55094 10.6505 2.62784 10.8362 2.62784 11.0297V19.7406V20.1468H3.03411H20.4558Z"
                                        fill="#F7F6F1" stroke="black" strokeWidth="0.812531"/>
                                </svg>}
                        </div>}
                </div>
            </div>
        </div>
    )
}