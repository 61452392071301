import React, {createContext, useContext, useState} from "react";
import {useHistory} from "react-router-dom";

const authContext = createContext();
export default function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}


const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
        let promise = new Promise(res => {
            res(fakeAuth.isAuthenticated = true);
        });
        promise.then(() => cb());
    },
    signout(cb) {
        let promise = new Promise(res => {
            res(fakeAuth.isAuthenticated = false);
        });
        promise.then(() => cb());
    }
};


export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const signin = cb => {
        return fakeAuth.signin(() => {
            setUser("user");
            cb();
        });
    };

    const signout = cb => {
        return fakeAuth.signout(() => {
            setUser(null);
            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}

export function AuthButton() {
    let history = useHistory();
    let auth = useAuth();

    return auth.user ? (
        <p>
            Welcome!{" "}
            <button
                onClick={() => {
                    auth.signout(() => history.push("/"));
                }}>
                Sign out
            </button>
        </p>
    ) : (
        <p>You are not logged in.</p>
    );
}