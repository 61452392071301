import React from 'react';
import './styles/range_slider.css'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'

const NewRangeSliderMultiple = ({value, setValue, max_price, step, error, disabled}) => {
    return (
        <div className={`range_slider_new ${error ? 'range_slider_new_error' : ''}`}>
            <InputRange
                maxValue={max_price}
                minValue={0}
                step={step}
                disabled={disabled}
                value={value}
                onChange={value => setValue({value})}
            />
        </div>
    )
}
export default NewRangeSliderMultiple
