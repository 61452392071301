import {Space} from "antd";

import {defaultBase64Image} from "../../../Constants/images";

import styles from "./StayBaseInfo.module.css";

export default function StayBaseInfo({ address, content, image, name, type }) {
    return (
        <div className={styles.root}>
            <div className={styles.imageWrapper}>
                <img
                    src={image ? process.env.REACT_APP_API_URL + '/' + image : defaultBase64Image}
                    alt=""
                />
            </div>
            <Space direction="vertical" size={10}>
                {type && <div className={styles.text}>{type}</div>}
                <h3 className={styles.title}>{name}</h3>
                <div className={styles.text}>{address}</div>
                {content}
            </Space>
        </div>
    )
}