import {Redirect, Route} from "react-router-dom";
import React from "react";
import helper from '../Constants/helper'

export default function PrivateRoute({children, ...rest}) {
    let getToken = localStorage.getItem(helper.JWT_TOKEN)

    return (
        <Route
            {...rest}
            render={({location}) => (
                getToken ?
                    children :
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
            )}
        />
    );
}

//const PrivateRoute = ({component: Component, ...rest}) => {
//     let responseComponent;
//
//     let getToken = getValueFromCookie(helper.JWT_TOKEN)
//
//     if (getToken) {
//         responseComponent = props => (<MainNavigationProfile {...props} />);
//     } else responseComponent = () => (<Redirect to='/' />);
//
//     return <Route {...rest} render={responseComponent}/>;
// };
//
// export function PublicPage() {
//     return <h3>Public</h3>;
// }
//
// export function ProtectedPage() {
//     return <h3>Protected</h3>;
// }

// export function LoginPage() {
//     let history = useHistory();
//     let location = useLocation();
//     let auth = useAuth();
//     console.log(location.state);
//     let {from} = location.state || {from: {pathname: "/"}};
//
//     let login = () => {
//         auth.signin(() => {
//             history.replace(from);
//         });
//     };
//
//     return (
//         <div>
//             <p>You must log in to view the page at {from.pathname}</p>
//             <button onClick={login}>Log in</button>
//         </div>
//     );
// }
