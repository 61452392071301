import React, {useCallback, useRef, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import sliderArrowLeft from "../assets/img/slider_arrow_left.svg";
// import sliderArrowRight from "../assets/img/slider_arrow_right.svg";
import Viewer from "react-viewer";
import useResize from "../Constants/useResize";
import {LazyLoadImage} from "react-lazy-load-image-component"; // This only needs to be imported once in your app

const PriceArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'art_price_body_slider_arrow art_price_body_slider_arrow_right'}>
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0H11.514L0 10.0213L11.514 20H14L2.48598 10.0213L14 0Z" fill="white"/>
        </svg>
    </div>
);

const PriceArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'art_price_body_slider_arrow art_price_body_slider_arrow_left'}>
        <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H2.48598L14 10.0213L2.48598 20H0L11.514 10.0213L0 0Z" fill="white"/>
        </svg>
    </div>
);


const PublicArtwork = ({artwork}) => {
    const [imageViewer, setImageViewer] = useState(false)
    const [activeSlide, segActiveImage] = useState(0)
    const slider1Ref = useRef()
    const slider2Ref = useRef();
    const {innerWidth} = useResize()
    const [swiped, setSwiped] = useState(false);

    const bodySettings = {
        slidesToShow: artwork.pictures.length,
        swipeToSlide: true,
        focusOnSelect: true,
        lazyLoad: "progressive",
    }

    const headSettings = {
        infinite: artwork.pictures.length > 1,
        arrows: innerWidth >= 992,
        slidesToShow: 1,
        swipeToSlide: true,
        slidesToScroll: 1,
        fade: true,
        nextArrow: <PriceArrowLeft/>,
        prevArrow: <PriceArrowRight/>,
        lazyLoad: "progressive",
    }


    const imgListViewer = useCallback(() => {
        return artwork.pictures.map(i => ({
            src: i,
            alt: 'preview_img'
        }));
    }, [artwork.pictures])

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        } else {
            setImageViewer(true)
        }
    }, [swiped])


    return (
        <div className={'wrapper_artwork_slider'}>
            {/*{artwork.sold ?*/}
            {/*    <div className={'artwork_sold'}><span>Sold</span></div>*/}
            {/*    : null}*/}
            <Slider asNavFor={slider2Ref.current}
                    {...headSettings}
                    beforeChange={(oldIndex, newIndex) => {
                        segActiveImage(newIndex)
                    }}
                    onSwipe={handleSwiped}
                    ref={slider1Ref}>
                {artwork.pictures.map((p, index) => (
                    <div className={'every_image'} key={index}
                         onClickCapture={handleOnItemClick}>
                        <LazyLoadImage effect={"blur"} src={p} alt={`${artwork.title} - Artwork`} wrapperClassName={"lazy_img"}/>
                    </div>
                ))}
            </Slider>
            <div className={'wrapper_artwork_slider__body'}>
                <Slider
                    asNavFor={slider1Ref.current}
                    ref={slider2Ref}
                    {...bodySettings}>
                    {artwork.pictures.map((p, index) => (
                        <div className={'every_image_body'} key={index}>
                            <LazyLoadImage effect={"blur"} src={p} alt={`${artwork.title} - Artwork`}  wrapperClassName={"lazy_img"}/>
                        </div>
                    ))}
                </Slider>
            </div>
            <Viewer
                visible={imageViewer}
                zIndex={3600}
                scalable={null}
                showTotal={null}
                attribute={null}
                onMaskClick={() => setImageViewer(false)}
                activeIndex={activeSlide}
                onClose={() => setImageViewer(false)}
                images={imgListViewer()}
            />
        </div>
    )
}

export default PublicArtwork
