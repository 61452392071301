import React from 'react';
import {Route} from "react-router-dom";
import {Navigation} from './navigation'
import {WrapperApp} from './MainRouter'

class Routers extends React.Component {
    render() {
        return (
            <WrapperApp>
                {Navigation.mainNavigation.map(route => (
                    <Route path={route.path}
                           exact
                           {...this.props}
                           component={route.main}
                           key={route.path}/>
                ))}
            </WrapperApp>
        )
    }
}


export default Routers;