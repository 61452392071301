import React, {useRef} from "react";
import MainButton from "../CustomComponents/MainButton";
import TextInput from "../CustomComponents/TextInput";
import {DatePicker} from 'antd';
import CustomDropdown from "../CustomComponents/Dropdown";
import moment from 'moment';
import API from "../../api/API";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";

// import 'antd/dist/antd.min.css'

const {RangePicker} = DatePicker;

const CvEntriesSectionBody = ({data: {bodyFields, sectionName}, setData, onSubmitEntry}) => {
    const monthFormat = 'YYYY/MM';
    let fileUploadRef = useRef();

    const fileUpload = event => {
        event.preventDefault();
        let files = event.target.files;
        if (files[0]) {
            setData(prevState => ({
                ...prevState,
                [sectionName]: {
                    ...prevState[sectionName],
                    bodyFields: {
                        ...prevState[sectionName].bodyFields,
                        proof_document: {
                            ...prevState[sectionName].bodyFields.proof_document,
                            value: files[0],
                            preview: files[0].name
                        }
                    }
                }
            }))
        }
    }

    function onChangeRangePicker(dates, dateStrings) {
        setData(prevState => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName],
                bodyFields: {
                    ...prevState[sectionName].bodyFields,
                    date: {
                        ...prevState[sectionName].bodyFields.date,
                        value: dateStrings[0] ? `${dateStrings[0]}-${dateStrings[1]}` : null,
                        error: false

                    }
                }
            }
        }))
    }

    function onChangePicker(dates, dateStrings) {
        setData(prevState => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName],
                bodyFields: {
                    ...prevState[sectionName].bodyFields,
                    date: {
                        ...prevState[sectionName].bodyFields.date,
                        value: dateStrings,
                        error: false
                    }
                }
            }
        }))
    }


    const onFileDelete = () => {
        setData(prevState => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName],
                bodyFields: {
                    ...prevState[sectionName].bodyFields,
                    proof_document: {
                        ...prevState[sectionName].bodyFields.proof_document,
                        value: null,
                        preview: null
                    }
                }
            }
        }))
    }

    return (
        <form className={'cv_entries_section_body'}
              onSubmit={e => onSubmitEntry(e, sectionName)}>
            {bodyFields.context_field.type === 'drop_down' ?
                <div className="wrapper_context_field">

                    <CustomDropdown value={bodyFields.context_field.value}
                                    setInputValue={val => setData(val)}
                                    call
                                    errorTitle={bodyFields.context_field.errorTitle}
                                    optionLabel={'context_field'}
                                    nestedObject={'bodyFields'}
                                    rootObject={sectionName}
                                    entryError={bodyFields.context_field.error}
                                    error={bodyFields.context_field.error}
                                    newError={bodyFields.context_field.error}
                                    search
                                    typeId
                                    borderDropdown
                                    dropdownSelect
                                    newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                            fill="black"/>
                                    </svg>
                                    }
                                    title={bodyFields.context_field.title}
                                    defaultDropdown
                                    onChange={({target}) => {
                                        let {value} = target;
                                        setData(prevState => ({
                                            ...prevState,
                                            [sectionName]: {
                                                ...prevState[sectionName],
                                                bodyFields: {
                                                    ...prevState[sectionName].bodyFields,
                                                    context_field: {
                                                        ...prevState[sectionName].bodyFields.context_field,
                                                        value: value,
                                                        error: false,
                                                        popup: true,
                                                    }
                                                }
                                            }
                                        }))
                                        setTimeout(() => {
                                            API().get(`/user/profile/cv/data?type=${capitalizeFirstLetter(sectionName)}&name=${value}`)
                                                .then(res => {
                                                    setData(prevState => ({
                                                        ...prevState,
                                                        [sectionName]: {
                                                            ...prevState[sectionName],
                                                            bodyFields: {
                                                                ...prevState[sectionName].bodyFields,
                                                                context_field: {
                                                                    ...prevState[sectionName].bodyFields.context_field,
                                                                    options: res.data.events
                                                                }
                                                            }
                                                        }
                                                    }))
                                                })
                                        })
                                    }}
                                    dropdownPadding={'5px 16px'}
                                    popup={bodyFields.context_field.popup}
                                    options={bodyFields.context_field.options}/>
                </div>
                : null}
            <div className={bodyFields.date.error ? 'wrapper_picker wrapper_picker_error' : 'wrapper_picker'}>
                <span className={'title'}>When</span>
                {bodyFields.date.type === 'range' ?
                    <RangePicker picker="month"
                                 format={monthFormat}
                                 value={bodyFields.date.value ?
                                     [moment(bodyFields.date.value.split('-')[0], monthFormat),
                                         moment(bodyFields.date.value.split('-')[1], monthFormat)] : ''}
                                 onChange={onChangeRangePicker}/> :
                    <DatePicker format={monthFormat}
                                value={bodyFields.date.value ? moment(bodyFields.date.value, monthFormat) : ''}
                                picker="month"
                                onChange={onChangePicker}/>
                }
                {bodyFields.date.error ?
                    <div className={'entry_error'}>
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z"
                                fill="#FF4949"/>
                            <path
                                d="M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z"
                                fill="#FF4949"/>
                            <path
                                d="M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z"
                                fill="#FF4949"/>
                        </svg>
                        <span className={'error_message'}>{'Fill this label'}</span>
                    </div>
                    : null}
            </div>
            {bodyFields.context_field.type === 'text' ?
                <TextInput type={'text'}
                           title={bodyFields.context_field.title}
                           margin={'0 0 1.5rem'}
                           value={bodyFields.context_field.value}
                           error={bodyFields.context_field.error}
                           entryError={bodyFields.country.error}
                           newError={bodyFields.context_field.error}
                           maxLength={25}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   [sectionName]: {
                                       ...prevState[sectionName],
                                       bodyFields: {
                                           ...prevState[sectionName].bodyFields,
                                           context_field: {
                                               ...prevState[sectionName].bodyFields.context_field,
                                               value: target.value,
                                               error: false
                                           }
                                       }
                                   }
                               }))
                           }}
                />
                : null}
            {bodyFields.country ?
                <CustomDropdown value={bodyFields.country.value}
                                setInputValue={val => setData(val)}
                                optionLabel={'country'}
                                entryError={bodyFields.country.error}
                                error={bodyFields.country.error}
                                newError={bodyFields.country.error}
                                nestedObject={'bodyFields'}
                                rootObject={sectionName}
                                errorTitle={bodyFields.country.errorTitle}
                                search
                                borderDropdown
                                dropdownSelect
                                newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                        fill="black"/>
                                </svg>}
                                title={'Country'}
                                defaultDropdown
                                onChange={({target}) => {
                                    setData(prevState => ({
                                        ...prevState,
                                        [sectionName]: {
                                            ...prevState[sectionName],
                                            bodyFields: {
                                                ...prevState[sectionName].bodyFields,
                                                country: {
                                                    ...prevState[sectionName].bodyFields.country,
                                                    error: false,
                                                    value: target.value,
                                                    popup: true
                                                }
                                            }
                                        }
                                    }))
                                }}
                                dropdownPadding={'5px 16px'}
                                popup={bodyFields.country.popup}
                                options={bodyFields.country.options}/>
                : <div className="context_field">
                    <TextInput type={'text'}
                               placeholder={'Max. 20 characters'}
                               maxLength={25}
                               title={bodyFields.context_field_description.title}
                               margin={'0 0 1.5rem'}
                               error={bodyFields.context_field_description.error}
                               entryError={bodyFields.context_field.error}
                               newError={bodyFields.context_field.error}
                               value={bodyFields.context_field_description.value}
                               onChange={({target}) => {
                                   setData(prevState => ({
                                       ...prevState,
                                       [sectionName]: {
                                           ...prevState[sectionName],
                                           bodyFields: {
                                               ...prevState[sectionName].bodyFields,
                                               context_field_description: {
                                                   ...prevState[sectionName].bodyFields.context_field_description,
                                                   error: false,
                                                   value: target.value
                                               }
                                           }
                                       }
                                   }))
                               }}/>
                </div>}
            {sectionName === 'education' ?
                <div className={'wrapper_context_description'}>
                    <TextInput type={'text'}
                               title={bodyFields.context_field_description.title}
                               margin={'0 0 1.5rem'}
                               error={bodyFields.context_field_description.error}
                               entryError={bodyFields.context_field.error}
                               newError={bodyFields.context_field.error}
                               value={bodyFields.context_field_description.value}
                               onChange={({target}) => {
                                   setData(prevState => ({
                                       ...prevState,
                                       [sectionName]: {
                                           ...prevState[sectionName],
                                           bodyFields: {
                                               ...prevState[sectionName].bodyFields,
                                               context_field_description: {
                                                   ...prevState[sectionName].bodyFields.context_field_description,
                                                   error: false,
                                                   value: target.value
                                               }
                                           }
                                       }
                                   }))
                               }}/>
                </div>
                : null}
            <div className={'wrapper_proofs'}>
                <div className={'wrapper_proofs__file'}>
                    <span className={'title'}>{bodyFields.proof_document.title}</span>
                    <input type="file"
                           hidden
                           ref={fileUploadRef}
                           accept="application/pdf"
                           onChange={fileUpload}/>
                    <div className={'wrapper_proofs__file_container'}
                         onClick={e => {
                             if (e.target.getAttribute('data-key') === 'delete_file') {
                                 onFileDelete()
                             } else {
                                 fileUploadRef.current.click()
                             }
                         }}>
                        {bodyFields.proof_document.preview ?
                            [
                                <svg width="22" height="22" viewBox="0 0 26 26" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" key={1}>
                                    <path
                                        d="M9.26549 7.89561L11.7811 5.37995V18.6876C11.7811 19.0109 11.9095 19.3209 12.1381 19.5494C12.3667 19.778 12.6767 19.9064 12.9999 19.9064C13.3231 19.9064 13.6331 19.778 13.8617 19.5494C14.0902 19.3209 14.2186 19.0109 14.2186 18.6876V5.37995L16.7343 7.89561C16.8474 8.00928 16.9817 8.09953 17.1297 8.16119C17.2777 8.22285 17.4364 8.2547 17.5968 8.25492C17.7571 8.25513 17.9159 8.22371 18.0641 8.16246C18.2122 8.10121 18.3468 8.01132 18.4602 7.89795C18.5736 7.78458 18.6635 7.64996 18.7247 7.5018C18.786 7.35363 18.8174 7.19484 18.8172 7.03451C18.817 6.87418 18.7851 6.71547 18.7234 6.56747C18.6618 6.41948 18.5715 6.2851 18.4579 6.17204L13.8617 1.57583C13.7485 1.46265 13.6142 1.37287 13.4663 1.31162C13.3184 1.25037 13.1599 1.21884 12.9999 1.21884C12.8398 1.21884 12.6814 1.25037 12.5335 1.31162C12.3856 1.37287 12.2513 1.46265 12.1381 1.57583L7.54193 6.17204C7.42825 6.2851 7.338 6.41948 7.27634 6.56747C7.21469 6.71547 7.18284 6.87418 7.18262 7.03451C7.1824 7.19484 7.21382 7.35363 7.27507 7.5018C7.33633 7.64996 7.42621 7.78458 7.53958 7.89795C7.65295 8.01132 7.78757 8.10121 7.93574 8.16246C8.0839 8.22371 8.2427 8.25513 8.40302 8.25492C8.56335 8.2547 8.72206 8.22285 8.87006 8.16119C9.01806 8.09953 9.15243 8.00928 9.26549 7.89561Z"
                                        fill="black"/>
                                    <path
                                        d="M23.5625 11.7812C23.2393 11.7813 22.9293 11.9097 22.7007 12.1382C22.4722 12.3668 22.3438 12.6768 22.3438 13V22.3438H3.65625V13C3.65625 12.6768 3.52785 12.3668 3.29929 12.1382C3.07073 11.9097 2.76073 11.7813 2.4375 11.7812C2.11427 11.7813 1.80427 11.9097 1.57571 12.1382C1.34715 12.3668 1.21875 12.6768 1.21875 13V22.75C1.21875 23.2887 1.43276 23.8054 1.81369 24.1863C2.19462 24.5672 2.71128 24.7812 3.25 24.7812H22.75C23.2887 24.7812 23.8054 24.5672 24.1863 24.1863C24.5672 23.8054 24.7812 23.2887 24.7812 22.75V13C24.7812 12.6768 24.6528 12.3668 24.4243 12.1382C24.1957 11.9097 23.8857 11.7813 23.5625 11.7812Z"
                                        fill="black"/>
                                </svg>,
                                <span key={2}>{bodyFields.proof_document.preview.length > 25 ?
                                    bodyFields.proof_document.preview.substring(0, 25) + '...' :
                                    bodyFields.proof_document.preview}</span>
                            ]
                            :
                            [<svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                  key={1}
                                  xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10H19.0024M10.0012 19.0012V0.998792" stroke="black" strokeWidth="2"/>
                            </svg>,
                                <span key={2}>Supporting documents</span>
                            ]
                        }
                        {bodyFields.proof_document.preview ?
                            <div className={'delete_file'}
                                 data-key={'delete_file'}>
                                <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                     data-key={'delete_file'}
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.28906L11.4566 11.7457" stroke="black"
                                          data-key={'delete_file'}
                                          strokeWidth="1.5"/>
                                    <path d="M1 11.457L11.4566 1.00042"
                                          data-key={'delete_file'}
                                          stroke="black" strokeWidth="1.5"/>
                                </svg>
                            </div>
                            : null}
                    </div>
                </div>
                <span className={'devide_inputs'}>or</span>
                <div className={'wrapper_proofs__link'}>
                    <TextInput type={'text'}
                               title={bodyFields.proof_link.title}
                               margin={'0 0 1.5rem'}
                               value={bodyFields.proof_link.value}
                               onChange={({target}) => {
                                   setData(prevState => ({
                                       ...prevState,
                                       [sectionName]: {
                                           ...prevState[sectionName],
                                           bodyFields: {
                                               ...prevState[sectionName].bodyFields,
                                               proof_link: {
                                                   ...prevState[sectionName].bodyFields.proof_link,
                                                   value: target.value
                                               }
                                           }
                                       }
                                   }))
                               }}
                    />
                </div>
            </div>
            <MainButton type={'submit'}
                        text={'Save'}
                        className={'button_black'}/>
        </form>
    )
}

export default CvEntriesSectionBody