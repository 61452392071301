import React, {useState} from 'react';
import MainButton from "./CustomComponents/MainButton";
import ModalWindow from "./ModalWindow";
import close_modal from "../assets/img/admin/close_modal.svg";
import CustomDropdown from "./CustomComponents/Dropdown";
import TextInput from "./CustomComponents/TextInput";
import RadioButton from "./CustomComponents/RadioButton";
import API from "../api/API";

const typeToValue = {
    "Prizes, Contests Won": "Awards",
    "Acquisition For A Public Collection (Museum)": "Museums",
    "PR, Media Features": "Media",
    "Exhibitions": "Exhibitions",
    "Residencies": "Residencies",
}

const AddInstitutionModal = ({onClose, countries}) => {
    const [data, setData] = useState({
        type: {
            value: null,
            error: false,
            errorTitle: "Type is required",
            options: [
                // "Education",
                "Prizes, Contests Won",
                "Acquisition For A Public Collection (Museum)",
                "PR, Media Features",
                "Exhibitions",
                "Residencies",
            ]
        },
        name: {
            value: null,
            error: false,
            errorTitle: "Title is required",
        },
        country: {
            value: null,
            error: false,
            errorTitle: "Country is required",
            options: countries
        },
        website_link: {
            value: null,
            error: false,
            errorTitle: "Website Link is required",
        },
        facebook_link: {
            value: null,
            error: false,
            errorTitle: "Facebook Link is required",
        },
        instagram_link: {
            value: null,
            error: false,
            errorTitle: "Instagram Link is required",
        },
        twitter_link: {
            value: null,
            error: false,
            errorTitle: "Twitter Link is required",
        },
        status: {
            value: 'Closed',
            error: false,
            errorMessage: null,
        },
        comments: {
            value: null,
            error: false,
            errorTitle: "",
            optional: true
        }
    });

    const handleSubmit = () => {
        let error = false;
        const final = {}
        Object.entries(data).forEach(item => {
            if (!item[1].value && !item[1].optional) {
                setData(prevState => ({
                    ...prevState,
                    [item[0]]: {
                        ...prevState[item[0]],
                        error: true,
                    },
                }))
                error = true
            } else {
                if (item[0] === "type") {
                    final[item[0]] = typeToValue[item[1].value]
                } else {
                    final[item[0]] = item[1].value
                }
            }
        })

        console.log(final)
        if (!error) {
            final.status = final.status === "Active"
            API().post("/user/profile/cv/data", final)
                .then(res => {
                    console.log(res)
                    onClose()
                })
        }
    }

    console.log(data)
    return (
        <div className="submit-institution-modal">
            <ModalWindow setActiveModal={onClose}>
                <h4>Submit New Institution</h4>
                <img src={close_modal} className={"close"} alt=""/>
                <div className="horizontal-line"/>
                <p>Please, fill in required labels:</p>
                <CustomDropdown value={data.type.value}
                                borderBottom
                                setInputValue={val => setData(val)}
                                optionLabel={'type'}
                                entryError={data.type.error}
                                error={data.type.error}
                                newError={data.type.error}
                                errorTitle={data.type.errorTitle}
                                borderDropdown
                                dropdownSelect
                                newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                        fill="black"/>
                                </svg>}
                                title={'Type'}
                                defaultDropdown
                                onChange={({target}) => {
                                    setData(prevState => ({
                                        ...prevState,
                                        type: {
                                            ...prevState.type,
                                            error: false,
                                            value: target.value,
                                            popup: true
                                        }
                                    }))
                                }}
                                dropdownPadding={'5px 0'}
                                popup={data.type.popup}
                                options={data.type.options}/>
                <TextInput type={'text'}
                           title={"Title"}
                           error={data.name.error}
                           errTitle={data.instagram_link.errorTitle}
                           entryError={data.name.error}
                           newError={data.name.error}
                           value={data.name.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   name: {
                                       ...prevState.name,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <CustomDropdown value={data.country.value}
                                setInputValue={val => setData(val)}
                                borderBottom
                                optionLabel={'country'}
                                entryError={data.country.error}
                                error={data.country.error}
                                newError={data.country.error}
                                errorTitle={data.country.errorTitle}
                                search
                                borderDropdown
                                dropdownSelect
                                newDropdownIcon={<svg width="16" height="10" viewBox="0 0 16 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z"
                                        fill="black"/>
                                </svg>}
                                title={'Country'}
                                defaultDropdown
                                onChange={({target}) => {
                                    setData(prevState => ({
                                        ...prevState,
                                        country: {
                                            ...prevState.country,
                                            error: false,
                                            value: target.value,
                                            popup: true
                                        }
                                    }))
                                }}
                                dropdownPadding={'0'}
                                popup={data.country.popup}
                                options={data.country.options}/>
                <TextInput type={'text'}
                           title={"Website Link"}
                           error={data.website_link.error}
                           errTitle={data.website_link.errorTitle}
                           entryError={data.website_link.error}
                           newError={data.website_link.error}
                           value={data.website_link.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   website_link: {
                                       ...prevState.website_link,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <TextInput type={'text'}
                           title={"Facebook"}
                           error={data.facebook_link.error}
                           errTitle={data.facebook_link.errorTitle}
                           entryError={data.facebook_link.error}
                           newError={data.facebook_link.error}
                           value={data.facebook_link.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   facebook_link: {
                                       ...prevState.facebook_link,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <TextInput type={'text'}
                           title={"Instagram"}
                           error={data.instagram_link.error}
                           errTitle={data.instagram_link.errorTitle}
                           entryError={data.instagram_link.error}
                           newError={data.instagram_link.error}
                           value={data.instagram_link.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   instagram_link: {
                                       ...prevState.instagram_link,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <TextInput type={'text'}
                           title={"Twitter"}
                           error={data.twitter_link.error}
                           entryError={data.twitter_link.error}
                           newError={data.twitter_link.error}
                           value={data.twitter_link.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   twitter_link: {
                                       ...prevState.twitter_link,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <span className={"subtitle"}>Status</span>
                <RadioButton title={'Closed'}
                             value={data.status.value}
                             error={data.status.error}
                             onClick={() => {
                                 setData(state => ({
                                         ...state,
                                         status: {
                                             ...state.status,
                                             value: 'Closed',
                                             error: false,
                                             errorMessage: null,
                                         }
                                     }
                                 ))
                             }}/>
                <RadioButton title={'Active'}
                             value={data.status.value}
                             error={data.status.error}
                             onClick={() => {
                                 setData(state => ({
                                         ...state,
                                         status: {
                                             ...state.status,
                                             value: 'Active',
                                             errorMessage: null,
                                             error: false
                                         }
                                     }
                                 ))
                             }}/>

                <TextInput type={'text'}
                           title={"Anything else you would like to add"}
                           error={data.comments.error}
                           entryError={data.comments.error}
                           newError={data.comments.error}
                           value={data.comments.value}
                           onChange={({target}) => {
                               setData(prevState => ({
                                   ...prevState,
                                   comments: {
                                       ...prevState.comments,
                                       error: false,
                                       value: target.value
                                   }
                               }))
                           }}/>
                <div className={'modal-window_buttons'}>
                    <MainButton type={'button'}
                                className={'button_black'}
                                text={'Cancel'}
                                onClick={onClose}
                    />
                    <MainButton type={'button'}
                                style={{background: '#000', color: '#fff'}}
                                className={'button_black'}
                                text={'Submit'}
                                onClick={handleSubmit}
                    />
                </div>
            </ModalWindow>
        </div>
    );
};

export default AddInstitutionModal;
