import React, {useEffect, useState} from 'react'
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import otherConst from "../../Constants/otherConst";
import avatar_default from "../../assets/img/new_landing/profile_avatar_default.svg";
import API from "../../api/API";
import {useSelector} from "react-redux";

const PublicCv = props => {
    //about_yourself
    const {
        cv, user,
        innerWidth, photo,
        name, country, categories
    } = props;
    const [showFileToolTip, setShowFileToolTip] = useState(false)
    const userRole = useSelector(state => state?.auth?.user_data?.role);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null)

    console.log('props: ', props.user.art_advisory)

    useEffect(() => {
        if (userRole) {
            API().get(`/user/payment/subscription`)
                .then((res) => {
                    setSubscriptionPlan(res.data.subscription)
                })
        }
// eslint-disable-next-line
    },[])

    const monthReplace = month => {
        let activeMonth;
        otherConst.months.forEach((m, index) => {
            if (index + 1 === Number(month.prev)) {
                activeMonth = m.prev
            }
        })
        return activeMonth
    }

    const downloadToolTip = item => (
        <a href={item.proof_file} target={'_blank'} rel="noopener noreferrer nofollow"
           data-key={'download_tool_tip'}
           className={'download_tool_tip'}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                 data-key={'download_tool_tip'}
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.46124 8.65551L8.16948 10.3637V1.32723C8.16948 1.10774 8.25668 0.897242 8.41188 0.742039C8.56708 0.586837 8.77758 0.499644 8.99707 0.499644C9.21656 0.499644 9.42706 0.586837 9.58226 0.742039C9.73746 0.897242 9.82466 1.10774 9.82466 1.32723V10.3637L11.5329 8.65551C11.6097 8.57832 11.7009 8.51703 11.8014 8.47516C11.9019 8.4333 12.0097 8.41167 12.1186 8.41152C12.2274 8.41137 12.3353 8.43271 12.4359 8.4743C12.5365 8.51589 12.6279 8.57693 12.7049 8.65391C12.7819 8.7309 12.8429 8.82231 12.8845 8.92292C12.9261 9.02353 12.9474 9.13136 12.9473 9.24023C12.9471 9.3491 12.9255 9.45687 12.8836 9.55737C12.8418 9.65787 12.7805 9.74911 12.7033 9.82589L9.58228 12.9469C9.50543 13.0238 9.41419 13.0847 9.31378 13.1263C9.21337 13.1679 9.10575 13.1893 8.99707 13.1893C8.88839 13.1893 8.78077 13.1679 8.68036 13.1263C8.57995 13.0847 8.48871 13.0238 8.41186 12.9469L5.29086 9.82589C5.21367 9.74911 5.15239 9.65787 5.11052 9.55737C5.06865 9.45687 5.04702 9.3491 5.04688 9.24023C5.04673 9.13136 5.06806 9.02353 5.10966 8.92292C5.15125 8.82231 5.21229 8.7309 5.28927 8.65391C5.36625 8.57693 5.45767 8.51589 5.55828 8.4743C5.65889 8.43271 5.76672 8.41137 5.87559 8.41152C5.98446 8.41167 6.09223 8.4333 6.19272 8.47516C6.29322 8.51703 6.38447 8.57832 6.46124 8.65551Z"
                    data-key={'download_tool_tip'}
                    fill="white" stroke="black" strokeWidth="0.5"/>
                <path
                    data-key={'download_tool_tip'}
                    d="M16.1724 8.67273C15.9529 8.67273 15.7424 8.75992 15.5872 8.91512C15.432 9.07033 15.3448 9.28083 15.3448 9.50032V15.8451H2.65517V9.50032C2.65517 9.28083 2.56798 9.07033 2.41278 8.91512C2.25758 8.75992 2.04708 8.67273 1.82759 8.67273C1.6081 8.67273 1.3976 8.75992 1.24239 8.91512C1.08719 9.07033 1 9.28083 1 9.50032V16.121C1 16.4868 1.14532 16.8377 1.40399 17.0963C1.66266 17.355 2.01349 17.5003 2.37931 17.5003H15.6207C15.9865 17.5003 16.3373 17.355 16.596 17.0963C16.8547 16.8377 17 16.4868 17 16.121V9.50032C17 9.28083 16.9128 9.07033 16.7576 8.91512C16.6024 8.75992 16.3919 8.67273 16.1724 8.67273Z"
                    fill="white" stroke="black" strokeWidth="0.5"/>
            </svg>
            <span data-key={'download_tool_tip'}>Download</span>
        </a>
    )

    const dateReplace = date => {
        let activeDate = date.split('/');
        activeDate[1] = monthReplace(date.split('/')[1])
        return activeDate.join(' ')
    }

    const dateNameReplace = date => {
        if (date.includes('-')) {
            let activeRangeDate = date.split('-');
            return `${dateReplace(activeRangeDate[0])} - ${dateReplace(activeRangeDate[1])}`
        } else {
            return dateReplace(date)
        }
    }

    console.log('subscriptionPlan: ', subscriptionPlan)
    console.log('profileSub: ', props.profileSub)
    return (
        <div className="public_cv">
            <div className="about_wrapper">
                <div className={`user_information_wrapper`}>
                    <div className={'info'}>
                        <div className={'avatar_container'}>
                            <div className={'change_avatar_wrapper'}>
                                <img src={photo ?? avatar_default} alt="avatar"/>
                            </div>
                        </div>
                        <div className={'about'}>
                            <h2>{name}</h2>
                            <p>{country}</p>
                            <div className={'categories'}>
                                {categories?.length ?
                                    categories.map(el => (
                                        <span key={el}># {el}</span>
                                    )) : null}
                            </div>
                            <div className={'user_links'}>
                                {user.linkedin_link ?
                                    <a rel="noopener noreferrer nofollow" href={user.linkedin_link}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.0129 11.8291H5V26.856H10.0129V11.8291Z" fill="#000"/>
                                            <path
                                                d="M23.67 12.0061C23.6168 11.9897 23.5663 11.9719 23.5103 11.9569C23.4431 11.9419 23.3758 11.9296 23.3072 11.9186C23.041 11.8667 22.7495 11.8298 22.4077 11.8298C19.4851 11.8298 17.6316 13.9022 17.0207 14.7027V11.8298H12.0078V26.8568H17.0207V18.6603C17.0207 18.6603 20.8091 13.5156 22.4077 17.2942C22.4077 20.667 22.4077 26.8568 22.4077 26.8568H27.4192V16.7163C27.4192 14.4459 25.8234 12.5539 23.67 12.0061Z"
                                                fill="#000"/>
                                            <path
                                                d="M7.45181 9.78126C8.80591 9.78126 9.90363 8.71094 9.90363 7.39063C9.90363 6.07032 8.80591 5 7.45181 5C6.09771 5 5 6.07032 5 7.39063C5 8.71094 6.09771 9.78126 7.45181 9.78126Z"
                                                fill="#000"/>
                                        </svg>
                                    </a>
                                    : null}
                                {user.facebook_link ?
                                    <a rel="noopener noreferrer nofollow" href={user.facebook_link}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.0052 12.5135V9.7813C17.0052 9.02722 17.6328 8.41521 18.4062 8.41521H19.8072V5H17.0052C14.6836 5 12.8021 6.83465 12.8021 9.09826V12.5135H10V15.9287H12.8021V26.8574H17.0052V15.9287H19.8072L21.2083 12.5135H17.0052Z"
                                                fill="#000"/>
                                        </svg>
                                    </a>
                                    : null}
                                {user.instagram_link ?
                                    <a rel="noopener noreferrer nofollow" href={user.instagram_link}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M20.4114 5H12.0052C8.13693 5 5 8.05867 5 11.8304V20.0269C5 23.7987 8.13693 26.8574 12.0052 26.8574H20.4114C24.2797 26.8574 27.4166 23.7987 27.4166 20.0269V11.8304C27.4166 8.05867 24.2797 5 20.4114 5ZM25.3151 20.0269C25.3151 22.6635 23.1154 24.8082 20.4114 24.8082H12.0052C9.30119 24.8082 7.10156 22.6635 7.10156 20.0269V11.8304C7.10156 9.19388 9.30119 7.04913 12.0052 7.04913H20.4114C23.1154 7.04913 25.3151 9.19388 25.3151 11.8304V20.0269Z"
                                                fill="#000"/>
                                            <path
                                                d="M16.2057 10.4644C13.1108 10.4644 10.6016 12.911 10.6016 15.9287C10.6016 18.9464 13.1108 21.3931 16.2057 21.3931C19.3006 21.3931 21.8098 18.9464 21.8098 15.9287C21.8098 12.911 19.3006 10.4644 16.2057 10.4644ZM16.2057 19.3439C14.2751 19.3439 12.7031 17.8112 12.7031 15.9287C12.7031 14.0449 14.2751 12.5135 16.2057 12.5135C18.1363 12.5135 19.7083 14.0449 19.7083 15.9287C19.7083 17.8112 18.1363 19.3439 16.2057 19.3439Z"
                                                fill="#000"/>
                                            <path
                                                d="M22.2272 10.7831C22.6397 10.7831 22.974 10.4572 22.974 10.055C22.974 9.65289 22.6397 9.3269 22.2272 9.3269C21.8148 9.3269 21.4805 9.65289 21.4805 10.055C21.4805 10.4572 21.8148 10.7831 22.2272 10.7831Z"
                                                fill="#000"/>
                                        </svg>
                                    </a>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"cv_info_wrapper"}>
                <div className="about">
                    <h3>Art Advisory</h3>
                    {/*<div className={'temporary-art-advisory-container'}>*/}
                    {/*    <p>*/}
                    {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                    {/*    </p>*/}
                    {/*    <div className={'temporary-art-advisory-popup'}>*/}
                    {/*        Available only for premium accounts*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={'about-artist-container'}>
                        {/*<p>{about_yourself}</p>*/}
                        {/* treba */}
                        <div className={props.profileSub ? 'about-artist-premium about-artist-content-container' : 'about-artist-content-container'}>
                            {props.user.art_advisory.map(item => (
                                <div className={props.profileSub ? 'art-advisory-item-container' : 'art-advisory-item-container about-artist-basic'}>
                                    <img src={item.pic} alt="advisory"/>
                                    <span>{item.text}</span>
                                </div>
                                )
                            )}

                        </div>
                        {!props.profileSub &&
                        <div className={'premium-accounts-block'}>
                            Available only for premium accounts
                        </div>
                        }
                    </div>
                </div>
                {console.log(cv)}
                <div className="profile_section_body_cv">
                    {Object.entries(cv).map((item, index) => {
                        return (
                            <div className={'entries_section_preview'} key={index}>
                                {item[0] !== 'Education' && index === 1 ?
                                    <div className={'wrapper_category_title'}>
                                        Career
                                    </div>
                                    : null}
                                {item[0] ?
                                    <div className={'entries_section_preview__title'}>
                                        <span>{item[0] === 'Awards' ? 'Prizes, Contests won:' :
                                            item[0] === 'Museums' ? 'Acquisition for a public collection (museum):' :
                                                item[0] === 'Media' ? 'pr, media features:' :
                                                item[0] === 'Residencies' ? 'Residencies:' :
                                                    item[0] === 'Education' ?
                                                        <div className={'wrapper_category_title'}>
                                                            {item[0] + ':'}
                                                        </div> : item[0] + ':'} </span>
                                    </div> :
                                    null}

                                <div className={'entries_section_preview__list'}>
                                    {item[1].length ?
                                        item[1].map(item => (
                                            <div className={'entries_section_preview__list_wrapper'} key={item.id}>
                                                <div
                                                    className={item.proof_link ? 'wrapper_title wrapper_title_link' : 'wrapper_title'}
                                                    onClick={e => {
                                                        if (e.target.getAttribute('data-key') === 'disable_click') {
                                                            e.preventDefault()
                                                        } else if (e.target.getAttribute('data-key') === 'download_tool_tip') {
                                                            window.open(item.proof_file, "_blank")
                                                        } else if (item.proof_link) {
                                                            window.open(item.proof_link, "_blank")
                                                        }
                                                    }}>
                                                    <span
                                                        className={item.proof_link ? 'title title_link' : 'title'}>{item.title}
                                                        {item.country ?
                                                            <span className={'country'}>({item.country})</span>
                                                            : null}
                                                        {item.proof_file ?
                                                            <span className={'file'}
                                                                  data-key={'disable_click'}>
                                                                <svg width="16" height="18" viewBox="0 0 16 18"
                                                                     fill="none"
                                                                     data-key={'disable_click'}
                                                                     onClick={() => {
                                                                         if (innerWidth < 768) {
                                                                             if (showFileToolTip === item.id) {
                                                                                 setShowFileToolTip(null)
                                                                             } else {
                                                                                 setShowFileToolTip(item.id)
                                                                             }
                                                                         }
                                                                     }}
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        data-key={'disable_click'}
                                                                        d="M14.3266 1.64717C12.097 -0.549058 8.46628 -0.549058 6.23906 1.64717L0.0615604 7.72761C0.0213238 7.76724 2.18814e-05 7.82087 2.18814e-05 7.87682C2.18814e-05 7.93278 0.0213238 7.9864 0.0615604 8.02603L0.934931 8.88634C0.974853 8.92549 1.02889 8.94747 1.08523 8.94747C1.14156 8.94747 1.1956 8.92549 1.23552 8.88634L7.41303 2.80591C8.17989 2.05051 9.20001 1.63552 10.284 1.63552C11.368 1.63552 12.3882 2.05051 13.1527 2.80591C13.9195 3.5613 14.3408 4.56615 14.3408 5.63163C14.3408 6.69944 13.9195 7.70196 13.1527 8.45735L6.85681 14.6567L5.8367 15.6616C4.88285 16.6011 3.33256 16.6011 2.37872 15.6616C1.91718 15.2069 1.66392 14.6031 1.66392 13.9596C1.66392 13.3161 1.91718 12.7123 2.37872 12.2576L8.62486 6.10725C8.78344 5.95337 8.99172 5.86711 9.21421 5.86711H9.21657C9.43906 5.86711 9.64497 5.95337 9.80119 6.10725C9.95977 6.26345 10.045 6.46862 10.045 6.68778C10.045 6.9046 9.9574 7.10977 9.80119 7.26365L4.69587 12.2879C4.65563 12.3276 4.63433 12.3812 4.63433 12.4371C4.63433 12.4931 4.65563 12.5467 4.69587 12.5864L5.56924 13.4467C5.60916 13.4858 5.6632 13.5078 5.71954 13.5078C5.77587 13.5078 5.82991 13.4858 5.86983 13.4467L10.9728 8.42005C11.4438 7.95609 11.7018 7.34059 11.7018 6.68545C11.7018 6.03031 11.4414 5.41247 10.9728 4.95084C10 3.99262 8.41894 3.99495 7.44616 4.95084L6.84025 5.55003L1.20239 11.1012C0.819738 11.4759 0.51642 11.9218 0.310022 12.4128C0.103624 12.9039 -0.00174596 13.4304 2.18814e-05 13.9619C2.18814e-05 15.0414 0.428423 16.0556 1.20239 16.818C2.00475 17.606 3.05564 18 4.10652 18C5.15741 18 6.20829 17.606 7.00829 16.818L14.3266 9.61376C15.4035 8.55061 16 7.13542 16 5.63163C16.0024 4.12551 15.4059 2.71032 14.3266 1.64717Z"
                                                                        fill="rgba(0,0,0,.5)"/>
                                                                </svg>
                                                                {innerWidth < 768 && showFileToolTip === item.id ?
                                                                    <OutsideClickHandler
                                                                        callback={() => setShowFileToolTip(null)}>
                                                                        {downloadToolTip(item)}
                                                                    </OutsideClickHandler>
                                                                    : innerWidth > 768 ?
                                                                        downloadToolTip(item)
                                                                        : null}

                                                            </span>
                                                            : null}
                                                    </span>
                                                </div>
                                                <span className={'description'}>{item.description}</span>
                                                <span className={'date'}>{dateNameReplace(item.date)}</span>
                                            </div>
                                        ))
                                        : <span className={'empty_list'}>There are no entries yet</span>}
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PublicCv
