import React, {memo, useEffect, useRef, useState, useCallback} from 'react';
import './styles/inbox_unique.css'
import useChat from "../../Components/Chat/useChat";
import InboxUniqueForm from "../../Components/Chat/InboxUniqueForm";
import ArtworkShippingDetails from "../../Components/Chat/ArtworkShippingDetails";
import InboxMessage from "../../Components/Chat/InboxMessage";
import {Link} from "react-router-dom";
import userPhoto from "../../assets/img/new_landing/profile_avatar_default.svg";
// import article_preview from "../../assets/img/article_preview.jpg";
// import event_cancel from "../../assets/img/event_cancel.svg";
import API from "../../api/API";
import {useSelector} from 'react-redux'

const InboxUnique = memo(() => {
    const getOrderId = () => window.location.pathname.split('/').pop();
    const [previewMessage, setPreviewMessage] = useState()
    const role = useSelector(state => state.auth.user_data.role);

    const {
        uniqueInboxData,
        sendMessage,
        isCompleted
    } = useChat(getOrderId());
    let chatRef = useRef();


    const handleSubmitMessage = (event, value, type) => {
        event?.preventDefault();
        // console.log(event, value, type);
        sendMessage(value, type)
    }


    useEffect(() => {
        API().get(`/user/profile/inbox/header?id=${getOrderId()}`)
            .then(res => {
                if (res.data) {
                    setPreviewMessage(res.data)
                }
            })
    }, [])

    const scrollToBottom = useCallback(() => {
        if (chatRef && uniqueInboxData?.messages) {
            chatRef?.scroll({top: chatRef.scrollHeight, behavior: 'smooth'});
        }
    }, [uniqueInboxData?.messages])

    useEffect(() => {
        scrollToBottom()
    }, [scrollToBottom, uniqueInboxData?.messages])


    const roleMessage = message => {
        return Object.entries(message.text).map(i => {
            if (i[0].includes(role)) {
                return i[1]
            } else {
                return null
            }
        }).filter(Boolean);
    }

    return (
        <div className="profile_section_body profile_section_inbox_unique">
            <div className={'wrapper_head_inbox'}>
                <Link className={'wrapper_head_inbox__go_back'} to={'/profile/inbox'}>
                    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.75167 7.88797L0.263672 4.30397L3.75167 0.719971H6.56767L3.07967 4.30397L6.56767 7.88797H3.75167Z"
                            fill="black"/>
                    </svg>
                    Go back
                </Link>
            </div>
            <div className="wrapper_list_message overflow_scrollbar"
                 ref={el => {
                     chatRef = el
                 }}>
                {previewMessage ?
                    <ArtworkShippingDetails previewMessage={previewMessage}
                                            order_status={uniqueInboxData?.order_status}
                                            handleSubmitMessage={handleSubmitMessage}
                                            role={role}/> : null}
                {uniqueInboxData?.messages?.length ?
                    uniqueInboxData.messages.map((item, index) => {
                        let event_preview_message, event_message_link;
                        if (item.type === 'tracking_number') {
                            event_message_link = roleMessage(item.message)[0].split(': ')[1];
                            event_preview_message = roleMessage(item.message)[0].split(': ')[0];
                        } else if (item.type !== 'text' && item.type !== 'tracking_number') {
                            event_preview_message = roleMessage(item.message)
                        }
                        return (
                            item.type === 'cancel' ?
                                <InboxMessage event_preview_message={event_preview_message}
                                              key={index}
                                              type_img={<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="32.5" cy="32" r="29.5" stroke="black"/>
                                                  <path d="M32.5 32L44.5 44M20.5 44L32.5 32L20.5 44ZM44.5 20L32.4977 32L44.5 20ZM32.4977 32L20.5 20L32.4977 32Z" stroke="black" strokeLinecap="square"/>
                                              </svg>}
                                              {...item}
                                /> : item.type === 'tracking_number' ?
                                <InboxMessage event_preview_message={event_preview_message}
                                              key={index}
                                              event_message_link={event_message_link}
                                              type_img={<svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M26.8846 1.57443C27.8375 1.04526 28.9095 0.767578 29.9994 0.767578C31.0894 0.767578 32.1614 1.04526 33.1143 1.57443L57.2212 14.964C57.7209 15.2419 58.1374 15.6484 58.4273 16.1413C58.7172 16.6343 58.87 17.1957 58.87 17.7676V44.1841C58.8697 45.3282 58.5635 46.4514 57.9831 47.4373C57.4028 48.4232 56.5693 49.236 55.5691 49.7914L33.1143 62.2699C32.1614 62.7991 31.0894 63.0768 29.9994 63.0768C28.9095 63.0768 27.8375 62.7991 26.8846 62.2699L4.42977 49.7914C3.43007 49.2363 2.59691 48.424 2.01656 47.4387C1.43621 46.4534 1.12975 45.3309 1.12891 44.1874V17.7676C1.12888 17.1957 1.28174 16.6343 1.57164 16.1413C1.86154 15.6484 2.27794 15.2419 2.77773 14.964L26.8878 1.57443H26.8846Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                  <path d="M1.5 16L29.9994 31.921M29.9994 31.921L58.5 16M29.9994 31.921V63" stroke="black" strokeLinejoin="round"/>
                                                  <path d="M15.5625 23.9025L44.433 7.86328" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                                  <path d="M10.7539 32.9717L20.3774 38.3352" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                              </svg>}
                                              {...item}
                                /> : item.type === 'start' ?
                                    <InboxMessage event_preview_message={event_preview_message}
                                                  key={index}
                                                  type_img={<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M1 65.0004H65M1 65.0004V24.5793M1 65.0004L25.5333 45.6319M65 65.0004V24.5793M65 65.0004L40.4667 45.6319M65 24.5793L33 2.68457L1 24.5793M65 24.5793L40.4667 45.6319M1 24.5793L25.5333 45.6319M40.4667 45.6319L33 39.7372L25.5333 45.6319" stroke="black"/>
                                                      <path d="M58.2646 1H7.73828V30.4737L25.4225 45.6316L33.0014 39.7368L40.5804 45.6316L58.2646 30.4737V1Z" fill="white"/>
                                                      <path d="M25.4225 15.3158H45.633M25.4225 22.8947H45.633M7.73828 1H58.2646V30.4737L40.5804 45.6316L33.0014 39.7368L25.4225 45.6316L7.73828 30.4737V1Z" stroke="black"/>
                                                      <line x1="19.5273" y1="15.2998" x2="21.2116" y2="15.2998" stroke="black"/>
                                                      <line x1="19.5273" y1="22.9004" x2="21.2116" y2="22.9004" stroke="black"/>
                                                  </svg>}
                                                  {...item}
                                    /> : item.type === 'completed' ?
                                        <InboxMessage event_preview_message={event_preview_message}
                                                      key={index}
                                                      type_img={<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <circle cx="32" cy="32" r="29.5" stroke="black"/>
                                                          <path d="M17 33L27 43L47.5 22" stroke="black"/>
                                                      </svg>
                                                      }
                                                      {...item}
                                        /> : item.type === 'text' ?
                                            <InboxMessage key={index}
                                                          {...item}/> : null
                        )
                    })
                    : null}
            </div>
            <InboxUniqueForm
                avatar={uniqueInboxData?.user_avatar ?? userPhoto}
                border={true}
                isCompleted={isCompleted}
                order_status={uniqueInboxData?.order_status}
                role={role}
                placeholder={'Reply'}
                handleSubmitMessage={handleSubmitMessage}/>
        </div>
    )
})

export default InboxUnique
