import React, {useEffect, useState} from 'react'
import {Link, useLocation} from "react-router-dom";
import API from "../../api/API";
import Keys from "../../Constants/helper";
import './styles/admin_article.css'
import delete_blog_icon from "../../assets/img/admin/delete_blog_icon.png";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import ModalWindow from "../../Components/ModalWindow";
import MainButton from "../../Components/CustomComponents/MainButton";
import {bodyOverflow} from "../../Constants/bodyOverflow";

export default function AdminBlogArticle() {
    let location = useLocation();

    const [activeArticleData, setActiveArticleData] = useState(null)
    const [activeDeleteModal, setActiveDeleteModal] = useState(null)
    const [loading, setLoading] = useState(false)
    let layoutIndex = 0;

    useEffect(() => {
        let url = location.pathname.split('/');
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/post?id=${url[3]}`)
            .then((res) => {
                if (res.data) {
                    // console.log(res)
                    setActiveArticleData(res.data)
                    setLoading(true)
                }
            })
            .catch(err => {
                if (err?.response?.data?.error === 'cant find post') {
                    window.location.href = '/admin/blog'
                }
            })
    }, [location.pathname])

    const deleteBlogArticle = () => {
        API(Keys.JWT_TOKEN_ADMIN).delete(`/admin/post?id=${activeArticleData.id}`)
            .then(res => {
                if (res.status === 200) {
                    setActiveDeleteModal(null)
                    bodyOverflow(false)
                    window.location.href = '/admin/blog'
                }
            })
    }

    return [
        <div key={1} className={'admin_content_wrapper admin_content_wrapper_article'}>
            <div className={'head'}>
                <div className={'navigation'}>
                    <Link to={'/admin/blog'}>Blog</Link>
                    <span>/</span>
                    <span>{loading && activeArticleData.id}</span>
                </div>
                <span>{loading && activeArticleData.date}</span>
            </div>

            <div className={'title'}>
                <h2>{loading && activeArticleData.name}</h2>
                <img className={'delete'} onClick={() => {
                    bodyOverflow(true)
                    setActiveDeleteModal(true)
                }}
                     src={delete_blog_icon} alt="delete"/>
            </div>

            <div className={'name_category'}>
                <p>{loading && activeArticleData.author_name}</p>
                <div/>
                <span>{loading && capitalizeFirstLetter(activeArticleData.category)}</span>
            </div>

            <div className={'blog_main_content'}>
                {loading ?
                    <img className={'blog_main_photo'} src={activeArticleData.photo} alt="blog preview"/> : null}
                <p>{loading && activeArticleData.description}</p>
            </div>
            {loading ? activeArticleData.sections.length
                ? activeArticleData.sections.map((el) => {
                    if (el.photo) {
                        layoutIndex += 1
                        return (
                            <div key={el.id} className={`blog_section ${layoutIndex % 2 === 0
                                ? 'blog_section_left' : 'blog_section_right'}`}>
                                <div className={'text_content'}>
                                    <h3>{el.name}</h3>
                                    <div dangerouslySetInnerHTML={{__html: el.description}}/>
                                </div>
                                <img src={el.photo} alt="preview"/>
                            </div>
                        )
                    } else {
                        return (
                            <div key={el.id} className={`blog_section blog_section_without_photo`}>
                                <div className={'text_content'}>
                                    <h3>{el.name}</h3>
                                    <div dangerouslySetInnerHTML={{__html: el.description}}/>
                                </div>
                            </div>
                        )
                    }
                }) : null : null}
        </div>,

        activeDeleteModal ?
            <ModalWindow activeModal={activeDeleteModal}
                         key={2}
                         setActiveModal={val => setActiveDeleteModal(val)}>
                <h4>Delete this article?</h4>
                <p>Are you sure you want to delete? This action can't be undone</p>
                <div className={'modal-window_buttons'}>
                    <MainButton type={'button'}
                                style={{padding: '10px 1.5rem', margin: '0'}}
                                text={'Cancel'}
                                onClick={() => {
                                    setActiveDeleteModal(null)
                                    bodyOverflow(false)
                                }}
                    />
                    <MainButton type={'button'}
                                style={{padding: '8px 1.5rem', margin: '0'}}
                                className={'button_bordered'}
                                text={'Delete'}
                                onClick={deleteBlogArticle}
                    />
                </div>
            </ModalWindow> : null
    ]
}