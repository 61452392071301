import * as types from "./types";
import Keys from "../../Constants/helper";
import {clearCookies} from "../../Constants/clearCookies";

const initState = {
    auth_variant: null,
    auth_popup: false,
    user_categories: [],
    cart_length: 0,
    active_upload: false,
    user_subscription: null,
    cart_status: 0,
    active_history: null,
    can_create: localStorage.getItem(Keys.CAN_CREATE) ?
        JSON.parse(localStorage.getItem(Keys.CAN_CREATE))
        : false,
    user_data: localStorage.getItem(Keys.USER_DATA) ?
        JSON.parse(localStorage.getItem(Keys.USER_DATA))
        : null,
    user_registration_data: sessionStorage.getItem(Keys.REGISTRATION_DATA) ?
        JSON.parse(sessionStorage.getItem(Keys.REGISTRATION_DATA)) :
        null,
    currency: localStorage.getItem(Keys.CURRENCY) || "EUR",
    units: localStorage.getItem(Keys.UNITS) || "centimetres",
    currencies: {},
    fromApp: false
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case types.USER_REGISTRATION_DATA:
            let userData = null;
            if (action.payload) {
                userData = JSON.stringify(action.payload)
            } else {
                sessionStorage.removeItem(Keys.REGISTRATION_DATA)
                clearCookies(Keys.REGISTRATION_DATA)
            }
            sessionStorage.setItem(Keys.REGISTRATION_DATA, userData)
            return {
                ...state,
                user_registration_data: action.payload,
            };
        case types.AUTH_POPUP:
            return {
                ...state,
                auth_popup: action.payload,
            };
        case types.ACTIVE_HISTORY:
            return {
                ...state,
                active_history: action.payload,
            };
        case types.CART_COUNT:
            return {
                ...state,
                cart_length: action.payload,
            };
        case types.AUTH_VARIANT:
            return {
                ...state,
                auth_variant: action.payload,
            };
        case types.USER_NAME:
            localStorage.setItem(Keys.USER_DATA, JSON.stringify(action.payload))
            return {
                ...state,
                user_data: action.payload,
            };
        case types.USER_CATEGORIES :
            return {
                ...state,
                user_categories: action.payload,
            };
        case types.USER_SUBSCRIPTION :
            return {
                ...state,
                user_subscription: action.payload,
            };
        case types.ACTIVE_UPLOAD :
            return {
                ...state,
                active_upload: action.payload,
            };
        case types.CART_STATUS :
            return {
                ...state,
                cart_status: action.payload,
            };
        case types.CAN_CREATE:
            localStorage.setItem(Keys.CAN_CREATE, JSON.stringify(action.payload))
            return {
                ...state,
                can_create: action.payload,
            };
        case types.CURRENCY:
            localStorage.setItem(Keys.CURRENCY, action.payload)
            return {
                ...state,
                currency: action.payload,
            };
        case types.UNITS:
            localStorage.setItem(Keys.UNITS, action.payload)
            return {
                ...state,
                units: action.payload,
            };
        case types.CURRENCIES:
            return {
                ...state,
                currencies: action.payload,
            };
        case types.FROM_APP:
            return {
                ...state,
                fromApp: action.payload
            }
        default: {
            return state;
        }
    }
}

export default authReducer;
