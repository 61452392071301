import React from 'react'

const YourPersonalData = () => {
    return (
        <div>
            <h3 className={'terms_item_title'}>5. HOW WE USE YOUR PERSONAL DATA</h3>
            <div className={'privacy_policy_item_container'}>
                <span>
                    We will only use your personal data when the law allows us to. Most commonly, we will use your
                    personal data in the following circumstances:
                    <br/>
                    Where we need to perform the contract we are about to enter into or have entered into with
                            you, either directly or as a commercial agent of our Sellers.
                    <br/>
                    Where it is necessary for our legitimate interests (or those of a third party) and your
                            interests and fundamental rights do not override those interests.
                    <br/>
                    Where we need to comply with a legal or regulatory obligation.
                    <br/>
                    Generally we do not rely on consent as a legal basis for processing your personal data other
                            than in relation to certain direct marketing (and you can see where that is the case in the
                            table below). You have the right to withdraw consent to marketing at any time by visiting
                            our preferences page.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</span>
                <span>
                    We have set out below, in a table format, a description of all the ways we plan to use your
                            personal data, and which of the legal bases we rely on to do so. We have also identified
                            what our legitimate interests are where appropriate.
                    <br/>
                    Note that we may process your personalІ data for more than one lawful ground depending on the
                            specific purpose for which we are using your data. Please Contact us at
                            legal@artisfact.co.uk if you need details about the specific legal ground we are relying on
                            to process your personal data where more than one ground has been set out in the table
                            below.
                    <br/>
                    Purpose/Activity
                    <br/>
                    Type of data
                    <br/>
                    Lawful basis for processing including basis of legitimate interest
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>To register you as a new customer</span>
                <span>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Performance of a contract with you.</span>
                <span>
                    To register you as a new seller
                    <br/>
                    (a)Identity
                    (b)Contact
                    (c)Profile
                    (d)Seller Application
                    (e)Seller Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Analytics tools</span>
                <span>
                    - we use analytics tools to track the way that users interact
                            with our website.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Performance of a contract with you.</span>
                <span>
                    To process and deliver your order including:
                    <br/>
                    (a) Manage payments, fees and charges
                    <br/>
                    (b) Manage your queries through our Customer Service team – this may include
                                        recording calls to our teams
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Financial
                    <br/>
                    (d)Transaction
                    <br/>
                    (e)Marketing and Communications
                    <br/>
                    (f)Seller Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Performance of a contract with you. We may also use some of the data related to your
                                    queries for our legitimate interests of ensuring our customer service quality
                                    standards are met.</span>
                <span>
                    To collect and recover money owed to us in respect of your order or a payment of
                                    commission due to us if you are a Seller
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Delivery providers</span>
                <span>
                     - in order to package and mail your orders to you, it is
                            necessary to share your information with delivery providers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>To collect and recover money owed to us in respect of your order or a payment of
                                    commission due to us if you are a Seller</span>
                <span>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Financial
                    <br/>
                    (d)Transaction
                    <br/>
                    (e)Seller Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Necessary for our legitimate interests (to recover debts due to us).</span>
                <span>
                      To carry out fraud assessments
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Financial
                    <br/>
                    (d)Transaction
                    <br/>
                    (e)Technical
                    <br/>
                    (f)Seller Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Necessary for our legitimate interests of ensuring payments are not fraudulent.</span>
                <span>
                       To process your purchase of a gift card from us
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Financial
                    <br/>
                    (d)Transaction
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Performance of a contract with you.</span>
                <span>
                       To notify you in relation to our legal obligations and documents, including changes
                                    to our terms or Privacy Policy
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Profile
                    <br/>
                    (d)Seller Profile
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Necessary for our legitimate interests of ensuring our customers are updated on these
                                    changes.</span>
                <span>
                        To help us improve our offering to our customers, including asking you to leave a
                                    review or take a survey, or provide customer insights
                    <br/>
                        (a)Identity
                    <br/>
                        (b)Contact
                    <br/>
                        (c)Profile
                    <br/>
                        (d)Marketing and Communications
                    <br/>
                    Necessary for our legitimate interests (to study how customers use our
                                    products/services, to improve our offering to our customers, to develop them and
                                    grow our business)
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>To enable you to partake in a prize draw, competition or complete a survey</span>
                <span>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Profile
                    <br/>
                    (d)Usage
                    <br/>
                    (e)Marketing and Communications
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       Performance of a contract with you to fulfil the promotion and run the
                                    competition/prize draw. We may also subsequently use your entries for the legitimate
                                    interests of understanding our customer base more effectively
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       To administer and protect our business and this website (including troubleshooting,
                                    data analysis, testing, system maintenance, support, reporting and hosting of
                                    data)
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Technical
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       (a)Necessary for our legitimate interests (for running our business, provision of
                                    administration and IT services, network security, to prevent fraud and in the
                                    context of a business reorganisation or group restructuring exercise).
                                    (b) Necessary to comply with a legal obligation.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       To deliver relevant website content and advertisements to you and measure or
                                    understand the effectiveness of the advertising we serve to you
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Profile
                    <br/>
                    (d)Usage
                    <br/>
                    (e)Marketing and Communications
                    <br/>
                    (f)Technical
                    <br/>
                    Necessary for our legitimate interests (to study how customers use our
                                    products/services, to develop them, to grow our business and to inform our marketing
                                    strategy). Please note that where cookies are used for this purpose, this is covered
                                    separately by our Cookies Policy.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       To deliver direct marketing to you
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Profile
                    <br/>
                    (d)Usage
                    <br/>
                    (e)Marketing and Communications
                    <br/>
                    (f)Technical
                    <br/>
                    Generally, necessary for our legitimate interests (to promote our business or our
                                    artists). Where you have been asked to opt in, or the law requires consent for
                                    electronic direct marketing, our lawful basis is consent.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       To use data analytics to improve our website, products/services, marketing, customer
                                    relationships and experiences
                    <br/>
                    (a)Technical
                    <br/>
                    (b)Usage
                    <br/>
                    Necessary for our legitimate interests (to define types of customers for our products
                                    and services, to keep our website updated and relevant, to develop our business and
                                    to inform our marketing strategy). Please note that where cookies are used for this
                                    purpose, this is covered separately by our Cookies Policy.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       To make suggestions and recommendations to you about goods or services that may be of
                                    interest to you
                    <br/>
                    (a)Identity
                    <br/>
                    (b)Contact
                    <br/>
                    (c)Technical
                    <br/>
                    (d)Usage
                    <br/>
                    (e)Profile
                    <br/>
                    (e)Seller Profile
                    <br/>
                    Necessary for our legitimate interests (to develop our products/services and grow our
                                    business).
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                       We do not conduct any automated decision making. We may on occasion profile our customers for
                            the purposes of targeting marketing at them and where this is done, this is undertaken for
                            our legitimate interests of ensuring our marketing is relevant to its audience. For example,
                            we may classify an appropriate audience for a promotion by what products on our website they
                            have previously looked at or expressed an interest in. We do not conduct any online
                            behavioural tracking.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                    <span>MARKETING AND PROMOTIONS</span>
                       <span>
                           We strive to provide you with choices regarding certain personal data uses, particularly
                            around marketing and advertising. Please see your preferences page on the website for more
                            information and to save your choices.
                           <br/>
                           We will only ever send marketing in accordance with your preferences that we have recorded
                            for you.
                           <br/>
                           We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what
                            we think you may want or need, or what may be of interest to you. This is how we decide
                            which products, services and offers may be relevant for you. We only use the data you
                            provide to us directly for this purpose along with the Aggregated Data provided to us by our
                            analytics partners and we do not track what other websites you may visit after visiting our
                            site, though in common with most websites, we may register the site which referred you to
                            our site (e.g. a search engine).
                           <br/>
                           You will receive marketing communications from us if you have requested information from us
                            or purchased goods or services from us or Sellers we represent or if you provided us with
                            your details when you entered a competition, registered for an account or promotion and, in
                            each case, you have not opted out of receiving that marketing.
                           <br/>
                           e will always offer a way to opt out of receiving marketing communications in every
                            marketing communication sent to you. We may on occasion send out postal marketing for the
                            purpose of growing our sales which is in our legitimate interests and in this case we will
                            rely on you to let us know if you do not want to receive this by opting out of marketing
                            (see Opting out below).
                       </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>ADVERTISING ON OTHER SITES</span>
                <span>
                           There are lots of different places you may see adverts for Artsted, and these may not be
                            using your personal data; we buy advertising space in the real world and on websites and
                            social media. If you see Artsted adverts on websites and in social media, these may not be
                            directed specifically at you and may be there because we have bid for the space or are using
                            the providers services to show our adverts to 'lookalike' audiences.
                           <br/>
                           Our website uses cookies/advertising IDs for the purpose of advertising. This enables us to
                            show our advertisements to visitors who are interested in our products on partner websites
                            and apps. Re-targeting technologies use your cookies or advertising IDs and display
                            advertisements based on your past browsing behaviour. To read more and/or oppose to their
                            services, please refer to their privacy policy listed below:
                           <br/>
                           Facebook Ads: Privacy Policy | Opt-out of personalised ad targeting
                           <br/>
                           Google Ads: Privacy Policy | Opt-out of personalised ad targeting
                           <br/>
                           We may also share certain data with third party social media platforms in order to show
                                you targeted ads when you visit them. We may do this by providing these platforms with
                                your email address to create 'audiences' of users fitting within a certain
                                demographic/category so that we can target our marketing. Please check the social media
                                platforms' terms for more details of these services. This is in our legitimate interests
                                of sending you direct marketing. See 'Opting out' below for details of how you can
                                adjust your marketing preferences.
                       </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>OPTING OUT</span>
                <span>
                           You can ask us to stop sending you marketing messages at any time by logging into your
                                preferences page, by following the opt-out links on any marketing message sent to you or
                                by contacting us at any time.
                           <br/>
                           If you opt out of receiving email marketing from us, we will no longer share your email
                                address with social media platforms (see 'External Third Parties' below). However, you
                                may continue to see our ads through them, due to their general demographic targeting.
                                Please check the social media platforms for more detail of how to opt out from seeing
                                these ads.
                           <br/>
                           Where you opt out of receiving these marketing messages, this will not apply to personal
                                data provided to us as a result of a product/service purchase from us or one of the
                                Sellers we represent.
                       </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>COOKIES</span>
                <span>
                           You can set your browser to refuse all or some browser cookies, or to alert you when
                                websites set or access cookies. If you disable or refuse cookies, please note that some
                                parts of this website may become inaccessible or not function properly.
                       </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>CHANGE OF PURPOSE</span>
                <span>
                           We will only use your personal data for the purposes for which we collected it, unless we
                                reasonably consider that we need to use it for another reason and that reason is
                                compatible with the original purpose. If you wish to get an explanation as to how the
                                processing for the new purpose is compatible with the original purpose, please contact
                                us.
                    <br/>
                    If we need to use your personal data for an unrelated purpose, we will notify you and we
                                will explain the legal basis which allows us to do so.
                    <br/>
                    Please note that we may process your personal data without your knowledge or consent, in
                                compliance with the above rules, where this is required or permitted by law.
                       </span>
            </div>
        </div>
    );
}

export default YourPersonalData