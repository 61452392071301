import React, {useCallback, useEffect, useState} from 'react';
import '../Profile/styles/profile.css'
import API from "../../api/API";
import './styles/profile_categories.css'
import sliderArrowLeft from "../../assets/img/new_landing/slider_left_arrow.svg";
import sliderArrowRight from "../../assets/img/new_landing/slider_right_arrow.svg";
import {useSelector} from "react-redux";
import MainButton from "../../Components/CustomComponents/MainButton";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import {Link} from "react-router-dom";
import Slider from "react-slick";
import ArtworkInfoCard from "../../Components/ArtworkInfoCard";
import {Loader} from "../../Components/Loader";
import useResize from "../../Constants/useResize";

const ArtworksArrowLeft = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_left'}>
        <img src={sliderArrowLeft} alt="arrow left"/>
    </div>
);
const ArtworksArrowRight = ({currentSlide, slideCount, ...props}) => (
    <div {...props} className={'slider_arrows_container slider_arrow_right'}>
        <img src={sliderArrowRight} alt="arrow right"/>
    </div>
);

const sliderSettings = {
    infinite: true,
    speed: 500,
    arrows: true,
    dots: false,
    slidesToShow: 4,
    useTransform: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1580,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1,
            }
        },
    ],
    prevArrow: <ArtworksArrowLeft/>,
    nextArrow: <ArtworksArrowRight/>,
}

export default function Categories() {

    const {innerWidth} = useResize();
    const reduxState = useSelector(state => ({
        user_categories: state.auth.user_categories,
    }));

    const [activeCategoryData, setActiveCategoryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [swiped, setSwiped] = useState(false);
    // const {innerWidth} = useResize();

    const handleSwiped = useCallback(() => {
        setSwiped(true)
    }, [])

    const handleOnItemClick = useCallback((e) => {
        if (swiped) {
            e?.preventDefault();
            e?.stopPropagation();
            setSwiped(false)
        }
    }, [swiped])


    useEffect(() => {
        setActiveCategoryData([])
        reduxState?.user_categories.forEach((item, index) => {
            API().get(`/user/profile/categories?category=${item}&order_by=most_viewed`)
                .then(res => {
                    // console.log(res.data)
                    setActiveCategoryData(prevState => ([
                        ...prevState,
                        {
                            category: item,
                            [item]: {
                                options: ['most_viewed', 'new_talents'],
                                value: 'most_viewed',
                                popup: false,
                            },
                            categoryData: res.data.artworks
                        }
                    ]))
                    if (index === reduxState?.user_categories.length - 1) {
                        setLoading(true)
                    }
                })
        })
    }, [reduxState?.user_categories])

    const handleActiveTab = (categoryName, order, index) => {
        API().get(`/user/profile/categories?category=${categoryName}&order_by=${order}`)
            .then(res => {
                console.log(res)
                setActiveCategoryData(prevState => ([
                    ...prevState.slice(0, index),
                    {
                        ...prevState[index],
                        categoryData: res.data.artworks
                    },
                    ...prevState.slice(index + 1),
                ]))
            })
    }

    return (
        <div className={'profile_categories_wrapper'}>
            <h2>Categories of art</h2>
            <div className={'profile_section_body_categories'}>
                {reduxState?.user_categories.map((item, index) => (
                    <span key={index}># {item}</span>
                ))}
            </div>
            <div style={!loading ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : null}>
                {loading ?
                    activeCategoryData.map((item, index) => {
                        let optionLabel = item[item.category]
                        let objLabel = item.category
                        return (
                            <div style={activeCategoryData.length - 1 !== index ? {marginBottom: '3.5rem'} : null}
                                 className={'categories_item_wrapper'}
                                 key={index}>
                                <h3>Last Updates in {item.category}</h3>
                                <div className={'categories_navigation'}>
                                    <div className={'tabs'}>
                                        {optionLabel.options.map((el, i) => (
                                            <MainButton type={'button'}
                                                        key={i}
                                                        text={capitalizeFirstLetter(el.split('_').join(' '))}
                                                        style={optionLabel.value === el ? {
                                                            background: '#000',
                                                            color: '#fff'
                                                        } : null}
                                                        className={'button_black'}
                                                        onClick={() => {
                                                            handleActiveTab(item.category, el, index)
                                                            setActiveCategoryData(prevState => ([
                                                                ...prevState.slice(0, index),
                                                                {...item, [objLabel]: {...item[objLabel], value: el}},
                                                                ...prevState.slice(index + 1)
                                                            ]))
                                                        }}
                                            />
                                        ))}
                                    </div>
                                    {innerWidth > 568 ?
                                        <Link to={`/artworks?category=${objLabel}#page=1`}>
                                            Go to category list ></Link> : null}
                                </div>

                                <div className={'art_price_body_slider'}>
                                    {item.categoryData.length ?
                                        <Slider {...sliderSettings} onSwipe={handleSwiped}>
                                            {item.categoryData.map((data, num) => (
                                                <div key={data.id} onClickCapture={(e) => handleOnItemClick(e)}>
                                                    <ArtworkInfoCard {...data}
                                                                     activeData={activeCategoryData}
                                                                     setActiveData={val => setActiveCategoryData(val)}
                                                                     nestedArray={'categoryData'}
                                                                     categoryDataIndex={index}
                                                                     categoriesHandleFavorite={activeCategoryData}
                                                                     categoriesFunction
                                                                     index={num}/>
                                                </div>
                                            ))}
                                        </Slider>
                                        : <span className={'no_items_style'}>No items</span>}
                                </div>

                                {innerWidth <= 568 ?
                                    <Link className={'go_to_category'} to={`/artworks?category=${objLabel}#page=1`}>
                                        Go to category list ></Link> : null}
                            </div>
                        )
                    })
                    : <Loader color={{
                        '--bgColor': '#000',
                        '--size': '48px',
                        '--wrapper_size': '40px'
                    }}/>}
            </div>
        </div>
    )
}
