import React from 'react'

const DataSecurity = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>7. DATA SECURITY</h3>
            <div className={'privacy_policy_item_container'}>
                <span>Data Controller</span>
                <span>
                    We have put in place appropriate security measures to prevent your personal data from
                                being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
                                In addition, we limit access to your personal data to those employees, agents,
                                contractors and other third parties who have a business need to know. They will only
                                process your personal data on our instructions and they are subject to a duty of
                                confidentiality.
                    <br/>
                    We have put in place procedures to deal with any suspected personal data breach and will
                                notify you and any applicable regulator of a breach where we are legally required to do
                                so.
                    <br/>
                    You acknowledge that the Internet is not a completely secure medium for communication
                                and, accordingly, we cannot guarantee the security of any information you send to us (or
                                we send to you) via the Internet. We are not responsible for any damages which you, or
                                others, may suffer as a result of the loss of confidentiality of such information
                    <br/>
                    We require all third parties to respect the security of your personal data and to treat
                                it in accordance with the law and they may only use your data for the purposes we
                                specify in our contract with them. We will always work with them to protect your
                                privacy.
                </span>
            </div>
        </div>
    )
}

export default DataSecurity