import React from 'react';
import Pagination from 'react-js-pagination'

const NewPaginationView = ({activePage, onChange, pageRangeDisplayed}) => {
    return (
        <Pagination
            activePage={activePage.page ?? 1}
            itemsCountPerPage={1}
            itemClass={'pagination_list_buttons'}
            activeClass={'pagination_list_buttons_active'}
            disabledClass={'pagination_list_buttons_disabled'}
            prevPageText={activePage.page >= 3 ? <button>{'Previous'}</button> : null}
            nextPageText={(activePage.pages - activePage.page) >= 2 ? <button>{'Next'}</button> : null}
            firstPageText={activePage.page >= 4 && activePage.pages > 5 ? '1' : null}
            lastPageText={activePage.page <= activePage.pages - 3 && activePage.pages > 5 ? activePage.pages.toString() : null}
            totalItemsCount={activePage.pages}
            pageRangeDisplayed={pageRangeDisplayed ? pageRangeDisplayed : 5}
            hideDisabled={true}
            onChange={onChange}/>
    )
}

export default NewPaginationView
