import React, {useRef, useEffect} from "react";

function useOutsideClick(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }

        const keyEscape = key => {
            if (key.code === 'Escape') {
                callback()
            }
        }

        document.addEventListener("keydown", keyEscape);
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("keydown", keyEscape);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
}

export default function OutsideClickHandler(props) {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, props.callback);

    return <div className={'outside_click_wrapper'} ref={wrapperRef}>{props.children}</div>;
}
