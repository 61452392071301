import React from 'react';
import SEO from "../../Components/SEO";

export default function SalesAndCommissions() {
    return (
        <div className={'faq_section_body sales_padding'}>
            <SEO title="Sales & Commissions: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Sales & Commissions: Buy art online Artsted"/>
            <h1>Sales & Commissions</h1>

            <p> All sales on Artsted are covered by the Buyer and Seller protection scheme, where both parties transact
                safely and securely. Unlike the brick-and-mortar galleries that have fixed costs and take up to 50% in
                commission on each sale, Artsted is only taking 35% off each sale made on behalf of the artist.</p>
               <p> The payout to the artist is made automatically after, once delivered, the 7-day return policy period
                expiries on the buyer's side.</p>
                <p>Once a filled out Tax Form has been uploaded, the payout will be directed to the Artist's provided IBAN
                Account.
            </p>
        </div>
    )
}