import React, {useState, useEffect} from 'react';
import MainButton from "../../Components/CustomComponents/MainButton";
import NewStatisticProfileViews from "../../Components/ProfileComponents/NewStatisticProfileViews";
import NewStatisticCategoryViews from "../../Components/ProfileComponents/NewStatisticCategoryViews";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import '../Profile/styles/new_statistic.css'
import API from "../../api/API";
import NewStatisticRegionViews from "../../Components/ProfileComponents/NewStatisticRegionViews";
import useResize from "../../Constants/useResize";
import NewStatisticPerformance from "../../Components/ProfileComponents/NewStatisticPerformance";

const NewStatistics = () => {
    const [activeTab, setActiveTab] = useState({
        options: ['all_time', 'last_week', 'last_month', 'year'],
        value: 'all_time'
    });
    const [statistic, setStatistic] = useState(null);
    const [performance, setPerformance] = useState(null);
    const [loading, setLoading] = useState(null);
    let {innerWidth} = useResize();

    useEffect(() => {
        Promise.all([
            API().get(`/user/profile/statistic?period=${activeTab.value}`),
            API().get(`/user/profile/cv`)
        ])
            .then(res => {
                setStatistic(res[0].data)
                setPerformance(res[1].data)
                setLoading(true)
            })
    }, [activeTab.value])

  console.log(statistic, "statistics")

    return (
        <div className={'new_statistic'}>
            <div className={'wrapper_tab'}>
                {activeTab.options.map((el, i) => (
                    <MainButton type={'button'}
                                key={i}
                                text={capitalizeFirstLetter(el.split('_').join(' '))}
                                style={activeTab.value === el ? {
                                    background: '#000',
                                    color: '#fff'
                                } : null}
                                className={'button_black'}
                                onClick={() => setActiveTab(prevState => ({
                                    ...prevState,
                                    value: el
                                }))}
                    />
                ))}
            </div>
            <div className={'wrapper_statistic_views'}>
                <NewStatisticProfileViews loading={loading}
                                          data={statistic?.views}/>
                <NewStatisticCategoryViews innerWidth={innerWidth}
                                           loading={loading}
                                           data={statistic?.most_popular_categories}/>
            </div>

            <div className={'wrapper_statistic_performance'}>
                <NewStatisticPerformance data={statistic?.performance}
                                         innerWidth={innerWidth}
                                         performance={performance}
                                         title={'Performance'}
                                         loading={loading}/>
            </div>
            <div className={'wrapper_statistic_region'}>
                <NewStatisticRegionViews data={statistic?.views_by_region}
                                         innerWidth={innerWidth}
                                         loading={loading}/>
            </div>
        </div>
    )
}

export default NewStatistics;
