import * as Yup from 'yup';

export const amenities = [
    'Wi-Fi',
    'Seating areas',
    'Coffee and tea station',
    'Gallery space',
    'Free parking',
    'Secure storage',
    'Communal worktables',
    'Adjustable easels',
    'High-quality paintbrushes',
    'Canvas storage racks',
    'Drawing boards',
    'Sculpting tools',
    'Printmaking press',
    'Digital drawing tablets',
    'Professional lighting',
    'Drying racks',
    'Art reference library',
    'Outdoor painting area',
    'Artwork storage lockers',
    'Soundproof studio booths',
    'Artwork photography equipment',
    'Specialty art papers',
    'Natural ventilation system',
    'On-site art instructor support'
]

export const places = ["Private room", "Shared room", "House", "Apartments", "Cottage", "Loft", "Townhouse", "Condo", "Villa", "Bungalow", "Duplex", "Penthouse", "Chalet", "Mansion"]

export const ListingFormSchemaValidation = Yup.object({
    place_type: Yup.string().required('Choose the place type, please.'),
    check_in_from_time: Yup.string().required('Choose the check in from time, please.'),
    check_in_to_time: Yup.string().required('Choose the check in to time, please.'),
    check_out_from_time: Yup.string().required('Choose the check out from time, please.'),
    check_out_to_time: Yup.string().required('Choose the check out to time, please.'),
    name: Yup.string().required('Input the studio name, please.'),
    daily_rentable: Yup.boolean(),
    daily_price: Yup.number().when("daily_rentable", {
        is: true,
        then: () => Yup.number().required('Input the price per night, please.'),
        otherwise: () => Yup.number()
    }),
    monthly_rentable: Yup.boolean(),
    monthly_price: Yup.number().when("monthly_rentable", {
        is: true,
        then: () => Yup.number().required('Input the price per month, please.'),
        otherwise: () => Yup.number()
    }),
    address: Yup.string().required('Choose the address, please.'),
    guests_num_max: Yup.number().min(1).required('Input the guests number, please.'),
    rooms_num: Yup.number().min(1).required('Input the rooms number, please.'),
    beds_num: Yup.number().min(1).required('Input the beds number, please.'),
    baths_num: Yup.number().min(1).required('Input the baths number, please.'),
    area_sq_ft: Yup.number().min(1).required('Input the studio area (sq. ft.), please.'),
    cancellation_policy_id: Yup.number().oneOf([1, 2, 3]).required('Choose cancellation policy, please.'),
    owner_email: Yup.string().email('Invalid email address'),
    validateRentable: Yup.mixed().test(
        'atLeastOneRequired',
        'At least one of the fields is required.',
        (value, context) => {
            const { daily_rentable, monthly_rentable } = context.parent;
            return daily_rentable || monthly_rentable;
        }
    ),
})