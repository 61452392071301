import React from 'react';
import { Route } from "react-router-dom";
import { Navigation } from './navigation'
import { NewWrapperApp } from './MainRouter'
import PasswordLock from "../Components/PasswordLock/PasswordLock";

class NewRouters extends React.Component {
  render() {
    return (
      <NewWrapperApp>
        {Navigation.mainNavigation.map(route => (
          <Route path={route.path}
                 exact
                 {...this.props}
                 component={route.main}
                 key={route.path}/>
        ))}
      </NewWrapperApp>
    )
  }
}


export default NewRouters;
