import Wrapper from "../OnboardingWrapper";
import {Loader} from "../../../Components/Loader";
import React, {useEffect, useState} from "react";
import API from "../../../api/API";
import classes from "../ArtistRegistration/styles/artist_reg.module.scss";
import Input from "../../../Components/CustomComponents/Onboarding/Input";
import Button from "../../../Components/CustomComponents/Onboarding/Button";
import regExp from "../../../Constants/regExp";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearCookies} from "../../../Constants/clearCookies";
import historyDetectBack from "../HistoryBackDetect";
import Keys, {organizationTypes} from "../../../Constants/helper";
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import useBottomPopup from "../../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import Dropdown from "../../../Components/CustomComponents/Onboarding/Dropdown";

const initialState = {
  name: {
    value: '',
    errorMessage: null,
  },
  email: {
    value: '',
    errorMessage: null,
  },
  password: {
    value: '',
    errorMessage: null,
  },
  confirmPassword: {
    value: '',
    errorMessage: null,
  },
  organization_type: {
    value: '',
    errorMessage: null,
  }
}

export const OrganizationReg = () => {
  const [registrationData, setRegistrationData] = useState(initialState)
  const [loading, setLoading] = useState(false);
  const [artImg, setArtImg] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector(state => state.auth)
  let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

  useEffect(() => {
    if (!store.user_registration_data) {
      history.push('/registration/type')
    }
  }, [store.user_registration_data, history])

  useEffect(() => {
    API().get(`/admin/landing`)
      .then(res => {
        if (res.data) {
          setArtImg(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
          setLoading(true)
        }
      })
  }, [])

  const onSubmitOrganizationReq = (e) => {
    e.preventDefault();
    const { organization_type, name, email, password, confirmPassword } = registrationData
    const submitData = organization_type.value && name.value && email.value && password.value && confirmPassword.value
    const passwordConfirmCheck = password.value.length > 0 && confirmPassword.value.length > 0 && password.value === confirmPassword.value
    const emailCheck = regExp.email.test(email.value)
    const passwordCheck = password.value.length >= 6

    if (!submitData) {
      Object.entries(registrationData).forEach(item => {
        if (!item[1].value) {
          setRegistrationData(prevState => ({
            ...prevState,
            [item[0]]: { ...prevState[item[0]], errorMessage: 'Please fill this label' }
          }))
        }
      })
      return;
    }
    if (!emailCheck) {
      setRegistrationData(prevState => ({
        ...prevState,
        email: {
          ...prevState.email,
          errorMessage: 'Enter valid email',
        }
      }))
    }
    if (!passwordCheck) {
      setRegistrationData(prevState => ({
        ...prevState,
        password: {
          ...prevState.password,
          errorMessage: 'Enter at least 6 characters',
        }
      }))
    }
    if (!passwordConfirmCheck) {
      setRegistrationData(prevState => ({
        ...prevState,
        confirmPassword: {
          ...prevState.confirmPassword,
          errorMessage: 'Password is not similar',
        }
      }))
    }
    if (submitData && emailCheck && passwordCheck && passwordConfirmCheck) {
      API().post('/user/registration/check_email', {email: email?.value})
        .then(response => {
          if (response.data.result) {
            return setRegistrationData(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                errorMessage: 'The email is already in use',
              }
            }))
          }
        }
        )
      API().post('/user/registration/email', {
        email: email.value,
        password: password.value,
        role: 'organization',
        name: name.value,
        organization_type: organization_type.value,
      })
        .then(res => {
          clearCookies();
          let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
          dispatch(setUserRegistrationData({
            ...store.user_registration_data,
            name: name.value || '',
            email: email.value || '',
            password: password.value || '',
            organization_type: organization_type.value || '',
            receive_email: false
          }))
          localStorage.setItem(Keys.JWT_TOKEN_REGISTRATION, res.data.access_token)
          history.push(nextHistory)
        })
        .catch(err => {
          changeContinuePopup('Something went wrong');
          setTimeout(
            () => changeContinuePopup('Something went wrong'),
            2000
          );
          console.log(err)
        });
      // let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
      // history.push(nextHistory)
    }
  }

  return (
    <>
      <ChangedConfirmedPopup
        successText={successText}
        changingConfirmed={changingConfirmed}
      />
      <Wrapper>
        {artImg && loading ?
          <Wrapper.Picture src={artImg.photo} artAuthor={artImg.user.name}
                           authorAvatar={artImg.user.avatar}/>
          : <Loader color={{
            '--bgColor': '#000',
            '--size': '48px',
            '--wrapper_size': '40px'
          }}/>}
        <Wrapper.Form>
          <div className={classes.wrapper}>
            <p className={classes.uptitle}>In order to submit your application you must register.</p>
            <h1 className={classes.title}>Register to continue</h1>
            <form className={classes.form} onSubmit={onSubmitOrganizationReq}>
              <div style={{marginTop: 21, marginBottom: 18}}>
                <Dropdown
                  options={organizationTypes}
                  value={registrationData.organization_type.value}
                  onChange={organization_type => {
                    setRegistrationData(prevState => ({
                      ...prevState,
                      organization_type: {
                        ...prevState.organization_type,
                        value: organization_type,
                        errorMessage: null,
                      }
                    }));
                  }}
                  placeholder='Organization Type'
                  error={registrationData.organization_type.errorMessage}
                />
              </div>
              <Input
                className={classes.form__input}
                title="Organization Name"
                value={registrationData.name.value}
                onChange={({target}) => {
                  setRegistrationData(prevState => ({
                    ...prevState,
                    name: {
                      ...prevState.name,
                      value: target.value,
                      errorMessage: null,
                    }
                  }));
                }}
                error={registrationData?.name.errorMessage}
              />
              <Input
                className={classes.form__input}
                title="Email"
                value={registrationData.email.value}
                onChange={({target}) => {
                  setRegistrationData(prevState => ({
                    ...prevState,
                    email: {
                      ...prevState.email,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
                error={registrationData?.email.errorMessage}
              />
              <Input
                className={classes.form__input}
                type="password"
                title="Password"
                value={registrationData.password.value}
                onChange={({target}) => {
                  setRegistrationData(prevState => ({
                    ...prevState,
                    password: {
                      ...prevState.password,
                      value: target.value.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
                error={registrationData?.password.errorMessage}
              />
              <Input
                className={classes.form__input}
                type="password"
                title="Confirm Password"
                value={registrationData.confirmPassword.value}
                onChange={({target}) => {
                  setRegistrationData(prevState => ({
                    ...prevState,
                    confirmPassword: {
                      ...prevState.confirmPassword,
                      value: target.value?.trim(),
                      errorMessage: null,
                    }
                  }));
                }}
                error={registrationData?.confirmPassword.errorMessage}
              />
              <div className={classes.errors}>
                {/*{Object.keys(errors).map(key => {*/}
                {/*  return <Error text={errors[key]} key={key}/>*/}
                {/*})}*/}
              </div>
              <div className={classes.form_footer}>
                <div className={classes.footer_btns}>
                  <Button type="submit">Continue &#62;</Button>
                </div>
              </div>
            </form>
          </div>
        </Wrapper.Form>
      </Wrapper>
    </>
  )
}