import React from "react";

import './styles/tool_tip_custom.css'

export default function ToolTipCustom({toolTipText, className}) {
    return (
        <div className={`tool_tip_error ${className}`}>
            <span>{toolTipText}</span>
        </div>
    )
}