import React from 'react';
import OutsideClickHandler from '../Constants/detectOutsideClick'
import './styles/popup.css'
import {bodyOverflow} from "../Constants/bodyOverflow";

const Popup = ({closeCallback, title, description, children, to_top}) => {

    const detectOutSideClick = () => {
        bodyOverflow(false)
        closeCallback()
    }

    return (
        <div className={`wrapper_popup ${to_top ? 'wrapper_popup_to_top' : ''}`}>
            <OutsideClickHandler callback={detectOutSideClick}>
                <div className={`popup overflow_scrollbar`}>
                    <div className={'wrapper_close_img'} onClick={detectOutSideClick}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.25 9.5C18.25 14.3325 14.3325 18.25 9.5 18.25C4.66751 18.25 0.75 14.3325 0.75 9.5C0.75 4.66751 4.66751 0.75 9.5 0.75C14.3325 0.75 18.25 4.66751 18.25 9.5Z"
                                stroke="#bc4386" strokeWidth="1.5"/>
                            <path d="M12.2295 6.7717L6.77273 12.2285" stroke="#bc4386" strokeLinecap="round"/>
                            <path d="M12.2292 12.2283L6.77246 6.77153" stroke="#bc4386" strokeLinecap="round"/>
                        </svg>
                    </div>
                    {children ??
                    [
                        <div className={'wrapper_title'} key={1}>
                            <span>{title}</span>
                        </div>, description ?
                        <div className={'wrapper_description'} key={2}>
                            <span>{description}</span>
                        </div>
                        : null
                    ]}
                </div>
            </OutsideClickHandler>
        </div>
    )
};

export default Popup