import React from 'react'
import '../../../styles/terms_of_service.css'

const TermsApplicability = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>1. Applicability of these GTC and Conclusion of Contract</h3>
            <div className={'terms_of_service_item_container'}>
                <span>1.1</span>
                <span>
                    The products and services of this website are distributed resp. produced by (Website operator):
                    <br/>
                    Artisfact Ltd.
                    <br/>
                    71-75 Shelton Street, Covent Garden
                    <br/>
                    London, England, WC2H 9JQ
                    <br/>
                    Email: info@artisfact.co.uk
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>1.2</span>
                <span>
                    These present GTC apply to all on the website available products and services for individual and
                    business customers. By posting the online order, the individual or business customer accepts these
                    present GTC. Any conditions conflicting or deviating from these GTC only apply after a respective
                    confirmation in written form by the Website operator.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>1.3</span>
                <span>
                    The offerings of the Website operator on the website are presented without any obligation.
                    The Website operator reserves the right to change prices, range of products and services, as well
                    as to execute technical changes at any time. The product and service offerings are only valid, as
                    long as they are presented on the website and as long as stocks last. Product and service
                    information such as descriptions, pictures etc., serve illustration purposes and are presented
                    without any obligation.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>1.4</span>
                <span>
                    The order of a customer via the online shop counts as a compulsory offer to the Website operator.
                    By clicking the button "place order" in the last step of the order process, the customer posts a
                    compulsory order for the products and services added to the shopping cart. The customer is obliged
                    to state truthfully all requested information in the order process
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>1.5</span>
                <span>
                    The Website operator reserves the right not to accept an order. The decision about acceptance or
                    refusal of an order is at the Website operators sole discretion. The contract is effective after
                    the declaration of acceptance by the Website operator. The order confirmation sent to the customer
                    by email counts as declaration of acceptance. Changes and updates of the order, of the delivery
                    conditions, of the product and service range, as well as restrictions on the quantities delivered
                    are reserved until the order is confirmed by the Website operator.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>1.6</span>
                <span>
                    These GTC apply accordingly to purchase contracts that have been concluded in a different way, e,g,
                    via phone, email or letter. Customers will be informed in the order confirmation where they can
                    download the GTC on the Internet.
                </span>
            </div>
        </div>
    )
}

export default TermsApplicability