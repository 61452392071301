import clsx from "clsx";
import { forwardRef } from "react";

import styles from "./IconButton.module.css";

const IconButtonBase = (
  {
    component,
    icon: Icon,
    round,
    color,
    className,
    iconClassName,
    disabled,
    size = "medium",
    ...rest
  },
  ref,
) => {
  const Component = component || "button";

  const classNames = clsx(
    styles.root,
    className,
    color && styles[color],
    disabled && styles.disabled,
    round && styles.round,
  );

  const iconClassNames = clsx(styles.icon, iconClassName, styles[size]);

  return (
    <Component ref={ref} disabled={disabled} {...rest} className={classNames}>
      <Icon className={iconClassNames} />
    </Component>
  );
};

const IconButton = forwardRef(IconButtonBase);

export default IconButton;
