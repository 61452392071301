import * as types from "./types";

const initialState = {
    loading: false,
    listings: [],
    page: 1,
    pages: null,
    per_page: 15,
    total: 0,
    filters: {},
    stayData: null,
    amenities: [],
    myListings: {
        items: [],
    },
    isDeleting: false,
}

const listingsReduces = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LISTINGS_REQUEST: {
            return {
                ...state,
                loading: true,
                listings: [],
                page: 1,
            }
        }
        case types.GET_LISTINGS_RECEIVED: {
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        }
        case types.GET_LISTINGS_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }
        case types.GET_MY_LISTINGS_REQUEST: {
            return {
                ...state,
                myListings: {
                    loading: true,
                    items: [],
                    page: 1,
                }
            }
        }
        case types.GET_MY_LISTINGS_RECEIVED: {
            return {
                ...state,
                myListings: {
                    loading: false,
                    items: action.payload.listings,
                }
            }
        }
        case types.GET_MY_LISTINGS_FAILED: {
            return {
                ...state,
                myListings: {
                    loading: false,
                    items: [],
                }
            }
        }
        case types.GET_LISTING_STAY_REQUEST: {
            return {
                ...state,
                loading: true,
                stayData: null
            }
        }
        case types.GET_LISTING_STAY_RECEIVED: {
            return {
                ...state,
                loading: false,
                stayData: action.payload
            }
        }
        case types.GET_LISTING_STAY_FAILED: {
            return {
                ...state,
                loading: false,
            }
        }
        case types.UPDATE_LISTING_PAGINATION: {
            return {
                ...state,
                page: action.payload
            }
        }
        case types.GET_LISTING_AMENITIES_RECEIVED: {
            return {
                ...state,
                amenities: action.payload
            }
        }
        case types.DELETE_LISTING_STAY_REQUEST: {
            return {
                ...state,
                isDeleting: true,
            }
        }
        case types.DELETE_LISTING_STAY_SUCCESS: {
            return {
                ...state,
                myListings: {
                  ...state.myListings,
                  items: state.myListings.items.filter((i) => i.id !== action.payload)
                },
                isDeleting: false,
            }
        }
        case types.DELETE_LISTING_STAY_FAILED: {
            return {
                ...state,
                isDeleting: false,
            }
        }
        default:
            return state
    }
}

export default listingsReduces