import React from 'react';
import {Link} from "react-router-dom";
import artworks_placeholder from "../../assets/img/artworks_placeholder.png";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {setNewHeaderBucket} from "../../main-store/auth/actions";
import delete_button from "../../assets/img/cart/delete_button.svg";
import {useDispatch} from "react-redux";

const NFTItem = ({
     linkArtistProfile,
     setActiveModal,
     activeModal,
     item,
     index,
     activeData
 }) => {
    const dispatch = useDispatch();
    return (
        <div className={'user_cart_order_item'} key={item.id}>
            <div className={'user_cart_order_item_preview'}>
                <Link to={`/public_artwork/${item.id}`}>
                    <img
                        src={item?.artwork?.pictures[0] ? item?.artwork?.pictures[0] : item.picture}
                        onError={e => {
                            e.target.src = artworks_placeholder
                        }}
                        alt={`NFT: ${item?.name} artwork`}/>
                </Link>
            </div>
            <div style={activeData.length === index + 1
                ? {borderBottom: 'none', padding: '0'}
                : {
                    borderBottom: 'solid 1px var(--lite-grey)',
                    padding: '0 0 1.5rem'
                }}
                 className={'user_cart_order_item_content'}
                // eslint-disable-next-line
                 style={{
                     gridTemplateColumns: '1fr max-content',
                     borderBottom: '0px'
                 }}>
                <div className={'user_cart_order_item_user'}>
                    <Link to={`/artist-profile/${item.profile_url}`}
                          onClick={event => linkArtistProfile(event)}>
                        <h5>{item.name}</h5>
                    </Link>
                    <p>{item?.user?.name ? item?.user?.name : item.artist.name}</p>
                    <p>{item?.artwork?.title ? item?.artwork?.title : item.title}</p>
                </div>
                <div className={'user_cart_order_item_content_price'}
                     style={{display: 'flex', gridGap: '4rem'}}>
                    <div className={'user_cart_order_item_content_price_container'}>
                        <span
                            style={{lineHeight: '1.2'}}>
                            ETH {item?.artwork?.ether_price ? item?.artwork?.ether_price : item.ethereum_price}
                        </span>
                        <span style={{
                            fontWeight: '300',
                            fontSize: '17px'
                        }}>(${item?.artwork?.price ? item?.artwork?.price : item.price})</span>
                    </div>
                    <img onClick={() => {
                        bodyOverflow(true)
                        setActiveModal(activeModal !== item.id || item.artwork.id ? item.id || item.artwork.id : null)
                        dispatch(setNewHeaderBucket(activeData?.length))
                    }}
                         src={delete_button} alt="delete button"/>
                </div>
            </div>
        </div>
    );
};

export default NFTItem;
