export const GET_LISTINGS_REQUEST = 'GET_LISTINGS_REQUEST';
export const GET_LISTINGS_RECEIVED = 'GET_LISTINGS_RECEIVED';
export const GET_LISTINGS_FAILED = 'GET_LISTINGS_FAILED';

export const GET_MY_LISTINGS_REQUEST = 'GET_MY_LISTINGS_REQUEST';
export const GET_MY_LISTINGS_RECEIVED = 'GET_MY_LISTINGS_RECEIVED';
export const GET_MY_LISTINGS_FAILED = 'GET_MY_LISTINGS_FAILED';

export const GET_LISTING_STAY_REQUEST = 'GET_LISTING_STAY_REQUEST';
export const GET_LISTING_STAY_RECEIVED = 'GET_LISTING_STAY_RECEIVED';
export const GET_LISTING_STAY_FAILED = 'GET_LISTING_STAY_FAILED';

export const DELETE_LISTING_STAY_REQUEST = 'DELETE_LISTING_STAY_REQUEST';
export const DELETE_LISTING_STAY_SUCCESS = 'DELETE_LISTING_STAY_SUCCESS';
export const DELETE_LISTING_STAY_FAILED = 'DELETE_LISTING_STAY_FAILED';

export const GET_LISTING_AMENITIES_RECEIVED = 'GET_LISTING_AMENITIES_RECEIVED';

export const UPDATE_LISTING_PAGINATION = 'UPDATE_LISTING_PAGINATION';