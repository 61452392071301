import './styles/banner.css'
import MainButton from "./CustomComponents/MainButton";
import React from "react";
import {BrushPrint} from "../assets/icons";

export const Banner = ({title, buttonText, onClick}) => {
  return(
    <div className={'top_banner_wrapper'} onClick={onClick}>
      <div className={'brush_wrapper'}>
        <BrushPrint/>
      </div>
      <div>
        <p className={'title'}>{title}</p>
      </div>
      <div className={'top_banner_button_wrapper'}>
        <MainButton
          style={{width: '100%'}}
          className={'button_white'}
          text={buttonText}
          onClick={() => ''}
        />
      </div>

    </div>
  )
}