import React from 'react'
import './styles/footer.css'
import logos from "../assets/img/artsted_footer_2.svg"
import twitter from "../assets/img/main_twitter.svg"
import facebook from "../assets/img/facebook.svg"
import instagram from "../assets/img/instagram.svg"
import linkedin from "../assets/img/linkedin.svg"
import pinterest from "../assets/img/pinterest.svg"
import medium from "../assets/img/medium.svg"
import trustpilot from "../assets/img/trustpilottt.svg"
import {Link} from 'react-router-dom';
import Keys from "../Constants/helper";

const infoFoot = [
    {
        title: "For Investors",
        preCategory: [
            {
                value: "Art Advisory Services",
                link: '/info/art_advisory_services'
            },
            {
                value: "Buyer Faq",
                link: '/info/buyer_faq'
            },
            {
                value: "Testimonials",
                link: '/info/testimonials'
            },

            {
                value: "Curator's Circle",
                link: '/info/curators_circle'
            }
        ]
    },
    {
        title: "For Artists",
        preCategory: [
            {
                value: "Why Sell",
                link: '/info/why_sell'
            },
            {
                value: "Artist Handbook",
                link: '/info/artist_handbook'
            },
            {
                value: "How Does It Work",
                link: '/info/how_does_it_work'
            }
        ]
    },
    {
        title: "About Us",
        preCategory: [
            {
                value: "Press",
                link: '/info/press'
            },
            {
                value: "Careers",
                link: '/info/careers'
            },
            {
                value: "Contact Us",
                link: '/info/contact_us'
            },
            {
                value: "Blog",
                link: null,
                href: 'https://blog.artsted.com'
            }
        ]
    },
    {
        title: "Artisfact",
        preCategory: [
            {
                value: "Terms Of Service",
                link: '/info/terms_of_service/applicability'
            },
            {
                value: "Privacy Policy",
                link: '/info/privacy_policy/important_information'
            },
            {
                value: "Copiright Policy",
                link: '/info/copyright_policy'
            },
            {
                value: "Affiliate Program",
                link: '/info/affiliate_program'
            }
        ]
    },
]

const Footer = () => {
    const forceUpdate = React.useState()[1].bind(null, {});

    return (
        <footer id={'footer'}>
            <div className="footer_section">
                <div className="six_section_wrapper padding_line">
                    <div className="footer_logo">
                        <img src={logos} alt="Logo"/>
                    </div>
                    <div className={"footer_navigation"}>
                                <span className={sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'investor' ?
                                    'footer_navigation__active' : null}
                                      onClick={() => {
                                          sessionStorage.setItem(Keys.FOOTER_CATEGORY, 'investor')
                                          forceUpdate()
                                      }}>For Investor</span>

                        <span className={sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'artist' ?
                            'footer_navigation__active' : null}
                              onClick={() => {
                                  sessionStorage.setItem(Keys.FOOTER_CATEGORY, 'artist')
                                  forceUpdate()
                              }}>For Artist</span>

                        <span className={sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'about' ?
                            'footer_navigation__active' : null}
                              onClick={() => {
                                  sessionStorage.setItem(Keys.FOOTER_CATEGORY, 'about')
                                  forceUpdate()
                              }}>About Us</span>

                        <span className={sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'artisfact' ?
                            'footer_navigation__active' : null}
                              onClick={() => {
                                  sessionStorage.setItem(Keys.FOOTER_CATEGORY, 'artisfact')
                                  forceUpdate()
                              }}>Artisfact</span>

                    </div>
                    <div className={'footer_navigation_list'}>
                        {sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'investor' ?
                            <div className="investors_block">
                                <ul>
                                    <li><Link to={"/info/art_advisory_services"}>Art Advisory Services</Link></li>
                                    <li><Link to={'/info/buyer_faq'}>Buyer FAQ</Link></li>
                                    <li><Link to={'/info/testimonials'}>Testimonials </Link></li>
                                    <li><Link to={'/info/curators_circle'}>Curator’s Circle</Link></li>
                                </ul>
                            </div>
                            : sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'artist' ?
                                <div className="investors_block">
                                    <ul>
                                        <li><Link to={"/info/why_sell"}>Why Sell</Link></li>
                                        <li><Link to={'/info/artist_handbook'}>Artists Handbook</Link></li>
                                        <li><Link to={'/info/how_does_it_work'}>How it work</Link></li>
                                    </ul>
                                </div> :
                                sessionStorage.getItem(Keys.FOOTER_CATEGORY) === 'about' ?
                                    <div className="investors_block">
                                        <ul>
                                            <li><Link to={'/info/press'}>Press</Link></li>
                                            <li><Link to={'/info/careers'}>Careers</Link></li>
                                            <li><Link to={'/info/contact_us'}>Contact us </Link></li>
                                            <li><Link to={'/blog'}>Blog</Link></li>
                                        </ul>
                                    </div> :
                                    <div className="investors_block">
                                        <ul>
                                            <li><Link to={'/info/terms_of_service/applicability'}>Terms of Service</Link></li>
                                            <li><Link to={'/info/privacy_policy/important_information'}>Privacy Policy</Link></li>
                                            <li><Link to={'/info/copyright_policy'}>Copyright Policy</Link></li>
                                            <li><Link to={'/info/affiliate_program'}>Affiliate Program</Link></li>
                                        </ul>
                                    </div>
                        }
                    </div>

                    <div className={'question_wrapper_info'}>
                        <div className={'footer_questions_list'}>
                            {infoFoot.map((item, index) => (
                                <div className={'investors_block'} key={index}>
                                    <span>{item.title}</span>
                                    <div className={'investors_block'}>
                                        {item.preCategory.map(el => (
                                            <div key={el.value}>
                                                {el.link ?
                                                    <Link to={el.link}>{el.value}</Link> :
                                                    <a href={el.href}>{el.value}</a>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>

                    </div>
                </div>
                <div className="footer_right">
                    <div className="messengers">
                        <ul>
                            <li><a target="new" href="https://www.facebook.com/artstedcom">
                                <button><img src={facebook} alt="Facebook_logo"/></button>
                            </a>
                            </li>
                            <li>
                                <a target="new" href="https://twitter.com/artsted1">
                                    <button><img src={twitter} alt="twitter_logo"/></button>
                                </a>
                            </li>
                            <li><a target="new" href="https://www.instagram.com/artstedcom/">
                                <button className={'no_circle insta_log'}><img src={instagram} alt="instagram_logo"/>
                                </button>
                            </a>
                            </li>

                            <li><a target="new" href="http://pinterest.com/Artsted_online/">
                                <button className={'no_circle linked'}><img src={pinterest} alt="pinterest"/></button>
                            </a>
                            </li>
                            <li><a target="new" href="http://linkedin.com/company/14810659/">
                                <button className={'no_circle linked'}><img src={linkedin} alt="linkedin"/></button>
                            </a>
                            </li>
                            <li><a target="new" href="https://artstedcom.medium.com/">
                                <button className={'no_circle linked'}><img src={medium} alt="medium_logo"/></button>
                            </a>
                            </li>
                        </ul>
                    </div>
                    <div className={"trustpilot_link"}>
                        <a target="new" href={"https://www.trustpilot.com/review/artsted.com"}>
                            <img src={trustpilot} alt="trustpilot"/>
                        </a>
                    </div>

                </div>
            </div>
            <div className="solid_line">
                <div/>
                <p>(C) 2021 artsted.com</p>
            </div>
        </footer>
    )
}

export default Footer;
