import io from "socket.io-client";
import Keys from "./helper";

const token = localStorage.getItem(Keys.JWT_TOKEN)

// function socket(id) {
let socket;
if (token) {
    socket = io(process.env.REACT_APP_API_URL, {
        query: {jwt: token}
    });
}
// }

export default socket