import './styles/upload_file.css'
import React from "react";
import close_circle from '../../assets/img/onboarding/close_circle.svg';

const Uploader = (props) => {

    const _uploadFile = e => {
        if (e.target.files[0] instanceof File) {
            const file = e.target.files[0];
            const blob = URL.createObjectURL(file);
            props.onLoad({file, blob});
        }
    }

    let radius = props.radius ? {borderRadius: '50%'} : null
    // mob version registration

    return (
        <div className={!props.radius ? 'uploader uploader_mob': 'uploader'}>
            <label className={'uploader__label'}
                   onClick={e => {
                       if (e.target.className === 'uploaded_work_delete_icon') {
                           e.preventDefault()
                       }
                   }}>
                <input type='file'
                       onChange={_uploadFile}
                       hidden
                       accept={props.acceptFiles ? props.acceptFiles : "image/png, image/jpg, image/jpeg, image/gif, image/webp"}/>
                <div className={'uploader_img'} style={radius}>
                    {props.value ? [
                        <img src={props.value} alt="work"
                             style={radius}
                             key={0}
                             className={'uploader_work'}/>,
                        props.delete &&
                        <img src={close_circle} alt="work"
                             style={radius}
                             key={1}
                             onClick={props._deleteFile}
                             className={'uploaded_work_delete_icon'}/>
                    ] : null}
                </div>
            </label>
        </div>
    );
}

export default Uploader