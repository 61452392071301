import React from 'react'

const TermsPayment = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>7. Payment Conditions</h3>
            <div className={'terms_of_service_item_container'}>
                <span>7.1</span>
                <span>
                    he prices are displayed under the respective offerings and listed in Euros / EUR. Applicable
                    is the price at the time the order is made.
                    The applicable price includes the applicable statutory VAT, if not separately declared.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>7.2</span>
                <span>
                    The customer may choose among the following payment options: <br/>
                    – Pay by credit card<br/>
                    – Pay by bank transfer (prepay): the invoice will be sent to the customer after receipt of the
                    order and must be paid in advance by bank transfer. If no payment is received within 14 days of
                    receipt of the order, the order will be canceled by the Website operator. Any partial payments
                </span>
            </div>
        </div>
    )
}

export default TermsPayment