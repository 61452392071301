import {useEffect, useState} from "react";
import socket from "../../Constants/socket"

const useChat = (orderId) => {
    const [uniqueInboxData, setUniqueInboxData] = useState(null);
    const [isCompleted, setIsCompleted] = useState(null);

    useEffect(() => {
        // alert('connect')
        // console.log('connect')
        socket.connect()
    }, [])

    const socketConnect = () => {
        socket.on("connect_to_chat", chat_data => {
            setUniqueInboxData(chat_data)
        });

        socket.on("new_chat_message", (message) => {
            if (message.type === 'completed' || message.type === 'cancel') {
                setIsCompleted(true)
            }
            setUniqueInboxData(prevData => {
                if (prevData.messages?.length > 1) {
                    let lastMessage = prevData.messages[prevData.messages.length - 1].message;
                    if (lastMessage.text === message.message.text &&
                        lastMessage.text === message.message.text) {
                        return ({
                            ...prevData
                        })
                    }else {
                        return ({
                            ...prevData,
                            messages: [...prevData.messages, message]
                        })
                    }
                }else {
                    return ({
                        ...prevData,
                        messages: [...prevData.messages, message]
                    })
                }
            });
            // console.log('________');
        });
    }

    useEffect(() => {
        socketConnect()
        return () => {
            // console.log('disconnect')
            socket.disconnect();
        }
    }, [])


    useEffect(() => {
        if (!isNaN(Number(orderId))) {
            console.log('connect_to_chat')
            socket.emit("connect_to_chat", {
                order: Number(orderId),
            });
        }
    }, [orderId])


    const sendMessage = (messageBody, type) => {
        socket.emit("new_chat_message", {
            message: messageBody,
            order: Number(orderId),
            type: type
        });
    };

    return {uniqueInboxData, sendMessage, isCompleted, setIsCompleted};
};

export default useChat;
