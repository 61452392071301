import React, {useState, useEffect} from 'react';
import "./styles/login.css"

import Logo from "../../assets/img/admin/login_logo.svg"
import Keys from "../../Constants/helper";
import TextInput from "../../Components/CustomComponents/TextInput";
import MainButton from "../../Components/CustomComponents/MainButton";
import useResize from "../../Constants/useResize";
import API from "../../api/API";

const LoginPage = () => {
    const {innerWidth} = useResize()
    const [adminData, setAdminData] = useState({
        login: '',
        password: '',
        error: null,
        errorMessage: null
    })

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN_ADMIN)) {
            window.location.pathname = "/admin/investors"
        }
    }, []);

    const handleSubmit = event => {
        event.preventDefault();
        const {login, password} = adminData
        if (login && password) {
            API().post(`/admin/login`, {
                login: adminData.login,
                password: adminData.password
            })
                .then(res => {
                    if (res.data.access_token) {
                        localStorage.setItem(Keys.JWT_TOKEN_ADMIN, res.data.access_token)
                        window.location.pathname = "/admin/investors"
                    }
                })
                .catch((err) => {
                    if (err.response.data.error === 'wrong data') {
                        setAdminData(prevState => ({
                            ...prevState,
                            error: true, errorMessage: 'Wrong username or password'
                        }))
                    }
                })
        } else {
            Object.entries(adminData).forEach(() => {
                setAdminData(prevState => ({
                    ...prevState,
                    error: true, errorMessage: 'Please fill all the fields',
                }))
            })
        }
    };

    return (
        <div className={'admin_login_wrapper'}>
            <div className={'admin_login_content'}>
                {innerWidth >= 1024 ?
                    [
                        <img src={Logo} alt="Artsted logo" key={1}/>,
                        <form onSubmit={(event) => handleSubmit(event)}
                              key={2}>
                            <TextInput value={adminData.login}
                                       type={'text'}
                                       error={adminData.error}
                                       placeholder={'Login'}
                                       onChange={({target}) => {
                                           setAdminData(prevState => ({
                                               ...prevState,
                                               login: target.value,
                                               errorMessage: null,
                                               error: false
                                           }))
                                       }}/>
                            <TextInput value={adminData.password}
                                       type={'password'}
                                       placeholder={'Password'}
                                       showPass={true}
                                       error={adminData.error}
                                       autoComplete={'on'}
                                       onChange={({target}) => {
                                           setAdminData(prevState => ({
                                               ...prevState,
                                               password: target.value,
                                               error: false,
                                               errorMessage: null
                                           }))
                                       }}/>
                            <span className={'custom_error'}>{adminData.errorMessage}</span>
                            <MainButton type={'submit'}
                                        style={{padding: '12px 0'}}
                                        text={'Login'}
                            />
                        </form>
                    ]
                    : <div className={'admin_panel_content_responsive'}>
                        <span>Admin panel is not available on this resolution</span>
                        <span>Please use the device with width at least 1100px</span>
                    </div>}
            </div>
        </div>

    );
};

export default LoginPage
