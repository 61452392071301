import React, {useEffect, useState} from 'react';
import './styles/registration.css'
import MainButton from "../../Components/CustomComponents/MainButton";
import MainInfo from "./MainInfo";
import InfoPhotoVideo from "./InfoPhotoVideo";
import InfoCategories from "./InfoCategories";
import API from "../../api/API";
import Keys from "../../Constants/helper";
import {clearCookies} from "../../Constants/clearCookies";
import {setUserData} from '../../main-store/auth/actions';
import {connect, useDispatch} from "react-redux";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import useResize from "../../Constants/useResize";
import {Link, useHistory} from "react-router-dom";

// import {clearCookieValue} from "../../Constants/clearCookieValue";

function PersonalInfo(props) {
    const initialState = {
        name: {
            value: '',
            error: false,
            require: true,
            errorMessage: `This field can't be empty`,
        },
        surname: {
            value: '',
            error: false,
            require: true,
            errorMessage: `This field can't be empty`,
        },
        country: {
            value: '',
            error: false,
            errorMessage: `This field can't be empty`,
            require: true,
            popup: false,
            options: [],
        },
        linkedin_link: {
            value: '',
        },
        behance_link: {
            value: '',
        },
        facebook_link: {
            value: '',
        },
        instagram_link: {
            value: '',
        },
        about_yourself: {
            value: '',
            error: null,
            require: true,
            errorMessage: `This field can't be empty`
        },
        categories: {
            value: '',
            popup: false,
            dataArray: [],
            error: false,
            errorMessage: 'You have to choose at least one option',
            options: [],
        },
        activeStep: 1
    }
    const [registrationData, setRegistrationData] = useState(() => initialState)
    const [loading, setLoading] = useState(null)
    const [role, setRole] = useState(null)
    const [cv, setCv] = useState(null)
    const [artistVideo, setArtistVideo] = useState(
        {
            preview: null,
            value: null,
            isLoaded: false
        }
    )
    const [changeCurrentPhoto, setChangeCurrentPhoto] = useState(null)
    const {innerWidth} = useResize();
    const [videoLoading, setVideoLoading] = useState(null);
    const [artistPhotos, setArtistPhotos] = useState({
        photos: {
            value: [],
            files: []
        }
    })
    let history = useHistory();
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    let dispatch = useDispatch()
    const headerToken = () => {
        return !localStorage.getItem(Keys.JWT_TOKEN) ? Keys.JWT_TOKEN_REGISTRATION : null
    }

    useEffect(() => {
        let getData = sessionStorage.getItem(Keys.REGISTRATION_DATA);
        let getTokenRegistration = localStorage.getItem(Keys.JWT_TOKEN_REGISTRATION);
        let getTokenUser = localStorage.getItem(Keys.JWT_TOKEN);
        // console.log(getData)
        if (getTokenUser) {
            let check_role = JSON.parse(localStorage.getItem(Keys.USER_DATA)).role
            Promise.all([
                API(headerToken()).get(`/user/categories`),
                API(headerToken()).get(`/user/countries`),
                API(headerToken()).get(`/user/profile/edit`)
            ])
                .then(res => {
                    setRegistrationData(state => ({
                        ...state,
                        categories: {
                            ...state.categories,
                            options: res[0].data.categories,
                            dataArray: res[2].data.categories
                        },
                        country: {...state.country, options: res[1].data.countries, value: res[2].data.country},
                        name: {...state.name, value: res[2].data.name},
                        surname: {...state.surname, value: res[2].data.surname},
                        linkedin_link: {...state.linkedin_link, value: res[2].data.linkedin_link},
                        behance_link: {...state.behance_link, value: res[2].data.behance_link},
                        facebook_link: {...state.facebook_link, value: res[2].data.facebook_link},
                        instagram_link: {...state.instagram_link, value: res[2].data.instagram_link},
                        about_yourself: {...state.about_yourself, value: res[2].data.about_yourself}
                    }))
                    setCv(res[2].data.cv)
                    setArtistVideo({preview: res[2].data.video, value: res[2].data.video})
                    setVideoLoading(true)
                    if (role === 'artist') {
                        setArtistPhotos({
                            photos: {
                                value: [...res[2].data.photos] ? [...res[2].data.photos] : null,
                                files: [...res[2].data.photos] ? [...res[2].data.photos] : null,
                            }
                        })
                    }
                })
                .then(() => {
                    setLoading(true)
                })
            setRole(check_role)
            setLoading(true)
        } else if (getData && getTokenRegistration) {
            setRegistrationData(JSON.parse(getData))
            API(headerToken()).get(`/user/check_role`)
                .then(res => {
                    setRole(res.data.role)
                    setLoading(true)
                })
                .catch(() => {
                    clearCookies();
                    window.location.pathname = '/'
                })
        } else if (getTokenRegistration) {
            Promise.all([
                API(headerToken()).get(`/user/categories`),
                API(headerToken()).get(`/user/countries`),
                API(headerToken()).get(`/user/check_role`),
            ])
                .then(res => {
                    setRegistrationData(state => ({
                        ...state,
                        categories: {...state.categories, options: res[0].data.categories},
                        country: {...state.country, options: res[1].data.countries},
                    }))
                    if (res[2].data) {
                        setRole(res[2].data.role)
                    } else {
                        let getRole = JSON.parse(localStorage.getItem(Keys.USER_DATA));
                        setRole(getRole)
                    }
                })
                .then(() => {
                    setLoading(true)
                })
                .catch(() => {
                    clearCookies();
                    sessionStorage.removeItem(Keys.REGISTRATION_DATA);
                    window.location.pathname = '/'
                })
        } else {
            clearCookies();
            sessionStorage.removeItem(Keys.REGISTRATION_DATA);
            window.location.pathname = '/'
        }
    }, [role])

    useEffect(() => {
        if (loading && headerToken()) {
            sessionStorage.setItem(Keys.REGISTRATION_DATA, JSON.stringify(registrationData))
        }
    }, [registrationData, loading])

    const uploadIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16.533" height="19.199" viewBox="0 0 16.533 19.199">
                <g id="noun_Attachment_1515241" transform="translate(600.602 -272.913)">
                    <path id="Path_4566"
                          d="M43.9 33.684a.889.889 0 1 0-1.253-1.253L35.981 39.1a2.769 2.769 0 0 0 0 3.92 2.835 2.835 0 0 0 3.92 0l6.666-6.666a4.657 4.657 0 1 0-6.587-6.587l-6.666 6.667a6.543 6.543 0 1 0 9.253 9.253l4.889-4.889a.889.889 0 1 0-1.256-1.256l-4.886 4.888a4.77 4.77 0 0 1-6.746-6.746l6.666-6.666a2.885 2.885 0 0 1 4.08 4.08l-6.666 6.666a1 1 0 0 1-1.413-1.413z"
                          fill="#fff" transform="translate(-632 244.513)"/>
                </g>
            </svg>
        )
    }

    const handleSubmitRegistration = () => {
        let formData = new FormData();
        let {
            name, surname, country, linkedin_link,
            behance_link, instagram_link, facebook_link,
            about_yourself
        } = registrationData;
        formData.append('name', name.value)
        formData.append('surname', surname.value)
        formData.append('country', country.value)
        if (role === 'artist') {
            formData.append('about_yourself', about_yourself.value)
            if (linkedin_link.value?.length) {
                formData.append('linkedin_link', linkedin_link.value)
            }
            if (behance_link.value?.length) {
                formData.append('behance_link', behance_link.value)
            }
            if (instagram_link.value?.length) {
                formData.append('instagram_link', instagram_link.value)
            }
            if (facebook_link.value?.length) {
                formData.append('facebook_link', facebook_link.value)
            }
            if (cv) {
                formData.append('cv', cv)
            }
        }

        if (artistPhotos.photos.files.length) {
            for (let i = 0; i < artistPhotos.photos.files.length; i++) {
                if (!headerToken() && artistPhotos.photos.value[i]?.local && (artistPhotos.photos.files[i]).toString() === '[object File]') {
                    formData.append('photos', artistPhotos.photos.files[i])
                } else if (headerToken()) {
                    formData.append('photos', artistPhotos.photos.files[i])
                }
            }
        }
        formData.append('categories', registrationData.categories.dataArray)

        if (!headerToken() && (artistVideo?.value)?.toString() === '[object File]') {
            formData.append('video', artistVideo.value)
        }

        API(headerToken()).post(`/user/profile/edit`, formData)
            .then(res => {
                let userData = JSON.stringify({
                    name: !headerToken() ? name.value : res.data.name,
                    role: !headerToken() ? role : res.data.role
                })
                if (!headerToken()) {
                    changeContinuePopup('Changes is saved')
                    props.setUserData(JSON.parse(userData))
                    dispatch(setUserData(userData))
                    setTimeout(() => {
                        changeContinuePopup('Changes is saved')
                        window.location.pathname = (role === 'investor' || role === 'organization') ? '/profile/about' : '/profile/portfolio';
                    }, 1000)
                } else {
                    clearCookies();
                    sessionStorage.removeItem(Keys.REGISTRATION_DATA);
                    localStorage.setItem(Keys.JWT_TOKEN, res.data.access_token)
                    localStorage.setItem(Keys.USER_DATA, userData)
                    dispatch(setUserData(userData))
                    props.setUserData(res.data)
                    window.location.pathname = (role === 'investor' || role === 'organization') ? '/profile/about' : '/profile/portfolio';
                }
            })
            .catch(e => console.log(e))
    }

    let handleErrorCategories = () => {
        if (registrationData.categories.dataArray.length) {
            handleSubmitRegistration()
        } else {
            setRegistrationData(state => ({
                ...state,
                categories: {...state.categories, error: true},
            }))
        }
    }

    const uploadCV = event => {
        event.preventDefault();
        let image = event.target.files[0];
        if (image) {
            setCv(image)
        }
    }

    const uploadVideo = event => {
        event.preventDefault();
        setArtistVideo(prevState => ({
            ...prevState,
            isLoaded: true
        }))
        setVideoLoading(false)
        setTimeout(() => {
            setVideoLoading(true)
            let file = event.target.files[0];
            let formData = new FormData();

            if (file) {
                let newVideo = artistVideo.value;
                formData.append('video', file)
                setArtistVideo(prevState => ({
                    ...prevState,
                    preview: URL.createObjectURL(file),
                    value: file,
                }))
                API(headerToken()).post(`/user/profile/upload_video`, formData)
                    .then((res) => {
                        setArtistVideo(prevState => ({
                            ...prevState,
                            isLoaded: false
                        }))
                        if (!headerToken()) {
                            changeContinuePopup(`Video is ${newVideo ? 'changed' : 'added'}`)
                            setTimeout(() => {
                                changeContinuePopup(`Video is ${newVideo ? 'changed' : 'added'}`)
                            }, 1000)
                        }
                    })

            } else {
                setArtistVideo(prevState => ({
                    ...prevState,
                    isLoaded: false
                }))
            }
        }, 1)
    }

    const uploadPhotos = (event, item = null) => {
        event.preventDefault()
        let files = event.target.files;
        if (item && !headerToken()) {
            let refactorPhotosFile = artistPhotos.photos.value.map(i => {
                if (i.id === item.id) {
                    return files[0]
                } else {
                    return i
                }
            })
            if (item.local) {
                let refactorPhotos = artistPhotos.photos.value.map(i => {
                    if (i.id === item.id) {
                        return {
                            path: URL.createObjectURL(event.target.files[0]),
                            id: performance.now(),
                            local: true
                        }
                    } else {
                        return i
                    }
                })
                setArtistPhotos(state => ({
                    photos: {
                        ...state.photos,
                        value: refactorPhotos,
                        files: refactorPhotosFile
                    }
                }))
                setChangeCurrentPhoto(null)
            } else {
                let formData = new FormData();
                formData.append('photo', event.target.files[0])
                API(headerToken()).patch(`/user/profile/edit?id=${item.id}`, formData)
                    .then(() => {
                        let refactorPhotos = artistPhotos.photos.value.map(i => {
                            if (i.id === item.id) {
                                return {
                                    path: URL.createObjectURL(event.target.files[0]),
                                    id: item.id
                                }
                            } else {
                                return i
                            }
                        })
                        setArtistPhotos(state => ({
                            photos: {
                                ...state.photos,
                                value: refactorPhotos,
                                files: refactorPhotosFile
                            }
                        }))
                        changeContinuePopup('Photo is changed')
                        setTimeout(() => {
                            changeContinuePopup('Photo is changed')
                        }, 1000)
                        setChangeCurrentPhoto(null)
                    })
            }
        } else {
            for (let i = 0; i < files.length; i++) {
                if (i < 5 && (files.length + artistPhotos.photos.value.length) <= 5) {
                    setArtistPhotos(state => ({
                        photos: {
                            ...state.photos,
                            value: [...state.photos.value,
                                {
                                    path: URL.createObjectURL(event.target.files[i]),
                                    id: performance.now(),
                                    local: true
                                },
                            ],
                            files: [...state.photos.files, files[i]]
                        }
                    }))

                }
            }
        }
    }

    const handleErrorMainInfo = () => {
        let {name, surname, country, about_yourself} = registrationData;
        let condition = name.value.length && surname.value.length && country.options.includes(country.value);
        if (!headerToken()) {
            if (registrationData.categories.dataArray.length && condition) {
                handleSubmitRegistration()
            } else {
                handleCatchError()
            }
        } else {
            if (role === 'artist') {
                if (condition && about_yourself.value) {
                    setRegistrationData(state => ({
                        ...state,
                        activeStep: 2,
                    }))
                } else {
                    handleCatchError()
                }
            } else {
                if (condition) {
                    setRegistrationData(state => ({
                        ...state,
                        activeStep: 3,
                    }))
                } else {
                    handleCatchError()
                }
            }

        }
    };

    const handleCatchError = () => {
        Object.entries(registrationData).forEach(i => {
            if (i[1]?.require) {
                if (i[0] === 'country') {
                    if (!i[1].options.includes(i[1].value)) {
                        setRegistrationData(state => ({
                            ...state,
                            [i[0]]: {...state[i[0]], error: true},
                        }))
                    }
                } else {
                    if (!i[1].value) {
                        setRegistrationData(state => ({
                            ...state,
                            [i[0]]: {...state[i[0]], error: true},
                        }))
                    }
                }
            }
        })
    }

    const changePhotos = photos => {
        setArtistPhotos({
            photos: {
                value: [...photos.value],
                files: [...photos.files]
            }
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [registrationData.activeStep])


    let _backConfirm = async () => {
        // console.log(e.location);
        // console.log(e);
        // if (window.confirm('123')) {
        sessionStorage.clear();
        clearCookies();
        history.goBack();
        // }
        // todo
        // detect history go back
    };

    useEffect(() => {
        window.history.pushState(null, "", history.location.href);
        window.onpopstate = _backConfirm;
        // eslint-disable-next-line
    }, [history])


    useEffect(() => {
        return () => {
            if (headerToken()) {
                sessionStorage.clear();
                clearCookies()
            }
        }
    }, [history])

    let handleClearPhoto = (event, item, index) => {
        event.preventDefault();
        let filterPhotos = artistPhotos.photos.value.filter(i => i.id !== item.id);
        let filterFiles = artistPhotos.photos.files;
        filterFiles.splice(index, 1);
        let photosConcat = {files: [...filterFiles], value: [...filterPhotos]}
        if (!headerToken() && !item.local) {
            API(headerToken()).delete(`/user/profile/edit?id=${item.id}`)
                .then(() => {
                    changeContinuePopup('Photo is deleted')
                    setTimeout(() => {
                        changeContinuePopup('Photo is deleted')
                    }, 1000)
                })
            changePhotos(photosConcat)
        } else if (item.local) {
            changePhotos(photosConcat)
        }
    }

    return (
        <div className={'registration_wrapper'}>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <div className={'registration_wrapper_head'}>
                <div className={'registration_wrapper_head_container'}>
                    <h1>Upload Info about Yourself</h1>
                    {loading ?
                        <div className={`registration_wrapper_head_block ${role !== 'artist' ?
                            'registration_wrapper_head_block_investor' : ''}`}>
                            <div
                                onClick={!headerToken() ?
                                    () => {
                                        setRegistrationData(state => ({
                                            ...state,
                                            activeStep: 1,
                                        }))
                                    } : null}
                                style={!headerToken() ? {cursor: 'pointer'} : null}
                                className={`head_container_circle ${registrationData.activeStep === 1 ? 'head_container_circle_active' : ''}`}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" id="noun_contract_1515721"
                                         width="20.292"
                                         height="18.601"
                                         viewBox="0 0 20.292 18.601">
                                        <path id="Path_4581"
                                              d="M43.755 28H31.073a2.536 2.536 0 0 0-2.536 2.536v10.992h-1.692a.845.845 0 0 0-.845.845v1.691a2.536 2.536 0 0 0 2.536 2.536h11.837a2.536 2.536 0 0 0 2.536-2.536V33.918h2.536a.845.845 0 0 0 .845-.845v-2.537A2.536 2.536 0 0 0 43.755 28zM27.691 44.064v-.845h10.146v.845a2.46 2.46 0 0 0 .156.845h-9.457a.845.845 0 0 1-.845-.845zm13.528 0a.845.845 0 1 1-1.691 0v-1.691a.845.845 0 0 0-.845-.845h-8.456V30.536a.845.845 0 0 1 .845-.845h10.3a2.46 2.46 0 0 0-.156.845zM44.6 32.227h-1.691v-1.691a.845.845 0 1 1 1.691 0z"
                                              fill="#cccbcb" transform="translate(-26 -28)"/>
                                        <path id="Path_4582"
                                              d="M46.764 36h-5.919a.845.845 0 0 0 0 1.691h5.918a.845.845 0 0 0 0-1.691z"
                                              fill="#cccbcb"
                                              transform="translate(-34.082 -32.618)"/>
                                        <path id="Path_4583"
                                              d="M46.764 44h-5.919a.845.845 0 1 0 0 1.691h5.918a.845.845 0 0 0 0-1.691z"
                                              fill="#cccbcb"
                                              transform="translate(-34.082 -37.236)"/>
                                        <path id="Path_4584"
                                              d="M46.764 52h-5.919a.845.845 0 0 0 0 1.691h5.918a.845.845 0 0 0 0-1.691z"
                                              fill="#cccbcb"
                                              transform="translate(-34.082 -41.854)"/>
                                    </svg>
                                </div>
                                <span>Main Info</span>
                            </div>
                            {role === 'artist' ?
                                <>
                                    <div className={'head_container_lines'}>
                                        <div/>
                                        {innerWidth >= 600 ?
                                            <>
                                                <div/>
                                                <div/>
                                            </>
                                            : null}
                                        <div/>
                                    </div>
                                    <div
                                        onClick={!headerToken() ?
                                            () => {
                                                setRegistrationData(state => ({
                                                    ...state,
                                                    activeStep: 2,
                                                }))
                                            } : null}
                                        style={!headerToken() ? {cursor: 'pointer'} : null}
                                        className={`head_container_circle ${registrationData.activeStep === 2 ? 'head_container_circle_active' : ''}`}>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" id="noun_take_photo_1515248"
                                                 width="20.291"
                                                 height="20.291"
                                                 viewBox="0 0 20.291 20.291">
                                                <path id="Path_4569"
                                                      d="M29.382 43.755h7.609a.845.845 0 0 0 0-1.691h-7.609a1.691 1.691 0 0 1-1.691-1.691v-7.609a1.691 1.691 0 0 1 1.691-1.691h1.691a.845.845 0 0 0 .757-.469l1.222-2.443a.845.845 0 0 1 .757-.469h2.985a.845.845 0 0 1 .757.469l1.22 2.439a.845.845 0 0 0 .757.469h1.691a1.691 1.691 0 0 1 1.691 1.691v4.227a.845.845 0 0 0 1.691 0v-4.223a3.382 3.382 0 0 0-3.382-3.382h-1.167l-.994-1.982a2.536 2.536 0 0 0-2.266-1.4h-2.984a2.536 2.536 0 0 0-2.266 1.4l-.993 1.978h-1.167A3.382 3.382 0 0 0 26 32.764v7.609a3.382 3.382 0 0 0 3.382 3.382z"
                                                      fill="#cccbcb" transform="translate(-26 -26)"/>
                                                <path id="Path_4570"
                                                      d="M42.227 40a4.227 4.227 0 1 0 4.227 4.227A4.227 4.227 0 0 0 42.227 40zm0 6.764a2.536 2.536 0 1 1 2.536-2.536 2.536 2.536 0 0 1-2.536 2.536z"
                                                      fill="#cccbcb" transform="translate(-32.927 -34.082)"/>
                                                <path id="Path_4571"
                                                      d="M63.918 60.536h-1.691v-1.691a.845.845 0 0 0-1.691 0v1.691h-1.691a.845.845 0 0 0 0 1.691h1.691v1.691a.845.845 0 1 0 1.691 0v-1.691h1.691a.845.845 0 1 0 0-1.691z"
                                                      fill="#cccbcb" transform="translate(-44.472 -44.472)"/>
                                            </svg>
                                        </div>
                                        <span>Photo & Video</span>
                                    </div>
                                </>
                                : null}
                            <div className={'head_container_lines'}>
                                <div/>
                                {innerWidth >= 600 ?
                                    <>
                                        <div/>
                                        <div/>
                                        <div/>
                                    </>
                                    : null}
                                <div/>
                            </div>
                            <div
                                onClick={!headerToken() ?
                                    () => {
                                        setRegistrationData(state => ({
                                            ...state,
                                            activeStep: 3,
                                        }))
                                    } : null}
                                style={!headerToken() ? {cursor: 'pointer'} : null}
                                className={`head_container_circle ${registrationData.activeStep === 3 ? 'head_container_circle_active' : ''}`}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" id="noun_Mail_1515245" width="20.292"
                                         height="20.291"
                                         viewBox="0 0 20.292 20.291">
                                        <path id="Path_4572"
                                              d="M27.691 46.291H44.6a1.691 1.691 0 0 0 1.691-1.691V34.455a1.691 1.691 0 0 0-1.691-1.691v-1.691a1.691 1.691 0 0 0-1.691-1.691h-.038l.038-1.666A1.691 1.691 0 0 0 41.218 26H31.073a1.691 1.691 0 0 0-1.691 1.691v1.691a1.691 1.691 0 0 0-1.691 1.691v1.691A1.691 1.691 0 0 0 26 34.455V44.6a1.691 1.691 0 0 0 1.691 1.691zm3.382-18.6h10.145l-.025 1.64h-10.12zm-1.691 3.382h13.527v1.691H29.382zm-1.691 3.382H44.6V44.6H27.691z"
                                              fill="#cccbcb" transform="translate(-26 -26)"/>
                                        <path id="Path_4573"
                                              d="M39.073 50h-4.228a.845.845 0 0 0-.845.845v4.227a.845.845 0 0 0 .845.845h4.227a.845.845 0 0 0 .845-.845v-4.227a.845.845 0 0 0-.844-.845zm-.845 4.227h-2.537v-2.536h2.536z"
                                              fill="#cccbcb" transform="translate(-30.618 -39.854)"/>
                                        <path id="Path_4574"
                                              d="M57.073 50h-4.228a.845.845 0 0 0 0 1.691h4.227a.845.845 0 0 0 0-1.691z"
                                              fill="#cccbcb"
                                              transform="translate(-41.009 -39.854)"/>
                                        <path id="Path_4575"
                                              d="M57.073 58h-4.228a.845.845 0 0 0 0 1.691h4.227a.845.845 0 0 0 0-1.691z"
                                              fill="#cccbcb"
                                              transform="translate(-41.009 -44.472)"/>
                                    </svg>

                                </div>
                                <span>Categories</span>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            {loading ?
                <div className={'registration_wrapper_body'}>
                    {registrationData.activeStep === 1 ?
                        <MainInfo registrationData={registrationData}
                                  uploadCV={uploadCV}
                                  cv={cv}
                                  setCv={e => setCv(e)}
                                  role={role}
                                  uploadIcon={uploadIcon}
                                  setRegistrationData={val => setRegistrationData(val)}/>
                        : null}
                    {registrationData.activeStep === 2 ?
                        <InfoPhotoVideo registrationData={registrationData}
                                        uploadPhotos={uploadPhotos}
                                        videoLoading={videoLoading}
                                        uploadIcon={uploadIcon}
                                        headerToken={headerToken}
                                        handleClearPhoto={handleClearPhoto}
                                        artistVideo={artistVideo}
                                        changeCurrentPhoto={changeCurrentPhoto}
                                        setChangeCurrentPhoto={(e) => setChangeCurrentPhoto(e)}
                                        artistPhotos={artistPhotos}
                                        uploadVideo={uploadVideo}
                                        setRegistrationData={val => setRegistrationData(val)}/>
                        : null}
                    {registrationData.activeStep === 3 ?
                        <InfoCategories registrationData={registrationData}
                                        setRegistrationData={val => setRegistrationData(val)}/>
                        : null}
                </div>
                : null}
            <div className="wrapper_navigation_btn">
                {headerToken() ?
                    <>
                        {registrationData.activeStep !== 1 ?
                            <MainButton text={'Back'}
                                        className={'button_bordered'}
                                        style={{width: 'max-content'}}
                                        onClick={() => {
                                            if (role === 'artist') {
                                                setRegistrationData(state => ({
                                                    ...state,
                                                    activeStep: state.activeStep - 1,
                                                }))
                                            } else {
                                                setRegistrationData(state => ({
                                                    ...state,
                                                    activeStep: 1,
                                                }))
                                            }
                                        }}/>
                            : null}
                        <MainButton text={registrationData.activeStep !== 3 ? 'Next' : 'Confirm'}
                                    type={'button'}
                                    onClick={registrationData.activeStep === 1 ?
                                        () => handleErrorMainInfo() :
                                        registrationData.activeStep === 3 ?
                                            () => handleErrorCategories() :
                                            () => setRegistrationData(state => ({
                                                ...state,
                                                activeStep: state.activeStep + 1,
                                            }))
                                    }
                                    style={{width: 'max-content'}}/>
                    </>
                    : [
                        <Link to={(role === 'investor' || role === 'organization') ? '/profile/about' : '/profile/portfolio'}
                              key={1}
                              className={'button_bordered'}
                              style={{width: 'max-content'}}>
                            {role === 'investor' ? 'Profile' : 'Portfolio'}
                        </Link>,
                        <MainButton text={"Save"}
                                    key={2}
                                    onClick={() => handleErrorMainInfo()}
                                    type={'submit'}
                                    style={{width: 'max-content'}}/>
                    ]
                }
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
};

const putDispatchToProps = {
    setUserData
}
export default connect(mapStateToProps, putDispatchToProps)(PersonalInfo);
