import React, {useEffect, useState} from 'react'
import search_icon from "../../assets/img/admin/search_button.svg";
import ModalWindow from "../../Components/ModalWindow";
import close_modal from "../../assets/img/admin/close_modal.svg";
import API from "../../api/API";
import Keys from "../../Constants/helper";
import {Link} from "react-router-dom";
import SwitchStatus from "./SwitchStatus";
import {getActivePage} from "../../Constants/getActivePage";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import NewPaginationView from "../../Components/NewPaginationView";

export default function UsersComponent({pageTitle, requestData}) {
    const {activeHashUrl} = useGetLastChartHash()
    const [activeSearch, setActiveSearch] = useState({
        value: ''
    })
    const [verifiedUser, setVerifiedUser] = useState(() => {
        if (requestData !== 'investor') {
            return 'unverified'
        } else {
            return 'unblocked'
        }
    })
    const [banReason, setBanReason] = useState('')
    const [activeModal, setActiveModal] = useState({
        value: null,
        type: null,
        reason: null,
    })
    const [activeUser, setActiveUser] = useState(null)
    const [activeData, setActiveData] = useState(null)
    const [activePage, setActivePage] = useState(() => ({
        page: null,
        pages: null,
        total: null,
        loading: null
    }));

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl,
            }))
        }
    }, [activeHashUrl])

    const getFilters = search => {
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/users?role=${requestData}&name=${search ?? activeSearch.value.length > 1 ? activeSearch.value.toLowerCase() : ''}&category=${verifiedUser}&page=${activePage.page}&per_page=10`)
            .then((res) => {
                // console.log(res);
                if (res.data) {
                    setActiveData(res.data.users)
                    setActivePage(prevState => ({
                        ...prevState,
                        pages: res.data.pages,
                        total: res.data.total,
                        loading: true
                    }))
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (activeSearch.value.length >= 2) {
            getFilters()
        } else if (activeSearch.value.length) {
            getFilters('')
        }

        // eslint-disable-next-line
    }, [activeSearch.value])

    useEffect(() => {
        if (activePage.page) {
            getFilters()
        }
        // eslint-disable-next-line
    }, [activePage.page, requestData, verifiedUser])

    // useEffect(() => {
    //     if (loading) {
    //         let filterData = tempArray.filter(el => el.name.toLowerCase().includes(activeSearch.value.toLowerCase()))
    //         setActiveData(filterData)
    //     }
    // }, [activeSearch.value, loading])

    const handleUserRequest = () => {
        API(Keys.JWT_TOKEN_ADMIN).post(`/admin/user?id=${activeModal.value}&act=${activeModal.type}&reason=${activeModal.type === 'block' ? banReason : null}`)
            .then(() => {
                // console.log(res)
                setActiveData(prevState => prevState.filter(el => el.id !== activeModal.value))
                setBanReason('')
                setActiveModal({
                    value: null,
                    type: null,
                    reason: null,
                })
            })
    }

    const downloadTableHandler = () => {
        const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
        window.open(process.env.REACT_APP_API_URL + `/admin/users_to_excel?role=${requestData}&jwt=` + token)
    }

    return [
        <div className={'admin_content_wrapper'} key={1}>
            <div className={'admin_content_wrapper_users'}>
                <h1>{pageTitle}</h1>
                <div className={'verified_container'}>
                    <div className={'search-and-download-button-container'}>
                        <label>
                            <input type="text"
                                   placeholder={'Search '}
                                   value={activeSearch.value}
                                   onChange={({target}) => {
                                       setActiveSearch(prevState => ({
                                           ...prevState,
                                           value: target.value
                                       }))
                                   }}
                            />
                            <img src={search_icon} alt="search icon"/>
                        </label>
                        <button onClick={downloadTableHandler}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2438 16.496C15.4338 16.4961 15.6167 16.5683 15.7556 16.698C15.8944 16.8277 15.9788 17.0053 15.9918 17.1949C16.0047 17.3845 15.9453 17.5719 15.8253 17.7193C15.7054 17.8668 15.534 17.9631 15.3458 17.989L15.2438 17.996H4.74977C4.55975 17.996 4.37683 17.9238 4.23798 17.7941C4.09913 17.6643 4.0147 17.4867 4.00175 17.2972C3.98879 17.1076 4.04829 16.9202 4.1682 16.7727C4.28812 16.6253 4.45952 16.5289 4.64777 16.503L4.74977 16.496H15.2438ZM10.0038 1.99905C10.1848 1.9993 10.3597 2.06504 10.4961 2.18414C10.6325 2.30324 10.7211 2.46766 10.7458 2.64705L10.7528 2.74905V12.942L13.7188 9.97205C13.8456 9.84488 14.0139 9.76747 14.193 9.75389C14.3721 9.74031 14.5502 9.79146 14.6948 9.89805L14.7798 9.97005C14.9071 10.0971 14.9844 10.2656 14.9978 10.445C15.0112 10.6243 14.9598 10.8025 14.8528 10.947L14.7808 11.031L10.5378 15.281L10.4678 15.344L10.3758 15.403L10.3398 15.424L10.2488 15.462L10.1288 15.492L10.0588 15.5L9.99877 15.502C9.94802 15.502 9.89741 15.4966 9.84777 15.486L9.76777 15.463C9.67272 15.4325 9.58493 15.3828 9.50977 15.317L5.21977 11.032C5.08523 10.8984 5.00632 10.7187 4.99893 10.5292C4.99153 10.3397 5.05619 10.1545 5.1799 10.0107C5.30361 9.86699 5.47718 9.77545 5.66567 9.75455C5.85416 9.73364 6.04357 9.78491 6.19577 9.89805L6.27977 9.97105L9.25277 12.938V2.74805C9.25277 2.54913 9.33179 2.35837 9.47244 2.21772C9.61309 2.07706 9.80386 1.99805 10.0028 1.99805L10.0038 1.99905Z" fill="black"/>
                            </svg>
                            Download Table
                        </button>
                    </div>
                    <div className={'verified_content'}>
                        <SwitchStatus switchStatus={'blocked'}
                                      setActivePage={e => setActivePage(e)}
                                      setVerifiedUser={e => setVerifiedUser(e)}
                                      verifiedUser={verifiedUser}/>
                        {requestData !== 'investor' ? [
                                <SwitchStatus switchStatus={'verified'}
                                              key={0}
                                              setActivePage={e => setActivePage(e)}
                                              setVerifiedUser={e => setVerifiedUser(e)}
                                              verifiedUser={verifiedUser}/>,
                                <SwitchStatus switchStatus={'unverified'}
                                              key={1}
                                              setActivePage={e => setActivePage(e)}
                                              setVerifiedUser={e => setVerifiedUser(e)}
                                              verifiedUser={verifiedUser}/>
                            ]
                            : <SwitchStatus switchStatus={'unblocked'}
                                            setActivePage={e => setActivePage(e)}
                                            setVerifiedUser={e => setVerifiedUser(e)}
                                            verifiedUser={verifiedUser}/>}
                    </div>
                </div>
                <div className={'title'}>
                    <h4>Registration</h4>
                    <h4>Name</h4>
                </div>
            </div>
            {activePage.loading ? activeData.length
                ? <div style={!activeData.length ? {padding: '1rem 0'} : null}
                       className={'admin_content_wrapper_users_list'}>
                    {activeData?.map(item => (
                        <div className={'item'} key={item.id}>
                            <div className={'item_content'}>
                                <span>{item.registration_time}</span>
                                <span>{item.name}</span>
                            </div>
                            <div
                                style={verifiedUser === 'unverified'
                                    ? {gridTemplateColumns: 'repeat(3, max-content)'}
                                    : {gridTemplateColumns: 'repeat(2, max-content)'}}
                                className={'users_list_buttons'}>
                                {verifiedUser === 'unverified'
                                    ? <>
                                        <button className={'admin_bordered_button'}
                                                onClick={() => {
                                                    setActiveModal({
                                                        value: activeModal.value !== item.id ? item.id : null,
                                                        type: 'block',
                                                        reason: null,
                                                    })
                                                    setActiveUser(item.name)
                                                }}>Block
                                        </button>
                                        <button onClick={() => {
                                            setActiveModal({
                                                value: activeModal.value !== item.id ? item.id : null,
                                                type: 'verify',
                                                reason: null
                                            })
                                            setActiveUser(item.name)
                                        }}>Verify
                                        </button>
                                        <Link to={`/admin/${pageTitle.toLowerCase()}/${item.id}`}>
                                            Details
                                        </Link>
                                    </> : verifiedUser === 'verified' ? <>
                                        <button className={'admin_bordered_button'}
                                                onClick={() => {
                                                    setActiveModal({
                                                        value: activeModal.value !== item.id ? item.id : null,
                                                        type: 'block',
                                                        reason: null
                                                    })
                                                    setActiveUser(item.name)
                                                }}>Block
                                        </button>
                                        <Link to={`/admin/${pageTitle.toLowerCase()}/${item.id}`}>
                                            Details
                                        </Link>
                                    </> : verifiedUser === 'blocked' ? <>
                                        <button className={'admin_bordered_button'}
                                                onClick={() => {
                                                    setActiveModal({
                                                        value: activeModal.value !== item.id ? item.id : null,
                                                        type: 'unblock',
                                                        reason: item.block_reason
                                                    })
                                                    setActiveUser(item.name)
                                                }}>Unblock
                                        </button>
                                        <Link to={`/admin/${pageTitle.toLowerCase()}/${item.id}`}>
                                            Details
                                        </Link>
                                    </> : <>
                                        <button className={'admin_bordered_button'}
                                                onClick={() => {
                                                    setActiveModal({
                                                        value: activeModal.value !== item.id ? item.id : null,
                                                        type: 'block',
                                                        reason: item.block_reason
                                                    })
                                                    setActiveUser(item.name)
                                                }}>Block
                                        </button>
                                        <Link to={`/admin/${pageTitle.toLowerCase()}/${item.id}`}>
                                            Details
                                        </Link>
                                    </>}
                            </div>
                        </div>
                    ))}
                </div>
                : <span className={'admin_users_list_no_items no_items_style'}>No items</span> : null}
        </div>,
        activePage.loading && activePage.total > 9 ?
            <div className={'wrapper_new_pagination admin_pagination'} key={2}>
                <NewPaginationView activePage={activePage}
                                   onChange={pageNumber => {
                                       getActivePage(pageNumber)
                                       setActivePage(prevState => ({
                                           ...prevState,
                                           page: pageNumber
                                       }))
                                   }}/>
            </div>
            : null,
        activeModal.value ?
            <div className={'admin_wrapper_modal'} key={3}>
                <ModalWindow activeModal={activeModal}
                             nestedObj
                             setNullData={val => setActiveUser(val)}
                             setActiveModal={val => setActiveModal(val)}>
                    <img onClick={() => {
                        setActiveUser(null)
                        setActiveModal({
                            value: null,
                            type: null,
                            reason: null,
                        })
                    }} src={close_modal} alt="close modal"/>
                    <h4>{activeUser ?? 'Username'}</h4>
                    <div className={'admin_wrapper_modal_line'}/>
                    <p style={activeModal.type === 'verify' && requestData !== 'investor'
                        ? {margin: '0'}
                        : {margin: '0 0 1rem'}}>
                        {activeModal.type === 'block'
                            ? 'Are you sure you want to block this user?'
                            : activeModal.type === 'verify' && requestData !== 'investor'
                                ? 'Are you sure you want to verify this user?'
                                : 'Are you sure you want to unblock this user?'}</p>
                    {activeModal.type === 'block'
                        ? <textarea value={banReason}
                                    rows={'3'}
                                    onChange={({target}) => setBanReason(target.value)}
                                    placeholder={'The reason for blocking'}/>
                        : activeModal.type === 'unblocked'
                            ? activeModal.reason
                                ? <div className={'admin_wrapper_modal_reason'}>
                                    <h6>Reason:</h6>
                                    <span>{activeModal.reason}</span>
                                </div> : null : null}
                    <button onClick={() => handleUserRequest()}>{activeModal.type === 'block'
                        ? 'Block'
                        : activeModal.type === 'verify'
                            ? 'Verify'
                            : 'Unblock'}</button>
                </ModalWindow>
            </div> : null
    ]
}
