import React from 'react'

const TermsRetention = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>8. Retention of Title and Trademark Rights</h3>
            <div className={'terms_of_service_item_container'}>
                <span>8.1</span>
                <span>
                    The goods remain the property of the Website operator, until the due payment is received in
                    full and on time.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>8.2</span>
                <span>
                    Tools or auxiliary means manufactured for the creation of custom products on behalf of the
                    customer remain the property of the Website operator and are not provided to the customer. This
                    also applies if these materials are separately charged for. The customer warrants and ensures
                    that the implementation of her order and the distribution of goods in her name (trademark) will
                    not infringe upon any copyright and any protective or proprietary or other rights of any third
                    party or parties. The customer indemnifies the Website operator from all possible third party
                    claims resulting from the infringement of their rights.
                </span>
            </div>
        </div>
    )
}

export default TermsRetention