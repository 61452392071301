import React from 'react';
import SEO from "../../../Components/SEO";

export default function ArtistHandbook() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Why Sell: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Why Sell: Buy art online Artsted"/>
            <h1>Artist Handbook</h1>

            <p>Free art advisory services at Artsted are managed by a team of Art History and Art Business graduates
                whose mission is to promote and kickstart the careers of international emerging artists. Our advisors
                will provide tailor-made solutions to suit your specific requests, existing collection, taste
                preferences, available space and budget.</p>
        </div>
    )
}