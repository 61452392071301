import React, {useRef, useState, useEffect} from "react";
import MainButton from "../CustomComponents/MainButton";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../CustomComponents/ChangedConfirmedPopup";
import {ResizeTextBox} from "../ResizeTextBox";
import ModalWindow from "../ModalWindow";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import API from "../../api/API";
import {useLocation} from "react-router-dom";

const InboxUniqueForm = props => {
    const {
        handleSubmitMessage,
        avatar,
        border,
        role,
        order_status,
        isCompleted,
    } = props;

    let formRef = useRef()
    const [messageBox, setMessageBox] = useState({value: ''});
    const [receivePopup, setReceivePopup] = useState({show: false, continue: false});
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    let location = useLocation();
    const [nftCategory, setNftCategory] = useState()

    useEffect(() => {
        let url = location.pathname.split('/');
        API().get(`/user/profile/inbox/header?id=${url[3]}`)
            .then(res => {
                // console.log(res)
                if (res.data) {
                    setNftCategory(res.data)
                }
            })
    // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (isCompleted) {
            changeContinuePopup('Artwork marked as received successfully!')
            setReceivePopup({
                show: false,
                continue: true
            })
            setTimeout(() => {
                changeContinuePopup('Artwork marked as received successfully!')
            }, 2000)
        }
        // eslint-disable-next-line
    }, [isCompleted])

    return (
        <form className={'platform_chat_container_footer every_inbox_message'}
              style={border ? {borderTop: 'solid 1px #dbddde'} : null}
              ref={formRef}
              onSubmit={e => {
                  e.preventDefault();
                  if (messageBox.value.trim()) {
                      handleSubmitMessage(e, messageBox.value, 'text')
                      setMessageBox({value: ''})
                  }
              }}>
            {messageBox.value.length >= 999 ?
                <span className={'maximum_amount'}>You have reached the maximum amount of text</span> : null}
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <div className="avatar_wrapper">
                {avatar ?
                    <img src={avatar} alt="avatar"/>
                    : null}
            </div>
            <ResizeTextBox value={messageBox.value}
                           onChange={({target}) => {
                               setMessageBox({value: target.value})
                           }}
                           placeholder={'Click to reply or forward'}
                           borderBottomInput/>
            <div className="wrapper_btn">
                <MainButton type={'submit'}
                            className={'button_black'}
                            text={'Send'}/>
                {/*<button onClick={testFunc}>AAAAAA</button>*/}
                {nftCategory?.artwork?.category !== 'NFT' && role === 'investor' && order_status === 'processed' && !isCompleted ?
                    <MainButton type={'button'}
                                onClick={() => {
                                    setReceivePopup(prevState => ({
                                        ...prevState,
                                        show: true
                                    }))
                                }}
                                style={{background: '#000', color: '#fff'}}
                                className={'button_black'}
                                text={'Mark as received'}/>
                    : null}
            </div>

            {receivePopup.show ?
                <div key={3} className={'new_modal_wrapper'}>
                    <ModalWindow setActiveModal={() => {
                        setReceivePopup(prevState => ({
                            ...prevState,
                            show: false
                        }))
                    }}>
                        <div className={'title'}>
                            <h4>Are you sure you would like to mark the artwork as received?</h4>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 onClick={() => {
                                     setReceivePopup(prevState => ({
                                         ...prevState,
                                         show: false
                                     }))
                                 }}
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" fill="#F6F6F1"/>
                                <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                            </svg>
                        </div>
                        <p>This action will release the payout to Artist.</p>
                        <div className={'modal-window_buttons'}>
                            <MainButton type={'button'}
                                        style={{background: '#000', color: '#fff'}}
                                        className={'button_black'}
                                        text={'Cancel'}
                                        onClick={() => {
                                            setReceivePopup(prevState => ({
                                                ...prevState,
                                                show: false
                                            }))
                                            bodyOverflow(false)
                                        }}
                            />
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Receive'}
                                        onClick={() => {
                                            handleSubmitMessage(null, messageBox.value, 'completed')
                                            setReceivePopup({
                                                show: false,
                                                continue: true
                                            })
                                        }}
                            />
                        </div>
                    </ModalWindow>
                </div>
                : null}
        </form>
    )
}
export default InboxUniqueForm