import React from 'react';
import mastercard_icon from "../assets/img/mastercard_icon.svg";
import StripeCard from "./StripeCard";
import API from "../api/API";
import {useStripe} from "@stripe/react-stripe-js";

const PaymentMethod = ({profile_data, no_fill}) => {
    const stripe = useStripe()

    console.log(stripe)
    let stripeRedirectPayers = () => {
        API().get(`/user/payment/change_card`)
            .then(({data}) => {
                console.log(data)
                stripe.redirectToCheckout({sessionId: data.session_id})
            })
            .catch(e => console.log(e))
    }

    return (
        <div className={'bank_cards'}>
            {profile_data?.card_brand && profile_data?.card_last4 ?
                <div className={'gap'}>
                    <h6>Added card:</h6>
                    <div className={'card_placeholder'}>
                        <div className={'numbers'}>
                            {profile_data.card_brand === 'visa' ?
                                <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.9552 9.53516C17.9552 9.53516 18.2455 10.9883 18.3104 11.293H17.0347C17.1608 10.9453 17.6458 9.59375 17.6458 9.59375C17.6382 9.60547 17.7719 9.23828 17.8483 9.01172L17.9552 9.53516ZM22 3.625V17.375C22 18.4102 21.1788 19.25 20.1667 19.25H1.83333C0.821181 19.25 0 18.4102 0 17.375V3.625C0 2.58984 0.821181 1.75 1.83333 1.75H20.1667C21.1788 1.75 22 2.58984 22 3.625ZM5.82465 13.4375L8.23854 7.375H6.61528L5.11424 11.5156L4.95 10.6758L4.41528 7.88672C4.32743 7.5 4.05625 7.39062 3.72014 7.375H1.24896L1.22222 7.49609C1.82569 7.65234 2.36424 7.87891 2.83403 8.16406L4.20139 13.4375H5.82465ZM9.43021 13.4453L10.3927 7.375H8.85729L7.89861 13.4453H9.43021ZM14.7736 11.4609C14.7812 10.7695 14.3687 10.2422 13.4865 9.80859C12.9479 9.53125 12.6194 9.34375 12.6194 9.05859C12.6271 8.80078 12.8983 8.53516 13.5017 8.53516C14.0021 8.52344 14.3687 8.64453 14.6437 8.76562L14.7812 8.83203L14.9913 7.51953C14.6896 7.39844 14.2083 7.26172 13.6163 7.26172C12.1 7.26172 11.0344 8.08984 11.0267 9.26953C11.0153 10.1406 11.7906 10.625 12.3712 10.918C12.9632 11.2148 13.1656 11.4102 13.1656 11.6719C13.158 12.0781 12.6844 12.2656 12.2451 12.2656C11.634 12.2656 11.3056 12.168 10.8052 11.9414L10.6028 11.8438L10.3889 13.207C10.7479 13.375 11.4125 13.5234 12.1 13.5312C13.7118 13.5352 14.7622 12.7188 14.7736 11.4609ZM20.1667 13.4453L18.9292 7.375H17.7413C17.3747 7.375 17.0958 7.48437 16.9392 7.87891L14.659 13.4453H16.2708C16.2708 13.4453 16.5344 12.6953 16.5917 12.5352H18.5625C18.6083 12.75 18.7458 13.4453 18.7458 13.4453H20.1667Z"
                                        fill="black"/>
                                </svg>
                                : <img src={mastercard_icon} alt="mastercard_icon"/>}
                            <span>•••• {profile_data.card_last4}</span>
                        </div>
                    </div>
                </div> : null}

            <StripeCard condition={!profile_data?.card}
                        text={!profile_data?.card ? 'Add payment method' : 'Edit payment method'}
                        no_fill={no_fill}
                        onClick={stripeRedirectPayers}/>
        </div>
    );
};

export default PaymentMethod;
