import {Modal} from "antd";

import styles from './StyledModal.module.css';

export default function StyledModal({ children, ...props }) {
    return (
        <Modal width={700} {...props} centered className={styles.root}>
            {children}
        </Modal>
    )
}