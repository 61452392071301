import React, {useEffect, useState} from "react";
import '../Profile/styles/new_portfolio_artist.css'
import {useDispatch, useSelector} from "react-redux";
import MainButton from "../../Components/CustomComponents/MainButton";
import API from "../../api/API";
import {Loader} from "../../Components/Loader";
import NewPaginationView from "../../Components/NewPaginationView";
import {getActivePage} from "../../Constants/getActivePage";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import {Link, useHistory} from "react-router-dom";
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import ModalWindow from "../../Components/ModalWindow";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import NewUploadWork from "../../Components/ProfileComponents/NewUploadWork";
import {setActiveUpload} from "../../main-store/auth/actions";
import NewInvestorPortfolio from "./NewInvestorPortfolio";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import {Banner} from "../../Components/Banner";
import {InfoBanner} from "../../Components/InfoBanner";
//import close_modal from "../../assets/img/admin/close_modal.svg";

export default function NewPortfolio() {
    const {activeHashUrl} = useGetLastChartHash();

    const reduxState = useSelector(state => ({
        user_data: state.auth.user_data,
        user_subscription: state.auth.user_subscription,
        active_upload: state.auth.active_upload,
        can_create: state.auth.can_create
    }));

    // console.log('reduxState: ', reduxState)

    const [activePage, setActivePage] = useState(() => ({
        page: activeHashUrl ? activeHashUrl : 1,
        pages: null,
        total: null
    }));
    //const [deliveryAddress, setDeliveryAddress] = useState([])
    const history = useHistory();
    const uploadDispatch = useDispatch();
    const [userProfile, setUserProfile] = useState(null);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    // const [activeArtistTab, setActiveArtistTab] = useState('classic_artwork');
    const [loading, setLoading] = useState(false);
    const [showUploadWork, setShowUploadWork] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [uploadEnd, setUploadEnd] = useState(false);
    const [activeCardPopup, setActiveCardPopup] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [activePopup, setActivePopup] = useState(null);

    const handleDeleteArtWork = () => {
        API().delete(`/user/artwork?id=${activeModal}`)
            .then(() => {
                setUserProfile(prevState => ({
                    ...prevState,
                    artworks: prevState.artworks.filter(i => i.id !== activeModal)
                }))
                setActiveModal(null)
                bodyOverflow(false)
            })
    }

    useEffect(() => {
        setUploadEnd(false)
        return () => uploadDispatch(setActiveUpload(false))
    }, [uploadDispatch])

    useEffect(() => {
        if (reduxState.user_data?.role === 'artist' || reduxState.user_data?.role === 'organization') {
            API().get(`/user/profile/portfolio?order_by=classic_artwork&page=${activePage.page}&per_page=9`)
                .then(res => {
                    if (res.data) {
                        setUserProfile(res.data)
                        //setDeliveryAddress()
                        //console.log(deliveryAddress)
                        setActivePage(prevState => ({
                            ...prevState,
                            pages: res.data.pages,
                            total: res.data.total,
                        }))
                    }
                })
                .then(() => setLoading(true))
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [activePage.page, reduxState.user_data?.role, uploadEnd])

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl
            }))
        }
    }, [activeHashUrl])

    const settingsHistoryPush = () => history.push("/profile/settings#delivery_info")

    return [
        <ChangedConfirmedPopup successText={successText}
                               key={10}
                               changingConfirmed={changingConfirmed}/>,
        (reduxState?.user_data.role === 'artist' || reduxState?.user_data.role === 'organization')
            ? reduxState?.active_upload || showUploadWork ?
            <NewUploadWork setShowUploadWork={e => setShowUploadWork(e)}
                           editMode={editMode}
                           setEditMode={e => setEditMode(e)}
                           setUploadEnd={e => setUploadEnd(e)}
                           key={6}
                           user_subscription={reduxState.user_subscription}/>
            : <div key={7}
                   className={'main_profile_wrapper'}>
                <InfoBanner
                  title={'Sell more with Artsted Portfolio'}
                  subtitle={'No Upfront Costs, Low Commission, Global Reach, Instant Payment processing, and Powerful Marketing Tools. Start Selling today.'}
                  buttonText={'Upload work'}
                  onClick={() => {
                      if (reduxState.can_create) {
                          setEditMode(null)
                          setShowUploadWork(true)
                      } else {
                          setActivePopup(true)
                      }
                  }}
                />
                {[
                    <div key={50} className={'artist_profile_buttons'} style={{marginTop: 50}}>
                        <div className={'classic_digital_buttons'}>
                            <h2>Here are your artworks for sale</h2>
                            {/*<MainButton type={'button'}*/}
                            {/*            text={'Classic Artwork'}*/}
                            {/*            style={activeArtistTab === 'classic_artwork' ? {*/}
                            {/*                background: '#000',*/}
                            {/*                color: '#fff'*/}
                            {/*            } : null}*/}
                            {/*            className={'button_black'}*/}
                            {/*            onClick={() => setActiveArtistTab('classic_artwork')}*/}
                            {/*/>*/}
                            {/*<MainButton type={'button'}*/}
                            {/*            text={'Digital Artwork'}*/}
                            {/*            className={'button_black'}*/}
                            {/*            style={activeArtistTab === 'digital_artwork' ? {*/}
                            {/*                background: '#000',*/}
                            {/*                color: '#fff'*/}
                            {/*            } : null}*/}
                            {/*            onClick={() => setActiveArtistTab('digital_artwork')}*/}
                            {/*/>*/}
                        </div>
                    </div>,
                    activePopup ?
                        <div className={'popup_window_upload_work'} key={316}>
                            <ModalWindow activeModal={activePopup}
                                         key={2}
                                         setActiveModal={val => setActivePopup(val)}>
                                <div className={'portfolio_popup_header_container'}>
                                    <h4>You can’t upload artwork</h4>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        setActivePopup(null)
                                        bodyOverflow(false)
                                    }}>
                                        <path d="M1 1L17 17M1 17L17 1" stroke="black" strokeWidth="2"/>
                                    </svg>
                                </div>
                                <p>You have to fill in shipping address.</p>
                                <div className={'modal-window_buttons'}>
                                    <MainButton type={'button'}
                                                className={'button_bordered_close_popup'}
                                                style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                text={'Cancel'}
                                                onClick={() => {
                                                    setActivePopup(null)
                                                    bodyOverflow(false)
                                                }}
                                    />
                                    <MainButton type={'button'}
                                                style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                className={'button_bordered_fill_in'}
                                                text={'Fill in'}
                                                onClick={() => {
                                                    setActivePopup(null)
                                                    bodyOverflow(false)
                                                    settingsHistoryPush()
                                                }}
                                    />
                                </div>
                            </ModalWindow>
                        </div> : null,
                    <div className={'upload_works_wrapper'} key={112}
                         style={!loading ? {
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center'
                         } : !userProfile.artworks.length ? {gridTemplateColumns: '1fr'} : null}>
                        {loading ? userProfile.artworks.length
                            ? userProfile.artworks.map((el) => (
                                <Link to={`/public-artwork/${el.id}`}
                                      onClick={e => el.hidden ? e.preventDefault() : null}
                                      className={'works_card'} key={el.id}>
                                    {el.hidden
                                        ? <div className={'artwork_hidden'}><span>Your artwork is hidden!</span>
                                        </div> : null}
                                    {el.sold
                                        ? <div className={'artwork_sold'}><span>Sold</span></div> : null}
                                    <img src={el.picture} alt="preview"/>
                                    <div className={'card_content'}>
                                        <div className={'edit_delete_wrapper'}>
                                            <div>
                                                <h4>{capitalizeFirstLetter(el.title?.length >= 20 ? el.title.substring(0, 20) + '...' : el.title)}</h4>
                                                <p>Starting at €{el.price}</p>
                                            </div>
                                            <div onClick={e => e.preventDefault()} className={'popup_wrapper'}>
                                                <div
                                                    style={activeCardPopup === el.id ? {pointerEvents: 'none'} : null}
                                                    onClick={() => setActiveCardPopup(activeCardPopup !== el.id ? el.id : null)}
                                                    className={'svg_wrap'}>
                                                    <svg width="4" height="28" viewBox="0 0 4 28" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="4" height="4" fill="black"/>
                                                        <rect y="12" width="4" height="4" fill="black"/>
                                                        <rect y="24" width="4" height="4" fill="black"/>
                                                    </svg>
                                                </div>
                                                {activeCardPopup === el.id ?
                                                    <OutsideClickHandler callback={() => setActiveCardPopup(null)}>
                                                        <div className={'popup'}>
                                                                <span onClick={(e) => {
                                                                    if (el.sold) {
                                                                        e.preventDefault();
                                                                        changeContinuePopup(`You can't edit sold artwork`)
                                                                        setTimeout(() => {
                                                                            changeContinuePopup(`You can't edit sold artwork`)
                                                                        }, 2000)
                                                                    } else {
                                                                        setEditMode(el.id)
                                                                        setShowUploadWork(true)
                                                                        setActiveCardPopup(null)
                                                                    }
                                                                }}>Edit</span>
                                                            <span onClick={() => {
                                                                bodyOverflow(true)
                                                                setActiveModal(el.id)
                                                                setActiveCardPopup(null)
                                                            }}>Delete</span>
                                                        </div>
                                                    </OutsideClickHandler> : null}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )) : <span className={'no_items_style'}>No items</span>
                            : <Loader color={{
                                '--bgColor': '#000',
                                '--size': '48px',
                                '--wrapper_size': '40px'
                            }}/>}
                    </div>,
                    <div key={26}>
                        {activePage?.total > 9 ? loading ?
                            <div className={'wrapper_new_pagination'}>
                                <NewPaginationView activePage={activePage}
                                                   onChange={pageNumber => {
                                                       getActivePage(pageNumber)
                                                       setActivePage(prevState => ({
                                                           ...prevState,
                                                           page: pageNumber
                                                       }))
                                                   }}/>
                            </div>
                            : <Loader color={{
                                '--bgColor': '#000',
                                '--size': '48px',
                                '--wrapper_size': '40px'
                            }}/>
                            : null}
                    </div>,
                    activeModal ?
                        <div key={35} className={'new_modal_wrapper'}>
                            <ModalWindow activeModal={activeModal}
                                         setActiveModal={val => setActiveModal(val)}>
                                <h4>Delete this artwork?</h4>
                                <p>Are you sure you want to delete? This action can't be undone.</p>
                                <div className={'modal-window_buttons'}>
                                    <MainButton type={'button'}
                                                style={{background: '#000', color: '#fff'}}
                                                className={'button_black'}
                                                text={'Cancel'}
                                                onClick={() => {
                                                    setActiveModal(null)
                                                    bodyOverflow(false)
                                                }}
                                    />
                                    <MainButton type={'button'}
                                                className={'button_black'}
                                                text={'Delete'}
                                                onClick={handleDeleteArtWork}
                                    />
                                </div>
                            </ModalWindow>
                        </div> : null,
                ]}
            </div>
            : <NewInvestorPortfolio key={5}/>
    ]
}