import React from 'react'
import close_modal from "../assets/img/admin/close_modal.svg";

import './styles/header.css';
import Keys from "../Constants/helper";

const HeaderBanner = (props) => {

    function closeBanner() {
        props.setShowBanner(false)
        localStorage.setItem(Keys.TOP_BANNER_HIDE, "true")
    }

    return (
        <div className={"header_banner_container"}>
            <p>Art investment | Buy art online</p>
            <p className={"justify-self-center"}>Free shipping — Free returns — Insurance coverage</p>
            <img style={{marginBottom: "1em"}} onClick={() => {
                closeBanner()
            }} src={close_modal} alt="close modal"/>
        </div>
    )
}


export default HeaderBanner;
