import './styles/loader.css'

const Loader = ({color}) => (
    <div className="loader" style={color}>
        <div/>
        <div/>
        <div/>
        <div/>
    </div>
);

export {Loader}