import React, {useEffect, useState} from 'react';
import Wrapper from '../OnboardingWrapper';
import artwork_img from '../../../assets/img/onboarding/artwork1.png';
import classes from './styles/profile.module.scss';
import useBottomPopup from "../../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import {useSelector} from "react-redux";
import Button from "../../../Components/CustomComponents/Onboarding/Button";
import historyDetectBack from "../HistoryBackDetect";
import {useHistory} from "react-router";
import {Loader} from '../../../Components/Loader'
import API from "../../../api/API";
import Keys from "../../../Constants/helper";


function UploadProfileVideo() {
    const [video, setVideo] = useState(null);
    const [loading, setLoading] = useState(true)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const store = useSelector(state => state.auth)
    const history = useHistory();

    const _onSubmit = e => {
        e.preventDefault();
        let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
        history.push(nextHistory)
    }

    useEffect(() => {
        API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/profile/upload_video`)
            .then(res => {
                setVideo({preview: res.data?.avatar ?? null})
            })
    }, [])

    const uploadVideo = event => {
        event.preventDefault();
        setLoading(false)
        setTimeout(() => {
            let file = event.target.files[0];
            let formData = new FormData();
            formData.append('video', file)
            API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/upload_video`, formData)
                .then(res => {
                    // console.log(res);
                    changeContinuePopup(`You successfully uploaded the video`)
                    setTimeout(() => {
                        changeContinuePopup(`You successfully uploaded the video`)
                    }, 2000)
                    if (file) {
                        setLoading(true)
                        setVideo({
                            preview: URL.createObjectURL(file),
                            value: file
                        })
                    }
                })
        }, 10)
    }

    return (
        <Wrapper>
            <Wrapper.Picture src={artwork_img}/>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>Upload your profile Video</h1>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <div className={classes.avatar_wrapper}>
                            <div className={classes.video_preview}>
                                <label htmlFor="file">
                                    <input id={'file'} type='file' onChange={uploadVideo} hidden accept={"video/*"}/>
                                    {video?.preview ?
                                        <video>
                                            <source src={video?.preview + '#t=0.001'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                                        </video>
                                        : null}
                                </label>
                            </div>
                        </div>
                        <div className={classes.form_button}>
                            <div className={classes.upload_profile_video_button_container}>
                                <button>Skip</button>
                                <Button type="submit">
                                    {!loading ?
                                        <Loader color={{
                                            '--bgColor': '#000',
                                            '--size': '24px',
                                            '--wrapper_size': '20px'
                                        }}/> :
                                        <>Continue &#62;</>}</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
        </Wrapper>
    );
}

export default UploadProfileVideo;
