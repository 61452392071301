import clsx from "clsx";
import {Dropdown} from "antd";
import moment from "moment";
import {useReducer, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import API from "../../../api/API";
import {defaultBase64Image} from "../../../Constants/images";

import StyledModal from "../../ui/StyledModal";

import {DotsVertical} from "../../../assets/icons";

import styles from './BookingItem.module.css'

export default function BookingItem({inactive, onDelete, ...props}) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteOpen, toggleDeleteOpen] = useReducer(state => !state, false);
    const [isDeleteFromHistoryOpen, toggleDeleteFromHistoryOpen] = useReducer(state => !state, false);

    const items = inactive ? [
        {label: 'Book again', key: '0', onClick: () => history.push(`/public-experiences/stay/${props.listing_id}`) },
        {label: 'Remove from history', key: '1', onClick: toggleDeleteFromHistoryOpen},
    ] : [
        {label: 'Cancel booking', key: '0', onClick: toggleDeleteOpen},
        {label: 'Change dates', key: '1', onClick: handleOpenSettings('dates')},
        {label: 'Manage guests', key: '2', onClick: handleOpenSettings('guests')},
    ];

    const handleCancelBooking = () => {
        setIsLoading(true)
        API().delete(`/user/listing/book?id=${props.id}`)
            .then(() => onDelete(props.id))
            .finally(() => {
                setIsLoading(false);
                if (isDeleteOpen) {
                    toggleDeleteOpen();
                }
                if (isDeleteFromHistoryOpen) {
                    toggleDeleteFromHistoryOpen();
                }
            })
    }

    function handleOpenSettings(type) {
        return () => history.push(`/booking/${props.id}/${type}`)
    }

    return (
        <div className={styles.root}>
            <Link to={`/bookings/${props.id}`} className={clsx(styles.link, inactive && styles.inactive)}>
                <div className={styles.imageWrapper}>
                    <img
                        width="100%"
                        height="auto"
                        src={props?.listing_photos?.[0]?.path ? (process.env.REACT_APP_API_URL + '/' + props?.listing_photos?.[0]?.path) : defaultBase64Image}
                        alt=""
                    />
                </div>
                <div className={styles.content}>
                    <h3 className={styles.title}>{props?.listing_name}</h3>
                    <p className={styles.address}>{props?.listing_address}</p>
                    <p className={styles.contentText}>
                        {props?.date_from?.replace(' 00:00:00', '')} - {props?.date_to?.replace(' 00:00:00', '')} ({moment(props?.date_to).diff(moment(props?.date_from), 'days')} nights)
                    </p>
                </div>
                <div className={styles.action}>
                    <div className={styles.price}>€{props?.price}</div>
                </div>
            </Link>
            <StyledModal
                okText="Yes, cancel"
                cancelText="No"
                title="Are you sure you want to cancel this booking?"
                open={isDeleteOpen}
                onCancel={toggleDeleteOpen}
                onOk={handleCancelBooking}
                okButtonProps={{loading: isLoading}}
            >
                Based on the cancellation policy, you will be refunded within 3 days.
            </StyledModal>
            <StyledModal
                okText="Yes, remove"
                cancelText="No"
                title="Are you sure you want to remove this booking from history?"
                open={isDeleteFromHistoryOpen}
                onCancel={toggleDeleteFromHistoryOpen}
                onOk={handleCancelBooking}
                okButtonProps={{loading: isLoading}}
            />
            <Dropdown overlayClassName={styles.menu} menu={{items}} trigger={['click']} placement="bottomRight">
                <button className={styles.actionButton}>
                    <DotsVertical width={24} height={48}/>
                </button>
            </Dropdown>
        </div>
    )
}