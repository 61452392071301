import React from 'react';
import SEO from "../../Components/SEO";

export default function Taxation() {
    return (
        <div className={'faq_section_body taxation_padding'}>
            <SEO title="Taxation: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Taxation: Buy art online Artsted"/>
            <h1>Taxation</h1>

            <p>Artsted is not liable for taxation procedures of any items either sold or purchased on the platform, the
                export tax whenever necessary in the outbound country is paid by the Artist, and the import tax whenever
                applicable is to be paid by the Buyer.</p>

               <p> For internal compliance purposes Artsted provides two tax forms for Artists: for non-US and US-based
                artists.</p>
            <p>The Artist will be required to fill out these forms after their First Sale with Artsted takes place.</p>
        </div>
    )
}