import React, {useEffect, useMemo, useState} from 'react';
import useBottomPopup from '../Constants/useBottomPopup';
import { Link, useLocation } from 'react-router-dom';
import useResize from '../Constants/useResize';
import ViewAnalytic from '../Constants/viewAnalytic';
import API from '../api/API';
import './styles/new_artwork_public.css';
import delivery_insured from '../assets/img/public_artwork/delivery_insured.svg';
import withdrawal_period from '../assets/img/public_artwork/withdrawal_period.svg';
import pay_safely from '../assets/img/public_artwork/pay_safely.svg';
import reliability_traceability from '../assets/img/public_artwork/reliability_traceability.svg';
import {
  setRecentView,
  setRecentViewArray,
} from '../main-store/recent_viewed/actions';
import { setCartCount, setNewHeaderBucket } from '../main-store/auth/actions';
import {connect, useDispatch, useSelector} from 'react-redux';
import ArtworkInfoCard from '../Components/ArtworkInfoCard';
import PublicArtwork from '../Components/PublicArtwork';
import MainButton from '../Components/CustomComponents/MainButton';
import artist_avatar_default from '../assets/img/new_landing/profile_avatar_default.svg';
import ChangedConfirmedPopup from '../Components/CustomComponents/ChangedConfirmedPopup';
import { capitalizeFirstLetter } from '../Constants/capitalizeFirstLetter';
import SEO from '../Components/SEO';
import ModalWindow from '../Components/ModalWindow';
import Viewer from 'react-viewer';
import moment from "moment";
import {unitByCurrency} from "../Constants/unitByCurrency";
import {localizeSize} from "../Constants/localizeSize";

const initialState = {
  tab: {
    active: 'shipping',
    options: ['shipping', 'returns', 'payment', 'warranty'],
  },
  shipping: {
    img: delivery_insured,
    id: 'shipping',
    text: 'We ship with the world’s best delivery partners',
  },
  returns: {
    img: withdrawal_period,
    id: 'returns',
    text: 'ARTISFACT has a 14 day withdrawal period, starting on the day you receive the work.',
  },
  payment: {
    img: pay_safely,
    id: 'payment',
    text: 'With ARTISFACT, you can pay safely by credit card or bank transfer.',
  },
  warranty: {
    img: reliability_traceability,
    id: 'warranty',
    text: 'ARTISFACT guarantees reliability  and traceability.',
  },
};

const NewArtworkPublic = ({currency, units, currencies}) => {
  const [artworkData, setArtworkData] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(initialState);
  const [recentlyLoading, setRecentlyLoading] = useState(false);
  const [showMainFile, setShowMainFile] = useState(false);
  const userStore = useSelector((store) => store.auth.user_data);
  const recentlyStore = useSelector((store) => store.recentView.recentViewList);
  let location = useLocation();
  let dispatch = useDispatch();
  let { innerWidth } = useResize();
  const [setOrderBasket] = useState();
  const { changeContinuePopup, successText, changingConfirmed } =
    useBottomPopup();

  const [addToBasketError, setAddToBasketError] = useState(false);

  useEffect(() => {
    if (!!userStore) {
      let bucket = localStorage.getItem('artstedBucketList')
        ? JSON.parse(localStorage.getItem('artstedBucketList'))
        : [];

      bucket.map((art) =>
        API()
          .post(`/user/basket`, { id: art.artwork.id })
          .catch((err) => {
            console.log(err);
          })
      );

      localStorage.setItem('artstedBucketList', JSON.stringify([]));
    }

    const publicArtworkUrl = location.pathname.split('/')[2];
    if (!isNaN(Number(publicArtworkUrl))) {
      ViewAnalytic(`/public/view_artwork?id=${publicArtworkUrl}`);

      API()
        .get(`/public/artwork?id=${publicArtworkUrl}`)
        .then((res) => {
          setArtworkData({
            ...res.data,
            loading: true,
          });

          dispatch(
            setRecentView({
              ...res.data.artwork,
              author_name: res.data.user.name,
            })
          );
          setRecentlyLoading(false);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.response?.data?.error === 'cant find artwork' ||
            err?.response?.data?.error === 'cant find item'
          ) {
            window.location.href = '/404-redirect'
          }
        });
    } else {
      window.location.href = '/404-redirect'
    }
  }, [dispatch, location.pathname, userStore]);

  const followArtist = () => {
    if (!!userStore) {
      API()
        .post(`/user/follow?id=${artworkData.user.id}`)
        .then(() => {
          setArtworkData((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              followed: !prevState.user.followed,
            },
          }));
        });
    } else {
      window.location.href = "/registration/type";
    }
  };

  const addToCart = () => {
    if (userStore) {
      API()
        .get(`/user/basket`)
        .then((res) => {
          setOrderBasket(res.data.artworks[0].category);
          // console.log('order ', res.data.artworks[0].category)
          // console.log('artworkData.artwork.category ', artworkData.artwork.category)
          // if (res.data.artworks[0]?.category === undefined || ((res.data.artworks[0].category === 'NFT') === (artworkData.artwork.category === 'NFT'))) alert('Bang')
        })
        .catch((err) => {
          console.error('AAA: ', err);
        });

      API()
        .post(`/user/basket`, { id: artworkData.artwork.id })
        .then(() => {
          setArtworkData((prevState) => ({
            ...prevState,
            artwork: {
              ...prevState.artwork,
              in_basket: true,
            },
          }));
          dispatch(setCartCount(userStore.cart_length));
          dispatch(setNewHeaderBucket(userStore.cart_length));
          changeContinuePopup('Added to cart');
          setTimeout(() => changeContinuePopup('Added to cart'), 2000);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.error === 'artworks has different category') {
            setAddToBasketError(true);
          }
          if (err.response.data.error === 'already in basket') {
            changeContinuePopup('Item is already added to cart');
            setTimeout(
              () => changeContinuePopup('Item is already added to cart'),
              2000
            );
            setArtworkData((prevState) => ({
              ...prevState,
              artwork: {
                ...prevState.artwork,
                in_basket: true,
              },
            }));
          }
        });
    } else {
      // window.location.href = "/login"
      /*treba*/
      // add to storage
      let bucketItems = localStorage.getItem('artstedBucketList')
        ? JSON.parse(localStorage.getItem('artstedBucketList'))
        : [];

      if (
        bucketItems.length === 0 ||
        (bucketItems[0].artwork.category === 'NFT') ===
          (artworkData.artwork.category === 'NFT')
      ) {
        if (
          bucketItems.filter((e) => e.artwork.id === artworkData.artwork.id)
            .length === 0
        ) {
          console.log(
            '---------- 1artworkData: ',
            artworkData,
            ' ----------------'
          );
          bucketItems.push({...artworkData.artwork, ...artworkData, artist: artworkData.user, added_at: moment().format("DD.MM.YYYY HH:mm:ss")} /*.artwork*/);
          localStorage.setItem(
            'artstedBucketList',
            JSON.stringify(bucketItems)
          );

          setArtworkData((prevState) => ({
            ...prevState,
            artwork: {
              ...prevState.artwork,
              in_basket: true,
            },
          }));

          dispatch(setCartCount(bucketItems.length /*+ 1*/));
          // dispatch(setNewHeaderBucket(bucketItems.length))
          changeContinuePopup('Added to cart');
          setTimeout(() => changeContinuePopup('Added to cart'), 2000);
        }
        if (!localStorage.getItem('artstedBucketList')?.length) {
          dispatch(setNewHeaderBucket(0));
        } else {
          dispatch(
            setNewHeaderBucket(
              localStorage.getItem('artstedBucketList')?.length
            )
          );
        }
      } else {
        // alert('Add the pictures of same type')
        setAddToBasketError(true);
      }
    }
  };

  useEffect(() => {
    if (!recentlyLoading && recentlyStore?.length) {
      let ids = [...recentlyStore?.map((i) => i.id).filter(Boolean)];
      API()
        .get(`/public/recently_viewed?ids=${ids}`)
        .then((res) => {
          let wholeList = recentlyStore.filter((el) => {
            return res.data.ids.indexOf(el.id) !== -1;
          });
          dispatch(setRecentViewArray(wholeList));
          setRecentlyLoading(true);
        });
    }
    // eslint-disable-next-line
  }, [recentlyStore, recentlyLoading]);

  const isInvestor = !userStore || userStore.role === 'investor';
  // const isArtist = !userStore || userStore.role === 'artist'

  // console.log('userStore.role: ', !userStore)

  const downloadNftAdditionalFile = () => {
    // const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
    window.open(artworkData?.artwork?.additional_file);
  };

  const downloadNftMainFile = () => {
    window.open(artworkData?.artwork?.main_file);
  };

  const clearCart = () => {
    if (!userStore) {
      localStorage.removeItem('artstedBucketList');
      dispatch(setNewHeaderBucket(0));
    } else if (userStore.role === 'investor') {
      API()
        .delete('/user/basket')
        .then((res) => {
          if (res.status === 200) {
            dispatch(setNewHeaderBucket(0));
          }
        });
    }
    setAddToBasketError(false);
  };

  // console.log('artworkData: ', artworkData)
  // console.log('AAABBBBCCCCEEEERRRRR:', reduxState.cart_status)

  console.log(artworkData);
  let size = useMemo(() => {
      if (artworkData?.artwork) {
          return units === "inches" ? localizeSize(artworkData.artwork.size, units) : artworkData.artwork.size
      }
  }, [units, artworkData?.artwork])
  const localizedPrice = currency ? (currencies[currency] * (artworkData?.artwork?.price || 0)).toFixed(0) : (artworkData?.artwork?.price || 0);
  const currencySymbol = currency ? unitByCurrency[currency] : "€"
  return (
    <div className={'new_artwork_public profile_section_body'}>
      {
        addToBasketError ? (
          <div
            key={3}
            className={
              'new_modal_wrapper_profile_popup_width new_modal_wrapper'
            }
          >
            <ModalWindow
              activeModal={addToBasketError}
              nestedObj
              setActiveModal={() => setAddToBasketError(false)}
            >
              <div className={'head'}>
                <h4>
                  You can't add{' '}
                  {artworkData.artwork.category === 'NFT'
                    ? 'NFT'
                    : 'classic artwork'}{' '}
                  to cart
                </h4>
                <svg
                  onClick={() => {
                    setAddToBasketError(false);
                  }}
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='40' height='40' fill='#F6F6F1' />
                  <path
                    d='M12 12L28 28M12 28L28 12'
                    stroke='black'
                    strokeWidth='2'
                  />
                </svg>
              </div>
              <p>
                You already have{' '}
                {artworkData.artwork.category !== 'NFT'
                  ? 'NFT'
                  : 'classic artwork'}{' '}
                in the cart
              </p>
              <div className={'modal-window_buttons'}>
                <MainButton
                  type={'button'}
                  className={'button_black'}
                  text={'Clear cart'}
                  onClick={clearCart}
                />
                <MainButton
                  type={'button'}
                  className={'button_black'}
                  style={{ background: '#000', color: '#fff' }}
                  text={'Cancel'}
                  onClick={() => setAddToBasketError(false)}
                />
              </div>
            </ModalWindow>
          </div>
        ) : null
        // <div key={35} className={'new_modal_wrapper'}>
        //     <ModalWindow>
        //         <h4>You can't add {artworkData.artwork.category === 'NFT' ? 'NFT' : 'classic artwork'} to card</h4>
        //         <p>You already have {artworkData.artwork.category !== 'NFT' ? 'NFT' : 'classic artwork'} in the card</p>
        //         <div className={'modal-window_buttons'}>
        //             <MainButton type={'button'}
        //                         className={'button_black'}
        //                         text={'Clear cart'}
        //                         onClick={clearCart}
        //             />
        //             <MainButton type={'button'}
        //                         className={'button_black'}
        //                         style={{backgroundColor: 'black', color: 'white'}}
        //                         text={'Cancel'}
        //                         onClick={() => setAddToBasketError(false)}
        //             />
        //         </div>
        //     </ModalWindow>
        // </div> : null
      }
      <ChangedConfirmedPopup
        successText={successText}
        changingConfirmed={changingConfirmed}
      />
      <div className={'wrapper_artwork_info'}>
        <div className={'wrapper_artwork_info__artwork'}>
          {artworkData?.loading ? (
            <div className={'public-artwork-nft-block-wrapper'}>
              <PublicArtwork innerWidth={innerWidth} {...artworkData} />
              {artworkData.artwork.category === 'NFT' && (
                <div className={'public-artwork-nft-block-container'}>NFT</div>
              )}
            </div>
          ) : null}
          {artworkData?.loading ? (
            <div className='wrapper_artwork_info__artwork_body'>
              <Viewer
                visible={showMainFile}
                zIndex={3600}
                scalable={null}
                showTotal={null}
                attribute={null}
                onMaskClick={() => setShowMainFile(false)}
                onClose={() => setShowMainFile(false)}
                images={[
                  {
                    src: artworkData.artwork.main_file,
                    alt: 'preview_img',
                  },
                ]}
              />
              <SEO
                title={`${artworkData.artwork.title} artwork for sale - Artist: ${artworkData.user.name} | Art investment: Artsted`}
                description={`${artworkData.artwork.description} ♦ ${artworkData.user.name} ♦ Buying artist paints as an investment with Art marketplace: Artsted `}
                fb_title={artworkData.artwork.title}
                fb_description={artworkData.artwork.description}
                image={artworkData.artwork.pictures[0]}
                brand={artworkData.user.name}
                availability={!artworkData.artwork.sold}
                price={artworkData.artwork.price}
                item_id={artworkData.artwork.id}
                group_id={artworkData.artwork.category}
              />
              {artworkData.artwork.category === 'NFT' && (
                <div className={'nft-fixed-price-title-container'}>
                  <span>Fixed Price</span>
                </div>
              )}
              <div className={'wrapper_preview'}>
                <span className={'secured_by'}>
                  Secured by Purchase Protection
                </span>
                <span className={'price'}>
                  {artworkData.artwork.category === 'NFT' ? 'ETH' : currencySymbol}{' '}
                  {artworkData.artwork.category === 'NFT'
                    ? artworkData.artwork.ether_price
                    : localizedPrice}
                </span>
              </div>
              {artworkData.artwork.category === 'NFT' && (
                <p className={'nft-price-dollars'}>
                  $ {artworkData.artwork.price}
                </p>
              )}
              <div className={'wrapper_description'}>
                {isInvestor && artworkData.artwork.sold ? (
                  <div className={'artwork-sold'}>SOLD</div>
                ) : !artworkData.artwork.can_buy ? (
                  <span className={'info'}>
                    Artist needs to set up payment information to be able to
                    sell this artwork
                  </span>
                ) : null}
                {/*{(isInvestor || !userStore) && artworkData.artwork.category !== 'NFT' ?*/}
                {/*    <MainButton*/}
                {/*        text={artworkData.artwork.in_basket ? "Already in Cart" : "Add to Cart"}*/}
                {/*        onClick={addToCart}*/}
                {/*        disabled={artworkData.artwork.sold || !artworkData.artwork.can_buy || artworkData.artwork.in_basket}*/}
                {/*        className={!artworkData.artwork.can_buy || artworkData.artwork.sold || artworkData.artwork.in_basket ?*/}
                {/*            'button_black button_disabled' :*/}
                {/*            'button_black'}/>*/}
                {/*    :*/}
                {/*    <MainButton*/}
                {/*        text={artworkData.artwork.in_basket ? "Already in Cart" : "Add to Cart"}*/}
                {/*        disabled={true}*/}
                {/*        className={'button_black button_disabled'}/>}*/}
                {(isInvestor || !userStore) && (
                  <MainButton
                    text={
                      artworkData.artwork.in_basket
                        ? 'Already in Cart'
                        : 'Add to Cart'
                    }
                    onClick={addToCart}
                    disabled={
                      artworkData.artwork.sold ||
                      !artworkData.artwork.can_buy ||
                      artworkData.artwork.in_basket
                    }
                    className={
                      !artworkData.artwork.can_buy ||
                      artworkData.artwork.sold ||
                      artworkData.artwork.in_basket
                        ? 'button_black button_disabled'
                        : 'button_black'
                    }
                  />
                )}
                {/*{(isInvestor || !userStore) && artworkData.artwork.category === 'NFT' && !artworkData.artwork.sold && (*/}
                {/*    <MainButton*/}
                {/*        text={*/}
                {/*          'Pay by card'*/}
                {/*        }*/}
                {/*        onClick={payByCard}*/}
                {/*        disabled={*/}
                {/*            artworkData.artwork.sold ||*/}
                {/*            !artworkData.artwork.can_buy ||*/}
                {/*            artworkData.artwork.in_basket*/}
                {/*        }*/}
                {/*        className={*/}
                {/*          !artworkData.artwork.can_buy ||*/}
                {/*          artworkData.artwork.sold ||*/}
                {/*          artworkData.artwork.in_basket*/}
                {/*              ? 'button_black button_disabled'*/}
                {/*              : 'button_black'*/}
                {/*        }*/}
                {/*    />*/}
                {/*)}*/}
              </div>
              <div className={'wrapper_shipping_list'}>
                <div className={'every_point'}>
                  <svg
                    width='17'
                    height='13'
                    viewBox='0 0 17 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 6.83333L5.66667 11.5L15.6667 1.5'
                      stroke='black'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span>
                    Original work delivered with a certificate of authenticity.
                  </span>
                </div>
                <div className={'every_point'}>
                  <svg
                    width='17'
                    height='13'
                    viewBox='0 0 17 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1 6.83333L5.66667 11.5L15.6667 1.5'
                      stroke='black'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  <span>Shipping usually takes up to 7 days.</span>
                </div>
              </div>
            </div>
          ) : null}
          {artworkData?.artwork.category !== 'NFT' ? (
            <div className='wrapper_artwork_info__shipping_details'>
              <div className={'tabs'}>
                {shippingDetails.tab.options.map((item, index) => (
                  <p
                    onClick={() => {
                      setShippingDetails((prevState) => ({
                        ...prevState,
                        tab: {
                          ...prevState.tab,
                          active: item,
                        },
                      }));
                    }}
                    className={
                      shippingDetails.tab.active === item
                        ? 'every_tab every_tab_active'
                        : 'every_tab'
                    }
                    key={index}
                  >
                    {capitalizeFirstLetter(item)}
                  </p>
                ))}
              </div>
              <div className={'info'}>
                {Object.entries(shippingDetails).map((i) => {
                  return i[0] === shippingDetails.tab.active ? (
                    <span key={i[1].text}>{i[1].text}</span>
                  ) : null;
                })}
              </div>

              <div className={'learn_more'}>
                <Link to='/'>Learn more </Link>
              </div>
            </div>
          ) : (
            <div className='wrapper-artwork-info-nft-bottom'>
              <div className={'learn_more'}>
                <a
                  href={
                    'https://artstedcom.medium.com/is-60-billion-art-market-is-blockchain-agnostic-a-hand-on-guide-to-artsted-nft-features-81131ad2898d'
                  }
                >
                  Learn more about Artsted NFT{' '}
                </a>
              </div>
            </div>
          )}
        </div>
        {artworkData?.loading ? (
          <div className={'wrapper_artwork_info__info'}>
            <div className={'artist_preview'}>
              <Link
                to={`/artist-profile/${artworkData.user.profile_url}`}
                className={'avatar'}
              >
                <img
                  src={artworkData.user.avatar ?? artist_avatar_default}
                  alt='avatar'
                />
              </Link>
              <Link
                to={`/artist-profile/${artworkData.user.profile_url}`}
                className={'wrapper_artist_preview_info'}
              >
                <div className={'public-artwork-name-container'}>
                  <span className={'name'}>{artworkData.user.name}</span>
                  {artworkData.user.premium && (
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.99987 15.4668C7.01933 15.4668 6.0484 15.2736 5.1425 14.8984C4.2366 14.5232 3.41348 13.9732 2.72014 13.2798C2.02679 12.5865 1.4768 11.7634 1.10157 10.8575C0.726334 9.95159 0.533203 8.98065 0.533203 8.00011C0.533203 7.01958 0.726334 6.04864 1.10157 5.14274C1.4768 4.23685 2.02679 3.41373 2.72014 2.72038C3.41348 2.02704 4.2366 1.47705 5.1425 1.10181C6.0484 0.726578 7.01933 0.533447 7.99987 0.533447C9.98015 0.533447 11.8793 1.32011 13.2796 2.72038C14.6799 4.12065 15.4665 6.01983 15.4665 8.00011C15.4665 9.9804 14.6799 11.8796 13.2796 13.2798C11.8793 14.6801 9.98015 15.4668 7.99987 15.4668Z'
                        fill='#3473FF'
                      />
                      <path
                        d='M4.26758 8.00016L7.46758 10.6668L11.7342 5.3335'
                        stroke='white'
                        strokeWidth='1.6'
                        strokeLinecap='round'
                      />
                    </svg>
                  )}
                </div>
                <span className={'country'}>{artworkData.user.country}</span>
              </Link>
              {innerWidth >= 992 && isInvestor && artworkData?.loading ? (
                <div className={'follow_artist'}>
                  <MainButton
                    text={
                      artworkData.user.followed
                        ? 'Unfollow Artist'
                        : 'Follow Artist'
                    }
                    onClick={followArtist}
                    className={'button_black'}
                  />
                </div>
              ) : null}
            </div>
            {artworkData.artwork.category !== 'NFT' && (
              <div className={'wrapper_artwork_info__categories'}>
                {artworkData.user.categories?.length
                  ? artworkData.user.categories.map((el, i) => (
                      <p key={i}># {el}</p>
                    ))
                  : null}
              </div>
            )}
            {innerWidth < 992 && isInvestor && artworkData?.loading ? (
              <div className={'follow_artist'}>
                <MainButton
                  text={
                    artworkData.user.followed
                      ? 'Unfollow Artist'
                      : 'Follow Artist'
                  }
                  onClick={followArtist}
                  className={'button_black'}
                />
              </div>
            ) : null}
            <div className={'artwork_info'}>
              <div className={'title'}>
                <span>{artworkData.artwork.title}</span>
              </div>
              {artworkData.artwork.category !== 'NFT' ? (
                <div className={'wrapper_artwork_info__details'}>
                  <span className={'preview'}>Year</span>
                  <span className={'value'}>{artworkData.artwork.year}</span>
                  <span className={'preview'}>Dimensions</span>
                  <span className={'value'}>{size}</span>
                  <span className={'preview'}>Category</span>
                  <span className={'value'}>
                    {artworkData.artwork.category}
                  </span>
                  {!!artworkData.artwork.styles?.length && (
                    <>
                      <span className={'preview'}>Style</span>
                      <div>
                        {artworkData.artwork.styles?.length ? (
                          <span className={'value'}>
                            {artworkData.artwork.styles.join(', ')}
                          </span>
                        ) : (
                          <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {!!artworkData.artwork.materials?.length && (
                    <>
                      <span className={'preview'}>Materials</span>
                      <div className={'value'}>
                        {artworkData.artwork.materials?.length ? (
                          <span className={'value'}>
                            {artworkData.artwork.materials.join(', ')}
                          </span>
                        ) : (
                          <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {!!artworkData.artwork.mediums?.length && (
                    <>
                      <span className={'preview'}>Mediums</span>
                      <div className={'value'}>
                        {artworkData.artwork.mediums?.length ? (
                          <span className={'value'}>
                            {artworkData.artwork.mediums.join(', ')}
                          </span>
                        ) : (
                          <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {artworkData.artwork.packaging_type && (
                    <>
                      <span className={'preview'}>Packaging type</span>
                      <span className={'value'}>
                        {artworkData.artwork.packaging_type}
                      </span>
                    </>
                  )}
                  {artworkData.artwork.exhibitions.link && (
                    <>
                      <span className={'preview'}>Exhibitions</span>
                      <div>
                        {artworkData.artwork.exhibitions.link ? (
                          <a
                            className={'value'}
                            href={artworkData.artwork.exhibitions.link}
                            target={'_blank'}
                            rel='noopener noreferrer nofollow'
                          >
                            {artworkData.artwork.exhibitions.description}
                          </a>
                        ) : (
                          (
                            <span className={'value'}>
                              {artworkData.artwork.exhibitions.description}
                            </span>
                          ) ?? <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {artworkData.artwork.publication.link && (
                    <>
                      <span className={'preview'}>Publications</span>
                      <div>
                        {artworkData.artwork.publication.link ? (
                          <a
                            className={'value'}
                            href={artworkData.artwork.publication.link}
                            target={'_blank'}
                            rel='noopener noreferrer nofollow'
                          >
                            {artworkData.artwork.publication.description}
                          </a>
                        ) : (
                          (
                            <span className={'value'}>
                              {artworkData.artwork.publication.description}
                            </span>
                          ) ?? <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {/*<span className={'value'}>{artworkData.artwork.}</span>*/}
                </div>
              ) : (
                <div className={'wrapper_artwork_info__details'}>
                  {artworkData.artwork.main_file &&
                    ['jpeg', 'png', 'jpg'].includes(
                      artworkData.artwork.main_file_name.split('.').pop()
                    ) && (
                      <MainButton
                        style={{ gridColumn: '-1/1' }}
                        text={'Preview main file'}
                        onClick={() => setShowMainFile(true)}
                      />
                    )}
                  {!!artworkData.artwork.styles?.length && (
                    <>
                      <span className={'preview'}>Style of art</span>
                      <div>
                        {/*{console.log('artworkData.artwork.styles: ', artworkData?.artwork.styles)}*/}
                        {artworkData.artwork.styles?.length ? (
                          <span className={'value'}>
                            {artworkData.artwork.styles instanceof Array
                              ? artworkData.artwork.styles.join(', ')
                              : artworkData.artwork.styles}
                          </span>
                        ) : (
                          <span className={'value'}>None</span>
                        )}
                      </div>
                    </>
                  )}
                  {artworkData.artwork.main_file_name && (
                    <>
                      <span className={'preview'}>Main File</span>
                      <span
                        onClick={downloadNftMainFile}
                        className={'value file-download-text'}
                      >
                        {artworkData.artwork.main_file_name
                          ? artworkData.artwork.main_file_name
                          : 'None'}
                      </span>
                    </>
                  )}
                  {artworkData.artwork.additional_file_name && (
                    <>
                      <span className={'preview'}>Additional File</span>
                      <span
                        onClick={downloadNftAdditionalFile}
                        className={'value file-download-text'}
                      >
                        {artworkData.artwork.additional_file_name
                          ? artworkData.artwork.additional_file_name
                          : 'None'}
                      </span>
                    </>
                  )}
                  {artworkData.artwork.additional_text && (
                    <>
                      <span className={'preview'}>Additional Text</span>
                      <span className={'value'}>
                        {artworkData.artwork.additional_text
                          ? artworkData.artwork.additional_text
                          : 'None'}
                      </span>
                    </>
                  )}
                </div>
              )}

              <div className={'wrapper_artwork_info__description'}>
                <div className={'title'}>
                  <span>Description</span>
                </div>
                <p className={'description'}>
                  {artworkData.artwork.description}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {recentlyStore?.length ? (
        <div className={'wrapper_artwork_recently_viewed'}>
          <h3>Recently Viewed</h3>
          <div className={'wrapper_artwork_recently_viewed__list'}>
            {recentlyStore.map((el, i) => (
              <ArtworkInfoCard
                {...el}
                key={i}
                picture={el.pictures ? el.pictures[0] : null}
                activeData={recentlyStore}
                setActiveData={(val) => setRecentView(val)}
                index={i}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  currency: state.auth.currency,
  currencies: state.auth.currencies,
  units: state.auth.units
})

export default connect(mapStateToProps)(NewArtworkPublic);
