export function clearCookies(cookie) {
    document.cookie.split(";").forEach(function (c) {
        if (cookie) {
            if (c.includes(cookie)) {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            }
        } else {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        }
    });
}