import React from "react";
import AppleSignin from "react-apple-signin-auth";
import apple from "../assets/img/apple.svg"
import "./styles/custom_social_buttons.css"

export const Apple = ({onSuccess}) => {

    return (
        <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
                /** Client ID - eg: 'com.example.com' */
                clientId: 'com.artsted.signin',
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: 'https://artsted.com/login',
                // redirectURI: "https://artsted-dev.netlify.app/login",
                /** State string that is returned with the apple response */
                state: 'state',
                /** Nonce */
                nonce: 'nonce',
                /** Uses popup auth instead of redirection */
                usePopup: true,
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren="Continue with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(response) => {
                onSuccess(response.authorization.id_token, response.user)
            }} // default = undefined
            // onSuccess={(response) => onSuccess("eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiY29tLmFydHN0ZWQuc2lnbmluIiwiZXhwIjoxNjkyNzAzMjIzLCJpYXQiOjE2OTI2MTY4MjMsInN1YiI6IjAwMTI3Ni4zNDg2MjY1ZTE2ODc0ZGRjOTVjMzdlYTM4NTMxZGYxMS4xMDQ2Iiwibm9uY2UiOiJub25jZSIsImNfaGFzaCI6Ik1SM1BpeFVwa05VcGxXY0ZNTnR3YlEiLCJlbWFpbCI6ImlsZXdkbGlAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOiJ0cnVlIiwiYXV0aF90aW1lIjoxNjkyNjE2ODIzLCJub25jZV9zdXBwb3J0ZWQiOnRydWV9.MOKKWSA1R6S4HrWgtgwEgJ6fW2LhYK-LrduZ_wfB6GR8Mxt7ejQts6itg7_4IEGM9-a1tW-OrgrRaE4_17CiMiemL2pmk8jhGMLXk--E3vNnLekKrrYxzr7AgB-XlwEqJIyjKg8YeSeQtpcUjEU9inzdcNFa9OVSR76yj4F-nQ03Pesi9GJjdYWosllajDCpjC0Vld3lgfYbn5C0dDXD2S4ERN1C4xVC4l41jZv1NeI0uakc7AGxWJvrD4-MUbJsyzC72fclLezav9eMtyxhJXqfq_vNioVFtd30tdbcQZ1W0LdzYEItwPe9imoBZVqYi2OOs9KXaNIP-VF9WlxJPQ")} // default = undefined
            /** Called upon signin error */
            onError={(error) => console.error(error, "error")} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
            render={(props) => (
                <button {...props} className={"apple_button"}>
                    <img src={apple} alt={"Apple logo"} />
                    <span>Continue with Apple</span>
                </button>)
            }
        />
    )
}
