import {Space} from "antd";
import {Link} from "react-router-dom";
import Slider from "react-slick";

import StyledButton from "../../ui/StyledButton";
import {defaultBase64Image} from "../../../Constants/images";

import {ChevronLeftMd, ChevronRightMd} from "../../../assets/icons";

import styles from './ExperiencesStayItem.module.css'

export default function ExperiencesStayItem(
    {
        id,
        address,
        daily_price,
        daily_rentable,
        monthly_price,
        monthly_rentable,
        name,
        title,
        photos,
        type,
    }) {

    return (
        <div className={styles.root}>
            <Link to={`/public-experiences/${type?.toLowerCase()}/${id}`} className={styles.link}>
                <div className={styles.photos}>
                    <Slider
                        dots
                        swipeToSlide
                        adaptiveHeight={false}
                        slidesToShow={1}
                        slidesToScroll={1}
                        nextArrow={<ChevronRightMd />}
                        prevArrow={<ChevronLeftMd />}
                    >
                        {photos?.length > 0 ? (
                            photos?.map((photo) => (
                                <div key={photo.path} className={styles.photosSlide}>
                                    <img src={`${process.env.REACT_APP_API_URL}/${photo.path}`} alt={`${title} - Artwork`}/>
                                </div>
                            ))
                        ) : (
                            <div>
                                <img
                                    className={styles.defaultImage}
                                    alt={title}
                                    src={defaultBase64Image}
                                />
                            </div>
                        )}
                    </Slider>
                </div>
                <div className={styles.content}>
                    <div className={styles.rentable}>
                        {daily_rentable && <span className={styles.badge}>Daily</span>}
                        {monthly_rentable && <span className={styles.badge}>Monthly</span>}
                    </div>
                    <div>
                        <h4 className={styles.title}>{name}</h4>
                        <p className={styles.address}>{address}</p>
                    </div>
                    <div>
                        {daily_price && (
                            <Space size={6} className={styles.price}>
                                <div className={styles.priceTotal}>
                                    €{daily_price.toLocaleString()}
                                </div>
                                night
                            </Space>
                        )}
                        {monthly_price && (
                            <Space size={6} className={styles.price}>
                                <div className={styles.priceTotal}>
                                    €{monthly_price.toLocaleString()}
                                </div>
                                month
                            </Space>
                        )}
                    </div>
                </div>
            </Link>
            <div className={styles.actions}>
                <Link to={`/public-experiences/${type?.toLowerCase()}/${id}`}>
                    <StyledButton color="black">
                        Book now
                    </StyledButton>
                </Link>
            </div>
        </div>
    )
}
