import React, {useEffect, useState} from 'react';
import API from "../../api/API";
import PaymentMethod from "../PaymentMethod";
import CustomCheckbox from "../CustomComponents/CheckBox";
import {AmexIcon, KlarnaIcon, MaestroIcon, MasterCardIcon, PayPalIcon, VisaIcon} from "../../assets/icons";
import MainButton from "../CustomComponents/MainButton";

const PaymentDetails = ({
                            setActiveStep,
                            activeData,
                            selectedShippingAddress,
                            activeSubscription,
                            shippingDetails,
                            checkout,
                            submitCheckout,
                            subscriptionCheckout
                        }) => {
    const [cardData, setCardData] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("card");

    useEffect(() => {
        getPaymentInfo()
    }, [])

    const getPaymentInfo = () => {
        API().get(`/user/profile`)
            .then(res => {
                console.log(res)
                setCardData(res.data)
                // setProfileInvestorData(res.data)
            })
    }

    console.log(shippingDetails)
    console.log(selectedShippingAddress)
    return (
        <div className="payment-details-wrapper">
            <span className="title">Payment</span>
            <span className="description">All transactions are secured and encrypted</span>

            <div className="checkboxes-wrapper">
                <CustomCheckbox text={"Credit card"}
                                noMargin
                                handleChange={() => {
                                    setPaymentMethod("card")
                                }}
                                value={paymentMethod === "card"}>
                    <PaymentMethod no_fill profile_data={cardData}/>
                    <div className="payment-method-icons">
                        <VisaIcon/>
                        <MasterCardIcon/>
                        <MaestroIcon/>
                        <AmexIcon/>
                    </div>
                </CustomCheckbox>
                <CustomCheckbox text={<PayPalIcon/>}
                                noMargin
                                handleChange={() => {
                                    // setPaymentMethod("paypal")
                                }}
                                value={paymentMethod === "paypal"}/>
                <CustomCheckbox text={"Buy now, pay later with Klarna."}
                                noMargin
                                handleChange={() => {
                                    // setPaymentMethod("klarna")
                                }}
                                value={paymentMethod === "klarna"}>
                    <div className="payment-method-icons">
                        <KlarnaIcon/>
                    </div>
                </CustomCheckbox>
            </div>

            {checkout.error && <span className="error">{checkout.error}</span>}
            <div className="shipping-details-footer">
                {!!activeSubscription?.data || (
                    <MainButton text={'Back'}
                                onClick={() => {
                                    setActiveStep(state => ({...state, active: 2}))
                                }}
                                className={'button_bordered'}/>
                )}
                <MainButton style={{marginLeft: 'auto'}}
                            onClick={() => activeSubscription?.data ? subscriptionCheckout() : submitCheckout()}
                            disabled={(!activeData.length && !activeSubscription?.value) || checkout.disabled}
                            text={"Checkout"}/>
            </div>
        </div>
    );
};

export default PaymentDetails;
