import React, {useState, useEffect, useRef} from "react";
import './styles/cv.css'
import API from "../../api/API";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import useBottomPopup from "../../Constants/useBottomPopup";
import MainButton from "../../Components/CustomComponents/MainButton";
import ProgressLine from "../../Components/ProgressLine";
import {useSelector} from "react-redux";
import {ResizeTextBox} from "../../Components/ResizeTextBox";
import CvEntriesSectionPreview from "../../Components/CvComponents/CvEntriesSectionPreview";
import CvEntriesSectionBody from "../../Components/CvComponents/CvEntriesSectionBody";
import {InitialState} from "../../Components/CvComponents/CvInitialState";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import ModalWindow from "../../Components/ModalWindow";
import useResize from "../../Constants/useResize";
import AddInstitutionModal from "../../Components/AddInstitutionModal";


export default function CV() {
    const [data, setData] = useState(InitialState)
    const [loading, setLoading] = useState(true)
    const [playVideo, setPlayVideo] = useState(false)
    const [progressLine, setProgressLine] = useState({value: 0, loading: null})
    const [updateCvModal, setUpdateCvModal] = useState({show: false, type: null})
    const [deleteVideoModal, setDeleteVideoModal] = useState(false)
    const [addInstitutionModal, setAddInstitutionModal] = useState(false)
    const videoRef = useRef()
    const refVideo = useRef()
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const storeUserData = useSelector(store => store.auth.user_data)
    const {innerWidth} = useResize();

    const filteredEvents = events => {
        return events.map(i => ({name: i.name, id: i.id}))
    }

    useEffect(() => {
        Promise.all([
            API().get(`/user/profile/upload_video`),
            API().get(`/user/profile/edit_photo`),
            API().get(`/user/countries`),
            API().get(`/user/profile/cv/data?type=Awards`),
            API().get(`/user/profile/cv/data?type=Museums`),
            API().get(`/user/profile/cv/data?type=Media`),
            API().get(`/user/profile/cv/data?type=Exhibitions`),
            API().get(`/user/profile/cv/data?type=Residencies`),
            API().get(`/user/profile/cv`),
            API().get(`/user/profile/edit_about_yourself`)
        ])
            .then(res => {
                setData(data => ({
                    ...data,
                    video: {
                        preview: res[0].data?.avatar ?? null,
                        value: null,
                        complete: {
                            ...data.video.complete,
                            value: !!res[0].data?.avatar
                        }
                    },
                    avatar: {
                        complete: {
                            ...data.avatar.complete,
                            value: !!res[1].data?.avatar
                        }
                    },
                    education: {
                        ...data.education,
                        activeList: res[8].data.Education ?? [],
                        bodyFields: {
                            ...data.education.bodyFields,
                            country: {
                                ...data.education.bodyFields.country,
                                options: res[2].data.countries
                            }
                        }
                    },
                    awards: {
                        ...data.awards,
                        activeList: res[8].data.Awards ?? [],
                        bodyFields: {
                            ...data.awards.bodyFields,
                            context_field: {
                                ...data.awards.bodyFields.context_field,
                                options: filteredEvents(res[3].data.events)
                            }
                        }
                    },
                    museums: {
                        ...data.museums,
                        activeList: res[8].data.Museums ?? [],
                        bodyFields: {
                            ...data.museums.bodyFields,
                            context_field: {
                                ...data.museums.bodyFields.context_field,
                                options: filteredEvents(res[4].data.events)
                            }
                        }
                    },
                    media: {
                        ...data.media,
                        activeList: res[8].data.Media ?? [],
                        bodyFields: {
                            ...data.media.bodyFields,
                            context_field: {
                                ...data.media.bodyFields.context_field,
                                options: filteredEvents(res[5].data.events)
                            }
                        }
                    },
                    exhibitions: {
                        ...data.exhibitions,
                        activeList: res[8].data.Exhibitions ?? [],
                        bodyFields: {
                            ...data.exhibitions.bodyFields,
                            context_field: {
                                ...data.exhibitions.bodyFields.context_field,
                                options: filteredEvents(res[6].data.events)
                            }
                        }
                    },
                    residencies: {
                        ...data.residencies,
                        activeList: res[8].data.Residencies ?? [],
                        bodyFields: {
                            ...data.residencies.bodyFields,
                            context_field: {
                                ...data.residencies.bodyFields.context_field,
                                options: filteredEvents(res[7].data.events)
                            }
                        }
                    },
                    about: {
                        ...data.about,
                        value: res[9].data.about_yourself,
                        complete: {
                            ...data.about.complete,
                            value: !!res[9].data.about_yourself,
                        }
                    }
                }))
            })
    }, [])

    const uploadVideo = event => {
        event.preventDefault();
        setLoading(false)
        setTimeout(() => {
            let file = event.target.files[0];
            let formData = new FormData();
            formData.append('video', file)
            API().post(`/user/profile/upload_video`, formData)
                .then(() => {
                    changeContinuePopup(`You successfully uploaded video`)
                    setTimeout(() => {
                        changeContinuePopup(`You successfully uploaded video`)
                    }, 2000)
                    if (file) {
                        setLoading(true)
                        setData(data => ({
                            ...data,
                            video: {
                                ...data.video,
                                preview: URL.createObjectURL(file),
                                value: file,
                                complete: {
                                    ...data.video.complete,
                                    value: !!URL.createObjectURL(file)
                                }
                            }
                        }))
                    }
                })
        }, 10)
    }

    const handlePlayVideo = () => {
        videoRef.current.play();
        setPlayVideo(true)
    }

    useEffect(() => {
        setData(data => ({
            ...data,
            avatar: {
                complete: {
                    ...data.avatar.complete,
                    value: !!storeUserData.avatar
                }
            }
        }))
    }, [storeUserData.avatar])

    useEffect(() => {
        let completeArray = [];
        Object.entries(data).forEach(i => {
            if (i[1].activeList?.length) {
                completeArray.push(i[1].complete.percent)
            } else if (i[1].complete.value) {
                completeArray.push(i[1].complete.percent)
            }
        })
        let concatNum = completeArray?.reduce((a, b) => a + b, 0)
        setProgressLine({loading: true, value: concatNum})
    }, [data])

    const checkError = (type) => {
        let detectError = false;
        Object.entries(data[type].bodyFields).forEach(i => {
            if (i[1].required && !i[1].value) {
                setData(prevState => ({
                    ...prevState,
                    [type]: {
                        ...prevState[type],
                        bodyFields: {
                            ...prevState[type].bodyFields,
                            [i[0]]: {
                                ...prevState[type].bodyFields[i[0]],
                                error: true
                            }
                        }
                    }
                }))
                detectError = true
            }
        })
        if (detectError) {
            // eslint-disable-next-line
            throw 'error'
        } else {
            return true
        }
    }

    const onSubmitEntry = (event, type) => {
        event.preventDefault();

        new Promise((resolve, reject) => {
            if (checkError(type)) {
                resolve(true)
            } else {
                reject(true)
            }
        }).then(() => {
            setUpdateCvModal({show: true, type})
        })

    }


    const acceptUpdateCv = () => {
        let type = updateCvModal.type,
            formData = new FormData(),
            detectError = false
        formData.append('type', capitalizeFirstLetter(type))
        if (data[type].edit) {
            formData.append('id', data[type].edit)
        }
        console.log(data[type].bodyFields)
        Object.entries(data[type].bodyFields).forEach(i => {
            if (i[0] === 'context_field' && i[1].type === 'drop_down') {
                if (!i[1].event_id) {
                    let bodyContextFields = data[type].bodyFields[i[0]]
                    console.log(bodyContextFields.options)
                    let filteredName = bodyContextFields.options.find(i => i.name === bodyContextFields.value)?.id;
                    if (filteredName) {
                        formData.append('event_id', filteredName)
                    } else {
                        setData(prevState => ({
                            ...prevState,
                            [type]: {
                                ...prevState[type],
                                bodyFields: {
                                    ...prevState[type].bodyFields,
                                    [i[0]]: {
                                        ...prevState[type].bodyFields[i[0]],
                                        errorTitle: 'Choose item from the list',
                                        error: true
                                    }
                                }
                            }
                        }))
                        detectError = true
                    }
                } else {
                    formData.append('event_id', i[1].event_id)
                }
            } else if (i[0] === 'context_field') {
                formData.append(i[1].name, i[1].value)
            } else if (i[0] === 'country') {
                console.log(i[1])
                let filteredName = i[1].options.find(j => j === i[1].value);
                if (filteredName) {
                    formData.append(i[1].name, i[1].value)
                } else {
                    setData(prevState => ({
                        ...prevState,
                        [type]: {
                            ...prevState[type],
                            bodyFields: {
                                ...prevState[type].bodyFields,
                                [i[0]]: {
                                    ...prevState[type].bodyFields[i[0]],
                                    errorTitle: 'Choose item from the list',
                                    error: true
                                }
                            }
                        }
                    }))
                    detectError = true
                }
            } else if (i[0] === 'context_field_description') {
                formData.append(i[1].name, i[1].value)
            } else if (i[0] === 'date') {
                formData.append(i[1].name, i[1].value)
            } else {
                formData.append(i[0], i[1].value)
            }
        })

        if (!detectError) {
            API().post(`/user/profile/cv`, formData)
                .then(res => {
                    changeContinuePopup(`You successfully ${data[type].edit ? 'edited' : "added"} ${type} entry`)
                    setTimeout(() => {
                        changeContinuePopup(`You successfully added ${type} entry`)
                    }, 2000)
                    let filteredActiveList = data[type].activeList.filter(i => i.id !== data[type].edit)
                    if (type === 'education') {
                        setData(data => ({
                            ...data,
                            [type]: {
                                ...data[type],
                                activeList: [...filteredActiveList, res.data],
                                edit: null,
                                entriesShow: false,
                                bodyFields: {
                                    ...InitialState[type].bodyFields,
                                    context_field: {
                                        ...InitialState[type].bodyFields.context_field,
                                        options: data[type].bodyFields.context_field.options,
                                    },
                                    country: {
                                        ...InitialState[type].bodyFields.country,
                                        options: data[type].bodyFields.country.options,
                                    }
                                }
                            }
                        }))
                    } else {
                        setData(data => ({
                            ...data,
                            [type]: {
                                ...data[type],
                                activeList: [...filteredActiveList, res.data],
                                edit: false,
                                entriesShow: false,
                                bodyFields: {
                                    ...InitialState[type].bodyFields,
                                    context_field: {
                                        ...InitialState[type].bodyFields.context_field,
                                        options: data[type].bodyFields.context_field.options,
                                    }
                                }
                            }
                        }))
                    }
                })
        }
    }

    const onSubmitAbout = e => {
        e.preventDefault();
        if (data.about.value) {
            API().post(`/user/profile/edit_about_yourself`,
                {about_yourself: data.about.value})
                .then(() => {
                    setData(data => ({
                        ...data,
                        about: {
                            ...data.about,
                            error: false,
                            complete: {
                                ...data.about.complete,
                                value: true,
                            }
                        }
                    }))
                    changeContinuePopup(`You successfully changed about field`)
                    setTimeout(() => {
                        changeContinuePopup(`You successfully changed about field`)
                    }, 2000)
                })
                .catch((err)=>{
                    console.log(err)
                    setData(data => ({
                        ...data,
                        about: {
                            ...data.about,
                            error: true,
                        }
                    }))
                })
        } else {
            setData(data => ({
                ...data,
                about: {
                    ...data.about,
                    error: true,
                }
            }))
        }
    }


    const onDeleteVideo = () => {
        API().delete(`/user/profile/upload_video`)
            .then(() => {
                setData(data => ({
                    ...data,
                    video: {
                        ...data.video,
                        preview: null,
                        value: null,
                        complete: {
                            ...data.video.complete,
                            value: false
                        }
                    }
                }))
            })
    }

    return (
        <div className={'profile_section_body profile_section_body_cv'}>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <h2>Profile Video</h2>
            <div className={'video_wrapper'}>
                <label className="video_preview_landing home_video_preview"
                       htmlFor={data.video.preview ?? 'video_file'}>
                    <input id={'file'} type='file'
                           onChange={e => {
                               uploadVideo(e)
                           }}
                           ref={refVideo}
                           hidden accept={"video/*"}/>
                    {loading ?
                        data.video.preview ?
                            <video controls={data.video.preview ? "controls" : null}
                                   ref={videoRef}
                                   preload="metadata">
                                <source src={data.video.preview + '#t=0.001'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            </video>
                            : null
                        : null}
                    {!data.video.preview ?
                        <svg width="120" height="120"
                             onClick={() => refVideo.current.click()}
                             viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.04674 6.67676L10.5624 4.16111V17.4688C10.5624 17.792 10.6908 18.102 10.9194 18.3306C11.1479 18.5591 11.4579 18.6875 11.7811 18.6875C12.1044 18.6875 12.4144 18.5591 12.6429 18.3306C12.8715 18.102 12.9999 17.792 12.9999 17.4688V4.16111L15.5155 6.67676C15.6286 6.79044 15.763 6.88069 15.911 6.94235C16.059 7.00401 16.2177 7.03586 16.378 7.03607C16.5383 7.03629 16.6971 7.00487 16.8453 6.94362C16.9935 6.88237 17.1281 6.79248 17.2415 6.67911C17.3548 6.56574 17.4447 6.43112 17.506 6.28295C17.5672 6.13479 17.5986 5.97599 17.5984 5.81567C17.5982 5.65534 17.5664 5.49663 17.5047 5.34863C17.443 5.20064 17.3528 5.06626 17.2391 4.9532L12.643 0.356986C12.5298 0.243808 12.3954 0.15403 12.2476 0.0927778C12.0997 0.031526 11.9412 0 11.7811 0C11.6211 0 11.4626 0.031526 11.3147 0.0927778C11.1669 0.15403 11.0325 0.243808 10.9193 0.356986L6.32318 4.9532C6.2095 5.06626 6.11925 5.20064 6.05759 5.34863C5.99594 5.49663 5.96409 5.65534 5.96387 5.81567C5.96365 5.97599 5.99507 6.13479 6.05632 6.28295C6.11758 6.43112 6.20746 6.56574 6.32083 6.67911C6.4342 6.79248 6.56882 6.88237 6.71699 6.94362C6.86515 7.00487 7.02395 7.03629 7.18427 7.03607C7.3446 7.03586 7.50331 7.00401 7.65131 6.94235C7.79931 6.88069 7.93368 6.79044 8.04674 6.67676Z"
                                fill="#fff"/>
                            <path
                                d="M22.3438 10.5625C22.0205 10.5625 21.7105 10.6909 21.482 10.9195C21.2534 11.148 21.125 11.458 21.125 11.7812V21.125H2.4375V11.7812C2.4375 11.458 2.3091 11.148 2.08054 10.9195C1.85198 10.6909 1.54198 10.5625 1.21875 10.5625C0.895517 10.5625 0.585524 10.6909 0.356964 10.9195C0.128404 11.148 0 11.458 0 11.7812V21.5312C0 22.07 0.214006 22.5866 0.594939 22.9676C0.975873 23.3485 1.49253 23.5625 2.03125 23.5625H21.5312C22.07 23.5625 22.5866 23.3485 22.9676 22.9676C23.3485 22.5866 23.5625 22.07 23.5625 21.5312V11.7812C23.5625 11.458 23.4341 11.148 23.2055 10.9195C22.977 10.6909 22.667 10.5625 22.3438 10.5625Z"
                                fill="#fff"/>
                        </svg>
                        : null}
                    {data.video.preview ?
                        innerWidth >= 568 ?
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                 onClick={() => setDeleteVideoModal(true)}
                                 className={'delete_icon'}
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="64" height="64" fill="white"/>
                                <path d="M24 24L40 40M24 40L40 24" stroke="black" strokeWidth="2"/>
                            </svg> :
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 className={'delete_icon'}
                                 onClick={() => setDeleteVideoModal(true)}
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" fill="white"/>
                                <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                            </svg>
                        : null}
                    {!playVideo && data.video.preview ?
                        <div onClick={() => handlePlayVideo()}
                             className={'play_button'}>
                            <svg width="20" height="23" viewBox="0 0 20 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.79317 21.1043L1.78618 21.1084L1.77926 21.1126C1.7315 21.1414 1.67774 21.1565 1.62342 21.1571C1.5691 21.1576 1.5151 21.1436 1.46686 21.1158C1.41856 21.0879 1.37736 21.0469 1.34838 20.996C1.31937 20.9451 1.30396 20.8867 1.30439 20.8268H1.30442V20.8197L1.30444 1.38247L1.3044 1.37564C1.30395 1.31176 1.32018 1.24936 1.35082 1.19479C1.38143 1.1403 1.42507 1.09597 1.4765 1.06539C1.52785 1.03486 1.58554 1.01886 1.64386 1.01829C1.70113 1.01773 1.75802 1.03207 1.80915 1.0605L18.5126 10.7789C18.5128 10.779 18.513 10.7791 18.5132 10.7792C18.5633 10.8087 18.6061 10.8516 18.6363 10.9044C18.6666 10.9575 18.6829 11.0184 18.6829 11.0809C18.6829 11.1434 18.6666 11.2043 18.6363 11.2574C18.6061 11.3101 18.5634 11.353 18.5133 11.3824C18.5131 11.3826 18.5129 11.3827 18.5126 11.3828L1.79317 21.1043Z"
                                    fill={'transparent'}
                                    stroke="#F6F6F1" strokeWidth="1.94671"/>
                            </svg>
                        </div>
                        : null}
                </label>
                <div className={'upload_file'}>
                    <MainButton onClick={() => refVideo.current.click()}
                                className={'button_black'}
                                text={data.video.preview ? 'Change video' : 'Upload video'}/>
                </div>
            </div>
            <div className="submit-institution">
                <span>Don’t see your institution? Submit one now!</span>
                <MainButton text={'Submit'}
                            onClick={() => setAddInstitutionModal(true)}
                            className={'button_black'}/>
            </div>
            <ProgressLine value={progressLine.value}/>
            <form className="wrapper_about_field" onSubmit={onSubmitAbout}>
                <div className={'wrapper_title'}>
                    <h3>About</h3>
                    <svg width="24" height="24" viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.4802 4.58036L19.4198 3.51986L18.6 2.70011C18.4646 2.55788 18.3017 2.44465 18.1212 2.36727C17.9407 2.2899 17.7464 2.25 17.55 2.25C17.3536 2.25 17.1593 2.2899 16.9788 2.36727C16.7983 2.44465 16.6354 2.55788 16.5 2.70011L3 16.2001V21.0001H7.8L21.3 7.50011C21.4422 7.3647 21.5555 7.20181 21.6328 7.02132C21.7102 6.84082 21.7501 6.64649 21.7501 6.45011C21.7501 6.25373 21.7102 6.0594 21.6328 5.87891C21.5555 5.69841 21.4422 5.53552 21.3 5.40011L20.4802 4.58036ZM7.2 19.5001H4.5V16.8001L17.55 3.75011L20.25 6.45011L7.2 19.5001Z"
                            fill="black"/>
                    </svg>
                </div>
                <ResizeTextBox value={data.about.value}
                               error={data.about.error}
                               maxLength={1000}
                               onChange={({target}) => {
                                   setData(data => ({
                                       ...data,
                                       about: {
                                           ...data.about,
                                           value: target.value,
                                           error: false
                                           // complete: {
                                           //     ...data.avatar.complete,
                                           //     value: !!data.about.value
                                           // }
                                       }
                                   }))
                               }}
                               borderBottomInput
                               placeholder={'Placeholder'}
                />
                <div className="wrapper_submit_button">
                    <MainButton text={'Save About'}
                                type={'submit'}
                                style={{background: '#000', color: '#fff'}}
                                className={'button_black'}/>
                </div>
            </form>
            <div className="wrapper_education_field">
                <h3>Education</h3>
                <CvEntriesSectionPreview data={data.education}
                                         innerWidth={innerWidth}
                                         changeContinuePopup={changeContinuePopup}
                                         setData={e => setData(e)}/>
                {data.education.entriesShow ?
                    <CvEntriesSectionBody data={data.education}
                                          onSubmitEntry={onSubmitEntry}
                                          setData={e => setData(e)}/>
                    : null}
            </div>
            <div className="wrapper_career_field">
                <h3>Career</h3>
                <div className={'wrapper_career_field__sections'}>
                    <CvEntriesSectionPreview data={data.awards}
                                             innerWidth={innerWidth}
                                             changeContinuePopup={changeContinuePopup}
                                             setData={e => setData(e)}/>
                    {data.awards.entriesShow ?
                        <CvEntriesSectionBody data={data.awards}
                                              onSubmitEntry={onSubmitEntry}
                                              setData={e => setData(e)}/>
                        : null}
                </div>
                <div className={'wrapper_career_field__sections'}>
                    <CvEntriesSectionPreview data={data.museums}
                                             innerWidth={innerWidth}
                                             changeContinuePopup={changeContinuePopup}
                                             setData={e => setData(e)}/>
                    {data.museums.entriesShow ?
                        <CvEntriesSectionBody data={data.museums}
                                              onSubmitEntry={onSubmitEntry}
                                              setData={e => setData(e)}/>
                        : null}
                </div>
                <div className={'wrapper_career_field__sections'}>
                    <CvEntriesSectionPreview data={data.media}
                                             innerWidth={innerWidth}
                                             changeContinuePopup={changeContinuePopup}
                                             setData={e => setData(e)}/>
                    {data.media.entriesShow ?
                        <CvEntriesSectionBody data={data.media}
                                              onSubmitEntry={onSubmitEntry}
                                              setData={e => setData(e)}/>
                        : null}

                </div>
                <div className={'wrapper_career_field__sections'}>
                    <CvEntriesSectionPreview data={data.exhibitions}
                                             innerWidth={innerWidth}
                                             changeContinuePopup={changeContinuePopup}
                                             setData={e => setData(e)}/>
                    {data.exhibitions.entriesShow ?
                        <CvEntriesSectionBody data={data.exhibitions}
                                              onSubmitEntry={onSubmitEntry}
                                              setData={e => setData(e)}/>
                        : null}
                </div>
                <div className={'wrapper_career_field__sections'}>
                    <CvEntriesSectionPreview data={data.residencies}
                                             innerWidth={innerWidth}
                                             changeContinuePopup={changeContinuePopup}
                                             setData={e => setData(e)}/>
                    {data.residencies.entriesShow ?
                        <CvEntriesSectionBody data={data.residencies}
                                              onSubmitEntry={onSubmitEntry}
                                              setData={e => setData(e)}/>
                        : null}
                </div>
            </div>
            {updateCvModal.show ?
                <div key={3} className={'new_modal_wrapper'}>
                    <ModalWindow setActiveModal={() => {
                        setUpdateCvModal({
                            show: false,
                            type: null
                        })
                    }}>
                        <div className={'title'}>
                            <h4>Do you want to save updates?</h4>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 onClick={() => {
                                     setUpdateCvModal({
                                         show: false,
                                         type: null
                                     })
                                 }}
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="40" height="40" fill="#F6F6F1"/>
                                <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                            </svg>
                        </div>
                        <p>Datartbase IS A SELF-AUIDIT TOOL FOR ARTISTS. WE HOLD NO LIABILITY FOR THE INFORMATION
                            SUBMITTED BY THE USERS. <br/><br/>
                            Datartbase is an independent non-profit organisation that is dedicated to tracking and
                            monitoring the careers of the artists globally based on a number of standardized
                            metrics. <br/><br/>
                            The personal information submitted to our database is sensitive as it influences the
                            estimated prices of tangible assets on the art market. Datartbase is using an Artificial
                            Intelligence CAIN algorithm to perform fact checking and in case of a user knowingly or
                            mistakenly submitting false information the user account will be flagged and/ or removed
                            that will impact the overall market trust towards a single user. Once entered into the
                            Datartbase system the information can not be removed.</p>
                        <div className={'modal-window_buttons'}>
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Cancel'}
                                        onClick={() => {
                                            setUpdateCvModal({
                                                show: false,
                                                type: null
                                            })
                                        }}
                            />
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Update'}
                                        style={{background: '#000', color: '#fff'}}
                                        onClick={() => {
                                            setUpdateCvModal({
                                                show: false,
                                                type: null
                                            })
                                            acceptUpdateCv()
                                        }}
                            />
                        </div>
                    </ModalWindow>
                </div> : null}
            {addInstitutionModal && (
                <AddInstitutionModal
                    onClose={() => setAddInstitutionModal(false)}
                    countries={data.education.bodyFields.country.options}
                />
            )}
            {deleteVideoModal ?
                <div key={3} className={'new_modal_wrapper'}>
                    <ModalWindow setActiveModal={() => {
                        setDeleteVideoModal(null)
                    }}>
                        <h4>Delete this video?</h4>
                        <p>Are you sure you want to delete? This action can't be undone.</p>
                        <div className={'modal-window_buttons'}>
                            <MainButton type={'button'}
                                        style={{background: '#000', color: '#fff'}}
                                        className={'button_black'}
                                        text={'Cancel'}
                                        onClick={() => {
                                            setDeleteVideoModal(null)
                                        }}
                            />
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Delete'}
                                        onClick={() => {
                                            onDeleteVideo()
                                            setDeleteVideoModal(null)
                                        }}
                            />
                        </div>
                    </ModalWindow>
                </div> : null}
        </div>
    )
}
