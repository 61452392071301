import React, {useEffect, useState, useCallback} from 'react';
import '../Profile/styles/inbox.css'
import API from "../../api/API";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import PaginationView from "../../Components/Pagination";
import {getActivePage} from "../../Constants/getActivePage";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import {Link} from "react-router-dom";

export default function Inbox() {
    let {activeHashUrl} = useGetLastChartHash()
    const [inbox, setInbox] = useState(() => ({page: null, loading: false}))

    const getHashUrl = useCallback(() => {
        if (activeHashUrl) {
            setInbox(prevState => ({...prevState, page: activeHashUrl}))
        }
    },[activeHashUrl])

    useEffect(() => {
        getHashUrl()
    }, [activeHashUrl, getHashUrl])

    useEffect(() => {
        if (inbox.page) {
            API().get(`/user/profile/inbox/orders?page=${inbox.page}&per_page=15`)
                .then(res => {
                    setInbox(prevState => ({...prevState, ...res.data, loading: true}))
                })
        }
    }, [inbox.page])

    return (
        <div className="profile_section_body inbox_wrapper"
             key={2}>
            <h2>Inbox</h2>
            <div className="wrapper_messages_list">
                <div className={'wrapper_messages_list__head'}>
                    <span>Status</span>
                    <span>Name</span>
                    <span>Subject</span>
                    <span>Date</span>
                </div>
                {inbox?.orders?.length ?
                    <div className={'wrapper_messages_list__body'}>
                        {inbox.orders.map((item, index) => {
                            return (
                                <Link to={`/profile/inbox/${item.id}`}
                                      className={'every'} key={index}>
                                    <div className={`status`}>
                                        <span>{capitalizeFirstLetter(item.status)}</span>
                                    </div>
                                    <div className={'name'}>
                                        <span>{item.name}</span>
                                    </div>
                                    <div className={'subject'}>
                                        <span>{item.subject}{item?.category === 'NFT' ? ' (NFT)' : null}</span>
                                    </div>
                                    <div className={'date'}>
                                        <span>{item.date}</span>
                                    </div>
                                </Link>
                            )
                        })}
                    </div> : <span className="no_items_style">No message</span>}
                {inbox.loading && inbox.total > 9 ?
                    <div className={'wrapper_pagination'}>
                        <PaginationView activePage={inbox}
                                        onChange={pageNumber => {
                                            getActivePage(pageNumber)
                                            setInbox(prevState => ({
                                                ...prevState,
                                                page: pageNumber
                                            }))
                                        }}/>
                    </div>
                    : null}
            </div>
        </div>
    )
}
