import {Col, Row, Space, Typography} from "antd";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";

import API from "../../api/API";

import StyledButton from "../../Components/ui/StyledButton";
import StyledInput from "../../Components/ui/StyledInput";
import StayBaseInfo from "../../Components/ui/StayBaseInfo";
import CheckInOutDates from "../../Components/ui/CheckInOutDates";

import error from "../../assets/img/danger-red.svg";
import success from "../../assets/img/tick-circle-green.svg";
import {CheckIcon, CloseIcon, TrustpilotNewIcon} from "../../assets/icons";

import styles from './ExperiencesConfirmPayment.module.css';

export default function ExperiencesConfirmPayment() {
    const history = useHistory();
    const [promo, setPromo] = useState({
        status: '',
        value: ''
    });
    const [data, setData] = useState({});
    const [status, setStatus] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        API().get('/user/listing/book/cart')
            .then((res) => {
                setData(res.data?.cart)
                setRemainingTime(res.data?.cart?.seconds_before_cart_expire);
                if (res.data?.cart) {
                    API().get('/user/listing/stay', {params: {id: res.data.cart.listing_id}})
                        .then((res) => {
                            setData((p) => ({
                                ...p,
                                type: res.data.type,
                                address: res.data.address,
                                check_in_from_time: res.data.check_in_from_time,
                                check_in_to_time: res.data.check_in_to_time,
                                check_out_from_time: res.data.check_out_from_time,
                                check_out_to_time: res.data.check_out_to_time,
                                cancellation_policy: res.data.cancellation_policy
                            }))
                        })
                }
            })
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime(prevRemainingTime => {
                if (prevRemainingTime <= 0) {
                    clearInterval(intervalId);
                    return 0;
                } else {
                    return prevRemainingTime - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [remainingTime]);

    const handleApplyPromoCode = () => {
        if (promo.value.length) {
            API().post("/user/payment/check_promo_code", {promo_code: promo.value})
                .then(res => {
                    setPromo((p) => ({
                        ...p,
                        status: res.data.active ? "success" : "error",
                        discount: res.data.active
                    }));
                });
        }
    };

    const handleSubmit = () => {
        setIsLoading(true);
        API().post('/user/listing/book/pay')
            .then(() => {
                setStatus('success')
            })
            .catch(() => setStatus('failed'))
            .finally(() => {
                setIsPaid(true);
                setIsLoading(false);
            });
    };

    if (data === null) {
        history.push('/experiences/stay');
    }

    const formatTime = () => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${Math.round(seconds) < 10 ? '0' : ''}${Math.round(seconds)}`;
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <h1 className={styles.title}>Confirm & Pay</h1>
            </div>
            <div className={clsx(styles.content, !isPaid && styles.contentGrid)}>
                <div>
                    {/*{isPaid && status && (*/}
                        <div className={styles.statusWrapper}>
                            {status === 'success' && (
                                <>
                                    <Typography.Title level={4} type="success">
                                        <Space>
                                            <div className={styles.statusIcon}>
                                                <CheckIcon/>
                                            </div>
                                            Booking Successful!
                                        </Space>
                                    </Typography.Title>
                                    <div className={styles.statusDescription}>
                                        Congratulations! Your booking has been successfully completed.
                                        <br/>
                                        If you have any questions or need further assistance, feel free to contact us.
                                    </div>
                                    <StyledButton color="transparent" onClick={() => history.push('/')}>
                                        Return Home
                                    </StyledButton>
                                </>
                            )}
                            {status === 'failed' && (
                                <>
                                    <Typography.Title level={4} type="danger">
                                        <Space>
                                            <div className={styles.statusIcon}>
                                                <CloseIcon/>
                                            </div>
                                            Booking Failed!
                                        </Space>
                                    </Typography.Title>
                                    <div className={styles.statusDescription}>
                                        We're sorry, but there was an issue processing your payment and we couldn't
                                        complete your booking.
                                        <br/>
                                        Please check your payment details and try again.
                                        <br/>
                                        If the problem persists, please contact our support team for assistance.
                                    </div>
                                    <Row gutter={[24, 24]} justify="center">
                                        <Col>
                                            <StyledButton color="transparent" onClick={() => setIsPaid(false)}>
                                                Return to Payment
                                            </StyledButton>
                                        </Col>
                                        <Col>
                                            <StyledButton color="transparent" onClick={() => window.open('mailto:info@artsted.digital')}>
                                                Contact Support
                                            </StyledButton>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    {/*)}*/}
                    <StayBaseInfo
                        type={data?.type}
                        image={data?.listing_photos?.find((p) => p.main)?.path || data?.listing_photos?.[0]?.path}
                        name={data?.listing_name}
                        address={data?.address}
                    />
                    <div className={styles.infoBody}>
                        <div className={styles.infoInner}>
                            <Typography.Title level={4}>Dates</Typography.Title>
                            <CheckInOutDates
                                checkIn={data?.date_from}
                                checkOut={data?.date_to}
                                checkInFromTime={data?.check_in_from_time}
                                checkInToTime={data?.check_in_to_time}
                                checkOutFromTime={data?.check_out_from_time}
                                checkOutToTime={data?.check_out_to_time}
                            />
                        </div>
                        <div className={styles.infoInner}>
                            <Typography.Title level={4}>Guests</Typography.Title>
                            {data?.guests_num}
                        </div>
                        <div className={styles.infoInner}>
                            <Typography.Title level={4}>Important details</Typography.Title>
                            <div className={styles.infoInnerGridLeft}>
                                <Typography.Text strong>Cancellation policy</Typography.Text>
                                <div className={styles.preWrap}>
                                    {data?.cancellation_policy?.text}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isPaid && (
                    <div className={styles.aside}>
                        <Typography.Title level={4}>Summary</Typography.Title>
                        <div className={styles.reservedBlock}>
                            <div>
                                Reserved for you for
                            </div>
                            <div className={styles.reservedTimer}>
                                {formatTime()}
                            </div>
                        </div>
                        <div className={styles.promoWrapper}>
                            <div>
                                <StyledInput
                                    label="Promo code"
                                    onChange={(e) => setPromo({
                                        status: '',
                                        value: e.target.value
                                    })}
                                />
                            </div>
                            <div className={styles.promoButton}>
                                <StyledButton color="transparent" onClick={handleApplyPromoCode}>
                                    Apply
                                </StyledButton>
                            </div>
                        </div>
                        {promo.status && (
                            <div className={styles.promo}>
                                <img
                                    src={promo.status === 'success' ? success : error}
                                    alt={promo.status}
                                />
                                <Typography.Text type={promo.status === 'success' ? 'success' : 'danger'}>
                                    The promo code you entered is
                                    {promo.status === 'success' ? " applied" : " invalid. Please try a different one."}
                                </Typography.Text>
                            </div>
                        )}
                        <div className={styles.prices}>
                            <Row justify="space-between">
                                <Col>Price:</Col>
                                <Col>€{data?.price}</Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>Discount:</Col>
                                <Col>{promo.discount ? `-${promo.discount}` : 0}%</Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>Platform fee:</Col>
                                <Col>€{data?.fee_amount}</Col>
                            </Row>
                            <Row justify="space-between" className={styles.priceTotal}>
                                <Col>Total:</Col>
                                <Col>€{promo.discount ? (data?.price + data?.fee_amount - (data?.price * promo.discount / 100)) : data?.price + data?.fee_amount}</Col>
                            </Row>
                        </div>
                        <StyledButton
                            block
                            loading={isLoading}
                            disabled={remainingTime < 0}
                            onClick={handleSubmit}
                        >
                            Pay now
                        </StyledButton>
                        {remainingTime < 0 && (
                            <Typography.Paragraph className={styles.error} type="danger">
                                The reserved time slot has expired. Please select a new time and start the reservation
                                process again.
                            </Typography.Paragraph>
                        )}
                        <Space direction="vertical" size={12} className={styles.icon}>
                            <TrustpilotNewIcon
                                onClick={() => window.open("https://www.trustpilot.com/review/artsted.com")}
                            />
                            <div>TrustScore <b>5.0</b> | <b>7</b> reviews</div>
                        </Space>
                    </div>
                )}
            </div>
        </div>
    )
}
