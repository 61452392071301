export const bodyOverflow = modal => {
    // take a modal boolean value for overflowing body scroll;
    let body = document.body,
        html = document.documentElement;

    let bodyHeight = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

    let screenVhHeight = 100 * bodyHeight / window.innerHeight

    if (modal) {
        body.style.overflowY = 'hidden'
        if (window.innerWidth > 1024 && screenVhHeight > 100) {
            body.style.width = 'calc(100% - 15px)'
        } else {
            body.style.width = ''
        }
    } else {
        body.style.overflowY = ''
        body.style.width = ''
    }
};