import React from 'react'

const TermsApplicableLaw = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>12. Applicable Law and Jurisdiction</h3>
            <div className={'terms_of_service_item_container'}>
                <span>12.1</span>
                <span>
                    In the event of a dispute, only substantive British law is applicable, explicitly excluding
                    the application of any conflict of law provisions. The place of jurisdiction is the ordinary
                    courts at the registered office of the website operator.
                </span>
            </div>
            <div className={'terms_of_service_item_container'}>
                <span>12.2</span>
                <span>
                    Should individual provisions of this contract be or become without legal force, the validity
                    of the rest of the contract is not touched. The ineffective provisions shall be replaced by an
                    agreement in accordance with the law and in as close correspondence as possible to the will of
                    the parties.
                </span>
            </div>
        </div>
    )
}

export default TermsApplicableLaw