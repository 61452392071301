import React from 'react';
import SEO from "../../Components/SEO";

export default function CareerChart() {
    return (
        <div className={'faq_section_body chart_padding'}>
            <SEO title="CV and Career Chart: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ CV and Career Chart: Buy art online Artsted"/>
            <h1>CV and Career Chart</h1>

            <p>At the beginning of their journey with Artsted the artist is asked to establish a base price for the
                uploaded works, that correlate with their current market price and former sales records. If such price
                is not possible to set, we suggest you price the artwork based on the materials cost and working hours
                employed in its creation.</p>
               <p> Artsted then will ask the user to update the on-platform CV every 2 months so that the performance
                reflected in one's exhibitions, publications, art residences, art prizes, record sales and other
                indicators of growth could be the underlying foundation for the Value Coefficient, that would apply to
                one's current and previously made and sold artwork.</p>
        </div>
    );
}
