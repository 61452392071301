import React, {useEffect, useState} from 'react'
import './Cart/styles/subscription_plans.css'
import {setCartCount} from "../main-store/auth/actions";
import {connect} from "react-redux";
import API from "../api/API";
import {useHistory} from "react-router-dom";
import Keys from "../Constants/helper";
import established from "../assets/img/subscription_2.svg";
import emerging from "../assets/img/subscription_3.svg";
import {capitalizeFirstLetter} from "../Constants/capitalizeFirstLetter";
import MainButton from "../Components/CustomComponents/MainButton";
import SEO from "../Components/SEO";


function SubscriptionPlans({user_data}) {

    let history = useHistory();

    const [activePlan, setActivePlan] = useState(false);
    // const [planTimeCreator, setPlanTimeCreator] = useState('Monthly')
    // const [planTimeAnalytics, setPlanTimeAnalytics] = useState('Monthly')
    const [loading, setLoading] = useState(false);
    const [choosenPlan, setChoosenPlan] = useState('month')
    const [disabledButton, setDisabledButton] = useState({
        basic: false,
        creator: false,
        analytics: false,
    });
    const [subscriptionPlanData, setSubscriptionPlanData] = useState([
        {
            id: 0,
            // logo: basic,
            plan_title: 'basic',
            percentage: 'Simple Analytics',
            price: 'Free',
            basicPlan: true,
            dataText: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'Upload artwork'],
        }
    ]);

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        if (localStorage.getItem(Keys.JWT_TOKEN)) {
            API().get(`/user/payment/subscription`)
                .then(res => {
                    // console.log(res)
                    setDisabledButton(prevState => ({
                        ...prevState,
                        basic: !!localStorage.getItem(Keys.JWT_TOKEN),
                        [res.data.subscription !== 'basic' ? res.data.subscription : null]: res.data.subscription,
                    }))
                    if (user_data?.role === 'investor') {
                        setSubscriptionPlanData(prevState => [
                            ...prevState,
                            {
                                id: 2,
                                logo: emerging,
                                plan_title: 'analytics',
                                percentage: '30 registrations',
                                price: '15',
                                priceMonth: '120',
                                dataText: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'See advanced analytics and price charts', 'Get personalised support', 'Premium shipping and handing'],
                            }])

                        setDisabledButton(prevState => ({
                            ...prevState,
                            creator: true,
                        }))
                    } else {
                        setSubscriptionPlanData(prevState => [
                            ...prevState, {
                                id: 1,
                                logo: established,
                                plan_title: 'creator',
                                percentage: 'Unlimited',
                                price: '30',
                                priceMonth: '240',
                                dataText: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'Upload artwork', 'See advanced analytics and price charts', 'Get personalised support', 'Premium promotion tools', 'Access the Non-fungible tokens creation tools', 'Receive exhibition opportunities', 'A dedicated curator', 'Community mentorship program']
                            }])

                        setDisabledButton(prevState => ({
                            ...prevState,
                            analytics: true,
                        }))
                    }
                    setLoading(true)
                    setActivePlan(res.data)
                })
                .catch(err => {
                    console.log(err)
                    window.location.href = '/404-redirect'
                })
        } else {
            window.location.href = '/login'
        }
    }, [user_data?.role, history])

    const handleChoosePlan = item => {
        console.log('handleChoosePlan: ', item)
        if (activePlan.subscription === 'basic') {
            let subscriptionPlanObject = subscriptionPlanData.find(el => el.id === item.id)
            subscriptionPlanObject['subTime'] = choosenPlan
            console.log('subscriptionPlanObject: ', subscriptionPlanObject)
            sessionStorage.setItem(Keys.SUBSCRIPTION_DATA, JSON.stringify(subscriptionPlanObject))
            sessionStorage.setItem(Keys.SUBSCRIPTION_PLAN_BILL, 'annually')
            history.push(`/cart?subscription=${item.plan_title}`);
        } else {
            API().post(`/user/payment/subscription?subscription=basic`)
                .then(res => {
                    window.location.href = res.data.url
                })
        }
    }

    return (
        <div className={'subscription_plan_wrapper'}>
            <SEO title="Choose your plan: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Choose your plan: Buy art online Artsted"/>
            {!JSON.parse(localStorage.getItem(Keys.FROM_APP)) && <div className={'subscription_plan_wrapper_content'}>
                <h2>Choose your plan</h2>
                <div className={'subscription_plan_cards_wrapper'}>

                    {loading && subscriptionPlanData.map(item => {
                            let condition = item.plan_title === activePlan.subscription?.toLowerCase();
                            return (
                                <div key={item.id}
                                     className={condition ?
                                         'subscription_plan_card subscription_plan_card_active' : 'subscription_plan_card'}>
                                    <h3>{capitalizeFirstLetter(item.plan_title)}</h3>
                                    <p>{item.percentage}</p>
                                    {item.plan_title !== 'basic' &&
                                    <div className={'plan_block_selector'}>
                                        <span className={choosenPlan === 'month' ? 'plan_block_selector__active' : 'plan_block_selector__not_active'}
                                              onClick={() => setChoosenPlan('month')}>Monthly</span>

                                        <span className={choosenPlan === 'year' ? 'plan_block_selector__active' : 'plan_block_selector__not_active'}
                                              onClick={() => setChoosenPlan('year')}>Yearly</span>
                                    </div>
                                    }

                                    <div className={'subscription_plan_card_price'}>
                                        {/*<h2>{item.basicPlan ? `€${item.price}` : item.price}/ </h2>*/}
                                        {item.plan_title === 'basic' &&
                                        <div style={{display: 'flex'}}>
                                            <h2>{item.price}/ </h2>
                                            <small> year</small>
                                        </div>

                                        }

                                        {item.plan_title !== 'basic' && choosenPlan === 'month' &&
                                        <div style={{display: 'flex'}}>
                                            <h2>{`€${item.price}`}/ </h2>
                                            <small> month</small>
                                        </div>
                                        }
                                        {item.plan_title !== 'basic' && choosenPlan === 'year' &&
                                        <div style={{display: 'flex'}}>
                                            <h2>{`€${item.priceMonth}`}/ </h2>
                                            <small> year</small>
                                        </div>
                                        }
                                    </div>

                                    <div className={'subscription_plan_card_text_content'}>
                                        {item.dataText.map((el, i) => (
                                            <div className={'subscription_plan_card_text_content_item'} key={i}>
                                                <svg width="17" height="13" viewBox="0 0 17 13" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 6.83333L5.66667 11.5L15.6667 1.5" stroke="black"
                                                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <p>{el}</p>
                                            </div>
                                        ))}
                                    </div>


                                    <MainButton type={'button'}
                                                style={{margin: 'auto 0 0'}}
                                                text={condition ? 'Current plan' : 'Choose plan'}
                                                className={`button_black ${disabledButton[item.plan_title.toLowerCase()] ? 'button_black_disabled' : ''}`}
                                                disabled={disabledButton[item.plan_title.toLowerCase()]}
                                                onClick={() => handleChoosePlan(item)}
                                    />
                                </div>
                            )
                        }
                    )}
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user_data: state.auth.user_data,
    }
};

const putDispatchToProps = {
    setCartCount
}

export default connect(mapStateToProps, putDispatchToProps)(SubscriptionPlans);
