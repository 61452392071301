import {Dropdown, Typography} from "antd";
import {useReducer} from "react";
import Slider from "react-slick";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import API from "../../../api/API";
import {deleteListingFailed, deleteListingRequest, deleteListingSuccess} from "../../../main-store/listing/actions";

import StyledModal from "../../ui/StyledModal";

import {ChevronLeftMd, ChevronRightMd, DotsVertical} from "../../../assets/icons";

import styles from './ListingItem.module.css';
import {defaultBase64Image} from "../../../Constants/images";

export default function ListingItem({ ...props }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const {isDeleting} = useSelector(state => state.listings)
    const [isDeleteModelOpen, toggleDeleteModalOpen] = useReducer(state => !state, false);

    const handleDelete = () => {
        dispatch(deleteListingRequest())
        API().delete('/user/listing/stay', {params: {id: props.id}})
            .then(() => dispatch(deleteListingSuccess(props.id)))
            .catch(() => dispatch(deleteListingFailed))
    }

    const items = [
        {label: 'Edit listing', key: '0', onClick: () => history.push(`/listing/${props.id}`)},
        {label: 'Delete listing', key: '1', onClick: toggleDeleteModalOpen},
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.root}>
                <div className={styles.imageWrapper}>
                    <Slider
                        dots
                        swipeToSlide
                        adaptiveHeight={false}
                        slidesToShow={1}
                        slidesToScroll={1}
                        nextArrow={<ChevronRightMd />}
                        prevArrow={<ChevronLeftMd />}
                    >
                        {props.photos.length ? (
                            props.photos.sort((a, b) => b.main - a.main).map((photo, index) => (
                                <div key={index}>
                                    <img
                                        className={styles.image}
                                        src={`${process.env.REACT_APP_API_URL}/${photo.path}`}
                                        alt={`${props.title} - Artwork`}
                                    />
                                </div>
                            ))
                        ) : (
                            <div>
                                <img
                                    className={styles.defaultImage}
                                    alt={props.title}
                                    src={defaultBase64Image}
                                />
                            </div>
                        )}
                    </Slider>
                </div>
                <div className={styles.inner}>
                    <Typography.Title ellipsis level={5}>{props.name}</Typography.Title>
                    <Typography.Paragraph ellipsis className={styles.address}>{props.address}</Typography.Paragraph>
                </div>
            </div>
            <StyledModal
                okText="Yes, delete"
                cancelText="No"
                title="Are you sure you want to delete this listing?"
                open={isDeleteModelOpen}
                onCancel={toggleDeleteModalOpen}
                onOk={handleDelete}
                okButtonProps={{
                    disabled: isDeleting
                }}
            />
            <Dropdown overlayClassName={styles.menu} menu={{items}} trigger={['click']} placement="bottomRight">
                <button className={styles.actionButton}>
                    <DotsVertical width={24} height={40}/>
                </button>
            </Dropdown>
        </div>
    )
}