import React, {useEffect, useState} from 'react';
import {Navigation} from "../../../routers/navigation";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import '../../styles/terms_of_service.css';
import useResize from "../../../Constants/useResize";
import SEO from "../../../Components/SEO";

export default function PrivacyPolicy() {
    const {innerWidth} = useResize();

    let location = useLocation();

    function SwitcherNavBar({routes}) {
        return (
            <Switch>
                {routes.map((route, index) => (
                    <Route path={route.path}
                           exact={true}
                           component={route.main}
                           key={index}/>
                ))}
            </Switch>
        )
    }

    //const handleLogOut = () => {
    //    API(Keys.JWT_TOKEN_ADMIN).post('/admin/logout')
    //        .then(() => {
    //            localStorage.removeItem(Keys.JWT_TOKEN_ADMIN)
    //            window.location.href = '/admin';
    //        });
    //};
    const renderSwitchRoute = React.useCallback(() => (
        <div /*className={'wrapper_admin_panel_content'}*/>
            <SwitcherNavBar routes={Navigation.mainNavigationPrivacyPolicy}/>
        </div>
    ), [])

    const [activeNavigationPage, setActiveNavigationPage] = useState(location.pathname.split('/'))
    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        let url = location.pathname.split('/');
        setActiveNavigationPage(url)
    }, [location.pathname])

    const OldSchoolMenuLink = ({label, pathname, path, icon}) => {
        return label ? (
            <div
                className={`terms_of_service_navigation_wrapper ${activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'terms_of_service_navigation_wrapper_active' : ''}`}>
                <Link key={pathname}
                      className={activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'admin_bar_active_link' : null}
                      onClick={() => {
                          if (pathname === 'blog') {
                              window.open(`https://webflow.com/dashboard/sites/blog-artsted-com/general`)
                          } else {
                              setActiveNavigationPage(pathname)
                              window.scrollTo(0, 0)
                          }
                      }}
                      to={path}>
                    {icon}
                    <span>{label}</span>
                    {activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ?
                        <div className={'terms_of_service_active_close'}>
                            <div className={'vertical_line_terms_item_close'}/>
                            <div className={'horizontal_line_terms_item_close'}/>
                        </div>
                        :
                        <div className={'terms_of_service_active'}>
                            <div className={'vertical_line_terms_item'}/>
                            <div className={'horizontal_line_terms_item'}/>
                        </div>
                    }
                </Link>
                {(activeNavigationPage === pathname || activeNavigationPage.includes(pathname)) && innerWidth < 550 && renderSwitchRoute()}
            </div>
        ) : null
    };

    return (
        <div>
            <SEO title="Privacy Policy: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Privacy Policy: Buy art online Artsted"/>
            <div className={'terms_of_service_container'}>
                <h1>Privacy Policy</h1>
                <div /*className={'terms_of_service-two-block'}*/>
                    <div /*className={'terms_of_service_content_container'}*/>

                    </div>
                    <div className={'terms_of_service_section_container'}>
                        <div /*className={'wrapper_admin_panel_navigation'}*/>
                            <div>
                                <div className={'panel_navigation_content_terms_of_service'}>
                                    {/*console.log(Navigation.mainNavigationTermsOfService)*/}
                                    {Navigation.mainNavigationPrivacyPolicy.map((item, index) => {
                                        return (
                                            <OldSchoolMenuLink {...item} key={index}/>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {innerWidth >= 550 && renderSwitchRoute()}
                    </div>
                </div>
            </div>
        </div>
        /*<div className={'answer_from_footer'}>
            <h1>PrivacyPolicy</h1>
            <p>This website is not intended for children and we do not knowingly
                collect data relating to children.</p>
            <div className={'entity_rules first_block_of_terms'}>
                <h1>1. IMPORTANT INFORMATION</h1>
                <div className={'products_services_terms'}>
                    <span>Data Controller</span>
                    <p>Artsted is the data controller responsible for this website and the handling of your personal
                        data.
                        We have appointed a data privacy lead who is responsible for overseeing questions in relation to
                        this Privacy Policy. If you have any questions about this Privacy Policy, including any requests
                        to
                        exercise your legal rights, please contact us at <a href="/">legal@artsted.com</a>.
                    </p>
                </div>

                <div className={'products_services_terms'}>
                    <span>CONTACT DETAILS</span>
                    <p>Artisfact Limited (company number 12015093, registered in England).<br/>
                        Registered office address: 71-75 Shelton Street, Covent Garden, London, England, WC2H 9JQ
                    </p>
                </div>
                <div className={'products_services_terms'}>
                    <span>CHANGES TO THE PRIVACY POLICY</span>
                    <p>We may need to update this Policy at any time and without notice and where we do this we will
                        notify
                        you by including pop up boxes on the website and/or emailing our customers. This Policy was last
                        updated on 30 July 2020. It is important that the personal data we hold about you is accurate
                        and
                        current. Please keep us informed if your personal data changes during your relationship with us.
                    </p>
                </div>
                <div className={'products_services_terms'}>
                    <span>THIRD-PARTY LINKS</span>
                    <p>This website may include links to third-party websites, plug-ins and applications. Clicking on
                        those
                        links or enabling those connections may allow third parties to collect or share data about you.
                        We
                        do not control these third-party websites and are not responsible for their privacy statements.
                        When
                        you leave our website, we encourage you to read the privacy policy of every website you visit.
                    </p>
                </div>
            </div>
            <div className={'entity_rules first_block_of_terms'}>
                <h2>2. THE DATA WE COLLECT</h2>
                <div className={'products_services_terms'}>
                    <p>Personal data, or personal information, means any information about an individual from which that
                        person can be identified. It does not include data where the identity has been removed
                        (anonymous
                        data).</p>
                    <p>We may collect, use, store and transfer different kinds of personal data about you which we have
                        grouped together follows:</p>
                </div>
                <div className={'data_collect_info'}>
                    <div/>
                    <p><span>Identity Data</span> includes first name, last name, username and ID, title, date of birth
                        and
                        gender</p>
                    <div/>
                    <p><span>Contact Data</span> includes billing address, delivery address, email address and telephone
                        number.</p>
                    <div/>
                    <p><span>Financial Data</span> includes payment card details and bank account (if you are a Seller).
                        We do
                        not
                        store or process your card details ourselves, they are processed and stored via one of our
                        contracted third party service providers. We encrypt your payment card details in your
                        browser
                        and securely transfer this data to our relevant third party payment provider to process a
                        payment.</p>
                    <div/>
                    <p><span>Transaction Data</span> includes details about payments to and from you and other details
                        of
                        products
                        and services you have purchased from us or our Sellers.</p>
                    <div/>
                    <p><span>Technical Data</span> includes internet protocol (IP) address, your login data, browser
                        type and
                        version, time zone setting and location, browser plug-in types and versions, operating
                        system
                        and platform and other technology on the devices you use to access this website.</p>
                    <div/>
                    <p><span>Profile Data</span> includes your username and password, purchases or orders made by you,
                        your
                        interests, artists / artworks / users you like / follow / dislike, preferences, a link to
                        your
                        Facebook account if you register using Facebook Connect, feedback and survey responses.</p>
                    <div/>
                    <p><span>Messaging Data</span> includes messages sent to or received from Sellers and Artsted staff,
                        including
                        use of our Make an Offer and Commission an Artist features.</p>
                    <div/>
                    <p><span>Usage Data</span> includes information about how you use our website, products and
                        services.</p>

                    <div/>
                    <p><span>Marketing and Communications Data</span> includes your preferences in receiving marketing
                        from us
                        and
                        our third parties and your communication preferences.</p>
                    <div/>
                    <p><span>Seller Application Data</span> includes any details provided when applying to become a
                        Seller.</p>
                    <div/>
                    <p><span>Seller Profile Data</span> includes any details provided by a Seller about themselves,
                        their
                        artists,
                        and their artworks in order to sell through Artsted.</p>
                </div>
                <div className={'products_services_terms'}>
                    <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any
                        purpose. Aggregated Data may be derived from your personal data but is not considered personal
                        data in law as this data does not directly or indirectly reveal your identity. For example, we
                        may aggregate your Usage Data to calculate the percentage of users accessing a specific website
                        feature. However, if we combine or connect Aggregated Data with your personal data so that it
                        can directly or indirectly identify you, we treat the combined data as personal data which will
                        be used in accordance with this Privacy Policy</p>

                    <p>We do not ordinarily collect any Special Categories of Personal Data about you (this includes
                        details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                        orientation, political opinions, trade union membership, information about your health and
                        genetic and biometric data). Nor do we collect any information about criminal convictions and
                        offences. If you use any of these types of personal data when using any of the website's
                        features (including messaging system), in your public artist or user bio or when selling
                        artworks through Artsted, we will not be able to tell. We will treat all the personal data
                        included in your user or artist profile or artwork listings as ordinary personal data</p>
                </div>
                <div className={'products_services_terms'}>
                    <span>IF YOU FAIL TO PROVIDE PERSONAL DATA</span>
                    <p>Where we need to collect personal data by law, or under the terms of a contract we have with you
                        and you fail to provide that data when requested, we may not be able to perform the contract we
                        have or are trying to enter into with you (for example, to provide you with goods or services).
                        In this case, we may have to cancel a product or service you have with us but we will notify you
                        if this is the case at the time.</p>
                </div>
            </div>
                <div className={'entity_rules first_block_of_terms'}>
                    <h2>3. HOW IS YOUR PERSONAL DATA COLLECTED?</h2>
                    <p>We use different methods to collect data from and about you including through:
                    </p>
                    <div className={'data_collect_info'}>
                        <div/>
                        <span><p><span>Direct interactions.</span> You may give us your Identity Data, Contact Data,
                            Transaction Data, Profile Data,
                            Financial Data and Marketing and Communications Data by using our website, filling in forms
                            or by corresponding with us by post,
                            phone, email or otherwise. This includes personal data you provide when you:</p>
                        <p>-purchase a product or service (including gift cards) through our website;</p>
                        <p>-create an account on our website;</p>
                        <p>-request marketing to be sent to you;</p>
                        <p>-use features of our website such as favoriting and following, or messaging our Sellers;</p>
                        <p>-apply to become a Seller;</p>
                        <p>-create or update your Artsted store;</p>
                        <p>-enter a competition, promotion or survey;</p>
                        <p>-give us some feedback;</p></span>
                        <div/>
                        <p><span>Automated technologies or interactions.</span> As you interact with our website, we may
                            automatically collect Usage Data and Technical Data about your equipment, browsing actions
                            and patterns. We collect this personal data by using cookies, server logs and other similar
                            technologies. Please see our Cookie Policy for further details</p>

                    </div>
                </div>
                <div className={'entity_rules first_block_of_terms'}>
                    <h2>4.THIRD PARTY SOURCES OF DATA AND SHARING DATA</h2>
                    <p>We also collect and share data as described below:</p>
                    <div className={'data_collect_info'}>
                        <div/>
                        <p><span>Sellers</span> (Artists) - when you place an order for an artwork, we share your
                            information with the relevant seller(s) on our marketplace ("Seller") so that they can
                            process and deliver your order.</p>
                        <div/>
                        <p><span>Cloud storage providers</span> - we use cloud computing platforms that securely store
                            all of our data, including customer details.</p>
                        <div/>
                        <p><span>Email service providers</span> - in order to send you marketing content and
                            transactional emails, we share your details with our email service providers.</p>
                        <div/>
                        <p><span>Social Media Platforms</span> - We may make your name and email address available to
                            companies in order to advertise and market our services to you through other platforms on
                            the internet (e.g. Facebook). See the 'Marketing' section of this Privacy Policy above.</p>
                        <div/>
                        <p><span>Analytics tools</span> - we use analytics tools to track the way that users interact
                            with our website.</p>
                        <div/>
                        <p><span>Profiling tools </span> -we use profiling tools to understand how you engage with our
                            website and show you content we think will be most relevant to you, based on our
                            understanding of your interests and preferences.</p>
                        <div/>
                        <p><span>Payment providers</span> - in order to facilitate any payments made on our site, we
                            facilitate the sharing of your Financial Data with payment providers.</p>
                        <div/>
                        <p><span>Delivery providers</span> - in order to package and mail your orders to you, it is
                            necessary to share your information with delivery providers.</p>
                        <div/>
                        <p><span>Marketing and insights providers</span> - marketing and insights tools allow us to
                            understand our customers better so that we may provide you with the best possible website,
                            products and customer service experience. We may share certain information about our
                            customers to facilitate this process.</p>
                        <div/>
                        <p><span>Customer service platforms</span> - when you interact with our customer service team,
                            your details are shared with our customer service platform providers.</p>
                        <div/>
                        <p><span>Feedback forms</span> - when you make a purchase, we engage a third party to send out
                            feedback forms on our behalf.</p>
                        <div/>
                        <p><span>Essential Service Providers</span> - Sometimes, other businesses give us data about you
                            which we may need for our legitimate interests of conducting business with you and on
                            occasion they are necessary to perform our contract with you. It usually comprises Financial
                            Data or Transaction Data. This happens when we link through to third party payment
                            providers. They tell us that you have paid for your products and, where relevant and/or
                            necessary they will provide us with your Contact Data and Transaction Data. We also might
                            engage third party contractors to provide us with technical or delivery services that are
                            related to your account with us.</p>
                        <div/>
                        <p><span>Professional Advisers and Investors</span> - We may also share your data with
                            professional advisers such as our lawyers and insurers to manage risks and legal claims,
                            and/or as part of our relationship and obligations to our investor organisations. This is in
                            our legitimate interests.</p>
                        <div/>
                        <p><span>Group</span> - It is possible we could sell our business to a third party or
                            re-organise our business or become insolvent. In that scenario, our database of customers is
                            one of the biggest parts of that business and so we would need to share it with the
                            third-party buyer and their advisers. This is in the legitimate interests of selling our
                            business. We may also expand our group of companies and in this scenario we may share your
                            data within our group in order to improve our products and services and because of some of
                            our internal support services may be shared across the group. This is in our legitimate
                            interests of cost efficiency and growing our business. Where this occurs, we will post a
                            link to all group companies and their locations in this Privacy Policy and they will use it
                            in the ways set out in this Policy.</p>
                        <div/>
                        <p><span>Law Enforcement/Legal Compliance</span> - We will cooperate with all third parties to
                            enforce their intellectual property or other rights. We will also cooperate with law
                            enforcement requests from within or outside your country of residence. This may include
                            disclosing your personal information to government or law enforcement agencies, or private
                            parties, when we have a good faith belief that disclosure is required by law or when we, in
                            our discretion, believe that disclosure is necessary to protect our legal rights, or those
                            of third parties and/or to comply with a judicial proceeding, court order, fraud reduction
                            or legal process served on us. In such cases, we may raise or waive any legal objection or
                            right available to us. These uses of your data are in our legitimate interests of protecting
                            our business security. We may also use your data and share it with the recipients listed in
                            this Privacy Policy for the purpose of complying with our legal obligations.</p>
                    </div>
                </div>
                <div className={'entity_rules first_block_of_terms'}>
                    <h2>5. HOW WE USE YOUR PERSONAL DATA</h2>
                    <p>We will only use your personal data when the law allows us to. Most commonly, we will use your
                        personal data in the following circumstances:</p>
                    <div className={'data_collect_info'}>
                        <div/>
                        <p>Where we need to perform the contract we are about to enter into or have entered into with
                            you, either directly or as a commercial agent of our Sellers.</p>
                        <div/>
                        <p>Where it is necessary for our legitimate interests (or those of a third party) and your
                            interests and fundamental rights do not override those interests.</p>
                        <div/>
                        <p>Where we need to comply with a legal or regulatory obligation.</p>
                    </div>
                    <div className={"products_services_terms"}>
                        <p>Generally we do not rely on consent as a legal basis for processing your personal data other
                            than in relation to certain direct marketing (and you can see where that is the case in the
                            table below). You have the right to withdraw consent to marketing at any time by visiting
                            our preferences page.</p>
                        <span>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</span>
                        <p>We have set out below, in a table format, a description of all the ways we plan to use your
                            personal data, and which of the legal bases we rely on to do so. We have also identified
                            what our legitimate interests are where appropriate.</p>
                        <p>Note that we may process your personalІ data for more than one lawful ground depending on the
                            specific purpose for which we are using your data. Please Contact us at
                            legal@artisfact.co.uk if you need details about the specific legal ground we are relying on
                            to process your personal data where more than one ground has been set out in the table
                            below.</p>
                        <div className={'list_of_activity_lawful'}>
                            <div className={"title_of_list_purposes"}>
                                <span>Purpose/Activity</span>
                                <span>Type of data</span>
                                <span>Lawful basis for processing including basis of legitimate interest</span>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To register you as a new customer</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                    </ul>
                                </div>
                                <p>Performance of a contract with you.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To register you as a new seller</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Seller Application</li>
                                        <li>(e)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Performance of a contract with you.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <div className={'list_with_variable_words '}>
                                    <p>To process and deliver your order including:</p>
                                    <p>(a) Manage payments, fees and charges</p>
                                    <p>(b) Manage your queries through our Customer Service team – this may include
                                        recording calls to our teams</p>
                                </div>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Financial</li>
                                        <li>(d)Transaction</li>
                                        <li>(e)Marketing and Communications</li>
                                        <li>(f)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Performance of a contract with you. We may also use some of the data related to your
                                    queries for our legitimate interests of ensuring our customer service quality
                                    standards are met.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To collect and recover money owed to us in respect of your order or a payment of
                                    commission due to us if you are a Seller</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Financial</li>
                                        <li>(d)Transaction</li>
                                        <li>(e)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests (to recover debts due to us).</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To carry out fraud assessments</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Financial</li>
                                        <li>(d)Transaction</li>
                                        <li>(e)Technical</li>
                                        <li>(f)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests of ensuring payments are not fraudulent.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To process your purchase of a gift card from us</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Financial</li>
                                        <li>(d)Transaction</li>
                                    </ul>
                                </div>
                                <p>Performance of a contract with you.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To notify you in relation to our legal obligations and documents, including changes
                                    to our terms or Privacy Policy</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests of ensuring our customers are updated on these
                                    changes.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To help us improve our offering to our customers, including asking you to leave a
                                    review or take a survey, or provide customer insights</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Marketing and Communications</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests (to study how customers use our
                                    products/services, to improve our offering to our customers, to develop them and
                                    grow our business)</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To enable you to partake in a prize draw, competition or complete a survey</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Usage</li>
                                        <li>(e)Marketing and Communications</li>
                                    </ul>
                                </div>
                                <p>Performance of a contract with you to fulfil the promotion and run the
                                    competition/prize draw. We may also subsequently use your entries for the legitimate
                                    interests of understanding our customer base more effectively</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To administer and protect our business and this website (including troubleshooting,
                                    data analysis, testing, system maintenance, support, reporting and hosting of
                                    data)</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Technical</li>
                                    </ul>
                                </div>
                                <p>(a)Necessary for our legitimate interests (for running our business, provision of
                                    administration and IT services, network security, to prevent fraud and in the
                                    context of a business reorganisation or group restructuring exercise).
                                    (b) Necessary to comply with a legal obligation.
                                </p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To deliver relevant website content and advertisements to you and measure or
                                    understand the effectiveness of the advertising we serve to you</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Usage</li>
                                        <li>(e)Marketing and Communications</li>
                                        <li>(f)Technical</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests (to study how customers use our
                                    products/services, to develop them, to grow our business and to inform our marketing
                                    strategy). Please note that where cookies are used for this purpose, this is covered
                                    separately by our Cookies Policy.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To deliver direct marketing to you</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Profile</li>
                                        <li>(d)Usage</li>
                                        <li>(e)Marketing and Communications</li>
                                        <li>(f)Technical</li>
                                        <li>(g)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Generally, necessary for our legitimate interests (to promote our business or our
                                    artists). Where you have been asked to opt in, or the law requires consent for
                                    electronic direct marketing, our lawful basis is consent.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To use data analytics to improve our website, products/services, marketing, customer
                                    relationships and experiences</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Technical</li>
                                        <li>(b)Usage</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests (to define types of customers for our products
                                    and services, to keep our website updated and relevant, to develop our business and
                                    to inform our marketing strategy). Please note that where cookies are used for this
                                    purpose, this is covered separately by our Cookies Policy.</p>
                            </div>
                            <div className={"title_of_list_purposes"}>
                                <p>To make suggestions and recommendations to you about goods or services that may be of
                                    interest to you</p>
                                <div className={'list_with_variable_words'}>
                                    <ul>
                                        <li>(a)Identity</li>
                                        <li>(b)Contact</li>
                                        <li>(c)Technical</li>
                                        <li>(d)Usage</li>
                                        <li>(e)Profile</li>
                                        <li>(e)Seller Profile</li>
                                    </ul>
                                </div>
                                <p>Necessary for our legitimate interests (to develop our products/services and grow our
                                    business).</p>
                            </div>
                        </div>
                    </div>
                    <div className={"products_services_terms"}>
                        <p>We do not conduct any automated decision making. We may on occasion profile our customers for
                            the purposes of targeting marketing at them and where this is done, this is undertaken for
                            our legitimate interests of ensuring our marketing is relevant to its audience. For example,
                            we may classify an appropriate audience for a promotion by what products on our website they
                            have previously looked at or expressed an interest in. We do not conduct any online
                            behavioural tracking.</p>
                    </div>
                    <div className={"products_services_terms"}>
                        <span>MARKETING AND PROMOTIONS</span>
                        <p>We strive to provide you with choices regarding certain personal data uses, particularly
                            around marketing and advertising. Please see your preferences page on the website for more
                            information and to save your choices.</p>
                        <p>We will only ever send marketing in accordance with your preferences that we have recorded
                            for you.</p>
                        <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what
                            we think you may want or need, or what may be of interest to you. This is how we decide
                            which products, services and offers may be relevant for you. We only use the data you
                            provide to us directly for this purpose along with the Aggregated Data provided to us by our
                            analytics partners and we do not track what other websites you may visit after visiting our
                            site, though in common with most websites, we may register the site which referred you to
                            our site (e.g. a search engine).</p>
                        <p>You will receive marketing communications from us if you have requested information from us
                            or purchased goods or services from us or Sellers we represent or if you provided us with
                            your details when you entered a competition, registered for an account or promotion and, in
                            each case, you have not opted out of receiving that marketing.</p>
                        <p>We will always offer a way to opt out of receiving marketing communications in every
                            marketing communication sent to you. We may on occasion send out postal marketing for the
                            purpose of growing our sales which is in our legitimate interests and in this case we will
                            rely on you to let us know if you do not want to receive this by opting out of marketing
                            (see Opting out below).</p>
                    </div>
                    <div className={"products_services_terms"}>
                        <span>ADVERTISING ON OTHER SITES</span>
                        <p>
                            There are lots of different places you may see adverts for Artsted, and these may not be
                            using your personal data; we buy advertising space in the real world and on websites and
                            social media. If you see Artsted adverts on websites and in social media, these may not be
                            directed specifically at you and may be there because we have bid for the space or are using
                            the providers services to show our adverts to 'lookalike' audiences.
                        </p>
                        <p>
                            Our website uses cookies/advertising IDs for the purpose of advertising. This enables us to
                            show our advertisements to visitors who are interested in our products on partner websites
                            and apps. Re-targeting technologies use your cookies or advertising IDs and display
                            advertisements based on your past browsing behaviour. To read more and/or oppose to their
                            services, please refer to their privacy policy listed below:
                        </p>
                        <div className={'data_collect_info'}>
                            <div/>
                            <p>Facebook Ads: Privacy Policy | Opt-out of personalised ad targeting</p>
                            <div/>
                            <p>Google Ads: Privacy Policy | Opt-out of personalised ad targeting</p>
                        </div>
                        <div className={'products_services_terms'}>
                            <p>
                                We may also share certain data with third party social media platforms in order to show
                                you targeted ads when you visit them. We may do this by providing these platforms with
                                your email address to create 'audiences' of users fitting within a certain
                                demographic/category so that we can target our marketing. Please check the social media
                                platforms' terms for more details of these services. This is in our legitimate interests
                                of sending you direct marketing. See 'Opting out' below for details of how you can
                                adjust your marketing preferences.
                            </p>
                        </div>
                        <div className={'products_services_terms'}>
                            <span>OPTING OUT</span>
                            <p>
                                You can ask us to stop sending you marketing messages at any time by logging into your
                                preferences page, by following the opt-out links on any marketing message sent to you or
                                by contacting us at any time.
                            </p>
                            <p>
                                If you opt out of receiving email marketing from us, we will no longer share your email
                                address with social media platforms (see 'External Third Parties' below). However, you
                                may continue to see our ads through them, due to their general demographic targeting.
                                Please check the social media platforms for more detail of how to opt out from seeing
                                these ads.
                            </p>
                            <p>
                                Where you opt out of receiving these marketing messages, this will not apply to personal
                                data provided to us as a result of a product/service purchase from us or one of the
                                Sellers we represent.
                            </p>
                        </div>
                        <div className={"products_services_terms"}>
                            <span>COOKIES</span>
                            <p>You can set your browser to refuse all or some browser cookies, or to alert you when
                                websites set or access cookies. If you disable or refuse cookies, please note that some
                                parts of this website may become inaccessible or not function properly.</p>
                        </div>
                        <div className={"products_services_terms"}>
                            <span>CHANGE OF PURPOSE</span>
                            <p>We will only use your personal data for the purposes for which we collected it, unless we
                                reasonably consider that we need to use it for another reason and that reason is
                                compatible with the original purpose. If you wish to get an explanation as to how the
                                processing for the new purpose is compatible with the original purpose, please contact
                                us.</p>
                            <p>If we need to use your personal data for an unrelated purpose, we will notify you and we
                                will explain the legal basis which allows us to do so.
                            </p>
                            <p>Please note that we may process your personal data without your knowledge or consent, in
                                compliance with the above rules, where this is required or permitted by law.</p>
                        </div>
                    </div>
                </div>
                    <div className={"entity_rules first_block_of_terms"}>
                        <h2>6. INTERNATIONAL TRANSFERS</h2>
                        <p>Some of our external third parties are based outside the European Economic Area ( EEA) so
                            their processing of your personal data will involve a transfer of data outside the
                            EEA.</p>
                        <p>Whenever we transfer your personal data out of the EEA, we ensure a similar degree of
                            protection is afforded to it in accordance with data protection laws. Some examples of
                            how we might do this are:</p>
                        <div className={'data_collect_info'}>
                            <div/>
                            <p>The transfer of your personal data is to countries that have been deemed to provide
                                an adequate level of protection for personal data by the European Commission. For
                                further details, see European Commission: Adequacy of the protection of personal
                                data in non-EU countries.</p>
                            <div/>
                            <p>The transfer uses specific contracts approved by the European Commission which give
                                personal data the same protection it has in Europe. For further details, see
                                European Commission: Model contracts for the transfer of personal data to third
                                countries .</p>
                            <div/>
                            <p>Where we use providers based in the US, the transfer of your personal data is to a
                                provider with Privacy Shield certification. For further details, see European
                                Commission: EU-US Privacy Shield.</p>
                        </div>
                        <p>Please Contact us if you want further information on the specific mechanism used by us
                            when transferring your personal data out of the EEA.
                        </p>
                    </div>
                    <div className={'entity_rules first_block_of_terms'}>
                        <h2>7. DATA SECURITY</h2>
                        <div className={"products_services_terms"}>
                            <p>We have put in place appropriate security measures to prevent your personal data from
                                being accidentally lost, used or accessed in an unauthorised way, altered or disclosed.
                                In addition, we limit access to your personal data to those employees, agents,
                                contractors and other third parties who have a business need to know. They will only
                                process your personal data on our instructions and they are subject to a duty of
                                confidentiality.</p>
                            <p>We have put in place procedures to deal with any suspected personal data breach and will
                                notify you and any applicable regulator of a breach where we are legally required to do
                                so.</p>
                            <p>You acknowledge that the Internet is not a completely secure medium for communication
                                and, accordingly, we cannot guarantee the security of any information you send to us (or
                                we send to you) via the Internet. We are not responsible for any damages which you, or
                                others, may suffer as a result of the loss of confidentiality of such information</p>
                            <p>We require all third parties to respect the security of your personal data and to treat
                                it in accordance with the law and they may only use your data for the purposes we
                                specify in our contract with them. We will always work with them to protect your
                                privacy.</p>
                        </div>
                    </div>
                    <div className={'entity_rules first_block_of_terms'}>
                        <h2>8. DATA RETENTION</h2>
                        <div className={"products_services_terms"}>
                            <span>HOW LONG WILL YOU USE MY PERSONAL DATA FOR?</span>
                            <p>We will only retain your personal data for as long as necessary to fulfil the purposes we
                                collected it for, including for the purposes of satisfying any legal, accounting, or
                                reporting requirements</p>
                            <p>To determine the appropriate retention period for personal data, we consider the amount,
                                nature, and sensitivity of the personal data, the potential risk of harm from
                                unauthorised use or disclosure of your personal data, the purposes for which we process
                                your personal data and whether we can achieve those purposes through other means, and
                                the applicable legal requirements. For example, details of your orders will be kept for
                                as long as we need to retain that data to comply with our legal and regulatory
                                requirements. This is generally 7 years unless the law prescribes a longer period.</p>
                            <p>In some circumstances you can ask us to delete your data: see Request erasure in Your
                                Legal Rights section below for further information</p>
                            <p>In some circumstances we may anonymise your personal data (so that it can no longer be
                                associated with you) for research or statistical purposes in which case we may use this
                                information indefinitely without further notice to you.</p>
                        </div>
                    </div>
                    <div className={'entity_rules first_block_of_terms'}>
                        <h2>9. YOUR LEGAL RIGHTS</h2>
                        <div className={"products_services_terms"}>
                            <p>Under certain circumstances, you have rights under UK data protection laws in relation to
                                your personal data. You have the right to:</p>
                            <p><span>Request access</span>to your personal data (commonly known as a "data subject
                                access request"). This enables you to receive a copy of the personal data we hold about
                                you and to check that we are lawfully processing it.</p>
                            <p><span>Request correction</span>of the personal data that we hold about you. This enables
                                you to have any incomplete or inaccurate data we hold about you corrected, though we may
                                need to verify the accuracy of the new data you provide to us.</p>
                            <p><span>Request erasure</span>of your personal data. This enables you to ask us to delete
                                or remove personal data where there is no good reason for us continuing to process it.
                                You also have the right to ask us to delete or remove your personal data where you have
                                successfully exercised your right to object to processing (see below), where we may have
                                processed your information unlawfully or where we are required to erase your personal
                                data to comply with local law. Note, however, that we may not always be able to comply
                                with your request of erasure for specific legal reasons which will be notified to you,
                                if applicable, at the time of your request.</p>
                            <p><span>Object to processing</span> of your personal data where we are relying on a
                                legitimate interest (or those of a third party) and there is something about your
                                particular situation which makes you want to object to processing on this ground as you
                                feel it impacts on your fundamental rights and freedoms. You also have the right to
                                object where we are processing your personal data for direct marketing purposes. In some
                                cases, we may demonstrate that we have compelling legitimate grounds to process your
                                information which override your rights and freedoms.</p>
                            <p><span>Request restriction of processing</span>of your personal data. This enables you to
                                ask us to suspend the processing of your personal data in the following scenarios: (a)
                                if you want us to establish the data's accuracy; (b) where our use of the data is
                                unlawful but you do not want us to erase it; (c) where you need us to hold the data even
                                if we no longer require it as you need it to establish, exercise or defend legal claims;
                                or (d) you have objected to our use of your data but we need to verify whether we have
                                overriding legitimate grounds to use it.</p>
                            <p><span>Request the transfer</span>of your personal data to you or to a third party. We
                                will provide to you, or a third party you have chosen, your personal data in a
                                structured, commonly used, machine-readable format. Note that this right only applies to
                                automated information which you initially provided consent for us to use or where we
                                used the information to perform a contract with you.</p>
                            <p><span>Withdraw consent at any time</span>where we are relying on consent to process your
                                personal data. However, this will not affect the lawfulness of any processing carried
                                out before you withdraw your consent. If you withdraw your consent, we may not be able
                                to provide certain products or services to you. We will advise you if this is the case
                                at the time you withdraw your consent.</p>
                            <p>If you wish to exercise any of the rights set out below, please Contact us, marking your
                                query for the attention of the DPL</p>
                        </div>
                        <div className={'products_services_terms'}>
                            <span>NO FEE USUALLY REQUIRED</span>
                            <p>You will not have to pay a fee to access your personal data (or to exercise any of the
                                other rights). However, we may charge a reasonable fee if your request is clearly
                                unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
                                request in these circumstances</p>
                        </div>
                        <div className={'products_services_terms'}>
                            <span>WHAT WE MAY NEED FROM YOU</span>
                            <p>We may need to request specific information from you to help us confirm your identity and
                                ensure your right to access your personal data (or to exercise any of your other
                                rights). This is a security measure to ensure that personal data is not disclosed to any
                                person who has no right to receive it. We may also contact you to ask you for further
                                information in relation to your request to speed up our response.</p>
                        </div>
                        <div className={'products_services_terms'}>
                            <span>TIME LIMIT TO RESPOND</span>
                            <p>We try to respond to all legitimate requests within one month. Occasionally it may take
                                us longer than a month if your request is particularly complex or you have made a number
                                of requests. In this case, we will notify you and keep you updated.</p>
                        </div>
                        <div className={'products_services_terms'}>
                            <span>COMPLAINTS</span>
                            <p>You have the right to make a complaint at any time to the Information Commissioner's
                                Office (ICO), the UK supervisory authority for data protection issues ( www.ico.org.uk).
                                In the UK, please read: https://ico.org.uk/for-the-public/raising-concerns/ for details
                                of how to do this. We would, however, appreciate the chance to deal with your concerns
                                before you approach the ICO so please contact us in the first instance.</p>
                        </div>
                    </div>
            </div>*/
    )
}
