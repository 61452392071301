import React, {useState, useEffect} from "react";
import {BrowserRouter as Router, Route, Switch, Link, useLocation} from "react-router-dom";
import '../main_global.css'
import './global_admin_style.css'
import artsted_logo from '../assets/img/new_landing/artsted_logo.svg'
import log_out from '../assets/img/admin/log_out.svg'
import {Navigation} from "../routers/navigation";
import useResize from "../Constants/useResize";
import API from "../api/API";
import Keys from "../Constants/helper";

const AdminRouters = React.memo(() => {
    let location = useLocation();
    const {innerWidth} = useResize();
    const [activeNavigationPage, setActiveNavigationPage] = useState(location.pathname.split('/'))

    useEffect(() => {
        let url = location.pathname.split('/');
        setActiveNavigationPage(url)
    }, [location.pathname])

    const OldSchoolMenuLink = ({label, pathname, path, icon}) => {
        return label ? (
            <div
                className={`wrapper_admin_panel_navigation_padding ${activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'wrapper_admin_panel_navigation_padding_active' : ''}`}>
                <Link key={pathname}
                      className={activeNavigationPage === pathname || activeNavigationPage.includes(pathname) ? 'admin_bar_active_link' : null}
                      onClick={() => {
                          if (pathname === 'blog') {
                              window.open(`https://webflow.com/dashboard/sites/blog-artsted-com/general`)
                          } else if (pathname === 'mailing_list') {
                              window.open(`https://sendgrid.com/`)
                          } else {
                              setActiveNavigationPage(pathname)
                              window.scrollTo(0, 0)
                          }
                      }}
                      to={path}>
                    {icon}
                    <span>{label}</span>
                </Link>
            </div>
        ) : null
    };

    const handleLogOut = () => {
        API(Keys.JWT_TOKEN_ADMIN).post('/admin/logout')
            .then(() => {
                localStorage.removeItem(Keys.JWT_TOKEN_ADMIN)
                window.location.href = '/admin';
            });
    };

    function SwitcherNavBar({routes}) {
        return (
            <Switch>
                {routes.map((route, index) => (
                    <Route path={route.path}
                           exact={true}
                           component={route.main}
                           key={index}/>
                ))}
            </Switch>
        )
    }

    const renderSwitchRoute = React.useCallback(() => (
        <div className={'wrapper_admin_panel_content'}>
            <SwitcherNavBar routes={Navigation.adminPanelNavigation}/>
        </div>
    ), [])

    return (
        <Router>
            {innerWidth >= 1100
                ? <>
                    <div className={'wrapper_admin_panel_navigation'}>
                        <div>
                            <img className={'wrapper_admin_panel_navigation_logo'} src={artsted_logo}
                                 alt="artsted logo"/>
                            <div className={'wrapper_admin_panel_navigation_content'}>
                                {Navigation.adminPanelNavigation.map((item, index) => {
                                    return (
                                        <OldSchoolMenuLink {...item} key={index}/>
                                    )
                                })}
                            </div>
                        </div>
                        <div onClick={() => handleLogOut()} className={'wrapper_admin_panel_log_out'}>
                            <img src={log_out} alt="log out"/>
                            <span>Log Out</span>
                        </div>
                    </div>
                    {renderSwitchRoute()}
                </>
                : <div className={'wrapper_admin_panel_content_responsive'}>
                    <div className={'admin_panel_content_responsive'}>
                        <span>Admin panel is not available on this resolution</span>
                        <span>Please use the device with width at least 1100px</span>
                    </div>
                </div>}
        </Router>
    );
});
export default AdminRouters;
