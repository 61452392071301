import React, {useState} from 'react'
import './styles/support_tickets.css'
import API from "../../api/API";
import Keys from "../../Constants/helper";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import SupportComponent from "../Components/SupportComponent";

export default function SupportTickets() {

    const [activeModal, setActiveModal] = useState({
        value: null,
        data: null
    })

    const getTicketsData = (item) => {
        bodyOverflow(true)
        API(Keys.JWT_TOKEN_ADMIN).get(`admin/contact_us_ticket?id=${item.id}`)
            .then(res => {
                if (res.data) {
                    setActiveModal(prevState => ({
                        value: prevState.value !== item.id ? item.id : null,
                        data: res.data
                    }))
                }
            })
    }

    return (
        <SupportComponent activeModal={activeModal}
                          requestDataTab={'new'}
                          pageTitle={'Support Tickets'}
                          setActiveModal={val => setActiveModal(val)}
                          getTicketsData={getTicketsData}
                          requestHandle={'contact_us_tickets'}/>
    )
}