import React from 'react';
import SEO from "../../Components/SEO";

export default function Pricing() {
    return (
        <div className={'faq_section_body pricing_padding'}>
            <SEO title="Pricing: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Pricing: Buy art online Artsted"/>
            <h1>Pricing</h1>
            <p>Artsted provides a visual representation of a user's career over time, as reported by the self-audit
                tools, that are subject to thorough due diligence. The visual representation of the "Performance Chart"
                can be found in the Artist's public profile. The updates of "CV", will be connected to the fluctuations
                in Personal Coefficient entries, analyzed by the proprietary algorithm. This means that the prices on
                already sold items will be updated according to the fluctuations by x% , which can be viewed in
                Investors profile in "My portfolio" section.</p>
            <div className={"pricing_wrapper_faq"}>
                <span>Pricing</span>
                <p>The price formation on Artsted is connected to the analytics of the proprietary “Artist Coefficient”
                    algorithm. The following metrics are comprised of the indices derived from:</p>
            </div>
            <div className={"faq_page_list"}>
                <div/>
                <span>Exhibition participation</span>
                <div/>
                <span>Publications</span>
                <div/>
                <span>Art prizes</span>
                <div/>
                <span>Museum acquisitions</span>
                <div/>
                <span>Residences</span>
                <div/>
                <span>Fine Art Degrees</span>
                <div/>
                <span>Past sales records</span>
                <div/>
                <span>Media following</span>
            </div>
            <p>By subscribing to Artifact Limited platform and using its services, the artist and the Investor declare
                to have confirmed their agreement to the present Price formation strategy, The Artist Coefficient
                Formation, and publication of Artist’s Performance Analytics in the public domain.</p>

        </div>
    )
}