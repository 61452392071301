import React, {useState} from "react";
import API from "../../api/API";
import ModalWindow from "../ModalWindow";
import MainButton from "../CustomComponents/MainButton";
import otherConst from "../../Constants/otherConst";
import OutsideClickHandler from "../../Constants/detectOutsideClick";

const CvEntriesSectionPreview = props => {
    const {
        data: {title, entriesShow, activeList, sectionName},
        setData, changeContinuePopup, innerWidth
    } = props;
    const [deleteModal, setDeleteModal] = useState(false)
    const [showFileToolTip, setShowFileToolTip] = useState(false)
    const showEntriesForm = () => {
        setData(prevState => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName],
                entriesShow: !prevState[sectionName].entriesShow
            }
        }))
    }

    const monthReplace = month => {
        let activeMonth;
        otherConst.months.forEach((m, index) => {
            if (index + 1 === Number(month.prev)) {
                activeMonth = m.prev
            }
        })
        return activeMonth
    }

    const downloadToolTip = item => (
        <a href={item.proof_file} target={'_blank'} rel="noopener noreferrer nofollow"
           data-key={'download_tool_tip'}
           className={'download_tool_tip'}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                 data-key={'download_tool_tip'}
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.46124 8.65551L8.16948 10.3637V1.32723C8.16948 1.10774 8.25668 0.897242 8.41188 0.742039C8.56708 0.586837 8.77758 0.499644 8.99707 0.499644C9.21656 0.499644 9.42706 0.586837 9.58226 0.742039C9.73746 0.897242 9.82466 1.10774 9.82466 1.32723V10.3637L11.5329 8.65551C11.6097 8.57832 11.7009 8.51703 11.8014 8.47516C11.9019 8.4333 12.0097 8.41167 12.1186 8.41152C12.2274 8.41137 12.3353 8.43271 12.4359 8.4743C12.5365 8.51589 12.6279 8.57693 12.7049 8.65391C12.7819 8.7309 12.8429 8.82231 12.8845 8.92292C12.9261 9.02353 12.9474 9.13136 12.9473 9.24023C12.9471 9.3491 12.9255 9.45687 12.8836 9.55737C12.8418 9.65787 12.7805 9.74911 12.7033 9.82589L9.58228 12.9469C9.50543 13.0238 9.41419 13.0847 9.31378 13.1263C9.21337 13.1679 9.10575 13.1893 8.99707 13.1893C8.88839 13.1893 8.78077 13.1679 8.68036 13.1263C8.57995 13.0847 8.48871 13.0238 8.41186 12.9469L5.29086 9.82589C5.21367 9.74911 5.15239 9.65787 5.11052 9.55737C5.06865 9.45687 5.04702 9.3491 5.04688 9.24023C5.04673 9.13136 5.06806 9.02353 5.10966 8.92292C5.15125 8.82231 5.21229 8.7309 5.28927 8.65391C5.36625 8.57693 5.45767 8.51589 5.55828 8.4743C5.65889 8.43271 5.76672 8.41137 5.87559 8.41152C5.98446 8.41167 6.09223 8.4333 6.19272 8.47516C6.29322 8.51703 6.38447 8.57832 6.46124 8.65551Z"
                    data-key={'download_tool_tip'}
                    fill="white" stroke="black" strokeWidth="0.5"/>
                <path
                    data-key={'download_tool_tip'}
                    d="M16.1724 8.67273C15.9529 8.67273 15.7424 8.75992 15.5872 8.91512C15.432 9.07033 15.3448 9.28083 15.3448 9.50032V15.8451H2.65517V9.50032C2.65517 9.28083 2.56798 9.07033 2.41278 8.91512C2.25758 8.75992 2.04708 8.67273 1.82759 8.67273C1.6081 8.67273 1.3976 8.75992 1.24239 8.91512C1.08719 9.07033 1 9.28083 1 9.50032V16.121C1 16.4868 1.14532 16.8377 1.40399 17.0963C1.66266 17.355 2.01349 17.5003 2.37931 17.5003H15.6207C15.9865 17.5003 16.3373 17.355 16.596 17.0963C16.8547 16.8377 17 16.4868 17 16.121V9.50032C17 9.28083 16.9128 9.07033 16.7576 8.91512C16.6024 8.75992 16.3919 8.67273 16.1724 8.67273Z"
                    fill="white" stroke="black" strokeWidth="0.5"/>
            </svg>
            <span data-key={'download_tool_tip'}>Download</span>
        </a>
    )

    const dateReplace = date => {
        let activeDate = date.split('/');
        let remYear = activeDate[0];
        activeDate[1] = remYear
        activeDate[0] = monthReplace(date.split('/')[1])
        return activeDate.join(' ')
    }

    const dateNameReplace = date => {
        if (date.includes('-')) {
            let activeRangeDate = date.split('-');
            return `${dateReplace(activeRangeDate[0])} - ${dateReplace(activeRangeDate[1])}`
        } else {
            return dateReplace(date)
        }
    }

    const handleEdit = item => {
        setData(prevState => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName],
                entriesShow: true,
                edit: item.id,
                bodyFields: {
                    ...prevState[sectionName].bodyFields,
                    date: {
                        ...prevState[sectionName].bodyFields.date,
                        value: item.date
                    },
                    context_field_description: {
                        ...prevState[sectionName].bodyFields.context_field_description,
                        value: item.description
                    },
                    context_field: {
                        ...prevState[sectionName].bodyFields.context_field,
                        value: item.title
                    },
                    proof_link: {
                        ...prevState[sectionName].bodyFields.proof_link,
                        value: item.proof_link
                    },
                    proof_document: {
                        ...prevState[sectionName].bodyFields.proof_document,
                        preview: item.proof_file
                    }
                }
            }
        }))
    }

    const handleDelete = id => {
        API().delete(`/user/profile/cv?id=${id}`)
            .then(() => {
                setData(data => ({
                    ...data,
                    [sectionName]: {
                        ...data[sectionName],
                        activeList: data[sectionName].activeList.filter(i => i.id !== id)
                    }
                }))
                changeContinuePopup(`You successfully deleted entry`)
                setTimeout(() => {
                    changeContinuePopup(`You successfully deleted entry`)
                }, 2000)
            })
    }

    return (
        <div className={'entries_section_preview'}>
            {title ?
                <div className={'entries_section_preview__title'}>
                    <span>{title}</span>
                </div> :
                null}
            <div className={'entries_section_preview__list'}>
                {activeList.length ?
                    activeList.map(item => (
                        <div className={'entries_section_preview__list_wrapper'} key={item.id}>
                            <svg width="16" height="16" viewBox="0 0 16 16"
                                 fill="none"
                                 onClick={() => handleEdit(item)}
                                 className={'edit'}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6535 3.05357L12.9465 2.34657L12.4 1.80007C12.3097 1.70525 12.2011 1.62976 12.0808 1.57818C11.9605 1.5266 11.8309 1.5 11.7 1.5C11.5691 1.5 11.4395 1.5266 11.3192 1.57818C11.1989 1.62976 11.0903 1.70525 11 1.80007L2 10.8001V14.0001H5.2L14.2 5.00007C14.2948 4.9098 14.3703 4.80121 14.4219 4.68088C14.4735 4.56055 14.5001 4.43099 14.5001 4.30008C14.5001 4.16916 14.4735 4.0396 14.4219 3.91927C14.3703 3.79894 14.2948 3.69035 14.2 3.60007L13.6535 3.05357ZM4.8 13.0001H3V11.2001L11.7 2.50007L13.5 4.30008L4.8 13.0001Z"
                                    fill="black"/>
                            </svg>
                            <div className={item.proof_link ? 'wrapper_title wrapper_title_link' : 'wrapper_title'}
                                 onClick={e => {
                                     if (e.target.getAttribute('data-key') === 'disable_click') {
                                         e.preventDefault()
                                     } else if (e.target.getAttribute('data-key') === 'download_tool_tip') {
                                         window.open(item.proof_file, "_blank")
                                     } else if (item.proof_link) {
                                         window.open(item.proof_link, "_blank")
                                     }
                                 }}>
                                <span className={item.proof_link ? 'title title_link' : 'title'}>{item.title}
                                    {item.country ?
                                        <span className={'country'}>({item.country})</span>
                                        : null}
                                    {item.proof_file ?
                                        <span className={'file'}
                                              data-key={'disable_click'}>
                                            <svg width="16" height="18" viewBox="0 0 16 18" fill="none"
                                                 data-key={'disable_click'}
                                                 onClick={() => {
                                                     if (innerWidth < 768) {
                                                         if (showFileToolTip === item.id) {
                                                             setShowFileToolTip(null)
                                                         } else {
                                                             setShowFileToolTip(item.id)
                                                         }
                                                     }
                                                 }}
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    data-key={'disable_click'}
                                                    d="M14.3266 1.64717C12.097 -0.549058 8.46628 -0.549058 6.23906 1.64717L0.0615604 7.72761C0.0213238 7.76724 2.18814e-05 7.82087 2.18814e-05 7.87682C2.18814e-05 7.93278 0.0213238 7.9864 0.0615604 8.02603L0.934931 8.88634C0.974853 8.92549 1.02889 8.94747 1.08523 8.94747C1.14156 8.94747 1.1956 8.92549 1.23552 8.88634L7.41303 2.80591C8.17989 2.05051 9.20001 1.63552 10.284 1.63552C11.368 1.63552 12.3882 2.05051 13.1527 2.80591C13.9195 3.5613 14.3408 4.56615 14.3408 5.63163C14.3408 6.69944 13.9195 7.70196 13.1527 8.45735L6.85681 14.6567L5.8367 15.6616C4.88285 16.6011 3.33256 16.6011 2.37872 15.6616C1.91718 15.2069 1.66392 14.6031 1.66392 13.9596C1.66392 13.3161 1.91718 12.7123 2.37872 12.2576L8.62486 6.10725C8.78344 5.95337 8.99172 5.86711 9.21421 5.86711H9.21657C9.43906 5.86711 9.64497 5.95337 9.80119 6.10725C9.95977 6.26345 10.045 6.46862 10.045 6.68778C10.045 6.9046 9.9574 7.10977 9.80119 7.26365L4.69587 12.2879C4.65563 12.3276 4.63433 12.3812 4.63433 12.4371C4.63433 12.4931 4.65563 12.5467 4.69587 12.5864L5.56924 13.4467C5.60916 13.4858 5.6632 13.5078 5.71954 13.5078C5.77587 13.5078 5.82991 13.4858 5.86983 13.4467L10.9728 8.42005C11.4438 7.95609 11.7018 7.34059 11.7018 6.68545C11.7018 6.03031 11.4414 5.41247 10.9728 4.95084C10 3.99262 8.41894 3.99495 7.44616 4.95084L6.84025 5.55003L1.20239 11.1012C0.819738 11.4759 0.51642 11.9218 0.310022 12.4128C0.103624 12.9039 -0.00174596 13.4304 2.18814e-05 13.9619C2.18814e-05 15.0414 0.428423 16.0556 1.20239 16.818C2.00475 17.606 3.05564 18 4.10652 18C5.15741 18 6.20829 17.606 7.00829 16.818L14.3266 9.61376C15.4035 8.55061 16 7.13542 16 5.63163C16.0024 4.12551 15.4059 2.71032 14.3266 1.64717Z"
                                                    fill="rgba(0,0,0,.5)"/>
                                            </svg>
                                            {innerWidth < 768 && showFileToolTip === item.id ?
                                                <OutsideClickHandler callback={() => setShowFileToolTip(null)}>
                                                    {downloadToolTip(item)}
                                                </OutsideClickHandler>
                                                : innerWidth > 768 ?
                                                    downloadToolTip(item)
                                                    : null}

                                        </span>
                                        : null}
                                </span>
                            </div>
                            <span className={'description'}>{item.description}</span>
                            <span className={'date'}>{dateNameReplace(item.date)}</span>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                 className={'delete'}
                                 onClick={() => setDeleteModal(item.id)}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L13.7296 13.7296M1 13.7296L13.7296 1" stroke="black" strokeWidth="2"/>
                            </svg>
                        </div>
                    ))
                    : <span className={'empty_list'}>There are no entries yet</span>}
                <div className={'add_entry'} onClick={showEntriesForm}>
                    {/*todo: Expected number*/}
                    {!entriesShow ?
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 20H29.0024M20.0012 29.0012V10.9988"
                                  stroke="black" strokeWidth="2"/>
                            <rect x="1" y="1" width="38" height="38" stroke="black" strokeWidth="2"/>
                        </svg>
                        :
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 20H29.0024" stroke="black" strokeWidth="2"/>
                            <rect x="1" y="1" width="38" height="38" stroke="black" strokeWidth="2"/>
                        </svg>}
                    <span className={'text'}>{entriesShow ? 'Hide Entry' : 'Add new entry'}</span>
                </div>
            </div>


            {deleteModal ?
                <div key={3} className={'new_modal_wrapper'}>
                    <ModalWindow setActiveModal={() => {
                        setDeleteModal(null)
                    }}>
                        <h4>Delete this entry?</h4>
                        <p>Are you sure you want to delete? This action can't be undone.</p>
                        <div className={'modal-window_buttons'}>
                            <MainButton type={'button'}
                                        style={{background: '#000', color: '#fff'}}
                                        className={'button_black'}
                                        text={'Cancel'}
                                        onClick={() => {
                                            setDeleteModal(null)
                                        }}
                            />
                            <MainButton type={'button'}
                                        className={'button_black'}
                                        text={'Delete'}
                                        onClick={() => {
                                            handleDelete(deleteModal)
                                            setDeleteModal(null)
                                        }}
                            />
                        </div>
                    </ModalWindow>
                </div> : null}
        </div>
    )
}

export default CvEntriesSectionPreview
