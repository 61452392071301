import React from 'react'

const LegalRights = () => {
    return (
        <div>
            <h3 className={'terms_item_title'}>9. YOUR LEGAL RIGHTS</h3>
            <div className={'privacy_policy_item_container'}>
                <span>
                    Under certain circumstances, you have rights under UK data protection laws in relation to
                                your personal data. You have the right to:
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Request access</span>
                <span>
                    to your personal data (commonly known as a "data subject
                                access request"). This enables you to receive a copy of the personal data we hold about
                                you and to check that we are lawfully processing it.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Request correction</span>
                <span>
                    of the personal data that we hold about you. This enables
                                you to have any incomplete or inaccurate data we hold about you corrected, though we may
                                need to verify the accuracy of the new data you provide to us.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Request erasure</span>
                <span>
                    of your personal data. This enables you to ask us to delete
                                or remove personal data where there is no good reason for us continuing to process it.
                                You also have the right to ask us to delete or remove your personal data where you have
                                successfully exercised your right to object to processing (see below), where we may have
                                processed your information unlawfully or where we are required to erase your personal
                                data to comply with local law. Note, however, that we may not always be able to comply
                                with your request of erasure for specific legal reasons which will be notified to you,
                                if applicable, at the time of your request.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Object to processing</span>
                <span>
                    of your personal data where we are relying on a
                                legitimate interest (or those of a third party) and there is something about your
                                particular situation which makes you want to object to processing on this ground as you
                                feel it impacts on your fundamental rights and freedoms. You also have the right to
                                object where we are processing your personal data for direct marketing purposes. In some
                                cases, we may demonstrate that we have compelling legitimate grounds to process your
                                information which override your rights and freedoms.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Request restriction of processing</span>
                <span>
                    of your personal data. This enables you to
                                ask us to suspend the processing of your personal data in the following scenarios: (a)
                                if you want us to establish the data's accuracy; (b) where our use of the data is
                                unlawful but you do not want us to erase it; (c) where you need us to hold the data even
                                if we no longer require it as you need it to establish, exercise or defend legal claims;
                                or (d) you have objected to our use of your data but we need to verify whether we have
                                overriding legitimate grounds to use it.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Request the transfer</span>
                <span>
                    of your personal data to you or to a third party. We
                                will provide to you, or a third party you have chosen, your personal data in a
                                structured, commonly used, machine-readable format. Note that this right only applies to
                                automated information which you initially provided consent for us to use or where we
                                used the information to perform a contract with you.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Withdraw consent at any time</span>
                <span>
                    where we are relying on consent to process your
                                personal data. However, this will not affect the lawfulness of any processing carried
                                out before you withdraw your consent. If you withdraw your consent, we may not be able
                                to provide certain products or services to you. We will advise you if this is the case
                                at the time you withdraw your consent.
                    <br/>
                    If you wish to exercise any of the rights set out below, please Contact us, marking your
                                query for the attention of the DPL
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>NO FEE USUALLY REQUIRED</span>
                <span>
                    You will not have to pay a fee to access your personal data (or to exercise any of the
                                other rights). However, we may charge a reasonable fee if your request is clearly
                                unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
                                request in these circumstances
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>WHAT WE MAY NEED FROM YOU</span>
                <span>
                    We may need to request specific information from you to help us confirm your identity and
                                ensure your right to access your personal data (or to exercise any of your other
                                rights). This is a security measure to ensure that personal data is not disclosed to any
                                person who has no right to receive it. We may also contact you to ask you for further
                                information in relation to your request to speed up our response.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>TIME LIMIT TO RESPOND</span>
                <span>
                    We try to respond to all legitimate requests within one month. Occasionally it may take
                                us longer than a month if your request is particularly complex or you have made a number
                                of requests. In this case, we will notify you and keep you updated.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>COMPLAINTS</span>
                <span>
                    You have the right to make a complaint at any time to the Information Commissioner's
                                Office (ICO), the UK supervisory authority for data protection issues ( www.ico.org.uk).
                                In the UK, please read: https://ico.org.uk/for-the-public/raising-concerns/ for details
                                of how to do this. We would, however, appreciate the chance to deal with your concerns
                                before you approach the ICO so please contact us in the first instance.
                </span>
            </div>
        </div>
    )
}

export default LegalRights