import React, {useEffect, useState} from "react";
import './styles/nft-portal.css'
import MainButton from "../../Components/CustomComponents/MainButton";
import ModalWindow from "../../Components/ModalWindow";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {Link, useHistory} from "react-router-dom";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {Loader} from "../../Components/Loader";
import NewPaginationView from "../../Components/NewPaginationView";
import {getActivePage} from "../../Constants/getActivePage";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import {useDispatch, useSelector} from "react-redux";
import useBottomPopup from "../../Constants/useBottomPopup";
import API from "../../api/API";
import {setActiveUpload} from "../../main-store/auth/actions";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
//import NewUploadWork from "../../Components/ProfileComponents/NewUploadWork";
import NewInvestorPortfolio from "../NewProfile/NewInvestorPortfolio";
import NewUploadNFTWork from "../../Components/ProfileComponents/NewUploadNFTWork";
import Keys from "../../Constants/helper";

const choosePlan = [
    {
        popular: null,
        title: 'Basic',
        subtitle: 'Get to know Artsted',
        price: 'Free',
        button: {
            value: 'basic',
            text: 'Sign Up',
        },
        options: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'Upload artwork', 'Upload up to 2 NFT artworks'],
    },
    {
        popular: {
            text: 'Most popular',
            className: 'creator'
        },
        title: 'Creator',
        subtitle: 'Ideal for emerging artists',
        price: '€240',
        monthlyPrice: '€30',
        button: {
            value: 'creator',
            text: 'Get Started',
        },
        options: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'Upload artwork', 'See advanced analytics and price charts', 'Get personalised support', 'Premium promotion tools', 'Access the Non-fungible tokens creation tools', 'Receive exhibition opportunities', 'A dedicated curator', 'Community mentorship program']
    },
    {
        popular: {
            text: 'Best choice',
            className: 'analytics'
        },
        title: 'Analytics',
        subtitle: 'Ideal for collectors and researchers',
        price: '€120',
        monthlyPrice: '€15',
        button: {
            value: 'analytics',
            text: 'Get Started',
        },
        options: ['See basic statistics', 'Verify profile', 'Receive notifications and alerts', 'See advanced analytics and price charts', 'Get personalised support', 'Premium shipping and handing']
    },
];

export default function NftPortal() {
    const {activeHashUrl} = useGetLastChartHash();

    const reduxState = useSelector(state => ({
        user_data: state.auth.user_data,
        user_subscription: state.auth.user_subscription,
        active_upload: state.auth.active_upload,
        can_create: state.auth.can_create
    }));

    const [activePage, setActivePage] = useState(() => ({
        page: activeHashUrl ? activeHashUrl : 1,
        pages: null,
        total: null
    }));
    //const [deliveryAddress, setDeliveryAddress] = useState([])
    const history = useHistory();
    const uploadDispatch = useDispatch();
    const [userProfile, setUserProfile] = useState(null);
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    // const [activeArtistTab, setActiveArtistTab] = useState('classic_artwork');
    const [loading, setLoading] = useState(false);
    const [showUploadWork, setShowUploadWork] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [uploadEnd, setUploadEnd] = useState(false);
    const [activeCardPopup, setActiveCardPopup] = useState(null);
    const [activeModal, setActiveModal] = useState(null);
    const [activePopup, setActivePopup] = useState(null);
    const [activePlan, setActivePlan] = useState(null);
    const [planPopupShow, setPlanPopupShow] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [planTimeCreator, setPlanTimeCreator] = useState('Monthly')
    const [planTimeAnalytics, setPlanTimeAnalytics] = useState('Monthly')
    const userRole = useSelector(state => state?.auth?.user_data?.role);
    const [disabledButton, setDisabledButton] = useState({
        basic: false,
        creator: false,
        analytics: false,
    });

    const handleDeleteArtWork = () => {
        API().delete(`/user/artwork?id=${activeModal}`)
            .then(() => {
                setUserProfile(prevState => ({
                    ...prevState,
                    artworks: prevState.artworks.filter(i => i.id !== activeModal)
                }))
                setActiveModal(null)
                bodyOverflow(false)
            })
    }

    useEffect(() => {
        if (userRole) {
            API().get(`/user/payment/subscription`)
                .then(res => {
                    setActivePlan(res.data.subscription)
                    setDisabledButton(prevState => ({
                        ...prevState,
                        basic: !!localStorage.getItem(Keys.JWT_TOKEN),
                        [res.data.subscription !== 'basic' ? res.data.subscription : null]: res.data.subscription,
                    }))
                    setSubscriptionPlan(res.data.subscription)
                })
            if (userRole === 'investor') {
                setDisabledButton(prevState => ({
                    ...prevState,
                    creator: true,
                }))
            } else if (userRole === 'artist') {
                setDisabledButton(prevState => ({
                    ...prevState,
                    analytics: true,
                }))
            }
        }
    }, [userRole])

    useEffect(() => {
        setUploadEnd(false)
        return () => uploadDispatch(setActiveUpload(false))
    }, [uploadDispatch])

    useEffect(() => {
        if (reduxState.user_data?.role === 'artist') {
            API().get(`/user/profile/NFT?page=${activePage.page}&per_page=20`)
                .then(res => {
                    if (res.data) {
                        // console.log(res.data)
                        setUserProfile(res.data)
                        //setDeliveryAddress()
                        //console.log(deliveryAddress)
                        setActivePage(prevState => ({
                            ...prevState,
                            pages: res.data.pages,
                            total: res.data.total,
                        }))
                    }
                })
                .then(() => setLoading(true))
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [activePage.page, reduxState.user_data?.role, uploadEnd])

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl
            }))
        }
    }, [activeHashUrl])

    console.log('userProfile.artworks: ', userProfile?.artworks)

    const settingsHistoryPush = () => history.push("/profile/settings#delivery_info")
    return [
        <ChangedConfirmedPopup successText={successText}
                               key={10}
                               changingConfirmed={changingConfirmed}/>,
        reduxState?.user_data.role === 'artist'
            ? reduxState?.active_upload || showUploadWork ?
                <NewUploadNFTWork setShowUploadWork={e => setShowUploadWork(e)}
                               editMode={editMode}
                               setEditMode={e => setEditMode(e)}
                               setUploadEnd={e => setUploadEnd(e)}
                               key={6}
                               user_subscription={reduxState.user_subscription}/>
                : <div key={100}
                       className={'main_profile_wrapper'}>
                    {[
                        <div key={50} className={'artist_profile_buttons'}>
                            {planPopupShow ?
                                <div key={3} className={'new_modal_wrapper_profile_popup_width new_modal_wrapper'}>
                                    <ModalWindow activeModal={activeModal}
                                                 nestedObj
                                                 setActiveModal={val => setActiveModal(val)}>
                                        <div className={'head'}>
                                            <h4>You cannot upload NFTs anymore</h4>
                                            <svg onClick={() => {
                                                setPlanPopupShow(false)
                                            }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="40" height="40" fill="#F6F6F1"/>
                                                <path d="M12 12L28 28M12 28L28 12" stroke="black" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                        <p>Upgrade your plan to have unlimited access</p>
                                        <div className={'modal-window_buttons'}>
                                            <MainButton type={'button'}
                                                        className={'button_black'}
                                                        text={'Cancel'}
                                                        onClick={() => {
                                                            setPlanPopupShow(false)
                                                        }}
                                            />
                                            <MainButton type={'button'}
                                                        className={'button_black'}
                                                        style={{background: '#000', color: '#fff'}}
                                                        text={'Upgrade'}
                                                        onClick={() => history.push('/subscription-plan')}
                                            />
                                        </div>
                                    </ModalWindow>
                                </div> : null
                            }
                            <div className={'classic_digital_buttons'}>
                                <h2>Your NFTs</h2>
                                {/*<MainButton type={'button'}*/}
                                {/*            text={'Classic Artwork'}*/}
                                {/*            style={activeArtistTab === 'classic_artwork' ? {*/}
                                {/*                background: '#000',*/}
                                {/*                color: '#fff'*/}
                                {/*            } : null}*/}
                                {/*            className={'button_black'}*/}
                                {/*            onClick={() => setActiveArtistTab('classic_artwork')}*/}
                                {/*/>*/}
                                {/*<MainButton type={'button'}*/}
                                {/*            text={'Digital Artwork'}*/}
                                {/*            className={'button_black'}*/}
                                {/*            style={activeArtistTab === 'digital_artwork' ? {*/}
                                {/*                background: '#000',*/}
                                {/*                color: '#fff'*/}
                                {/*            } : null}*/}
                                {/*            onClick={() => setActiveArtistTab('digital_artwork')}*/}
                                {/*/>*/}
                            </div>
                            <MainButton type={'button'}
                                        text={'Upload NFT'}
                                        className={'button_black'}
                                        onClick={() => {
                                            if (subscriptionPlan === 'basic' && userProfile?.artworks.length === 2) {
                                                setPlanPopupShow(!planPopupShow)
                                            } else {
                                                if (reduxState.can_create) {
                                                    setEditMode(null)
                                                    setShowUploadWork(true)
                                                } else {
                                                    setActivePopup(true)
                                                }
                                            }
                                        }}
                            />
                        </div>,
                        activePopup ?
                            <div className={'popup_window_upload_work'} key={316}>
                                <ModalWindow activeModal={activePopup}
                                             key={2}
                                             setActiveModal={val => setActivePopup(val)}>
                                    <div className={'portfolio_popup_header_container'}>
                                        <h4>You can’t upload artwork</h4>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                            setActivePopup(null)
                                            bodyOverflow(false)
                                        }}>
                                            <path d="M1 1L17 17M1 17L17 1" stroke="black" strokeWidth="2"/>
                                        </svg>
                                    </div>
                                    <p>You have to fill in shipping address.</p>
                                    <div className={'modal-window_buttons'}>
                                        <MainButton type={'button'}
                                                    className={'button_bordered_close_popup'}
                                                    style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                    text={'Cancel'}
                                                    onClick={() => {
                                                        setActivePopup(null)
                                                        bodyOverflow(false)
                                                    }}
                                        />
                                        <MainButton type={'button'}
                                                    style={{padding: '8px 0', margin: '0', minWidth: '100px'}}
                                                    className={'button_bordered_fill_in'}
                                                    text={'Fill in'}
                                                    onClick={() => {
                                                        setActivePopup(null)
                                                        bodyOverflow(false)
                                                        settingsHistoryPush()
                                                    }}
                                        />
                                    </div>
                                </ModalWindow>
                            </div> : null,
                        <div className={'upload_works_wrapper'} key={112}
                             style={!loading ? {
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center'
                             } : !userProfile.artworks.length ? {gridTemplateColumns: '1fr'} : null}>
                            {loading ? userProfile.artworks.length
                                    ? userProfile.artworks.map((el, key) => (
                                        <Link to={`/public-artwork/${el.id}`}
                                              key={key}
                                              onClick={e => el.hidden ? e.preventDefault() : null}
                                              className={'works_card'}>
                                            {el.hidden
                                                ? <div className={'artwork_hidden'}><span>Your {el.category === 'NFT' ? 'NFT' : 'artwork'} is hidden!</span>
                                                </div> : null}
                                            {el.sold
                                                ? <div className={'artwork_sold'}><span>Sold</span></div> : null}
                                            <img src={el.picture} alt="preview"/>
                                            <div className={'card_content'}>
                                                <div className={'edit_delete_wrapper'}>
                                                    <div>
                                                        <h4>{capitalizeFirstLetter(el.title?.length >= 20 ? el.title.substring(0, 20) + '...' : el.title)}</h4>
                                                        {el.category === 'NFT' ? <p>Starting at ETH {el.ethereum_price} ($ {el.price})</p> : <p>Starting at €{el.price}</p>}

                                                    </div>
                                                    <div onClick={e => e.preventDefault()} className={'popup_wrapper'}>
                                                        <div
                                                            style={activeCardPopup === el.id ? {pointerEvents: 'none'} : null}
                                                            onClick={() => setActiveCardPopup(activeCardPopup !== el.id ? el.id : null)}
                                                            className={'svg_wrap'}>
                                                            <svg width="4" height="28" viewBox="0 0 4 28" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="4" height="4" fill="black"/>
                                                                <rect y="12" width="4" height="4" fill="black"/>
                                                                <rect y="24" width="4" height="4" fill="black"/>
                                                            </svg>
                                                        </div>
                                                        {activeCardPopup === el.id ?
                                                            <OutsideClickHandler callback={() => setActiveCardPopup(null)}>
                                                                <div className={'popup'}>
                                                                <span onClick={(e) => {
                                                                    if (el.sold) {
                                                                        e.preventDefault();
                                                                        changeContinuePopup(`You can't edit sold artwork`)
                                                                        setTimeout(() => {
                                                                            changeContinuePopup(`You can't edit sold artwork`)
                                                                        }, 2000)
                                                                    } else {
                                                                        setEditMode(el.id)
                                                                        setShowUploadWork(true)
                                                                        setActiveCardPopup(null)
                                                                    }
                                                                }}>Edit</span>
                                                                    <span onClick={() => {
                                                                        bodyOverflow(true)
                                                                        setActiveModal(el.id)
                                                                        setActiveCardPopup(null)
                                                                    }}>Delete</span>
                                                                </div>
                                                            </OutsideClickHandler> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )) :
                                    <div>
                                        {subscriptionPlan !== 'basic' &&
                                            <span className={'no_items_style'}>No items</span>
                                        }
                                    </div>
                                : <Loader color={{
                                    '--bgColor': '#000',
                                    '--size': '48px',
                                    '--wrapper_size': '40px'
                                }}/>}
                        </div>,
                        <div key={389}>
                            {subscriptionPlan === 'basic' &&
                                <div className={'premium_page_wrapper'}>
                                    <section className={'choose_plan_section'} style={{padding: '0'}}>
                                        <p className={'choose-plan-header-text'}>In Basic plan you can upload up to 2 NFTs. For unlimited acces please upgrade your plan.</p>
                                        <div className={'plans_wrapper'}>
                                            {choosePlan.map((el, i) => (
                                                <div key={i} className={'plan_item'}>
                                                    {el.popular ? <span className={el.popular.className}>{el.popular.text}</span> : null}
                                                    <div style={i === 0 ? {margin: '74px 0 0'} : null} className={'content'}>
                                                        <div>
                                                            <h3>{el.title}</h3>
                                                            <p>{el.subtitle}</p>
                                                            {el.title !== 'Basic' &&
                                                            <>
                                                                {el.title === 'Creator' ?
                                                                    <div className={'plan-time-container'}>
                                                                        <div
                                                                            className={planTimeCreator === 'Monthly' ? 'time-plan time-plan-active' : 'time-plan'}
                                                                            onClick={() => setPlanTimeCreator('Monthly')}>
                                                                            <span>Monthly</span>
                                                                        </div>
                                                                        <div
                                                                            className={planTimeCreator === 'Yearly' ? 'time-plan time-plan-active' : 'time-plan'}
                                                                            onClick={() => setPlanTimeCreator('Yearly')}>
                                                                            <span>Yearly</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={'plan-time-container'}>
                                                                        <div
                                                                            className={planTimeAnalytics === 'Monthly' ? 'time-plan time-plan-active' : 'time-plan'}
                                                                            onClick={() => setPlanTimeAnalytics('Monthly')}>
                                                                            <span>Monthly</span>
                                                                        </div>
                                                                        <div
                                                                            className={planTimeAnalytics === 'Yearly' ? 'time-plan time-plan-active' : 'time-plan'}
                                                                            onClick={() => setPlanTimeAnalytics('Yearly')}>
                                                                            <span>Yearly</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                            }
                                                            <div className={'price'}>
                                                                {el.title === 'Creator' && planTimeCreator === 'Monthly' &&
                                                                <div className={'plan-price-container'}>
                                                                    <h4>{el.monthlyPrice}/</h4><span>month</span>
                                                                </div>
                                                                }
                                                                {el.title === 'Creator' && planTimeCreator === 'Yearly' &&
                                                                <div className={'plan-price-container'}>
                                                                    <h4>{el.price}/</h4><span>year</span>
                                                                </div>
                                                                }
                                                                {el.title === 'Analytics' && planTimeAnalytics === 'Monthly' &&
                                                                <div className={'plan-price-container'}>
                                                                    <h4>{el.monthlyPrice}/</h4><span>month</span>
                                                                </div>
                                                                }
                                                                {el.title === 'Analytics' && planTimeAnalytics === 'Yearly' &&
                                                                <div className={'plan-price-container'}>
                                                                    <h4>{el.price}/</h4><span>year</span>
                                                                </div>
                                                                }
                                                                {el.title === 'Basic' &&
                                                                <div className={'plan-price-container'}>
                                                                    <h4>{el.price}/</h4><span>year</span>
                                                                </div>
                                                                }

                                                            </div>
                                                            {/*<div className={'price'}>*/}
                                                            {/*    {el.title === 'Creator' &&*/}
                                                            {/*    <div className={'plan-price-container'}>*/}
                                                            {/*        <h4>{el.price}/</h4><span>year</span>*/}
                                                            {/*    </div>*/}
                                                            {/*    }*/}
                                                            {/*    {el.title === 'Analytics' &&*/}
                                                            {/*    <div className={'plan-price-container'}>*/}
                                                            {/*        <h4>{el.price}/</h4><span>year</span>*/}
                                                            {/*    </div>*/}
                                                            {/*    }*/}
                                                            {/*    {el.title === 'Basic' &&*/}
                                                            {/*    <div className={'plan-price-container'}>*/}
                                                            {/*        <h4>{el.price}/</h4><span>year</span>*/}
                                                            {/*    </div>*/}
                                                            {/*    }*/}

                                                            {/*</div>*/}
                                                            <div className={'options'}>
                                                                {el.options.map((item, index) => (
                                                                    <div className={'options_item'} key={index}>
                                                                        <svg width="17" height="13" viewBox="0 0 17 13" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 6.83333L5.66667 11.5L15.6667 1.5" stroke="black"
                                                                                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                        <p>{item}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <MainButton type={'button'}
                                                                    text={el.button.value === activePlan ? 'Current plan' : el.button.text}
                                                                    className={`button_black ${disabledButton[el.title.toLowerCase()] ? 'button_black_disabled' : ''}`}
                                                                    disabled={disabledButton[el.title.toLowerCase()]}
                                                                    onClick={() => {
                                                                        if (!localStorage.getItem(Keys.JWT_TOKEN)) {
                                                                            history.push('/registration/type')
                                                                        } else {
                                                                            history.push('/subscription-plan')
                                                                        }
                                                                    }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </section>
                                </div>
                            }
                        </div>,
                        <div key={26}>
                            {activePage?.total > 9 ? loading ?
                                    <div className={'wrapper_new_pagination'}>
                                        <NewPaginationView activePage={activePage}
                                                           onChange={pageNumber => {
                                                               getActivePage(pageNumber)
                                                               setActivePage(prevState => ({
                                                                   ...prevState,
                                                                   page: pageNumber
                                                               }))
                                                           }}/>
                                    </div>
                                    : <Loader color={{
                                        '--bgColor': '#000',
                                        '--size': '48px',
                                        '--wrapper_size': '40px'
                                    }}/>
                                : null}
                        </div>,
                        activeModal ?
                            <div key={35} className={'new_modal_wrapper'}>
                                <ModalWindow activeModal={activeModal}
                                             setActiveModal={val => setActiveModal(val)}>
                                    <h4>Delete this NFT?</h4>
                                    <p>Are you sure you want to delete? This action can't be undone.</p>
                                    <div className={'modal-window_buttons'}>
                                        <MainButton type={'button'}
                                                    style={{background: '#000', color: '#fff'}}
                                                    className={'button_black'}
                                                    text={'Cancel'}
                                                    onClick={() => {
                                                        setActiveModal(null)
                                                        bodyOverflow(false)
                                                    }}
                                        />
                                        <MainButton type={'button'}
                                                    className={'button_black'}
                                                    text={'Delete'}
                                                    onClick={handleDeleteArtWork}
                                        />
                                    </div>
                                </ModalWindow>
                            </div> : null,
                    ]}
                </div>
            : <NewInvestorPortfolio key={5}/>
    ]
}
