import React from 'react';
import SEO from "../../../Components/SEO";

export default function BuyerFaq() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Buyer Faq: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Buyer Faq: Buy art online Artsted"/>
            <h1>Buyer Faq</h1>
            <div className={'wrapper_info_main'}>
                <div className={'categories_of_questions_footer'}>
                    <span>1.Registration</span>
                    <p>From the landing please select Register located at the top right of the page. On the next page,
                        click
                        "I'm an Investor", insert your data and provide a password. Your account is now created, but in
                        order to log in, you'll need to verify your email.. Click the link provided within the email to
                        complete the email verification.
                        From the main menu at the top right, hover over your name and select Account Details to open
                        your
                        profile page. Then provide your shipping and billing address, contact email and tick the
                        appropriate
                        boxes.
                    </p>
                </div>
                <div className={'categories_of_questions_footer'}>
                    <span>2.Placing an order</span>
                    <p>By placing an order an investor is automatically connected to the artist, who has 7 days to
                        dispatch
                        the artwork to the destination, the tracking number will be provided after the dispatching. Each
                        order will include the original artwork, including the authenticity certificate.</p>
                </div>
                <div className={'categories_of_questions_footer'}>
                    <span>3.Shipping</span>
                    <p>All artworks are shipped in boxes or crates, with multiple pluriball layers to protect the
                        artwork's
                        integrity. Artsted has a number of exclusive agreements with the world's most reliable carriers,
                        providing multiple shipping options for each order.</p>
                </div>
                <div className={'categories_of_questions_footer'}>
                    <span>4.Custom duties</span>
                    <p>We invite the customers to calculate the possible customs duties and other costs using free
                        online
                        software e.g. https://www.simplyduty.com</p>
                </div>
                <div className={'categories_of_questions_footer'}>
                    <span>5.Returns</span>
                    <p>Artsted is committed to ensuring the best possible customer experience for our collectors. We
                        have
                        introduced a 7-day return policy as part of our Buyer protection scheme.</p>
                </div>
            </div>


        </div>
    )
}