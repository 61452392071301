import React, {useState, useEffect} from 'react';
import Wrapper from '../OnboardingWrapper';
import artwork_img from '../../../assets/img/onboarding/artwork6.png';
import classes from './styles/profile.module.scss';
import ChangedConfirmedPopup from "../../../Components/CustomComponents/ChangedConfirmedPopup";
import {useSelector} from "react-redux";
import API from "../../../api/API";
import useBottomPopup from "../../../Constants/useBottomPopup";
import Keys from "../../../Constants/helper";
import Uploader from "../UploadFile";
import Button from "../../../Components/CustomComponents/Onboarding/Button";
import historyDetectBack from "../HistoryBackDetect";
import {useHistory} from "react-router";

function UploadProfilePhoto() {
    const [avatar, setAvatar] = useState(null);
    const store = useSelector(state => state.auth)
    const history = useHistory();
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    const _onSubmit = e => {
        e.preventDefault();
        let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
        history.push(nextHistory)
    }

    useEffect(() => {
        API(Keys.JWT_TOKEN_REGISTRATION).get(`/user/profile/edit_photo`)
            .then(res => setAvatar({blob: res.data?.avatar ?? null}))
    }, [])

    const _deleteFile = () => {
        API(Keys.JWT_TOKEN_REGISTRATION).delete(`/user/profile/edit_photo`)
            .then(() => {
                setAvatar(null)
                changeContinuePopup(`You successfully deleted avatar`)
                setTimeout(() => {
                    changeContinuePopup(`You successfully deleted avatar`)
                }, 2000)
            })

    }
    const _uploadPdf = e => {
        if (e.file) {
            let formData = new FormData()
            formData.append("photo", e.file)
            API(Keys.JWT_TOKEN_REGISTRATION).post(`/user/profile/edit_photo`, formData)
                .then(() => {
                    setAvatar({file: e.file, blob: e.blob});
                    changeContinuePopup(`You successfully uploaded avatar`)
                    setTimeout(() => {
                        changeContinuePopup(`You successfully uploaded avatar`)
                    }, 2000)
                })
        }
    }

    return (
        <Wrapper>
            <Wrapper.Picture src={artwork_img}/>
            <Wrapper.Form>
                <ChangedConfirmedPopup successText={successText}
                                       changingConfirmed={changingConfirmed}/>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>Upload your profile Photo</h1>
                    <form className={classes.form} onSubmit={_onSubmit}>
                        <div className={classes.avatar_wrapper}>
                            <Uploader value={avatar ? avatar.blob : null}
                                      radius={true}
                                      delete={true}
                                      _deleteFile={() => _deleteFile()}
                                      onLoad={photo => _uploadPdf(photo)}/>
                        </div>
                        <div className={classes.form_button}>
                            <Button type="submit">Continue &#62;</Button>
                        </div>
                    </form>
                </div>
            </Wrapper.Form>
        </Wrapper>
    );
}

export default UploadProfilePhoto;