import React, {useState, useEffect} from 'react'
import API from '../../api/API';
// import TextInput from "../CustomComponents/TextInput";
// import regExp from "../../Constants/regExp";
// import RangeSliderMultiple from "../CustomComponents/RangeSliderMultiple";
// import vector from "../../assets/img/chevron-down.svg";
// import OutsideClickHandler from "../../Constants/detectOutsideClick";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import Keys from "../../Constants/helper";

export default function NewFiltersWrapper(initialState, getFilterRequest, listType, filterListRequest, token = null, customPagination, units) {
    let {activeHashUrl} = useGetLastChartHash();
    const [filtersObject, setFiltersObject] = useState(initialState)
    const [activeFilter, setActiveFilter] = useState(null)
    const [activeFilterArray, setActiveFilterArray] = useState([])
    const [showMoreFilters, setShowMoreFilters] = useState(null);
    const [loading, setLoading] = useState(null);
    const [listingData, setListingData] = useState(() => ({page: 1}));
    const [offset, setOffset] = useState(15);
    // const [showMobileFilters, setShowMobileFilters] = useState(null);

    const deleteActiveFilterArray = item => {
        let newFilter = filtersObject[item.category].activeList?.filter(i => i.name !== item.name);
        let filter = activeFilterArray.filter(i => i.name !== item.name)
        setActiveFilterArray(filter)
        setFiltersObject(prevState => ({
            ...prevState,
            [item.category]: {
                ...prevState[item.category],
                activeList: newFilter
            }
        }))
    }

    const handleDetectOutSide = () => setActiveFilter(null)

    const revertObject = obj => {
        if (filtersObject[obj]) {
            return filtersObject[obj]?.activeList?.map(i => i.name);
        } else {
            return []
        }
    }

    useEffect(() => {
        if (activeHashUrl && !customPagination) {
            setListingData(prevState => ({
                ...prevState,
                page: activeHashUrl
            }));
        }
    }, [activeHashUrl, customPagination])

    useEffect(() => {
        if (loading) {
            let activeFilterArray = [];
            Object.entries(filtersObject).forEach(i => {
                if (i[1] instanceof Object) {
                    i[1].activeList?.forEach(elem => {
                        activeFilterArray.push(elem)
                    })
                }
            })
            setActiveFilterArray(activeFilterArray)
        }
    }, [filtersObject, loading])


    const getFilters = (activePage, searchArg = null) => {
        let categoryArray = revertObject('category'),
            countryArray = revertObject('country'),
            stylesArray = revertObject('styles'),
            mediumsArray = revertObject('mediums'),
            materialsArray = revertObject('materials'),
            status = `${filtersObject?.status ? `&hide=${filtersObject?.status?.value === 'hidden' ? 1 : 0}` : ''}`,
            categories_request = categoryArray?.length ? `&categories=${categoryArray}` : '',
            country_request = countryArray?.length ? `&countries=${countryArray}` : '',
            search = filtersObject.listing_search.value.length > 1 ? `&name=${searchArg ?? filtersObject.listing_search.value}` : '',
            height = '',
            width = '',
            price = '',
            sort_by_request = '',
            materials_request = '',
            styles_request = '',
            mediums_request = '';
        if (listType === 'artworks') {
            const multiplier = units === "inches" ? 2.54 : 1,
                minHeight = (filtersObject.height.min * multiplier).toFixed(0),
                maxHeight = (filtersObject.height.max * multiplier).toFixed(0),
                minWidth = (filtersObject.width.min * multiplier).toFixed(0),
                maxWidth = (filtersObject.width.max * multiplier).toFixed(0);
            sort_by_request = filtersObject.sort_by.activeList[0]?.name ? `&order_by=${filtersObject.sort_by.activeList[0].name}` : ''
            styles_request = stylesArray?.length ? `&styles=${stylesArray}` : ''
            mediums_request = mediumsArray?.length ? `&mediums=${mediumsArray}` : ''
            materials_request = materialsArray?.length ? `&materials=${materialsArray}` : ''
            price = `&min_price=${filtersObject.price.min}&max_price=${filtersObject.price.max}`
            height = `&height=${minHeight}-${maxHeight}`
            width = `&width=${minWidth}-${maxWidth}`
        }
        let request = `${filterListRequest}?page=${(activePage ?? listingData.page) - ((offset / 15) - 1)}${status}${search}&per_page=${offset}${price}${country_request}${sort_by_request}${height}${width}${categories_request}${styles_request}${mediums_request}${materials_request}`
        if(JSON.parse(localStorage.getItem(Keys.FROM_APP))) {
            request += "&show_allowed_artworks=1"
        }
        console.log(request, "request")
        API(token ?? null).get(request)
            .then(res => {
                setListingData(prevState => ({
                    ...prevState,
                    ...res.data
                }))
                setLoading(true)
            })
            .catch(err => {
                if (err.response.status === 404) {
                    window.location.href = '/404-redirect'
                }
            })
    }

    useEffect(() => {
        if (filtersObject.listing_search.value.length >= 2) {
            setListingData(state => ({...state, page: 1}))
        } else if (filtersObject.listing_search.value.length < 2 && filtersObject?.price?.max) {
            getFilters(1, '')
        }
        // eslint-disable-next-line
    }, [filtersObject.listing_search.value])

    useEffect(() => {
        if (filtersObject.loading) {
            getFilters()
        }
        // eslint-disable-next-line
    }, [listingData.page, filtersObject, offset, units])

    const setInputPriceRange = (value, rangeValue, category) => {
        setListingData(prevState => ({
            ...prevState,
            page: 1
        }))
        setFiltersObject(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [rangeValue]: Number(value),
            }
        }))
    }

    const showFilterPopup = name => {
        if (activeFilter === name) {
            setActiveFilter(null)
        } else {
            setActiveFilter(name)
        }
    }

    const sortByCustomHandle = item => {
        let uniqueFilter = activeFilterArray;
        if (uniqueFilter?.length) {
            if (uniqueFilter.some(e => e.unique)) {
                uniqueFilter.forEach(i => {
                    if (i.unique) {
                        i.name = item.name
                    }
                })
                setActiveFilterArray([...uniqueFilter])
            } else {
                setActiveFilterArray(prevState => [...prevState, {
                    name: item.name,
                    unique: true,
                    category: 'sort_by'
                }])
            }
        } else {
            setActiveFilterArray(prevState => [...prevState, {
                name: item.name,
                unique: true,
                category: 'sort_by'
            }])
        }
        setFiltersObject(prevState => ({
            ...prevState,
            sort_by: {
                ...prevState.sort_by,
                activeList: [{
                    value: item.name,
                    name: item.preview,
                    category: 'sort_by'
                }]
            },
        }))
        handleDetectOutSide()
    }

    const setActiveFilterFunc = (item, filterCategory) => {
        const addItemToList = () => setFiltersObject(prevState => ({
            ...prevState,
            [filterCategory]: {
                ...prevState[filterCategory],
                activeList: filterCategory === "category" || filterCategory === "sort_by"
                    ? [{
                        name: item,
                        category: filterCategory
                    }]
                    : [
                        ...prevState[filterCategory].activeList,
                        {
                            name: item,
                            category: filterCategory
                        }]
            }
        }))

        if (filtersObject[filterCategory].activeList.length) {
            if (filtersObject[filterCategory].activeList.some(elem => elem.name === item)) {
            } else {
                addItemToList()
            }
        } else {
            addItemToList()
        }
    }

    const setPriceRange = (event, val) => {
        if (Number(event.value.min) >= 0 && event.value.max <= filtersObject.max_price) {
            setFiltersObject(prevState => ({
                ...prevState,
                [val]: {
                    min: Number(event.value.min),
                    max: Number(event.value.max)
                }
            }))
        }
    }

    useEffect(() => {
        let categoryName = new URLSearchParams(window.location.search).get("category")
        if (categoryName) {
            setFiltersObject(prevState => ({
                ...prevState,
                category: {
                    ...prevState.category,
                    activeList: [...prevState.category.activeList, {
                        name: categoryName,
                        category: 'category'
                    }]
                }
            }))
        }
        console.log("request here")
        API(token ?? null).get(getFilterRequest)
            .then(res => {
                if (listType === 'artworks') {
                    setFiltersObject(prevState => ({
                        ...prevState,
                        materials: {...prevState.materials, list: res.data.materials},
                        category: {...prevState.category, list: res.data.category},
                        styles: {...prevState.styles, list: res.data.styles},
                        mediums: {...prevState.mediums, list: res.data.mediums},
                        max_price: res.data.max_price,
                        price: {...prevState.price, max: res.data.max_price},
                        loading: true
                    }))
                } else if ('artists') {
                    setFiltersObject(prevState => ({
                        ...prevState,
                        country: {...prevState.country, list: res.data.country},
                        category: {...prevState.category, list: res.data.category},
                        loading: true
                    }))
                }
            })
    }, [getFilterRequest, listType, token])

// const filterViewPriceRange = mobile => (
//     <div className={`every_filter__popup ${mobile ? 'overflow_scrollbar' : ''}`}>
//
//         <div className={'input_range'}>
//             <TextInput value={filtersObject.price.min}
//                        placeholder={0}
//                        title={'Min'}
//                        onChange={({target}) => {
//                            let {value} = target
//                            if (regExp.only_number.test(value) && Number(value) < Number(filtersObject.price.max)) {
//                                setInputPriceRange(Number(value), 'min', 'price')
//                            }
//                        }}/>
//             <span>-</span>
//             <TextInput value={filtersObject.price.max}
//                        title={'Max'}
//                        placeholder={filtersObject.max_price}
//                        onChange={({target}) => {
//                            let {value} = target
//                            if (regExp.only_number.test(value) && Number(value) <= filtersObject.max_price) {
//                                if (value > filtersObject.price.min) {
//                                    setInputPriceRange(Number(value), 'max', 'price')
//                                } else {
//                                    setInputPriceRange(Number(value), 'max', 'price')
//                                    setInputPriceRange(0, 'min', 'price')
//                                }
//                            }
//                        }}/>
//         </div>
//         <RangeSliderMultiple setValue={e => {
//             setPriceRange(e, 'price')
//             setListingData(prevState => ({
//                 ...prevState, page: 1
//             }))
//             getActivePage(1)
//         }}
//                              value={filtersObject.price}
//                              step={200}
//                              max_price={filtersObject.max_price}/>
//     </div>
// )

// const filterViewPrice = mobile => (
//     <div className={`every_filter price ${activeFilter === 'price' ? 'not_event' : ''}`}>
//         <OutsideClickHandler callback={() => activeFilter === 'price' && !mobile && handleDetectOutSide()}>
//             <div className={'category_wrapper'} onClick={() => showFilterPopup('price')}>
//                 <span>Price</span>
//                 <img src={vector}
//                      className={`${activeFilter === 'price' ? 'active' : ''}`}
//                      alt="vector"/>
//             </div>
//             {activeFilter === 'price' ?
//                 filterViewPriceRange(mobile)
//                 : null}
//         </OutsideClickHandler>
//     </div>
// )

    const categoryViewSection = theme => (
        <div style={!showMoreFilters ? {margin: '1.5rem 0 0'} : {margin: '0'}}
             className={`${theme === 'gray' ? 'theme_gray' : ''} categories_options_container categories_options_container_new wrapper_every_section`}>
            {activeFilterArray.map(item => (
                <div key={item.name}
                     className={'categories_options'}>
                    <span>{item.name}</span>
                    <svg onClick={() => {
                        deleteActiveFilterArray(item)
                    }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 12.5L12.5 1.5M12.5 12.5L1.5 1.5" stroke="white" strokeWidth="2"/>
                    </svg>
                </div>
            ))}
        </div>
    )

    const urlFromFilters = (filterArray) => {
        const artworkScheme = `/${listType}/:category/:page/f/:filters`,
            activeCategory = filterArray.find(i => i.category === "category")?.name,
            otherFilters = filterArray.filter(i => i.category !== "category"),
            hasOtherFilters = !!otherFilters.length;
        let otherFiltersGrouped = {};

        otherFilters.forEach(({category, name}) => {
            const group = otherFiltersGrouped[category];

            otherFiltersGrouped[category] = !!group ? [...group, name] : [name]
        })

        let categorySearchValue = activeCategory ? ":category" : "/:category",
            categoryReplaceValue = activeCategory || "",
            pageSearchValue = listingData.page > 1 ? ":page" : "/:page",
            pageReplaceValue = listingData.page > 1 ? "page-" + listingData.page : "",
            filterSearchValue = hasOtherFilters ? ":filters" : "/f/:filters",
            otherFiltersString = Object.entries(otherFiltersGrouped)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(i => {
                    i[1] = i[1].sort((a, b) => a.localeCompare(b)).join(',')
                    return i.join('=')
                })
                .join("/"),
            filterReplaceValue = hasOtherFilters ? otherFiltersString : "";

        const filledScheme = artworkScheme.replace(categorySearchValue, categoryReplaceValue.toLowerCase()).replace(filterSearchValue, filterReplaceValue).replace(pageSearchValue, pageReplaceValue)
        return filledScheme + window.location.hash;
    }

// const viewMoreFilters = text => (
//     <div className={`mobile_show_filters`}
//          style={showMobileFilters ? {pointerEvents: 'none'} : null}
//          onClick={() => setShowMobileFilters(!showMobileFilters)}>
//         <span>{text}</span>
//         {showMobileFilters ?
//             <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
//                  xmlns="http://www.w3.org/2000/svg">
//                 <path
//                     d="M18.25 9.5C18.25 14.3325 14.3325 18.25 9.5 18.25C4.66751 18.25 0.75 14.3325 0.75 9.5C0.75 4.66751 4.66751 0.75 9.5 0.75C14.3325 0.75 18.25 4.66751 18.25 9.5Z"
//                     stroke={'#cccbcb'} strokeWidth="1.5"/>
//                 <path d="M12.2295 6.7717L6.77273 12.2285" stroke={'#cccbcb'} strokeLinecap="round"/>
//                 <path d="M12.2292 12.2283L6.77246 6.77153" stroke={'#cccbcb'}
//                       strokeLinecap="round"/>
//             </svg>
//             : <svg xmlns="http://www.w3.org/2000/svg" width="16.887" height="9.603"
//                    viewBox="0 0 16.887 9.603">
//                 <g id="noun_Arrow_2289377" transform="translate(-32 -31.787)">
//                     <path id="Path_4555"
//                           fill={'#cccbcb'}
//                           d="M63.374 41.39a1.119 1.119 0 0 1-.794-.325 1.078 1.078 0 0 1 .036-1.516l3.1-2.959-3.1-2.959A1.072 1.072 0 1 1 64.1 32.08l3.753 3.572a1.31 1.31 0 0 1 .4.938 1.251 1.251 0 0 1-.4.938L64.1 41.1a1.073 1.073 0 0 1-.726.29z"
//                           className="cls-1" transform="translate(-19.358)"/>
//                     <path id="Path_4556"
//                           fill={'#cccbcb'}
//                           d="M47.444 44.165H33.083a1.083 1.083 0 0 1 0-2.165h14.361a1.063 1.063 0 0 1 1.083 1.083 1.086 1.086 0 0 1-1.083 1.082z"
//                           className="cls-1" transform="translate(0 -6.528)"/>
//                 </g>
//             </svg>
//         }
//     </div>
// )

    return {
        setOffset,
        filtersObject,
        setFiltersObject,
        showFilterPopup,
        deleteActiveFilterArray,
        showMoreFilters,
        activeFilter,
        // showMobileFilters,
        // setShowMobileFilters,
        setActiveFilter,
        setShowMoreFilters,
        setPriceRange,
        listingData,
        // viewMoreFilters,
        setListingData,
        sortByCustomHandle,
        setActiveFilterFunc,
        categoryViewSection,
        activeFilterArray,
        // filterViewPrice,
        setActiveFilterArray,
        loading,
        setInputPriceRange,
        handleDetectOutSide,
        urlFromFilters
    }
}
