import Button from './Button';
import classes from './styles/illustration.module.scss';
import cn from 'classnames';

function Illustration (props) {
    return (
        <div className={cn(classes.wrapper, props.className)}>
            <img className={classes.img} src={props.src} alt={`${props.text} artwork`} />
            <p className={classes.text}>{props.text}</p>
            {props.price && <Button className={classes.button}>BUY NOW € {props.price}</Button>}
        </div>
    );
}

export default Illustration;