import React, { useState } from 'react';
import './styles/email_subscription_popup.css';
import { bodyOverflow } from '../Constants/bodyOverflow';
import popupImage from '../assets/img/email_popup_image.png';
import popupImageMobile from '../assets/img/email_popup_image_mobile.png';
import popupLogo from '../assets/img/email_popup_logo_new.svg';
import TextInput from './CustomComponents/TextInput';
import MainButton from './CustomComponents/MainButton';
import regExp from '../Constants/regExp';
import API from '../api/API';
import Popup from './Popup';

export default function EmailSubscriptionPopup({
  setEmailSubscribePopup,
  innerWidth,
}) {
  const [activeSubscribe, setActiveSubscribe] = useState({
    value: '',
    error: false,
    errorMessage: `This field can't be empty`,
  });
  const [popup, setPopup] = useState(null);

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    if (activeSubscribe.value) {
      if (regExp.email.test(activeSubscribe.value)) {
        API()
          .post(`/landing/subscribe`, { email: activeSubscribe.value })
          .then((res) => {
            if (res.status === 200) {
              setPopup(true);
              bodyOverflow(true);
              localStorage.setItem('emailSubscriptionPopup', 'not show');
            }
          });
      } else {
        setActiveSubscribe((prevState) => ({
          ...prevState,
          error: true,
          errorMessage: `Wrong email format`,
        }));
      }
    } else {
      setActiveSubscribe((prevState) => ({
        ...prevState,
        error: true,
        errorMessage: `This field can't be empty`,
      }));
    }
  };

  return (
    <div className={'email_subscription_popup_wrapper'}>
      {popup ? (
        <Popup
          closeCallback={() => {
            setPopup(false);
            setEmailSubscribePopup(false);
            setActiveSubscribe((prevState) => ({
              ...prevState,
              value: '',
            }));
          }}
          to_top={true}
          title={'Thank you for subscription!'}
        />
      ) : null}
      <div className={'content'}>
        {innerWidth > 325 ? (
          <div className={'left_side_image'}>
            <img
              src={innerWidth > 750 ? popupImage : popupImageMobile}
              alt='subscription'
            />
          </div>
        ) : null}
        <div className={'text'}>
          <img src={popupLogo} alt='logo' />
          <h2>Sign up for exciting email updates</h2>
          <p>
            Get exclusive drop alerts, new arrivals, real-time market analytics
            and special offers!
          </p>
          <form onSubmit={(e) => handleEmailSubmit(e)}>
            <TextInput
              type={'text'}
              margin={'0 0 2rem'}
              title={'E-Mail'}
              error={activeSubscribe.error}
              newError={activeSubscribe.error}
              errTitle={activeSubscribe.errorMessage}
              value={activeSubscribe.value}
              placeholder={'Your E-mail'}
              borderBottomInput
              onChange={({ target }) => {
                setActiveSubscribe((prevState) => ({
                  ...prevState,
                  value: target.value,
                  error: false,
                }));
              }}
            />
            <div className={'inline'}>
              <span
                onClick={() => {
                  setEmailSubscribePopup(false);
                  bodyOverflow(false);
                  localStorage.setItem('emailSubscriptionPopup', 'not show');
                }}
              >
                No , thank you{' '}
              </span>
              <MainButton
                text={'I agree!'}
                type={'submit'}
                className={'button_black'}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
