import React, {useEffect, useRef} from "react";
import TextBox from "./CustomComponents/TextBox";

export const ResizeTextBox = (props) => {
    const inputRef = useRef();
    const {
        value, error, errorMessage, blockStyle, onChange,
        inputTitle, addMargin, borderBottomInput,
        errTitle, disabled, placeholder, maxLength
    } = props;

    const _setHeight = () => {
        const field = inputRef.current;
        if (!field) {
            return;
        }

        field.style.height = 'inherit';
        const computed = window.getComputedStyle(field);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + parseInt(computed.getPropertyValue('padding-top'), 10)
            + field.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
        field.style.height = height + 'px';
    }

    useEffect(() => {
        _setHeight();
    })

    return (
        <TextBox title={inputTitle ? inputTitle : null}
                 value={value}
                 maxLength={maxLength ?? null}
                 rows={1}
                 addMargin={addMargin}
                 disabled={disabled}
                 placeholder={placeholder}
                 blockStyle={blockStyle}
                 ref={inputRef}
                 borderBottomInput={borderBottomInput}
                 error={error}
                 newError={error}
                 errTitle={errorMessage ? errorMessage : errTitle}
                 onChange={(e) => onChange(e)}/>
    )
}
