import React from 'react'
import './styles/list_of_works.css'
import Keys from "../../Constants/helper";
import {Link} from "react-router-dom";
import FiltersWrapper from "../../Components/FilterComponents/FiltersWrapper";
import FilterView from "../../Components/FilterComponents/FilterView";
import TextInput from "../../Components/CustomComponents/TextInput";
import SwitchStatus from "../Components/SwitchStatus";
import artworks_placeholder from "../../assets/img/artworks_placeholder.png";
import {getActivePage} from "../../Constants/getActivePage";
import NewPaginationView from "../../Components/NewPaginationView";

const initialState = {
    loading: false,
    listing_search: {
        value: ''
    },
    category: {
        activeList: [],
        list: []
    },
    styles: {
        activeList: [],
        list: []
    },
    sort_by: {
        activeList: [],
        list: [{name: 'price_asc', preview: 'Price asc'}, {name: 'price_desc', preview: 'Price desc'}],
    },
    materials: {
        activeList: [],
        list: []
    },
    max_price: null,
    price: {
        max: null,
        min: 0
    },
    width: {
        max: 500,
        min: 0
    },
    height: {
        max: 500,
        min: 0
    },
    status: {
        value: 'published'
    }
}

export default function ListOfWorks() {
    let {
        filtersObject,
        showFilterPopup,
        setListingData,
        setActiveFilterFunc,
        loading,
        activeFilter,
        setActiveFilter,
        listingData,
        setFiltersObject,
        activeFilterArray,
        categoryViewSection,
        handleDetectOutSide,
        sortByCustomHandle,
        filterViewPrice
    } = FiltersWrapper(
        initialState, '/admin/artwork_filters',
        'artworks', '/admin/artworks',
        Keys.JWT_TOKEN_ADMIN, true
    );

    const switchStatusChange = (status) => {
        setFiltersObject(prevState => ({
            ...prevState,
            status: {
                value: status,
            }
        }))
        setListingData(prevState => ({
            ...prevState,
            page: 1
        }))
        getActivePage(1)
    }

    const downloadTableHandler = () => {
        console.log('test')
        const token = localStorage.getItem(Keys.JWT_TOKEN_ADMIN)
        window.open(process.env.REACT_APP_API_URL + '/admin/artworks_to_excel?jwt=' + token)
    }

    return [
        <div className={'admin_content_wrapper admin_artworks_content_wrapper'} key={1}>
            <div className={'wrapper_page_head_preview'}>
                <h1 className={'wrapper_title'}>{'List of works'}</h1>
                <div className={'verified_content'}>
                    <SwitchStatus switchStatus={'published'}
                                  switchStatusChange={() => switchStatusChange('published')}
                                  verifiedUser={filtersObject.status?.value}/>
                    <SwitchStatus switchStatus={'hidden'}
                                  switchStatusChange={() => switchStatusChange('hidden')}
                                  verifiedUser={filtersObject.status?.value}/>
                </div>
            </div>
            <div className={'admin_artworks_head wrapper_list_filters'}>
                <div className={'wrapper_search_input'}>
                    <TextInput type="text"
                               placeholder={'Search'}
                               value={filtersObject.listing_search.value}
                               searchIcon={true}
                               onChange={({target}) => {
                                   setFiltersObject(prevState => ({
                                       ...prevState,
                                       listing_search: {
                                           value: target.value,
                                       }
                                   }))
                                   setListingData(prevState => ({
                                       ...prevState,
                                       page: 1
                                   }))
                               }}/>
                    <button onClick={downloadTableHandler} className={'download-table-list-of-works'}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.2438 16.496C15.4338 16.4961 15.6167 16.5683 15.7556 16.698C15.8944 16.8277 15.9788 17.0053 15.9918 17.1949C16.0047 17.3845 15.9453 17.5719 15.8253 17.7193C15.7054 17.8668 15.534 17.9631 15.3458 17.989L15.2438 17.996H4.74977C4.55975 17.996 4.37683 17.9238 4.23798 17.7941C4.09913 17.6643 4.0147 17.4867 4.00175 17.2972C3.98879 17.1076 4.04829 16.9202 4.1682 16.7727C4.28812 16.6253 4.45952 16.5289 4.64777 16.503L4.74977 16.496H15.2438ZM10.0038 1.99905C10.1848 1.9993 10.3597 2.06504 10.4961 2.18414C10.6325 2.30324 10.7211 2.46766 10.7458 2.64705L10.7528 2.74905V12.942L13.7188 9.97205C13.8456 9.84488 14.0139 9.76747 14.193 9.75389C14.3721 9.74031 14.5502 9.79146 14.6948 9.89805L14.7798 9.97005C14.9071 10.0971 14.9844 10.2656 14.9978 10.445C15.0112 10.6243 14.9598 10.8025 14.8528 10.947L14.7808 11.031L10.5378 15.281L10.4678 15.344L10.3758 15.403L10.3398 15.424L10.2488 15.462L10.1288 15.492L10.0588 15.5L9.99877 15.502C9.94802 15.502 9.89741 15.4966 9.84777 15.486L9.76777 15.463C9.67272 15.4325 9.58493 15.3828 9.50977 15.317L5.21977 11.032C5.08523 10.8984 5.00632 10.7187 4.99893 10.5292C4.99153 10.3397 5.05619 10.1545 5.1799 10.0107C5.30361 9.86699 5.47718 9.77545 5.66567 9.75455C5.85416 9.73364 6.04357 9.78491 6.19577 9.89805L6.27977 9.97105L9.25277 12.938V2.74805C9.25277 2.54913 9.33179 2.35837 9.47244 2.21772C9.61309 2.07706 9.80386 1.99805 10.0028 1.99805L10.0038 1.99905Z" fill="black"/>
                        </svg>
                        Download Table
                    </button>
                </div>
                <div className={'wrapper_list_filters'}>
                    <FilterView category={'sort by'}
                                handleDetectOutSide={handleDetectOutSide}
                                activeFilter={activeFilter}
                                sort_by_output={true}
                                filterList={filtersObject.sort_by.list}
                                setActiveFilterFunc={setActiveFilterFunc}
                                setListingData={e => setListingData(e)}
                                setActiveFilter={e => setActiveFilter(e)}
                                customHandleClick={sortByCustomHandle}
                                showFilterPopup={e => showFilterPopup(e)}/>
                    {filtersObject.loading && filtersObject.category.list?.length ?
                        <FilterView category={'category'}
                                    handleDetectOutSide={handleDetectOutSide}
                                    activeFilter={activeFilter}
                                    filterList={filtersObject.category.list}
                                    setActiveFilterFunc={setActiveFilterFunc}
                                    setListingData={e => setListingData(e)}
                                    setActiveFilter={e => setActiveFilter(e)}
                                    showFilterPopup={e => showFilterPopup(e)}
                        />
                        : null}
                    {filtersObject.loading && filtersObject.materials.list?.length ?
                        <FilterView category={'materials'}
                                    handleDetectOutSide={handleDetectOutSide}
                                    activeFilter={activeFilter}
                                    filterList={filtersObject.materials.list}
                                    setListingData={e => setListingData(e)}
                                    setActiveFilterFunc={setActiveFilterFunc}
                                    setActiveFilter={e => setActiveFilter(e)}
                                    showFilterPopup={e => showFilterPopup(e)}/>
                        : null}
                    {filtersObject.loading && filtersObject.styles.list?.length ?
                        <FilterView category={'styles'}
                                    handleDetectOutSide={handleDetectOutSide}
                                    activeFilter={activeFilter}
                                    filterList={filtersObject.styles.list}
                                    setListingData={e => setListingData(e)}
                                    setActiveFilterFunc={setActiveFilterFunc}
                                    setActiveFilter={e => setActiveFilter(e)}
                                    showFilterPopup={e => showFilterPopup(e)}/>
                        : null}
                    {filterViewPrice()}
                </div>
            </div>
            {activeFilterArray.length ?
                categoryViewSection('gray')
                : null}
            <div className={'admin_artworks_container'}>
                {loading ? listingData.artworks.length
                    ? listingData.artworks.map((item) => (
                        <Link to={`/admin/artworks/${item.id}`} className={'admin_artworks_container_card'}
                              key={item.id}>
                            <div className={'image'}>
                                <img src={item.picture}
                                     onError={e => {
                                         e.target.src = artworks_placeholder
                                     }}
                                     alt="preview"/>
                            </div>
                            <div className={'content'}>
                                <h4>{item.title}, {item.year}</h4>
                                <div className={'price'}>
                                    <p>{item.author_name}</p>
                                    <h5>€{item.price}</h5>
                                </div>
                                <div className={'category'}>
                                    <span>{item.keyword ?? 'Painting'}</span>
                                    <span>/</span>
                                    <span>{item.size}</span>
                                </div>
                            </div>
                        </Link>
                        ))
                    : <span>
</span> : null}
            </div>
        </div>,
        loading && listingData.total > 9 ?
            <div className={'wrapper_new_pagination admin_pagination'} key={2}>
                <NewPaginationView activePage={listingData}
                                onChange={pageNumber => {
                                    getActivePage(pageNumber)
                                    setListingData(prevState => ({
                                        ...prevState,
                                        page: pageNumber
                                    }))
                                }}/>
            </div>
            : null
    ]
}
