import {Input} from "antd";
import clsx from "clsx";
import {forwardRef} from "react";

import styles from './StyledInput.module.css';

const StyledInput = forwardRef(({centered, label, textarea, variant, ...props}, ref) => {
    const Component = textarea ? Input.TextArea : Input;

    return (
        <>
            {label && <label className={clsx(styles.label, centered && styles.centered)}>{label}</label>}
            <Component
                {...props}
                ref={ref}
                className={clsx(styles.root, centered && styles.centered, variant && styles[variant])}
            />
        </>
    )
})

export default StyledInput;