import {Col, Row, Tag} from "antd";
import clsx from "clsx";
import React, {useEffect, useState} from "react";

import API from "../../../api/API";
import Keys from "../../../Constants/helper";
import {places} from "../../../Components/profile/ListingForm/ListingForm.constants";

import StyledTabs from "../../../Components/ui/StyledTabs";
import StyledInput from "../../../Components/ui/StyledInput";

import {CloseOutlinedIcon, SearchIcon} from "../../../assets/icons";
import NewPaginationView from "../../../Components/NewPaginationView";
import AdminExperienceItem from "../../Components/AdminExperienceItem";
import {Loader} from "../../../Components/Loader";
import StyledSelect from "../../../Components/ui/StyledSelect";

import styles from './AdminExperience.module.css';

const items = [
    {key: 'Studio', label: 'Studios'},
    {key: 'Event', label: 'Events'},
    {key: 'Stay', label: 'Stays'},
];

export default function AdminExperience() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [params, setParams] = useState({
        page: 1
    });
    const [search, setSearch] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [filters, setFilters] = useState({});
    const [type] = useState("Stay");

    useEffect(() => {
        API().get('/listing/amenity')
            .then((res) => setAmenities(res.data));
    }, [])

    useEffect(() => {
        setLoading(true);
        API(Keys.JWT_TOKEN_ADMIN).get('/admin/listing/list', {
            params: {
                type,
                ...params,
                ...filters,
                search,
                amenities: filters?.amenities?.join(','),
                place_types: filters?.place_types?.join(','),
            }
        })
            .then((res) => setData(res.data))
            .finally(() => setLoading(false))
    }, [search, filters, params, type]);

    const handleChangeFilter = (n, v) => {
        setFilters((p) => ({
            ...p,
            [n]: ['place_types', 'amenities'].includes(n) ? Array.from(new Set([...p[n] || [], v])) : v
        }))
    }

    const handleChangeRentalFilter = (v) => {
        setFilters((p) => ({
            ...p,
            daily_rentable: null,
            monthly_rentable: null,
            [`${v}_rentable`]: 1
        }))
    }

    const handleRemoveFromFilter = (k, v) => {
        const data = {...filters};
        if (k === 'amenities' || k === 'place_types') {
            data[k] = filters[k].filter((i) => i !== v);
        } else {
            delete data?.[k]
        }
        setFilters(data);
    }

    const handleChangePage = (page) => {
        setParams((p) => ({...p, page}))
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <div className={clsx("admin_content_wrapper", styles.root)}>
            <div className={'admin_content_wrapper_users'}>
                <Row gutter={[12, 12]} justify="space-between">
                    <Col>
                        <h1>Experience</h1>
                    </Col>
                    <Col>
                        <StyledTabs
                            className={styles.tabs}
                            defaultActiveKey="Stay"
                            items={items}
                        />
                    </Col>
                </Row>
                <Row align='middle' gutter={10}>
                    <Col xs={24} md={7}>
                        <div className={'wrapper_search_input'}>
                            <StyledInput
                                variant="outlined"
                                placeholder="Search"
                                suffix={<SearchIcon/>}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={17}>
                        <Row gutter={[10, 10]} justify="end">
                            <Col>
                                <StyledSelect
                                    variant="text"
                                    placement="bottomRight"
                                    className={styles.select}
                                    popupClassName={styles.selectPopup}
                                    value="Status"
                                    onChange={(v) => handleChangeFilter('is_active', v)}
                                    options={[
                                        {value: '1', label: 'Active'},
                                        {value: '0', label: 'Inactive'},
                                    ]}
                                />
                            </Col>
                            <Col>
                                <StyledSelect
                                    variant="text"
                                    placement="bottomRight"
                                    value="Rental Type"
                                    className={styles.select}
                                    popupClassName={styles.selectPopup}
                                    onChange={handleChangeRentalFilter}
                                    options={[
                                        {value: 'daily', label: 'Daily'},
                                        {value: 'monthly', label: 'Monthly'},
                                    ]}
                                />
                            </Col>
                            <Col>
                                <StyledSelect
                                    variant="text"
                                    placement="bottomRight"
                                    value="Place Type"
                                    className={styles.select}
                                    popupClassName={styles.selectPopup}
                                    onChange={(v) => handleChangeFilter('place_types', v)}
                                    options={places.map((place) => ({
                                        value: place, label: place
                                    }))}
                                />
                            </Col>
                            <Col>
                                <StyledSelect
                                    variant="text"
                                    placement="bottomRight"
                                    className={styles.select}
                                    popupClassName={styles.selectPopup}
                                    value="Amenities"
                                    onChange={(v) => handleChangeFilter('amenities', v)}
                                    options={amenities.map((a) => ({value: a, label: a}))}
                                />
                            </Col>
                            <Col>
                                <StyledSelect
                                    variant="text"
                                    placement="bottomRight"
                                    className={styles.select}
                                    popupClassName={styles.selectPopup}
                                    value="Price"
                                    onChange={(v) => handleChangeFilter('daily_price_to', v)}
                                    options={[
                                        {value: 500, label: 'Under €500'},
                                        {value: 1000, label: 'Under €1000'}
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[8, 8]} className={styles.filtersList}>
                    {filters.is_active !== undefined && (
                        <Col>
                            <Tag
                                closable
                                bordered={false}
                                className={styles.tag}
                                closeIcon={<CloseOutlinedIcon />}
                                onClose={() => handleRemoveFromFilter('is_active')}
                            >
                                {filters.is_active === '1' ? 'Active' : 'Inactive'}
                            </Tag>
                        </Col>
                    )}
                    {(filters.daily_rentable || filters.monthly_rentable) && (
                        <Col>
                            <Tag
                                closable
                                bordered={false}
                                className={styles.tag}
                                closeIcon={<CloseOutlinedIcon />}
                                onClose={() => handleRemoveFromFilter(filters.daily_rentable ? 'daily_rentable' : 'monthly_rentable')}
                            >
                                {filters.daily_rentable ? 'Daily' : 'Monthly'}
                            </Tag>
                        </Col>
                    )}
                    {filters.place_types && (
                        <Col>
                            {filters.place_types.map((place) => (
                                <Tag
                                    closable
                                    bordered={false}
                                    className={styles.tag}
                                    closeIcon={<CloseOutlinedIcon />}
                                    onClose={() => handleRemoveFromFilter('place_types', place)}
                                >
                                    {place}
                                </Tag>
                            ))}
                        </Col>
                    )}
                    {filters.daily_price_to && (
                        <Col>
                            <Tag
                                closable
                                bordered={false}
                                className={styles.tag}
                                closeIcon={<CloseOutlinedIcon />}
                                onClose={() => handleRemoveFromFilter('daily_price_to')}
                            >
                                Under €{filters.daily_price_to}
                            </Tag>
                        </Col>
                    )}
                    {filters.amenities && (
                        filters.amenities.map((amenity) => (
                            <Col key={amenity}>
                                <Tag
                                    closable
                                    bordered={false}
                                    className={styles.tag}
                                    closeIcon={<CloseOutlinedIcon />}
                                    onClose={() => handleRemoveFromFilter('amenities', amenity)}
                                >
                                    {amenity}
                                </Tag>
                            </Col>
                        ))
                    )}
                </Row>
                <div className={styles.list}>
                    <Row gutter={[24, 24]}>
                        {loading ? (
                            <Col>
                                <Loader/>
                            </Col>
                        ) : (
                            data.listings?.map((item) => (
                                <Col xs={24} sm={12} xl={8} key={item.id}>
                                    <AdminExperienceItem {...item} />
                                </Col>
                            ))
                        )}
                    </Row>
                </div>
                {!loading && (
                    <div className={'wrapper_new_pagination admin_pagination'}>
                        <NewPaginationView
                            activePage={{
                                page: params.page,
                                pages: data.pages
                            }}
                            onChange={handleChangePage}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}