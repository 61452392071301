import React, {useState} from 'react';
import arrow_right from '../assets/img/arrow_right_black.svg'
import './styles/extend-seo.css'

const ExtendSEO = ({title, description}) => {
    const [extended, setExtended] = useState(false);

    return (title || description) ? (
        <div className="block-seo">
            <div className={`js_seo_content ${extended ? 'extended' : ''}`}>
                <h2>{title}</h2>
                <p>{description}</p>
                {extended || <div className="extend-blur"/>}
            </div>

            {extended ||
            <div
                onClick={() => setExtended(state => !state)}
                className="opener-holder"
            >
                <p>SHOW MORE <img src={arrow_right} alt=""/></p>
            </div>}
        </div>
    ) : null;
};

export default ExtendSEO;
