import React, {useEffect, useState, useRef} from 'react'
import './styles/admin_blog.css'
import API from "../../api/API";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import Keys from "../../Constants/helper";
import search_icon from "../../assets/img/admin/search_button.svg";
import {Link} from "react-router-dom";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import NewPostComponent from "../Components/NewPostComponent";
import {useGetLastChartHash} from "../../Constants/useGetLastChartHash";
import PaginationView from "../../Components/Pagination";
import CustomDropdown from "../../Components/CustomComponents/Dropdown/index";
import {getActivePage} from "../../Constants/getActivePage";
import edit_blog_icon from "../../assets/img/admin/edit_blog_icon.svg";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";
import categoryClose from "../../assets/img/personal_registration/category_close.svg";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
import {useHistory} from "react-router";

const initialState = {
    post_photo: {
        previews: '',
        files: '',
        value: false,
        error: false,
    },
    name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    description: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
    author_name: {
        value: '',
        error: false,
        errorMessage: 'Fill this label',
    },
}

export default function AdminBlog() {
    const history = useHistory();

    let postRef = useRef();
    const {activeHashUrl} = useGetLastChartHash()

    const [activeBlogData, setActiveBlogData] = useState(null)
    const [loading, setLoading] = useState(false)

    const [editMode, setEditMode] = useState(null);
    const [editorState, setEditorState] = useState([]);
    const [editorMarkup, setEditorMarkup] = useState([])
    const [articleData, setArticleData] = useState([])
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();
    const [blogMainData, setBlogMainData] = useState(() => initialState)
    const [activeSearch, setActiveSearch] = useState({
        value: '',
    })
    const [postPopup, setPostPopup] = useState({
        value: false,
        category: {
            popup: false,
            options: null,
            error: false,
            value: null,
        },
        filter_category: {
            popup: false,
            options: null,
            error: false,
            value: null,
        },
    })
    const [activePage, setActivePage] = useState(() => ({
        page: null,
        pages: null,
        total: null,
        loading: null
    }));


    useEffect(() => {
        history.push('/admin/investors')
    }, [history])

    const uploadFiles = (event, objKey, article, arrIndex) => {
        event.preventDefault();
        setLoading(false)
        // let files = event.target.files;
        // console.log(files)
        if (article) {
            if (event.target.files[0]) {
                let changeState = articleData.find((el, i) => i === arrIndex)
                changeState = {
                    ...changeState,
                    [objKey]: {
                        ...changeState[objKey],
                        files: event.target.files[0],
                        previews: {
                            url: URL.createObjectURL(event.target.files[0]),
                            id: performance.now()
                        },
                    },
                }
                setArticleData(prevState => ([
                    ...prevState.slice(0, arrIndex),
                    changeState,
                    ...prevState.slice(arrIndex + 1)
                ]))
                setLoading(true)
            }
        } else {
            if (event.target.files[0]) {
                setBlogMainData(state => ({
                    ...state,
                    [objKey]: {
                        ...state[objKey],
                        files: event.target.files[0],
                        previews: {
                            url: URL.createObjectURL(event.target.files[0]),
                            id: performance.now()
                        },
                        value: true,
                        error: false,
                        errorMessage: null,
                    },
                }))
                setLoading(true)
            }
        }
    }

    useEffect(() => {
        if (activeHashUrl) {
            setActivePage(prevState => ({
                ...prevState,
                page: activeHashUrl,
            }))
        }
    }, [activeHashUrl])


    const setHtmlToEditor = (html) => {
        const sampleMarkup = `<p>${html}</p>`
        const options = {
            customInlineFn: (element, {Style}) => {
                if (element.style.color) {
                    return Style('color-' + element.style.color);
                }
            }
        };
        // const options = { customInlineFn: (element, { Style,Entity }) => { if (element.style.color) { return Style('CUSTOM_COLOR_' + element.style.color); } } };

        setEditorState(prevState => ([
            ...prevState, EditorState.createWithContent(stateFromHTML(sampleMarkup, options.customInlineFn))
        ]))
    }

    useEffect(() => {
        if (activePage.page) {
            API(Keys.JWT_TOKEN_ADMIN).get('/admin/post', {
                params: {
                    name: activeSearch.value.length > 1 ? activeSearch.value : '',
                    category: postPopup.filter_category.value,
                    page: activePage.page,
                    per_page: 9
                }
            })
                .then(res => {
                    setActivePage(prevState => ({
                        ...prevState,
                        pages: res.data.pages,
                        total: res.data.total,
                    }))
                    setActiveBlogData(res.data)
                    setLoading(true)
                })
        }
    }, [postPopup.filter_category.value, activeSearch.value, activePage.page])

    useEffect(() => {
        API().get(`/user/categories`)
            .then((res) => {
                setPostPopup(prevState => ({
                    ...prevState,
                    category: {
                        ...prevState.category,
                        options: res.data.categories
                    },
                    filter_category: {
                        ...prevState.filter_category,
                        options: res.data.categories
                    }
                }))

            })
    }, [activePage.page, activeSearch.value])

    const handlePostSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('author_name', blogMainData.author_name.value)
        formData.append('name', blogMainData.name.value)

        if (blogMainData.post_photo.files?.toString() === '[object File]') {
            formData.append('post_photo', blogMainData.post_photo.files)
        }
        formData.append('category', postPopup.category.value)
        formData.append('description', blogMainData.description.value)

        // console.log(articleData);
        // console.log(blogMainData);
        articleData.forEach((item, index) => {
            if (item.name.value) {
                formData.append(`section_name_${index}`, item.name.value)
            }
            if (item.file.files?.toString() === '[object File]') {
                formData.append(`section_file_${index}`, item.file.files)
            } else {
                if (item.file.previews.url) {
                    formData.append(`photo_url_${index}`, item.file.previews.url)
                }
            }
        })

        // console.log(editorMarkup)
        editorMarkup.forEach((item, index) => {
            // console.log(item)
            if (item) {
                formData.append(`section_description_${index}`, item)
            }
        })

        // for (let b of formData.entries()) {
        //     console.log(b)
        // }

        const condition = postPopup.category.value && blogMainData.author_name.value && blogMainData.name.value &&
            (blogMainData.post_photo.files || blogMainData.post_photo.previews.url) && blogMainData.description.value;
        if (condition) {
            if (editMode) formData.append(`id`, editMode)

            API(Keys.JWT_TOKEN_ADMIN).post(`/admin/post`, formData)
                .then(res => {
                    if (res.data) {
                        if (editMode) {
                            changeContinuePopup('Post successfully edited')
                            setTimeout(() => {
                                changeContinuePopup('Post successfully edited')
                            }, 2000)
                            let newArray = activeBlogData.posts.map(i => {
                                if (i.id === res.data.post.id) {
                                    return res.data.post
                                } else {
                                    return i
                                }
                            })
                            setActiveBlogData(prevState => ({
                                ...prevState,
                                posts: newArray
                            }))
                        } else {
                            changeContinuePopup('Post successfully added')
                            setTimeout(() => {
                                changeContinuePopup('Post successfully added')
                            }, 2000)
                            setActiveBlogData(prevState => ({
                                ...prevState,
                                posts: [{
                                    id: res.data.post_id,
                                    author_name: blogMainData.author_name.value,
                                    category: postPopup.category.value,
                                    date: res.data.date ?? '21.04.2021',
                                    name: blogMainData.name.value,
                                    photo: blogMainData.post_photo.previews.url
                                }, ...prevState.posts]
                            }))
                        }

                        setPostPopup(prevState => ({
                            ...prevState,
                            value: false,
                            category: {
                                ...prevState.category,
                                value: null,
                            }
                        }))
                        bodyOverflow(false)
                        setEditorState([]);
                        setEditorMarkup([])
                        setArticleData([])
                        setBlogMainData(() => initialState)
                    }
                    setEditMode(null)
                })
        } else {
            postRef?.current.scroll({top: 0, behavior: 'smooth'});
            if (!postPopup.category.value) {
                setPostPopup(prevState => ({
                    ...prevState,
                    category: {
                        ...prevState.category,
                        error: true,
                    }
                }))
            }
            Object.entries(blogMainData).forEach(item => {
                if (!item[1].value) {
                    setBlogMainData(prevState => ({
                        ...prevState,
                        [item[0]]: {...prevState[item[0]], error: true, errorMessage: 'Please fill this label'},
                    }))
                }
            })
        }
    }

    const handleEditArticle = (e, item) => {
        e.preventDefault();
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/post?id=${item.id}`)
            .then((res) => {
                if (res.data) {
                    setEditMode(item.id)
                    setPostPopup(prevState => ({
                        ...prevState,
                        category: {
                            ...prevState.category,
                            value: res.data.category,
                        }
                    }))
                    setBlogMainData(prevState => ({
                        ...prevState,
                        post_photo: {
                            ...prevState.post_photo,
                            previews: {
                                ...prevState.post_photo.previews,
                                url: res.data.photo,
                            }
                        },
                        name: {
                            ...prevState.name,
                            value: res.data.name,
                        },
                        description: {
                            ...prevState.description,
                            value: res.data.description,
                        },
                        author_name: {
                            ...prevState.author_name,
                            value: res.data.author_name,
                        },
                    }))
                    res.data.sections.forEach(item => {
                        setHtmlToEditor(item.description)
                        setEditorMarkup(prevState => ([
                            ...prevState, item.description
                        ]))
                        setArticleData(prevState => ([
                            ...prevState, {
                                name: {
                                    value: item.name,
                                },
                                file: {
                                    ...prevState.file,
                                    previews: {
                                        url: item.photo,
                                    },
                                },
                            }]))
                    })
                    setPostPopup(prevState => ({
                        ...prevState,
                        value: true,
                    }))
                    bodyOverflow(true)
                }
            })
    }


    return [
        <ChangedConfirmedPopup key={0} successText={successText}
                               changingConfirmed={changingConfirmed}/>,
        <div key={1} className={'admin_content_wrapper admin_content_wrapper_blog'}>
            <div className={'wrapper_header_preview_section'}>
                <h1>Blog</h1>
                {postPopup.filter_category.options?.length ?
                    <div className="admin_category_wrapper">
                        <CustomDropdown value={postPopup.filter_category.value}
                                        setInputValue={val => setPostPopup(val)}
                                        errorTitle={postPopup.filter_category.errorMessage}
                                        optionLabel={'filter_category'}
                                        error={postPopup?.filter_category.error}
                                        defaultDropdown
                                        dropdownSelect
                                        withoutPreview={true}
                                        withoutPreviewTitle={'Category'}
                                        dropdownPadding={'5px 0'}
                                        dropdownIcon={greyChevron}
                                        popup={postPopup.filter_category.popup}
                                        options={postPopup.filter_category.options}
                        />
                    </div>
                    : null}
            </div>
            <div className={'head'}>
                <div className={'category'}>
                    <label>
                        <input type="text"
                               placeholder={'Search '}
                               value={activeSearch.value}
                               onChange={({target}) => {
                                   setActiveSearch(prevState => ({
                                       ...prevState,
                                       value: target.value
                                   }))
                               }}
                        />
                        <img src={search_icon} alt="search icon"/>
                    </label>
                </div>
                <div>
                    <button onClick={() => {
                        bodyOverflow(true)
                        setPostPopup(prevState => ({
                            ...prevState,
                            value: true,
                        }))
                    }}>+ Add New Post
                    </button>
                </div>
            </div>
            <div className={'categories_options_container'}>
                {postPopup.filter_category?.value ?
                    <div className={'categories_options'}>
                        <span>{postPopup.filter_category.value}</span>
                        <img onClick={() => {
                            setPostPopup(prevState => ({
                                ...prevState,
                                filter_category: {
                                    ...prevState.filter_category,
                                    value: null
                                }
                            }))
                        }} src={categoryClose} alt="close icon"/>
                    </div>
                    : null}
            </div>
            <div className={'body'}>
                {loading ? activeBlogData.posts?.length
                    ? activeBlogData.posts.map((item) => (
                        <Link to={`/admin/article/${item.id}`}
                              className={'article_card'} key={item.id}>
                            <img className={'preview'} src={item.photo} alt="preview"/>
                            <img onClick={(e) => handleEditArticle(e, item)}
                                 className={'edit'} src={edit_blog_icon} alt="edit"/>
                            <div className={'card_content'}>
                                <h4>{item.name.length > 40 ?
                                    item.name.substring(0, 40) + '...' :
                                    item.name}</h4>
                                <p>{item.author_name}</p>
                                <div className={'category'}>
                                    <span>{item.category}</span>
                                    <span>{item.date}</span>
                                </div>
                            </div>
                        </Link>
                    ))
                    : <span className={'no_items_style'}>No items</span> : null}
            </div>
        </div>,

        loading && activePage.total > 9 ?
            <div className={'admin_pagination wrapper_pagination'} key={2}>
                <PaginationView activePage={activePage}
                                onChange={pageNumber => {
                                    getActivePage(pageNumber)
                                    setActivePage(prevState => ({
                                        ...prevState,
                                        page: pageNumber
                                    }))
                                }}/>
            </div> : null,

        postPopup.value
        && <NewPostComponent setPostPopup={val => setPostPopup(val)}
                             postPopup={postPopup}
                             key={3}
                             editMode={editMode}
                             initialState={initialState}
                             blogMainData={blogMainData}
                             uploadFiles={uploadFiles}
                             loading={loading}
                             setArticleData={val => setArticleData(val)}
                             setEditorState={val => setEditorState(val)}
                             editorMarkup={editorMarkup}
                             convertToRaw={convertToRaw}
                             postRef={postRef}
                             editorState={editorState}
                             handlePostSubmit={handlePostSubmit}
                             articleData={articleData}
                             EditorState={EditorState}
                             setEditorMarkup={val => setEditorMarkup(val)}
                             setBlogMainData={val => setBlogMainData(val)}/>
    ]
}