import React, { useEffect, useState } from 'react';
import '../styles/new_upload_work.css';
import { useHistory } from 'react-router-dom';
import TextInput from '../CustomComponents/TextInput';
import CustomDropdown from '../CustomComponents/Dropdown';
import greyChevron from '../../assets/img/personal_registration/chevron-input-down.svg';
import API from '../../api/API';
import { ResizeTextBox } from '../ResizeTextBox';
import DropzonePhotoContainer from '../DropzonePhotoContainer';
import RadioButton from '../CustomComponents/RadioButton';
import MainButton from '../CustomComponents/MainButton';
import DropzoneFileContainer from '../DropzoneFileContainer';
import NftFileContainer from '../NftFileContainer';
import CustomCheckbox from '../CustomComponents/CheckBox';
import { setActiveUpload } from '../../main-store/auth/actions';
import ChangedConfirmedPopup from '../CustomComponents/ChangedConfirmedPopup';
import useBottomPopup from '../../Constants/useBottomPopup';
import { useDispatch } from 'react-redux';
import { Loader } from '../Loader';

const initialState = {
  type: {
    value: 'NFT',
  },
  styles: {
    /*use*/ value: '',
    options: null,
    popup: false,
    required: false,
    error: null,
    errorMessage: 'Choose style',
    dataArray: [],
  },
  art_title: {
    /*use*/ value: '',
    errorMessage: 'Fill this label',
    required: true,
    error: null,
  },
  ethereum_address: {
    /*use*/ value: '',
    errorMessage: 'Fill this label',
    required: false,
    error: null,
  },
  price: {
    /*use*/ value: '',
    errorMessage: 'Fill this label',
    required: true,
    error: null,
  },
  ethereum_price: {
    value: '',
    errorMessage: 'Fill this label',
    required: true,
    error: null,
  },
  art_description: {
    /*use*/ value: '',
    errorMessage: 'Fill this label',
    required: false,
    error: null,
  },
  agree_terms: {
    /*use*/ value: false,
    required: true,
    errorMessage: 'You must agree to the Terms of service to upload artwork',
    error: null,
  },
  exhibition_participation: {
    /*use*/ value: '',
    error: null,
    required: true,
    errorMessage: 'Fill this label',
  },
  exhibition_participation_proofs: {
    /*use*/ files: [],
    error: null,
    required: true,
    errorMessage: 'At least one main file must be uploaded',
  },
  additional_info: {
    value: '',
    error: null,
    required: false,
    errorMessage: 'Fill this label',
  },
  additional_info_proofs: {
    files: [],
    error: null,
    errorMessage: null,
  },
  price_type: {
    /*use*/ value: null,
    error: null,
    required: true,
    errorMessage: 'Select the packaging type',
  },
};

export default function NewUploadNFTWork(props) {
  // const hoverRef = useRef();

  const { editMode, setShowUploadWork, setUploadEnd, setEditMode } = props;

  const uploadDispatch = useDispatch();
  const history = useHistory();
  // const { innerWidth } = useResize();

  const [files, setFiles] = useState([{}]);
  const [artworkFilesError, setArtworkFilesError] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const [soldError /*, setSoldError*/] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [uploadWorkData, setUploadWorkData] = useState(() => initialState);
  // const [activePopover, setActivePopover] = useState(false);
  const [pendingButton, setPendingButton] = useState(false);
  const [estimatePrice, setEstimatePrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [advancedSettingsHandler, setAdvancedSettingsHandler] = useState(false);
  let { successText, changingConfirmed, changeContinuePopup } =
    useBottomPopup();
  const [wrongEthereumAddress, setWrongEthereumAddress] = useState(false);
  const [backendError, setBackendError] = useState(false);

  useEffect(() => {
    API()
      .get(`/user/artwork/data`)
      .then((res) => {
        // let dataStylesNFT = [];
        // // eslint-disable-next-line
        // Object.entries(res.data.styles).map((e) => {
        //   if (e[1].type === 'NFT') {
        //     dataStylesNFT.push(e[1]);
        //   }
        // });
        setUploadWorkData((state) => ({
          ...state,
          styles: { ...state.styles, options: res.data.styles },
        }));
        setEstimatePrice(res.data.price);
      })
      .then(() => setLoading(true));
  }, []);

  useEffect(() => {
    if (editMode) {
      API()
        .get(`/user/artwork?id=${editMode}`)
        .then((res) => {
          setFiles([[{ preview: res.data.picture }]]);
          setUploadWorkData((state) => ({
            ...state,
            styles: { ...state.styles, dataArray: res.data.styles },
            hidden: res.data.hidden,
            price_type: { ...state.price_type, value: res.data.price_type },
            art_description: {
              ...state.art_description,
              value: res.data.description,
            },
            art_title: { ...state.art_title, value: res.data.title },
            type: { ...state.type, value: res.data.category },
            ethereum_address: {
              ...state.ethereum_address,
              value: res.data.ethereum_address,
            },
            price: { ...state.price, value: res.data.price },
            ethereum_price: {
              ...state.ethereum_price,
              value: res.data.ethereum_price,
            },
            agree_terms: { ...state.agree_terms, value: true },

            exhibition_participation: {
              ...state.exhibition_participation,
              value: res.data.additional_text,
            },
            exhibition_participation_proofs: {
              ...state.exhibition_participation_proofs,
              files: [
                { preview: res.data.nft_file, name: res.data.nft_file_name },
              ],
            },
            additional_info: {
              ...state.additional_info,
              value: res.data.additional_text, // additional_text
            },
            additional_info_proofs: {
              ...state.additional_info_proofs,
              files: [
                {
                  preview: res.data.additional_file,
                  name: res.data.additional_file_name,
                },
              ],
            },
            publication_proofs: {
              ...state.publication_proofs,
              files: [res.data.ntf_file],
            },
          }));
        });
    }
  }, [editMode /*, uploadWorkData.weight.options*/]);

  useEffect(
    () => () => {
      //Make sure to revoke the data uris to avoid memory leaks
      files
        .map((el) => el)
        .forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  /*here*/
  const uploadProofWrap = (inputTitle, objHandle, filesArray) => {
    return (
      <div className={'upload_proof_wrap'}>
        <ResizeTextBox
          borderBottomInput
          value={uploadWorkData[objHandle]?.value}
          error={uploadWorkData[objHandle].error}
          inputTitle={inputTitle}
          disabled={!!editMode}
          onChange={({ target }) =>
            setUploadWorkData((prevState) => ({
              ...prevState,
              [objHandle]: {
                ...prevState[objHandle],
                value: target.value,
                error: false,
              },
            }))
          }
          maxLength={243}
          blockStyle={{
            margin: '0 0 1.5rem',
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignItems: 'center',
          }}
        />
        <div
          style={editMode ? { pointerEvents: 'none' } : null}
          className={'proofs_wrap'}
        >
          <DropzoneFileContainer
            data={uploadWorkData}
            accept={''}
            disabled={!!editMode}
            setData={(e) => {
              setUploadWorkData(e);
            }}
            filesArray={filesArray}
          />
        </div>
      </div>
    );
  };

  const uploadNftWrap = (inputTitle, objHandle, filesArray) => {
    return (
      <div className={'upload_proof_wrap'}>
        <div
          style={editMode ? { pointerEvents: 'none' } : null}
          className={'proofs_wrap'}
        >
          <NftFileContainer
            data={uploadWorkData}
            disabled={!!editMode}
            setData={(e) => {
              setUploadWorkData(e);
            }}
            filesArray={filesArray}
          />
        </div>
      </div>
    );
  };

  const infoDetails = (objValues, category, title, active_category) => {
    return (
      <div>
        <CustomDropdown
          value={objValues.value}
          filterItem={false}
          title={title}
          disableMargin={true}
          setInputValue={(val) => {
            if (!editMode) {
              setUploadWorkData(val);
            }
          }}
          disabled={editMode}
          optionLabel={category}
          placeholder={category}
          newDropdownIcon={
            <svg
              width='16'
              height='10'
              viewBox='0 0 16 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16 0V1.73624L7.98294 9.77778L0 1.73624V0L7.98294 8.04154L16 0Z'
                fill='black'
              />
            </svg>
          }
          error={objValues.error}
          newError={objValues.error}
          active_category={active_category}
          errorTitle={objValues.errorMessage}
          selectCategory
          borderDropdown
          defaultDropdown
          popup={objValues.popup}
          search
          dataArray={objValues.dataArray}
          onChange={({ target }) => {
            if (!editMode) {
              setUploadWorkData((prevState) => ({
                ...prevState,
                [category]: {
                  ...prevState[category],
                  value: target.value,
                  error: false,
                  errorMessage: 'Fill this label',
                },
              }));
            }
          }}
          dropdownPadding={'5px 16px'}
          dropdownIcon={greyChevron}
          options={objValues.options}
          type={'NFT'}
        />
        {objValues.dataArray.length ? (
          <div
            style={editMode ? { pointerEvents: 'none' } : null}
            className={
              'categories_options_container upload_work_options_container upload_nft_work_options_container'
            }
          >
            {objValues.dataArray.map((item, index) => {
              return (
                <div
                  style={
                    editMode
                      ? { background: 'rgba(0, 0, 0, .3)', gridGap: '0' }
                      : null
                  }
                  className={'categories_options'}
                  key={index}
                >
                  <span>{item}</span>
                  {!editMode ? (
                    <svg
                      onClick={() => {
                        if (!editMode) {
                          setUploadWorkData((prevState) => ({
                            ...prevState,
                            [category]: {
                              ...prevState[category],
                              dataArray: prevState[category].dataArray.filter(
                                (el) => el !== item
                              ),
                            },
                          }));
                        }
                      }}
                      width='10'
                      height='10'
                      viewBox='0 0 10 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1 9L9 1M9 9L1 1'
                        stroke='white'
                        strokeWidth='2'
                      />
                    </svg>
                  ) : null}
                </div>
              );
            })}
            <div>
              {objValues.dataArray.filter((e) => e === 'Other').length !==
                0 && (
                  <div className={'other-style-container'}>
                    <div className={'basic_info'}>
                      <TextInput
                        type={'text'}
                        title={'Specify'}
                        error={uploadWorkData.styles.error}
                        newError={uploadWorkData.styles.error}
                        errTitle={uploadWorkData.styles.errorMessage}
                        value={uploadWorkData.styles.value}
                        placeholder={'Type here'}
                        disabled={toBeDisabled()}
                        borderBottomInput
                        onChange={({ target }) => {
                          setUploadWorkData((state) => ({
                            ...state,
                            styles: {
                              ...state.styles,
                              value: target.value,
                              error: false,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const handleCustomError = (margin, text) => (
    <div style={{ margin: margin }} className={'new_error_wrapper'}>
      <svg
        width='17'
        height='15'
        viewBox='0 0 17 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z'
          fill='#FF4949'
        />
        <path
          d='M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z'
          fill='#FF4949'
        />
        <path
          d='M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z'
          fill='#FF4949'
        />
      </svg>
      <span>{text}</span>
    </div>
  );

  const handleSubmit = (e) => {
    e?.preventDefault();
    setFieldsError(false);
    const {
      art_title,
      type,
      ethereum_address,
      price,
      ethereum_price,
      art_description,
      agree_terms,
      exhibition_participation_proofs,
      styles,
      additional_info,
      additional_info_proofs,
      price_type,
    } = uploadWorkData;

    if (editMode) {
      setPendingButton(true);
      API()
        .patch(`/user/artwork`, {
          id: editMode,
          title: art_title.value,
          description: art_description.value,
          ethereum_address: ethereum_address.value,
          price: price.value,
          ethereum_price: ethereum_price.value,
        })
        .then(() => {
          changeContinuePopup('You artwork is updated');
          setTimeout(() => {
            changeContinuePopup('You artwork is updated');
            history.push('/profile/nft_portal');
            setShowUploadWork(false);
            setPendingButton(false);
            uploadDispatch(setActiveUpload(false));
            setIsFinished(false);
            setUploadEnd(true);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let formData = new FormData();

      const condition =
        art_title.value && agree_terms.value && price_type.value;
      if (condition && files.some((el) => Object.keys(el).length)) {
        if (art_description.value) {
          formData.append('description', art_description.value);
        }
        formData.append('title', art_title.value);
        formData.append('type', type.value);
        formData.append('ethereum_address', ethereum_address.value);
        formData.append('price', price.value);
        formData.append('ethereum_price', ethereum_price.value);
        formData.append('price_type', price_type.value);
        formData.append(
          'styles',
          styles.dataArray.filter((e) => e === 'Other').length === 0
            ? styles.dataArray
            : [styles.value]
        );
        formData.append('additional_text', additional_info.value);
        formData.append('nft_file', exhibition_participation_proofs.files[0]);
        formData.append('additional_file', additional_info_proofs.files[0]);

        files.forEach((item) => {
          if (Object.keys(item).length) {
            console.log(item);
            formData.append('pictures', item[0]);
          }
        });

        console.log(isFinished, estimatePrice);

        for (let b of formData.entries()) {
          console.log(b);
        }
        setPendingButton(true);
        setBackendError(false);
        setWrongEthereumAddress(false);

        API()
          .post(`/user/artwork`, formData)
          .then(() => {
            changeContinuePopup('You artwork is uploaded');
            setTimeout(() => {
              setPendingButton(false);
              changeContinuePopup('You artwork is uploaded');
              history.push('/profile/nft_portal');
              window.scrollTo(0, 0);
              setShowUploadWork(false);
              uploadDispatch(setActiveUpload(false));
              setIsFinished(false);
              setUploadEnd(true);
            }, 1000);
          })
          .catch((res) => {
            setPendingButton(false);
            if (res?.response?.data?.error === 'wrong ethereum_address') {
              setWrongEthereumAddress(true);
            } else if (res?.response?.data?.error === "cant add nft") {
              setBackendError("Basic subscription plan doesn't allow to upload more than 2 NFTs")
            } else {
              setBackendError(true);
            }
          });
      } else {
        setFieldsError(true);
        if (files.every((el) => !Object.keys(el).length)) {
          setArtworkFilesError(true);
        }
        Object.entries(uploadWorkData).forEach((i) => {
          if (i[1].value && i[1].options === undefined) {
            // console.log(1, i)
          } else if (i[1].options?.length && i[1].dataArray?.length) {
            // console.log(2, i)
            // console.log(i)
          } else if (i[1].options?.length && i[1].value) {
            // console.log(i)
            // console.log(4, i)
          } else if (i[1].files?.length) {
            // console.log(3, i)
          } else if (i[1].required) {
            // console.log(i[1])
            setUploadWorkData((state) => ({
              ...state,
              [i[0]]: {
                ...state[i[0]],
                error: true,
              },
            }));
          }
        });
      }
    }
  };

  const toBeDisabled = () => {
    if (editMode) {
      return !uploadWorkData.hidden;
    }
  };

  console.log('pendingButton: ', pendingButton);

  return [
    <ChangedConfirmedPopup
      successText={successText}
      key={1}
      changingConfirmed={changingConfirmed}
    />,
    <form
      key={0}
      className={'upload_work_wrapper'}
      onSubmit={(e) => handleSubmit(e)}
    >
      {uploadWorkData.hidden ? (
        <div className={'artwork_hidden'}>
          <span>Your NFT is hidden!</span>
        </div>
      ) : null}
      <div className={'title_wrap'}>
        <h2>{editMode ? 'Edit' : 'Upload'} NFT</h2>
      </div>
      <p className={'subtitle'}>
        Present your work in the best light! Make sure to include appealing
        high-quality images and photos.
      </p>
      <p className={'subtitle-text-file-upload'}>
        File upload (PNG, SVG, GIF, 3D Object, etc):
      </p>
      {uploadNftWrap(
        'File upload (PNG, SVG, GIF, 3D Object, etc):',
        'exhibition_participation',
        'exhibition_participation_proofs'
      )}
      {uploadWorkData.exhibition_participation_proofs.error
        ? handleCustomError(
          '1rem 0 2.5rem',
          'At least one main file must be uploaded'
        )
        : null}
      {/*{console.log('exhibition_participation_proofs: ', uploadWorkData.exhibition_participation_proofs.files[0]?.preview)}*/}
      {/*<p className={'upload-display-nft-image-title'}>Upload display image or gif</p>*/}
      <div className={'title_wrap'} style={{ marginTop: 30 }}>
        <h2>Upload display image or gif</h2>
      </div>
      {editMode ? (
        <p className={'subtitle'}>
          <b>You can not edit the pictures on this stage.</b>
        </p>
      ) : null}
      <div
        style={
          editMode
            ? { pointerEvents: 'none' }
            : artworkFilesError
              ? { margin: '0' }
              : null
        }
        className={'upload_photos_wrapper upload_photos_nft_wrapper'}
      >
        <div>
          <DropzonePhotoContainer
            data={files}
            setData={(e) => setFiles(e)}
            index={0}
            key={0}
            artworkFilesError={artworkFilesError}
            setArtworkFilesError={(e) => setArtworkFilesError(e)}
            biggerContainer
          />
        </div>
      </div>
      {artworkFilesError
        ? handleCustomError(
          '1rem 0 1.5rem',
          'At least one picture must be uploaded'
        )
        : null}
      <div className={'title-nft-container'}>
        <TextInput
          type={'text'}
          title={'Title'}
          error={uploadWorkData.art_title.error}
          newError={uploadWorkData.art_title.error}
          errTitle={uploadWorkData.art_title.errorMessage}
          value={uploadWorkData.art_title.value}
          placeholder={'Artwork title'}
          disabled={toBeDisabled()}
          borderBottomInput
          onChange={({ target }) => {
            setUploadWorkData((state) => ({
              ...state,
              art_title: {
                ...state.art_title,
                value: target.value,
                error: false,
              },
            }));
          }}
        />
      </div>
      <div className={'resize_description'}>
        <ResizeTextBox
          inputTitle={'Description'}
          placeholder={'About artwork'}
          borderBottomInput
          value={uploadWorkData.art_description.value}
          error={uploadWorkData.art_description.error}
          disabled={toBeDisabled()}
          onChange={({ target }) =>
            setUploadWorkData((prevState) => ({
              ...prevState,
              art_description: {
                ...prevState.art_description,
                value: target.value,
                error: false,
              },
            }))
          }
          blockStyle={{
            margin: '0',
            display: 'grid',
            gridTemplateColumns: '1fr',
            alignItems: 'center',
          }}
        />
      </div>
      <div className={'title-nft-container'}>
        <TextInput
          type={'text'}
          title={'Ethereum address (if available)'}
          error={uploadWorkData.ethereum_address.error}
          newError={uploadWorkData.ethereum_address.error}
          errTitle={uploadWorkData.ethereum_address.errorMessage}
          value={uploadWorkData.ethereum_address.value}
          placeholder={'Ethereum'}
          disabled={toBeDisabled()}
          wrongEthereumAddress={wrongEthereumAddress}
          borderBottomInput
          onChange={({ target }) => {
            setUploadWorkData((state) => ({
              ...state,
              ethereum_address: {
                ...state.ethereum_address,
                value: target.value,
                error: false,
              },
            }));
          }}
        />
        {wrongEthereumAddress && (
          <div className={'wrong_ethereum_address_container'}>
            <svg
              width='17'
              height='15'
              viewBox='0 0 17 15'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z'
                fill='#FF4949'
              />
              <path
                d='M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z'
                fill='#FF4949'
              />
              <path
                d='M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z'
                fill='#FF4949'
              />
            </svg>
            <p>Wrong ethereum address</p>
          </div>
        )}
      </div>
      <div className={'optional_info_wrapper'}>
        <div className={'optional_info_content'}>
          <p>Price</p>
          <div className={'create-nfc-price-container'}>
            <div
              style={
                uploadWorkData.price_type.error
                  ? { margin: '0 0 0 1rem' }
                  : null
              }
              className={'optional_packing nfc-radio-button-margin'}
            >
              <RadioButton
                title={'Fixed'}
                disabled={!!editMode}
                value={uploadWorkData.price_type.value}
                error={uploadWorkData.price_type.error}
                onClick={() => {
                  if (!editMode) {
                    setUploadWorkData((state) => ({
                      ...state,
                      price_type: {
                        ...state.price_type,
                        value: 'Fixed',
                        error: false,
                        errorMessage: null,
                      },
                    }));
                  }
                }}
              />
              <RadioButton
                title={'Bidding'}
                disabled={true}
                value={uploadWorkData.price_type.value}
                error={uploadWorkData.price_type.error}
                // onClick={() => {
                //     if (!editMode) {
                //         setUploadWorkData(state => ({
                //                 ...state, price_type: {
                //                     ...state.price_type,
                //                     value: 'Bidding',
                //                     errorMessage: null,
                //                     error: false
                //                 }
                //             }
                //         ))
                //     }
                // }}
              />
            </div>
            <div className={'basic_info'}>
              <TextInput
                type={'text'}
                title={'Price, ETH'}
                error={uploadWorkData.ethereum_price.error}
                newError={uploadWorkData.ethereum_price.error}
                errTitle={uploadWorkData.ethereum_price.errorMessage}
                value={uploadWorkData.ethereum_price.value}
                placeholder={'Price'}
                disabled={toBeDisabled()}
                borderBottomInput
                onChange={({ target }) => {
                  setUploadWorkData((state) => ({
                    ...state,
                    ethereum_price: {
                      ...state.ethereum_price,
                      value: target.value,
                      error: false,
                    },
                  }));
                }}
              />
            </div>
          </div>

          {uploadWorkData.price_type.error
            ? handleCustomError('1rem 0 2.5rem', 'Select the price type')
            : null}
        </div>
        <div
          onClick={() => {
            setAdvancedSettingsHandler(!advancedSettingsHandler);
          }}
          className={'advanced-settings-container'}
        >
          <h2 className={'advanced-settings-title'}>
            Provide more information about your NFT
          </h2>
          <div
            className={`square ${
              advancedSettingsHandler ? 'square_active' : ''
            }`}
          >
            <div/>
            <div/>
          </div>
        </div>

        <div
          className={
            'advanced-settings-expanded ' +
            (advancedSettingsHandler ? 'active-expand' : '')
          }
        >
          <div className={'advanced-settings-nft-styles'}>
            {loading &&
              infoDetails(uploadWorkData.styles, 'styles', 'Style of art: ')}
          </div>
          {uploadProofWrap(
            'Additional info (audio file, text etc):',
            'additional_info',
            'additional_info_proofs'
          )}
        </div>
      </div>
      <div
        className={'end_upload_work_line'}
        style={{ margin: '2rem 0 2.5rem' }}
      />
      <div className={'terms_of_service'}>
        {loading && !editMode ? (
          <CustomCheckbox
            text={'Agree to provide a “Digital Signature”'}
            handleChange={() => {
              if (!editMode) {
                setUploadWorkData((state) => ({
                  ...state,
                  agree_terms: {
                    ...state.agree_terms,
                    value: !state.agree_terms.value,
                    error: false,
                    errorMessage: null,
                  },
                }));
              }
            }}
            error={uploadWorkData.agree_terms.error}
            value={uploadWorkData.agree_terms.value}
          />
        ) : null}
        {uploadWorkData.agree_terms.error
          ? handleCustomError(
            '1rem 0 2.5rem',
            'You must agree to provide a "Digital Signature"'
          )
          : null}
      </div>
      {' '}
      <div className={'apply_cancel'}>
        <MainButton
          type={'button'}
          className={'button_black'}
          text={'Cancel'}
          onClick={() => {
            setShowUploadWork(false);
            setEditMode(null);
            window.scrollTo(0, 0);
          }}
        />
        <MainButton
          text={
            pendingButton ? (
              <Loader
                color={{
                  '--bgColor': '#fff',
                  '--size': '24px',
                  '--wrapper_size': '12px',
                  '--margin': '-5px',
                }}
              />
            ) : editMode ? (
              'Save changes'
            ) : (
              'Submit for review'
            )
          }
          type={'button'}
          disabled={pendingButton}
          className={'button_black'}
          onClick={(e) => handleSubmit(e)}
          style={{ background: '#000', color: '#fff', marginLeft: 'auto' }}
        />

        {pendingButton && <span>Uploading files, please wait</span>}

        {fieldsError ? (
          <div className={'all_fields_error'}>
            {handleCustomError('1rem 0', 'Fill all the fields')}
          </div>
        ) : null}

        {soldError ? (
          <div className={'all_fields_error'}>
            {handleCustomError('1rem 0', 'Sold artwork cannot be changed')}
          </div>
        ) : null}
      </div>
      {backendError && (
        <div className={'wrong_ethereum_address_container'}>
          <svg
            width='17'
            height='15'
            viewBox='0 0 17 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ marginTop: -14 }}
          >
            <path
              d='M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z'
              fill='#FF4949'
            />
            <path
              d='M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z'
              fill='#FF4949'
            />
            <path
              d='M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z'
              fill='#FF4949'
            />
          </svg>
          <p>
            {typeof backendError === 'string'
              ? backendError
              : "Please make sure you filled the fields right and uploaded both NFTand display image."
            }
          </p>
        </div>
      )}
      {/*{showPreview ?*/}
      {/*    <AuthenticityCertificate uploadWorkData={uploadWorkData}*/}
      {/*                             files={files}*/}
      {/*                             handleCustomError={handleCustomError}*/}
      {/*                             setIsFinished={e => setIsFinished(e)}*/}
      {/*                             setShowPreview={e => setShowPreview(e)}*/}
      {/*                             handleSubmitArtwork={handleSubmit}/>*/}
      {/*    : null}*/}
    </form>,
  ];
}
