import React from 'react';
import {capitalizeFirstLetter} from '../../Constants/capitalizeFirstLetter'
import {getActivePage} from "../../Constants/getActivePage";

const SwitchStatus = ({verifiedUser, switchStatus, setVerifiedUser, setActivePage, switchStatusChange}) => {

    let title = capitalizeFirstLetter(switchStatus);

    return (
        <h5 className={verifiedUser === switchStatus
            ? 'verified_content_active' : null}
            onClick={() => {
                if (switchStatusChange) {
                    switchStatusChange(switchStatus)
                } else {
                    getActivePage(1)
                    setVerifiedUser(switchStatus)
                    setActivePage(prevState => ({
                        ...prevState,
                        page: 1
                    }))
                }
            }}>{title}</h5>
    )
};

export default SwitchStatus
