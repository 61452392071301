import classes from './styles/button.module.scss';
import cn from 'classnames';

function Button (props) {
    return (
        <button
            className={cn(classes.btn, props.className)}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default Button;