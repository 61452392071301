import ModalWindow from "../ModalWindow";
import upload_img from "../../assets/img/upload-image.svg";
import take_img from "../../assets/img/take-image.svg";
import React, {useState} from "react";
import Webcam from "react-webcam";
import MainButton from "../CustomComponents/MainButton";
import Dropzone from 'react-dropzone'
import drop_image from '../../assets/img/drop_images.svg'

const VerificationPopup = ({setVerificationPopup}) => {
    const [step, setStep] = useState(0)
    const [imgSrc, setImgSrc] = useState(null);
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        setStep(3)
    }, [webcamRef, setImgSrc]);

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const renderContent = () => {
        switch (step) {
            case 0:
                return (
                    <>
                        <div className={'verification-variants'}>
                            <div onClick={() => setStep(2)}>
                                <div className={'image-wrapper center-image'}>
                                    <img src={upload_img} width={76} height={64} alt=""/>
                                </div>
                                <span>Upload from Computer</span>
                            </div>
                            <span>or</span>
                            <div onClick={() => setStep(1)}>
                                <div className={'image-wrapper'}>
                                    <img width={48} height={42} src={take_img} alt=""/>
                                </div>
                                <span>Take a Photo</span>
                            </div>
                        </div>
                        <span
                            className={'verification-text'}>Upload or take a photo holding your identity card, making sure the data is visible</span>
                    </>
                )
            case 1:
                return (
                    <div className={'take-photo-wrapper'}>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                        />
                        <MainButton text={'Capture photo'} onClick={capture}/>
                    </div>
                )
            case 2:
                return (
                    <div className={'take-photo-wrapper'}>
                        <Dropzone maxFiles={1} accept={'image/jpeg, image/png, image/jpg'} onDrop={acceptedFiles => {
                            getBase64(acceptedFiles[0], (res) => {
                                setImgSrc(res)
                            })
                        }}>
                            {({getRootProps, getInputProps, open}) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()}/>
                                        {imgSrc
                                            ? <img src={imgSrc} className={'result-img'} alt=""/>
                                            : <img src={drop_image} width={64} height={76} alt=""/>
                                        }
                                    </div>
                                    <MainButton text={imgSrc ? 'Continue' : 'Find on Computer'} onClick={imgSrc ? () => setStep(3) : open}/>
                                </section>
                            )}

                        </Dropzone>
                    </div>
                )
            case 3:
                return (
                    <div className={'take-photo-wrapper'}>
                        {imgSrc && (
                            <img src={imgSrc} alt={'verification'}/>
                        )}
                        <MainButton text={'Apply'} onClick={() => setVerificationPopup(false)}/>
                    </div>
                )
            default:
                break;
        }
    }

    return (
        <div className={'verification-popup-wrapper'}>
            <ModalWindow setActiveModal={val => setVerificationPopup(val)}>
                <div>
                    <span className="verification-title">Verify your profile</span>
                    <span className="verification-text">Artsted KYC verification only takes a few moments. Please bear with us</span>
                    {renderContent()}
                </div>
            </ModalWindow>
        </div>
    )
}

export default VerificationPopup