import {useState} from "react";
import clsx from "clsx";
import {GoogleApiWrapper, InfoWindow, Map, Marker} from "google-maps-react";

import {defaultBase64Image} from "../../../Constants/images";

import styles from './ExperiencesMap.module.css';

const tagSvgRaw = (price) => `
    <svg xmlns="http://www.w3.org/2000/svg" width="79" height="49" viewBox="0 0 79 49" fill="none">
        <rect x="8" y="8" width="63" height="33" rx="16.5" fill="black"/>
        <text x="50%" y="28" text-anchor="middle" fill="#FFF" font-size="14px" font-family="sans-serif" font-weight="600">
            ${price}
        </text>
    </svg>`;

function ExperiencesMap({list, ...props}) {
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const image = selectedPlace?.photos.length ? `${process.env.REACT_APP_API_URL}/${selectedPlace?.photos?.sort((a, b) => Number(b.main) - Number(a.main))?.[0]?.path}` : defaultBase64Image;

    const onMarkerClick = (props, marker, data) => {
        console.log(props, data);
        if (data.id !== selectedPlace?.id) {
            setSelectedPlace(data);
            setActiveMarker(marker);
            setShowingInfoWindow(true);
        }
    };

    const onClose = () => {
        if (showingInfoWindow) {
            setActiveMarker(null);
            setShowingInfoWindow(false);
        }
    };

    function encodeSVG(rawSvgString) {
        const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;

        // Use single quotes instead of double to avoid URI encoding
        rawSvgString = rawSvgString
            .replace(/'/g, '"')
            .replace(/>\s+</g, '><')
            .replace(/\s{2,}/g, ' ');

        return (
            'data:image/svg+xml;utf-8,' +
            rawSvgString.replace(symbols, encodeURIComponent)
        );
    }

    return (
        <div className={clsx(styles.wrapper, props.isFilterOpen && styles.withFilter)}>
            <Map
                google={props.google}
                zoom={14}
                tilt={0}
                initialCenter={{
                    lat: 32.178195,
                    lng: 34.90761
                }}
                mapTypeControl={false}
                streetViewControl={false}
                zoomControl
                mapType="roadmap"
                rotateControl={false}
            >
                {list.map((item) => (
                    <Marker
                        key={item.id}
                        position={{lat: item.latitude, lng: item.longitude}}
                        icon={{
                            url: encodeSVG(tagSvgRaw(`€${item.daily_price}`)),
                        }}
                        onMouseover={(props, marker) => onMarkerClick(props, marker, item)}
                    />
                ))}
                <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}
                    onClose={onClose}
                >
                    <div>
                        <div className={styles.imageWrapper}>
                            <img src={image} alt=""/>
                        </div>
                        <div className={styles.infoContent}>
                            <h3 className={styles.infoTitle}>{selectedPlace && selectedPlace.name}</h3>
                            <div>{selectedPlace?.address}</div>
                            <div className={styles.infoPrice}>
                                <b>€{selectedPlace?.daily_price}</b> night
                            </div>
                        </div>
                    </div>
                </InfoWindow>
            </Map>
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(ExperiencesMap);