import React, {useRef} from 'react'
import MainButton from "../../Components/CustomComponents/MainButton"
import './styles/registrationVideoPhotos.css';
import uploadPhotoPreview from "../../assets/img/uploadPhotoPreview.svg";
import clearPhoto from "../../assets/img/clear_gray.svg";
import {Loader} from '../../Components/Loader'

export default function InfoPhotoVideo(props) {
    let {
        artistVideo,
        uploadVideo,
        handleClearPhoto,
        uploadPhotos,
        uploadIcon,
        artistPhotos,
        videoLoading,
        setChangeCurrentPhoto,
        changeCurrentPhoto,
        headerToken
    } = props;
    let refVideo = useRef();
    let refPhotos = useRef();

    // const sortArrays = (array, photo, currentPhoto) => {
    //     return (
    //         artistPhotos.photos[array].map(i => {
    //             if (i.id === currentPhoto.id) {
    //                 return photo
    //             }
    //             if (photo.id === i.id) {
    //                 return currentPhoto
    //             }
    //             return i
    //         })
    //     )
    // }


    // const handleDragDrop = (e, photo) => {
    //     e.preventDefault();
    //     let sortArrayPhotos = sortArrays('value', photo, currentPhoto)
    //     let sortArrayFiles = sortArrays('files', photo, currentPhoto)
    //
    //     let photosConcat = {files: [...sortArrayFiles], value: [...sortArrayPhotos]}
    //     e.target.style.background = 'white';
    //     changePhotos(photosConcat)
    // }
    //
    // const handleDragStart = (e, photo) => {
    //     setCurrentPhoto(photo);
    // }
    //
    // const handleDragOver = e => {
    //     e.preventDefault();
    // }
    //
    // const handleDragEnd = e => {
    //     e.preventDefault();
    //     e.target.style.background = 'white'
    // }
    //
    // const handleDragLeave = e => {
    //     e.target.style.background = 'white'
    // }


    let placeholderArray = JSON.parse(JSON.stringify(artistPhotos.photos.value));

    while (placeholderArray.length < 5) {
        placeholderArray.push(null)
    }

    return (
        <div className={'registration_photo_video'}>
            <h2>Upload your profile Video & Photo</h2>

            <div className={'wrapper_photos'}>
                {/*<div className={'the_photos_hint'}>*/}
                {/*    <span>Drag photos to change order</span>*/}
                {/*</div>*/}
                <div className={'photos'}>
                    {placeholderArray.map((item, index) => {
                        // console.log(item)
                        return !item ?
                            <div className="every_photo empty"
                                 key={Date.now() + index}
                                 onClick={() => {
                                     refPhotos.click()
                                 }}
                                 style={index === 0 ? {height: '142px'} : null}>
                                {index === 0 ?
                                    <img src={uploadPhotoPreview}
                                         className={'preview'}
                                         alt="uploadPhotoPreview"/>
                                    : null}
                            </div>
                            : index === 0 ?
                                <div className="every_photo"
                                     style={{height: '142px'}}
                                    // onDragStart={(e) => handleDragStart(e, item)}
                                    // onDragOver={(e) => handleDragOver(e)}
                                    // onDragEnd={(e) => handleDragEnd(e)}
                                    // onDragLeave={(e) => handleDragLeave(e)}
                                    // onDrop={(e) => handleDragDrop(e, item)}
                                    // draggable={true}

                                     key={item.id}>
                                    <img src={item.path}
                                         className={'photo_preview'}
                                         onClick={() => {
                                             refPhotos.click()
                                             if (!headerToken()) {
                                                 setChangeCurrentPhoto(item)
                                             }
                                         }}
                                         alt="img"/>
                                    <img src={uploadPhotoPreview}
                                         className={'preview'}
                                         alt="uploadPhotoPreview"/>
                                    <img src={clearPhoto}
                                         className={'clear clear_first'}
                                         onClick={e => handleClearPhoto(e, item, index)}
                                         alt="clear"/>
                                </div>
                                : <div className="every_photo"
                                    // onDragStart={(e) => handleDragStart(e, item)}
                                    // onDragOver={(e) => handleDragOver(e)}
                                    // onDragEnd={(e) => handleDragEnd(e)}
                                    // onDragLeave={(e) => handleDragLeave(e)}
                                    // onDrop={(e) => handleDragDrop(e, item)}
                                    // draggable={true}
                                       key={item.id}>
                                    <img src={item.path}
                                         className={'photo_preview'}
                                         onClick={() => {
                                             refPhotos.click()
                                             if (!headerToken()) {
                                                 setChangeCurrentPhoto(item)
                                             }
                                         }}
                                         alt="img"/>
                                    <img src={clearPhoto}
                                         onClick={e => {
                                             e.preventDefault()
                                             handleClearPhoto(e, item, index)
                                         }}
                                         className={'clear'}
                                         alt="clear"/>
                                </div>
                    })}
                </div>
                <div className={'upload_cv_wrapper'}>
                    <input type="file"
                           multiple={true}
                           accept="image/jpeg,image/png, .jpeg,.jpg,.png"
                        // accept=".jpg, .jpeg, .png .doc .docx .pdf"
                           ref={input => refPhotos = input}
                           onChange={e => {
                               if (!e) {
                                   setChangeCurrentPhoto(null)
                               } else {
                                   uploadPhotos(e, changeCurrentPhoto)
                               }
                           }}/>

                    <MainButton text={'Find on Computer'}
                                svg={uploadIcon()}
                                onClick={() => refPhotos.click()}
                                hoverDisable={true}
                        // className={registrationData.cv ? 'button_bordered' : 'button_purple'}
                                style={{width: 'max-content', padding: '10px 16px 10px 16px'}}/>
                    <div>
                        <span>Upload up to 5 images</span>
                    </div>
                </div>
            </div>
            <div className={'upload_cv_wrapper wrapper_video'}>
                <input type="file"
                       accept={'video/*'}
                       ref={input => refVideo = input}
                       onChange={e => uploadVideo(e)}/>
                <span>Your Video</span>
                <div className={'video_preview'}
                     onClick={!artistVideo.preview ? () => refVideo.click() : null}>
                    {artistVideo.isLoaded && artistVideo.preview ?
                        <div className={'wrapper_loader'}>
                            <Loader color={{
                                '--bgColor': '#bc4386',
                                '--size': '32px',
                                '--wrapper_size': '40px'
                            }}/>
                            <span>Uploading file ...</span>
                        </div>
                        : null}
                    {videoLoading ?
                        <video controls={artistVideo.preview ? "controls" : null}
                               ref={refVideo}
                               preload="metadata">
                            <source src={artistVideo.preview + '#t=0.001'}
                                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                        </video>
                        : null}
                </div>
                <MainButton text={artistVideo.preview ? 'Change video' : 'Find on Computer'}
                            svg={uploadIcon()}
                            hoverDisable={true}
                            onClick={() => refVideo.click()}
                    // className={registrationData.cv ? 'button_bordered' : 'button_purple'}
                            style={{width: 'max-content', padding: '10px 16px 10px 16px', marginTop: '20px'}}/>
            </div>

        </div>
    )
}
