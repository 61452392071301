import Analytics from "analytics";
import simpleAnalyticsPlugin from "analytics-plugin-simple-analytics";
import API from "../api/API";


const ViewAnalytic = request => {
    const analytics = Analytics({
        app: "awesome-app",
        debug: true,
        plugins: [
            // Load simple analytics! 🎉
            simpleAnalyticsPlugin(),
        ],
    });
    analytics.page()

    let _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
        let u = "https://zenhugle7898b6netlifyapp.matomo.cloud/";
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', '1']);
        let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.src = '//cdn.matomo.cloud/zenhugle7898b6netlifyapp.matomo.cloud/matomo.js';
        s.parentNode.insertBefore(g, s);
    })();

    API().get(request)
        .catch(e => console.log(e))
};
export default ViewAnalytic