import React from 'react'

const SalesCommission = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>3. Sales Commission</h3>
            <div className={'terms_of_service_item_container'}>
                <span>3.1</span>
                <span>
                    All sales on Artsted are covered by the Buyer and Seller protection scheme, where both parties
                    transact safely and securely. The platform is entitled to a fixed commission of 35% on final listing
                    and sale price. Discounts and promotions up to 10% of the final listing price on some items may be
                    applicable and are not subject to Seller approval. The payout to the Seller is made
                    automatically after the return policy period expiries on the Buyer's side.
                </span>
            </div>
        </div>
    )
}

export default SalesCommission