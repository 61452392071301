import React from 'react';
import {usePlacesWidget} from "react-google-autocomplete";
import TextInput from "./TextInput";

const AutocompleteTextInput = ({onAutocomplete, onSelect, ...props}) => {
    const {ref: autoFillProp} = usePlacesWidget({
        apiKey: process.env.REACT_APP_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            onSelect?.(place?.formatted_address);
            const address_components = place?.address_components;
            let components = {};
            if (address_components) {
                address_components.forEach(v1 => {
                        v1.types.forEach(v2 => {
                            components[v2] = (v2 === "administrative_area_level_1" || v2 === "country") ? {
                                value: v1.short_name,
                                preview: v1.long_name
                            } : v1.long_name
                        });
                    }
                );

                if (components.street_number) {
                    const address = (components.street_number ? (components.street_number + " ") : "") +
                        (components.route ?? "")
                    const city = (components.locality ?? "")
                    const state = (components.administrative_area_level_1 ?? "")
                    const postcode = (components.postal_code ?? "")
                    const country = (components.country.preview ?? "")
                    const country_code = (components.country.value ?? "")

                    onAutocomplete?.({country, country_code, address, city, state, postcode})
                }
            }
        },
        inputAutocompleteValue: "country",
        options: {
            types: ['geocode'],
            language: "en",
        },
    });
    return (
        <TextInput {...props} autoFillProp={autoFillProp}/>
    )
};

export default AutocompleteTextInput;
