import React from "react";
import {useDropzone} from "react-dropzone";
import './styles/dropzone_styles.css';
import artist_avatar_default from "../assets/img/new_landing/profile_avatar_default.svg";
import investor_avatar_default from "../assets/img/new_landing/investor_avatar_default.svg";
import API from "../api/API";
import {useDispatch, useSelector} from "react-redux";
import {setUserData} from '../main-store/auth/actions'

export default function DropzoneAvatarContainer(props) {
    const {data, setData, profilePhoto} = props;
    let dispatch = useDispatch()
    let store = useSelector(store => store.auth)

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: (acceptedFiles, fileRejections) => {
            fileRejections.forEach(el => el.errors.some(item => {
                if (item.message.includes("File type must be image/*")) {
                    alert('File type must be image')
                    return null
                } else {
                    return null
                }
            }))
            if (fileRejections.length > 1) {
                alert('Maximum allowed 1 photo')
                return null
            } else {
                let formData = new FormData();
                acceptedFiles.map(file => {
                    return (Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    }))
                })

                if (profilePhoto) {
                    formData.append('photo', acceptedFiles[0])
                    API().post(`/user/profile/edit_photo`, formData)
                        .then(() => {
                            dispatch(setUserData({
                                ...store.user_data,
                                avatar: acceptedFiles[0].preview
                            }))
                            setData(prevState => ({
                                ...prevState,
                                photo: acceptedFiles[0].preview
                            }))
                        })
                }
            }
        }
    })

    return (
        <div onClickCapture={(e) => {
            if (data.photo) {
                e.preventDefault();
            }
        }} {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div className={'photo_wrapper'}>
                {data.photo
                    ? <div className={'change_avatar_wrapper'}>
                        <div onClick={() => {
                            API().delete(`/user/profile/edit_photo`)
                                .then(() => {
                                    dispatch(setUserData({
                                        ...store.user_data,
                                        avatar: null
                                    }))
                                    setData(prevState => ({
                                        ...prevState,
                                        photo: null
                                    }))
                                })
                        }} className={'delete_avatar'}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 5L27.6274 27.6274" stroke="white" strokeWidth="2"/>
                                <path d="M5 27L27.6274 4.37258" stroke="white" strokeWidth="2"/>
                            </svg>
                        </div>
                        <img src={data.photo} alt="avatar"/>
                    </div>
                    : <div className={'upload_avatar_wrapper'}>
                        <div className={'change_avatar'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.04772 6.67676L10.5634 4.16111V17.4688C10.5634 17.792 10.6918 18.102 10.9203 18.3306C11.1489 18.5591 11.4589 18.6875 11.7821 18.6875C12.1054 18.6875 12.4153 18.5591 12.6439 18.3306C12.8725 18.102 13.0009 17.792 13.0009 17.4688V4.16111L15.5165 6.67676C15.6296 6.79044 15.764 6.88069 15.912 6.94235C16.06 7.00401 16.2187 7.03586 16.379 7.03607C16.5393 7.03629 16.6981 7.00487 16.8463 6.94362C16.9944 6.88237 17.1291 6.79248 17.2424 6.67911C17.3558 6.56574 17.4457 6.43112 17.5069 6.28295C17.5682 6.13479 17.5996 5.97599 17.5994 5.81567C17.5992 5.65534 17.5673 5.49663 17.5057 5.34863C17.444 5.20064 17.3538 5.06626 17.2401 4.9532L12.6439 0.356986C12.5308 0.243808 12.3964 0.15403 12.2485 0.0927778C12.1007 0.031526 11.9422 0 11.7821 0C11.6221 0 11.4636 0.031526 11.3157 0.0927778C11.1678 0.15403 11.0335 0.243808 10.9203 0.356986L6.32415 4.9532C6.21048 5.06626 6.12023 5.20064 6.05857 5.34863C5.99691 5.49663 5.96506 5.65534 5.96484 5.81567C5.96463 5.97599 5.99604 6.13479 6.0573 6.28295C6.11855 6.43112 6.20844 6.56574 6.32181 6.67911C6.43518 6.79248 6.5698 6.88237 6.71796 6.94362C6.86613 7.00487 7.02492 7.03629 7.18525 7.03607C7.34558 7.03586 7.50429 7.00401 7.65228 6.94235C7.80028 6.88069 7.93466 6.79044 8.04772 6.67676Z"
                                    fill="#F7F6F1"/>
                                <path
                                    d="M22.3438 10.5625C22.0205 10.5625 21.7105 10.6909 21.482 10.9195C21.2534 11.148 21.125 11.458 21.125 11.7812V21.125H2.4375V11.7812C2.4375 11.458 2.3091 11.148 2.08054 10.9195C1.85198 10.6909 1.54198 10.5625 1.21875 10.5625C0.895517 10.5625 0.585524 10.6909 0.356964 10.9195C0.128404 11.148 0 11.458 0 11.7812V21.5312C0 22.07 0.214006 22.5866 0.594939 22.9676C0.975873 23.3485 1.49253 23.5625 2.03125 23.5625H21.5312C22.07 23.5625 22.5866 23.3485 22.9676 22.9676C23.3485 22.5866 23.5625 22.07 23.5625 21.5312V11.7812C23.5625 11.458 23.4341 11.148 23.2055 10.9195C22.977 10.6909 22.667 10.5625 22.3438 10.5625Z"
                                    fill="#F7F6F1"/>
                            </svg>
                        </div>
                        <img src={data.role === 'artist' ? artist_avatar_default : investor_avatar_default}
                             alt="avatar"/>
                    </div>}
            </div>
        </div>
    )
}