import React, {useCallback, useEffect, useState} from 'react'
import './styles/new_artist_public.css';
import API from "../api/API";
import {useLocation} from "react-router";
import NewUserInformationWrapper from "../Components/NewUserInformationWrapper";
import ArtworkInfoCard from "../Components/ArtworkInfoCard";
import {Loader} from "../Components/Loader";
import NewPaginationView from "../Components/NewPaginationView";
import {getActivePage} from "../Constants/getActivePage";
import './styles/new_artist_gallery.css'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import SEO from "../Components/SEO";
import userPhoto from "../assets/img/avatar.png";

const NewArtistProfilePublicGallery = () => {
    const location = useLocation();
    const [activeArtistData, setActiveArtistData] = useState({loading: false});
    const [profileInfo, setProfileInfo] = useState({loading: false});

    const getUserId = useCallback(() => {
        const nameArr = location.pathname.split('/')[2]?.split('-')
        return nameArr[nameArr.length - 1]
    }, [location.pathname])

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        if (!isNaN(Number(getUserId()))) {
            API().get(`/public/profile?id=${getUserId()}`)
                .then(res => {
                    setProfileInfo({
                        loading: true,
                        ...res.data,
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            window.location.pathname = '404'
        }
    }, [getUserId])

    useEffect(() => {
        API().get(`/public/profile/artworks?category=all&id=${getUserId()}&page=1&pages=10`)
            .then(res => {
                setActiveArtistData({
                    loading: true,
                    ...res.data
                })
            })
            .catch(err => {
                if (err.response.data.error === "cant find user") {
                    window.location.href = '/404-redirect'
                }
            })
    }, [getUserId])

    const followArtist = () => {
        API().post(`/user/follow?id=${getUserId()}`)
            .catch(e => console.log(e))
    }

    return (
        <div className={'wrapper_artist_gallery new_artist_public profile_section_body'}>
            {profileInfo.loading ?
                <section className={'home_artists_header_wrapper'}>
                    <SEO title={`Arts by ${profileInfo.user.full_name} | Art investment: Artsted`}
                         image={profileInfo.user.photos?.length ? profileInfo.user.photos[0] : userPhoto}
                         description={`${profileInfo.user.full_name} the artist from Toronto ♦ Buying art as an investment with Art marketplace: Artsted`}/>

                    <NewUserInformationWrapper {...profileInfo}
                                               test={'test'}
                                               name={profileInfo.user.full_name}
                                               categories={profileInfo.user.categories}
                                               photo={profileInfo.user.photo}
                                               follow={profileInfo.user.follow}
                                               publicArtist={true}
                                               followArtist={followArtist}
                                               profileInfo={profileInfo}
                                               setProfileInfo={e => setProfileInfo(e)}/>
                </section>
                : null}
            <section className={'wrapper_gallery'}>
                <h2>Artworks</h2>
                <div className={'artworks_list wrapper_every_section'}>
                    {activeArtistData.loading ? activeArtistData.artworks?.length ?
                        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1400: 4, 1900: 5}}>
                            <Masonry gutter={'24px'}>
                                {activeArtistData.artworks.map((el, index) => (
                                    <div key={index}>
                                        <ArtworkInfoCard {...el}
                                                         activeData={activeArtistData}
                                                         setActiveData={val => setActiveArtistData(val)}
                                                         nestedArray={'artworks'}
                                                         index={index}/>
                                    </div>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                        : <span className={'no_items_style'}>No items</span>
                        : <Loader color={{
                            '--bgColor': '#000',
                            '--size': '48px',
                            '--wrapper_size': '40px'
                        }}/>}
                </div>
                {activeArtistData.loading && activeArtistData.total > 9 ?
                    <div className={'wrapper_new_pagination'}>
                        <NewPaginationView activePage={activeArtistData}
                                           onChange={pageNumber => {
                                               getActivePage(pageNumber)
                                               setActiveArtistData(prevState => ({
                                                   ...prevState,
                                                   page: pageNumber
                                               }))
                                           }}/>
                    </div>
                    : null}
            </section>
        </div>
    )
}

export default NewArtistProfilePublicGallery
