import propTypes from "prop-types";
import {useState, useRef} from "react";
import classes from "./dropdown.module.scss";
import classNames from 'classnames';
import OutsideClickHandler from '../../../../Constants/detectOutsideClick';

function Dropdown(props) {
    let dropDownMenuItems = [];
    const [inputValue, setInputValue] = useState('');
    const [isOpen, setOpen] = useState(false);
    const dropdownRef = useRef();

    const _changeInputValue = e => {
        setInputValue(e.target.value);
    }

    const _openMenu = () => {
        setOpen(true);
    }

    const _closeMenu = () => {
        setInputValue('');
        setOpen(false);
    };

    const _onClickItem = item => {
        if (typeof props.onChange !== 'function') {
            return;
        }

        props.onChange(item);
        _closeMenu();
    }

    const filteredDropDownItems = props.options.filter(opt => {
        return opt.toLowerCase().includes(inputValue.toLowerCase());
    });

    if (filteredDropDownItems.length) {
        dropDownMenuItems = filteredDropDownItems.map(opt => {
            return (
                <div key={opt} className={classes.menu__item} onClick={_onClickItem.bind(this, opt)}>{opt}</div>
            );
        })
    } else {
        dropDownMenuItems = [<div className={classes.menu__item}>No items</div>]
    }

    return (
        <OutsideClickHandler callback={_closeMenu}>
            <div
                className={
                    classNames(
                        classes.wrapper,
                        {[classes.is_open]: isOpen},
                        {[classes.error]: props.error}
                    )
                }
                ref={dropdownRef}
            >
                <div className={classes.control} onClick={(e) => {
                    if (isOpen){
                        _closeMenu()
                    }else {
                        _openMenu()
                    }
                }}>
                    {isOpen && (
                        <input placeholder="Start type..."
                               autoFocus={true}
                               value={inputValue}
                               onChange={_changeInputValue}
                               className={classes.input}
                        />
                    )}
                    {!isOpen && (
                        <p className={classes.control__value}>
                            {props.value || props.placeholder}
                        </p>
                    )}
                    <div className={classes.control__icon}/>
                </div>
                {isOpen && (
                    <div className={classes.menu}>
                        {dropDownMenuItems}
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
}

Dropdown.defaultProps = {
    placeholder: "Select item..."
}

Dropdown.propTypes = {
    onChange: propTypes.func.isRequired
}

export default Dropdown;