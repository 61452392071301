import React, {useEffect, useState, useRef} from 'react'
import './styles/landing_page.css'
import API from "../../api/API";
import Keys from "../../Constants/helper";
import TextInput from "../../Components/CustomComponents/TextInput";
import MainButton from "../../Components/CustomComponents/MainButton";
import useBottomPopup from "../../Constants/useBottomPopup";
import ChangedConfirmedPopup from "../../Components/CustomComponents/ChangedConfirmedPopup";
// import close_verified_icon from "../../assets/img/admin/close_icon_admin.svg";

const initialState = {
    main_header: {
        value: '',
        error: false,
        errorMessage: 'Please fill this label',
    },
    main_subheader: {
        value: '',
        error: false,
        errorMessage: 'Please fill this label',
    },
    post_header: {
        value: '',
        error: false,
        errorMessage: 'Please fill this label',
    },
    post_subheader: {
        value: '',
        error: false,
        errorMessage: 'Please fill this label',
    },
    post_text: {
        value: '',
        error: false,
        errorMessage: 'Please fill this label',
    }
}

export default function AdminLandingPage() {
    let refVideo = useRef();
    const [landingData, setLandingData] = useState(() => initialState)
    const [videoData, setVideoData] = useState({preview: null, value: null})
    const [loading, setLoading] = useState(false)
    let {successText, changingConfirmed, changeContinuePopup} = useBottomPopup();

    useEffect(() => {
        API(Keys.JWT_TOKEN_ADMIN).get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    // console.log(res)
                    // const [first, second, third] = res.data.for_artist_points

                    setLandingData(prevState => ({
                        main_header: {
                            ...prevState.main_header,
                            value: res.data.main_header ? res.data.main_header : '',
                        },
                        main_subheader: {
                            ...prevState.main_subheader,
                            value: res.data.main_subheader ? res.data.main_subheader : '',
                        },
                        post_header: {
                            ...prevState.post_header,
                            value: res.data.post_header ? res.data.post_header : '',
                        },
                        post_subheader: {
                            ...prevState.post_subheader,
                            value: res.data.post_subheader ? res.data.post_subheader : '',
                        },
                        post_text: {
                            ...prevState.post_text,
                            value: res.data.post_text ? res.data.post_text : '',
                        },
                    }))
                    setVideoData({preview: res.data.video_file, value: res.data.video_file})
                    setLoading(true)
                }
            })
    }, [])

    const handleLandingSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();

        const {main_header, main_subheader, post_subheader, post_text, post_header} = landingData
        const submitData = main_header.value && main_subheader.value && post_subheader.value && post_text.value && post_header.value

        if (submitData) {
            formData.append('main_header', main_header.value)
            formData.append('main_subheader', main_subheader.value)
            formData.append('post_subheader', post_subheader.value)
            formData.append('post_text', post_text.value)
            formData.append('post_header', post_header.value)
            if ((videoData?.value)?.toString() === '[object File]') {
                formData.append('video_file', videoData.value)
            }
            // for (let key of formData.entries()) {
            //     console.log(key)
            // }
            API(Keys.JWT_TOKEN_ADMIN).post(`/admin/landing`, formData)
                .then(() => {
                    // console.log(res)
                    changeContinuePopup()
                    setTimeout(() => {
                        changeContinuePopup()
                    }, 2000)
                })
        } else {
            Object.entries(landingData).forEach(item => {
                if (!item[1].value) {
                    setLandingData(state => ({
                        ...state,
                        [item[0]]: {...state[item[0]], error: true}
                    }))
                }
            })
        }
    }

    const uploadVideo = event => {
        event.preventDefault();
        setLoading(false)
        setTimeout(() => {
            setLoading(true)
            let file = event.target.files[0];
            if (file) {
                setVideoData({
                    preview: URL.createObjectURL(file),
                    value: file
                })
            }
        }, 10)
    }

    return (
        <>
            <ChangedConfirmedPopup successText={successText}
                                   changingConfirmed={changingConfirmed}/>
            <form className={'admin_content_wrapper admin_content_wrapper_landing'}
                  onSubmit={(e) => handleLandingSubmit(e)}>
                <h1>Change information on the landing page</h1>
                <div className={'mb-3'}>
                    <p>Landing title</p>
                    <TextInput value={landingData?.main_header.value}
                               error={landingData?.main_header.error}
                               maxLength={255}
                               errTitle={landingData?.main_header.errorMessage}
                               onChange={({target}) => {
                                   setLandingData(prevState => ({
                                       ...prevState,
                                       main_header: {
                                           ...prevState.main_header,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}/>
                </div>

                <div className={'mb-3'}>
                    <p>Landing description</p>
                    <TextInput value={landingData?.main_subheader.value}
                               error={landingData?.main_subheader.error}
                               maxLength={255}
                               errTitle={landingData?.main_subheader.errorMessage}
                               onChange={({target}) => {
                                   setLandingData(prevState => ({
                                       ...prevState,
                                       main_subheader: {
                                           ...prevState.main_subheader,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}/>
                </div>

                {/*<div className={'header_photos'}>*/}
                {/*    <p>Photo header</p>*/}
                {/*    {imagesWrapper(headerPhotosArray)}*/}
                {/*</div>*/}

                <div className={'admin_landing_page_line'}/>

                <div className={'mb-3'}>
                    <p>Video block title</p>
                    <TextInput value={landingData?.post_header.value}
                               error={landingData?.post_header.error}
                               maxLength={255}
                               errTitle={landingData?.post_header.errorMessage}
                               onChange={({target}) => {
                                   setLandingData(prevState => ({
                                       ...prevState,
                                       post_header: {
                                           ...prevState.post_header,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}/>
                </div>

                <div className={'mb-3'}>
                    <p>Video block subtitle</p>
                    <TextInput value={landingData?.post_subheader.value}
                               error={landingData?.post_subheader.error}
                               maxLength={512}
                               errTitle={landingData?.post_subheader.errorMessage}
                               onChange={({target}) => {
                                   setLandingData(prevState => ({
                                       ...prevState,
                                       post_subheader: {
                                           ...prevState.post_subheader,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}/>
                </div>

                <div className={'mb-3'}>
                    <p>Video block description</p>
                    <TextInput value={landingData?.post_text.value}
                               error={landingData?.post_text.error}
                               maxLength={512}
                               errTitle={landingData?.post_text.errorMessage}
                               onChange={({target}) => {
                                   setLandingData(prevState => ({
                                       ...prevState,
                                       post_text: {
                                           ...prevState.post_text,
                                           value: target.value,
                                           error: false,
                                       }
                                   }))
                               }}/>
                </div>

                <p>Video file</p>
                <div className={'wrapper_video'}>
                    <input type="file"
                           accept={'video/*'}
                           ref={input => refVideo = input}
                           onChange={e => uploadVideo(e)}/>
                    <div style={videoData?.preview ? {border: 'none'} : {border: '1px dashed rgba(0, 0, 0, .25)'}}
                         className={'video_preview'}
                         onClick={!videoData?.preview ? () => refVideo.click() : null}>
                        {loading ?
                            <video controls={videoData?.preview ? "controls" : null}
                                   ref={refVideo}
                                   preload="metadata">

                                <source src={videoData?.preview + '#t=0.001'}
                                        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                            </video>
                            : null}
                    </div>
                    <MainButton text={videoData?.preview ? 'Change video' : 'Find on Computer'}
                                svg={<svg xmlns="http://www.w3.org/2000/svg" width="16.533" height="19.199"
                                          viewBox="0 0 16.533 19.199">
                                    <g id="noun_Attachment_1515241" transform="translate(600.602 -272.913)">
                                        <path id="Path_4566"
                                              d="M43.9 33.684a.889.889 0 1 0-1.253-1.253L35.981 39.1a2.769 2.769 0 0 0 0 3.92 2.835 2.835 0 0 0 3.92 0l6.666-6.666a4.657 4.657 0 1 0-6.587-6.587l-6.666 6.667a6.543 6.543 0 1 0 9.253 9.253l4.889-4.889a.889.889 0 1 0-1.256-1.256l-4.886 4.888a4.77 4.77 0 0 1-6.746-6.746l6.666-6.666a2.885 2.885 0 0 1 4.08 4.08l-6.666 6.666a1 1 0 0 1-1.413-1.413z"
                                              fill="#fff" transform="translate(-632 244.513)"/>
                                    </g>
                                </svg>}
                                onClick={() => refVideo.click()}
                                style={{width: 'max-content', padding: '10px 16px 10px 16px', marginTop: '20px'}}/>
                </div>

                <div className={'admin_landing_page_line'}/>

                <button className={'submit'} type={'submit'}>Submit</button>
            </form>
        </>
    )
}
