import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import Wrapper from './OnboardingWrapper';
import Button from '../../Components/CustomComponents/Onboarding/Button';
import classes from './styles/type.module.scss';
import artist_img from '../../assets/img/onboarding/artist_card.svg';
import collector_img from '../../assets/img/onboarding/collector_card.svg';
import organization_img from '../../assets/img/onboarding/organization_card.svg';
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setUserRegistrationData} from "../../main-store/auth/actions";
import {capitalizeFirstLetter} from "../../Constants/capitalizeFirstLetter";
import API from "../../api/API";
import {Loader} from "../../Components/Loader";

const COLLECTOR = "collector";
const ARTIST = "artist";
const ORGANIZATION = "organization";

function Card(props) {
    const className = classNames(
        classes.card_wrapper,
        {[classes.selected]: props.selected}
    );

    let img, text;
    if (props.type === ARTIST) {
        img = artist_img;
        text = "Reach a global audience of collectors";
    } else if (props.type === COLLECTOR) {
        img = collector_img;
        text = "Collect artwork from future blue-chip artists";
    } else if (props.type === ORGANIZATION) {
        img = organization_img;
        text = "Grow your international network and streamline projects";
    }

    return (
        <div className={className} onClick={() => props.onClick(props.type)}>
            <div className={classes.card} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div className={classes.selected_border}/>
                <div className={classes.card_img_wrapper}>
                    <img className={classes.card__img} style={{height: 'auto'}} alt="card_img" src={img}/>
                </div>
                <div className={classes.card_content} style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
                    <div>
                        <h2 className={classes.card__title}>{capitalizeFirstLetter(props.type)}</h2>
                        <p className={classes.card__text}>{text}</p>
                    </div>
                    <div style={{flex: 1, display: 'flex', alignItems: 'flex-end'}}>
                        <div className={classes.card_check} style={{flex: 1}}>
                            <svg className={classes.card_check__icon} width="30" height="30" viewBox="0 0 30 30"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="1" y="1" width="28" height="28" rx="14" stroke="black" strokeWidth="2"
                                      fill={'transparent'}/>
                                <path d="M10 15L13.5 18.5L21 11" stroke="black" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                            <span
                              className={classes.card_check__text}>I'm {props.type === 'artist' ? 'an' : 'a'} {props.type}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Type() {
    const [type, setType] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch()
    const [artImgType, setArtImgType] = useState(null);
    const [loading, setLoading] = useState(false);

    const _continueHandler = () => {
        if (!type) {
            return;
        }
        dispatch(setUserRegistrationData({type: type}))
        history.push('/registration/auth')
    }

    useEffect(() => {
        API().get(`/admin/landing`)
          .then(res => {
              if (res.data) {
                  setArtImgType(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                  setLoading(true)
              }
          })
    }, [])

    return (
      <Wrapper>
            <Wrapper.Form>
                <div className={classes.wrapper}>
                    <h1 className={classes.title}>Choose your account type</h1>
                    <div className={classes.cards}>
                        <Card type={COLLECTOR}
                              onClick={type => setType(type)}
                              selected={type === COLLECTOR}/>
                        <Card type={ARTIST} onClick={setType} selected={type === ARTIST}/>
                        <Card type={ORGANIZATION} onClick={setType} selected={type === ORGANIZATION}/>
                    </div>
                    <div className={classes.buttons}>
                        <Button variant="link" onClick={() => history.push('/login')}>
                            Already a member? Log In
                        </Button>
                        <Button onClick={_continueHandler}>Continue
                            <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.75167 7.88797L0.263672 4.30397L3.75167 0.719971H6.56767L3.07967 4.30397L6.56767 7.88797H3.75167Z"
                                    fill="black"/>
                            </svg>
                        </Button>
                    </div>
                </div>
            </Wrapper.Form>
            {artImgType && loading ?
                <Wrapper.Picture src={artImgType.photo} imgRight artAuthor={artImgType.user.name}
                                 authorAvatar={artImgType?.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
        </Wrapper>
    )
}

export default Type;
