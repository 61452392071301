import React from 'react'

const DataCollect = () => {
    return(
        <div>
            <h3 className={'terms_item_title'}>2. THE DATA WE COLLECT</h3>
            <div className={'privacy_policy_item_container'}>
                <span>Data Controller</span>
                <span>
                    Personal data, or personal information, means any information about an individual from which that
                    person can be identified. It does not include data where the identity has been removed
                    (anonymous
                    data).
                    <br/>
                    We may collect, use, store and transfer different kinds of personal data about you which we have
                    grouped together follows:
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Identity Data</span>
                <span>
                    includes first name, last name, username and ID, title, date of birth and gender
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Contact Data</span>
                <span>
                    includes billing address, delivery address, email address and telephone number.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Financial Data</span>
                <span>
                    includes payment card details and bank account (if you are a Seller).
                        We do
                        not
                        store or process your card details ourselves, they are processed and stored via one of our
                        contracted third party service providers. We encrypt your payment card details in your
                        browser
                        and securely transfer this data to our relevant third party payment provider to process a
                        payment.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Transaction Data</span>
                <span>
                    includes details about payments to and from you and other details
                        of
                        products
                        and services you have purchased from us or our Sellers.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Technical Data</span>
                <span>
                    includes internet protocol (IP) address, your login data, browser
                        type and
                        version, time zone setting and location, browser plug-in types and versions, operating
                        system
                        and platform and other technology on the devices you use to access this website.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Profile Data</span>
                <span>
                    includes your username and password, purchases or orders made by you,
                        your
                        interests, artists / artworks / users you like / follow / dislike, preferences, a link to
                        your
                        Facebook account if you register using Facebook Connect, feedback and survey responses.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Messaging Data</span>
                <span>
                    includes messages sent to or received from Sellers and Artsted staff,
                        including
                        use of our Make an Offer and Commission an Artist features.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Usage Data</span>
                <span>
                    includes information about how you use our website, products and
                        services.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Marketing and Communications Data</span>
                <span>
                    includes your preferences in receiving marketing
                        from us
                        and
                        our third parties and your communication preferences.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>Seller Application Data</span>
                <span>
                    includes any details provided when applying to become a
                        Seller.
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>
                    We also collect, use and share Aggregated Data such as statistical or demographic data for any
                        purpose. Aggregated Data may be derived from your personal data but is not considered personal
                        data in law as this data does not directly or indirectly reveal your identity. For example, we
                        may aggregate your Usage Data to calculate the percentage of users accessing a specific website
                        feature. However, if we combine or connect Aggregated Data with your personal data so that it
                        can directly or indirectly identify you, we treat the combined data as personal data which will
                        be used in accordance with this Privacy Policy
                </span>
                <span>
                    We do not ordinarily collect any Special Categories of Personal Data about you (this includes
                        details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                        orientation, political opinions, trade union membership, information about your health and
                        genetic and biometric data). Nor do we collect any information about criminal convictions and
                        offences. If you use any of these types of personal data when using any of the website's
                        features (including messaging system), in your public artist or user bio or when selling
                        artworks through Artsted, we will not be able to tell. We will treat all the personal data
                        included in your user or artist profile or artwork listings as ordinary personal data
                </span>
            </div>
            <div className={'privacy_policy_item_container'}>
                <span>IF YOU FAIL TO PROVIDE PERSONAL DATA</span>
                <span>
                    Where we need to collect personal data by law, or under the terms of a contract we have with you
                        and you fail to provide that data when requested, we may not be able to perform the contract we
                        have or are trying to enter into with you (for example, to provide you with goods or services).
                        In this case, we may have to cancel a product or service you have with us but we will notify you
                        if this is the case at the time.
                </span>
            </div>
        </div>
    )
}

export default DataCollect