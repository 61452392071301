import React, {Component} from 'react'
// import MainButton from '../Components/CustomComponents/MainButton'
// import {useHistory} from "react-router-dom"
// import './styles/404.css';

export class PageNotFound extends Component {

    componentWillMount() {
        window.open("/not-found", "_self")
    }

    render() {
        return (<section>Redirecting...</section>);
    }
}

//
// export default function PageNotFound() {
//     const history = useHistory();
//
//     return (
//         <div className={'page_not_found_wrapper'}>
//             <div className={'forward_block_page'}>
//                 <h1>404</h1>
//                 <p>PAGE NOT FOUND</p>
//                 <div className={'return_button_page'}>
//                     <MainButton text={'Go back'}
//                                 className={'button_black'}
//                                 onClick={() => history.push('/')}/>
//                 </div>
//             </div>
//         </div>
//     )
// }
