import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router";
import Wrapper from "./OnboardingWrapper";
import Button from "../../Components/CustomComponents/Onboarding/Button";
//import artwork_img from "../../assets/img/onboarding/artwork2.png";
import Input from "../../Components/CustomComponents/Onboarding/Input";
import './styles/reset_password.css';
import API from "../../api/API";
import {Loader} from "../../Components/Loader";

const ResetPassword = (props) => {
    const [codeActive, setCodeActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [randomLoading, setRandomLoading] = useState(false)
    const [email, setEmail] = useState({value: '', checked: false})
    const [passwords, setPasswords] = useState({repeat: '', new: ''})
    const [error, setError] = useState(null)
    const [submitResponse, setSubmitResponse] = useState(null)
    const [done, setDone] = useState(false)
    const [artImgResetPassword, setArtImgResetPassword] = useState(null);
    let slug = useParams()
    let history = useHistory();

    useEffect(() => {
        if (props?.location?.email) {
            API().post('/user/registration/check_email', {email: props.location.email})
                .then(res => {
                    setEmail({checked: res.data.result, value: props.location.email})
                })
        }

        if (slug.code) {
            setCodeActive(true)
        } else {
            setCodeActive(false)
        }
        setLoading(true)
    }, [props.location.email, slug])


    const emailChange = ({target}) => {
        if (target.value.length > 4) {
            API().post('/user/registration/check_email', {email: target.value})
                .then(res => {
                    setEmail(prevState => ({...prevState, checked: res.data.result}))
                    if (!res.data.result) {
                        setSubmitResponse(null)
                        setError(null)
                    }
                })
        } else {
            setEmail(prevState => ({...prevState, checked: false}))
        }
        setEmail(prevState => ({...prevState, value: target.value}))
    }

    const _onSubmit = event => {
        event.preventDefault()
        if (done) {
            history.push('/login')
            return null
        }
        if (codeActive) {
            if (passwords.repeat === passwords.new) {
                API().put(`/user/forgot_password`,
                    {
                        password: passwords.new,
                        code: slug.code
                    })
                    .then(() => {
                        setSubmitResponse('Password Reset Successfully')
                        setTimeout(() => {
                            setSubmitResponse(null)
                            setError(null)
                            setDone(true)
                        }, 1000)
                        setError(null)
                    })
                    .catch(() => {
                        setSubmitResponse(null)
                        setError("Token has expired")
                        setTimeout(() => {
                            setSubmitResponse(null)
                            setError(null)
                            setDone(true)
                        }, 1000)
                    })
            } else {
                setError("Passwords isn't similar")
            }
        } else {
            API().post(`/user/forgot_password`, {email: email.value})
                .then(() => {
                    setSubmitResponse('We’ve sent you a mail with a recovery link')
                    setError(null)
                })
                .catch(e => {
                    if (e.response?.data?.error === 'too often') {
                        setSubmitResponse(null)
                        setError("Too often")
                    } else {
                        setSubmitResponse(null)
                        setError("Can't find the user")
                    }
                })
        }
    }

    useEffect(() => {
        //todo: delete later
        document.getElementsByTagName('body')[0].classList.add('new_landing_fonts')
        return () => document.getElementsByTagName('body')[0].classList.remove('new_landing_fonts')
    }, [])

    useEffect(() => {
        API().get(`/admin/landing`)
            .then(res => {
                if (res.data) {
                    setArtImgResetPassword(res.data.artworks_top[Math.floor(Math.random() * res.data.artworks_top.length)])
                    setRandomLoading(true)
                }
            })
    }, [])

    return (
        <Wrapper>
            <Wrapper.Form>
                {loading ?
                    <div className={'wrapper'}>
                        <h1 className={'reset_title'}>{codeActive ? 'New Password' : 'Reset Password'}</h1>
                        <p className={'description'}>After you insert your e-mail you will receive a message with a
                            password recovery link.</p>
                        <form className={'reset_form'} onSubmit={_onSubmit}>
                            {codeActive ?
                                <>
                                    <Input value={passwords.new}
                                           onChange={({target}) => {
                                               setPasswords(prevState => ({
                                                   ...prevState,
                                                   new: target.value.trim()
                                               }))
                                               setError(null)
                                               setDone(null)
                                           }}
                                           title="Password"
                                           error={null}
                                           type="password"
                                           autoComplete="off"
                                    />
                                    <Input value={passwords.repeat}
                                           onChange={({target}) => {
                                               setPasswords(prevState => ({
                                                   ...prevState,
                                                   repeat: target.value.trim()
                                               }))
                                               setDone(null)
                                               setError(null)
                                           }}
                                           title="Repeat Password"
                                           error={null}
                                           type="password"
                                           autoComplete="off"
                                    />
                                </> :
                                <Input value={email.value}
                                       onChange={emailChange}
                                       title="E-Mail"
                                       error={null}
                                       checkEmail={email.checked}
                                       type="text"
                                       autoComplete="off"
                                />}
                            <div className={'wrapper_info'}
                                 style={submitResponse || error ?
                                     {display: 'grid', gridTemplateColumns: '1fr max-content'} :
                                     {display: 'grid', gridTemplateColumns: 'max-content', justifyContent: 'end'}}>
                                {submitResponse || error ?
                                    <div className="info">
                                        {error ?
                                            <svg width="30" height="27" viewBox="0 0 30 27" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M29.6737 23.0092L17.0844 1.20393C16.1578 -0.401016 13.8426 -0.401601 12.9156 1.20393L0.326278 23.0092C-0.600324 24.6141 0.556786 26.6195 2.41069 26.6195H27.5892C29.4425 26.6195 30.6006 24.6147 29.6737 23.0092ZM27.5892 24.4138H2.41069C2.25425 24.4138 2.15798 24.2477 2.23638 24.112L14.8257 2.30672C14.9039 2.17119 15.0958 2.1709 15.1742 2.30672L27.7635 24.1119C27.8418 24.2475 27.7461 24.4138 27.5892 24.4138Z"
                                                    fill="#FF4949"/>
                                                <path
                                                    d="M15.0012 19.1084C14.3922 19.1084 13.8984 19.6022 13.8984 20.2112V20.8697C13.8984 21.4788 14.3922 21.9725 15.0012 21.9725C15.6103 21.9725 16.104 21.4788 16.104 20.8697V20.2112C16.1041 19.602 15.6103 19.1084 15.0012 19.1084Z"
                                                    fill="#FF4949"/>
                                                <path
                                                    d="M15.0012 9.14941C14.3922 9.14941 13.8984 9.64318 13.8984 10.2522V16.5739C13.8984 17.1829 14.3922 17.6767 15.0012 17.6767C15.6103 17.6767 16.104 17.1829 16.104 16.5739V10.2522C16.1041 9.64318 15.6103 9.14941 15.0012 9.14941Z"
                                                    fill="#FF4949"/>
                                            </svg> :
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                                 className={'check_icon'}
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect x="1" y="1" width="28" height="28" rx="14" stroke="#17A300"
                                                      strokeWidth="2"/>
                                                <path d="M10 15L13.5 18.5L21 11" stroke="#17A300" strokeWidth="2"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>}
                                        <span style={error ? {color: '#FF4949'} : {color: '#17A300'}}>
                                            {submitResponse ?? error}
                                        </span>
                                    </div>
                                    : <Button type="submit">
                                        {codeActive ?
                                            done ? 'Go to login' :
                                                error === 'Code Expired' ?
                                                    'Resend Code' :
                                                    'Reset Password' :
                                            'Send Code'}</Button>}
                            </div>
                        </form>
                    </div>
                    : null}
            </Wrapper.Form>
            {randomLoading && artImgResetPassword ?
                <Wrapper.Picture src={artImgResetPassword.photo} imgRight artAuthor={artImgResetPassword.user.name}
                                 authorAvatar={artImgResetPassword.user.avatar}/>
                : <Loader color={{
                    '--bgColor': '#000',
                    '--size': '48px',
                    '--wrapper_size': '40px'
                }}/>}
        </Wrapper>
    )
}

export default ResetPassword