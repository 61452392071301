import React from 'react';
import CartTimer from "./CartTimer";
import {bodyOverflow} from "../../Constants/bodyOverflow";
import {setNewHeaderBucket} from "../../main-store/auth/actions";
import {useDispatch} from "react-redux";
import CartSidebarArtwork from "./CartSidebarArtwork";
import PromoCode from "./Promocode";
import FinalPrice from "./FinalPrice";
import Subscription from "./Subscription";
import moment from "moment";
import {TrustpilotIcon} from "../../assets/icons";
import MainButton from "../CustomComponents/MainButton";

const CartSidebar = ({
                         activeData,
                         setActiveModal,
                         shippingDetails,
                         setShippingDetails,
                         price,
                         currency,
                         currencies,
                         activeStep,
                         setActiveStep,
                         activeSubscription,
                         selectedShippingAddress,
                         submitCheckout,
                         subscriptionCheckout,
                         checkout
                     }) => {
        const lastArtworkAdded = activeData?.sort((a, b) => moment(b.added_at, "DD.MM.YYYY hh:mm:ss").diff(moment(a.added_at, "DD.MM.YYYY hh:mm:ss")))
        console.log(lastArtworkAdded)
        const dispatch = useDispatch();
        console.log(activeData)

        const removeArtwork = id => {
            bodyOverflow(true)
            setActiveModal(state => state !== id ? id : null)
            dispatch(setNewHeaderBucket(activeData?.length))
        }

        const onContinue = () => {
            if (activeStep?.active === 2) {
                setActiveStep({access: 3, active: 3})
            } else {
                if (activeSubscription?.data) {
                    subscriptionCheckout()
                } else {
                    submitCheckout()
                }
            }
        }

        console.log(lastArtworkAdded)
        return (
            <div className="user_cart_price_checkout">
                <span className="title">Your cart</span>
                {activeSubscription?.value
                    ? <>
                        <Subscription activeSubscription={activeSubscription}/>
                        <PromoCode shippingDetails={shippingDetails} setShippingDetails={setShippingDetails} noApply/>
                        <FinalPrice price={price} currency={currency} shippingDetails={shippingDetails}/>
                    </>
                    : activeData?.length
                        ? <>
                            {activeData?.length && <CartTimer startTime={lastArtworkAdded[0].added_at}/>}
                            <div className="cart-items">
                                {activeData.map((i, idx) => {
                                    const artwork = i.artwork ?? i
                                    return (
                                        <CartSidebarArtwork
                                            i={artwork}
                                            idx={idx}
                                            currency={currency}
                                            currencies={currencies}
                                            removeArtwork={removeArtwork}
                                        />
                                    )
                                })}
                            </div>
                            <PromoCode shippingDetails={shippingDetails} setShippingDetails={setShippingDetails}/>
                            <FinalPrice price={price} currency={currency} shippingDetails={shippingDetails}/>
                        </>
                        : <span className="empty">No items</span>}

                {(!!activeData?.length || !!activeSubscription?.value) && activeStep?.active > 1 && (
                    <MainButton disabled={!selectedShippingAddress.id || (activeStep?.active === 3 && checkout.disabled)}
                                onClick={onContinue}
                                text={activeStep?.active === 3 ? "Checkout" : "Continue"}/>
                )}

                <TrustpilotIcon
                    className={activeData?.length > 1 ? 'extend' : ""}
                    onClick={() => window.open("https://www.trustpilot.com/review/artsted.com")}
                />
            </div>
        );
    }
;

export default CartSidebar;
