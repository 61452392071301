import {Table} from "antd";
import clsx from "clsx";

import {ArrowLeft2Icon, ArrowRight2Icon} from "../../../assets/icons";

import styles from './StyledTable.module.css';

export default function StyledTable({className, pagination, ...props}) {
    return (
        <Table
            scroll={{x: 1000}}
            showSorterTooltip={false}
            pagination={{
                hideOnSinglePage: true,
                showSizeChanger: false,
                ...pagination,
                rootClassName: styles.pagination,
                itemRender: (page, v) => (
                    <div>
                        {v === 'prev' && (
                            <div className={styles.paginationButtons}>
                                <ArrowLeft2Icon width="1.5rem"/>
                                Previous
                            </div>
                        )}
                        {v === 'page' && page}
                        {v === 'jump-next' && <div className={styles.paginationDots}>...</div>}
                        {v === 'next' && (
                            <div className={styles.paginationButtons}>
                                Next
                                <ArrowRight2Icon width="1.5rem"/>
                            </div>
                        )}
                    </div>
                )
            }}
            {...props}
            className={clsx(styles.table, className)}
        />
    )
}