import Wrapper from '../OnboardingWrapper';
import Button from '../../../Components/CustomComponents/Onboarding/Button';
import classes from './styles/about.module.scss';
import artwork_img from '../../../assets/img/onboarding/artwork7.png';
import {useEffect, useRef, useState} from 'react';
import Error from '../../../Components/CustomComponents/Onboarding/Error';
import classNames from 'classnames';
import {setUserRegistrationData} from "../../../main-store/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import historyDetectBack from "../HistoryBackDetect";
import {useHistory} from "react-router";

function About() {
    const inputRef = useRef();
    const [textareaText, setText] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const store = useSelector(state => state.auth)

    useEffect(() => {
        _setHeight();
    })

    const _isValid = () => {
        if (!textareaText) {
            setError('Should not be empty');
            return false;
        }
    }

    const _onInputTextarea = e => {
        let value = e.target.value;

        if (value.length > 250) {
            value = value.slice(0, 250);
        }
        setError('');
        setText(value);
    }

    const _setHeight = () => {
        const field = inputRef.current;
        if (!field) {
            return;
        }

        field.style.height = 'inherit';
        const computed = window.getComputedStyle(field);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
            + parseInt(computed.getPropertyValue('padding-top'), 10)
            + field.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'), 10)
            + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

        field.style.height = height + 'px';
    }

    const _onSubmit = event => {
        event.preventDefault()
        if (!textareaText) {
            setError('Should not be empty');
            _isValid();
            return false;
        }
        dispatch(setUserRegistrationData({
            ...store.user_registration_data,
            about_yourself: textareaText,
        }))
        let {nextHistory} = historyDetectBack(store.user_registration_data, store.active_history)
        // console.log(nextHistory);
        history.push(nextHistory)
    }

    useEffect(() => {
        setText(store.user_registration_data.about_yourself ?? '')
    }, [store.user_registration_data.about_yourself])

    return (
        <Wrapper>
            <Wrapper.Picture src={artwork_img}/>
            <Wrapper.Form>
                <form className={classNames(classes.wrapper, {[classes.error]: error})}
                      onSubmit={_onSubmit}>
                    <h2 className={classes.title}>Tell us a little about yourself</h2>
                    <p className={classes.subtitle}>What is the focus of your artistic or visual research?
                        What are the topics you address in your work?</p>
                    <p className={classes.label}>Your explanation here</p>
                    <div className={classes.text_wrapper}>
                        <textarea
                            ref={inputRef}
                            className={classes.textarea}
                            placeholder='Type text here...'
                            value={textareaText}
                            onKeyDown={event => {
                                if (event.keyCode === 13) {
                                    if (!event.shiftKey && textareaText.trim()) {
                                        _onSubmit(event)
                                    }
                                }
                            }}
                            maxLength={250}
                            onInput={_onInputTextarea}
                        />
                    </div>
                    {error && <Error text={error} className={classes.error_component}/>}
                    <div className={classes.footer}>
                        <p className={classes.char_counter}>
                            {textareaText.length}/250 Characters
                        </p>

                        <Button>Continue &#62;</Button>
                    </div>
                </form>
            </Wrapper.Form>
        </Wrapper>
    );
}

export default About;