import classes from './styles/section.module.scss';
import cn from 'classnames';

function Section (props) {
    return (
        <div className={cn(classes.wrapper, {[classes.vertical_reverse]: props.vReverse})}>{props.children}</div>
    )
}

function Left (props) {
    return (
        <div className={classes.left}>{props.children}</div>
    )
}

function Right (props) {
    return (
        <div className={classes.right}>{props.children}</div>
    )
}

Section.Left = Left;
Section.Right = Right;

export default Section;