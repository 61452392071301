import React, {useMemo, useState} from "react";
import PieComponent from "../StatisticComponent/PieComponent";

const NewStatisticCategoryViews = ({data, innerWidth, loading}) => {
    const [COLOR] = useState(['#000', '#fff', '#F6F6F1', '#9D9D9D'])

    const mostPopularCategories = useMemo(() => {
        if (loading && data?.length) {
            return data.filter((i, index) => {
                i.title = i.name
                i.y = i.percent
                i.background = COLOR[index]
                delete i.name
                delete i.percent
                return i
            })
        }
    }, [COLOR, data, loading])

    return (
        <div className={'statistic_profile_views'}>
            <div className={'title'}>
                <span>Category views</span>
            </div>
            {loading && mostPopularCategories?.length ?
                <div className="statistic_wrapper__popular_category">
                    <PieComponent innerWidth={innerWidth}
                                  COLOR={COLOR}
                                  loading={loading}
                                  data={mostPopularCategories}/>
                    <div className={'info_wrapper'}>
                        <div className={'wrapper_views_info'}>
                            {mostPopularCategories.map((item, index) => {
                                return (
                                    <div className={'every'} key={index}>
                                        <span>{item.title}</span>
                                        <span className={'val'}>{item.y}%</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                : <span>No data yet</span>}
        </div>
    )
}

export default NewStatisticCategoryViews