import React from "react";
import {useDropzone} from "react-dropzone";
import './styles/dropzone_styles.css';
import MainButton from "./CustomComponents/MainButton";

export default function NftFileContainer(props) {

    const {data, setData, filesArray, disabled} = props;
    const {getRootProps, getInputProps} = useDropzone({
        // accept: any,
        maxFiles: 5,
        onDrop: (acceptedFiles, fileRejections) => {
            fileRejections.forEach(el => el.errors.some(item => {
                if (item.message.includes("File type must be application/pdf")) {
                    alert('File type must be .pdf')
                    return null
                } else {
                    return null
                }
            }))
            if (fileRejections.length > 5) {
                alert('maximum allowed 5 files')
                return null
            } else {
                setData(prevState => ({
                    ...prevState,
                    [filesArray]: {
                        ...prevState[filesArray],
                        files: acceptedFiles.map(file => {
                            return (Object.assign(file, {
                                preview: URL.createObjectURL(file)
                            }))
                        })
                    }
                }))
            }
        }
    })

    // console.log("______")
    // console.log('filesArray: ', filesArray,'data: ', data)
    // console.log("______")
    return [
        <div style={data[filesArray].length ? {gridGap: '21px'} : null} key={0} className={'file_previews'}>
            <div className={'uploaded_files_wrapper'}>
                {data[filesArray].files?.map((el, i) => {
                    return (
                        <a href={el.preview} target={'_blank'} key={i} rel="noopener noreferrer nofollow">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path
                                        d="M21.3266 7.64717C19.097 5.45094 15.4663 5.45094 13.2391 7.64717L7.06156 13.7276C7.02132 13.7672 7.00002 13.8209 7.00002 13.8768C7.00002 13.9328 7.02132 13.9864 7.06156 14.026L7.93493 14.8863C7.97485 14.9255 8.02889 14.9475 8.08523 14.9475C8.14156 14.9475 8.1956 14.9255 8.23552 14.8863L14.413 8.80591C15.1799 8.05051 16.2 7.63552 17.284 7.63552C18.368 7.63552 19.3882 8.05051 20.1527 8.80591C20.9195 9.5613 21.3408 10.5662 21.3408 11.6316C21.3408 12.6994 20.9195 13.702 20.1527 14.4574L13.8568 20.6567L12.8367 21.6616C11.8829 22.6011 10.3326 22.6011 9.37872 21.6616C8.91718 21.2069 8.66392 20.6031 8.66392 19.9596C8.66392 19.3161 8.91718 18.7123 9.37872 18.2576L15.6249 12.1072C15.7834 11.9534 15.9917 11.8671 16.2142 11.8671H16.2166C16.4391 11.8671 16.645 11.9534 16.8012 12.1072C16.9598 12.2635 17.045 12.4686 17.045 12.6878C17.045 12.9046 16.9574 13.1098 16.8012 13.2636L11.6959 18.2879C11.6556 18.3276 11.6343 18.3812 11.6343 18.4371C11.6343 18.4931 11.6556 18.5467 11.6959 18.5864L12.5692 19.4467C12.6092 19.4858 12.6632 19.5078 12.7195 19.5078C12.7759 19.5078 12.8299 19.4858 12.8698 19.4467L17.9728 14.4201C18.4438 13.9561 18.7018 13.3406 18.7018 12.6854C18.7018 12.0303 18.4414 11.4125 17.9728 10.9508C17 9.99262 15.4189 9.99495 14.4462 10.9508L13.8402 11.55L8.20239 17.1012C7.81974 17.4759 7.51642 17.9218 7.31002 18.4128C7.10362 18.9039 6.99825 19.4304 7.00002 19.9619C7.00002 21.0414 7.42842 22.0556 8.20239 22.818C9.00475 23.606 10.0556 24 11.1065 24C12.1574 24 13.2083 23.606 14.0083 22.818L21.3266 15.6138C22.4035 14.5506 23 13.1354 23 11.6316C23.0024 10.1255 22.4059 8.71032 21.3266 7.64717Z"
                                        fill="black"/>
                                </g>
                            </svg>
                            <span>{el.name}</span>
                            <svg onClick={(e) => {
                                e.preventDefault();
                                setData(prevState => ({
                                    ...prevState,
                                    [filesArray]: {
                                        ...prevState[filesArray],
                                        files: prevState[filesArray].files.filter((item, index) => index !== i)
                                    }
                                }))
                            }} width="26" height="26" viewBox="0 0 26 26" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.35">
                                    <path d="M8 8.28906L18.4566 18.7457" stroke="black" strokeWidth="1.5"/>
                                    <path d="M8 18.457L18.4566 8.00042" stroke="black" strokeWidth="1.5"/>
                                </g>
                            </svg>
                        </a>
                    )
                })}
            </div>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <MainButton type={'button'}
                            disabled={disabled}
                            className={`button_black ${disabled ? 'button_black_disabled' : ''}`}
                            text={'Upload NFT'}
                />
            </div>
        </div>
    ]
}
