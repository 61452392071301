import React, {useState, useEffect} from 'react';
import './styles.css'
import OutsideClickHandler from "../../../Constants/detectOutsideClick";
import {Tooltip} from "antd";

export default function CustomDropdown(props) {
    const [filterArray, setFilterArray] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const nft = (props?.type === 'NFT');

    // const [activeTabIndex, setActiveTabIndex] = React.useState(0);
    // let optionsRef = useRef()

    useEffect(() => {
        if (props.disabled !== undefined && props.disabled !== null) {
            setDisabled(props.disabled)
        }
    }, [props.disabled])

    useEffect(() => {
        if (props.unFilteredArray) {
            return setFilterArray(props.options)
        }
        let filterArray1;

        if (props.search && props.value) {
            filterArray1 = props.options.filter(el => props.typeId ?
                el.name.toLowerCase().includes(props.value.toLowerCase()) :
                el.name ? el.name.toLowerCase().includes(props.value.toLowerCase()) :
                    el.toLowerCase().includes(props.value.toLowerCase()))
        } else if (props.filterItem) {
            filterArray1 = props.options.map(el => el.name ?? el)
        } else {
            filterArray1 = props.options
        }
        setFilterArray(filterArray1)
    }, [props.options, props.search, props.typeId, props.value, props.landingPage, props.unFilteredArray, props.filterItem])

    const filterDropdown = props.options.filter(el => el.name ? el.name !== props.value : el !== props.value);

    const setActivePopup = (item, dropdownSelect) => {
        if (props.rootObject) {
            props.setInputValue(prevState => ({
                ...prevState,
                [props.rootObject]: {
                    ...prevState[props.rootObject],
                    [props.nestedObject]: {
                        ...prevState[props.rootObject][props.nestedObject],
                        [props.optionLabel]: {
                            ...prevState[props.rootObject][props.nestedObject][props.optionLabel],
                            popup: !prevState[props.rootObject][props.nestedObject][props.optionLabel].popup,
                            value: dropdownSelect ? item : prevState[props.rootObject][props.nestedObject][props.optionLabel].value,
                            error: false
                        }
                    }
                }
            }))
        } else if (props.nestedObject) {
            props.setInputValue(prevState => ({
                ...prevState,
                [props.nestedObject]: {
                    ...prevState[props.nestedObject],
                    [props.optionLabel]: {
                        ...prevState[props.nestedObject][props.optionLabel],
                        popup: !prevState[props.nestedObject][props.optionLabel].popup,
                        value: dropdownSelect ? item : prevState[props.nestedObject][props.optionLabel].value,
                        error: false
                    }
                }
            }))
        } else if (props.defaultDropdown || props.landingPage) {
            props.setInputValue(prevState => ({
                ...prevState,
                [props.optionLabel]: {
                    ...prevState[props.optionLabel],
                    popup: !prevState[props.optionLabel].popup,
                    value: dropdownSelect ? item : prevState[props.optionLabel].value,
                    error: false
                }
            }))
        } else {
            props.handleActiveTab(props.optionLabel, item, props.categoryDataIndex)
            props.setInputValue(prevState => ([
                ...prevState.slice(0, props.categoryDataIndex),
                {
                    ...props.categoryDataHandle,
                    [props.optionLabel]: {
                        ...props.categoryDataHandle[props.optionLabel],
                        popup: !prevState[props.categoryDataIndex][props.optionLabel].popup,
                        value: item
                    }
                },
                ...prevState.slice(props.categoryDataIndex + 1)
            ]))
        }
    }

    return (
        <Tooltip title={props.tooltip}>
            <div style={props.margin ? {margin: props.margin} : null}>
                {props.title ? <span
                    className={`custom_dropdown_wrapper_title ${props.borderDropdown ? 'new_custom_dropdown_wrapper_title' : ''}`}>{props.title}</span> : null}
                <OutsideClickHandler callback={() => {
                    if (props.popup) {
                        if (props.rootObject) {
                            props.setInputValue(prevState => ({
                                ...prevState,
                                [props.rootObject]: {
                                    ...prevState[props.rootObject],
                                    [props.nestedObject]: {
                                        ...prevState[props.rootObject][props.nestedObject],
                                        [props.optionLabel]: {
                                            ...prevState[props.rootObject][props.nestedObject][props.optionLabel],
                                            popup: false
                                        }
                                    }
                                }
                            }))
                        } else if (props.landingPage || props.defaultDropdown) {
                            props.setInputValue(prevState => ({
                                ...prevState,
                                [props.optionLabel]: {
                                    ...prevState[props.optionLabel],
                                    popup: false
                                }
                            }))
                        } else {
                            props.setInputValue(prevState => ([
                                ...prevState.slice(0, props.categoryDataIndex),
                                {
                                    ...props.categoryDataHandle,
                                    [props.optionLabel]: {
                                        ...props.categoryDataHandle[props.optionLabel],
                                        popup: false
                                    }
                                },
                                ...prevState.slice(props.categoryDataIndex + 1)
                            ]))
                        }
                    }
                }}>
                    <div style={props.dropdownBorder ? {border: props.dropdownBorder, borderRadius: '4px'} : null}
                         className={'custom_dropdown_wrapper'}>
                        <div
                            style={props.entryError ? {[props.borderBottom ? "borderBottom" : "border"]: '1px solid #FE3E3E'} : props.newError ? {borderBottom: '1px solid #FE3E3E'}
                                : props.error ? {border: '0.5px solid #FE3E3E'} : null}
                            className={disabled || props.dataArray?.length >= 5 || (nft && props.dataArray?.length >= 1) ? `custom_dropdown_wrapper_search custom_dropdown_wrapper_search_disabled ${props.borderDropdown ? 'new_dropdown_wrapper_search_disabled' : ''}` : props.borderDropdown ? 'new_dropdown_wrapper_search' : props.search ? 'custom_dropdown_wrapper_search' : null}>
                            {props.search ?
                                <label style={props.entryError || props.newError ? {border: 'none'} : null}
                                       className={props.borderDropdown ? 'custom_dropdown_border' : ''}>
                                    <input id={props.id ? props.id : null}
                                        // onKeyDown={key => onCustomSelect(key)}
                                        //    onFocus
                                        //    disable={props.unFilteredArray}
                                           style={props.style ? props.style : null}
                                           type={props.type ? props.type : 'text'}
                                           disabled={disabled || props.dataArray?.length >= 5}
                                           required={props.required ? props.required : null}
                                           name={props.name ? props.name : ''}
                                           placeholder={props.placeholder ? props.placeholder : null}
                                           className={props.error ? `error custom_dropdown_select` : 'custom_dropdown_select'}
                                           value={props.value ? props.value : ''}
                                           onChange={props.onChange ? e => {
                                               props.onChange(e)
                                           } : null}
                                           onClick={props.setInputValue ? () => {
                                               setActivePopup()
                                           } : null}
                                    />
                                    {props.newDropdownIcon ? props.newDropdownIcon
                                        : <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.99993 7.87459C4.99908 7.63606 5.09296 7.40694 5.26093 7.23759C5.42663 7.08139 5.64724 6.99683 5.87489 7.00226C6.10254 7.00769 6.31887 7.10267 6.47693 7.26659L8.85093 9.75659L11.2249 7.26659C11.3823 7.10149 11.5989 7.00568 11.8269 7.00024C12.055 6.99481 12.2758 7.08018 12.4409 7.23759C12.606 7.39499 12.7018 7.61154 12.7073 7.83958C12.7127 8.06763 12.6273 8.28849 12.4699 8.45359L9.60393 11.4646C9.50602 11.5652 9.38894 11.6452 9.25961 11.6998C9.13028 11.7544 8.99132 11.7826 8.85093 11.7826C8.7101 11.7856 8.57022 11.7588 8.44046 11.704C8.31069 11.6492 8.19397 11.5676 8.09793 11.4646L5.23194 8.45359C5.08495 8.29637 5.00218 8.0898 4.99993 7.87459V7.87459Z"
                                                fill="#DBDDDE"/>
                                        </svg>}
                                </label>
                                : <div
                                    style={props.entryError || props.newError ? {border: 'none'}
                                        : props.dropdownPadding
                                            ? {padding: props.dropdownPadding}
                                            : props.dropdownStyle
                                                ? props.dropdownStyle : null}
                                    className={`custom_dropdown_select ${props.borderDropdown ? 'custom_dropdown_border' : ''}`}
                                    onClick={props.setInputValue && (props.landingPage || props.defaultDropdown) ?
                                        () => {
                                            props.setInputValue(prevState => ({
                                                ...prevState,
                                                [props.optionLabel]: {
                                                    ...prevState[props.optionLabel],
                                                    popup: !prevState[props.optionLabel].popup
                                                }
                                            }))
                                        } : () => {
                                            props.setInputValue(prevState => ([
                                                ...prevState.slice(0, props.categoryDataIndex),
                                                {
                                                    ...props.categoryDataHandle,
                                                    [props.optionLabel]: {
                                                        ...props.categoryDataHandle[props.optionLabel],
                                                        popup: !prevState[props.categoryDataIndex][props.optionLabel].popup
                                                    }
                                                },
                                                ...prevState.slice(props.categoryDataIndex + 1)
                                            ]))
                                        }}>
                                    {props.withoutPreview ?
                                        <span>{props.withoutPreviewTitle}</span>
                                        : <span>{props.value}</span>}
                                    {props.newDropdownIcon ? props.newDropdownIcon : props.dropdownIcon ?
                                        <img
                                            style={props.popup ? {transform: 'rotateX(180deg)'} : {transform: 'rotateX(0)'}}
                                            src={props.dropdownIcon}
                                            alt="chevron down"/> :
                                        <svg
                                            style={props.popup ? {transform: 'rotateX(180deg)'} : {transform: 'rotateX(0)'}}
                                            width="18" height="18" viewBox="0 0 18 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.99993 7.87459C4.99908 7.63606 5.09296 7.40694 5.26093 7.23759C5.42663 7.08139 5.64724 6.99683 5.87489 7.00226C6.10254 7.00769 6.31887 7.10267 6.47693 7.26659L8.85093 9.75659L11.2249 7.26659C11.3823 7.10149 11.5989 7.00568 11.8269 7.00024C12.055 6.99481 12.2758 7.08018 12.4409 7.23759C12.606 7.39499 12.7018 7.61154 12.7073 7.83958C12.7127 8.06763 12.6273 8.28849 12.4699 8.45359L9.60393 11.4646C9.50602 11.5652 9.38894 11.6452 9.25961 11.6998C9.13028 11.7544 8.99132 11.7826 8.85093 11.7826C8.7101 11.7856 8.57022 11.7588 8.44046 11.704C8.31069 11.6492 8.19397 11.5676 8.09793 11.4646L5.23194 8.45359C5.08495 8.29637 5.00218 8.0898 4.99993 7.87459V7.87459Z"
                                                fill={props.dropdownIconColor ? props.dropdownIconColor : '#DBDDDE'}/>
                                        </svg>}
                                </div>}
                        </div>
                        {props.options.length ?
                            <div
                                // ref={ref => {
                                //     optionsRef = ref
                                // }}
                                className={`custom_dropdown_option overflow_scrollbar ${props.popup ? "active_custom_dropdown" : ""}`}
                                style={props.options.length > 4 ? {overflowY: 'scroll'} : {overflowY: 'auto'}}>
                                {props.search ?
                                    !!props.dataArray && filterArray?.length ?
                                        filterArray.sort().join(',') !== props.dataArray?.sort().join(',') ?
                                            filterArray.map((item, index) => props.dataArray.includes(item) || (
                                                <span key={index}
                                                    // className={`${activeTabIndex === index ? 'activeTabIndex' : ''}`}
                                                      onClick={props.setInputValue ? () => {
                                                          if (props.active_category) {
                                                              props.setInputValue(prevState => ({
                                                                  ...prevState,
                                                                  [props.optionLabel]: {
                                                                      ...prevState[props.optionLabel],
                                                                      value: props.typeId ? item.name : props.selectCategory ? '' : (item.name ?? item),
                                                                      popup: false,
                                                                      error: false,
                                                                      id: props.typeId ? item.id : null,
                                                                      dataArray: [(item.name ?? item)]
                                                                  }
                                                              }))
                                                          } else {
                                                              props.setInputValue(prevState => ({
                                                                  ...prevState,
                                                                  [props.optionLabel]: {
                                                                      ...prevState[props.optionLabel],
                                                                      value: props.typeId ? item.name : props.selectCategory ? '' : (item.name ?? item),
                                                                      popup: false,
                                                                      error: false,
                                                                      id: props.typeId ? item.id : null,
                                                                      dataArray: props.dataArray
                                                                          ? [...prevState[props.optionLabel].dataArray, (item.name ?? item)] : null,
                                                                  }
                                                              }))
                                                          }
                                                      } : null}>{props.typeId ? item.name : (item.name ?? item)}</span>
                                            )) : <span>No items</span>
                                        : filterArray?.length ?
                                        filterArray.map((item, index) => {
                                            return (
                                                <span key={index}
                                                    // className={`${activeTabIndex === index ? 'activeTabIndex' : ''}`}
                                                      onClick={props.setInputValue ? () => {
                                                          if (props.rootObject) {
                                                              props.setInputValue(prevState => ({
                                                                  ...prevState,
                                                                  [props.rootObject]: {
                                                                      ...prevState[props.rootObject],
                                                                      [props.nestedObject]: {
                                                                          ...prevState[props.rootObject][props.nestedObject],
                                                                          [props.optionLabel]: {
                                                                              ...prevState[props.rootObject][props.nestedObject][props.optionLabel],
                                                                              value: props.typeId ? item.name : props.selectCategory ? '' : (item.name ?? item),
                                                                              event_id: props.rootObject ? item.id : null,
                                                                              popup: false
                                                                          }
                                                                      }
                                                                  }
                                                              }))
                                                          } else if (props.nestedObject) {
                                                              props.setInputValue(prevState => ({
                                                                  ...prevState,
                                                                  [props.nestedObject]: {
                                                                      ...prevState[props.nestedObject],
                                                                      [props.optionLabel]: {
                                                                          ...prevState[props.nestedObject][props.optionLabel],
                                                                          value: props.typeId ? item.name : props.selectCategory ? '' : (item.name ?? item),
                                                                          error: false,
                                                                          popup: false,
                                                                          id: props.typeId ? item.id : null,
                                                                      }
                                                                  }
                                                              }))
                                                          } else {
                                                              props.setInputValue(prevState => ({
                                                                  ...prevState,
                                                                  [props.optionLabel]: {
                                                                      ...prevState[props.optionLabel],
                                                                      value: props.typeId ? item.name : props.selectCategory ? '' : (item.name ?? item),
                                                                      error: false,
                                                                      popup: false,
                                                                      id: props.typeId ? item.id : null,
                                                                      dataArray: props.dataArray
                                                                          ? [...prevState[props.optionLabel].dataArray, (item.name ?? item)] : null,
                                                                  }
                                                              }))
                                                          }
                                                      } : null}>
                                                  {props.typeId ? item.name : (item.name ?? item)}
                                                      </span>
                                            )
                                        }) : <span>No items</span>
                                    : filterDropdown.map((item, index) => (
                                        <span key={index}
                                            // className={`${activeTabIndex === index ? 'activeTabIndex' : ''}`}
                                              onClick={props.setInputValue ?
                                                  () => {
                                                      setActivePopup(item, props.dropdownSelect)
                                                  }
                                                  : null}>
                                                  {props.replaceValue ? item.split('_').join(' ') : (item.name ?? item)}
                                                      </span>
                                    ))
                                }
                            </div>
                            : null}
                        {/*{props.error && props.errorTitle ?*/}
                        {/*    <div className={'input_component'}>*/}
                        {/*        <span style={{color: 'var(--error)', margin: '0'}}>*/}
                        {/*        {props.errorTitle}*/}
                        {/*    </span>*/}
                        {/*    </div> : null}*/}
                    </div>
                </OutsideClickHandler>
                {props.newError && props.error
                    ? <div className={props.entryError ? 'new_error_wrapper entry_error' : 'new_error_wrapper'}>
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.6427 12.94L9.5819 0.710387C9.06221 -0.189756 7.7637 -0.190085 7.24381 0.710387L0.182995 12.94C-0.336696 13.8402 0.312277 14.9649 1.35205 14.9649H15.4736C16.513 14.9649 17.1626 13.8405 16.6427 12.94ZM15.4736 13.7278H1.35205C1.26431 13.7278 1.21032 13.6347 1.25429 13.5585L8.31507 1.3289C8.35894 1.25288 8.4666 1.25272 8.51057 1.3289L15.5714 13.5585C15.6153 13.6345 15.5616 13.7278 15.4736 13.7278Z"
                                fill="#FF4949"/>
                            <path
                                d="M8.41148 5.16602C8.0699 5.16602 7.79297 5.44295 7.79297 5.78452V9.33008C7.79297 9.67166 8.0699 9.94859 8.41148 9.94859C8.75305 9.94859 9.02999 9.67166 9.02999 9.33008V5.78452C9.03002 5.44295 8.75309 5.16602 8.41148 5.16602Z"
                                fill="#FF4949"/>
                            <path
                                d="M8.41148 10.752C8.0699 10.752 7.79297 11.0289 7.79297 11.3705V11.7398C7.79297 12.0814 8.0699 12.3583 8.41148 12.3583C8.75305 12.3583 9.02999 12.0814 9.02999 11.7398V11.3705C9.03002 11.0288 8.75309 10.752 8.41148 10.752Z"
                                fill="#FF4949"/>
                        </svg>
                        <span
                            className={'error_message'}>{(props.errTitle || props.errorTitle) ? (props.errTitle || props.errorTitle) : 'Fill this label'}</span>
                    </div>
                    : props.error && (props.errTitle || props.errorTitle) ?
                        <div className={'input_component'}
                             style={props.disableMargin ? {margin: 0} : null}>
                            {props.error ?
                                <span style={props.error ? {color: 'var(--error)', margin: '0'}
                                    : null}>
                    {(props.errTitle || props.errorTitle) ? (props.errTitle || props.errorTitle) : ''}</span> : null}
                        </div> : null}

            </div>
        </Tooltip>
    )
}
