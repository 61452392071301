import {Avatar, Col, Row, Typography} from "antd";
import {useEffect, useState} from "react";
import {useParams} from "react-router";

import API from "../../../api/API";

import AsideCard from "../../../Components/ui/AsideCard";
import BookingItemInfo from "../../../Components/profile/BookingItemInfo";
import {Loader} from "../../../Components/Loader";

import styles from "./BookingView.module.css";

export default function BookingView() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [bookData, setBookData] = useState(null);

    useEffect(() => {
        API().get(`/user/listing/book?id=${id}`)
            .then((res) => {
                setBookData(res.data);
                API().get(`/user/listing/stay?id=${res.data?.listing_id}`)
                    .then((res) => {
                        setData(res.data);
                    })
            })
            .finally(() => setIsLoading(false))
    }, []);

    return (
        <main>
            <div className={styles.header}>
                <h1 className={styles.title}>Your booking in {data?.name}</h1>
                <div>Booking number: #{bookData?.id}</div>
            </div>
            <div className={styles.content}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <BookingItemInfo data={{...data, ...bookData}} />
                )}
                <AsideCard>
                    <div className={styles.contacts}>
                        <Typography.Title level={4}>
                            Contacts
                        </Typography.Title>
                        <div>
                            <Typography.Text strong>
                                Host email
                            </Typography.Text>
                            <div>{data?.owner_email}</div>
                        </div>
                        <div>
                            <Typography.Text strong>
                                Host phone number
                            </Typography.Text>
                            <div>{data?.owner_phone ?? ''}</div>
                        </div>
                        <div>
                            <Row gutter={16}>
                                <Col>
                                    <Avatar size={40} />
                                </Col>
                                <Col>
                                    <div>Hosted by</div>
                                    <Typography.Text strong>{data?.owner_full_name}</Typography.Text>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </AsideCard>
            </div>
        </main>
    )
}