import React from 'react';
import CustomDropdown from "../../Components/CustomComponents/Dropdown";
import greyChevron from "../../assets/img/personal_registration/chevron-input-down.svg";
import categoryClose from '../../assets/img/personal_registration/category_close.svg'

export default function InfoCategories({registrationData, setRegistrationData}) {

    return (
        <div>
            <div>
                <h2>Choose art categories</h2>
                <div className={'registration_categories_select'}>
                    <CustomDropdown value={registrationData.categories.value}
                                    title={'Categories *'}
                                    setInputValue={val => setRegistrationData(val)}
                                    optionLabel={'categories'}
                                    placeholder={'Painting'}
                                    error={registrationData.categories.error}
                                    errTitle={registrationData.categories.errorMessage}
                                    selectCategory
                                    disabled={registrationData.categories.dataArray.length >= 5}
                                    defaultDropdown
                                    search
                                    dataArray={registrationData.categories.dataArray}
                                    onChange={({target}) => {
                                        setRegistrationData(prevState => ({
                                            ...prevState,
                                            categories: {
                                                ...prevState.categories,
                                                value: target.value,
                                            }
                                        }))
                                    }}
                                    dropdownPadding={'5px 16px'}
                                    dropdownIcon={greyChevron}
                                    popup={registrationData.categories.popup}
                                    options={registrationData.categories.options}
                    />
                    {registrationData.categories.dataArray.length >= 5 ?
                        <span>You can choose maximum 5 categories</span>
                        : null}
                </div>

                <div className={'categories_options_container'}>
                    {registrationData.categories.dataArray.map((item, index) => (
                        <div className={'categories_options'} key={index}>
                            <span>{item}</span>
                            <img onClick={() => {
                                setRegistrationData(prevState => ({
                                    ...prevState,
                                    categories: {
                                        ...prevState.categories,
                                        dataArray: prevState.categories.dataArray.filter(el => el !== item)
                                    }
                                }))
                            }} src={categoryClose} alt="close icon"/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}