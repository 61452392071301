import * as types from "./types";

export const getListingsRequest = () => ({
    type: types.GET_LISTINGS_REQUEST
})

export const getListingsReceived = (payload) => ({
    type: types.GET_LISTINGS_RECEIVED,
    payload
})

export const getListingsFailed = () => ({
    type: types.GET_LISTINGS_FAILED,
})

export const getMyListingsRequest = () => ({
    type: types.GET_MY_LISTINGS_REQUEST
})

export const getMyListingsReceived = (payload) => ({
    type: types.GET_MY_LISTINGS_RECEIVED,
    payload
})

export const getMyListingsFailed = () => ({
    type: types.GET_MY_LISTINGS_FAILED,
})

export const getListingStayRequest = () => ({
    type: types.GET_LISTING_STAY_REQUEST
})

export const getListingStayReceived = (payload) => ({
    type: types.GET_LISTING_STAY_RECEIVED,
    payload
})

export const getListingStayFailed = () => ({
    type: types.GET_LISTING_STAY_FAILED,
})

export const getListingAmenitiesFailed = (payload) => ({
    type: types.GET_LISTING_AMENITIES_RECEIVED,
    payload
})

export const deleteListingRequest = () => ({
    type: types.DELETE_LISTING_STAY_REQUEST
})

export const deleteListingSuccess = (payload) => ({
    type: types.DELETE_LISTING_STAY_SUCCESS,
    payload
})

export const deleteListingFailed = () => ({
    type: types.DELETE_LISTING_STAY_FAILED
})