import React, {useEffect} from 'react';
import { useTimer } from 'react-timer-hook';
import moment from "moment";

const CartTimer = ({startTime, expiryTimestampValue, onExpire = () => {}}) => {
    let expiryTimestamp = new Date(moment.utc(startTime, "DD.MM.YYYY hh:mm:ss"))
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 900);

    const {
        seconds,
        minutes,
        start
    } = useTimer({ expiryTimestamp: expiryTimestampValue || expiryTimestamp, onExpire });

    useEffect(() => {
        start()
    }, []);

    return (
        <div className="cart-timer">
            <span>Reserved for you for</span>
            <span className="time">{minutes}:{seconds.toString().length === 1 ? "0" + seconds : seconds}</span>
        </div>
    );
};

export default CartTimer;
