import IconButton from "../IconButton";

import {MinusIcon, PlusIcon} from "../../../assets/icons";

import styles from './IncrementDecrementInput.module.css'

export default function IncrementDecrementInput({ min = 1, max, content, value = 0, onChange }) {
    const handleIncreaseValue = () => {
        if (max && value >= max) {
            return;
        }
        onChange?.(value + 1);
    };

    const handleDecreaseValue = () => {
        if (value > min) {
            onChange?.(value - 1);
        }
    };

    return (
        <div className={styles.root}>
            <IconButton
                className={styles.button}
                size="small"
                icon={MinusIcon}
                onClick={handleDecreaseValue}
            />
            {content}
            <IconButton
                className={styles.button}
                size="small"
                icon={PlusIcon}
                onClick={handleIncreaseValue}
            />
        </div>
    );
};