import React from 'react';
import './styles.css';

function CustomRadioButton(props) {

    return (
        <div
            style={props.disabled ? {opacity: '.3', cursor: 'not-allowed'} : null}
            className={'checkbox_wrapper'}
            onClick={props.onClick ? e => {
                props.onClick(e)
            } : null}
        >
            <div className={'input_checkbox_wrap'}>
                <div style={props.error ? {border: '2px solid var(--new-error)'} : null}
                     className={`input_checkbox_border ${props.value === props.title ? 'input_checkbox_border_active' : ''}`}>
                    <div/>
                </div>
            </div>
            <p style={{marginTop: props.no_margin ? 0 : 14}}>{props.title ? props.title : ''}</p>
        </div>
    );
}

export default CustomRadioButton;
