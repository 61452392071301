import React from 'react';
import SEO from "../../../Components/SEO";

export default function CopyRightPolicy() {
    return (
        <div className={'answer_from_footer'}>
            <SEO title="Copyright Policy: Art investment | Artsted"
                 description="To invest in affordable fine art ⭐ Copyright Policy: Buy art online Artsted"/>
            <h1>Copyright Policy</h1>
            <div className={'products_services_terms'}>
                <p><span>Artsted.com</span> and its operator Artisfact Limited ("Artsted") in appropriate circumstances
                    and at its
                    discretion to disable and/or terminate the accounts of users who repeatedly infringe or are
                    repeatedly
                    charged with infringing the copyrights or other intellectual property rights of others. In
                    accordance
                    with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S.
                    Copyright
                    Office website at http://www.copyright.gov/legislation/dmca.pdf, Artsted will respond expeditiously
                    to
                    claims of copyright infringement committed using the Artsted website. If you are a copyright owner,
                    or
                    are authorized to act on behalf of one, or authorized to act under any exclusive right under
                    copyright,
                    please report alleged copyright infringements taking place on or through the Site by completing the
                    following DMCA Notice of Alleged Infringement and delivering it to Artsted Designated Copyright
                    Agent.
                    Upon receipt of the notice as described below, Artsted will take whatever action, in its sole
                    discretion, it deems appropriate, including removal of the challenged material from the Site.</p>
            </div>
            <div className={"products_services_terms"}>
                <span>Notice of Alleged Infringement ("Notice")</span>
                <div className={"data_collect_info"}>
                    <span>1</span>
                    <p>Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted
                        works are covered by this Notice - you may provide a representative list of the copyrighted
                        works that you claim have been infringed.</p>
                    <span>2</span>
                    <p>Identify (i) the material that you claim is infringing (or to be the subject of infringing
                        activity) and that is to be removed or access to which is to be disabled, and information
                        reasonably sufficient to permit us to locate the material, including at a minimum, if
                        applicable, the URL of the link shown on the Site where such material may be found, and (ii) the
                        reference or link, to the material or activity that you claim to be infringing, that is to be
                        removed or access to which is to be disabled, and information reasonably sufficient to permit us
                        to locate that reference or link, including at a minimum, if applicable, the URL of the link
                        shown on the Site where such reference or link may be found.</p>
                    <span>3</span>
                    <p>Provide your mailing address, telephone number, and, if available, email address.</p>
                    <span>4</span>
                    <p>
                        <p>Include both of the following statements in the body of the Notice:
                        "I hereby state that I have a good faith belief that the disputed use of the copyrighted material or reference or link to such material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)."</p>
                        <p>"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."</p>
                    </p>
                    <span>5</span>
                    <p>Provide your full legal name and your electronic or physical signature.
                        Deliver this Notice, with all items completed, to Artsted legal Agent: legal@artsted.com
                    </p>
                </div>
            </div>

        </div>
    )
}