import clsx from "clsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {CalendarIcon} from "../../../assets/icons";

import styles from './StyledDatePicker.module.css';

export default function StyledDatePicker({error, type, className, ...props}) {
    return (
        <div className={clsx(styles.root, className, error && styles.rootError, type && styles[type])}>
            <DatePicker
                showIcon
                icon={<CalendarIcon />}
                dateFormat="d MMMM yyyy"
                minDate={new Date()}
                popperPlacement="bottom-end"
                {...props}
            />
        </div>
    )
}